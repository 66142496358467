import { DeleteIconButton, notify } from '@/components';
import {
  useDeleteCommunitySubcategoryMutation,
  useUserContextSelector,
} from '@/redux';
import { useTranslation } from 'react-i18next';

interface Props {
  id: string;
}

function useDeleteSubcategory(props: Props) {
  const { id } = props;
  const [remove] = useDeleteCommunitySubcategoryMutation();

  return () =>
    remove({ id })
      .unwrap()
      .then(() => notify.success.t('items.details.subcategories.deleted'));
}

export function DeleteSubcategoryButton(props: Props) {
  const hasRole = useUserContextSelector((x) => x.hasRole);
  const handleDelete = useDeleteSubcategory(props);
  const { t } = useTranslation();

  if (!hasRole('CLIENT_ADMIN') && !hasRole('BFP_ADMIN')) {
    return null;
  }

  return (
    <DeleteIconButton
      confirm
      entityName={t('items.details.subcategories.entityName')}
      title={t('delete')}
      onClick={handleDelete}
    />
  );
}
