import { rtkq, RtkqRequest } from '../rtkq';
import { api } from '../api';

import {
  Paged,
  Paging,
  SignatureDto,
  SignatureFilterParams,
  signaturesHttp,
  UpdateSignatureDto,
} from '@/core';
import { SortResult } from '@/components/Collections.Paging/useSorting';

export type SignatureState = SignatureDto;
export type UpdateSignatureArgs = UpdateSignatureDto;

const signaturesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSignatureList: build.query<
      Paged<SignatureState>,
      RtkqRequest<{
        paging: Paging;
        filters?: SignatureFilterParams;
        sorting?: SortResult;
      }>
    >({
      queryFn: async (args) => {
        return rtkq(args).exec(() =>
          signaturesHttp.getAll(args.paging, args.filters, args.sorting),
        );
      },
      providesTags: [{ type: 'signature', id: 'list' }],
    }),

    updateSignature: build.mutation<void, RtkqRequest<UpdateSignatureArgs>>({
      queryFn: async (args) => {
        return rtkq(args).exec(() => signaturesHttp.update(args));
      },
      invalidatesTags: [{ type: 'signature', id: 'list' }],
    }),
  }),
});

export const { useGetSignatureListQuery, useUpdateSignatureMutation } =
  signaturesApi;
