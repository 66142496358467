import { useTranslation } from 'react-i18next';
import { EnumValue, Icon, Page } from '@/components';
import { useParams } from 'react-router-dom';
import { ProjectAdministrationTabsPanel, ProjectDetailsPanel } from '@/views';
import { useGetProjectQuery } from '@/redux';
import { ProjectType } from '@/core';

function _ProjectDetailsPage() {
  const { t } = useTranslation();
  const { id } = useParams<'id'>();
  const { data } = useGetProjectQuery({ id: id! });
  const title = data?.name ?? t('fetchFallbackTitle');
  const titleSecondary =
    <EnumValue type={ProjectType} value={data?.type} /> ??
    t('fetchFallbackTitle');

  return (
    <>
      <Page.Card
        collapsible
        defaultCollapsed
        title={title}
        subTitle={titleSecondary}
        icon={<Icon type="pie-chart" />}
        color="#1FB197"
      >
        {id && <ProjectDetailsPanel id={id!} />}
      </Page.Card>

      <Page.TabCard>
        {data && <ProjectAdministrationTabsPanel data={data} />}
      </Page.TabCard>
    </>
  );
}

export const ProjectDetailsPage = _ProjectDetailsPage;
