import { useTranslation } from 'react-i18next';

import { AddClientUserButton, ClientUsersListPanel } from '@/views';
import { Icon, Page } from '@/components';
import { useUserContextSelector } from '@/redux';

export function ClientUsersListPage() {
  const { t } = useTranslation();

  const [isBFP, userClientId, isAdmin] = useUserContextSelector((x) => [
    x.isBFP,
    x.clientId,
    x.isAdmin,
  ]);

  return (
    <Page htmlTitle={t('users.title')}>
      <Page.Paths>
        <Page.Path>{t('users.title')}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card
          title={t('users.title')}
          icon={<Icon type="users" />}
          color="#44A2B4"
          actions={
            isAdmin && <AddClientUserButton organizationId={userClientId} />
          }
        >
          {(isBFP || userClientId) && (
            <ClientUsersListPanel
              organizationId={userClientId}
              showClientName
            />
          )}
        </Page.Card>
      </Page.Body>
    </Page>
  );
}
