import { Auth, EditIconButton, EnumValue, TextBox, Title } from '@/components';
import { useGetClientDetailsQuery } from '@/redux';
import { useTranslation } from 'react-i18next';
import { useEditClientAction } from '@/views';
import { UsaStates } from '@/core';

interface Props {
  id: string;
}

export function ClientDetailsPanel(props: Props) {
  const { openEdit, editModal } = useEditClientAction();
  const { id } = props;
  const { data } = useGetClientDetailsQuery({ id });
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  const { name, address } = data;

  return (
    <div>
      <Title>
        {t('clients.details.general')}

        <Title.Actions inline>
          <Auth role="BFP_ADMIN">
            <EditIconButton onClick={() => openEdit(id)} />
          </Auth>
        </Title.Actions>
      </Title>
      <TextBox label={t('clients.details.name')}>{name}</TextBox>
      <TextBox label={t('clients.details.address.street')}>
        {address.street}
      </TextBox>
      <TextBox label={t('clients.details.address.city')}>
        {address.city}
      </TextBox>
      <TextBox label={t('clients.details.address.state')}>
        <EnumValue type={UsaStates} value={address.state} />
      </TextBox>

      {editModal}
    </div>
  );
}
