import { Tabs as AntTabs, TabsProps } from 'antd';
import { useMemo, useState } from 'react';
import { EnumObject } from '@/utils';
import React from 'react';

const { TabPane } = AntTabs;

interface Props extends TabsProps {
  tabKeys: EnumObject<any>;
}

function onTabClick(key: string) {
  window.location.hash = key;
}

function hasValueInNestedTabs(
  children: React.ReactNode,
  value: string,
): boolean {
  for (const child of React.Children.toArray(children)) {
    if (React.isValidElement(child)) {
      if (child.props.tabKeys && value in child.props.tabKeys) {
        return true;
      } else if (
        child.props.children &&
        hasValueInNestedTabs(child.props.children, value)
      ) {
        return true;
      }
    }
  }

  return false;
}

function _Tabs({
  children,
  tabKeys,
  defaultActiveKey: defaultInitialActiveKey,
  ...props
}: Props) {
  const [activeKey, setActiveKey] = useState<string>();

  const defaultActiveKey = useMemo(() => {
    const value =
      window.location.hash.length > 0 ? window.location.hash.substring(1) : '';

    const selectedTab = React.Children.toArray(children).find(
      (child) =>
        React.isValidElement(child) &&
        child.type === TabPane &&
        child.props.children &&
        hasValueInNestedTabs(child.props.children, value),
    ) as any;

    return value in tabKeys
      ? value
      : selectedTab
      ? selectedTab.key?.toString().substring(2)
      : defaultInitialActiveKey;
  }, [children, defaultInitialActiveKey, tabKeys]);

  return (
    <AntTabs
      {...props}
      defaultActiveKey={defaultActiveKey}
      activeKey={activeKey || defaultActiveKey}
      onTabClick={(activeKey: string) => {
        setActiveKey(activeKey);
        onTabClick(activeKey);
      }}
    >
      {children}
    </AntTabs>
  );
}

export const Tabs = Object.assign(_Tabs, {
  TabPane,
});
