import { DevelopScopeTaskStatus, TaskStatus } from '@/core';
import {
  DevelopScopeItemState,
  useGetDevelopScopeItemsListQuery,
} from '@/redux';
import { DevelopScopeListItem, useProjectContext } from '@/views';
import { Divider, Typography } from 'antd';
import { isEmpty } from 'lodash';
import React, { useMemo, useState } from 'react';
import { DevelopScopeTaskStatusFilter } from './DevelopScopeTaskStatusFilter';

interface Props {
  roomId: string | null;
  homeId: string;
  isFloorplan?: boolean;
  readonly?: boolean;
  title?: string;
  compact?: boolean;
}

export const statusFilters = {
  [DevelopScopeTaskStatus.Completed]: (item: DevelopScopeItemState) =>
    item.taskStatus === TaskStatus.Completed,
  [DevelopScopeTaskStatus.InProgress]: (item: DevelopScopeItemState) =>
    item.taskStatus === TaskStatus.InProgress,
  [DevelopScopeTaskStatus.NotStarted]: (item: DevelopScopeItemState) =>
    item.taskStatus === TaskStatus.NotStarted,
  [DevelopScopeTaskStatus.TaskNotAdded]: (item: DevelopScopeItemState) =>
    item.taskRequired === true && item.projectTaskId === null,
  [DevelopScopeTaskStatus.TaskNotRequired]: (item: DevelopScopeItemState) =>
    item.taskRequired === false,
};

export function getDevelopScopeRoomId(roomId?: string | null) {
  return roomId === 'null' ? null : roomId;
}

function useDevelopScopeItems(
  props: Props,
  filterStatuses: DevelopScopeTaskStatus[] | undefined,
) {
  const { homeId, roomId: selectedRoomId, isFloorplan } = props;
  const roomId = getDevelopScopeRoomId(selectedRoomId) ?? null;
  const { projectId } = useProjectContext();
  const { data: items } = useGetDevelopScopeItemsListQuery({
    projectId,
    homeId,
    roomId,
    onlyWholeHome: !roomId && !isFloorplan,
    onlyFloorplan: isFloorplan,
  });

  return useMemo(() => {
    if (isEmpty(filterStatuses)) return items;

    return filterStatuses!.flatMap((status) =>
      items!.filter(statusFilters[status]),
    );
  }, [items, filterStatuses]);
}

export function DevelopScopeList(props: Props) {
  const { readonly, title, compact } = props;
  const [selectedStatus, setSelectedStatus] =
    useState<DevelopScopeTaskStatus[]>();
  const items = useDevelopScopeItems(props, selectedStatus);

  if (!items) {
    return null;
  }

  return (
    <>
      <DevelopScopeTaskStatusFilter
        onSelected={setSelectedStatus}
        selected={selectedStatus}
        className="mt-3"
        compact={compact}
      />
      <Typography.Title level={5}>{title}</Typography.Title>
      {items.map((item, index) => (
        <React.Fragment key={item.id}>
          <DevelopScopeListItem
            key={item.id}
            readonly={readonly}
            className="mb-3"
            item={item}
            compact={compact}
          />
          {index !== items.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </>
  );
}
