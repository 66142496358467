import { FileDownloadButton, TabPanel } from '@/components';
import { communitiesHttp } from '@/core';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemsUpload } from './FormItemsUpload';

export function ItemsUploadPanel() {
  const { t } = useTranslation();

  return (
    <TabPanel min="auto">
      <TabPanel.Title>{t('items.upload.title')}</TabPanel.Title>
      <TabPanel.Body>
        <Row>
          <FileDownloadButton
            className="mb-3"
            download="download"
            name={t('items.upload.downloadSample')}
            action={() => communitiesHttp.downloadUploadSample()}
            type="link"
          />
        </Row>
        <Row>
          <Col span={6}>
            <FormItemsUpload />
          </Col>
        </Row>
      </TabPanel.Body>
    </TabPanel>
  );
}
