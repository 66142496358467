import { Form } from '@/components';
import { ProjectLocationStepDeadlineLookupDto } from '@/core';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';
import i18next from 'i18next';
import { isEqual } from 'lodash';
import { ProjectLocationDeadlineInfo } from '@/redux';

interface Props {
  onClose: () => any;
  onSubmit: (value: ProjectLocationStepDeadlineLookupDto) => void;
  selectedStep: ProjectLocationStepDeadlineLookupDto;
  value: ProjectLocationDeadlineInfo;
}

const getPreviousStep = (
  currentStepId: string,
  steps: ProjectLocationStepDeadlineLookupDto[],
) => {
  const currentStep = steps.find((x) => x.id === currentStepId)!;
  const index = steps.indexOf(currentStep);
  return index > 0 ? steps.at(index - 1) : undefined;
};

const getNextStep = (
  currentStepId: string,
  steps: ProjectLocationStepDeadlineLookupDto[],
) => {
  const currentStep = steps.find((x) => x.id === currentStepId)!;
  const index = steps.indexOf(currentStep);
  return index < steps.length - 1 ? steps[index + 1] : undefined;
};

function validateDeadlineDate(
  deadlineDate: Date | undefined,
  context: Yup.TestContext<AnyObject>,
  previousDate?: string,
  nextDate?: string,
) {
  const initialDate = context.parent.initialDate as Date | undefined;

  if (!deadlineDate) return true;
  const previousStepDate = previousDate;

  if (previousStepDate && deadlineDate < new Date(previousStepDate))
    return context.createError({
      path: context.path,
      message: i18next.t('errors.date_cannot_be_less_than_previous_date'),
    });

  if (initialDate && isEqual(initialDate, deadlineDate)) return true;

  if (nextDate && deadlineDate > new Date(nextDate))
    return context.createError({
      path: context.path,
      message: i18next.t('errors.date_cannot_be_greater_than_next_date'),
    });

  return true;
}

function getSchema(previousDate?: string, nextDate?: string) {
  return Yup.object().shape({
    deadlineDate: Yup.date()
      .test('deadlineDate', (deadlineDate, context) =>
        validateDeadlineDate(deadlineDate, context, previousDate, nextDate),
      )
      .nullable()
      .required(),
  });
}

export function LocationDeadlineEditModal(props: Props) {
  const { onClose: onCancel, selectedStep, value, onSubmit } = props;
  const { t } = useTranslation();
  const previousStep = getPreviousStep(selectedStep.id, value.steps);
  const nextStep = getNextStep(selectedStep.id, value.steps);
  return (
    <Modal
      title={t('projects.locationDeadlines.editDeadline')}
      visible
      onCancel={onCancel}
      footer={[
        <Button key="back" type="default" onClick={onCancel}>
          {t('cancel')}
        </Button>,
        <Form.Submit
          uid="location-deadline-details"
          key="submit"
          type="primary"
        >
          {t('save')}
        </Form.Submit>,
      ]}
    >
      <Form.Formik<ProjectLocationStepDeadlineLookupDto>
        uid="location-deadline-details"
        i18n="locationDeadlines.details"
        initialValues={selectedStep}
        validationSchema={getSchema(
          previousStep?.deadlineDate,
          nextStep?.deadlineDate,
        )}
        onSubmit={onSubmit}
      >
        <Form.Date
          label={t('projects.details.deadlineName', {
            name: selectedStep.name,
          })}
          name="deadlineDate"
          allowClear={false}
          required
        />
      </Form.Formik>
    </Modal>
  );
}
