import { Button } from 'antd';
import styles from './Button.module.scss';
import { CancelButtonProps } from './CancelButton';
import classNames from 'classnames';

export function SecondaryCancelButton({
  className,
  ...props
}: CancelButtonProps) {
  return (
    <Button
      {...props}
      type="default"
      className={classNames(styles.secondary, className)}
    />
  );
}
