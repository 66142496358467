import { rtkq, RtkqRequest } from '../rtkq';
import { api } from '../api';
import { AddPaymentDto, PaymentsDto, paymentsHttp } from '@/core';

export type PaymentsState = PaymentsDto;
export type AddPaymentState = AddPaymentDto;

const personalizationPaymentsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPersonalizationPayments: build.query<
      PaymentsState[],
      RtkqRequest<{
        homeId: string;
        projectId: string;
        stepId?: string;
      }>
    >({
      queryFn: async (args) => {
        return rtkq(args).exec(() => paymentsHttp.getAll(args));
      },
      providesTags: (_, __, args) => [
        {
          type: 'payments',
          id: args.projectId + args.homeId,
        },
      ],
    }),

    addPayment: build.mutation<string, RtkqRequest<AddPaymentState>>({
      queryFn: (request) => {
        return rtkq(request).exec(() => paymentsHttp.add(request));
      },
      invalidatesTags: (_, __, request) => [
        { type: 'payments', id: request.projectId + request.homeId },
      ],
    }),

    deletePayment: build.mutation<
      string,
      RtkqRequest<{
        id: string;
        homeId: string;
        projectId: string;
      }>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() => paymentsHttp.delete({ id: args.id }));
      },
      invalidatesTags: (_, __, args) => [
        {
          type: 'payments',
          id: args.projectId + args.homeId,
        },
      ],
    }),
  }),
});

export const {
  useGetPersonalizationPaymentsQuery,
  useAddPaymentMutation,
  useDeletePaymentMutation,
} = personalizationPaymentsApi;
