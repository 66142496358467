import { useState } from 'react';
import { useBulkUpdateTasksMutation, BulkUpdateTaskArgs } from '@/redux';
import { notify } from '@/components';
import { BulkUpdateTasksModal } from './BulkUpdateTasksModal';

export function useBulkUpdateTasksActions() {
  const [taskIds, setId] = useState<string[]>();
  const [projectId, setProjectId] = useState<string>();
  const [communityId, setCommunityId] = useState<string>();
  const [bulkUpdate] = useBulkUpdateTasksMutation();

  const reset = () => {
    setId(undefined);
    setProjectId(undefined);
    setCommunityId(undefined);
  };

  const handleSubmit = (value: BulkUpdateTaskArgs) => {
    bulkUpdate(value)
      .unwrap()
      .then(() => {
        notify.success.t('tasks.bulkUpdate.success');
        reset();
      });
  };

  return {
    openBulkUpdate: (
      taskIds: string[],
      projectId: string,
      communityId: string,
    ) => {
      setId(taskIds);
      setProjectId(projectId);
      setCommunityId(communityId);
    },
    bulkUpdateModal: (
      <>
        {taskIds && projectId && communityId && (
          <BulkUpdateTasksModal
            onSubmit={handleSubmit}
            taskIds={taskIds}
            projectId={projectId}
            communityId={communityId}
            onClose={reset}
          />
        )}
      </>
    ),
  };
}
