import { SubscriptionState, useGetSubscriptionsListQuery } from '@/redux';
import {
  DefaultNumberSorting,
  Table,
  TabPanel,
  useDefaultTablePaging,
  useFilters,
  useSorting,
} from '@/components';
import { useSubscriptionsColumns } from './useSubscriptionsColumns';
import { useEditSubscriptionAction } from '../Subscriptions.Details/useEditSubscriptionAction';
import { useTranslation } from 'react-i18next';
import { AddSubscriptionButton } from '../Subscriptions.Details';
import { useMemo } from 'react';
import { SubscriptionFilterParams } from '@/core';

export function SubscriptionListTabPanel() {
  const { setTotal, paging, params } = useDefaultTablePaging();
  const {
    filters,
    onFiltersChange,
    resetFilters,
    stringFilterOptions,
    setStringFilterOptions,
  } = useFilters<SubscriptionFilterParams>([
    'number',
    'clientName',
    'communityName',
  ]);
  const { sorting, onSortingChange } =
    useSorting<SubscriptionState>(DefaultNumberSorting);

  const { data, isFetching } = useGetSubscriptionsListQuery({
    paging: params,
    filters,
    sorting,
  });

  const subscriptions = useMemo(() => {
    if (data) {
      setTotal(data.total);
      setStringFilterOptions(data.stringFilterOptions);
    }
    return data?.items;
  }, [data, setStringFilterOptions, setTotal]);

  const { t } = useTranslation();
  const { editModal, openEdit } = useEditSubscriptionAction();
  const columns = useSubscriptionsColumns(
    subscriptions,
    openEdit,
    stringFilterOptions,
  );

  return (
    <TabPanel min="auto">
      <TabPanel.Title>{t('subscriptions.title')}</TabPanel.Title>
      <TabPanel.Actions>
        <AddSubscriptionButton />
      </TabPanel.Actions>
      <TabPanel.Body>
        <Table<SubscriptionState>
          name="Subscriptions"
          loading={isFetching}
          columns={columns}
          dataSource={subscriptions}
          rowKey={(row) => row.communityId}
          pagination={paging}
          onChange={(_, filters, sorting) => {
            onFiltersChange(filters);
            onSortingChange(sorting);
          }}
          onFiltersReset={resetFilters}
        />
        {editModal}
      </TabPanel.Body>
    </TabPanel>
  );
}
