import { useCallback } from 'react';
import {
  HomePriceMatrixRowState,
  SaveItemPriceArgs,
  useSaveHomeItemPriceMutation,
} from '@/redux';
import { notify } from '@/components';

type RowValueName = keyof Pick<
  SaveItemPriceArgs,
  'baseCost' | 'ownerPrice' | 'residentPrice'
>;

function useSaveRowValue(communityId: string) {
  const [save] = useSaveHomeItemPriceMutation();

  return useCallback(
    (
      row: HomePriceMatrixRowState,
      name: RowValueName,
      value: number | undefined,
    ) => {
      const isEmptyBaseCost = name === 'baseCost' && value == null;
      save({
        itemId: row.itemId,
        homeId: row.home.id,
        roomId: row.room?.id,
        baseCost: row.prices?.baseCost?.isOverriden
          ? row.prices?.baseCost?.value
          : undefined,
        ownerPrice:
          row.prices?.ownerPrice?.isOverriden && !isEmptyBaseCost
            ? row.prices?.ownerPrice?.value
            : undefined,
        residentPrice:
          row.prices?.residentPrice?.isOverriden && !isEmptyBaseCost
            ? row.prices?.residentPrice?.value
            : undefined,
        [name]: value,
        communityId,
      })
        .unwrap()
        .then(() => notify.success.t('pricing.saved'));
    },
    [save, communityId],
  );
}

export function useHomePriceMatrixRowChangeHandlerFactory(communityId: string) {
  const saveRowValue = useSaveRowValue(communityId);

  return useCallback(
    (item: HomePriceMatrixRowState, name: RowValueName) =>
      (value: number | undefined) => {
        return saveRowValue(item, name, value);
      },
    [saveRowValue],
  );
}
