import { AddButton, DeleteIconButton, Form } from '@/components';
import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { dataNodeUtils } from './dataNodeUtils';
import { DataNode } from 'antd/lib/tree';
import styles from './FormsEditTree.module.scss';

export interface FormsTreeTexts {
  addChildBtnText: React.ReactNode;
  nameFieldTitle: React.ReactNode;
  childNameFieldTitle: React.ReactNode;
}

export interface FormsTreeProps {
  maxDepth?: number;
  texts: FormsTreeTexts;
  onAdd: (parent: DataNode) => any;
  onDelete: (item: DataNode) => any;
}

type ItemProps = FormsTreeProps & {
  item: DataNode;
  depth: number;
  className?: string;
};

function useHandleDelete(props: ItemProps) {
  const { onDelete, item } = props;
  return useCallback(() => onDelete(item), [item, onDelete]);
}

function useHandleAdd(props: ItemProps) {
  const { onAdd, item } = props;
  return useCallback(() => onAdd(item), [onAdd, item]);
}

const Item = (props: ItemProps) => {
  const { values } = useFormikContext<DataNode>();
  const { depth, maxDepth, item, texts, className } = props;

  const handleDelete = useHandleDelete(props);
  const handleAdd = useHandleAdd(props);

  const canAdd = depth < maxDepth!;
  const isRoot = depth === 0;
  const canDelete = !isRoot;
  const name = dataNodeUtils.findTitlePath(values, item.key)!;
  const nameTitle = isRoot ? texts.nameFieldTitle : texts.childNameFieldTitle;

  return (
    <div className={className}>
      <div className={styles.group}>
        <div className={styles.input}>
          <Form.Input name={name} label={nameTitle} required />
        </div>
        {canDelete && (
          <div className={styles.actions}>
            <DeleteIconButton onClick={handleDelete} />
          </div>
        )}
      </div>

      <div>
        {canAdd && (
          <AddButton onClick={handleAdd} type="link" className="mb-3">
            {texts.addChildBtnText}
          </AddButton>
        )}
      </div>

      {item.children?.map((item) => {
        return (
          <Item
            className="ms-4"
            {...props}
            item={item}
            depth={depth + 1}
            key={item.key}
          />
        );
      })}
    </div>
  );
};

export function FormsTree(props: FormsTreeProps) {
  const { values } = useFormikContext<DataNode>();
  return <Item {...props} item={values} depth={0} />;
}
