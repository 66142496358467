import { SortResult } from '@/components/Collections.Paging/useSorting';
import {
  Http,
  AddClientDto,
  ClientDto,
  UpdateClientDto,
  Paging,
  Paged,
  ClientFilterParams,
} from '@/core';
import { guard } from '@/utils';
import qs from 'qs';

class ClientsHttp extends Http {
  public async getAll(
    paging: Paging,
    filters?: ClientFilterParams,
    sorting?: SortResult,
  ) {
    const response = await this._axios.get<Paged<ClientDto>>(
      `/api/client?${qs.stringify({
        ...paging,
        ...filters,
        ...sorting,
      })}`,
    );
    return response.data;
  }

  public async get(id: string) {
    guard.notNull(id, 'id');
    const response = await this._axios.get<ClientDto>(`/api/client/${id}`);
    return response.data;
  }

  public async create(request: AddClientDto) {
    guard.notNull(request, 'request');
    const response = await this._axios.post<string>('/api/client', request);
    return response.data;
  }

  public async update(request: UpdateClientDto) {
    guard.notNull(request, 'request');
    await this._axios.put('/api/client', request);
  }

  public async remove(id: string) {
    guard.notNull(id, 'id');
    await this._axios.delete(`/api/client/${id}`);
  }
}

export const clientsHttp = new ClientsHttp();
