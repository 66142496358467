import { TaskDetailsDto, TaskStatus } from '@/core';
import { EnumValue } from '@/components';
import { Button, Space } from 'antd';
import { useUpdateTaskStatusMutation } from '@/redux';
import { useCallback } from 'react';
import { RightOutlined } from '@ant-design/icons';

function useHandleClick(taskId: string) {
  const [change] = useUpdateTaskStatusMutation();

  return useCallback(
    (status: TaskStatus) => change({ taskId, status }).unwrap(),
    [change, taskId],
  );
}

export function TaskStatusControl({
  task,
  readonly,
}: {
  task: TaskDetailsDto;
  readonly: boolean;
}) {
  const { status } = task;
  const handleClick = useHandleClick(task.id);

  return (
    <Space size={10}>
      {!readonly && (
        <>
          <StatusButton
            currentStatus={status}
            checkStatus={TaskStatus.NotStarted}
            handleClick={handleClick}
          />

          <RightOutlined />

          <StatusButton
            currentStatus={status}
            checkStatus={TaskStatus.InProgress}
            handleClick={handleClick}
          />

          <RightOutlined />

          <StatusButton
            currentStatus={status}
            checkStatus={TaskStatus.Completed}
            handleClick={handleClick}
          />
        </>
      )}
    </Space>
  );
}

function StatusButton({
  handleClick,
  currentStatus,
  checkStatus,
}: {
  currentStatus: TaskStatus;
  checkStatus: TaskStatus;
  handleClick: (status: TaskStatus) => Promise<void>;
}) {
  const isCurrentStatus = currentStatus === checkStatus;
  return (
    <>
      {isCurrentStatus ? (
        <EnumValue type={TaskStatus} value={currentStatus} />
      ) : (
        <Button
          type="primary"
          size="small"
          ghost
          onClick={() => handleClick(checkStatus)}
        >
          <EnumValue type={TaskStatus} value={checkStatus} />
        </Button>
      )}
    </>
  );
}
