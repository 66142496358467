import { AddButton, FieldRow } from '@/components';
import { Col, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { GenerateHomeStatusReportButton } from '../Reports.HomeStatus/GenerateHomeStatusReportButton';
import { GenerateTrendReportButton } from '../Reports.TrendReport/GenerateTrendReportButton';
import { GeneratePersonalizationSummaryReportButton } from '../Reports.PersonalizationSummary/GeneratePersonalizationSummaryReportButton';

interface Props {
  onCreateCustomReport: () => any;
}

export function ReportActions(props: Props) {
  const { onCreateCustomReport } = props;
  const { t } = useTranslation();

  return (
    <FieldRow>
      <Col span={10}>
        <AddButton onClick={() => onCreateCustomReport()}>
          {t('reports.createCustomReport')}
        </AddButton>
      </Col>
      <Col span={14} className="text-end">
        <Space size={'middle'} wrap>
          <GeneratePersonalizationSummaryReportButton />
          <GenerateHomeStatusReportButton />
          <GenerateTrendReportButton />
        </Space>
      </Col>
    </FieldRow>
  );
}
