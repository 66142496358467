import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddButton, INITIAL_CONTACT } from '@/components';
import { ContactDetailsModal } from '@/views';
import { Contact } from '@/core';

interface Props {
  className?: string;
  onAdd: (contact: Contact) => Promise<any>;
}

export function AddContactButton(props: Props) {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const { className, onAdd } = props;

  const submit = (contact: Contact) => {
    onAdd(contact).then(() => {
      setOpenModal(false);
    });
  };

  return (
    <div className={className}>
      <AddButton onClick={() => setOpenModal(true)}>
        {t('clients.details.addContactButton')}
      </AddButton>
      {openModal && (
        <ContactDetailsModal
          title={t('clients.details.addContactTitle')}
          buttonSaveName={t('add')}
          onSubmit={submit}
          value={INITIAL_CONTACT}
          onClose={() => setOpenModal(false)}
        />
      )}
    </div>
  );
}
