import { Button, Col, Space } from 'antd';
import { FieldRow, Form } from '@/components';
import * as Yup from 'yup';
import { AddResidentArgs, UpdateResidentArgs } from '@/redux';
import { useTranslation } from 'react-i18next';

const schema = Yup.object().shape({
  name: Yup.string().field().nullable().required(),
  email: Yup.string().email().nullable().required(),
  phone: Yup.string().phone(),
  notes: Yup.string().field('lg').nullable(),
});

interface Props<TValue extends UpdateResidentArgs | AddResidentArgs> {
  onCancel: () => any;
  onSubmit: (value: TValue) => any;
  value: TValue;
  className?: string;
  saveButtonName: string;
}

export function ResidentDetailsForm<
  TValue extends UpdateResidentArgs | AddResidentArgs,
>(props: Props<TValue>) {
  const { onCancel, value, onSubmit, saveButtonName } = props;
  const { t } = useTranslation();

  return (
    <Form.Formik<TValue>
      uid="resident-details"
      i18n="residents.details"
      initialValues={value}
      validationSchema={schema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <FieldRow>
        <Col span={12}>
          <Form.Input name="name" required />
        </Col>
      </FieldRow>
      <FieldRow>
        <Col span={12}>
          <Form.Input name="email" required />
        </Col>
        <Col span={12}>
          <Form.Phone name="phone" />
        </Col>
      </FieldRow>
      <Form.TextArea name="notes" />
      <Space>
        <Button type="default" onClick={onCancel}>
          {t('residents.details.cancelButton')}
        </Button>
        <Form.Submit type="primary">{saveButtonName}</Form.Submit>
      </Space>
    </Form.Formik>
  );
}
