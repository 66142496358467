import { Modal, Button, Col } from 'antd';
import { FieldRow, Form } from '@/components';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { AddPaymentState } from '@/redux';
import { MAX_VALUE, useProjectStepLookupSource } from '@/views';
import { PaymentType } from '@/core';

const schema = Yup.object().shape({
  projectId: Yup.string().field().nullable().required(),
  homeId: Yup.string().field().nullable().required(),
  stepId: Yup.string().field().nullable().required(),
  paymentType: Yup.string()
    .oneOf(Object.values(PaymentType))
    .nullable()
    .required(),
  date: Yup.date().nullable().required(),
  description: Yup.string().field().nullable().required(),
  amount: Yup.number().min(0).max(MAX_VALUE).nullable().required(),
});

interface Props {
  onClose: () => any;
  onSubmit: (props?: any) => any;
  value: AddPaymentState;
}

export function PaymentDetailsModal(props: Props) {
  const { onClose: onCancel, value, onSubmit } = props;
  const { t } = useTranslation(undefined, { keyPrefix: 'payments.details' });

  const stepsDataSource = useProjectStepLookupSource([value.projectId]);

  return (
    <Modal
      title={t('title')}
      visible
      onCancel={onCancel}
      footer={[
        <Button key="back" type="default" onClick={onCancel}>
          {t('cancel')}
        </Button>,
        <Form.Submit uid="payments-details" key="submit" type="primary">
          {t('buttonAddName')}
        </Form.Submit>,
      ]}
    >
      <Form.Formik<AddPaymentState>
        uid="payments-details"
        i18n="payments.details"
        initialValues={value}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        <>
          <FieldRow>
            <Col span={12}>
              <Form.EnumSelect name="paymentType" type={PaymentType} required />
            </Col>
            <Col span={12}>
              <Form.Date
                required
                name="date"
                placeholder={t('placeholder.date')}
                allowClear={false}
              />
            </Col>
          </FieldRow>
          <FieldRow>
            <Col span={12}>
              <Form.AsyncSelect
                name="stepId"
                dataSource={stepsDataSource}
                required
              />
            </Col>
          </FieldRow>
          <FieldRow>
            <Col span={24}>
              <Form.TextArea
                required
                name="description"
                placeholder={t('placeholder.description')}
              />
            </Col>
          </FieldRow>
          <FieldRow>
            <Col span={12}>
              <Form.Money
                required
                name="amount"
                max={MAX_VALUE}
                placeholder={t('placeholder.amount')}
              />
            </Col>
          </FieldRow>
        </>
      </Form.Formik>
    </Modal>
  );
}
