import { notify } from '@/components';
import { Paging } from '@/core';
import {
  SpecialRequestPriceMatrixRowState,
  UpdateSpecialRequestPriceMatrixRowArgs,
  useUpdateSpecialRequestPriceMatrixRowMutation,
} from '@/redux';
import { useCallback } from 'react';

export type RowValueName =
  | 'subcontractorId'
  | 'fee'
  | 'status'
  | 'baseCost'
  | 'ownerPrice'
  | 'residentPrice';

const PRICE_ROW_VALUE_NAME: RowValueName[] = [
  'baseCost',
  'ownerPrice',
  'residentPrice',
];

function useSaveRowValue(
  communityId: string,
  paging: Paging,
  projectIds?: string[],
) {
  const [save] = useUpdateSpecialRequestPriceMatrixRowMutation();
  return useCallback(
    (
      row: SpecialRequestPriceMatrixRowState,
      name: RowValueName,
      value: any,
    ) => {
      const { id, fee, status, prices, subcontractor } = row;

      const request: UpdateSpecialRequestPriceMatrixRowArgs = {
        communityId,
        projectIds,
        id,
        fee,
        status,
        subcontractorId: subcontractor?.id,
        prices: {
          baseCost: prices?.baseCost!,
          ownerPrice:
            prices?.ownerPrice && prices.ownerPrice.isOverriden
              ? prices?.ownerPrice.value
              : undefined,

          residentPrice:
            prices?.residentPrice && prices.residentPrice.isOverriden
              ? prices?.residentPrice.value
              : undefined,
        },
        paging: paging,
        [name]: value,
      };

      if (PRICE_ROW_VALUE_NAME.includes(name)) {
        (request.prices as any)[name] = value;
      }

      if (request.prices?.baseCost == null) {
        request.prices = undefined;
      }

      if (Object.values(request?.prices ?? {}).every((x) => x == null))
        request.prices = undefined;

      return save(request)
        .unwrap()
        .then(() => notify.success.t('pricing.specialRequest.saved'));
    },
    [communityId, projectIds, paging, save],
  );
}

export function useSpecialRequestPriceMatrixRowChangeHandlerFactory(
  communityId: string,
  paging: Paging,
  projectIds?: string[],
) {
  const saveRowValue = useSaveRowValue(communityId, paging, projectIds);

  return useCallback(
    (item: SpecialRequestPriceMatrixRowState, name: RowValueName) =>
      (value: any) => {
        return saveRowValue(item, name, value);
      },
    [saveRowValue],
  );
}
