import { useTranslation } from 'react-i18next';
import { Tabs } from '@/components';
import { EnumObject, enumObject } from '@/utils';
import { CommunityAssignmentsListPanel } from '../CommunityAssignments.List';
import { RolesPermissionsListPanel } from './RolesPermissionsListPanel';
import { useCommunityContext } from '../Communities.Common';
import { CommunityPermissions } from '@/core';

const TAB_KEYS = ['roles', 'permissions'] as const;

export const AssignmentsTabContentKeys = enumObject(
  'AssignmentsTabContentKeys',
  TAB_KEYS,
);

const { TabPane } = Tabs;

interface Props {
  className?: string;
  communityId: string;
  clientId: string;
  tabKeys: EnumObject<any>;
}

export const CommunityAssignmentsTabContent = (props: Props) => {
  const { className, communityId, clientId, tabKeys } = props;
  const { t } = useTranslation();
  const { hasPermission } = useCommunityContext();

  return (
    <Tabs className={className} tabKeys={tabKeys} destroyInactiveTabPane>
      <TabPane tab={t('communityUsers.title')} key={tabKeys.roles}>
        <CommunityAssignmentsListPanel
          clientId={clientId}
          communityId={communityId}
        />
      </TabPane>
      {hasPermission(CommunityPermissions.Users.Manage) && (
        <TabPane tab={t('rolesPermissions.title')} key={tabKeys.permissions}>
          <RolesPermissionsListPanel />
        </TabPane>
      )}
    </Tabs>
  );
};
