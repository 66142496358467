import {
  Attach,
  DateValue,
  DeleteIconButton,
  EditIconButton,
  TextBox,
  Title,
} from '@/components';
import { useTranslation } from 'react-i18next';
import { formatters, useAppClippo, CommunityPermissions } from '@/core';
import { useGetTaskDetailsQuery } from '@/redux';
import {
  TaskCommentsPanel,
  useCommunityContext,
  useEditTaskAction,
  useProjectContext,
} from '@/views';
import { TaskStatusControl } from './TaskStatusControl';
import { useDuplicateTaskAction } from './useDuplicateTaskAction';
import { CopyIconButton } from '@/components/Styled/CopyIconButton';
import { useDeleteTask } from './useDeleteTask';

interface Props {
  taskId: string;
}

export function TaskDetailsPanel(props: Props) {
  const { taskId } = props;
  const { t } = useTranslation();
  const { openEdit, editModal } = useEditTaskAction();
  const { data } = useGetTaskDetailsQuery({ id: taskId }, { skip: !taskId });
  const { openDuplicate, duplicateModal } = useDuplicateTaskAction();
  const { assert } = useCommunityContext();
  const { isActive: isActiveProject } = useProjectContext();
  const handleDelete = useDeleteTask(true);

  const readonly =
    !assert(CommunityPermissions.TaskManagement.Manage, true) ||
    !isActiveProject;

  const clippoProps = useAppClippo({
    defer: false,
    ownerId: taskId,
    ownerType: 'Task',
  });

  if (!data) {
    return null;
  }

  const {
    id,
    name,
    homeName,
    number,
    startDate,
    endDate,
    responsiblePerson,
    estimatedCost,
    description,
    projectId,
  } = data;

  return (
    <div>
      <Title>
        <Title.Actions inline space>
          {!readonly && (
            <>
              <EditIconButton type="link" onClick={() => openEdit(id)} />
              <CopyIconButton
                onClick={() => openDuplicate([props.taskId], projectId)}
              />
              <DeleteIconButton onClick={() => handleDelete(id, projectId)} />
            </>
          )}
        </Title.Actions>

        {t('tasks.details.general')}
      </Title>
      <TextBox label={t('tasks.details.number')}>{number}</TextBox>
      <TextBox label={t('tasks.details.home')}>{homeName}</TextBox>
      <TextBox label={t('tasks.details.name')}>{name}</TextBox>
      <TextBox label={t('tasks.details.status')}>
        <TaskStatusControl task={data} readonly={readonly} />
      </TextBox>
      <TextBox label={t('tasks.details.startDate')}>
        <DateValue value={startDate} />
      </TextBox>
      <TextBox label={t('tasks.details.endDate')}>
        <DateValue value={endDate} />
      </TextBox>
      <TextBox label={t('tasks.details.responsiblePerson')}>
        {responsiblePerson.label}
      </TextBox>
      <TextBox label={t('tasks.details.estimatedCost')}>
        {formatters.money(estimatedCost)}
      </TextBox>
      <TextBox label={t('tasks.details.description')}>
        <div className="text-multiline">{description}</div>
      </TextBox>

      {editModal}
      {duplicateModal}

      <Title className="mt-4">{t('tasks.details.attachments')}</Title>
      <Attach className="mt-4" {...clippoProps} readonly={readonly} />

      <Title className="mt-4">{t('tasks.details.comments')}</Title>
      <TaskCommentsPanel taskId={taskId} canAdd={!readonly} />
    </div>
  );
}
