import { DataNode } from 'antd/lib/tree';
import { dataNodeUtils } from '../Forms';
import { Title } from '../Title';
import { TreeViewEditForm, TreeViewEditFormTexts } from './TreeViewEditForm';

export interface TreeViewEditTexts extends TreeViewEditFormTexts {
  addTitle: React.ReactNode;
  editTitle: React.ReactNode;
}

interface Props {
  value: DataNode;
  uid: string;
  texts: TreeViewEditTexts;
  onCancel: () => any;
  onSubmit: (value: DataNode) => any;
}

export function TreeViewEdit(props: Props) {
  const { value, uid, texts, onSubmit, onCancel } = props;

  const { addTitle, editTitle } = texts;
  const isNew = dataNodeUtils.isNew(value);

  return (
    <div>
      <Title>{isNew ? addTitle : editTitle}</Title>
      <TreeViewEditForm
        uid={uid}
        value={value}
        texts={texts}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
}
