import { Form } from '@/components';
import {
  PathTemplateSourcesState,
  useGetPathTemplateSourcesQuery,
} from '@/redux';
import { array } from '@/utils';
import { useField } from 'formik';
import { useCallback, useMemo } from 'react';

interface Props {
  i18n: string;
  stepsFieldName: string;
  organizationId?: string;
  communityId?: string;
}

function useSources({ organizationId, communityId }: Props) {
  const { data: templates = array.empty<PathTemplateSourcesState>() } =
    useGetPathTemplateSourcesQuery({
      organizationId,
      communityId,
    });

  return templates;
}

function useOptions(props: Props) {
  const templates = useSources(props);

  return useMemo(
    () =>
      templates.map((pathTemplate) => ({
        value: pathTemplate.id,
        label: pathTemplate.name,
      })),
    [templates],
  );
}

function useHandleSourceTemplateIdChange(props: Props) {
  const { stepsFieldName } = props;
  const sources = useSources(props);
  const [, , { setValue }] = useField(stepsFieldName);

  return useCallback(
    (_: any, newValue: string | null) => {
      if (!newValue) return;

      const source = sources!.find((x) => x.id === newValue)!;
      const steps = source.steps.map((step) => ({ ...step, id: null! }));
      setValue(steps);
    },
    [sources, setValue],
  );
}

export function FormPathTemplateSource(props: Props) {
  const options = useOptions(props);
  const handleSourceTemplateIdChange = useHandleSourceTemplateIdChange(props);
  const [{ value: useTemplate }] = useField<boolean>('useTemplate');

  return (
    <>
      <Form.FieldChange
        name="sourceTemplateId"
        onChange={handleSourceTemplateIdChange}
      />

      <Form.YesNo name="useTemplate" />

      {useTemplate && (
        <Form.Select name="sourceTemplateId" options={options} required />
      )}
    </>
  );
}
