import { DATE_FORMAT_MOMENT } from '@/platform';
import { Button, Col, DatePicker, Divider, Row, Space } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const SEPARATOR = '///';

export function _DateTimeRangeFilter(props: FilterDropdownProps) {
  const { t } = useTranslation();
  const { setSelectedKeys, visible, confirm } = props;

  const [startDate, setStartDate] = useState<moment.Moment | null>(null);
  const [endDate, setEndDate] = useState<moment.Moment | null>(null);

  function reset() {
    setStartDate(null);
    setEndDate(null);
  }

  useEffect(() => {
    if (visible) {
      return;
    }

    const exists = !!startDate || !!endDate;
    const startValue = startDate?.startOf('day').toISOString() ?? '';
    const endValue = endDate?.startOf('day').toISOString() ?? '';
    const result = startValue + SEPARATOR + endValue;

    setSelectedKeys(exists ? [result] : []);
    confirm({ closeDropdown: false });

    // eslint-disable-next-line
  }, [visible]);

  return (
    <div>
      <Space style={{ padding: 8 }}>
        <DatePicker
          format={DATE_FORMAT_MOMENT}
          value={startDate}
          onChange={setStartDate}
        />
        <DatePicker
          format={DATE_FORMAT_MOMENT}
          value={endDate}
          onChange={setEndDate}
        />
      </Space>
      <Divider style={{ margin: 0 }} />
      <Row justify="space-between" style={{ padding: 8 }}>
        <Col>
          <Button onClick={reset} size="small" type="link">
            {t('reset')}
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => confirm({ closeDropdown: true })}
            size="small"
            type="primary"
          >
            {t('ok')}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

function onFilter<T>(filterValue: string | number | boolean, recordValue: T) {
  const [start, end] = filterValue.toString().split(SEPARATOR);
  const startDate = isEmpty(start) ? null : moment(start).startOf('day');
  const endDate = isEmpty(end) ? null : moment(end).endOf('day');
  const recordDateValue = recordValue ? moment(recordValue) : null;

  if (!recordDateValue) {
    return false;
  }

  const result = !startDate || recordDateValue >= startDate;
  return result && (!endDate || recordDateValue <= endDate);
}

export const DateTimeRangeFilter = Object.assign(_DateTimeRangeFilter, {
  onFilter,
});
