import { rtkq, RtkqRequest } from '../rtkq';
import {
  AddPersonalizationItemDto,
  PersonalizationItemDto,
  UpdatePersonalizationItemDto,
  personalizationsHttp,
  RoomState,
  AddExistingItemDto,
  UpdateExistingItemDto,
  ExistingBaseItemDto,
  AvailableItemDto,
} from '@/core';
import { api } from '../api';

export type RoomPersonalizationItemState = PersonalizationItemDto;
export type RoomPersonalizationAvailableItemState = AvailableItemDto;
export type AddRoomPersonalizationItemArgs = AddPersonalizationItemDto;
export type UpdateRoomPersonalizationItemArgs = UpdatePersonalizationItemDto & {
  categoryId: string;
};
export type AddExistingItemArgs = AddExistingItemDto;
export type UpdateExistingItemArgs = UpdateExistingItemDto;
export type ExistingBaseItemState = ExistingBaseItemDto;

export const roomPersonalizationApi = api.injectEndpoints({
  endpoints: (build) => ({
    addRoomPersonalizationItem: build.mutation<
      string,
      RtkqRequest<AddRoomPersonalizationItemArgs>
    >({
      queryFn: async (value) => {
        return rtkq(value).exec(() => personalizationsHttp.create(value));
      },
      invalidatesTags: (_, __, args) => {
        return [
          { type: 'personalizationItem-category', id: args.categoryId },
          { type: 'personalizationItem', id: 'list' },
          { type: 'personalizationStep-finalized', id: args.stepId },
        ];
      },
    }),

    updateRoomPersonalizationItem: build.mutation<
      void,
      RtkqRequest<UpdateRoomPersonalizationItemArgs>
    >({
      queryFn: async (args) => {
        const { categoryId, ...dto } = args;
        return rtkq(args).exec(() => personalizationsHttp.update(dto));
      },
      invalidatesTags: (_, __, args) => {
        return [
          { type: 'personalizationItem-category', id: args.categoryId },
          { type: 'personalizationItem', id: args.id },
        ];
      },
    }),

    deleteRoomPersonalizationItem: build.mutation<
      void,
      RtkqRequest<{ id: string; categoryId: string; stepId: string }>
    >({
      queryFn: async (args) => {
        const { id } = args;
        return rtkq(args).exec(() => personalizationsHttp.unlist(id));
      },
      invalidatesTags: (_, __, args) => {
        return [
          { type: 'personalizationItem-category', id: args.categoryId },
          { type: 'personalizationItem', id: args.id },
          { type: 'personalization-item-room', id: 'list' },
          { type: 'personalizationStep-finalized', id: args.stepId },
        ];
      },
    }),

    getNotFinalizedRoomPersonalizationRoomIdsList: build.query<
      (string | null)[],
      RtkqRequest<{
        communityId: string;
        projectId: string;
        homeId: string;
        stepId: string;
      }>
    >({
      queryFn: async (args) => {
        return await rtkq(args).exec(
          async () =>
            await personalizationsHttp.getNotFinalizedRoomIdsAll(
              args.communityId,
              args.projectId,
              args.homeId,
              args.stepId,
            ),
        );
      },
      providesTags: (_, __, { stepId }) => [
        { type: 'personalizationStep-finalized', id: stepId },
      ],
    }),

    getRoomPersonalizationAvailableItems: build.query<
      RoomPersonalizationAvailableItemState[],
      RtkqRequest<{
        homeId: string;
        projectId: string;
        categoryId: string;
        roomId?: string;
        stepId?: string;
      }>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() =>
          personalizationsHttp.getAvailableItems({
            ...args,
            floorplanPersonalization: false,
          }),
        );
      },
      providesTags: (_, __, args) => [
        { type: 'personalizationItem-category', id: 'list' },
        {
          type: 'personalizationItem-category',
          id: args.categoryId,
        },
      ],
    }),

    getPersonalizationRoomsList: build.query<
      RoomState[],
      RtkqRequest<{ homeId: string; projectId: string }>
    >({
      queryFn: async (args) => {
        return await rtkq(args).exec(() =>
          personalizationsHttp.getPersonalizationRooms(args),
        );
      },
      providesTags: (result = []) => [
        { type: 'room', id: 'list' },
        { type: 'personalization-item-room', id: 'list' },
        ...result.map(({ id }) => ({
          type: 'personalization-item-room' as const,
          id,
        })),
      ],
    }),

    keepExisting: build.mutation<string, RtkqRequest<AddExistingItemArgs>>({
      queryFn: async (value) => {
        return rtkq(value).exec(() => personalizationsHttp.keepExisting(value));
      },
      invalidatesTags: (id, __, args) => {
        return [
          { type: 'personalizationItem-category', id: args.categoryId },
          { type: 'existing-item', id: 'list' },
          { type: 'existing-item', id: id },
          { type: 'personalizationStep-finalized', id: args.stepId },
          { type: 'special-request', id: args.stepId },
        ];
      },
    }),

    getCategoryExistingItem: build.query<
      ExistingBaseItemState,
      RtkqRequest<{
        homeId: string;
        projectId: string;
        categoryId: string;
        stepId?: string;
        roomId?: string;
      }>
    >({
      queryFn: async (args) => {
        return await rtkq(args).exec(() =>
          personalizationsHttp.getExistingItem(args),
        );
      },
      providesTags: (result) => [
        { type: 'room', id: 'list' },
        { type: 'existing-item', id: 'list' },
        { type: 'existing-item', id: result?.id },
      ],
    }),

    updateExistingItem: build.mutation<
      void,
      RtkqRequest<UpdateExistingItemArgs>
    >({
      queryFn: async (args) => {
        return rtkq(args).exec(() =>
          personalizationsHttp.updateExistingItem(args),
        );
      },
      invalidatesTags: (_, __, args) => {
        return [{ type: 'existing-item', id: args.id }];
      },
    }),

    unkeepExistingItem: build.mutation<
      void,
      RtkqRequest<{ id: string; categoryId: string; stepId: string }>
    >({
      queryFn: async (args) => {
        return rtkq(args).exec(() =>
          personalizationsHttp.unkeepExistingItem(args),
        );
      },
      invalidatesTags: (_, __, args) => {
        return [
          { type: 'personalizationItem-category', id: args.categoryId },
          { type: 'existing-item', id: 'list' },
          { type: 'existing-item', id: args.id },
          { type: 'personalizationStep-finalized', id: args.stepId },
        ];
      },
    }),
  }),
});

export const {
  useAddRoomPersonalizationItemMutation,
  useUpdateRoomPersonalizationItemMutation,
  useDeleteRoomPersonalizationItemMutation,
  useGetNotFinalizedRoomPersonalizationRoomIdsListQuery,
  useGetRoomPersonalizationAvailableItemsQuery,
  useGetPersonalizationRoomsListQuery,
  useKeepExistingMutation,
  useUpdateExistingItemMutation,
  useUnkeepExistingItemMutation,
  useGetCategoryExistingItemQuery,
} = roomPersonalizationApi;
