import {
  LookupSource,
  LookupOption,
  createEntireLoadLookupSource,
} from '@/components';
import {
  ActivityUserState,
  RtkqSpin,
  useLazyGetActivityUsersQuery,
} from '@/redux';
import { useMemo } from 'react';

function map(value: ActivityUserState): LookupOption {
  return {
    label: `${value.firstName} ${value.lastName}`,
    value: value.id,
  };
}

export function useActivityUserLookupSource(): LookupSource {
  const [fetchList] = useLazyGetActivityUsersQuery();

  return useMemo(() => {
    return createEntireLoadLookupSource({
      initial: (id: string) =>
        fetchList(
          {
            [RtkqSpin]: false,
          },
          true,
        )
          .unwrap()
          .then((values) => map(values.find((x) => x.id === id)!)),
      load: () =>
        fetchList(
          {
            [RtkqSpin]: false,
          },
          true,
        )
          .unwrap()
          .then((values) => values.map(map)),
    });
  }, [fetchList]);
}
