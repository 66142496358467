import { AddTaskArgs, useAddTaskMutation } from '@/redux';
import { useMemo, useState } from 'react';
import { AddButton, notify } from '@/components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TaskDetailsModal, useProjectContext } from '@/views';

interface Props {
  developScopeItemId: string;
  className?: string;
  projectId: string;
  homeId: string;
}

function useValue(props: Props): AddTaskArgs {
  const { projectId, homeId, developScopeItemId } = props;

  return useMemo(
    () => ({
      projectId: projectId!,
      name: null!,
      homeId: homeId!,
      startDate: null!,
      endDate: null!,
      responsiblePerson: null!,
      estimatedCost: null!,
      description: null!,
      status: null!,
      developScopeItemId: developScopeItemId!,
    }),
    [developScopeItemId, homeId, projectId],
  );
}

const useSubmit = () => {
  const [add] = useAddTaskMutation();
  const push = useNavigate();

  return (addArgs: AddTaskArgs) => {
    add(addArgs)
      .unwrap()
      .then((id) => {
        notify.success.t('tasks.added');
        push(`/tasks/${id}`);
      });
  };
};

export function AssignTaskToDevelopScopeButton(props: Props) {
  const submit = useSubmit();
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const { isActive } = useProjectContext();
  const { className } = props;

  const initialValue: AddTaskArgs = useValue(props);

  if (!isActive) return null;

  return (
    <div className={className}>
      <AddButton ghost onClick={() => setOpenModal(true)}>
        {t('tasks.details.addButton')}
      </AddButton>
      {openModal && (
        <TaskDetailsModal
          title={t('tasks.details.addTitle')}
          buttonSaveName={t('tasks.details.add')}
          onSubmit={submit}
          value={initialValue}
          onClose={() => setOpenModal(false)}
          homeDisabled
        />
      )}
    </div>
  );
}
