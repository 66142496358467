import { Form, notify, useAppFindFormik } from '@/components';
import { useCallback } from 'react';
import * as Yup from 'yup';
import i18next from 'i18next';
import { useChangePasswordMutation } from '@/redux';
import { useTranslation } from 'react-i18next';

type FormValue = OmitIndex<Yup.InferType<typeof schema>>;

const schema = Yup.object().shape({
  current: Yup.string().nullable().required(),
  new: Yup.string()
    .password()
    .nullable()
    .required()
    .notOneOf(
      [Yup.ref('current'), null],
      i18next.t('users.passwordChange.passwordCurrentMatchError'),
    ),
  newConfirmation: Yup.string()
    .oneOf(
      [Yup.ref('new'), null],
      i18next.t('users.passwordChange.passwordConfirmationMatchError'),
    )
    .nullable()
    .required(),
});

const usePanelState = (props: ChangePasswordPanelProps) => {
  const [changePassword] = useChangePasswordMutation();
  const form = useAppFindFormik('password-change');
  const { onSubmit } = props;

  const submit = useCallback(
    async (value: FormValue) => {
      await changePassword(value as FormValue)
        .unwrap()
        .then(() => notify.success.t('users.passwordChange.success'))
        .then(() => onSubmit && onSubmit());
      form.current!.resetForm();
    },
    [changePassword, form, onSubmit],
  );

  return { submit };
};

const INITIAL_VALUE: FormValue = {
  current: null!,
  new: null!,
  newConfirmation: null!,
};

interface ChangePasswordPanelProps {
  onSubmit?: () => void;
}

export function ChangePasswordPanel(props: ChangePasswordPanelProps) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'users.passwordChange',
  });

  const { submit } = usePanelState(props);

  return (
    <div>
      <Form.Formik<FormValue>
        uid="password-change"
        i18n="users.passwordChange"
        initialValues={{ ...INITIAL_VALUE }}
        validationSchema={schema}
        onSubmit={submit}
      >
        <Form.Input name="current" type="password" required />
        <div className="mb-3">
          <Form.Input
            name="new"
            type="password"
            required
            helpMessage={t('requirenments')}
          />
        </div>

        <Form.Input name="newConfirmation" type="password" required />
      </Form.Formik>
    </div>
  );
}
