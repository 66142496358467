import { Form } from '@/components';
import { useHomeLookupSource } from '@/views';

interface HomeInputProps {
  communityId: string;
  projectId: string;
  name: string;
}

export function HomeInput(props: HomeInputProps){
  const { communityId, name, projectId } = props
  const homeDataSource = useHomeLookupSource(communityId, projectId);
  return (
    <Form.AsyncSelect
      name={`${name}.homeId`}
      dataSource={homeDataSource}
      label={false}
      />
  )
}