import { AddButton, notify } from '@/components';
import { useCreateProjectMutation } from '@/redux';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  INITIAL_PROJECT_DETAILS_FORM_VALUE,
  ProjectDetailsFormValue,
  ProjectDetailsFormVisibilityProps,
} from './ProjectDetailsForm';
import { ProjectDetailsModal } from './ProjectDetailsModal';
import { CodedException } from '@/core';
import { RtkqNotifyError } from '@/redux/rtkq';

export interface AddProjectButtonProps
  extends ProjectDetailsFormVisibilityProps {
  clientId?: string;
  communityId?: string;
}

function useValue(props: AddProjectButtonProps) {
  const { clientId, communityId } = props;

  return useMemo(
    () => ({
      ...INITIAL_PROJECT_DETAILS_FORM_VALUE,
      clientId: clientId!,
      communityId: communityId!,
    }),
    [clientId, communityId],
  );
}

function useHandleSave(setDisplay: (value: boolean) => any) {
  const [create] = useCreateProjectMutation();

  return useCallback(
    (value: ProjectDetailsFormValue) => {
      const handleError = (error: any) => {
        const e = CodedException.from(error);
        if (e.code === 'INV.SUBSCRIPTION.PM_LITE_PROJECTS.FORBIDDEN') {
          notify.errorWithFormatting.coded(error);
        } else {
          notify.error.coded(error);
        }
      };

      create({ ...value, [RtkqNotifyError]: false })
        .unwrap()
        .then(() => notify.success.t('projects.added'))
        .then(() => setDisplay(false))
        .catch(handleError);
    },
    [create, setDisplay],
  );
}

export function AddProjectButton(props: AddProjectButtonProps) {
  const { t } = useTranslation();
  const { noCommunity } = props;
  const [display, setDisplay] = useState(false);
  const handleSave = useHandleSave(setDisplay);
  const value = useValue(props);

  return (
    <>
      <AddButton onClick={() => setDisplay(true)}>
        {t('projects.addBtnText')}
      </AddButton>
      {display && (
        <ProjectDetailsModal
          title={t('projects.details.addTitle')}
          saveBtnText={t('projects.details.addBtnText')}
          value={value}
          onSave={handleSave}
          onCancel={() => setDisplay(false)}
          noCommunity={noCommunity === true}
        />
      )}
    </>
  );
}
