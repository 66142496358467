import { Button, Dropdown, Space } from 'antd';
import { useEffect, useState } from 'react';
import { ColumnState } from './ColumnMenuItem';
import { useTranslation } from 'react-i18next';
import { ColumnsMenu } from './ColumnsMenu';
import { Icon } from '@/components/Icon';

interface ColumnsDropdownProps {
  value: ColumnState[];
  onChange: (columns: ColumnState[]) => any;
}

export function ColumnsDropdown(props: ColumnsDropdownProps) {
  const { value: initialColumns, onChange } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [columns, setColumns] = useState<ColumnState[]>(initialColumns);

  const { t } = useTranslation();

  useEffect(() => {
    setColumns(initialColumns);
  }, [initialColumns]);

  const handleColumnChange = (value: ColumnState) => {
    const newColumns = columns.map((x) => (x.key === value.key ? value : x));
    setColumns(newColumns);
  };

  const handleSaveClick = (columns: ColumnState[]) => {
    setVisible(false);
    onChange(columns);
  };

  const handleVisibleChange = (value: boolean) => {
    setVisible(value);

    if (!value) {
      setColumns(initialColumns);
    }
  };

  return (
    <Dropdown
      className="mb-3"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      trigger={['click']}
      overlay={
        <ColumnsMenu
          columns={columns}
          onSave={handleSaveClick}
          onChange={handleColumnChange}
        />
      }
    >
      <Button>
        <Space onClick={() => setVisible(!visible)}>
          {t('columns')}
          <Icon type="arrow-down" />
        </Space>
      </Button>
    </Dropdown>
  );
}
