import {
  Table,
  useDefaultTablePaging,
  useFilters,
  useSorting,
} from '@/components';
import { useEditLegalLanguageAction } from './useEditLegalLanguageAction';
import { useCustomLegalLanguageColumns } from './useCustomLegalLanguageColumns';
import { useMemo } from 'react';
import { useDeleteLegalLanguageAction } from './useDeleteLegalLanguageAction';
import { CustomLegalLanguageFilterParams } from '@/core';
import {
  CustomLegalLanguageState,
  useGetCustomLegalLanguageListQuery,
} from '@/redux';

export function CustomLegalLanguageListPanel() {
  const { setTotal, paging, params } = useDefaultTablePaging();
  const {
    filters,
    onFiltersChange,
    resetFilters,
    stringFilterOptions,
    setStringFilterOptions,
  } = useFilters<CustomLegalLanguageFilterParams>(['client', 'community']);
  const { sorting, onSortingChange } = useSorting<CustomLegalLanguageState>();

  const { data } = useGetCustomLegalLanguageListQuery({
    paging: params,
    filters,
    sorting,
  });

  const languages = useMemo(() => {
    if (data) {
      setStringFilterOptions(data.stringFilterOptions);
      setTotal(data.total);
    }
    return data?.items || [];
  }, [data, setStringFilterOptions, setTotal]);

  const { openEdit, editModal } = useEditLegalLanguageAction(params);
  const { handleDelete } = useDeleteLegalLanguageAction();
  const columns = useCustomLegalLanguageColumns(
    openEdit,
    handleDelete,
    languages,
    stringFilterOptions,
  );

  return (
    <>
      <Table<CustomLegalLanguageState>
        name={'customLegalLanguage'}
        columns={columns}
        dataSource={languages}
        rowKey={(row) => row.id}
        hideDefaultActions
        pagination={paging}
        onChange={(_, filters, sorting) => {
          onFiltersChange(filters);
          onSortingChange(sorting);
        }}
        onFiltersReset={resetFilters}
      />
      {editModal}
    </>
  );
}
