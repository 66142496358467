import { useTranslation } from 'react-i18next';

interface Props {
  value: { isActive?: boolean };
}

export function UserStatusBadge({ value }: Props) {
  const { t } = useTranslation();
  const { isActive } = value;

  return (
    <>{isActive ? t(`users.status.active`) : t(`users.status.deactivated`)}</>
  );
}
