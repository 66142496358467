import { EnumValue, enumObject } from '@/utils';
import { CommonFilterType } from '..';

const SIGNATURE_TYPE = ['E-Signature', 'SignatureLine', 'None'] as const;
export type SignatureType = EnumValue<typeof SIGNATURE_TYPE>;
// eslint-disable-next-line
export const SignatureType = enumObject('SignatureType', SIGNATURE_TYPE);

export interface SignatureDto {
  clientId: string;
  clientName: string;
  communityId: string;
  communityName: string;
  type: SignatureType;
}

export type UpdateSignatureDto = Pick<
  SignatureDto,
  'clientId' | 'communityId' | 'type'
>;

export interface SignatureFilterParams extends CommonFilterType {
  community?: string[];
  client?: string[];
  signature?: SignatureType[];
}
