import { enumObject, EnumValue } from '@/utils';

const COMMUNITY_ROLE_VALUES = [
  'Manage',
  'Construction',
  'Personalization',
  'ProjectManagement',
  'Designer',
  'View',
  'Reports',
] as const;

export type CommunityRole = EnumValue<typeof COMMUNITY_ROLE_VALUES>;

// eslint-disable-next-line
export const CommunityRole = enumObject('CommunityRole', COMMUNITY_ROLE_VALUES);
