import { dataNodeUtils } from '../Forms';
import { TreeViewEdit, TreeViewEditTexts } from './TreeViewEdit';
import { TreeViewRead, TreeViewReadTexts } from './TreeViewRead';
import { useEffect, useMemo, useState } from 'react';
import { DataNode } from 'antd/lib/tree';

export type TreeViewTexts = TreeViewReadTexts & TreeViewEditTexts;

interface Props<T> {
  value: T;
  uid: string;
  texts: TreeViewTexts;
  keyBy: Extract<keyof T, string>;
  titleBy: Extract<keyof T, string>;
  childrenBy: Extract<keyof T, string>;
  onDelete?: (value: T) => any;
  onCancel?: (value: T) => any;
  onSubmit: (value: T) => any;
  readonly?: boolean;
}

function useDataNode<T>(props: Props<T>) {
  const { value, keyBy, titleBy, childrenBy } = props;
  return useMemo(
    () => dataNodeUtils.convertFrom(value, keyBy, titleBy, childrenBy),
    [value, keyBy, titleBy, childrenBy],
  );
}

export function TreeView<T>(props: Props<T>) {
  const {
    value,
    uid,
    texts,
    keyBy,
    titleBy,
    childrenBy,
    onDelete,
    onCancel,
    onSubmit,
    readonly,
  } = props;
  const node = useDataNode(props);
  const [edit, setEdit] = useState<DataNode>();
  const isEditMode = dataNodeUtils.isNew(node) || edit;

  function handleCancel() {
    setEdit(undefined);
    onCancel && onCancel(value);
  }

  function handleSubmit(item: DataNode) {
    onSubmit(dataNodeUtils.convertTo(item, keyBy, titleBy, childrenBy));
    setEdit(undefined);
  }

  useEffect(() => {
    if (edit !== node) {
      setEdit(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node]);

  return (
    <div>
      {isEditMode ? (
        <TreeViewEdit
          value={node}
          uid={uid}
          texts={texts}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      ) : (
        <TreeViewRead
          readonly={readonly}
          value={node}
          canEdit={!dataNodeUtils.isNew(node)}
          texts={texts}
          onEdit={() => setEdit(node)}
          onDelete={() => onDelete && onDelete(value)}
        />
      )}
    </div>
  );
}
