import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const FormI18nContext = React.createContext<string | undefined>(
  undefined,
);

export function useFormI18nPrefix() {
  return useContext(FormI18nContext);
}

export function useFormLabel(name: string, label?: React.ReactNode | false) {
  const [t, i18n] = useTranslation();
  let i18nPrefix = useFormI18nPrefix();

  i18nPrefix = i18nPrefix ? `${i18nPrefix}.` : '';
  name = name.replaceAll(/\[\d+\]/g, '');

  if (label === false) {
    return undefined;
  }

  if (label) {
    return label;
  }

  if (i18n.exists(`${i18nPrefix}${name}`)) {
    return t(`${i18nPrefix}${name}`);
  }

  if (i18n.exists(name)) {
    return t(name);
  }

  return t(`${i18nPrefix}${name}`);
}
