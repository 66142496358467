import { useBool } from '@/utils';
import { Col, Row, Space, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { ItemListTabPanel } from '..';
import { ItemListTabPanelGridView } from './ItemListTabPanelGridView';

interface Props {
  communityId: string;
  className?: string;
}

export function ItemListPanel(props: Props) {
  const { communityId, className } = props;
  const { t } = useTranslation();
  const [tableView, , toggleTableView] = useBool(false);

  return (
    <>
      <Row justify="end">
        <Col>
          <Space className={className}>
            <Switch checked={tableView} onChange={toggleTableView} />
            {t('items.gridView')}
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {!tableView && <ItemListTabPanel communityId={communityId} />}
          {tableView && <ItemListTabPanelGridView communityId={communityId} />}
        </Col>
      </Row>
    </>
  );
}
