import { useTranslation } from 'react-i18next';
import { CommunityDetailsPanel, CommunityDetailsTabsPanel } from '@/views';
import { Icon, Page } from '@/components';
import { useGetCommunityDetailsQuery } from '@/redux';
import { useParams } from 'react-router-dom';

const useId = () => {
  const params = useParams<'communityId'>();
  return params.communityId!;
};

export function CommunityDetailsPage() {
  const { t } = useTranslation();
  const id = useId();

  const { data } = useGetCommunityDetailsQuery({ id });
  const title = data?.name ?? t('fetchFallbackTitle');

  return (
    <Page htmlTitle={title}>
      <Page.Paths>
        <Page.Path href="/communities">{t('communities.title')}</Page.Path>
        <Page.Path>{title}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card
          collapsible
          defaultCollapsed
          title={title}
          icon={<Icon type="map-pin" />}
          color="#814A93"
        >
          <CommunityDetailsPanel id={id} />
        </Page.Card>

        <Page.TabCard>
          <CommunityDetailsTabsPanel communityId={id} />
        </Page.TabCard>
      </Page.Body>
    </Page>
  );
}
