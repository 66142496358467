import { AddPathToProjectArgs, useAddPathToProjectMutation } from '@/redux';
import { Form, FormRadioOption, notify } from '@/components';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import {
  useCustomPathTemplateLookupSource,
  useDefaultPathTemplateLookupSource,
} from '@/views/PathTemplates.Common';
import { Button, Space } from 'antd';
import { useFormikContext } from 'formik';
import { nameOf } from '@/utils';
import * as Yup from 'yup';

interface Props {
  projectId: string;
  communityId: string;
  onAdded: () => any;
}

interface FormValue extends AddPathToProjectArgs {
  isCustom: boolean;
}

function useValue(props: Props): FormValue {
  const { projectId } = props;

  return useMemo(
    () => ({
      isCustom: true,
      pathTemplateId: null!,
      projectId: projectId!,
    }),
    [projectId],
  );
}

const useSubmit = (props: Props) => {
  const [add] = useAddPathToProjectMutation();
  const { onAdded } = props;

  return (args: AddPathToProjectArgs) =>
    add(args)
      .unwrap()
      .then(() => {
        notify.success.t('projects.path.added');
        onAdded();
      });
};

const OPTIONS: FormRadioOption<boolean>[] = [
  { value: true, label: i18next.t<string>('projects.path.custom') },
  { value: false, label: i18next.t<string>('projects.path.default') },
];

const schema = Yup.object().shape({
  pathTemplateId: Yup.string().field().nullable().required(),
  isCustom: Yup.boolean(),
});

function FormContent(props: Props) {
  const { communityId } = props;
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<FormValue>();
  const customPathTemplatesDataSource =
    useCustomPathTemplateLookupSource(communityId);
  const defaultPathTemplatesDataSource = useDefaultPathTemplateLookupSource();

  useEffect(() => {
    setFieldValue(`${nameOf<FormValue>('pathTemplateId')}`, null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.isCustom]);

  return (
    <>
      <Form.Radio
        name="isCustom"
        options={OPTIONS}
        label={t('projects.path.kind')}
        inline={false}
      />
      {values.isCustom && (
        <Form.AsyncSelect
          name="pathTemplateId"
          dataSource={customPathTemplatesDataSource}
          required
          preload
          label={t('projects.details.selectPathTemplate')}
        />
      )}
      {!values.isCustom && (
        <Form.AsyncSelect
          name="pathTemplateId"
          dataSource={defaultPathTemplatesDataSource}
          required
          preload
          label={t('projects.details.selectPathTemplate')}
        />
      )}
    </>
  );
}

export const AddPathToProjectPanel = (props: Props) => {
  const { onAdded } = props;
  const initialValues = useValue(props);
  const submit = useSubmit(props);
  const { t } = useTranslation();

  return (
    <Form.Formik<FormValue>
      uid="project-path"
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={schema}
    >
      <FormContent {...props} />
      <Space>
        <Button type="default" onClick={onAdded}>
          {t('cancel')}
        </Button>
        <Form.Submit uid="project-path" key="submit" type="primary">
          {t('save')}
        </Form.Submit>
      </Space>
    </Form.Formik>
  );
};
