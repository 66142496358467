import styles from './Overlay.module.scss';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const VISIBLE_CLASSNAME = 'overlay-visible';

export type OverlayAction =
  | 'close'
  | {
      icon: React.ReactNode;
      title?: string;
      onClick: () => any;
    };

export interface OverlayProps {
  actions: OverlayAction[];
  visible: boolean;
  onClose?: () => any;
  children: React.ReactNode;
}

export function Overlay(props: OverlayProps) {
  const { actions, visible, onClose, children } = props;
  const { t } = useTranslation();

  useEffect(() => {
    visible && document.body.classList.add(VISIBLE_CLASSNAME);
    !visible && document.body.classList.remove(VISIBLE_CLASSNAME);

    return () => {
      document.body.classList.remove(VISIBLE_CLASSNAME);
    };
  }, [visible]);

  if (!visible) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.actions}>
        {actions.map((x, index) =>
          x === 'close' ? (
            <span key="close" onClick={onClose} title={t('close')}>
              <CloseOutlined />
            </span>
          ) : (
            <span key={index} onClick={x.onClick} title={x.title}>
              {x.icon}
            </span>
          ),
        )}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
}
