import { enumObject, EnumValue } from '@/utils';
import { CategoryLookupDto } from '../http.categories';

const ITEM_MENU_ITEM_TYPE_VALUES = ['Standard', 'Upgrade'] as const;
export type ItemMenuItemType = EnumValue<typeof ITEM_MENU_ITEM_TYPE_VALUES>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ItemMenuItemType = enumObject(
  'ItemMenuItemType',
  ITEM_MENU_ITEM_TYPE_VALUES,
);

const ITEM_MENU_SUBOPTION_TYPE_VALUES = ['Standard', 'Upgrade'] as const;
export type ItemMenuSuboptionType = EnumValue<
  typeof ITEM_MENU_SUBOPTION_TYPE_VALUES
>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ItemMenuSuboptionType = enumObject(
  'ItemMenuSuboptionType',
  ITEM_MENU_SUBOPTION_TYPE_VALUES,
);

export function resolveDefaultSuboptionType(
  itemType: ItemMenuItemType,
): ItemMenuSuboptionType {
  switch (itemType) {
    case 'Standard':
      return 'Standard';

    case 'Upgrade':
      return 'Upgrade';

    default:
      throw new Error(
        `Unable to resolve suboption type for item type ${itemType}`,
      );
  }
}

const ALLOWED_SUBOPTION_TYPES_BY_ITEM_TYPE: Record<
  ItemMenuItemType,
  ItemMenuSuboptionType[]
> = {
  Standard: ['Standard'],
  Upgrade: ['Upgrade'],
};

export function isAllowedSuboptionType(
  itemType: ItemMenuItemType,
  suboptionType: ItemMenuSuboptionType,
): boolean {
  return (
    ALLOWED_SUBOPTION_TYPES_BY_ITEM_TYPE[itemType]?.includes(suboptionType) ===
    true
  );
}

export interface ItemMenuDto {
  id: string;
  name: string;
  items: SelectedItemMenuItemDto[];
}

export interface UpdateItemMenuDto {
  id: string;
  name: string;
  items: SelectedItemMenuItemDto[];
}

export interface SelectedItemMenuItemDto {
  itemId: string;
  type: ItemMenuItemType;
  suboptions: SelectedItemMenuItemSuboptionsDto;
  standardCredit: boolean;
}

export interface ItemMenuSuboptionValue {
  id: string;
  type: ItemMenuSuboptionType;
}

export interface SelectedItemMenuItemSuboptionsDto {
  all: boolean;
  values?: ItemMenuSuboptionValue[];
}

export type AddItemMenuDto = Omit<UpdateItemMenuDto, 'id'> & {
  communityId: string;
};

export interface ItemMenuDetailsDto {
  id: string;
  communityId: string;
  name: string;
  items: ItemMenuItemDetailsDto[];
}

export interface ItemMenuItemDetailsDto {
  id: string;
  name: string;
  type: ItemMenuItemType;
  suboptions: ItemMenuSuboptionValue[];
  categoryPath: CategoryLookupDto[];
  standardCredit: boolean;
}

export type ItemMenuLookupDto = ItemMenuDto;

export interface ItemMenuItemDto {
  id: string;
  type: ItemMenuItemType;
  standardCredit: boolean;
  categoryId: string;
}
