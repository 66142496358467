import { useField } from 'formik';
import { useCallback, useEffect } from 'react';
import {
  CreatableAsyncSelect,
  CreatableAsyncSelectProps,
} from '../AsyncSelect/CreatableAsyncSelect';
import { LookupOption } from '../lookups';
import { FormsGroup } from './FormsGroup';
import { useFormLabel } from './FormsI18nContext';

export interface FormsCreatableAsyncSelectProps
  extends Omit<CreatableAsyncSelectProps, 'value' | 'onChange' | 'onBlur'> {
  name: string;
  label?: React.ReactNode;
  required?: boolean;
}

function useHandleChange(props: FormsCreatableAsyncSelectProps) {
  const { name } = props;
  const [, , { setValue, setTouched }] = useField(name);

  return useCallback(
    (value: string | null, isInitialValue?: boolean) => {
      !isInitialValue && setTouched(true);
      setValue(value == null ? null : value, true);
    },
    [setValue, setTouched],
  );
}

export function FormsCreatableAsyncSelect(
  props: FormsCreatableAsyncSelectProps,
) {
  const {
    name,
    preloadInitial,
    label: labelOverride,
    required,
    ...asyncSelectProps
  } = props;
  const [{ value }, , { setTouched }] = useField(name);
  const label = useFormLabel(name, labelOverride);
  const handleChange = useHandleChange(props);
  const handleBlur = useCallback(() => setTouched(true, true), [setTouched]);

  useEffect(() => {
    handleChange((preloadInitial as LookupOption)?.value, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preloadInitial]);

  return (
    <FormsGroup label={label} name={name} required={required}>
      <CreatableAsyncSelect
        {...asyncSelectProps}
        preloadInitial={preloadInitial}
        value={value}
        onChange={(value) => handleChange(value ?? null)}
        onBlur={handleBlur}
      />
    </FormsGroup>
  );
}
