import { useGetProfileQuery } from '@/redux';
import { useTranslation } from 'react-i18next';
import { UserProfilePanel } from '@/views';
import { Page } from '@/components';

export function UserProfilePage() {
  const { t } = useTranslation();
  const fallbackTitle = t('fetchFallbackTitle');
  const { data, isLoading } = useGetProfileQuery();
  const name = [data?.firstName, data?.lastName].join(' ');
  const title = isLoading ? fallbackTitle : name;

  return (
    <Page htmlTitle={title}>
      <Page.Paths>
        <Page.Path>{title}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card className="p-4">
          <UserProfilePanel />
        </Page.Card>
      </Page.Body>
    </Page>
  );
}
