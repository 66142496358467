import { StyledRow, TextBox, spinner, DateTimeValue } from '@/components';
import { useAppActivity } from '@/core';
import { Activity } from '@webinex/activity';
import { Col } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityAction } from '../Activity/ActivityAction';
import { ActivityKind } from '../Activity/ActivityKind';

export interface ActivityDetailsPanelProps {
  id: string;
  onLoaded?: (activity: Activity) => void;
}

export function ActivityDetailsPanel(props: ActivityDetailsPanelProps) {
  const { id, onLoaded } = props;
  const { t } = useTranslation();
  const { state, fetch } = useAppActivity(id);
  const { value } = state;

  useEffect(() => {
    spinner.show();
    fetch().finally(spinner.hide);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    onLoaded && value && onLoaded(value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  if (value == null) return null;

  return (
    <div>
      <StyledRow>
        <Col span={12}>
          <TextBox label={t('activity.details.key')}>
            <ActivityKind>{value.kind}</ActivityKind>
          </TextBox>
        </Col>
        <Col span={12}>
          <TextBox label={t('activity.details.performedAt')}>
            <DateTimeValue value={value.performedAt} />
          </TextBox>
        </Col>
        <Col span={12}>
          <TextBox className="mt-2" label={t('activity.details.actions')}>
            <ActivityAction activity={value} />
          </TextBox>
        </Col>
        <Col span={12}>
          <TextBox className="mt-2" label={t('activity.details.user')}>
            {value.userName}
          </TextBox>
        </Col>
        {value.communityNumber && (
          <Col span={12}>
            <TextBox
              className="mt-2"
              label={t('activity.details.communityNumber')}
            >
              {value.communityNumber}
            </TextBox>
          </Col>
        )}
        {value.homeNumber && (
          <Col span={12}>
            <TextBox className="mt-2" label={t('activity.details.homeNumber')}>
              {value.homeNumber}
            </TextBox>
          </Col>
        )}
        {value.projectNumber && (
          <Col span={24}>
            <TextBox
              className="mt-2"
              label={t('activity.details.projectNumber')}
            >
              {value.projectNumber}
            </TextBox>
          </Col>
        )}
        <Col span={24}>
          <TextBox className="mt-2" label={t('activity.details.values')}>
            <pre className="codeblock p-2 mt-2 rounded">
              {JSON.stringify(value.values, undefined, 2)}
            </pre>
          </TextBox>
        </Col>
      </StyledRow>
    </div>
  );
}
