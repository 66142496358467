import { Page } from '@/components';
import { NotificationsListPanel } from '@/views';
import { NotificationSettingsModal } from '@/views/Notifications.Last/NotificationSettingsModal';
import { SettingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function NotificationsListPage() {
  const { t } = useTranslation();
  const [modalState, setModalState] = useState(false);

  return (
    <Page htmlTitle={t('notifications.list.title')}>
      <Page.Paths>
        <Page.Path>{t('notifications.list.title')}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card
          title={
            <>
              {t('notifications.list.title')}
              <Button
                type="link"
                className="ms-2"
                icon={<SettingOutlined style={{ fontSize: '20px' }} />}
                onClick={() => setModalState(true)}
              ></Button>
            </>
          }
        >
          <NotificationsListPanel />
          <NotificationSettingsModal
            visible={modalState}
            onClose={() => setModalState(false)}
          />
        </Page.Card>
      </Page.Body>
    </Page>
  );
}
