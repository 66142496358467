import { EditIconButton } from '@/components';
import { useEditCommunityAction } from './useEditCommunityAction';

interface Props {
  id: string;
}

export function EditCommunityButton({ id }: Props) {
  const { openEdit, editModal } = useEditCommunityAction();

  return (
    <div>
      <EditIconButton onClick={() => openEdit(id)} />
      {editModal}
    </div>
  );
}
