import { Menu } from 'antd';
import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import styles from '../TabPanel.module.scss';

export interface TabPanelNavMenuProps<T extends Record<string, any>> {
  items: T[];
  selectedKey: string | undefined;
  onSelected: (key: string) => any;
  titleBy: (node: T) => any;
  keyBy: (node: T) => any;
  iconBy?: (node: T) => any;
}

function useItems<T>(props: TabPanelNavMenuProps<T>) {
  const { items, keyBy, titleBy, iconBy, onSelected } = props;

  return useMemo(() => {
    return items.map((item) => ({
      key: keyBy(item),
      label: titleBy(item),
      itemIcon: iconBy && (
        <span className={styles.TabItemIcon}>{iconBy(item)}</span>
      ),
      onClick: () => onSelected(keyBy(item)),
    }));
  }, [items, keyBy, titleBy, iconBy, onSelected]);
}

export function TabPanelNavMenu<T>(props: TabPanelNavMenuProps<T>) {
  const { selectedKey } = props;
  const items = useItems(props);
  const selectedKeys = selectedKey ? [selectedKey] : undefined;

  return (
    <Menu className="side-nav" selectedKeys={selectedKeys} items={items} />
  );
}

type UseTabPanelNavMenuArgs<T> = Omit<
  TabPanelNavMenuProps<T>,
  'selected' | 'selectedKey' | 'onSelected'
>;

export type UseTabMenuResult<T> = TabPanelNavMenuProps<T> & {
  select: (key: string | undefined) => any;
  selected: T | undefined;
};

export function useTabPanelNavMenuState<TItem>(
  args: UseTabPanelNavMenuArgs<TItem>,
): UseTabMenuResult<TItem> {
  const { items, titleBy, keyBy, iconBy } = args;
  let [selectedKey, setSelectedKey] = useState<string>();

  selectedKey = useMemo(() => {
    if (!selectedKey && isEmpty(items)) {
      return isEmpty(items) ? selectedKey : items?.at(0);
    }

    const item = items.find((x) => keyBy(x) === selectedKey) ?? items?.at(0);
    return item && keyBy(item);
  }, [items, selectedKey, keyBy]);

  const selected = useMemo(
    () => items.find((x) => keyBy(x) === selectedKey),
    [items, keyBy, selectedKey],
  );

  return useMemo(
    () => ({
      items,
      titleBy,
      iconBy,
      keyBy,
      selectedKey,
      selected,
      onSelected: setSelectedKey,
      select: setSelectedKey,
    }),
    [items, titleBy, iconBy, keyBy, selectedKey, selected],
  );
}
