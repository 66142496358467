import { TableColumnType } from '@/components';
import { RolesPermissions } from '@/core';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useRolesPermissionsColumns() {
  const { t } = useTranslation();

  return useMemo<TableColumnType<RolesPermissions>[]>(
    () => [
      {
        title: t('rolesPermissions.area'),
        key: 'area',
        render: (_, record) => record.area,
        width: 20,
      },
      {
        title: t('rolesPermissions.action'),
        key: 'action',
        render: (_, record) => record.action,
        width: 20,
      },
      {
        title: t('rolesPermissions.manageRole'),
        key: 'manageRole',
        render: (_, record) => record.manageRole,
      },
      {
        title: t('rolesPermissions.constructionRole'),
        key: 'constructionRole',
        render: (_, record) => record.constructionRole,
      },
      {
        title: t('rolesPermissions.personalizationRole'),
        key: 'personalizationRole',
        render: (_, record) => record.personalizationRole,
      },
      {
        title: t('rolesPermissions.projectManagementRole'),
        key: 'projectManagementRole',
        render: (_, record) => record.projectManagementRole,
      },
      {
        title: t('rolesPermissions.designerRole'),
        key: 'designerRole',
        render: (_, record) => record.designerRole,
      },
      {
        title: t('rolesPermissions.viewRole'),
        key: 'viewRole',
        render: (_, record) => record.viewRole,
      },
      {
        title: t('rolesPermissions.reportsRole'),
        key: 'reportsRole',
        render: (_, record) => record.reportsRole,
      },
    ],
    [t],
  );
}
