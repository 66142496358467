import {
  LookupSource,
  LookupOption,
  createEntireLoadLookupSource,
} from '@/components';
import {
  CategoryState,
  RtkqSpin,
  useLazyGetCategoriesQuery,
  useLazyGetPersonalizationCategoriesQuery,
} from '@/redux';
import { Space, Typography } from 'antd';
import { flatten } from 'lodash';
import { useMemo } from 'react';

const { Text } = Typography;

interface CategoryItem extends CategoryState {
  parent1?: CategoryState;
  parent2?: CategoryState;
}

function map(value: CategoryItem): LookupOption {
  const { name, id, parent1, parent2 } = value;
  const hasParent = parent1 || parent2;

  return {
    _search: [name, parent1?.name ?? '', parent2?.name ?? ''].join(' '),
    value: id,
    label: (
      <span>
        <div>{name}</div>
        {hasParent && (
          <div>
            <Text type="secondary">
              <Space split="/">
                {parent2?.name}
                {parent1?.name}
              </Space>
            </Text>
          </div>
        )}
      </span>
    ),
  };
}

function mapMany(values: CategoryState[], canAddChild?: boolean) {
  function project(
    value: CategoryState,
    path: CategoryState[],
  ): CategoryItem[] {
    const nextPath = [...path, value];
    const current = { ...value, parent1: path.at(0), parent2: path.at(1) };
    const children = value.children?.map((child) => project(child, nextPath));
    return flatten([current, ...children]);
  }

  const items = flatten(values.map((v) => project(v, [])));
  return (canAddChild ? items.filter((i) => !i.parent2) : items).map(map);
}

export function useCategoryLookupSource(options?: {
  communityId?: string;
  listed?: boolean;
  canAddChild?: boolean;
}): LookupSource {
  const [fetchList] = useLazyGetCategoriesQuery();

  return useMemo(() => {
    const entireSource = createEntireLoadLookupSource({
      initial: (id: string) =>
        fetchList({ ...options, [RtkqSpin]: false }, true)
          .unwrap()
          .then((values) => mapMany(values, options?.canAddChild))
          .then((values) => values.find((x) => x.value === id)!),
      load: () =>
        fetchList({ ...options, [RtkqSpin]: false }, true)
          .unwrap()
          .then((values) => mapMany(values, options?.canAddChild)),
    });

    return { ...entireSource, searchField: '_search' };
  }, [fetchList, options]);
}

export function usePersonalizationCategoryLookupSource(options: {
  homeId: string;
  projectId: string;
  stepId?: string;
  roomId?: string;
  floorplanPersonalization: boolean;
}): LookupSource {
  const [fetchList] = useLazyGetPersonalizationCategoriesQuery();

  return useMemo(() => {
    const entireSource = createEntireLoadLookupSource({
      initial: (id: string) =>
        fetchList({ ...options, [RtkqSpin]: false }, true)
          .unwrap()
          .then(mapMany)
          .then((values) => values.find((x) => x.value === id)!),
      load: () =>
        fetchList({ ...options, [RtkqSpin]: false }, true)
          .unwrap()
          .then(mapMany),
    });

    return { ...entireSource, searchField: '_search' };
  }, [fetchList, options]);
}
