import { DeleteIconButton, notify } from '@/components';
import { useDeleteItemMenuMutation } from '@/redux';
import { useTranslation } from 'react-i18next';

interface Props {
  id: string;
}

function useDeleteItemMenu(props: Props) {
  const { id } = props;
  const [deleteItemMenu] = useDeleteItemMenuMutation();

  return () =>
    deleteItemMenu({ id })
      .unwrap()
      .then(() => notify.success.t('itemMenus.details.deleted'));
}

export function DeleteItemMenuButton(props: Props) {
  const handleDelete = useDeleteItemMenu(props);
  const { t } = useTranslation();

  return (
    <DeleteIconButton
      confirm
      entityName={t('itemMenus.details.entityName')}
      title={t('delete')}
      onClick={handleDelete}
    />
  );
}
