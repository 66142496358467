import { Col } from 'antd';
import { Auth, FieldRow, Form } from '@/components';
import { GenerateTrendReportArgs } from '@/redux';
import { useClientLookupSource } from '../Clients.Common';
import { useFormikContext } from 'formik';
import { useReportingCommunitiesLookupSource } from '../Communities.Common';
import { useReportingProjectsLookupSource } from '../Projects.Common';
import { useReportingHomesLookupSource } from '../Homes.Common';
import { useEffect } from 'react';

export function FormGenerateTrendReport() {
  const { values, setFieldValue } = useFormikContext<GenerateTrendReportArgs>();
  const { clientId, communityId, projectIds } = values;

  const clientDataSource = useClientLookupSource();
  const communityDataSource = useReportingCommunitiesLookupSource(
    clientId ?? false,
  );
  const projectDataSource = useReportingProjectsLookupSource(
    false,
    communityId,
  );
  const homeDataSource = useReportingHomesLookupSource(communityId, projectIds);

  useEffect(() => {
    setFieldValue(`communityId`, undefined);
  }, [clientId, setFieldValue]);

  useEffect(() => {
    setFieldValue(`projectIds`, []);
  }, [communityId, setFieldValue]);

  useEffect(() => {
    setFieldValue(`homeIds`, undefined);
  }, [projectIds, setFieldValue]);

  return (
    <>
      <FieldRow>
        <Col span={12}>
          <Form.Date name="startDate" allowClear={false} />
        </Col>
        <Col span={12}>
          <Form.Date name="endDate" allowClear={false} />
        </Col>
      </FieldRow>
      <FieldRow>
        <Auth role="BFP_ADMIN">
          <Col span={12}>
            <Form.AsyncSelect
              dataSource={clientDataSource}
              name="clientId"
              required
            />
          </Col>
        </Auth>
        <Col span={12}>
          <Form.AsyncSelect
            key={clientId}
            dataSource={communityDataSource}
            name="communityId"
            required
          />
        </Col>
      </FieldRow>
      <FieldRow>
        <Col span={12}>
          <Form.AsyncSelect
            key={communityId}
            dataSource={projectDataSource}
            name="projectIds"
            mode="multiple"
          />
        </Col>
        <Col span={12}>
          <Form.AsyncSelect
            key={projectIds?.join()}
            dataSource={homeDataSource}
            name="homeIds"
            mode="multiple"
          />
        </Col>
      </FieldRow>
    </>
  );
}
