import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useFileUpload } from './useFileUpload';
import { useTranslation } from 'react-i18next';
import styles from './FileUpload.module.scss';
import { notify } from '@/components';
import { RcFile } from 'antd/es/upload';

export interface FileUploadProps {
  accept?: string;
  onChange: (value?: string | null) => any;
}

const beforeUpload = (file: RcFile, accept?: string) => {
  const types = accept?.split(/,| |, /);
  if (accept == null || types?.includes(file.type)) return;

  notify.error.t('uploadFile.invalidType');
  return Upload.LIST_IGNORE;
};

export function FileUpload(props: FileUploadProps) {
  const { accept, onChange } = props;
  const { t } = useTranslation();

  const { handleUpload, handleRemove } = useFileUpload(onChange);

  return (
    <span className={styles.fileUpload}>
      <Upload
        accept={accept}
        maxCount={1}
        customRequest={handleUpload}
        onRemove={handleRemove}
        beforeUpload={(file) => beforeUpload(file, accept)}
      >
        <Button className="w-100" icon={<UploadOutlined />}>
          {t('upload')}
        </Button>
      </Upload>
    </span>
  );
}
