import { notify } from '@/components';
import { AddExternalLinkDto } from '@/core';
import {
  useAddItemExternalLinkMutation,
  useDeleteItemExternalLinkMutation,
  useGetItemDetailsQuery,
} from '@/redux';
import { ExternalLinks } from '../Items.ExternalLinks/ExternalLinks';

const useExternalLinkSubmit = (itemId: string) => {
  const [add] = useAddItemExternalLinkMutation();

  return (args: AddExternalLinkDto) =>
    add({ ...args, itemId })
      .unwrap()
      .then(() => {
        notify.success.t('externalLinks.added');
      });
};

const useExternalLinkDelete = (itemId: string) => {
  const [remove] = useDeleteItemExternalLinkMutation();

  return (id: string) =>
    remove({ itemId, id })
      .unwrap()
      .then(() => {
        notify.success.t('externalLinks.deleted');
      });
};

interface Props {
  itemId: string;
  readonly?: boolean;
}

export function ItemDetailsExternalLinks(props: Props) {
  const { itemId, readonly } = props;
  const linkSubmit = useExternalLinkSubmit(itemId);
  const linkDelete = useExternalLinkDelete(itemId);
  const { currentData: data } = useGetItemDetailsQuery({
    id: itemId,
  });

  const links = data?.externalLinks || [];

  return (
    <ExternalLinks
      readonly={readonly}
      links={links}
      onCreate={linkSubmit}
      onDelete={linkDelete}
    />
  );
}
