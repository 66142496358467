import { notify } from '@/components';
import { AddExternalLinkDto } from '@/core';
import {
  SubOptionState,
  useAddSuboptionExternalLinkMutation,
  useDeleteSuboptionExternalLinkMutation,
} from '@/redux';
import { ExternalLinks } from '../Items.ExternalLinks/ExternalLinks';

const useExternalLinkSubmit = (suboptionId: string) => {
  const [add] = useAddSuboptionExternalLinkMutation();

  return (args: AddExternalLinkDto) =>
    add({ ...args, suboptionId })
      .unwrap()
      .then(() => notify.success.t('externalLinks.added'));
};

const useExternalLinkDelete = (suboptionId: string) => {
  const [remove] = useDeleteSuboptionExternalLinkMutation();

  return (id: string) =>
    remove({ suboptionId, id })
      .unwrap()
      .then(() => notify.success.t('externalLinks.deleted'));
};

interface Props {
  suboption: SubOptionState;
  readonly?: boolean;
}

export function SubOptionExternalLinks(props: Props) {
  const { suboption, readonly } = props;
  const onSubmit = useExternalLinkSubmit(suboption.id);
  const onDelete = useExternalLinkDelete(suboption.id);

  return (
    <ExternalLinks
      readonly={readonly}
      links={suboption.externalLinks}
      onCreate={onSubmit}
      onDelete={onDelete}
    />
  );
}
