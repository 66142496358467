import { useTranslation } from 'react-i18next';
import { Icon, Page, notify } from '@/components';
import { Outlet, useParams } from 'react-router-dom';
import { CommunityContext, CommunityDetailsTabKeys } from '@/views';
import {
  useCommunityContextSelector,
  useGetCommunityContextQuery,
  useGetProjectContextQuery,
  useGetProjectQuery,
  useUpdateProjectStatusMutation,
} from '@/redux';
import { Button, Col, Row, Tag } from 'antd';
import { CommunityPermissions, ProjectStatus } from '@/core';
import { ProjectContext } from '@/views/Projects.Common/ProjectContext';
import styles from './ProjectPage.module.scss';
import classNames from 'classnames';
import { FormatPainterOutlined } from '@ant-design/icons';

export function ProjectPage() {
  const { t } = useTranslation();
  const { id } = useParams<'id'>();
  const { data } = useGetProjectQuery({ id: id! });

  const { communityId } = data ?? {};

  const { data: communityContext } = useGetCommunityContextQuery(
    { id: communityId! },
    { skip: !data },
  );

  const hasPermission = useCommunityContextSelector(
    communityId!,
    (x) => x.hasPermission,
  );

  const { data: projectContext } = useGetProjectContextQuery(
    {
      id: id!,
    },
    { skip: !id || !data },
  );
  const [updateStatus] = useUpdateProjectStatusMutation();
  if (!communityContext || !projectContext) {
    return null;
  }

  const { status: projectStatus, projectId } = projectContext;

  const handleStatusChanged = () => {
    updateStatus({ id: projectId, status: ProjectStatus.InProgress })
      .unwrap()
      .then(() =>
        notify.success.t(
          `projects.details.savedStatus.${ProjectStatus.InProgress}`,
        ),
      );
  };

  return (
    <ProjectContext.Provider value={projectContext}>
      <CommunityContext.Provider value={communityContext}>
        <Page htmlTitle={data?.name}>
          <Page.Paths>
            <Page.Path href="/communities">{t('nav.communities')}</Page.Path>
            <Page.Path
              href={`/communities/${data?.communityId}#${CommunityDetailsTabKeys.projects}`}
            >
              {data?.communityName ?? t('fetchFallbackTitle')}
            </Page.Path>
            <Page.Path
              href={`/communities/${data?.communityId}#${CommunityDetailsTabKeys.projects}`}
            >
              {t('projects.title')}
            </Page.Path>
            <Page.Path>{data?.name}</Page.Path>
          </Page.Paths>

          <Page.Body xs={24} lg={24} xl={24} xxl={{ span: 22, offset: 1 }}>
            <Row
              justify="end"
              wrap={false}
              className={classNames(styles.project)}
            >
              {projectStatus === ProjectStatus.NotStarted && (
                <Button
                  type="primary"
                  className={styles.start}
                  danger
                  onClick={handleStatusChanged}
                >
                  {t('projects.start')}
                </Button>
              )}

              {projectStatus !== ProjectStatus.NotStarted && (
                <Tag
                  color={
                    projectStatus !== ProjectStatus.InProgress
                      ? 'error'
                      : 'blue'
                  }
                  className={styles.tag}
                >
                  {t(`projects.${projectStatus}`)}
                </Tag>
              )}
            </Row>
            <Row wrap={false} gutter={16}>
              <Col flex="none">
                <Page.Nav basePath="/projects/:id" expandedWidth={250}>
                  <Page.NavItem
                    href="administration"
                    icon={<Icon type="pie-chart" />}
                  >
                    {t('projects.administration.title')}
                  </Page.NavItem>
                  {data?.pmLiteEnabled &&
                    hasPermission(CommunityPermissions.DevelopScope.View) && (
                      <Page.NavItem
                        href="develop-scope"
                        icon={<Icon type="edit" />}
                      >
                        {t('developScope.title')}
                      </Page.NavItem>
                    )}
                  {data?.pmLiteEnabled &&
                    hasPermission(CommunityPermissions.DevelopScope.View) && (
                      <Page.NavItem
                        href="workspace"
                        icon={<Icon type="template" />}
                      >
                        {t('workspace.title')}
                      </Page.NavItem>
                    )}

                  {data?.pmLiteEnabled &&
                    hasPermission(CommunityPermissions.DevelopScope.View) && (
                      <Page.NavItem
                        href="task-management"
                        icon={<Icon type="clipboard" />}
                      >
                        {t('tasks.title')}
                      </Page.NavItem>
                    )}
                  {hasPermission(CommunityPermissions.Homes.View) && (
                    <Page.NavItem
                      href="home-personalization"
                      icon={
                        <FormatPainterOutlined
                          size={32}
                          style={{ fontSize: '20px' }}
                        />
                      }
                    >
                      {t('personalization.homeTitle')}
                    </Page.NavItem>
                  )}
                  {data?.pmLiteEnabled &&
                    hasPermission(CommunityPermissions.DevelopScope.View) && (
                      <Page.NavItem
                        href="financial-management"
                        icon={<Icon type="dollar" />}
                      >
                        {t('tasks.financialInfo.title')}
                      </Page.NavItem>
                    )}
                  {hasPermission(CommunityPermissions.Homes.View) && (
                    <Page.NavItem href="homes" icon={<Icon type="home" />}>
                      {t('homes.title')}
                    </Page.NavItem>
                  )}
                </Page.Nav>
              </Col>
              <Col flex="auto">
                <Outlet />
              </Col>
            </Row>
          </Page.Body>
        </Page>
      </CommunityContext.Provider>
    </ProjectContext.Provider>
  );
}
