import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { Form } from '@/components';
import { AddExistingItemArgs, UpdateExistingItemArgs } from '@/redux';
import * as Yup from 'yup';

interface Props<TValue extends AddExistingItemArgs | UpdateExistingItemArgs> {
  onSubmit: (args: TValue) => any;
  onClose: () => any;
  value: TValue | undefined;
}

const schema = Yup.object().shape({
  name: Yup.string().field().nullable().required(),
});

export function KeepExistingDetailsModal<
  TValue extends AddExistingItemArgs | UpdateExistingItemArgs,
>(props: Props<TValue>) {
  const { onSubmit, onClose, value } = props;
  const { t } = useTranslation();
  const id: string | undefined = (value as UpdateExistingItemArgs).id;

  return (
    <Modal
      title={t('personalization.keepExisting.keep')}
      visible
      onCancel={onClose}
      footer={[
        <Button key="back" type="default" onClick={onClose}>
          {t('cancel')}
        </Button>,
        <Form.Submit uid="keep-existing" key="submit" type="primary">
          {t('save')}
        </Form.Submit>,
      ]}
    >
      <Form.Formik<TValue>
        initialValues={value!}
        uid="keep-existing"
        onSubmit={onSubmit}
        validationSchema={schema}
        i18n="personalization.keepExisting.details"
      >
        <Form.Images
          name="imageActions"
          defer
          ownerId={id}
          ownerType="ExistingItemImage"
          noPreload={!id}
          label={t('personalization.keepExisting.images')}
        />
        <Form.TextArea
          name="name"
          required
          label={t('personalization.keepExisting.details.itemName')}
        />
      </Form.Formik>
    </Modal>
  );
}
