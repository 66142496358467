import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Button, Col, Modal } from 'antd';
import { FieldRow, Form } from '@/components';
import { AddSubcontractorArgs } from '@/redux/subcontractors';

const schema = Yup.object().shape({
  name: Yup.string().field().nullable().required(),
  email: Yup.string().email().nullable(),
  phone: Yup.string().phone().nullable(),
  workType: Yup.string().field().nullable().required(),
});

interface Props {
  onClose: () => any;
  onSubmit: (props?: any) => any;
  value: AddSubcontractorArgs;
  title: string;
  buttonSaveName: string;
}

export function SubcontractorDetailsModal(props: Props) {
  const { onClose: onCancel, value, onSubmit, title, buttonSaveName } = props;
  const { t } = useTranslation();

  return (
    <Modal
      title={title}
      visible
      onCancel={onCancel}
      footer={[
        <Button key="back" type="default" onClick={onCancel}>
          {t('subcontractors.details.cancel')}
        </Button>,
        <Form.Submit uid="subcontractor-details" key="submit" type="primary">
          {buttonSaveName}
        </Form.Submit>,
      ]}
    >
      <Form.Formik<AddSubcontractorArgs>
        uid="subcontractor-details"
        i18n="subcontractors.details"
        initialValues={value}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <>
            <FieldRow>
              <Col span={12}>
                <Form.Input name="name" required />
              </Col>
              <Col span={12}>
                <Form.Input name="email" />
              </Col>
            </FieldRow>
            <FieldRow>
              <Col span={12}>
                <Form.Phone name="phone" />
              </Col>
              <Col span={12}>
                <Form.Input name="workType" required />
              </Col>
            </FieldRow>
          </>
        )}
      </Form.Formik>
    </Modal>
  );
}
