import { Button, Modal, Space } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
  onClose: () => any;
}

export function HomeHasNoProjectModal(props: Props) {
  const { onClose } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'homes.details.assignMenu',
  });

  return (
    <Modal
      title={t('title')}
      visible
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          {t('close')}
        </Button>,
      ]}
    >
      <Space direction="vertical" size="large">
        {t('noProject')}
      </Space>
    </Modal>
  );
}
