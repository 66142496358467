import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ResidentState, useGetResidentsListQuery } from '@/redux';
import { Table, TabPanel } from '@/components';
import {
  AddResidentPanel,
  AddResidentButton,
  EditResidentPanel,
  Assert,
  useIsHomeOrAnyStepFinalized,
} from '@/views';
import { useResidentColumns } from './useResidentsColumns';
import { CommunityPermissions } from '@/core';

interface Props {
  homeId: string;
  projectId?: string;
  communityId: string;
}

export function ResidentsListPanel(props: Props) {
  const { homeId, projectId, communityId } = props;
  const { t } = useTranslation();

  const [isAdd, setIsAdd] = useState(false);
  const [editResidentId, setEditResidentId] = useState<string>();

  const { data: homeResidents, isFetching } = useGetResidentsListQuery({
    homeId,
  });
  const isFinalized = useIsHomeOrAnyStepFinalized(homeId, projectId);
  const columns = useResidentColumns(
    homeId,
    setEditResidentId,
    isFinalized === true,
  );

  return (
    <TabPanel>
      <TabPanel.Title>{t('residents.title')}</TabPanel.Title>
      <TabPanel.Actions>
        {!isAdd && !editResidentId && isFinalized !== true && (
          <Assert permission={CommunityPermissions.Residents.Manage} active>
            <AddResidentButton onAdd={() => setIsAdd(true)} />
          </Assert>
        )}
      </TabPanel.Actions>
      <TabPanel.Body>
        {!isAdd && !editResidentId && (
          <Table<ResidentState>
            name="residents"
            loading={isFetching}
            columns={columns}
            dataSource={homeResidents}
            rowKey={(row) => row.id}
          />
        )}
        {isAdd && (
          <AddResidentPanel
            communityId={communityId}
            homeId={homeId}
            onCancel={() => setIsAdd(false)}
          />
        )}
        {editResidentId && (
          <EditResidentPanel
            id={editResidentId}
            onCancel={() => setEditResidentId(undefined)}
          />
        )}
      </TabPanel.Body>
    </TabPanel>
  );
}
