import { memo } from 'react';
import styles from './ImageBox.module.scss';
import { useTranslation } from 'react-i18next';

export interface ImageSkeletonProps {}

function _ImageSkeleton(props: ImageSkeletonProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.skeleton}>
      <div className={styles.uploadPlaceholder}>
        <div className={styles.uploadText}>
          <div>{t('noImage')}</div>
        </div>
      </div>
    </div>
  );
}

export const ImageSkeleton = memo(_ImageSkeleton);
