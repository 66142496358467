import { useTranslation } from 'react-i18next';
import { PathTemplateDetailsPanel } from '@/views';
import { Icon, Page } from '@/components';
import { useParams } from 'react-router-dom';
import {
  useGetPathTemplateDetailsQuery,
  useUserContextSelector,
} from '@/redux';
import { AdminTabKeys } from '@/views/Admin/AdminTabs';

const useId = () => {
  const { id } = useParams<'id'>();
  return id!;
};

export function PathTemplateDetailsPage() {
  const { t } = useTranslation();
  const id = useId();
  const isBFP = useUserContextSelector((x) => x.isBFP);
  const { data } = useGetPathTemplateDetailsQuery({
    id,
    includeCommunityLevel: false,
  });
  const title = data?.name ?? t('fetchFallbackTitle');
  const pathTemplatesHref = isBFP
    ? `/admin#${AdminTabKeys['path-templates']}`
    : undefined;

  return (
    <Page htmlTitle={title}>
      <Page.Paths>
        <Page.Path href={pathTemplatesHref}>{t('nav.admin')}</Page.Path>
        <Page.Path href={pathTemplatesHref}>
          {t('pathTemplates.title')}
        </Page.Path>
        <Page.Path>{title}</Page.Path>
      </Page.Paths>
      <Page.Body>
        <Page.Card
          title={title}
          icon={<Icon type="template" />}
          color="#EFAC4E"
        >
          <PathTemplateDetailsPanel
            includeCommunityLevel={false}
            id={id}
            editRoute={(id) => `/admin/path-templates/${id}/edit`}
          />
        </Page.Card>
      </Page.Body>
    </Page>
  );
}
