import { Modal, Button } from 'antd';
import { Form } from '@/components';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { CommunityRole } from '@/core/http.communities/communityRole';
import { useUserLookupSource } from '@/views';
import i18next from 'i18next';

export interface AssignmentDetailsModalValue {
  userId: string;
  roles: CommunityRole[];
}

const schema = Yup.object().shape({
  userId: Yup.string().field().nullable().required(),
  roles: Yup.array()
    .min(1, i18next.t('errors.mixed.required'))
    .required()
    .nullable(),
});

interface Props {
  onClose: () => any;
  onSubmit: (value: AssignmentDetailsModalValue) => any;
  value: AssignmentDetailsModalValue;
  title: string;
  buttonSaveName: string;
  disabledUserSearch?: boolean;
  clientId: string;
  assignedUserIds?: string[];
}

export function AssignmentDetailsModal(props: Props) {
  const {
    onClose,
    value,
    onSubmit,
    title,
    buttonSaveName,
    disabledUserSearch,
    clientId,
    assignedUserIds,
  } = props;
  const { t } = useTranslation();
  const communityUserDataSource = useUserLookupSource({
    excludeUserIds: assignedUserIds,
    organizationId: clientId,
    roles: ['CLIENT_USER'],
  });

  return (
    <Modal
      title={title}
      visible
      onCancel={onClose}
      footer={[
        <Button key="back" type="default" onClick={onClose}>
          {t('communityUsers.details.cancel')}
        </Button>,
        <Form.Submit uid="community-user-details" key="submit" type="primary">
          {buttonSaveName}
        </Form.Submit>,
      ]}
    >
      <Form.Formik<AssignmentDetailsModalValue>
        uid="community-user-details"
        i18n="communityUsers.details"
        initialValues={value}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        <Form.AsyncSelect
          dataSource={communityUserDataSource}
          name="userId"
          preload
          disabled={disabledUserSearch}
          required
        />
        <Form.EnumSelect
          type={CommunityRole}
          mode="multiple"
          name="roles"
          required
        />
      </Form.Formik>
    </Modal>
  );
}
