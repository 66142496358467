import { AttachmentDto } from '@/core';
import { UseClippoResult } from '@webinex/clippo';
import { keyBy, mapValues } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

export function useClippoBlobs(props: UseClippoResult<AttachmentDto>) {
  const [blobURLById, setBlobURLById] = useState<Record<string, string>>({});
  const [notFound, setNotFound] = useState(false);
  const { value, content } = props;
  const { items } = value;

  const blobs = useMemo(
    () =>
      items.map((item) => ({ ...item, blobURL: blobURLById[item.id] ?? '' })),
    [blobURLById, items],
  );

  function revokeBlobUrl() {
    Object.values(blobURLById).forEach((x) => URL.revokeObjectURL(x));
  }

  async function fetchOne({ id }: AttachmentDto) {
    const blobURL = blobURLById[id]
      ? blobURLById[id]
      : await content(id).then((c) => URL.createObjectURL(c.blob));

    return { id, blobURL };
  }

  async function fetchAll() {
    const result = await Promise.all(items.map(fetchOne));
    const resultById = keyBy(result, (x) => x.id);
    const blobURLById = mapValues(resultById, (x) => x.blobURL);
    setBlobURLById(blobURLById);
  }

  useEffect(() => {
    fetchAll()
      .then(() => setNotFound(false))
      .catch(() => setNotFound(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => revokeBlobUrl, []);

  return { notFound, blobs };
}
