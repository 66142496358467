import * as Yup from 'yup';
import { CreateTaskTemplateArgs, UpdateTaskTemplateArgs, useGetTaskTemplateDetailsQuery } from "@/redux/taskTemplates";
import { TaskResponsiblePersonValue } from "@/core";
import { TemplateTaskDto } from "@/core";
import { useFormikContext } from "formik";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AddButton, Form, StyledRow } from "@/components";
import { get } from "lodash";
import { Button, Space } from "antd";
import { FormTaskTemplateTaskList } from "@/views";

interface Props<T extends CreateTaskTemplateArgs | UpdateTaskTemplateArgs> {
  communityId?: string;
  value: T;
  onSubmit: (values: T) => any;
  noTemplate?: boolean;
  onCancel?: () => any;
  isAdding?: boolean;
}

export interface FormTaskTemplateTaskValue {
  id?: string;
  name: string;
  responsiblePerson?: TaskResponsiblePersonValue
  costEstimate?: number
}

export type FormTaskTemplateValue<
  T extends Omit<CreateTaskTemplateArgs | UpdateTaskTemplateArgs, 'tasks'>,
> = T & {
  tasks: FormTaskTemplateTaskValue[];
};

const INITIAL_TEMPLATE_TASK: TemplateTaskDto = {
  id: undefined!,
  name: null!,
  responsiblePerson: null!
};

const SCHEMA = Yup.object().shape({
  name: Yup.string().field().nullable().required(),
  tasks: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().field().nullable().required(),
        costEstimate: Yup.number().nullable()
      }),
    )
    .required(),
});

function useHandleAddTemplateTask() {
  const { setValues, values } = useFormikContext<CreateTaskTemplateArgs>();

  return useCallback(() => {
    setValues({
      ...values,
      tasks: [...values.tasks, INITIAL_TEMPLATE_TASK],
    });
  }, [setValues, values]);
}

function AddTemplateTaskButton(){
  const { t } = useTranslation();
  const handleClick = useHandleAddTemplateTask();

  return (
    <AddButton onClick={handleClick} ghost>
      {t('taskTemplates.details.form.addTaskButton')}
    </AddButton>
  )
}

function useValue<
  TValue extends CreateTaskTemplateArgs | UpdateTaskTemplateArgs,
>(props: Props<TValue>): FormTaskTemplateValue<TValue> | null {
  const id: string | undefined = get(props.value, 'id');
  const {data} = useGetTaskTemplateDetailsQuery({id: id!}, {skip: !id});

  return useMemo<FormTaskTemplateValue<TValue> | null>(() => {
    if (!id) {
      return {
        ...props.value,
        tasks: props.value.tasks,
      };
    }

    if (!data) {
      return null;
    }

    return {
      ...props.value,
      name: props.value.name,
      tasks: data.tasks.map((task) => ({
        id: task.id,
        name: task.name,
        responsiblePerson: task.responsiblePerson,
        costEstimate: task.costEstimate,
      })),
    };
  }, [data, id, props.value]);
}

export function FormTaskTemplate<
  TValue extends
    | CreateTaskTemplateArgs
    | UpdateTaskTemplateArgs,
>(props: Props<TValue>) {
  const {
    onSubmit,
    communityId,
    isAdding,
    onCancel,
  } = props

  const { t } = useTranslation(undefined, { keyPrefix: 'taskTemplates.details', });
  const formValue = useValue(props);

  if (formValue == null){
    return null;
  }

  return (
    <Form.Formik<FormTaskTemplateValue<TValue>>
      uid="taskTemplate-details"
      i18n="taskTemplates.details.form"
      initialValues={formValue}
      validationSchema={SCHEMA}
      onSubmit={({ ...value}) =>
        onSubmit(value as unknown as TValue)
      }
    >
      <Form.Input
        required
        name="name"
        placeholder={t('placeholders.name')}
      />

      <FormTaskTemplateTaskList
        name="tasks"
        communityId={communityId}
      />

      <div className="mt-4">
        <AddTemplateTaskButton />
      </div>

      {isAdding && (
        <StyledRow justify="end" className="mt-3">
          <Space size="middle">
            <Button onClick={onCancel}>{t('cancel')}</Button>
            <Form.Submit uid="taskTemplate-details" type="primary" >
              {t('save')}
            </Form.Submit>
          </Space>
        </StyledRow>
      )}
    </Form.Formik>
  )
}
