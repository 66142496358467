import { useField } from 'formik';
import { useMemo } from 'react';
import { ItemDetailsPanelProps } from './ItemDetailsPanel';

export function useFormikSelectableSuboption(name: string) {
  const [{ value }, , { setValue, setTouched }] = useField(name);

  const selectableSuboption: ItemDetailsPanelProps['selectableSuboption'] =
    useMemo(
      () => ({
        type: 'radio',
        selected: value ?? undefined,
        onSelect: (id: string) => {
          setTouched(true);
          setValue(id ?? null, true);
        },
      }),
      [value, setTouched, setValue],
    );

  return selectableSuboption;
}
