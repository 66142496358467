import { Space } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useUserContextSelector,
  useGetTaskTemplateDetailsQuery,
  useGetTaskTemplatesListQuery,
  TaskTemplateState,
} from '@/redux';
import { NavLink } from 'react-router-dom';
import { AddTaskTemplatePanel, TaskTemplateTaskList } from '@/views';
import { useNavigate } from 'react-router-dom';
import {
  AddButton,
  Table,
  TabPanel,
  TableColumnType,
  DeleteIconButton,
  EditIconButton,
} from '@/components';
import { useUnlistTaskTemplate } from '@/views';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import styles from './TaskTemplatesList.module.scss';
import { useDefaultTablePaging } from '@/components/Collections.Paging';

interface Props {
  organizationId: string;
  communityId?: string;
  readonly?: boolean;
  title?: string;
  editRoute: (id: string) => string;
  detailsRoute: (id: string) => string;
}

function useColumns(props: Props) {
  const { organizationId, readonly, editRoute, detailsRoute } = props;
  const { t } = useTranslation();
  const push = useNavigate();
  const unlist = useUnlistTaskTemplate(false);

  const [isBFP, hasRole, userOrgId] = useUserContextSelector((x) => [
    x.isBFP,
    x.hasRole,
    x.organizationId,
  ]);

  const canModify =
    isBFP || (organizationId === userOrgId && hasRole('CLIENT_ADMIN'));

  return useMemo<TableColumnType<TaskTemplateState>[]>(() => {
    const columns: Array<TableColumnType<TaskTemplateState> | false> = [
      {
        title: t('taskTemplates.name'),
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (_, { id, name }) => (
          <NavLink to={detailsRoute(id)}>{name}</NavLink>
        ),
      },
      canModify &&
        !readonly && {
          title: t('taskTemplates.actions'),
          key: 'actions',
          render: (_, { id }) => (
            <Space size="large">
              <EditIconButton
                type="link"
                onClick={() => push(editRoute(id))}
                className="table-action"
              />
              <DeleteIconButton
                onClick={() => unlist(id)}
                className="table-action"
              />
            </Space>
          ),
        },
    ];

    return columns.filter((x) => !!x) as TableColumnType<TaskTemplateState>[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, push]);
}

const defaultExpandable: ExpandableConfig<TaskTemplateState> = {
  expandedRowRender: (record: TaskTemplateState) => <Expanded id={record.id} />,
  expandIcon: ({ expanded, onExpand, record }) =>
    record.tasks.length > 0 ? (
      expanded ? (
        <DownOutlined
          onClick={(e: any) => onExpand(record, e)}
          className={styles.icon}
        />
      ) : (
        <RightOutlined
          onClick={(e: any) => onExpand(record, e)}
          className={styles.icon}
        />
      )
    ) : (
      <></>
    ),
};

function Expanded({ id }: { id: string }) {
  const { data } = useGetTaskTemplateDetailsQuery({ id });

  if (!data) {
    return null;
  }

  return (
    <TaskTemplateTaskList
      value={data}
      className="ps-5"
      templateId={data.id}
      editRoute={() =>
        `/communities/${data!.communityId}/task-templates/${id}/edit`
      }
    />
  );
}

export function TaskTemplatesListTabPanel(props: Props) {
  const { communityId, readonly, title } = props;
  const columns = useColumns(props);

  const { setTotal, paging, params } = useDefaultTablePaging();

  const { data, isFetching } = useGetTaskTemplatesListQuery({
    communityId,
    paging: params,
  });

  const taskTemplates = useMemo(() => {
    if (data) setTotal(data.total);
    return data?.items;
  }, [data, setTotal]);

  const { t } = useTranslation(undefined, { keyPrefix: 'taskTemplates' });
  const [add, setAdd] = useState(false);

  return (
    <TabPanel>
      <TabPanel.Title>{title ?? t('title')}</TabPanel.Title>
      <TabPanel.Actions>
        {!readonly && (
          <AddButton onClick={() => setAdd(true)}>
            {t('details.addButton')}
          </AddButton>
        )}
      </TabPanel.Actions>
      <TabPanel.Body compact={add}>
        {!add && (
          <Table<TaskTemplateState>
            name="taskTemplates"
            expandable={defaultExpandable}
            loading={isFetching}
            columns={columns}
            dataSource={taskTemplates}
            rowKey={(row) => row.id}
            showHeader={false}
            hideDefaultActions
            pagination={paging}
          />
        )}
        {add && (
          <AddTaskTemplatePanel
            communityId={communityId}
            onCreated={() => setAdd(false)}
            onCancel={() => setAdd(false)}
          />
        )}
      </TabPanel.Body>
    </TabPanel>
  );
}
