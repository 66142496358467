import { EditIconButton, notify } from '@/components';
import { useState } from 'react';
import {
  ExistingBaseItemState,
  UpdateExistingItemArgs,
  useUpdateExistingItemMutation,
} from '@/redux';
import { KeepExistingDetailsModal } from './KeepExistingDetailsModal';

interface Props {
  value: ExistingBaseItemState;
}

export const EditExistingItemButton = (props: Props) => {
  const { value } = props;
  const [open, setOpen] = useState(false);
  const submit = useSubmit(() => setOpen(false));
  return (
    <>
      <EditIconButton onClick={() => setOpen(true)} />
      {open && (
        <KeepExistingDetailsModal
          onSubmit={submit}
          onClose={() => setOpen(false)}
          value={value}
        />
      )}
    </>
  );
};

const useSubmit = (onClose: () => any) => {
  const [updateExisting] = useUpdateExistingItemMutation();

  return (args: UpdateExistingItemArgs) =>
    updateExisting(args)
      .unwrap()
      .then(() => {
        notify.success.t('personalization.keepExisting.updated');
        onClose();
      });
};
