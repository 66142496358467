import { AddButton } from '@/components';
import { useGetCommunityContextQuery } from '@/redux';
import { useField } from 'formik';
import { isEmpty } from 'lodash';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CommunityContext } from '../Communities.Common';
import { ProjectHomesModal } from './ProjectHomesModal';

interface Props {
  name: string;
  communityId: string;
  projectId: string | null | undefined;
}

export function FormProjectHomes(props: Props) {
  const { name, communityId, projectId } = props;
  const { t } = useTranslation();
  const [display, setDisplay] = useState(false);
  const [{ value: homeIds }, , { setValue }] = useField<string[]>(name);
  const existingContext = useContext(CommunityContext);
  const { data } = useGetCommunityContextQuery(
    { id: communityId },
    { skip: !communityId || !!existingContext },
  );
  const context = existingContext ?? data;

  const handleSave = useCallback(
    (selected: string[]) => {
      setDisplay(false);
      setValue(selected);
    },
    [setValue],
  );

  const title = useMemo(
    () =>
      isEmpty(homeIds)
        ? t('projects.details.addHomes')
        : t('projects.details.selectHomes', { count: homeIds.length }),
    [homeIds, t],
  );

  return (
    <>
      <AddButton type="link" onClick={() => setDisplay(true)}>
        {title}
      </AddButton>
      {display && (
        <CommunityContext.Provider value={context}>
          <ProjectHomesModal
            communityId={communityId}
            projectId={projectId}
            homeIds={homeIds}
            onCancel={() => setDisplay(false)}
            onSave={handleSave}
          />
        </CommunityContext.Provider>
      )}
    </>
  );
}
