import {
  TextBox,
  EnumValue,
  EditIconButton,
  Attach,
  ImageBox,
  useImageBox,
  Title,
  useUploadImagesFromPdf,
} from '@/components';
import { useGetFloorplanDetailsQuery } from '@/redux';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Assert, useCommunityContext, useEditFloorplanAction } from '@/views';
import {
  FloorplanStatus,
  FloorplanType,
  useAppClippo,
  CommunityPermissions,
} from '@/core';

interface Props {
  id: string;
}

export function FloorplanDetailsPanel(props: Props) {
  const { openEdit, editModal } = useEditFloorplanAction();
  const { id } = props;
  const { data, isLoading } = useGetFloorplanDetailsQuery({ id });
  const { t } = useTranslation();
  const { assert } = useCommunityContext();

  const imageBox = useImageBox({
    defer: false,
    ownerId: id,
    ownerType: 'FloorplanImage',
  });

  const uploadImages = useUploadImagesFromPdf(imageBox, id, 'FloorplanImage');

  const clippoProps = useAppClippo({
    defer: false,
    ownerId: id,
    ownerType: 'Floorplan',
  });

  if (isLoading || !data) {
    return null;
  }

  const {
    constructionName,
    marketingName,
    status,
    type,
    squareFootage,
    description,
  } = data;

  const canAdd = assert(CommunityPermissions.Floorplans.Manage, true);

  return (
    <div>
      <Row>
        <Col span={10}>
          <Title
            actions={
              <Assert
                permission={CommunityPermissions.Floorplans.Manage}
                active
              >
                <EditIconButton type="link" onClick={() => openEdit(id)} />
              </Assert>
            }
            inlineActions
          >
            {t('floorplans.details.general')}
          </Title>
          <TextBox label={t('floorplans.details.marketingName')}>
            {marketingName}
          </TextBox>
          <TextBox label={t('floorplans.details.constructionName')}>
            {constructionName}
          </TextBox>
          <TextBox label={t('floorplans.details.type')}>
            <EnumValue type={FloorplanType} value={type} />
          </TextBox>
          <TextBox label={t('floorplans.details.status')}>
            <EnumValue type={FloorplanStatus} value={status} />
          </TextBox>
          <TextBox label={t('floorplans.details.squareFootage')}>
            {squareFootage}
          </TextBox>
          <TextBox label={t('floorplans.details.description')}>
            <div className="text-multiline">{description}</div>
          </TextBox>
        </Col>
        <Col span={14}>
          <Title
            actions={
              <Assert
                permission={CommunityPermissions.Floorplans.Manage}
                active
              >
                <EditIconButton type="link" onClick={imageBox.toggleGallery} />
              </Assert>
            }
            inlineActions
          >
            {t('floorplans.details.images')}
          </Title>
          <ImageBox
            {...imageBox}
            onStore={uploadImages}
            readonly={!canAdd}
            acceptPdf
          />
        </Col>
      </Row>

      <Title className="mt-4">{t('floorplans.details.attachments')}</Title>
      <Attach {...clippoProps} readonly={!canAdd} />

      {editModal}
    </div>
  );
}
