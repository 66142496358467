import { confirmation, ContextMenu, NavLink, notify } from '@/components';
import {
  useChangeActiveMutation,
  useDeleteUserMutation,
  UserState,
  useUserContextSelector,
} from '@/redux';
import { TableColumnType } from '@/components';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UserStatusBadge } from './UserStatusBadge';
import { useFiltersFactory, PhoneView } from '@/utils';
import { StringFilterOptions } from '@/core';

const useChangeActive = () => {
  const [changeActive] = useChangeActiveMutation();

  return useCallback(
    (id: string, value: boolean) => {
      if (value) {
        changeActive({ id, value })
          .unwrap()
          .then(() => notify.success.t(`users.savedStatus.activated`));
      } else {
        confirmation.deactivate({ key: 'users.entityName' }, () =>
          changeActive({ id, value })
            .unwrap()
            .then(() => notify.success.t(`users.savedStatus.deactivated`)),
        );
      }
    },
    [changeActive],
  );
};

function useHandleDelete() {
  const [deleteUser] = useDeleteUserMutation();

  return useCallback(
    (id: string) => {
      confirmation.delete({ key: 'users.entityName' }, () => {
        deleteUser({ id });
      });
    },
    [deleteUser],
  );
}

export function useUsersColumns<T extends UserState>(
  users: T[] | undefined,
  openEdit: (id: string) => any,
  stringFilterOptions?: StringFilterOptions,
) {
  const { t } = useTranslation();
  const filters = useFiltersFactory(users, stringFilterOptions);
  const isAdmin = useUserContextSelector((x) => x.isAdmin);

  const changeActive = useChangeActive();
  const deleteUser = useHandleDelete();

  return useMemo<TableColumnType<T>[]>(() => {
    const columns: Array<TableColumnType<T> | false> = [
      {
        title: t('users.number'),
        key: 'number',
        sorter: (a, b) => a.number.localeCompare(b.number),
        defaultSortOrder: 'descend',
        ...filters.select((x) => x.number, 'number'),
        render: (_, item) => item.number,
        width: 100,
      },
      {
        title: t('users.name'),
        sorter: (a, b) =>
          (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName),
        ...filters.select((x) => x.firstName + ' ' + x.lastName, 'name'),
        render: (_, { firstName, lastName, id }) =>
          isAdmin ? (
            <NavLink to={`/users/${id}`}>
              {firstName} {lastName}
            </NavLink>
          ) : (
            `${firstName} ${lastName}`
          ),
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: t('users.email'),
        ...filters.select((x) => x.email, 'email'),
        sorter: (a, b) => a.email.localeCompare(b.email),
        key: 'email',
        dataIndex: 'email',
      },
      {
        title: t('users.phone'),
        ...filters.plainText((x) => x.phone),
        sorter: (a, b) => a.phone.localeCompare(b.phone),
        key: 'phone',
        dataIndex: 'phone',
        render: (_, { phone }) => <PhoneView value={phone} />,
      },
      {
        title: t('users.status.title'),
        key: 'isActive',
        width: 100,
        render: (_, user) => <UserStatusBadge value={user} />,
      },
      {
        title: t('users.actions.title'),
        key: 'actions',
        width: 80,
        render: (_, { id, isActive }) => (
          <ContextMenu
            items={[
              {
                onClick: () => openEdit(id),
                label: t('users.actions.edit'),
                if: isActive,
              },
              isActive
                ? {
                    onClick: () => changeActive(id, false),
                    label: t('users.actions.deactivate'),
                  }
                : {
                    onClick: () => changeActive(id, true),
                    label: t('users.actions.activate'),
                  },
              {
                onClick: () => deleteUser(id),
                label: t('users.actions.delete'),
              },
            ]}
          />
        ),
      },
    ];
    return columns.filter((x) => !!x) as TableColumnType<T>[];
  }, [changeActive, deleteUser, filters, isAdmin, openEdit, t]);
}
