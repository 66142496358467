import { useTranslation } from 'react-i18next';
import { TabPanel } from '@/components';
import { CustomLegalLanguageListPanel } from './CustomLegalLanguageListPanel';
import { AddCustomLegalLanguageButton } from './AddCustomLegalLanguageButton';

export function CustomLegalLanguageListTabPanel() {
  const { t } = useTranslation();
  const title = t('customLegalLanguage.title');

  return (
    <TabPanel min="auto">
      <TabPanel.Title>{title}</TabPanel.Title>
      <TabPanel.Actions>
        <AddCustomLegalLanguageButton />
      </TabPanel.Actions>
      <TabPanel.Body>
        <CustomLegalLanguageListPanel />
      </TabPanel.Body>
    </TabPanel>
  );
}
