import i18next from 'i18next';
import { CodedException } from '@/core';
import { guard } from '@/utils';
import { notification } from 'antd';
import { NotificationInstance } from 'antd/lib/notification';
import { Trans } from 'react-i18next';

function create(fn: keyof NotificationInstance, withFormatting?: boolean) {
  return Object.assign(
    (message: string) =>
      notification[fn]({
        message: i18next.t<string>(`notifications.title.${fn}`),
        description: message,
      }),
    {
      t: (key: string, values?: any) =>
        notification[fn]({
          message: i18next.t<string>(`notifications.title.${fn}`),
          description: withFormatting ? (
            <Trans
              i18nKey={key}
              values={values}
              components={{ bold: <strong /> }}
            />
          ) : (
            i18next.t<string>(key, values)
          ),
        }),
    },
  );
}

export const notify = {
  success: create('success'),
  error: Object.assign(create('error'), {
    coded: (error: CodedException) => {
      guard.notNull(error, 'error');
      notify.error.t(`errors.coded.${error.code}`, { payload: error.payload });
    },
  }),
  info: create('info'),
  errorWithFormatting: Object.assign(create('error', true), {
    coded: (error: CodedException) => {
      guard.notNull(error, 'error');
      notify.errorWithFormatting.t(`errors.coded.${error.code}`, {
        payload: error.payload,
      });
    },
  }),
};
