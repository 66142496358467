import {
  AddSubcontractorDto,
  Paged,
  Paging,
  SubcontractorDetailsDto,
  SubcontractorDto,
  SubcontractorFilterParams,
  SubcontractorLookupDto,
  subcontractorsHttp,
  UpdateSubcontractorDto,
} from '@/core';
import { rtkq, RtkqRequest } from '@/redux/rtkq';
import { api } from '../api';
import { SortResult } from '@/components/Collections.Paging/useSorting';

export type SubcontractorState = SubcontractorDto;
export type SubcontractorDetailsState = SubcontractorDetailsDto;
export type AddSubcontractorArgs = AddSubcontractorDto;
export type UpdateSubcontractorDtoArgs = UpdateSubcontractorDto;
export type UpdateSubcontractorItemsDtoArgs = { itemIds: string[] };
export type SubcontractorLookupState = SubcontractorLookupDto;

const subcontractorsApi = api.injectEndpoints({
  endpoints: (build) => ({
    addSubcontractor: build.mutation<string, RtkqRequest<AddSubcontractorArgs>>(
      {
        queryFn: (args) => {
          return rtkq(args).exec({
            query: () => subcontractorsHttp.create(args),
          });
        },
        invalidatesTags: () => [
          { type: 'subcontractor', id: 'list' },
          { type: 'task', id: 'responsible-persons' },
        ],
      },
    ),

    updateSubcontractor: build.mutation<
      void,
      RtkqRequest<UpdateSubcontractorDtoArgs>
    >({
      queryFn: (args) => {
        return rtkq(args).exec({
          query: () => subcontractorsHttp.update(args),
        });
      },
      invalidatesTags: (_, __, args) => [
        { type: 'subcontractor', id: 'list' },
        { type: 'subcontractor', id: args.id },
        { type: 'task', id: 'responsible-persons' },
      ],
    }),

    getSubcontractorDetails: build.query<
      SubcontractorDetailsState,
      RtkqRequest<{ id: string }>
    >({
      queryFn: async (args) => {
        const { id } = args;
        return rtkq(args).exec(() => subcontractorsHttp.get(id));
      },
      providesTags: (s) => [
        { type: 'subcontractor', id: s?.id ?? 'none' },
        { type: 'item', id: 'list' },
        { type: 'category', id: 'list' },
      ],
    }),

    getSubcontractorsList: build.query<
      Paged<SubcontractorState>,
      RtkqRequest<{
        communityId?: string | string[];
        paging: Paging;
        filters?: SubcontractorFilterParams;
        sorting?: SortResult;
      }>
    >({
      queryFn: async (args) => {
        const { communityId, paging, filters, sorting } = args;
        return rtkq(args).exec(() =>
          subcontractorsHttp.getAll(paging, filters, sorting, communityId),
        );
      },
      providesTags: (result) => [
        { type: 'subcontractor', id: 'list' },
        ...(result?.items || []).map(({ id }) => ({
          type: 'subcontractor' as const,
          id,
        })),
      ],
    }),

    unlistSubcontractor: build.mutation<void, RtkqRequest<{ id: string }>>({
      queryFn: async (args) => {
        return rtkq(args).exec(() => subcontractorsHttp.unlist(args.id));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'subcontractor', id: args.id },
        { type: 'task', id: 'responsible-persons' },
      ],
    }),
  }),
});

export const {
  useAddSubcontractorMutation,
  useGetSubcontractorsListQuery,
  useGetSubcontractorDetailsQuery,
  useLazyGetSubcontractorDetailsQuery,
  useUpdateSubcontractorMutation,
  useUnlistSubcontractorMutation,
  useLazyGetSubcontractorsListQuery,
} = subcontractorsApi;
