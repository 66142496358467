import { FieldRow } from '@/components';
import {
  ReportHomeStateOption,
  ReportItemTypeOption,
  ReportPersonalizationItemLocationOption,
} from '@/core';
import { Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './FormCustomReport.module.scss';
import { AdditionalOptionCheckBoxGroupSection } from './AdditionalOptionCheckBoxGroup';
import { useProjectStepLookupSource } from '../Projects.Common';
import { useField, useFormikContext } from 'formik';
import { AdditionalOptionDropDownBoxSection } from './AdditionalOptionDropDownBox';
import { useEffect } from 'react';
import { AdditionalOptionLimitBySubcontractorSection } from './AdditionalOptionLimitBySubcontractorSection';

interface Props {
  name: string;
  className?: string;
}

export function FormCustomReportOptions(props: Props) {
  const { name, className } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'reports.custom.details.options',
  });

  const [{ value: projectIds }] = useField<string[] | undefined>(
    'filter.projectIds',
  );
  const projectStepsDataSource = useProjectStepLookupSource(projectIds, true);

  const [{ value: communityIds }] = useField<string[] | undefined>(
    'filter.communityIds',
  );

  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue(`${name}.pathStepIds`, undefined);
  }, [projectIds, name, setFieldValue]);

  return (
    <div className={styles.additionalOptions}>
      <Typography.Title level={4}>{t('title')}</Typography.Title>
      <FieldRow className={className}>
        <Col span={5}>
          <AdditionalOptionCheckBoxGroupSection
            fieldName={`${name}.homeState`}
            className="pb-3"
            title={t('homeState')}
            valueType={ReportHomeStateOption}
          />
        </Col>
        <Col span={5}>
          <AdditionalOptionCheckBoxGroupSection
            fieldName={`${name}.location`}
            className="pb-3"
            title={t('location')}
            valueType={ReportPersonalizationItemLocationOption}
          />
        </Col>
        <Col span={4}>
          <AdditionalOptionCheckBoxGroupSection
            fieldName={`${name}.itemType`}
            className="pb-3"
            title={t('itemType')}
            valueType={ReportItemTypeOption}
          />
        </Col>
        <Col span={5}>
          <AdditionalOptionDropDownBoxSection
            key={projectIds?.join()}
            fieldName={`${name}.pathStepIds`}
            className="pb-3"
            title={t('pathSteps')}
            placeholder={t('placeholders.pathStepIds')}
            dataSource={projectStepsDataSource}
          />
        </Col>
        <Col span={5}>
          <AdditionalOptionLimitBySubcontractorSection
            name={name}
            communityIds={communityIds}
          />
        </Col>
      </FieldRow>
    </div>
  );
}
