import { useAppListState, useAppUnreadCount } from '@/core/wispoClient';
import {
  NotificationListItemState,
  FilterRule,
  NotificationField,
  NotificationListField,
  SortRule,
} from '@webinex/wispo';
import { Button, List, Space } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../Notifications';

import classes from './LastNotificationsPanel.module.scss';
import { SettingOutlined } from '@ant-design/icons';

function ListItem({
  item,
  onMarkRead,
}: {
  item: NotificationListItemState;
  onMarkRead: (id: string) => any;
}) {
  const { id } = item;
  const handleRead = useCallback(() => onMarkRead(id), [id, onMarkRead]);
  const { t } = useTranslation();
  const markRead = item.markRead;
  const busy = markRead.isPending || markRead.isReloading;

  const { subject, body } = useNotification(item, handleRead) ?? {};

  return (
    <div className="p-2">
      <div>
        <div className="text-multiline">{subject}</div>
        <h6 className="text-multiline">{body}</h6>
      </div>
      <div className="mt-2">
        <Button type="link" className="text-small" onClick={handleRead}>
          {!busy && t('notifications.last.markRead')}
          {busy && t('notifications.last.markingRead')}
        </Button>
      </div>
    </div>
  );
}

const FILTER: FilterRule = {
  operator: '=',
  fieldId: NotificationField.IS_READ,
  value: false,
};

const SORT: SortRule[] = [
  { fieldId: NotificationField.CREATED_AT, dir: 'desc' },
];

const INCLUDE = [
  NotificationListField.Items,
  NotificationListField.TotalUnread,
];

export function LastNotificationsPanel(props: {
  onClosePopover?: () => any;
  adaptive?: boolean;
}) {
  const { onClosePopover, adaptive } = props;
  const { t } = useTranslation();
  const unread = useAppUnreadCount();
  const push = useNavigate();
  const { isPending, items, markRead, markViewRead } = useAppListState({
    take: 10,
    filter: FILTER,
    sort: SORT,
    include: INCLUDE,
  });

  const handleMarkRead = (id: string) => {
    markRead([id]);
  };

  return (
    <div className={adaptive ? classes.adaptiveContainer : classes.container}>
      <h6 className="bg-light p-2 mb-0">
        {t('notifications.last.title', { unreadCount: unread })}
      </h6>

      <hr className="my-0" />

      <List
        className={adaptive ? classes.adaptiveList : classes.list}
        dataSource={items}
        loading={isPending}
        renderItem={(item: NotificationListItemState) => (
          <ListItem item={item} onMarkRead={handleMarkRead} />
        )}
      />

      <hr className="my-0" />

      <div className="bg-light p-2 text-center text-small">
        <Space>
          <Button type="link" onClick={markViewRead} disabled={isPending}>
            {t('notifications.last.markViewRead')}
          </Button>
          <Button type="link" onClick={() => push(`/notifications`)}>
            {t('notifications.last.showAll')}
          </Button>
          <Button
            type="link"
            icon={<SettingOutlined />}
            onClick={onClosePopover}
          ></Button>
        </Space>
      </div>
    </div>
  );
}
