import { notify, TabPanel } from '@/components';
import { useTranslation } from 'react-i18next';
import { UpdateItemArgs, useAddItemMutation } from '@/redux';
import { useEffect, useMemo, useState } from 'react';
import {
  INITIAL_ITEM,
  ItemDetailsPanel,
  ItemEditPanel,
} from '../Items.Details';
import { ItemListNav, useItemNavSelectedState } from './ItemListNav';
import {
  AddSubcategoryForm,
  INITIAL_SUBCATEGORY,
  ItemListActions,
  Assert,
  SubcategoryDetailsPanel,
  useCommunityContext,
} from '@/views';
import { CommunityPermissions } from '@/core';
import styles from './ItemListPanel.module.scss';

interface Props {
  communityId: string;
}

const useSubmit = (setAdd: (value: boolean) => any, props: Props) => {
  const [add] = useAddItemMutation();
  return (args: UpdateItemArgs) =>
    add({ communityId: props.communityId, ...args })
      .unwrap()
      .then(() => notify.success.t('items.saved'))
      .then(() => setAdd(false));
};

function useInitialItemValue(addItem: boolean | { categoryId: string }) {
  return useMemo(
    () => ({
      ...INITIAL_ITEM,
      categoryId: typeof addItem === 'boolean' ? null! : addItem.categoryId,
    }),
    [addItem],
  );
}

function useInitialSubcategoryValue(
  addSubcategory: boolean | { parentId: string },
) {
  return useMemo(
    () => ({
      ...INITIAL_SUBCATEGORY,
      parentId:
        typeof addSubcategory === 'boolean'
          ? null!
          : addSubcategory.parentId ?? null!,
    }),
    [addSubcategory],
  );
}

export function ItemListTabPanel(props: Props) {
  const { communityId } = props;
  const selectedState = useItemNavSelectedState();
  const [addItem, setAddItem] = useState<boolean | { categoryId: string }>(
    false,
  );
  const [addSubcategory, setAddSubcategory] = useState<
    boolean | { parentId: string }
  >(false);
  const submit = useSubmit(setAddItem, props);
  const { t } = useTranslation();
  const initialItemValue = useInitialItemValue(addItem);
  const initialSubcategoryValue = useInitialSubcategoryValue(addSubcategory);
  const {
    selectedId,
    onSelected,
    selectedSubcategoryId,
    onSubcategorySelected,
  } = selectedState;

  const { assert } = useCommunityContext();

  useEffect(() => {
    selectedId && setAddItem(false);
    selectedSubcategoryId && setAddSubcategory(false);
    // eslint-disable-next-line
  }, [selectedId, selectedSubcategoryId]);

  const handleCreateItemClick = () => {
    setAddItem(true);
    setAddSubcategory(false);
    onSelected(undefined);
  };

  const handleCreateSubcategoryClick = () => {
    setAddSubcategory(true);
    setAddItem(false);
    onSubcategorySelected(undefined);
  };

  const handleAdd = (categoryId: string) => {
    setAddItem({ categoryId: categoryId });
    setAddSubcategory(false);
    onSelected(undefined);
  };

  const handleAddSubcategory = (parentId: string) => {
    setAddSubcategory({ parentId: parentId });
    setAddItem(false);
    onSubcategorySelected(undefined);
  };

  const handleDelete = () => {
    onSelected(undefined);
  };

  const readonly = !assert(CommunityPermissions.ItemsCategories.Manage, true);

  return (
    <TabPanel min="auto">
      <TabPanel.Title>{t('items.title')}</TabPanel.Title>
      <TabPanel.Actions>
        <Assert permission={CommunityPermissions.ItemsCategories.Manage} active>
          <ItemListActions
            onItemCreate={handleCreateItemClick}
            onSubcategoryCreate={handleCreateSubcategoryClick}
          />
        </Assert>
      </TabPanel.Actions>
      <TabPanel.Nav title={t('items.navTitle')} className={styles.navClass}>
        <ItemListNav
          communityId={communityId}
          onAdd={handleAdd}
          onAddSubcategory={handleAddSubcategory}
          onDeleted={handleDelete}
          {...selectedState}
        />
      </TabPanel.Nav>
      <TabPanel.Body>
        {selectedSubcategoryId && !addItem && (
          <SubcategoryDetailsPanel
            id={selectedSubcategoryId}
            communityId={communityId}
          />
        )}
        {selectedId && !addSubcategory && (
          <ItemDetailsPanel id={selectedId} readonly={readonly} />
        )}
        {addItem && !selectedId && (
          <ItemEditPanel
            onCancel={() => setAddItem(false)}
            onSubmit={submit}
            title={t('items.details.createTitle')}
            value={initialItemValue}
            communityId={communityId}
          />
        )}
        {addSubcategory && !selectedSubcategoryId && (
          <AddSubcategoryForm
            value={initialSubcategoryValue}
            communityId={communityId}
            onCancel={() => setAddSubcategory(false)}
            onSubmitted={() => setAddSubcategory(false)}
          />
        )}
      </TabPanel.Body>
    </TabPanel>
  );
}
