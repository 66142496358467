import { ExistingBaseItemState } from '@/redux';
import { ImageBox, StyledRow, useImageBox } from '@/components';
import { Card, Col, Space, Typography } from 'antd';
import { CommunityPermissions } from '@/core';
import { Assert, useProjectContext } from '@/views';
import styles from '../PersonalizationListItem.module.scss';
import { EditExistingItemButton } from './EditExistingItemButton';

interface Props {
  item: ExistingBaseItemState;
  readonly?: boolean;
}

const { Title } = Typography;

export function ExistingItem(props: Props) {
  const { item, readonly } = props;
  const { name } = item;
  const { isActive } = useProjectContext();

  const imageBox = useImageBox({
    defer: false,
    ownerId: item.id,
    ownerType: 'ExistingItemImage',
  });

  return (
    <div>
      <Card className={styles.cardBody} bordered={false}>
        <StyledRow wrap>
          <Col>
            <ImageBox {...imageBox} inCard readonly />
          </Col>
          <Col>
            <Space>
              <Title level={3}>{name}</Title>
            </Space>
          </Col>
          <Col flex="auto" className="text-end">
            {!readonly && isActive && (
              <Assert
                permission={CommunityPermissions.Personalization.Manage}
                active
              >
                <Space>
                  <EditExistingItemButton value={item} />
                </Space>
              </Assert>
            )}
          </Col>
        </StyledRow>
      </Card>
    </div>
  );
}
