import classNames from 'classnames';
import { ImageMarker } from './ImageMarker';
import { ImageMarkerValue } from './ImageMarkerBox';
import styles from './ImageMarkerBox.module.scss';

export interface ImageMarkerViewProps<T extends ImageMarkerValue> {
  src: string;
  markers: T[];
  imageRef?: React.LegacyRef<HTMLDivElement>;
  className?: string;
  onDelete?: (value: T) => any;
  onEdit?: (value: T) => any;
  readonly?: boolean;
}

export function ImageMarkerView<T extends ImageMarkerValue>(
  props: ImageMarkerViewProps<T>,
) {
  const { imageRef, src, markers, onDelete, onEdit, readonly } = props;

  return (
    <div className={classNames(styles.viewContainer)}>
      <div
        ref={imageRef}
        className={classNames(styles.view)}
        style={{ backgroundImage: `url(${src})` }}
      >
        <img
          src={src}
          alt="Floorplan"
          style={{ visibility: 'hidden', width: '100%', height: 'auto' }}
        />
        {markers.map((marker, index) => (
          <ImageMarker
            value={marker}
            key={index}
            index={index}
            onDelete={onDelete}
            onEdit={onEdit}
            readonly={readonly}
          />
        ))}
      </div>
    </div>
  );
}
