import {
  EnumValue,
  Form,
  ImageBox,
  notify,
  StyledRow,
  useImageBoxLazy,
} from '@/components';
import { CommunityPermissions, formatters, ItemMenuItemType } from '@/core';
import {
  RoomPersonalizationAvailableItemState,
  ProjectStepLookupState,
  useAddRoomPersonalizationItemMutation,
  useGetSubOptionsListQuery,
} from '@/redux';
import {
  AddPersonalizationItemButton,
  AddPersonalizationItemFormValue,
  getPersonalizationItemInitialValue,
  PersonalizationItemFormValue,
  useCommunityContext,
} from '@/views';
import { Avatar, Card, Col, Row, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusCircleOutlined } from '@ant-design/icons';
import styles from './RoomPersonalization.module.scss';
import { useMemo } from 'react';

import * as Yup from 'yup';

interface Props {
  item: RoomPersonalizationAvailableItemState;
  homeId: string;
  roomId?: string;
  step: ProjectStepLookupState;
  projectId: string;
  className?: string;
  disabled?: boolean;
  categoryId: string;
  compact?: boolean;
}

const personalizationItemValidationSchema = Yup.object().shape({
  __hasSuboptions: Yup.bool(),
  itemId: Yup.string().nullable().required({ key: 'item.required' }),
  suboptionId: Yup.string().nullable().when('__hasSuboptions', {
    is: true,
    then: Yup.string().nullable().required(),
  }),
  notes: Yup.string().field('lg').nullable(),
});

const { Text, Title } = Typography;

export function RoomPersonalizationItemToAdd(props: Props) {
  const {
    item,
    homeId,
    roomId,
    step,
    className,
    projectId,
    disabled,
    categoryId,
  } = props;
  const imageBox = useImageBoxLazy({
    defer: false,
    ownerId: item.id,
    ownerType: 'ItemImage',
  });

  const showImageBox = !!imageBox.notInitialized || imageBox.items.length > 0;

  const [add] = useAddRoomPersonalizationItemMutation();
  const handleSubmit = (args: PersonalizationItemFormValue) => {
    add({
      ...args,
      stepId: step.id,
      homeId,
      roomId,
      projectId,
      isFloorplan: false,
      categoryId: categoryId,
    })
      .unwrap()
      .then(() => notify.success.t('personalization.item.details.saved'));
  };

  const { assert } = useCommunityContext();
  const canAdd =
    !disabled && assert(CommunityPermissions.Personalization.Manage, true);
  const { t } = useTranslation();
  const { notInMenu, tbd, totalResidentPrice, type } = item.pricing ?? {};
  const { data: subOptions } = useGetSubOptionsListQuery(
    {
      itemId: item.id,
      includedUnlistedSuboptions: [],
    },
    { skip: !item.id },
  );

  const { options: subOptionValues, hasSubOptions } = useMemo(() => {
    const options = subOptions?.map((x) => {
      return { label: x.name, value: x.id };
    });
    return { options, hasSubOptions: !!options && options.length > 0 };
  }, [subOptions]);

  return (
    <Card className={className} bordered={false}>
      <StyledRow align="middle" justify={'space-between'}>
        <Col>
          <StyledRow>
            <AddPersonalizationItemButton
              type="link"
              communityId={item.communityId}
              floorplanPersonalization={false}
              homeId={homeId}
              itemId={item.id}
              roomId={roomId}
              step={step}
              onSubmit={handleSubmit}
              projectId={projectId}
              disabled={!canAdd}
              name={item?.name}
            />
          </StyledRow>
          <StyledRow className="text-multiline">{item.description}</StyledRow>
          {showImageBox && (
            <StyledRow className="mt-3">
              <ImageBox hideEmpty compact {...imageBox} readonly />
            </StyledRow>
          )}
        </Col>
        <Col span={14}>
          <Form.Formik<AddPersonalizationItemFormValue>
            uid="personalization-item-details"
            i18n="personalization.item.details"
            initialValues={{
              ...getPersonalizationItemInitialValue(item.id),
              __hasSuboptions: hasSubOptions,
            }}
            validationSchema={personalizationItemValidationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            <Row justify={'space-between'} align="middle">
              <Col className={styles.select}>
                <Form.Select
                  label={false}
                  placeholder={t('personalization.item.details.suboption')}
                  name="suboptionId"
                  options={subOptionValues ?? []}
                />
              </Col>
              <Col>
                <Title level={5} className="text-end">
                  <EnumValue type={ItemMenuItemType} value={type} />
                </Title>
                <Title level={2} className="mb-0">
                  {notInMenu && (
                    <Text type="danger">
                      {t('personalization.item.details.notInMenu')}
                    </Text>
                  )}
                  {!notInMenu && !tbd && formatters.money(totalResidentPrice)}
                  {!notInMenu && tbd && (
                    <Text type="danger">
                      {t('personalization.item.details.tbd')}
                    </Text>
                  )}
                </Title>
              </Col>
              <Col>
                <Form.Submit type="link">
                  <Tooltip title={t('add')}>
                    <Avatar
                      size={50}
                      icon={<PlusCircleOutlined />}
                      className={styles.add}
                    />
                  </Tooltip>
                </Form.Submit>
              </Col>
            </Row>
          </Form.Formik>
        </Col>
      </StyledRow>
    </Card>
  );
}
