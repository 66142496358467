import { useRoomPersonalization } from './useRoomPersonalization';

export function RoomPersonalizationPanel({
  communityId,
  homeId,
  projectId,
  selectedRoomId,
  compact,
}: {
  communityId: string;
  homeId: string;
  projectId: string;
  selectedRoomId?: string;
  compact?: boolean;
}) {
  const { personalizationSteps, personalizationAlert, content, title } =
    useRoomPersonalization({
      communityId,
      projectId,
      homeId,
      compact,
      selectedRoomId,
    });
  return (
    <>
      {title} {personalizationSteps} {personalizationAlert} {content}
    </>
  );
}
