import { useField } from 'formik';
import { FormsGroup } from './FormsGroup';
import { useFormLabel } from './FormsI18nContext';
import { DatePicker, DatePickerProps } from 'antd';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { DATE_FORMAT_MOMENT } from '@/platform';

export type FormsDateProps = {
  name: string;
  label?: React.ReactNode;
  required?: boolean;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  allowClear?: boolean;
};

export const FormsDate = ({
  name,
  label: labelOverride,
  required,
  className,
  placeholder,
  disabled,
  allowClear,
}: FormsDateProps) => {
  const [field, , { setValue }] = useField({
    name,
    type: 'date',
  });
  const label = useFormLabel(name, labelOverride);

  const handleChange = useCallback(
    (value: DatePickerProps['value']) => {
      setValue(value?.utc(true).startOf('date'), true);
    },
    [setValue],
  );

  const value = useMemo(() => {
    return typeof field.value === 'string'
      ? moment(field.value)
      : field.value ?? '';
  }, [field.value]);

  return (
    <FormsGroup label={label} name={name} required={required}>
      <DatePicker
        {...field}
        onChange={handleChange}
        className={className}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        style={{ width: '100%' }}
        allowClear={allowClear}
        format={DATE_FORMAT_MOMENT}
      />
    </FormsGroup>
  );
};
