import {
  LookupSource,
  LookupOption,
  createEntireLoadLookupSource,
} from '@/components';
import {} from '@/core';
import { useLazyGetReportingLocationsListQuery } from '@/redux';
import { RtkqSpin } from '@/redux/rtkq';
import { useMemo } from 'react';

export function mapLocationLookup(value: {
  id: string;
  name: string;
}): LookupOption {
  const { id, name } = value;

  const searchValue = [name].join(' ');

  return {
    _search: searchValue,
    value: id,
    label: name,
  };
}

export function useReportingLocationLookupSource(
  communityId?: string | string[],
  projectId?: string | string[],
): LookupSource {
  const [fetchList] = useLazyGetReportingLocationsListQuery();

  return useMemo(() => {
    const entireSource = createEntireLoadLookupSource({
      initial: (id: string) =>
        fetchList({ communityId, projectId, [RtkqSpin]: false }, true)
          .unwrap()
          .then((values) =>
            mapLocationLookup(values.find((x) => x.id === id)!),
          ),
      load: () =>
        fetchList({ communityId, projectId, [RtkqSpin]: false }, true)
          .unwrap()
          .then((values) => values.map(mapLocationLookup)),
    });

    return { ...entireSource, searchField: '_search' };
  }, [fetchList, communityId, projectId]);
}
