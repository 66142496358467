import { ImageBox, useImageBoxLazy } from '@/components';
import { PersonalizationSuboptionState } from '@/redux';
import { Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './PersonalizationListItem.module.scss';

const { Title } = Typography;

interface Props {
  suboption?: PersonalizationSuboptionState;
  className?: string;
}

export function PersonalizationItemSuboption(props: Props) {
  const { suboption, className } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'personalization.item.details',
  });

  const clippoImagesProps = useImageBoxLazy({
    defer: false,
    ownerId: suboption?.id,
    ownerType: 'SuboptionImage',
  });

  if (!suboption) return null;

  return (
    <div className={className}>
      <Space>
        <Title level={5} className={styles.label}>
          {t('color')}:
        </Title>
        <Title level={5}>{suboption.name}</Title>
      </Space>
      <ImageBox {...clippoImagesProps} readonly compact />
    </div>
  );
}
