import { HomeState, useGetHomesListQuery } from '@/redux';
import {
  Table,
  useDefaultTablePaging,
  useFilters,
  useSorting,
} from '@/components';
import { useEditHomeAction } from '@/views';
import { useHomesColumns } from './useHomesColumns';
import React, { useMemo } from 'react';
import { TableRowSelection } from 'antd/lib/table/interface';
import { HomeFilterParams } from '@/core';

export interface HomesListPanelProps {
  communityId?: string;
  noProject?: boolean;
  projectId?: string;
  rowActions?:
    | true
    | false
    | ((record: HomeState, actions: React.ReactElement) => React.ReactElement);
  tablelActions?: React.ReactElement;
  rowSelection?: TableRowSelection<HomeState>;
}

export function HomesListPanel(props: HomesListPanelProps) {
  const { communityId, projectId, tablelActions, rowSelection } = props;
  const { setTotal, paging, params } = useDefaultTablePaging();
  const {
    filters,
    onFiltersChange,
    resetFilters,
    stringFilterOptions,
    setStringFilterOptions,
  } = useFilters<HomeFilterParams>([
    'number',
    'homeNumber',
    'locationLevel1Name',
    'locationLevel2Name',
    'locationLevel3Name',
    'constructionNumber',
    'floorplanMarketingName',
    'projectName',
    'menu',
    'residentFilter',
  ]);
  const { sorting, onSortingChange } = useSorting<HomeState>({
    sortBy: 'homeNumber',
    sortOrder: 'ascend',
  });
  const { data, isFetching } = useGetHomesListQuery({
    communityId,
    projectId,
    paging: params,
    filters,
    sorting,
  });

  const homes = useMemo(() => {
    if (data) {
      setTotal(data.total);
      setStringFilterOptions(data.stringFilterOptions);
    }
    return data?.items;
  }, [data, setStringFilterOptions, setTotal]);

  const { openEdit, editModal } = useEditHomeAction();
  const columns = useHomesColumns(
    { ...props, openEdit },
    homes,
    stringFilterOptions,
  );

  return (
    <>
      <Table<HomeState>
        name="Homes"
        loading={isFetching}
        columns={columns}
        dataSource={homes}
        rowKey={(row) => row.id}
        actions={tablelActions}
        rowSelection={rowSelection}
        pagination={paging}
        onChange={(_, filters, sorting) => {
          onFiltersChange(filters);
          onSortingChange(sorting);
        }}
        scroll={{ x: 1600 }}
        onFiltersReset={resetFilters}
      />
      {editModal}
    </>
  );
}
