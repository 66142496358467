import { useTranslation } from 'react-i18next';
import {
  Assert,
  CommunityDetailsTabKeys,
  FloorplanDetailsPanel,
} from '@/views';
import { Icon, notify, Page } from '@/components';
import {
  UpdateWholeHomeFloorplanArgs,
  useGetFloorplanDetailsQuery,
  useUpdateWholeHomeFloorplanMutation,
} from '@/redux';
import { useParams } from 'react-router-dom';
import { RoomListTabPanel } from '@/views/Rooms.List/RoomListTabPanel';
import { CommunityPermissions } from '@/core';

const useId = () => {
  const { id } = useParams<'id'>();
  return id!;
};

function _FloorplanDetailsPage() {
  const { t } = useTranslation();
  const id = useId();
  const { data } = useGetFloorplanDetailsQuery({ id });
  const title = `${data?.marketingName ?? t('fetchFallbackTitle')}`;

  const [update] = useUpdateWholeHomeFloorplanMutation();

  const handleWholeHomeUpdate = ({
    squareFootage,
    categories,
  }: UpdateWholeHomeFloorplanArgs) =>
    update({ id, squareFootage, categories })
      .unwrap()
      .then(() => notify.success.t('floorplans.wholeHomeSaved'));

  return (
    <Page htmlTitle={title}>
      <Page.Paths>
        <Page.Path href="/communities">{t('communities.title')}</Page.Path>
        <Page.Path
          href={`/communities/${data?.communityId}#${CommunityDetailsTabKeys.floorplans}`}
        >
          {data?.communityName ?? t('fetchFallbackTitle')}
        </Page.Path>
        <Page.Path
          href={`/communities/${data?.communityId}#${CommunityDetailsTabKeys.floorplans}`}
        >
          {t('floorplans.title')}
        </Page.Path>
        <Page.Path>{title}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card
          collapsible
          defaultCollapsed
          title={title}
          icon={<Icon type="maximize" />}
          color="#107CC7"
        >
          <FloorplanDetailsPanel id={id} />
        </Page.Card>

        <Page.Card>
          {data && (
            <RoomListTabPanel
              communityId={data.communityId}
              floorplanId={id}
              wholeHomeSquareFootage={data?.wholeHomeSquareFootage}
              wholeHomeCategories={data?.categories}
              onWholeHomeUpdate={handleWholeHomeUpdate}
            />
          )}
        </Page.Card>
      </Page.Body>
    </Page>
  );
}

export const FloorplanDetailsPage = Assert.Banner(
  CommunityPermissions.Floorplans.View,
)(_FloorplanDetailsPage);
