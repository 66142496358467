import { enumObject, EnumValue } from '@/utils';

export const CUSTOM_REPORT_COLUMN_VALUES = [
  'LocationName',
  'ConstructionName',
  'ResidentNames',
  'FloorplanName',
  'FloorplanDescription',
  'RoomName',
  'ProjectType',
  'ItemName',
  'ItemDescription',
  'ItemNotes',
  'TotalSquareFoot',
  'HomeName',
  'HomeNumber',
  'Category',
  'Subcategory1',
  'Subcategory2',
  'ItemSubOption',
  'ClientName',
  'ClientId',
  'CommunityName',
  'CommunityId',
  'CommunityStatus',
  'ProjectName',
  'ProjectId',
  'ProjectStatus',
  'PathStepName',
  'FloorplanType',
  'MenuName',
  'IsFinalized',
  'ItemSymbol',
  'SpecialRequestStatus',
  'SpecialRequestNotes',
  'SpecialRequestDate',
  'SpecialRequestComment',
  'SpecialRequestFee',
  'Subcontractor',
  'SquareFootage',
  'OptionCategory',
  'BaseCost',
  'OwnerPrice',
  'ResidentPrice',
  'SubCost',
  'TbdPrice',
  'ConstructionMarkup',
  'ResidentMarkup',
  'ItemCount',
  'Fee',
  'Reimbursement',
  'Credit',
  'Payment',
  'PaymentTotal',
  'Balance',
] as const;

export type CustomReportColumn = EnumValue<typeof CUSTOM_REPORT_COLUMN_VALUES>;

// eslint-disable-next-line
export const CustomReportColumn = enumObject(
  'CustomReportColumn',
  CUSTOM_REPORT_COLUMN_VALUES,
);

export const NoSubTotalReportColumnValues: CustomReportColumn[] = [
  'LocationName',
  'ConstructionName',
  'ResidentNames',
  'FloorplanName',
  'FloorplanDescription',
  'RoomName',
  'ProjectType',
  'ItemName',
  'ItemDescription',
  'ItemNotes',
  'TotalSquareFoot',
  'HomeName',
  'HomeNumber',
  'Category',
  'Subcategory1',
  'Subcategory2',
  'ItemSubOption',
  'ClientName',
  'ClientId',
  'CommunityName',
  'CommunityId',
  'CommunityStatus',
  'ProjectName',
  'ProjectId',
  'ProjectStatus',
  'PathStepName',
  'FloorplanType',
  'MenuName',
  'IsFinalized',
  'ItemSymbol',
  'SpecialRequestStatus',
  'SpecialRequestNotes',
  'SpecialRequestDate',
  'SpecialRequestComment',
  'SpecialRequestFee',
  'Subcontractor',
  'SquareFootage',
  'OptionCategory',
  'BaseCost',
  'OwnerPrice',
  'ResidentPrice',
  'SubCost',
  'TbdPrice',
  'ConstructionMarkup',
  'ResidentMarkup',
];

export const HomeSubtotalReportColumnValues: CustomReportColumn[] = [
  'LocationName',
  'ConstructionName',
  'ResidentNames',
  'FloorplanName',
  'FloorplanDescription',
  'ProjectType',
  'TotalSquareFoot',
  'HomeName',
  'HomeNumber',
  'ClientName',
  'ClientId',
  'CommunityName',
  'CommunityId',
  'CommunityStatus',
  'ProjectName',
  'ProjectId',
  'ProjectStatus',
  'FloorplanType',
  'MenuName',
  'IsFinalized',
  'BaseCost',
  'OwnerPrice',
  'ResidentPrice',
  'ItemCount',
  'Fee',
  'Reimbursement',
  'Credit',
  'Payment',
  'PaymentTotal',
  'Balance',
];

const REPORT_HOME_STATE_OPTION_VALUES = ['Finalized', 'UnFinalized'] as const;

export type ReportHomeStateOption = EnumValue<
  typeof REPORT_HOME_STATE_OPTION_VALUES
>;

// eslint-disable-next-line
export const ReportHomeStateOption = enumObject(
  'ReportHomeStateOption',
  REPORT_HOME_STATE_OPTION_VALUES,
);

const REPORT_PERSONALIZATION_ITEM_LOCATION_OPTION_VALUES = [
  'FloorplanItems',
  'RoomItems',
] as const;

export type ReportPersonalizationItemLocationOption = EnumValue<
  typeof REPORT_PERSONALIZATION_ITEM_LOCATION_OPTION_VALUES
>;

// eslint-disable-next-line
export const ReportPersonalizationItemLocationOption = enumObject(
  'ReportPersonalizationItemLocationOption',
  REPORT_PERSONALIZATION_ITEM_LOCATION_OPTION_VALUES,
);

const REPORT_ITEM_TYPE_OPTION_VALUES = [
  'Standard',
  'Upgrade',
  'SpecialRequest',
] as const;

export type ReportItemTypeOption = EnumValue<
  typeof REPORT_ITEM_TYPE_OPTION_VALUES
>;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportItemTypeOption = enumObject(
  'ReportItemType',
  REPORT_ITEM_TYPE_OPTION_VALUES,
);

export const CUSTOM_REPORT_TYPE_VALUES = [
  'NoSubTotal',
  'HomeSubtotal',
  'CustomTemplate',
] as const;

export type CustomReportType = EnumValue<typeof CUSTOM_REPORT_TYPE_VALUES>;

// eslint-disable-next-line
export const CustomReportType = enumObject(
  'ReportHomeSubTotalOption',
  CUSTOM_REPORT_TYPE_VALUES,
);

export interface GenerateCustomReportFilterDto {
  startDate: string;
  endDate: string;
  clientIds?: string[];
  communityIds?: string[];
  projectIds?: string[];
  homeIds?: string[];
}

export interface GenerateCustomReportOptionsDto {
  type: CustomReportType;
  homeState?: ReportHomeStateOption[];
  location?: ReportPersonalizationItemLocationOption[];
  itemType?: ReportItemTypeOption[];
  hasSubcontractorAssigned?: boolean;
  excelTemplateAttachmentId?: string;
  pathStepIds?: string[];
  subcontractorIds?: string[];
}

export interface GenerateCustomReportDto {
  filter: GenerateCustomReportFilterDto;
  columns: CustomReportColumn[];
  options: GenerateCustomReportOptionsDto;
}

export interface GenerateTrendReportDto {
  startDate?: string;
  endDate?: string;
  clientId: string;
  communityId: string;
  projectIds?: string[];
  homeIds?: string[];
}

export interface ReportingCommunityDto {
  id: string;
  name: string;
}

export interface ReportingProjectDto {
  id: string;
  name: string;
  communityName?: string;
}

export interface ReportingHomeDto {
  id: string;
  homeNumber: string;
  constructionNumber: string;
  number: string;
}

export interface ReportingLocationDto {
  id: string;
  name: string;
}

export interface GeneratePersonalizationSummaryReportDto {
  projectIds: string[];
  homeIds: string[];
}
