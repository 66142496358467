import { EditIconButton, TextBox, Title } from '@/components';
import { CommunityPermissions } from '@/core';
import {
  CategoryState,
  useGetCategoriesQuery,
  useUserContextSelector,
} from '@/redux';
import { tree } from '@/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Assert } from '../Communities.Common';
import { DeleteSubcategoryButton } from './DeleteSubcategoryButton';
import { EditSubcategoryForm } from './EditSubcategoryForm';

interface Props {
  communityId: string;
  id: string;
}

function findFirstParent(categories: CategoryState[], id: string) {
  return tree.findFirstParent(
    categories,
    id,
    (x) => x.id,
    (x) => x.children,
  );
}

function findFirst(categories: CategoryState[], id: string) {
  return tree.findFirst(
    categories,
    id,
    (x) => x.id,
    (x) => x.children,
  );
}

export function SubcategoryDetailsPanel(props: Props) {
  const { communityId, id } = props;

  const { t } = useTranslation();
  const [edit, setEditSubcategory] = useState<boolean>();
  const hasRole = useUserContextSelector((x) => x.hasRole);

  const { data: categories } = useGetCategoriesQuery({
    communityId,
    listed: true,
  });

  if (!categories) {
    return null;
  }

  const parent = findFirstParent(categories, id);
  const children = findFirst(categories, id);
  const canEditSubcategory = hasRole('BFP_ADMIN') || hasRole('CLIENT_ADMIN');

  if (!parent || !children?.custom || !categories) {
    return null;
  }

  const value = {
    parentId: parent!.id,
    name: children!.name,
  };

  if (edit) {
    return (
      <EditSubcategoryForm
        communityId={communityId}
        id={id}
        onCancel={() => setEditSubcategory(false)}
        value={value}
      />
    );
  }

  return (
    <>
      <Title level={2}>
        <Title.Actions space inline>
          {canEditSubcategory && (
            <Assert
              permission={CommunityPermissions.ItemsCategories.Manage}
              active
            >
              <EditIconButton
                type="link"
                onClick={() => setEditSubcategory(true)}
              />
            </Assert>
          )}
          <Assert
            permission={CommunityPermissions.ItemsCategories.Manage}
            active
          >
            <DeleteSubcategoryButton id={id} />
          </Assert>
        </Title.Actions>
        {t('items.details.subcategories.detailsTitle')}
      </Title>
      <TextBox label={t('items.details.subcategories.nameLabel')}>
        {children?.name}
      </TextBox>
    </>
  );
}
