import {
  AddButton,
  TabPanel,
  TreeView,
  TreeViewTexts,
  useTabPanelNavMenuState,
  notify,
} from '@/components';
import { useTranslation } from 'react-i18next';
import {
  useGetLocationsQuery,
  useCreateLocationMutation,
  useUpdateLocationMutation,
  useDeleteLocationMutation,
  LocationState,
} from '@/redux';
import { useMemo, useState } from 'react';
import { array } from '@/utils';
import { Empty } from 'antd';
import { isEmpty } from 'lodash';
import { CommunityPermissions } from '@/core';
import { Assert, useCommunityContext } from '@/views';

interface Props {
  communityId: string;
}

const INITIAL_LOCATION: LocationState = {
  id: null!,
  children: [],
  name: null!,
};

function useTexts(): TreeViewTexts {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      addChildBtnText: t('locations.details.addChild'),
      addTitle: t('locations.details.addTitle'),
      editTitle: t('locations.details.editTitle'),
      title: t('locations.details.title'),
      nameFieldTitle: t('locations.details.name'),
      childNameFieldTitle: t('locations.details.childName'),
      entityName: t('locations.entityName'),
    }),
    [t],
  );
}

export function LocationListPanel(props: Props) {
  const { communityId } = props;
  const { data = array.empty<LocationState>() } = useGetLocationsQuery({
    communityId,
  });
  const [update] = useUpdateLocationMutation();
  const [create] = useCreateLocationMutation();
  const [remove] = useDeleteLocationMutation();
  const { t } = useTranslation();
  const [add, setAdd] = useState(false);
  const texts = useTexts();

  const { assert } = useCommunityContext();

  const items = useMemo(
    () => (add ? [...data, INITIAL_LOCATION] : data),
    [data, add],
  );

  const tabNav = useTabPanelNavMenuState({
    items,
    keyBy: (x) => x.id ?? 'new',
    titleBy: (x) => x.name ?? t('locations.new'),
  });

  const { select, selected } = tabNav;

  const handleAddClick = () => {
    setAdd(true);
    select('new');
  };

  const handleSelect = (id: string) => {
    setAdd(false);
    select(id);
  };

  const handleDelete = (location: LocationState) =>
    remove({ communityId, id: location.id })
      .unwrap()
      .then(() => notify.success.t('locations.deleted'));

  const handleUpdate = (location: LocationState) =>
    update({ communityId, location })
      .unwrap()
      .then(() => notify.success.t('locations.updated'));

  const handleAdd = (location: LocationState) =>
    create({ communityId, location })
      .unwrap()
      .then(() => setAdd(false))
      .then(() => notify.success.t('locations.added'));

  const empty = isEmpty(items);
  const readonly = !assert(CommunityPermissions.Locations.Manage, true);

  return (
    <TabPanel>
      <TabPanel.Title>{t('locations.title')}</TabPanel.Title>
      <TabPanel.Actions>
        <Assert permission={CommunityPermissions.Locations.Manage} active>
          <AddButton onClick={handleAddClick}>{t('locations.add')}</AddButton>
        </Assert>
      </TabPanel.Actions>
      {!empty && (
        <TabPanel.Nav title={t('locations.navTitle')}>
          <TabPanel.Menu<LocationState> {...tabNav} onSelected={handleSelect} />
        </TabPanel.Nav>
      )}
      <TabPanel.Body compact={!empty}>
        {empty && <Empty />}
        {!empty && selected && (
          <TreeView
            key={selected.id}
            uid="location-details"
            value={selected}
            texts={texts}
            keyBy="id"
            titleBy="name"
            childrenBy="children"
            onSubmit={selected.id ? handleUpdate : handleAdd}
            onDelete={selected.id ? handleDelete : undefined}
            onCancel={() => setAdd(false)}
            readonly={readonly}
          />
        )}
      </TabPanel.Body>
    </TabPanel>
  );
}
