import { FormRadioOption, FormsRadio } from './FormsRadio';
import i18next from 'i18next';
import { SpaceProps } from 'antd';

export type FormsYesNoProps = {
  name: string;
  label?: React.ReactNode;
  direction?: SpaceProps['direction'];
};

const OPTIONS: FormRadioOption<boolean>[] = [
  { value: true, label: i18next.t<string>('yesNo.true') },
  { value: false, label: i18next.t<string>('yesNo.false') },
];

export const FormsYesNo = (props: FormsYesNoProps) => {
  const { name, label, direction } = props;

  return (
    <FormsRadio
      name={name}
      options={OPTIONS}
      label={label}
      inline={false}
      direction={direction}
    />
  );
};
