import { FC, useEffect } from 'react';
import { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet-async';
import { ReactChildren } from '@/utils';
import { useTranslation } from 'react-i18next';
import { PagePath, PagePaths, PagePathsRenderer } from './PagePath';
import { Col, ColProps, Row } from 'antd';
import styles from './Page.module.scss';
import classNames from 'classnames';
import { PageCardExports } from './PageCard';
import { PageNavExports } from './PageNav';

const { PageCard } = PageCardExports;
const { PageNav, PageNavItem } = PageNavExports;

export const PageBody: FC<
  PropsWithChildren<
    Pick<ColProps, 'span' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'offset'>
  >
> = () => <></>;

PageBody.defaultProps = {
  xs: { span: 24 },
  lg: { span: 22, offset: 1 },
  xl: { span: 20, offset: 2 },
  xxl: { span: 18, offset: 3 },
};

const _Page: FC<PropsWithChildren<{ htmlTitle?: string }>> = ({
  children,
  htmlTitle,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll({ top: 0 });
  }, []);

  const pageBodyProps = ReactChildren.of(children).find(PageBody).props();
  const pathsProps = ReactChildren.of(children).find(PagePaths).props();

  const { children: _, ...colBodyProps } = pageBodyProps;

  return (
    <div className={styles.page}>
      <Helmet>
        <title>
          {htmlTitle ? `${htmlTitle} / ` : ''}
          {t('brand')}
        </title>
      </Helmet>

      {pageBodyProps && (
        <Row className={styles.body}>
          <Col {...colBodyProps}>
            <div>
              <PagePathsRenderer {...pathsProps} />
            </div>
            <div>{pageBodyProps.children}</div>
          </Col>
        </Row>
      )}
    </div>
  );
};

const TabCard = (props: React.HTMLAttributes<HTMLDivElement>) => (
  <div {...props} className={classNames(props.className, styles.tabCard)} />
);

export const Page = Object.assign(_Page, {
  Body: PageBody,
  Paths: PagePaths,
  Path: PagePath,
  Card: PageCard,
  TabCard: TabCard,
  Nav: PageNav,
  NavItem: PageNavItem,
});
