import {
  LookupSource,
  LookupOption,
  createEntireLoadLookupSource,
} from '@/components';
import { DEFAULT_PAGING } from '@/core';
import {
  SubcontractorLookupState,
  useLazyGetSubcontractorsListQuery,
} from '@/redux';
import { RtkqSpin } from '@/redux/rtkq';
import { useMemo } from 'react';

export function mapSubcontractorLookup(
  value: SubcontractorLookupState,
  includeCommunityName: boolean = false,
): LookupOption {
  return {
    label: includeCommunityName
      ? `${value.communityName} - ${value.name}`
      : value.name,
    value: value.id,
  };
}

export function useSubcontractorLookupSource(
  communityId?: string | string[],
  includeCommunityName: boolean = false,
): LookupSource {
  const [fetchList] = useLazyGetSubcontractorsListQuery();

  return useMemo(() => {
    const entireSource = createEntireLoadLookupSource({
      initial: (id: string) =>
        fetchList(
          { communityId, paging: DEFAULT_PAGING, [RtkqSpin]: false },
          true,
        )
          .unwrap()
          .then((values) =>
            mapSubcontractorLookup(
              values.items.find((x) => x.id === id)!,
              includeCommunityName,
            ),
          ),
      load: () =>
        fetchList(
          { communityId, paging: DEFAULT_PAGING, [RtkqSpin]: false },
          true,
        )
          .unwrap()
          .then((values) =>
            values.items.map((x) =>
              mapSubcontractorLookup(x, includeCommunityName),
            ),
          ),
    });

    return { ...entireSource };
  }, [fetchList, communityId, includeCommunityName]);
}
