import { useTranslation } from 'react-i18next';
import { Tabs } from '@/components';
import { EnumObject, enumObject } from '@/utils';
import { PricingListTabPanel } from '../Pricing.List';
import { PricingSettingsListTabPanel } from '../PricingSettings.List/PricingSettingsListTabPanel';
import { PricingSpecialRequestListTabPanel } from '../PricingSpecialRequest.List/PricingSpecialRequestListTabPanel';
import { CommunityPermissions } from '@/core/permissions.communities/communityPermissions';
import { useCommunityContext } from '../Communities.Common/CommunityContext';
import { PricingHomeOverrideListTabPanel } from '../PricingHomeOverride.List/PricingHomeOverrideListTabPanel';
import { useFullScreenAction } from './useFullScreenAction';
import classNames from 'classnames';
import styles from './PricingDetails.module.scss';

const TAB_KEYS = [
  'pricing',
  'special-request-pricing',
  'settings',
  'home-override',
] as const;
export const PricingTabContentKeys = enumObject(
  'PricingTabContentKeys',
  TAB_KEYS,
);

const { TabPane } = Tabs;

interface Props {
  className?: string;
  communityId: string;
  tabKeys: EnumObject<any>;
}

export const PricingTabContent = (props: Props) => {
  const { className, communityId, tabKeys } = props;
  const { t } = useTranslation();
  const { hasPermission } = useCommunityContext();
  const { fullScreen, fullScreenAction } = useFullScreenAction();

  return (
    <div className={classNames({ [styles.fullScreen]: fullScreen })}>
      <Tabs
        className={className}
        tabKeys={tabKeys}
        destroyInactiveTabPane
        tabBarExtraContent={fullScreenAction}
      >
        <TabPane tab={t('pricing.tabs.pricing')} key={tabKeys['pricing']}>
          <PricingListTabPanel
            communityId={communityId}
            title={t('pricing.title')}
          />
        </TabPane>

        <TabPane
          tab={t('pricing.tabs.specialRequestPricing')}
          key={tabKeys['special-request-pricing']}
        >
          <PricingSpecialRequestListTabPanel communityId={communityId} />
        </TabPane>

        {hasPermission(CommunityPermissions.Pricing.Manage) && (
          <TabPane tab={t('pricing.tabs.settings')} key={tabKeys.settings}>
            <PricingSettingsListTabPanel communityId={communityId} />
          </TabPane>
        )}

        <TabPane
          tab={t('pricing.tabs.homeOverrides')}
          key={tabKeys['home-override']}
        >
          <PricingHomeOverrideListTabPanel communityId={communityId} />
        </TabPane>
      </Tabs>
    </div>
  );
};
