import { FC, useCallback } from 'react';
import { FormikContextType, useFormikContext } from 'formik';
import { useAppFindFormik } from './FormsContext';
import { Button, ButtonProps } from 'antd';
import styles from './Forms.module.scss';
import classNames from 'classnames';

export interface FormsSubmitProps extends ButtonProps {
  uid?: string;
  onSubmitted?: () => any;
  secondary?: boolean;
}

const useHandleClick = (
  { onSubmitted, onClick }: FormsSubmitProps,
  formik: { current: FormikContextType<any> | undefined },
) => {
  return useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      const errors = await formik.current!.validateForm();
      const isValid = Object.keys(errors).length === 0;
      await formik.current!.submitForm();
      isValid && onSubmitted && onSubmitted();
      onClick && onClick(e);
    },
    [formik, onSubmitted, onClick],
  );
};

function SubmitCurrentForm(props: FormsSubmitProps) {
  const formik = useFormikContext();
  const handleClick = useHandleClick(props, { current: formik });
  return <Button {...props} onClick={handleClick} />;
}

function SubmitForm(props: FormsSubmitProps) {
  const { uid, onSubmitted, ...otherProps } = props;
  const form = useAppFindFormik(uid!);
  const handleClick = useHandleClick(props, form);
  return <Button {...otherProps} onClick={handleClick} />;
}

export const FormsSubmit: FC<FormsSubmitProps> = (props) => {
  return props.uid ? (
    <SubmitForm
      {...props}
      className={classNames(
        props.secondary && styles.secondary,
        props.className,
      )}
    />
  ) : (
    <SubmitCurrentForm
      {...props}
      className={classNames(
        props.secondary && styles.secondary,
        props.className,
      )}
    />
  );
};
