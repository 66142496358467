import { NavLink } from '@/components';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './HomeProjectLink.module.scss';
import { useEditHomeAction } from './useEditHomeAction';

interface Props {
  homeId: string;
  projectId?: string;
  projectName?: string;
}

export const HomeProjectLink = (props: Props) => {
  const { homeId, projectId, projectName } = props;
  const { openEdit, editModal } = useEditHomeAction();
  const { t } = useTranslation();

  return (
    <div>
      <span className={styles.label}>{t('homes.project')}</span>
      {projectId ? (
        <NavLink to={`/projects/${projectId}/${'administration'}`}>
          {projectName}
        </NavLink>
      ) : (
        <Button
          type="link"
          className={styles.addToProject}
          onClick={() => openEdit(homeId)}
        >
          {t('homes.details.add')}
        </Button>
      )}
      {editModal}
    </div>
  );
};
