import { useTranslation } from 'react-i18next';
import {
  PersonalizationHistoryState,
  useGetProjectContextQuery,
  useGetProjectHomeInfoQuery,
} from '@/redux';
import { Alert, Typography } from 'antd';
import { PersonalizationTabs } from './PersonalizationTabs';
import { PersonalizationHistorySelect } from './PersonalizationHistorySelect';
import { useState } from 'react';

interface Props {
  homeId: string;
  communityId: string;
  personalizationHistory: PersonalizationHistoryState[];
}

interface PersonalizationContentProps extends Props {
  projectId: string;
}

function PersonalizationContent(props: PersonalizationContentProps) {
  const { homeId, communityId, projectId } = props;
  const { t } = useTranslation();
  const { data } = useGetProjectContextQuery({ id: projectId });
  const { data: info } = useGetProjectHomeInfoQuery({
    homeId,
    id: projectId,
  });

  if (!data) {
    return null;
  }

  if (data.status === 'NotStarted') {
    return (
      <Typography.Text>
        {t('personalization.project.updateProjectStatus')}
      </Typography.Text>
    );
  }

  if (!info?.floorplanId) {
    return (
      <Alert
        type="info"
        description={t('personalization.floorplan.geo.noFloorplan')}
      />
    );
  }

  if (!info?.menuId) {
    return (
      <Alert
        type="info"
        description={t('personalization.floorplan.geo.noMenu')}
      />
    );
  }

  return (
    <PersonalizationTabs
      communityId={communityId}
      homeId={homeId}
      projectId={projectId}
      className="mb-4"
    />
  );
}

export const PersonalizationTabContent = (props: Props) => {
  const { personalizationHistory } = props;
  const [selectedProjectId, setSelectedProjectId] = useState<string>(
    personalizationHistory.at(0)?.projectId!,
  );

  return (
    <>
      <PersonalizationHistorySelect
        onSelect={setSelectedProjectId}
        selected={selectedProjectId}
        value={personalizationHistory}
        className="mb-3"
      />
      <PersonalizationContent
        {...props}
        projectId={selectedProjectId}
        key={selectedProjectId}
      />
    </>
  );
};
