import {
  PathTemplateDto,
  CreatePathTemplateDto,
  PathTemplateDetailsDto,
  UpdatePathTemplateDto,
  pathTemplatesHttp,
  PathTemplateStepDetailsDto,
  Paged,
  Paging,
  DEFAULT_PAGING,
} from '@/core';
import { rtkq, RtkqRequest } from '../rtkq';
import { BFP_ORGANIZATION_ID } from '@/platform';
import { flatten } from 'lodash';
import { api } from '../api';
import { tree } from '@/utils';

export type PathTemplateState = PathTemplateDto;
export type CreatePathTemplateArgs = CreatePathTemplateDto;
export type PathTemplateDetailsState = PathTemplateDetailsDto;
export type PathTemplateStepDetailsState = PathTemplateStepDetailsDto;
export type UpdatePathTemplateArgs = UpdatePathTemplateDto;
export type PathTemplateSourcesState = PathTemplateDto;

const pathTemplatesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPathTemplatesList: build.query<
      Paged<PathTemplateState>,
      RtkqRequest<{
        organizationId?: string;
        communityId?: string;
        paging: Paging;
      }>
    >({
      queryFn: async (args) => {
        return rtkq(args).exec(() => pathTemplatesHttp.getAll(args));
      },
      providesTags: (result) => [
        { type: 'path-template', id: 'list' },
        ...(result?.items || [])
          .flatMap((x) => x.steps.flatMap((x) => x.categories))
          .map((category) => ({ type: 'category' as const, id: category })),
        ...(result?.items || []).map(({ id }) => ({
          type: 'path-template' as const,
          id,
        })),
      ],
    }),

    addPathTemplate: build.mutation<
      string,
      RtkqRequest<CreatePathTemplateArgs>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() => pathTemplatesHttp.create(args));
      },
      invalidatesTags: () => [{ type: 'path-template', id: 'list' }],
    }),

    getPathTemplate: build.query<
      PathTemplateState,
      RtkqRequest<{ id: string }>
    >({
      queryFn: async (args) => {
        const { id } = args;
        return await rtkq(args).exec(() => pathTemplatesHttp.get(id));
      },
      providesTags: (pathTemplate) => [
        { type: 'path-template', id: pathTemplate?.id ?? 'none' },
        ...(pathTemplate?.steps
          .flatMap((x) => x.categories)
          .map((category) => ({ type: 'category' as const, id: category })) ??
          []),
      ],
    }),

    getPathTemplateDetails: build.query<
      PathTemplateDetailsState,
      RtkqRequest<{ id: string; includeCommunityLevel: boolean }>
    >({
      queryFn: async (args) => {
        const { id, includeCommunityLevel } = args;
        return await rtkq(args).exec(() =>
          pathTemplatesHttp.getDetails(id, includeCommunityLevel),
        );
      },
      providesTags: (pathTemplate) => {
        if (!pathTemplate) {
          return [{ type: 'path-template' as const, id: 'none' }];
        }

        return [
          { type: 'path-template' as const, id: pathTemplate.id },

          ...tree
            .flatten(pathTemplate.steps.flatMap((x) => x.selectedCategories))
            .map((category) => ({
              type: 'category' as const,
              id: category.id,
            })),
        ];
      },
    }),

    updatePathTemplate: build.mutation<
      void,
      RtkqRequest<UpdatePathTemplateArgs>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() => pathTemplatesHttp.update(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'path-template', id: args.id },
      ],
    }),

    unlistPathTemplate: build.mutation<void, RtkqRequest<{ id: string }>>({
      queryFn: (args) => {
        const { id } = args;
        return rtkq(args).exec(() => pathTemplatesHttp.unlist(id));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'path-template', id: args.id },
        { type: 'path-template', id: 'list' },
      ],
    }),

    getPathTemplateSources: build.query<
      PathTemplateSourcesState[],
      RtkqRequest<{ organizationId?: string; communityId?: string }>
    >({
      queryFn: async (args) => {
        const { organizationId, communityId } = args;
        return rtkq(args).exec(async () => {
          const paging = DEFAULT_PAGING;
          const promises = [
            pathTemplatesHttp
              .getAll({
                organizationId: BFP_ORGANIZATION_ID,
                paging,
              })
              .then((x) => x.items),
            organizationId === BFP_ORGANIZATION_ID
              ? []
              : pathTemplatesHttp
                  .getAll({
                    organizationId: organizationId,
                    communityId,
                    paging,
                  })
                  .then((x) => x.items),
          ];
          return flatten(await Promise.all(promises));
        });
      },
      providesTags: (result = []) => [
        { type: 'path-template', id: 'list' },
        ...result.map(({ id }) => ({ type: 'path-template' as const, id })),
      ],
    }),
  }),
});

export const {
  useGetPathTemplatesListQuery,
  useLazyGetPathTemplatesListQuery,
  useAddPathTemplateMutation,
  useGetPathTemplateQuery,
  useUpdatePathTemplateMutation,
  useGetPathTemplateSourcesQuery,
  useGetPathTemplateDetailsQuery,
  useUnlistPathTemplateMutation,
  useLazyGetPathTemplateSourcesQuery,
} = pathTemplatesApi;
