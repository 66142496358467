import { Col } from 'antd';
import {
  useClientLookupSource,
  useCommunityLookupSource,
  useProjectLookupSource,
} from '@/views';
import { AsyncSelect, StyledRow } from '@/components';
import { useEffect, useState } from 'react';
import styles from './SelectPanel.module.scss';
import {
  useGetLastCreatedCommunityQuery,
  useUserContextSelector,
} from '@/redux';
import { useTranslation } from 'react-i18next';

interface Props {
  clientId?: string;
  communityId?: string;
  projectId?: string;
  setClientId: (value?: string) => any;
  setCommunityId: (value?: string) => any;
  setProjectId: (value?: string) => any;
  isAdmin: boolean;
}

export function IndexSelectPanel(props: Props) {
  const {
    clientId,
    communityId,
    projectId,
    setClientId,
    setCommunityId,
    setProjectId,
    isAdmin,
  } = props;

  const { t } = useTranslation(undefined, { keyPrefix: 'index.selectPanel' });
  const preloadClientId = useUserContextSelector((x) => x.clientId);

  const { data: lastCreatedCommunity } = useGetLastCreatedCommunityQuery({
    clientId: preloadClientId,
  });

  const clientDataSource = useClientLookupSource();
  const communityDataSource = useCommunityLookupSource(clientId || false);
  const projectDataSource = useProjectLookupSource(communityId, true);

  const communityPreloadInitial = communityId || lastCreatedCommunity?.id;
  const clientPreloadInitial =
    (isAdmin ? clientId : undefined) ||
    (lastCreatedCommunity
      ? {
          value: lastCreatedCommunity.clientId,
          label: lastCreatedCommunity.clientName,
        }
      : undefined);

  const [isPreloaded, setIsPreloaded] = useState<boolean>(false);

  const handleClientIdChange = (clientId?: string) => {
    setClientId(clientId);
    sessionStorage.removeItem('clientId');
    clientId && sessionStorage.setItem('clientId', clientId);
  };

  const handleCommunityIdChange = (communityId?: string) => {
    setCommunityId(communityId);
    sessionStorage.removeItem('communityId');
    communityId && sessionStorage.setItem('communityId', communityId);
  };

  const handleProjectIdChange = (projectId?: string) => {
    setProjectId(projectId);
    sessionStorage.removeItem('projectId');
    projectId && sessionStorage.setItem('projectId', projectId);
  };

  useEffect(() => {
    !clientId && handleClientIdChange(lastCreatedCommunity?.clientId);
    !clientId && handleCommunityIdChange(lastCreatedCommunity?.id);
    !clientId && setIsPreloaded(!lastCreatedCommunity);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastCreatedCommunity]);

  useEffect(() => {
    isPreloaded && handleCommunityIdChange(undefined);
    setIsPreloaded(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, setClientId]);

  useEffect(() => {
    isPreloaded && handleProjectIdChange(undefined);
    setIsPreloaded(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityId, setCommunityId]);

  return (
    <StyledRow className={styles.container}>
      <Col span={8}>
        <AsyncSelect
          value={clientId}
          onChange={handleClientIdChange}
          dataSource={clientDataSource}
          disabled={!isAdmin}
          placeholder={t('clientPlaceholder')}
          preloadInitial={clientPreloadInitial}
        />
      </Col>
      <Col span={8}>
        <AsyncSelect
          key={clientId}
          value={communityId}
          onChange={handleCommunityIdChange}
          dataSource={communityDataSource}
          disabled={!clientId && !preloadClientId}
          preloadInitial={communityPreloadInitial}
          preload
        />
      </Col>
      <Col span={8}>
        <AsyncSelect
          key={communityId}
          value={projectId}
          onChange={handleProjectIdChange}
          dataSource={projectDataSource}
          disabled={!communityId}
          placeholder={t('projectPlaceholder')}
          preloadInitial={projectId}
          preload
        />
      </Col>
    </StyledRow>
  );
}
