import { notify } from '@/components';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { KeepExistingDetailsModal } from './KeepExistingDetailsModal';
import {
  AddExistingItemArgs,
  useKeepExistingMutation,
  useUnkeepExistingItemMutation,
} from '@/redux';
import { useMemo, useState } from 'react';

interface Props {
  id?: string;
  stepId?: string;
  projectId: string;
  homeId: string;
  roomId?: string;
  categoryId: string;
  isKeepExistingAvailable: boolean;
}

const useKeepSubmit = (onClose: () => any) => {
  const [keepExisting] = useKeepExistingMutation();

  return (args: AddExistingItemArgs) =>
    keepExisting(args)
      .unwrap()
      .then(() => {
        notify.success.t('personalization.keepExisting.added');
        onClose();
      });
};

const useUnkeepSubmit = () => {
  const [unkeepExisting] = useUnkeepExistingItemMutation();

  return (args: { id: string; categoryId: string; stepId: string }) =>
    unkeepExisting(args)
      .unwrap()
      .then(() => notify.success.t('personalization.keepExisting.deleted'));
};

function useInitialValue({
  stepId,
  projectId,
  homeId,
  roomId,
  categoryId,
}: Props) {
  return useMemo<AddExistingItemArgs>(
    () => ({
      name: null!,
      stepId: stepId!,
      categoryId: categoryId ?? null!,
      projectId,
      homeId,
      roomId: roomId,
    }),
    [stepId, categoryId, projectId, homeId, roomId],
  );
}

export function KeepExistingButton(props: Props) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'personalization.keepExisting',
  });
  const [openModal, setOpenModal] = useState(false);
  const value = useInitialValue(props);
  const submit = useKeepSubmit(() => setOpenModal(false));
  const unkeepSubmit = useUnkeepSubmit();
  const { isKeepExistingAvailable, id, stepId, categoryId } = props;

  return (
    <>
      <Button
        type="default"
        onClick={() =>
          isKeepExistingAvailable
            ? setOpenModal(true)
            : unkeepSubmit({ id: id!, stepId: stepId!, categoryId })
        }
      >
        {t(isKeepExistingAvailable ? 'keep' : 'unkeep')}
      </Button>
      {openModal && (
        <KeepExistingDetailsModal
          onSubmit={submit}
          onClose={() => setOpenModal(false)}
          value={value}
        />
      )}
    </>
  );
}
