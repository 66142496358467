import { AttachmentDto } from '@/core';
import { ClippoOptions } from '@webinex/clippo';
import { Space } from 'antd';
import { useField } from 'formik';
import { useEffect } from 'react';
import { ImageBox, useImageBoxClippo } from '../ImageBox';
import { EditIconButton } from '../Styled';
import { FormsGroup } from './FormsGroup';
import { useFormLabel } from './FormsI18nContext';

export interface FormsImagesProps extends ClippoOptions<AttachmentDto> {
  name: string;
  label?: React.ReactNode;
  compact?: boolean;
}

export function FormsImages(props: FormsImagesProps) {
  const { name, compact, ...options } = props;
  const { imageBox, clippo } = useImageBoxClippo(options);
  const [, , { setValue }] = useField(name);
  const label = useFormLabel(props.name, props.label);

  const {
    value: { actions },
  } = clippo;

  useEffect(() => {
    setValue(actions);

    // eslint-disable-next-line
  }, [actions, name]);

  return (
    <FormsGroup
      label={
        <Space>
          {label} <EditIconButton onClick={imageBox.toggleGallery} />
        </Space>
      }
      name={name}
      required={false}
    >
      <ImageBox {...imageBox} compact={compact} />
    </FormsGroup>
  );
}
