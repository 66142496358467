import { Space } from 'antd';
import { DataNode } from 'antd/lib/tree';
import {
  EditIconButton,
  DeleteIconButton,
  Title,
  Icon,
  DirectoryTree,
} from '@/components';

export interface TreeViewReadTexts {
  title: React.ReactNode;
  entityName: string;
}

interface Props {
  value: DataNode;
  canEdit: boolean;
  texts: TreeViewReadTexts;
  onEdit: (value: DataNode) => any;
  onDelete: (value: DataNode) => any;
  readonly?: boolean;
}

export function TreeViewRead(props: Props) {
  const { value, onDelete, canEdit, onEdit, texts, readonly } = props;
  const { title, entityName } = texts;

  return (
    <div>
      <Title>
        <Title.Actions inline>
          {!readonly && (
            <Space>
              {canEdit && (
                <EditIconButton type="link" onClick={() => onEdit(value)} />
              )}
              <DeleteIconButton
                confirm
                entityName={entityName}
                type="link"
                onClick={() => onDelete(value)}
              />
            </Space>
          )}
        </Title.Actions>

        {title}
      </Title>
      <DirectoryTree
        selectable={false}
        treeData={value?.children}
        icon={null}
        switcherIcon={<Icon type="arrow-down" />}
      />
    </div>
  );
}
