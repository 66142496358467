import { useTranslation } from 'react-i18next';
import { Auth, TabPanel, Tabs } from '@/components';
import {
  AddClientUserButton,
  AddCommunityButton,
  ClientUsersListPanel,
  CommunitiesListPanel,
  ContactListPanel,
} from '@/views';
import { enumObject } from '@/utils';

const TAB_KEYS = ['contacts', 'communities', 'users'] as const;

export const ClientDetailsTabKeys = enumObject(
  'ClientDetailsTabKeys',
  TAB_KEYS,
);

const { TabPane } = Tabs;

export function ClientDetailsTabsPanel({ clientId }: { clientId: string }) {
  const { t } = useTranslation(undefined, { keyPrefix: 'clients.tabs' });
  return (
    <Tabs tabKeys={ClientDetailsTabKeys} destroyInactiveTabPane>
      <TabPane tab={t('communities')} key={ClientDetailsTabKeys.communities}>
        <TabPanel>
          <TabPanel.Title>{t('communities')}</TabPanel.Title>
          <TabPanel.Actions>
            <Auth role="BFP_ADMIN">
              <AddCommunityButton clientId={clientId} />
            </Auth>
          </TabPanel.Actions>
          <TabPanel.Body>
            <CommunitiesListPanel clientId={clientId} />
          </TabPanel.Body>
        </TabPanel>
      </TabPane>
      <TabPane tab={t('users')} key={ClientDetailsTabKeys.users}>
        <TabPanel>
          <TabPanel.Title>{t('users')}</TabPanel.Title>
          <TabPanel.Actions>
            <Auth role="BFP_ADMIN">
              <AddClientUserButton
                organizationId={clientId}
                disableClientSelect
              />
            </Auth>
          </TabPanel.Actions>
          <TabPanel.Body>
            <ClientUsersListPanel organizationId={clientId} />
          </TabPanel.Body>
        </TabPanel>
      </TabPane>
      <TabPane tab={t('contacts')} key={ClientDetailsTabKeys.contacts}>
        <ContactListPanel clientId={clientId} />
      </TabPane>
    </Tabs>
  );
}
