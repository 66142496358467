import { useEffect } from 'react';
import { UseImagesPreviewResult } from './useImagesPreview';

export function useImagePreviewLazyLoad(
  props: UseImagesPreviewResult,
  load: (id: string) => any,
  current?: number,
) {
  const { items, index } = props;
  const currentId = current && items[current - 1]?.id;

  useEffect(() => {
    if (index != null && items.length === 1) {
      load(items[0].id);
    }

    // eslint-disable-next-line
  }, [index]);

  useEffect(() => {
    currentId && load(currentId);

    // eslint-disable-next-line
  }, [currentId]);
}
