import { UsePagingArgs } from '@/components/Collections.Paging';
import { PriceMatrixFilterParams } from '@/core';

export function usePricingSessionValues() {
  const pricingCommunityId =
    window.sessionStorage.getItem('pricingCommunityId') ?? undefined;

  const pricingMenuId =
    window.sessionStorage.getItem('pricingMenuId') ?? undefined;

  const collapseFloorplanState = JSON.parse(
    window.sessionStorage.getItem('collapseFloorplanState') ?? 'false',
  );

  const collapseRoomState = JSON.parse(
    window.sessionStorage.getItem('collapseRoomState') ?? 'false',
  );

  const collapseCostState = JSON.parse(
    window.sessionStorage.getItem('collapseCostState') ?? 'false',
  );

  const pagingState = JSON.parse(
    window.sessionStorage.getItem('pagingState') ?? '{}',
  );

  const filtersState = JSON.parse(
    window.sessionStorage.getItem('filtersState') ?? '{}',
  );

  const savePricingCommunityId = (communityId: string | undefined) => {
    communityId &&
      window.sessionStorage.setItem('pricingCommunityId', communityId);

    window.sessionStorage.removeItem('pricingMenuId');
    window.sessionStorage.removeItem('collapseFloorplanState');
    window.sessionStorage.removeItem('collapseRoomState');
    window.sessionStorage.removeItem('collapseCostState');
    window.sessionStorage.removeItem('pagingState');
    window.sessionStorage.removeItem('filtersState');
  };

  const savePricingMenuId = (menuId: string | undefined) => {
    menuId && window.sessionStorage.setItem('pricingMenuId', menuId);
  };

  const saveCollapseFloorplanState = (state: boolean) => {
    window.sessionStorage.setItem(
      'collapseFloorplanState',
      JSON.stringify(state),
    );
  };

  const saveCollapseRoomState = (state: boolean) => {
    window.sessionStorage.setItem('collapseRoomState', JSON.stringify(state));
  };

  const saveCollapseCostState = (state: boolean) => {
    window.sessionStorage.setItem('collapseCostState', JSON.stringify(state));
  };

  const savePagingState = (pagingState: UsePagingArgs) => {
    window.sessionStorage.setItem('pagingState', JSON.stringify(pagingState));
  };

  const saveFiltersState = (filtersState: PriceMatrixFilterParams) => {
    window.sessionStorage.setItem('filtersState', JSON.stringify(filtersState));
  };

  return {
    pricingCommunityId,
    pricingMenuId,
    collapseFloorplanState,
    collapseRoomState,
    collapseCostState,
    pagingState,
    filtersState,
    savePricingCommunityId,
    saveCollapseFloorplanState,
    saveCollapseRoomState,
    saveCollapseCostState,
    savePricingMenuId,
    savePagingState,
    saveFiltersState,
  };
}
