import { useCallback } from 'react';
import {
  PriceMatrixRowState,
  SaveItemPriceArgs,
  UpdateItemMenuItemPricingInfoArgs,
  useDeleteItemPriceMutation,
  useSaveItemPriceMutation,
  useUpdateItemMenuItemPricingInfoMutation,
} from '@/redux';
import { notify } from '@/components';
import { Paging, PriceMatrixFilterParams } from '@/core';

type RowValueName = keyof Pick<
  SaveItemPriceArgs,
  'baseCost' | 'ownerPrice' | 'residentPrice'
>;

function useDeleteRow(communityId: string, menuId: string) {
  const [remove] = useDeleteItemPriceMutation();

  return useCallback(
    (
      row: PriceMatrixRowState,
      paging: Paging,
      filters?: PriceMatrixFilterParams,
    ) =>
      remove({
        itemPrice: {
          communityId,
          menuId,
          floorplanId: row.floorplan?.id,
          itemId: row.itemId,
          roomId: row.room?.id,
        },
        paging,
        filters,
      })
        .unwrap()
        .then(() => notify.success.t('pricing.saved')),
    [remove, communityId, menuId],
  );
}

function useSaveRowValue(communityId: string, menuId: string) {
  const [save] = useSaveItemPriceMutation();

  return useCallback(
    (
      row: PriceMatrixRowState,
      name: RowValueName,
      value: number | undefined,
      paging: Paging,
      filters?: PriceMatrixFilterParams,
    ) =>
      save({
        itemPrice: {
          itemId: row.itemId,
          floorplanId: row.floorplan?.id,
          roomId: row.room?.id,
          baseCost: row.prices?.baseCost!,
          ownerPrice: row.prices?.ownerPrice?.isOverriden
            ? row.prices?.ownerPrice?.value
            : undefined,
          residentPrice: row.prices?.residentPrice?.isOverriden
            ? row.prices?.residentPrice?.value
            : undefined,
          [name]: value,
          communityId,
          menuId,
        },
        paging,
        filters,
      })
        .unwrap()
        .then(() => notify.success.t('pricing.saved')),
    [save, communityId, menuId],
  );
}

export function usePriceMatrixPricingInfoChangeHandlerFactory(
  communityId: string,
  menuId: string,
  onBeforeRowChange: () => any,
  paging: Paging,
) {
  const [update] = useUpdateItemMenuItemPricingInfoMutation();

  return useCallback(
    (
        item: PriceMatrixRowState,
        name: keyof Pick<
          UpdateItemMenuItemPricingInfoArgs,
          'standardCredit' | 'type'
        >,
      ) =>
      async (value: any) => {
        const args: UpdateItemMenuItemPricingInfoArgs = {
          communityId,
          menuId,
          id: item.itemId,
          standardCredit: item.standardCredit,
          type: item.itemType,
          [name]: value,
        };
        onBeforeRowChange();
        if (args.type === 'Upgrade') args.standardCredit = false;

        await update({ itemPrice: args, paging }).unwrap();
        return notify.success.t('pricing.saved');
      },
    [communityId, menuId, onBeforeRowChange, paging, update],
  );
}

export function usePriceMatrixRowChangeHandlerFactory(
  communityId: string,
  menuId: string,
  onBeforeRowChange: () => any,
  paging: Paging,
  filters?: PriceMatrixFilterParams,
) {
  const saveRowValue = useSaveRowValue(communityId, menuId);
  const deleteRow = useDeleteRow(communityId, menuId);

  return useCallback(
    (item: PriceMatrixRowState, name: RowValueName) =>
      (value: number | undefined) => {
        onBeforeRowChange();
        if (name === 'baseCost' && value == null) {
          return deleteRow(item, paging, filters);
        }

        return saveRowValue(item, name, value, paging, filters);
      },
    [onBeforeRowChange, saveRowValue, paging, filters, deleteRow],
  );
}
