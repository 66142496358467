import {
  ColumnFilterItem,
  FilterDropdownProps,
  FilterSearchType,
  FilterValue,
} from 'antd/lib/table/interface';
import { enumName, EnumObject } from '@/utils';
import { useTranslation } from 'react-i18next';
import { useCallback, useRef } from 'react';
import { DateTimeRangeFilter } from './DateTimeRangeFilter';
import { NumberRangeFilter } from './NumberRangeFilter';
import { isArray } from 'lodash';
import { PlainTextFilter } from './PlainTextFilter';
import { StringFilterOptions } from '@/core';
import { TreeFilter } from './TreeFilter';

export function useEnumFilterOptionFactory() {
  const { t } = useTranslation();
  const cache = useRef<Record<string, ColumnFilterItem[]>>({});

  return useCallback(
    <TEnum extends Readonly<string[]>>(
      enumType: EnumObject<TEnum>,
      values?: TEnum[number][],
    ) => {
      const name = enumName(enumType);
      const cacheKey = values
        ? `${name}-${values.slice().sort().join('-')}`
        : name;

      if (cache.current[cacheKey]) {
        return cache.current[cacheKey];
      }

      values = values ?? Object.values(enumType);
      const options = values.map((value) => ({
        text: t(`enums.${name}.${value as string}`),
        value: value as string,
      }));

      cache.current[cacheKey] = options;
      return options;
    },
    [t, cache],
  );
}

export function useYesNoFilterOptionFactory() {
  const { t } = useTranslation();
  return useCallback(
    () => [
      { text: t('yesNo.true'), value: true },
      { text: t('yesNo.false'), value: false },
    ],
    [t],
  );
}

export interface FiltersColumnDataType<TRecord> {
  filters?: ColumnFilterItem[];
  filterDropdown?:
    | React.ReactNode
    | ((props: FilterDropdownProps) => React.ReactNode);
  filterMultiple?: boolean;
  filteredValue?: FilterValue | null;
  defaultFilteredValue?: FilterValue | null;
  filterIcon?: React.ReactNode | ((filtered: boolean) => React.ReactNode);
  filterMode?: 'menu' | 'tree';
  filterSearch?: FilterSearchType;
  onFilter?: (value: string | number | boolean, record: TRecord) => boolean;
  filterDropdownVisible?: boolean;
  onFilterDropdownVisibleChange?: (visible: boolean) => void;
  filterResetToDefaultFilteredValue?: boolean;
}

export function useFiltersFactory<T>(
  data: T[] | undefined,
  stringFilterOptions?: StringFilterOptions,
) {
  const enumFilterOptionsFactory = useEnumFilterOptionFactory();
  const yesNoFilterOptionsFactory = useYesNoFilterOptionFactory();

  return {
    plainText: <A extends unknown>(
      callback: (x: T) => A,
      filter?: (filterValue: string, record: T) => boolean,
    ): FiltersColumnDataType<T> => ({
      filterDropdown: PlainTextFilter,
      onFilter: (value, record) =>
        filter
          ? filter(value.toString(), record)
          : PlainTextFilter.onFilter(value, callback(record)),
    }),

    yesNo: <A extends unknown>(
      callback: (x: T) => A,
    ): FiltersColumnDataType<T> => ({
      filters: yesNoFilterOptionsFactory(),
      onFilter: (value, record) => callback(record) === value,
    }),

    dateTimeRange: <A extends unknown>(
      callback: (x: T) => A,
    ): FiltersColumnDataType<T> => ({
      filterDropdown: DateTimeRangeFilter,
      onFilter: (value, record) =>
        DateTimeRangeFilter.onFilter(value, callback(record)),
    }),

    enumSelect: <A, TEnum extends Readonly<string[]>>(
      callback: (x: T) => A,
      enumType: EnumObject<TEnum>,
      values?: TEnum[number][],
    ): FiltersColumnDataType<T> => ({
      filterSearch: (value: string, record: any) =>
        record.value.toLowerCase().includes(value.toLowerCase()),
      filters: enumFilterOptionsFactory(enumType, values),
      onFilter: (valueInput, record) => {
        const value = valueInput as string;
        const recordValue = callback(record) as unknown as
          | string
          | null
          | undefined
          | string[];

        if (isArray(recordValue)) {
          return recordValue.includes(value);
        }

        return recordValue === value;
      },
      filterMode: 'tree',
    }),

    numberRange: <A extends unknown>(
      callback: (x: T) => A,
    ): FiltersColumnDataType<T> => ({
      filterDropdown: NumberRangeFilter,
      onFilter: (value, record) =>
        NumberRangeFilter.onFilter(value, callback(record)),
    }),

    select: <A extends unknown>(
      callback: (x: T) => A,
      key: string,
      searchType?: 'include' | 'equal',
      customOption?: string,
    ): FiltersColumnDataType<T> => ({
      filterSearch: (value: string, record: any) =>
        record.value.toLowerCase().includes(value.toLowerCase()),
      filterDropdown: TreeFilter,
      filters: [
        ...(customOption ? [{ text: customOption, value: customOption }] : []),
        ...(stringFilterOptions ? stringFilterOptions[key] : []).map((x) => {
          return { text: x, value: x };
        }),
      ],
      onFilter: (valueInput, record) => {
        const value = valueInput as string;
        const recordValue = callback(record) as unknown as
          | string
          | null
          | undefined
          | string[];

        if (isArray(recordValue)) {
          return recordValue.includes(value);
        }

        return searchType === 'include'
          ? recordValue?.includes(value) ?? false
          : recordValue === value;
      },
    }),
  };
}
