import { SpecialRequestState, useDeleteSpecialRequestMutation } from '@/redux';
import {
  DeleteIconButton,
  EnumValue,
  ImageBox,
  notify,
  StyledRow,
  useImageBoxLazy,
  YesNo,
} from '@/components';
import { useTranslation } from 'react-i18next';
import { Card, Col, Space, Tag, Typography } from 'antd';
import { CommunityPermissions, formatters, SpecialRequestStatus } from '@/core';
import { Assert, useProjectContext } from '@/views';
import { EditSpecialRequestButton } from './EditSpecialRequestButton';
import styles from '../PersonalizationListItem.module.scss';
import { useEffect } from 'react';
import { eventBus } from '@/core';

const { Paragraph } = Typography;

interface Props {
  item: SpecialRequestState;
  className?: string;
  readonly?: boolean;
  specialRequestMark?: boolean;
  compact?: boolean;
}

export const useDeleteSpecialRequest = () => {
  const [deleteItem] = useDeleteSpecialRequestMutation();

  return (id: string, stepId: string) => {
    deleteItem({ id, stepId })
      .unwrap()
      .then(() => notify.success.t('specialRequests.deleted'));
  };
};

const { Title, Text } = Typography;

export function SpecialRequestListItem(props: Props) {
  const { item, className, readonly, specialRequestMark, compact } = props;
  const { t } = useTranslation();
  const { name, description, status, hasAnyComment, prices, number } = item;
  const { residentPrice } = prices ?? {};
  const handleDelete = useDeleteSpecialRequest();
  const { isActive } = useProjectContext();

  const clippoImagesProps = useImageBoxLazy({
    defer: false,
    ownerId: item.id,
    ownerType: 'SpecialRequestImage',
  });

  useEffect(() => {
    const unsubscribe = eventBus.subscribe(
      'special-request-images-updated',
      (id: string) => id === item.id && clippoImagesProps.fetch(),
    );

    return () => {
      unsubscribe();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className}>
      <Card className={styles.cardBody} bordered={false}>
        <StyledRow wrap={compact}>
          <Col>
            <ImageBox
              {...clippoImagesProps}
              compact={compact}
              inCard
              readonly
            />
          </Col>
          <Col flex="auto">
            <StyledRow justify="space-between" wrap={compact} align="middle">
              <Col flex="auto">
                <Title level={3} ellipsis={{ tooltip: true, rows: 3 }}>
                  {number} {name}
                </Title>
              </Col>
              {specialRequestMark && (
                <Col className="text-end">
                  <Title level={5}>
                    {t('specialRequests.details.specialRequestMark')}
                  </Title>
                </Col>
              )}
            </StyledRow>
            <StyledRow>
              <Col>
                <Paragraph
                  className={styles.description}
                  ellipsis={{ tooltip: true, rows: 2 }}
                >
                  {description}
                </Paragraph>
                <div className={styles.comments}>
                  <span className={styles.label}>
                    {t('specialRequests.details.comments')}
                  </span>
                  : <YesNo>{hasAnyComment}</YesNo>
                </div>
              </Col>
            </StyledRow>
            <StyledRow justify="space-between">
              <Col>
                <Tag color="blue">
                  <span className={styles.label}>
                    {t('specialRequests.details.status')}
                  </span>
                  : <EnumValue type={SpecialRequestStatus} value={status} />
                </Tag>
              </Col>
              <Col className="text-end">
                <Title level={2}>
                  {residentPrice != null && formatters.money(residentPrice)}
                  {residentPrice == null && (
                    <Text type="danger">
                      {t('specialRequests.details.tbd')}
                    </Text>
                  )}
                </Title>
              </Col>
            </StyledRow>
          </Col>
          <Col className="text-end">
            {!readonly && isActive && (
              <Assert
                permission={
                  CommunityPermissions.Personalization.SpecialRequestComment
                }
                active
              >
                <Space>
                  <EditSpecialRequestButton
                    value={item}
                    stepId={item.step.id}
                    projectId={item.projectId}
                    homeId={item.homeId}
                    disabledCategory={specialRequestMark}
                  />
                  {
                    <Assert
                      permission={CommunityPermissions.Personalization.Manage}
                      active
                    >
                      <DeleteIconButton
                        onClick={() => handleDelete(item.id, item.step.id)}
                        confirm
                        entityName={t('specialRequests.entityName')}
                      />
                    </Assert>
                  }
                </Space>
              </Assert>
            )}
          </Col>
        </StyledRow>
      </Card>
    </div>
  );
}
