import { CategoryState, ItemState } from '@/redux';
import { DataNode } from 'antd/lib/tree';

interface ConvertArgs {
  categories: CategoryState[];
  items: ItemState[];
  mapItem: (item: ItemState) => DataNode;
  mapCategory: (category: CategoryState) => DataNode;
}

export function convertCategoryAndItemsToTreeData(args: ConvertArgs) {
  const { categories, items, mapItem, mapCategory: _mapCategory } = args;

  function mapCategory(category: CategoryState): DataNode {
    const categoryChildren = category!.children
      ? category!.children.map(mapCategory)
      : [];

    const itemChildren = items!
      .filter((x) => x.categoryId === category.id)
      .map(mapItem);

    return {
      ..._mapCategory(category),
      children: [...categoryChildren, ...itemChildren],
    };
  }

  return categories!.map(mapCategory);
}
