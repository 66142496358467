import { useAppClippo } from '@/core';
import { Dropdown, Menu, Space, Typography, Image } from 'antd';
import { useMemo } from 'react';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { DownOutlined } from '@ant-design/icons';
import loading from '@/assets/images/load.gif';
import { useClippoBlobs } from '@/utils';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './FloorplanPersonalization.module.scss';
import {
  useGetProjectHomeInfoQuery,
  useUpdateProjectHomeFloorplanImageMutation,
} from '@/redux';
import {
  EditIconButton,
  ImageBox,
  notify,
  useImageBox,
  useUploadImagesFromPdf,
} from '@/components';

interface Props {
  projectId: string;
  homeId: string;
  className?: string;
}

function DropdownMenu({
  floorplanId,
  onSave,
  imageBox,
}: {
  floorplanId: string;
  homeId: string;
  onSave: (value: any) => any;
  imageBox?: any;
}) {
  const clippoProps = useAppClippo({
    defer: false,
    ownerId: floorplanId,
    ownerType: 'FloorplanImage',
  });
  const { blobs } = useClippoBlobs(clippoProps);
  const homeBlobs = useClippoBlobs(imageBox);

  const menuItems: ItemType[] = useMemo(() => {
    const unitedBlobs = blobs.concat(homeBlobs.blobs);
    return unitedBlobs.map((value) => ({
      key: value.id,
      label: (
        <Space direction="horizontal" onClick={() => onSave(value.id)}>
          {value.blobURL ? (
            <Image
              width={70}
              height={70}
              src={value.blobURL}
              preview={{ visible: false, mask: '' }}
            />
          ) : (
            <Image width={70} height={70} className="--loading" src={loading} />
          )}
          <span className={classNames('text-multiline', styles.imageName)}>
            {value.fileName}
          </span>
        </Space>
      ),
    }));
  }, [blobs, onSave, homeBlobs]);

  return <Menu items={menuItems} />;
}

export function FloorplanPersonalizationImagesControl(props: Props) {
  const { t } = useTranslation();
  const { projectId, homeId, className } = props;
  const { data } = useGetProjectHomeInfoQuery({ id: projectId, homeId });

  const imageBox = useImageBox({
    defer: false,
    ownerId: homeId,
    ownerType: 'ProjectHomeFloorplanImage',
  });

  const [update] = useUpdateProjectHomeFloorplanImageMutation();
  const uploadImages = useUploadImagesFromPdf(
    imageBox,
    homeId,
    'ProjectHomeFloorplanImage',
  );

  const handleSave = (attachmentId: string) =>
    update({ homeId, projectId, attachmentId })
      .unwrap()
      .then(() => notify.success.t('personalization.floorplan.imageUpdated'));

  if (!data || !data.floorplanId) {
    return null;
  }

  return (
    <>
      <Space direction="horizontal">
        <Dropdown
          className={className}
          overlay={
            <DropdownMenu
              floorplanId={data.floorplanId}
              homeId={data.homeId}
              onSave={handleSave}
              imageBox={imageBox}
            />
          }
          trigger={['click']}
        >
          <Typography.Link>
            <Space>
              <Space>
                {t('personalization.floorplan.selectImage')}
                <DownOutlined />
              </Space>
            </Space>
          </Typography.Link>
        </Dropdown>
        <EditIconButton
          className={'ms-2'}
          type="link"
          onClick={imageBox.toggleGallery}
        />
      </Space>
      <ImageBox
        {...imageBox}
        galleryOnly
        acceptPdf
        onStore={uploadImages}
      ></ImageBox>
    </>
  );
}
