import { useRemoveClientMutation } from '@/redux';
import { confirmation, notify } from '@/components';

const useDelete = () => {
  const [updateClient] = useRemoveClientMutation();

  return async (id: string) => {
    confirmation.delete('client', () => {
      updateClient({ id })
        .unwrap()
        .then(() => {
          notify.success.t('clients.details.saved');
        });
    });
  };
};

export function useDeleteClientAction() {
  const deleteAction = useDelete();

  return {
    deleteAction,
  };
}
