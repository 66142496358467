import { Col } from 'antd';
import { FieldRow, Form } from '@/components';
import {
  useReportingHomesLookupSource,
  useReportingProjectsLookupSource,
} from '@/views';
import { useFormikContext } from 'formik';
import { GeneratePersonalizationSummaryReportArgs } from '@/redux';
import { useEffect } from 'react';

export function PersonalizationSummaryReportFilterForm() {
  const { values, setFieldValue } =
    useFormikContext<GeneratePersonalizationSummaryReportArgs>();
  const { projectIds: projectId } = values;

  const projectsDataSource = useReportingProjectsLookupSource(true);
  const homesDataSource = useReportingHomesLookupSource(
    undefined,
    projectId,
    true,
  );

  useEffect(() => {
    setFieldValue('homeIds', undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return (
    <>
      <FieldRow>
        <Col span={12}>
          <Form.AsyncSelect
            name="projectIds"
            dataSource={projectsDataSource}
            mode="multiple"
            required
            preload
          />
        </Col>
      </FieldRow>
      <FieldRow></FieldRow>
      <FieldRow>
        <Col span={12}>
          <Form.AsyncSelect
            key={projectId?.join()}
            name="homeIds"
            dataSource={homesDataSource}
            mode="multiple"
            required
            preload
          />
        </Col>
      </FieldRow>
    </>
  );
}
