import {
  AttachmentDto,
  clippoAppHttp,
  GenerateCustomReportDto,
  GeneratePersonalizationSummaryReportDto,
  GenerateTrendReportDto,
  ReportingCommunityDto,
  ReportingHomeDto,
  ReportingLocationDto,
  ReportingProjectDto,
  reportsHttp,
} from '@/core';
import { api } from '../api';
import { rtkq, RtkqRequest } from '../rtkq';

export type GenerateCustomReportArgs = GenerateCustomReportDto;
export type GenerateHomeStatusReportArgs = {
  clientId?: string | string[];
  communityId?: string | string[];
  projectId: string | string[];
  locationId?: string | string[];
  homeId?: string | string[];
};
export type GenerateTrendReportArgs = GenerateTrendReportDto;

export type GeneratePersonalizationSummaryReportArgs =
  GeneratePersonalizationSummaryReportDto;

const reportsApi = api.injectEndpoints({
  endpoints: (build) => ({
    generateCustomReport: build.mutation<
      string,
      RtkqRequest<GenerateCustomReportArgs>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(async () => reportsHttp.generateCustom(args));
      },
    }),

    generateHomeStatusReport: build.mutation<
      string,
      RtkqRequest<GenerateHomeStatusReportArgs>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() => reportsHttp.generateHomeStatus(args));
      },
    }),

    generateTrendReport: build.mutation<
      string,
      RtkqRequest<GenerateTrendReportArgs>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() => reportsHttp.generateTrend(args));
      },
    }),

    getReportExcelTemplates: build.query<
      AttachmentDto[],
      RtkqRequest<{ id: string }>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() =>
          clippoAppHttp.byOwner('ReportExcelTemplate', args.id),
        );
      },
      providesTags: ['report-templates'],
    }),

    getReportingCommunitiesList: build.query<
      ReportingCommunityDto[],
      RtkqRequest<{
        clientId?: string[] | string;
      }>
    >({
      queryFn: async (args) => {
        const { clientId } = args;
        return await rtkq(args).exec(() =>
          reportsHttp.getCommunitites(clientId),
        );
      },
      providesTags: (result = []) => [
        { type: 'community', id: 'list' },
        ...result.map(({ id }) => ({ type: 'community' as const, id })),
      ],
    }),

    getReportingProjectsList: build.query<
      ReportingProjectDto[],
      RtkqRequest<{
        clientId?: string | string[];
        communityId?: string | string[];
      }>
    >({
      queryFn: async (args) => {
        const { clientId, communityId } = args;
        return rtkq(args).exec(() =>
          reportsHttp.getProjects(clientId, communityId),
        );
      },
      providesTags: (result = []) => [
        { type: 'project', id: 'list' },
        ...result.map(({ id }) => ({ type: 'project' as const, id })),
      ],
    }),

    getReportingHomesList: build.query<
      ReportingHomeDto[],
      RtkqRequest<{
        communityId?: string | string[];
        projectId?: string | string[];
        withMenu?: boolean;
      }>
    >({
      queryFn: async (args) => {
        const { communityId, projectId, withMenu } = args;
        return rtkq(args).exec(() =>
          reportsHttp.getHomes(communityId, projectId, withMenu),
        );
      },
      providesTags: () => [
        { type: 'home', id: 'list' },
        { type: 'project', id: 'project_homes' },
      ],
    }),

    getReportingLocationsList: build.query<
      ReportingLocationDto[],
      RtkqRequest<{
        communityId?: string | string[];
        projectId?: string | string[];
      }>
    >({
      queryFn: async (args) => {
        const { communityId, projectId } = args;
        return rtkq(args).exec(() =>
          reportsHttp.getLocations(communityId, projectId),
        );
      },
      providesTags: () => [
        { type: 'home', id: 'list' },
        { type: 'project', id: 'project_homes' },
      ],
    }),
  }),
});

export const {
  useGenerateCustomReportMutation,
  useLazyGetReportExcelTemplatesQuery,
  useGenerateHomeStatusReportMutation,
  useGenerateTrendReportMutation,
  useLazyGetReportingProjectsListQuery,
  useLazyGetReportingHomesListQuery,
  useLazyGetReportingCommunitiesListQuery,
  useLazyGetReportingLocationsListQuery,
} = reportsApi;
