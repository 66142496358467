import { EditIconButton, EnumValue, TableColumnType } from '@/components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HomeState } from '@/redux';
import { NavLink } from 'react-router-dom';
import { sort, useFiltersFactory } from '@/utils';
import {
  CommunityPermissions,
  FloorplanType,
  HomeDto,
  ResidentLookupDto,
  StringFilterOptions,
} from '@/core';
import { HomesListPanelProps } from './HomesListPanel';
import { useEditHomeAction } from '../Homes.Details';
import { useCommunityContext } from '@/views';

export function useHomesColumns(
  args: HomesListPanelProps &
    Pick<Partial<ReturnType<typeof useEditHomeAction>>, 'openEdit'>,
  homes?: HomeDto[],
  homesStringFilterOptions?: StringFilterOptions,
) {
  const { rowActions, noProject, openEdit } = args;
  const { t } = useTranslation();

  const { assert } = useCommunityContext();

  const filters = useFiltersFactory(homes, homesStringFilterOptions);

  const canModify = assert(CommunityPermissions.Homes.Manage, true);

  return useMemo<TableColumnType<HomeState>[]>(() => {
    const columns: Array<TableColumnType<HomeState> | false> = [
      {
        title: t('homes.number'),
        key: 'number',
        ...filters.select((x) => x.number, 'number'),
        sorter: (a, b) => a.number.localeCompare(b.number),
        render: (_, item) => item.number,
        width: '10%',
      },
      {
        title: t('homes.homeNumber'),
        key: 'homeNumber',
        defaultSortOrder: 'ascend',
        ...filters.select((x) => x.homeNumber, 'homeNumber'),
        sorter: (a, b) => a.homeNumber.localeCompare(b.homeNumber),
        render: (_, { id, homeNumber, communityId }) => (
          <NavLink to={`/communities/${communityId}/homes/${id}`}>
            {homeNumber}
          </NavLink>
        ),
        width: '10%',
      },
      {
        title: t('homes.location'),
        key: 'locationLevel1Name',
        ...filters.select((x) => x.locationLevel1Name, 'locationLevel1Name'),
        sorter: (a, b) => sort(a.locationLevel1Name, b.locationLevel1Name),
        render: (_, item) => item.locationLevel1Name,
        width: '10%',
      },
      {
        title: t('homes.sublocation'),
        key: 'locationLevel2Name',
        ...filters.select((x) => x.locationLevel2Name, 'locationLevel2Name'),
        sorter: (a, b) => sort(a.locationLevel2Name, b.locationLevel2Name),
        render: (_, item) => item.locationLevel2Name,
        width: '10%',
      },
      {
        title: t('homes.sublocation2'),
        key: 'locationLevel3Name',
        ...filters.select((x) => x.locationLevel3Name, 'locationLevel3Name'),
        sorter: (a, b) => sort(a.locationLevel3Name, b.locationLevel3Name),
        render: (_, item) => item.locationLevel3Name,
        width: '10%',
      },
      {
        title: t('homes.constructionNumber'),
        key: 'constructionNumber',
        ...filters.select((x) => x.constructionNumber, 'constructionNumber'),
        sorter: (a, b) => sort(a.constructionNumber, b.constructionNumber),
        render: (_, item) => item.constructionNumber,
        width: '10%',
      },
      {
        title: t('homes.resident'),
        key: 'resident',
        ...filters.select(
          ({ residents }) => residents?.map((r) => r.name).join(', '),
          'residentFilter',
          'include',
        ),
        sorter: (a, b) =>
          sort(getResidentsNames(a.residents), getResidentsNames(b.residents)),
        render: (_, { residents }) => getResidentsNames(residents),
        width: '10%',
      },
      {
        title: t('homes.floorplanMarketingName'),
        key: 'floorplanMarketingName',
        ...filters.select(
          (x) => x.floorplan?.marketingName,
          'floorplanMarketingName',
        ),
        sorter: (a, b) =>
          sort(a.floorplan?.marketingName, b.floorplan?.marketingName),
        render: (_, item) => (
          <NavLink
            to={`/communities/${item.communityId}/floorplans/${item.floorplan?.id}`}
          >
            {item.floorplan?.marketingName}
          </NavLink>
        ),
        width: '10%',
      },
      {
        title: t('homes.floorplanType'),
        key: 'floorplanType',
        sorter: (a, b) => sort(a.floorplan?.type, b.floorplan?.type),
        ...filters.enumSelect((x) => x.floorplan?.type, FloorplanType),
        render: (_, { floorplan }) => (
          <EnumValue type={FloorplanType} value={floorplan?.type} />
        ),
        width: '10%',
      },
      !noProject && {
        title: t('homes.activeProject'),
        key: 'projectName',
        ...filters.select((x) => x.projectName, 'projectName'),
        sorter: (a, b) => sort(a.projectName, b.projectName),
        render: (_, item) => (
          <NavLink to={`/projects/${item.projectId}/${'administration'}`}>
            {item.projectName}
          </NavLink>
        ),
        width: '10%',
      },
      {
        title: t('homes.menu'),
        key: 'menu',
        ...filters.select((x) => x.menu?.name, 'menu'),
        sorter: (a, b) => sort(a.menu?.name, b.menu?.name),
        render: (_, item) => item.menu?.name,
        width: '10%',
      },
      rowActions !== false && {
        title: t('homes.actions'),
        width: '10%',
        key: 'actions',
        render: (_, record) => {
          const actionsElement = canModify ? (
            <EditIconButton
              type="link"
              onClick={() => openEdit!(record.id)}
              className="table-action"
            />
          ) : (
            <></>
          );

          if (typeof rowActions === 'function') {
            return rowActions(record, actionsElement);
          }

          return actionsElement;
        },
      },
    ];

    return columns.filter((x) => !!x) as TableColumnType<HomeState>[];
  }, [t, filters, noProject, rowActions, canModify, openEdit]);
}

function getResidentsNames(residents?: ResidentLookupDto[]) {
  return residents
    ?.map((x) => x.name)
    .sort((a, b) => a.localeCompare(b))
    .join(', ');
}
