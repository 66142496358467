import { UserTableSettingsDto } from '@/core';
import { useUserContextSelector } from '@/redux';
import {
  useGetUserTableSettingsQuery,
  UserTableSettingsState,
  useSetUserTableSettingsMutation,
} from '@/redux/clientSettings';
import { ColumnsType } from 'antd/lib/table';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { ColumnState } from './ColumnMenuItem';
import { ColumnsDropdown } from './ColumnsDropdown';

function useColumnOptions<T>(
  columns: ColumnsType<T>,
  settings: UserTableSettingsState,
  defaultHiddenColumnKeys?: string[],
) {
  return useMemo(() => {
    const columnOptions = createColumnOptions(
      columns,
      settings,
      defaultHiddenColumnKeys,
    );
    return {
      columnOptions,
      hiddenColumnKeys: selectHidden(columnOptions),
    };
  }, [columns, defaultHiddenColumnKeys, settings]);
}

export function useColumnsSelect<T>(
  table: string,
  columns?: ColumnsType<T>,
  defaultHiddenColumnKeys?: string[],
) {
  const userId = useUserContextSelector((x) => x.id);
  const { data, isFetching } = useGetUserTableSettingsQuery({
    userId,
    table,
  });

  const [updateSettings, { isLoading }] = useSetUserTableSettingsMutation();
  const handleColumnsChanged = (columns: ColumnState[]) => {
    const hiddenColumns = selectHidden(columns);
    updateSettings({ table, userId, hiddenColumns });
  };

  const workingColumns = useMemo(
    () => (columns || []).filter((item) => item.title && item.key),
    [columns],
  );

  const { columnOptions, hiddenColumnKeys } = useColumnOptions(
    workingColumns,
    data!,
    defaultHiddenColumnKeys,
  );

  return {
    columnsSelect: (
      <ColumnsDropdown value={columnOptions} onChange={handleColumnsChanged} />
    ),
    isLoading: isLoading || isFetching,
    visibleColumns: columns?.filter(
      (x) => !x.key || !hiddenColumnKeys.split(',').includes(x.key.toString()),
    ),
  };
}

function selectHidden(columns: ColumnState[]) {
  return columns
    .filter((x) => !x.enabled)
    .map((x) => x.key)
    .join(',');
}

function createColumnOptions<T>(
  columns: ColumnsType<T>,
  settings: UserTableSettingsDto,
  defaultHiddenColumnKeys?: string[],
): ColumnState[] {
  const hiddenColumnsKeys = settings?.hiddenColumns?.split(',') ?? [];

  const defaultHidden = defaultHiddenColumnKeys ?? [];

  return columns.map((column) => ({
    title: column.title as React.ReactNode,
    key: column.key!,
    enabled: !isEmpty(hiddenColumnsKeys)
      ? !hiddenColumnsKeys.includes(column.key!.toString())
      : !defaultHidden.includes(column.key!.toString()),
  }));
}
