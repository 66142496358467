import { useGetProjectHomeInfoQuery } from '@/redux';
import { useMemo } from 'react';

export const useIsHomeOrAnyStepFinalized = (
  homeId: string,
  projectId?: string,
) => {
  const { data: context } = useGetProjectHomeInfoQuery(
    {
      id: projectId!,
      homeId,
    },
    { skip: !projectId },
  );

  return useMemo(() => {
    if (!context) return null;
    return !!context.finalizedAt || context?.steps.some((s) => s.isFinalized);
  }, [context]);
};
