import { useEffect, useRef } from 'react';
import { spinner } from './Spinner';

export function useSpin(spin: boolean) {
  const isInitial = useRef(true);
  const lastValueRef = useRef<boolean | null>(null);
  const destroyed = useRef(false);

  useEffect(() => {
    return () => {
      destroyed.current = true;

      if (!isInitial.current && lastValueRef.current) {
        spinner.hide();
      }
    };
  }, []);

  useEffect(() => {
    if (destroyed.current) {
      return;
    }

    if (isInitial.current) {
      if (spin) {
        spinner.show();
      }

      isInitial.current = false;
      lastValueRef.current = spin;
      return;
    }

    if (spin) {
      spinner.show();
    } else {
      spinner.hide();
    }

    lastValueRef.current = spin;
  }, [spin]);
}
