import { dataNodeUtils, Icon, notify } from '@/components';
import {
  CategoryState,
  ItemState,
  SubcontractorDetailsState,
  UpdateSubcontractorItemsDtoArgs,
  useGetCategoriesQuery,
  useGetItemsListQuery,
  useUpdateSubcontractorMutation,
} from '@/redux';
import { array } from '@/utils';
import { useMemo, useState } from 'react';
import { FormSubcontractorItems } from './FormSubcontractorItems';
import { DataNode } from 'antd/lib/tree';
import { ViewSubcontractorItems } from './ViewSubcontractorItems';

interface Props {
  data: SubcontractorDetailsState;
}

function removeEmptyNodes(nodes: DataNode[], items: ItemState[]): DataNode[] {
  let ar: DataNode[] = [];

  for (const node of nodes) {
    const newChildren =
      node.children!.length > 0 ? removeEmptyNodes(node.children!, items) : [];

    if (
      newChildren.length > 0 ||
      items.some((item) => item.categoryId === node.key)
    ) {
      ar.push({
        ...node,
        className: 'item-child',
        children: newChildren,
      });
    }
  }

  return ar;
}

function insertItemsToNodes(nodes: DataNode[], items: ItemState[]): void {
  for (const node of nodes) {
    if (node.children!.length > 0) {
      insertItemsToNodes(node.children!, items);
    }

    const foundItems = items.filter((item) => item.categoryId === node.key);
    foundItems.forEach((item) => {
      node.children!.push({
        key: item.id,
        title: (
          <>
            <Icon type="rectangle" />
            {item.name}
          </>
        ),
        children: [],
        className: 'item-child',
      });
    });
  }
}

export function useSubcontractorTreeData(
  communityId: string,
  items: ItemState[],
) {
  const { data: categories = array.empty<CategoryState>() } =
    useGetCategoriesQuery({ communityId });

  return useMemo(() => {
    let nodes = dataNodeUtils.convertFromMany(
      categories,
      'id',
      'name',
      'children',
      'name',
    );
    nodes = removeEmptyNodes(nodes, items);
    insertItemsToNodes(nodes, items);

    return nodes;
  }, [categories, items]);
}

export function SubcontractorItemsDetailsPanel(props: Props) {
  const { data } = props;
  const selectedItems = data.items;
  const { communityId } = data;
  const [isEdit, setEdit] = useState(false);
  const [update] = useUpdateSubcontractorMutation();
  const { data: items } = useGetItemsListQuery(
    { communityId, selectedItems: selectedItems },
    { refetchOnMountOrArgChange: true },
  );

  if (!items) {
    return null;
  }

  const handleSubmit = ({ itemIds }: UpdateSubcontractorItemsDtoArgs) => {
    const result = { ...data, itemIds };

    update(result)
      .unwrap()
      .then(() => {
        notify.success.t('subcontractors.updated');
        setEdit(false);
      });
  };

  return (
    <div>
      {isEdit ? (
        <FormSubcontractorItems
          onSubmit={handleSubmit}
          onCancel={() => setEdit(false)}
          value={data}
          items={items}
        />
      ) : (
        <ViewSubcontractorItems
          onEdit={() => setEdit(true)}
          communityId={data.communityId}
          selectedItems={selectedItems}
        />
      )}
    </div>
  );
}
