import { Col, Space } from 'antd';
import { Form, SecondaryCancelButton, StyledRow } from '@/components';
import * as Yup from 'yup';
import { AddSubOptionArgs, UpdateSubOptionArgs } from '@/redux';
import { useTranslation } from 'react-i18next';

const schema = Yup.object().shape({
  name: Yup.string().field().required().nullable(),
});

interface Props<TValue extends UpdateSubOptionArgs | AddSubOptionArgs> {
  onCancel: () => any;
  onSubmit: (value: TValue) => any;
  value: TValue;
  className?: string;
}

function getId(x: UpdateSubOptionArgs | AddSubOptionArgs) {
  return (x as any).id;
}

export function SubOptionEditPanel<
  TValue extends UpdateSubOptionArgs | AddSubOptionArgs,
>(props: Props<TValue>) {
  const { onCancel, value, onSubmit, className } = props;
  const { t } = useTranslation();
  const id = getId(value);

  return (
    <Form.Formik<TValue>
      uid="subOption-details"
      i18n="subOptions.details"
      initialValues={value}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <div className={className}>
        <StyledRow>
          <Col span={12}>
            <Form.Input name="name" required />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col>
            <Form.Images
              name="images"
              defer
              ownerId={id}
              ownerType="SubOptionImage"
              noPreload={!id}
            />
          </Col>
        </StyledRow>
        <Space className={className}>
          <SecondaryCancelButton onClick={onCancel}>
            {t('subOptions.cancelButton')}
          </SecondaryCancelButton>
          <Form.Submit type="primary" secondary>
            {t('subOptions.saveButton')}
          </Form.Submit>
        </Space>
      </div>
    </Form.Formik>
  );
}
