import { notify, Title } from '@/components';
import { HomeOutlined } from '@ant-design/icons';
import {
  AddDevelopScopeItemArgs,
  useAddDevelopScopeItemMutation,
} from '@/redux';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DevelopScopeItemEditView,
  DevelopScopeList,
  getDevelopScopeRoomId,
  useCommunityContext,
  useProjectContext,
} from '@/views';
import { CommunityPermissions } from '@/core';
import { Button, Row } from 'antd';

interface Props {
  projectId: string;
  roomId: string;
  homeId: string;
  isWholeHome: boolean;
  isFloorplan: boolean;
  onFloorplanItemAdd?: () => void;
  compact?: boolean;
}

const useSubmit = (
  setAddItem: (value: boolean) => any,
  onFloorplanItemAdd?: () => any,
) => {
  const [add] = useAddDevelopScopeItemMutation();

  return (args: AddDevelopScopeItemArgs) =>
    add({ ...args, roomId: getDevelopScopeRoomId(args.roomId) })
      .unwrap()
      .then(() => notify.success.t('developScope.saved'))
      .then(() => setAddItem(false))
      .then(args.isFloorplan && onFloorplanItemAdd && onFloorplanItemAdd());
};

function useInitialValue({ roomId, homeId, projectId, isFloorplan }: Props) {
  return useMemo<AddDevelopScopeItemArgs>(
    () => ({
      projectId,
      homeId,
      roomId,
      description: null!,
      taskRequired: false,
      imageActions: [],
      isFloorplan: isFloorplan,
    }),
    [projectId, homeId, roomId, isFloorplan],
  );
}

export function DevelopScopeListPanel(props: Props) {
  const { roomId, homeId, isFloorplan, onFloorplanItemAdd, compact } = props;
  const { t } = useTranslation();
  const { isActive: isActiveProject } = useProjectContext();
  const { assert } = useCommunityContext();
  const [addItem, setAddItem] = useState(false);
  const submit = useSubmit(setAddItem, onFloorplanItemAdd);
  const initialValue = useInitialValue(props);

  const readonly =
    !isActiveProject || !assert(CommunityPermissions.DevelopScope.Manage, true);

  return (
    <div>
      <Title level={1}>
        <Row>{t('developScope.title')}</Row>
        <Row justify="end">
          {!readonly && (
            <Button
              className="mt-2"
              onClick={() => setAddItem(true)}
              type="primary"
              icon={<HomeOutlined />}
            >
              {t('developScope.details.add')}
            </Button>
          )}
        </Row>
      </Title>

      {!addItem && (
        <DevelopScopeList
          roomId={roomId}
          homeId={homeId}
          readonly={readonly}
          isFloorplan={isFloorplan}
          compact={compact}
        />
      )}

      {addItem && (
        <DevelopScopeItemEditView
          onCancel={() => setAddItem(false)}
          onSubmit={submit}
          value={initialValue}
          saveButtonName={t('developScope.details.addSaveButton')}
          compact={compact}
        />
      )}
    </div>
  );
}
