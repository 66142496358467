import { Button, Divider, Row } from 'antd';
import { Form } from '@/components';
import { useTranslation } from 'react-i18next';
import { GenerateHomeStatusReportArgs } from '@/redux';
import { useReportingProjectsLookupSource } from '@/views';
import { useFormikContext } from 'formik';
import { useBool } from '@/utils';
import { useState } from 'react';

export function ReportingProjectSelect({
  communityId,
  name,
}: {
  name: string;
  communityId?: string | string[];
}) {
  const projectsDataSource = useReportingProjectsLookupSource(
    true,
    communityId,
  );
  const [options, setOptions] = useState<string[]>([]);
  const { setFieldValue } = useFormikContext<GenerateHomeStatusReportArgs>();
  const { t } = useTranslation();
  const [allSelected, , toggleAllSelected] = useBool(false);
  const handleAllSelect = () => {
    if (allSelected) {
      setFieldValue(name, []);
    } else {
      setFieldValue(name, options);
    }
    toggleAllSelected();
  };
  return (
    <Form.AsyncSelect
      key={`${communityId}`}
      name={name}
      dataSource={projectsDataSource}
      required
      mode="multiple"
      onSearchLoad={(options) => setOptions(options.map((x) => x.value))}
      dropdownRender={(menu) => (
        <>
          {menu}
          <>
            <Divider style={{ margin: '2px' }} />
            <Row justify="center" className="p-2">
              <Button type="link" onClick={handleAllSelect}>
                {t(
                  `reports.homeStatus.${
                    allSelected ? 'unselectAll' : 'selectAll'
                  }`,
                )}
              </Button>
            </Row>
          </>
        </>
      )}
    />
  );
}
