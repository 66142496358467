import { Form, TableColumnType } from '@/components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useFiltersFactory } from '@/utils';
import { SignatureType, StringFilterOptions } from '@/core';
import { SignatureState, UpdateSignatureArgs } from '@/redux';
import { FormsEnumSelect } from '@/components/Forms/FormsEnumSelect';
import * as yup from 'yup';

const schema = yup.object().shape({
  clientId: yup.string().field().nullable().required(),
  communityId: yup.string().field().nullable().required(),
  type: yup.mixed<SignatureType>().nullable().required(),
});

export function useSignaturesColumns(
  data: SignatureState[],
  onTypeChange: (value: UpdateSignatureArgs) => void,
  stringFilterOptions?: StringFilterOptions,
) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'summarySettings.signatures',
  });
  const filters = useFiltersFactory(data, stringFilterOptions);
  return useMemo<TableColumnType<SignatureState>[]>(() => {
    const cols: Array<TableColumnType<SignatureState> | false> = [
      {
        title: t('client'),
        key: 'client',
        ...filters.select((x) => x.clientName, 'client'),
        sorter: (a, b) => a.clientName.localeCompare(b.clientName),
        render: (_, { clientId, clientName }) => (
          <NavLink to={`/clients/${clientId}`}>{clientName}</NavLink>
        ),
        ellipsis: true,
      },
      {
        title: t('community'),
        key: 'community',
        ...filters.select((x) => x.communityName, 'community'),
        sorter: (a, b) => a.communityName.localeCompare(b.communityName),
        render: (_, { communityId, communityName }) => (
          <NavLink to={`/communities/${communityId}`}>{communityName}</NavLink>
        ),
      },
      {
        title: t('type'),
        key: 'type',
        ...filters.enumSelect((x) => x.type, SignatureType),
        sorter: (a, b) => a.type.localeCompare(b.type),
        render: (_, item) => (
          <Form.Formik<UpdateSignatureArgs>
            uid="activity-table"
            onSubmit={onTypeChange}
            initialValues={item}
            i18n="summarySettings.signatures"
            validationSchema={schema}
          >
            {(formik) => (
              <FormsEnumSelect
                label={false}
                name="type"
                type={SignatureType}
                allowClear={false}
                onBlur={formik.submitForm}
              />
            )}
          </Form.Formik>
        ),
      },
    ];

    return cols.filter((x) => !!x) as TableColumnType<SignatureState>[];
  }, [filters, onTypeChange, t]);
}
