import { dataNodeUtils, Tree } from '@/components';
import {
  CategoryState,
  ItemMenuItemDetailsState,
  ItemState,
  useGetCategoriesQuery,
  useGetItemsListQuery,
} from '@/redux';
import { array } from '@/utils';
import { Input } from 'antd';
import { useField } from 'formik';
import { t } from 'i18next';
import { useCallback, useMemo, useState } from 'react';
import { useItemNavSelectedState } from '../Items.List';
import { convertCategoryAndItemsToTreeData } from './convertCategoryAndItemsToTreeData';
import { convertItemMenuItemsToTreeData } from './convertItemMenuItemsToTreeData';
import { MenuItemFormValue } from './FormItemMenuItemSuboptions';

interface Props {
  name: string;
  communityId: string;
  className?: string;
  initialItems?: ItemMenuItemDetailsState[];
  select: ReturnType<typeof useItemNavSelectedState>;
}

const ITEM_PREFIX = 'item://';
const CATEGORY_PREFIX = 'category://';

function useTreeData(props: Props, searchString: string) {
  const {
    communityId,
    initialItems = array.empty<ItemMenuItemDetailsState>(),
  } = props;

  const { data: items = array.empty<ItemState>() } = useGetItemsListQuery({
    communityId,
  });

  const { data: categories = array.empty<CategoryState>() } =
    useGetCategoriesQuery({ communityId, listed: true });

  const treeData = useMemo(() => {
    const tree = convertCategoryAndItemsToTreeData({
      items,
      categories,
      mapItem: (item) => ({
        key: ITEM_PREFIX + item.id,
        title: item.name,
      }),
      mapCategory: (category) => ({
        key: CATEGORY_PREFIX + category.id,
        title: category.name,
      }),
    });

    const initialTree = convertItemMenuItemsToTreeData(initialItems!, {
      mapItem: (item) => ({
        key: ITEM_PREFIX + item.id,
        title: item.name,
      }),
      mapCategory: (category) => ({
        key: CATEGORY_PREFIX + category.id,
        title: category.name,
      }),
    });

    return dataNodeUtils.merge(tree, initialTree);
  }, [categories, items, initialItems]);

  const filteredTreeData = useMemo(
    () => dataNodeUtils.filter(treeData, searchString),
    [treeData, searchString],
  );

  return useMemo(
    () => ({ items, treeData: filteredTreeData }),
    [items, filteredTreeData],
  );
}

export function useHandleCheck(props: Props, items: ItemState[]) {
  const { name } = props;

  const [{ value }, , { setValue }] = useField<MenuItemFormValue[]>(name);

  return useCallback(
    (checked: string[]) => {
      const checkedItemIds = checked
        .filter((x) => x.startsWith(ITEM_PREFIX))
        .map((x) => x.substring(ITEM_PREFIX.length));

      const values = value.filter((x) => checkedItemIds.includes(x.itemId));
      const existingItemIds = values.map((x) => x.itemId);
      const newItemIds = checkedItemIds.filter(
        (x) => !existingItemIds.includes(x),
      );

      const newItemValues: MenuItemFormValue[] = newItemIds.map((id) => ({
        itemId: id,
        suboptions: { all: true, values: undefined },
        type: 'Upgrade',
        pristine: false,
        noSuboptions: null,
        standardCredit: false,
        categoryId: items.find((x) => x.id === id)!.categoryId,
      }));

      setValue([...values, ...newItemValues], true);
    },
    [setValue, value, items],
  );
}

export function FormItemMenuItems(props: Props) {
  const { name, className, select } = props;
  const [searchString, setSearchString] = useState('');
  const { treeData, items } = useTreeData(props, searchString);
  const [{ value }] = useField<MenuItemFormValue[]>(name);
  const handleCheck = useHandleCheck(props, items);
  const checkedItemIds = value.map((x) => x.itemId);
  const checkedKeys = checkedItemIds.map((id) => ITEM_PREFIX + id);

  const onSelect = (selectedKeysValue: React.Key[]) => {
    const key = selectedKeysValue[0].toString();

    const itemId = key.startsWith(ITEM_PREFIX)
      ? key.substring(ITEM_PREFIX.length)
      : undefined;

    select.onSelected(itemId);
  };

  return (
    <div>
      <Input
        className={className}
        placeholder={t('itemMenus.details.search')}
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
      />
      <Tree
        treeData={treeData}
        checkable
        showIcon
        onCheck={handleCheck as any}
        checkedKeys={checkedKeys}
        onSelect={onSelect}
      />
    </div>
  );
}
