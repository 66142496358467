import { TreeProps } from 'antd';
import { DataNode } from 'antd/lib/tree';
import { useMemo } from 'react';
import { CustomTree, dataNodeUtils } from '@/components';

export const DEFAULT_TREE_HEIGHT = 500;
export const MIN_COUNT_TO_USE_VIRTUAL = 100;

export function Tree<T extends DataNode = DataNode>(props: TreeProps<T>) {
  const { height: customHeight, treeData, virtual } = props;
  const height = useMemo(() => {
    return customHeight ||
      (dataNodeUtils.getNodesCount(treeData) > MIN_COUNT_TO_USE_VIRTUAL &&
        virtual !== false)
      ? DEFAULT_TREE_HEIGHT
      : undefined;
  }, [customHeight, treeData, virtual]);
  return <CustomTree {...props} height={height} />;
}
