import { ExternalLinkDto } from '@/core';
import { List } from 'antd';
import { isEmpty } from 'lodash';
import { ExternalLinkItem } from './ExternalLinkItem';

interface Props {
  readonly?: boolean;
  links: ExternalLinkDto[];
  onDelete?: (id: string) => any;
}

export function ExternalLinkList(props: Props) {
  const { links, onDelete, readonly } = props;

  if (isEmpty(links)) {
    return null;
  }

  return (
    <List
      itemLayout="horizontal"
      dataSource={links}
      rowKey={(row) => row.id}
      renderItem={(link) => {
        return (
          <ExternalLinkItem
            key={link.id}
            onDelete={onDelete}
            link={link}
            readonly={readonly}
          />
        );
      }}
    />
  );
}
