import { AddExternalLinkDto, ExternalLinkDto } from '@/core';
import { Space } from 'antd';
import styles from './ExternalLink.module.scss';
import { ExternalLinkList } from './ExternalLinkList';
import { AddExternalLinkButton } from './AddExternalLinkButton';

interface Props {
  readonly?: boolean;
  links: ExternalLinkDto[];
  onCreate?: (args: AddExternalLinkDto) => any;
  onDelete?: (id: string) => any;
}

export function ExternalLinks(props: Props) {
  const { onCreate, onDelete, readonly } = props;

  return (
    <Space direction="vertical" size="small" className={styles.linkPanel}>
      <ExternalLinkList {...props} onDelete={onDelete} readonly={readonly} />

      {!readonly && <AddExternalLinkButton onCreate={onCreate!} />}
    </Space>
  );
}
