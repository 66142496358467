import styles from '@/views/Index.Common/IndexImage.module.scss';
import { Icon } from '@/components';
import { Button, Tooltip } from 'antd';

export function UploadImageButton(props: {onClick: () => any}) {
  const { onClick } = props;
  return (
    <Tooltip title={"Change image"} placement={'bottomRight'}>
      <Button className={styles.editButton} onClick={onClick}>
        <Icon type={'edit'}/>
      </Button>
    </Tooltip>
  )
}