import {
  CreateTaskTemplateDto,
  Paged,
  Paging,
  TaskTemplateDetailsDto,
  TaskTemplateDto,
  TaskTemplateLookupDto,
  TaskTemplateMatrixDto,
  TaskTemplateMatrixRowDto,
  taskTemplatesHttp,
  TemplateTaskDetailsDto,
  UpdateTaskTemplateDto,
} from '@/core';
import { api } from '../api';
import { rtkq, RtkqRequest } from '../rtkq';

export type TaskTemplateState = TaskTemplateDto;
export type TaskTemplateDetailsState = TaskTemplateDetailsDto;
export type CreateTaskTemplateArgs = CreateTaskTemplateDto;
export type UpdateTaskTemplateArgs = UpdateTaskTemplateDto;
export type TaskTemplateTaskDetailsState = TemplateTaskDetailsDto;
export type TaskTemplateMatrixState = TaskTemplateMatrixDto;
export type TaskTemplateMatrixRowState = TaskTemplateMatrixRowDto;
export type TaskTemplateLookupState = TaskTemplateLookupDto;

const taskTemplatesApi = api.injectEndpoints({
  endpoints: (build) => {
    return {
      getTaskTemplatesList: build.query<
        Paged<TaskTemplateState>,
        RtkqRequest<{ communityId?: string; paging: Paging }>
      >({
        queryFn: async (args) => {
          return rtkq(args).exec(() => taskTemplatesHttp.getAll(args));
        },
        providesTags: (result) => [
          { type: 'task-template', id: 'list' },
          ...(result?.items || []).map(({ id }) => ({
            type: 'task-template' as const,
            id,
          })),
        ],
      }),

      getTaskTemplateDetails: build.query<
        TaskTemplateDetailsState,
        RtkqRequest<{ id: string }>
      >({
        queryFn: async (args) => {
          const { id } = args;
          return await rtkq(args).exec(() => taskTemplatesHttp.getDetails(id));
        },
        providesTags: (taskTemplate) => [
          { type: 'task-template', id: taskTemplate?.id ?? 'none' },
          ...(taskTemplate?.tasks.map((task) => ({
            type: 'template-task' as const,
            id: task.id,
          })) ?? []),
        ],
      }),

      addTaskTemplate: build.mutation<
        string,
        RtkqRequest<CreateTaskTemplateArgs>
      >({
        queryFn: (args) => {
          return rtkq(args).exec(() => taskTemplatesHttp.create(args));
        },
        invalidatesTags: () => [{ type: 'task-template', id: 'list' }],
      }),

      updateTaskTemplate: build.mutation<
        void,
        RtkqRequest<UpdateTaskTemplateArgs>
      >({
        queryFn: (args) => {
          return rtkq(args).exec(() => taskTemplatesHttp.update(args));
        },
        invalidatesTags: (_, __, args) => [
          { type: 'task-template', id: args.id },
        ],
      }),

      getTaskTemplate: build.query<
        TaskTemplateState,
        RtkqRequest<{ id: string }>
      >({
        queryFn: async (args) => {
          const { id } = args;
          return await rtkq(args).exec(() => taskTemplatesHttp.get(id));
        },
        providesTags: (taskTemplate) => [
          { type: 'task-template', id: taskTemplate?.id ?? 'none' },
          ...(taskTemplate?.tasks.map((task) => ({
            type: 'template-task' as const,
            id: task.id,
          })) ?? []),
        ],
      }),

      unlistTaskTemplate: build.mutation<void, RtkqRequest<{ id: string }>>({
        queryFn: (args) => {
          const { id } = args;
          return rtkq(args).exec(() => taskTemplatesHttp.unlist(id));
        },
        invalidatesTags: (_, __, args) => [
          { type: 'task-template', id: args.id },
          { type: 'task-template', id: 'list' },
        ],
      }),

      deleteTemplateTask: build.mutation<void, RtkqRequest<{ id: string }>>({
        queryFn: (args) => {
          const { id } = args;
          return rtkq(args).exec(() => taskTemplatesHttp.deleteTask(id));
        },
        invalidatesTags: (_, __, args) => [
          { type: 'template-task', id: args.id },
          { type: 'template-task', id: 'list' },
        ],
      }),

      getTaskTemplateToImport: build.query<
        TaskTemplateMatrixState,
        RtkqRequest<{ templateIds: string[] }>
      >({
        queryFn: (args) => {
          const { templateIds } = args;
          return rtkq(args).exec(() =>
            taskTemplatesHttp.getTasksToImport(templateIds),
          );
        },
      }),
    };
  },
});

export const {
  useGetTaskTemplatesListQuery,
  useGetTaskTemplateDetailsQuery,
  useAddTaskTemplateMutation,
  useUpdateTaskTemplateMutation,
  useGetTaskTemplateQuery,
  useUnlistTaskTemplateMutation,
  useDeleteTemplateTaskMutation,
  useLazyGetTaskTemplatesListQuery,
  useGetTaskTemplateToImportQuery,
} = taskTemplatesApi;
