import { array } from '@/utils';
import React, { memo, useCallback, useRef, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './ImageBox.module.scss';
import { useTranslation } from 'react-i18next';
import { notify } from '../Notify';

export interface ImageUploadProps {
  onUpload: (files: File[]) => any;
  acceptPdf?: boolean;
}

export function fileListToArray(fileList: FileList) {
  return array.sequence(fileList.length).map((index) => fileList.item(index)!);
}

export function validate(file: File, acceptPdf?: boolean): boolean {
  const accept = acceptPdf ? ['image/', 'application/pdf'] : ['image/'];
  const isAcceptedType = accept.some((type) =>
    file.type.startsWith(type.trim()),
  );

  if (!isAcceptedType) {
    notify.error.t('uploadFile.invalidType');
    return false;
  }

  return true;
}

function useHandleUpload(props: ImageUploadProps) {
  const { onUpload: onUploadProp, acceptPdf } = props;
  const [uploading, setUploading] = useState(false);

  const onUploadFn = (e: React.ChangeEvent<HTMLInputElement>) => {
    let files = fileListToArray(e.target.files!);
    files = files.filter((file) => validate(file, acceptPdf));
    setUploading(true);
    Promise.resolve(onUploadProp(files)).finally(() => setUploading(false));
  };

  const onUpload = useCallback(onUploadFn, [acceptPdf, onUploadProp]);
  return { onUpload, uploading };
}

function _ImageUpload(props: ImageUploadProps) {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const { onUpload, uploading } = useHandleUpload(props);
  const handleClick = useCallback(() => inputRef.current?.click(), [inputRef]);

  return (
    <div className={styles.upload} onClick={handleClick}>
      <div className={styles.uploadPlaceholder}>
        {uploading ? (
          <LoadingOutlined />
        ) : (
          <div className={styles.uploadText}>
            <div className="plus">+</div>
            <div>{t('upload')}</div>
          </div>
        )}
      </div>
      <input
        disabled={uploading}
        ref={inputRef}
        type="file"
        value=""
        accept={props.acceptPdf ? 'image/*, application/pdf' : 'image/*'}
        multiple
        onChange={onUpload}
      />
    </div>
  );
}

export const ImageUpload = memo(_ImageUpload);