import { EditIconButton, Title } from '@/components';
import { CommunityPermissions } from '@/core';
import { Space } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Assert } from '../Communities.Common';
import { DeleteItemMenuButton } from './DeleteItemMenuButton';
import { EditItemMenu } from './EditItemMenu';
import { ItemMenuTreeDetails } from './ItemMenuTreeDetails';

interface Props {
  id: string;
}

function _ItemMenuDetails(props: Props) {
  const { id } = props;
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);

  return (
    <div>
      {!edit && (
        <>
          <Title>
            <Title.Actions inline space>
              <Assert permission={CommunityPermissions.Menus.Manage} active>
                <Space>
                  <EditIconButton type="link" onClick={() => setEdit(true)} />
                  <DeleteItemMenuButton id={id} />
                </Space>
              </Assert>
            </Title.Actions>
            {t('itemMenus.details.title')}
          </Title>
          <ItemMenuTreeDetails id={id} />
        </>
      )}
      {edit && <EditItemMenu id={id} onCancel={() => setEdit(false)} />}
    </div>
  );
}

export function ItemMenuDetails(props: Props) {
  return <_ItemMenuDetails {...props} key={props.id} />;
}
