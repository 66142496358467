import {
  LookupSource,
  LookupOption,
  createEntireLoadLookupSource,
} from '@/components';
import { DEFAULT_PAGING, Role } from '@/core';
import { RtkqSpin, useLazyGetUsersQuery, UserState } from '@/redux';
import { useMemo } from 'react';

function map(value: UserState): LookupOption {
  return {
    label: `${value.firstName} ${value.lastName}`,
    value: value.id,
  };
}

interface UseUserLookupSourceArgs {
  excludeUserIds?: string[];
  organizationId?: string;
  roles?: Role[];
}

export function useUserLookupSource(
  args: UseUserLookupSourceArgs,
): LookupSource {
  const { excludeUserIds, organizationId, roles } = args;
  const [fetchList] = useLazyGetUsersQuery();

  return useMemo(() => {
    return createEntireLoadLookupSource({
      initial: (id: string) =>
        fetchList(
          {
            organizationId,
            roles,
            active: true,
            paging: DEFAULT_PAGING,
            [RtkqSpin]: false,
          },
          true,
        )
          .unwrap()
          .then((values) => map(values.items.find((x) => x.id === id)!)),
      load: () =>
        fetchList(
          {
            organizationId,
            roles,
            active: true,
            paging: DEFAULT_PAGING,
            [RtkqSpin]: false,
          },
          true,
        )
          .unwrap()
          .then((values) =>
            values.items
              .filter((x) => !excludeUserIds || !excludeUserIds.includes(x.id))
              .map(map),
          ),
    });
  }, [fetchList, organizationId, roles, excludeUserIds]);
}
