import {
  LookupSource,
  LookupOption,
  createEntireLoadLookupSource,
} from '@/components';
import { DEFAULT_PAGING } from '@/core';
import { RtkqSpin, useLazyGetProjectsListQuery } from '@/redux';
import { useMemo } from 'react';

export function mapProjectLookup(value: {
  id: string;
  name: string;
}): LookupOption {
  const { name } = value;

  return {
    _search: name,
    value: value.id,
    label: name,
  };
}

export function useProjectLookupSource(
  communityId?: string | string[],
  isActive?: boolean,
): LookupSource {
  const [fetchList] = useLazyGetProjectsListQuery();

  return useMemo(() => {
    const entireSource = createEntireLoadLookupSource({
      initial: (id: string) =>
        fetchList(
          { communityId, isActive, paging: DEFAULT_PAGING, [RtkqSpin]: false },
          true,
        )
          .unwrap()
          .then((values) =>
            mapProjectLookup(values.items.find((x) => x.id === id)!),
          ),
      load: () =>
        fetchList(
          { communityId, isActive, paging: DEFAULT_PAGING, [RtkqSpin]: false },
          true,
        )
          .unwrap()
          .then((values) => values.items.map(mapProjectLookup)),
    });

    return { ...entireSource, searchField: '_search' };
  }, [fetchList, communityId, isActive]);
}
