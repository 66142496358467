import { StyledRow } from '@/components';
import { formatters } from '@/core';
import { Col, Typography } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './PersonalizationSummary.module.scss';

interface Props {
  title: string;
  baseCostTotal: number | undefined;
  residentPriceTotal: number | undefined;
  className?: string;
}

const Title = Typography.Title;

export function PersonalizationSummaryTotalInfo(props: Props) {
  const { title, baseCostTotal, residentPriceTotal, className } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'personalization.summary',
  });

  const showBaseCostTotal = baseCostTotal !== undefined;
  const showResidentPriceTotal = residentPriceTotal !== undefined;

  if (!showBaseCostTotal && !showResidentPriceTotal) {
    return null;
  }

  return (
    <StyledRow className={classNames(className, styles.totalInfo)}>
      <Col span={17}>
        <Title className={styles.totalTitle} level={4}>
          {title} {'('}
          {showBaseCostTotal && t('baseCost')}
          {showResidentPriceTotal && showBaseCostTotal && '/'}
          {showResidentPriceTotal && t('residentPrice')}
          {')'}
        </Title>
      </Col>
      <Col span={6}>
        <Title level={2} className={styles.totalValue}>
          {'$'}
          {showBaseCostTotal && formatters.price(baseCostTotal)}
          {showBaseCostTotal && showResidentPriceTotal && '/'}
          {showResidentPriceTotal && formatters.price(residentPriceTotal)}
        </Title>
      </Col>
    </StyledRow>
  );
}
