import { useTranslation } from 'react-i18next';
import { Form, Icon, Page } from '@/components';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Space } from 'antd';
import { EditProjectPathPanel } from '@/views/Projects.Details/EditProjectPathPanel';
import { useCommunityContext } from '@/views';
import { CommunityPermissions } from '@/core';

export function ProjectPathEditPage() {
  const { t } = useTranslation();
  const title = t('projects.path.editTitle');
  const { id } = useParams<'id'>();
  const push = useNavigate();
  const { assert } = useCommunityContext();

  if (!assert(CommunityPermissions.Path.Manage, true)) return null;

  return (
    <Page.Card
      actionsPosition="bottom"
      actions={
        <Space size="middle">
          <Button onClick={() => push(-1)}>{t('cancel')}</Button>
          <Form.Submit uid="pathTemplate-details" type="primary">
            {t('save')}
          </Form.Submit>
        </Space>
      }
      title={title}
      icon={<Icon type="template" />}
      color="#EFAC4E"
    >
      {id && <EditProjectPathPanel id={id} onSaved={() => push(-1)} />}
    </Page.Card>
  );
}
