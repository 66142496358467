import { Auth, FieldRow, Form } from '@/components';
import { GenerateCustomReportFilterDto } from '@/core';
import { Button, Col, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  useClientLookupSource,
  useReportingCommunitiesLookupSource,
  useReportingHomesLookupSource,
  useReportingProjectsLookupSource,
} from '@/views';
import styles from './FormCustomReport.module.scss';
import { useEffect } from 'react';

const { Text } = Typography;

interface Props {
  initialValue: GenerateCustomReportFilterDto;
  name: string;
  className?: string;
  clientId?: string;
  communityId?: string;
  projectId?: string;
}

export function FormCustomReportFilter(props: Props) {
  const { name, initialValue, className, communityId, clientId, projectId } =
    props;
  const [{ value }, meta, { setValue }] =
    useField<GenerateCustomReportFilterDto>(name);
  const { t } = useTranslation(undefined, {
    keyPrefix: 'reports.custom.details.filter',
  });
  const { clientIds, communityIds, projectIds } = value;

  const clientDataSource = useClientLookupSource();
  const communityDataSource = useReportingCommunitiesLookupSource(
    clientIds || false,
  );
  const projectDataSource = useReportingProjectsLookupSource(
    false,
    communityIds,
  );
  const homeDataSource = useReportingHomesLookupSource(
    communityIds,
    projectIds,
  );

  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    meta.touched && setFieldValue(`${name}.communityIds`, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientIds, name, setFieldValue]);

  useEffect(() => {
    meta.touched && setFieldValue(`${name}.projectIds`, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityIds, name, setFieldValue]);

  useEffect(() => {
    meta.touched && setFieldValue(`${name}.homeIds`, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectIds, name, setFieldValue]);

  return (
    <FieldRow className={className}>
      <Col flex="none" className={styles.filter}>
        <Text>{t('filterBy')}:</Text>
      </Col>

      <Col span={3}>
        <Form.Date
          required
          label={false}
          name={name + '.startDate'}
          placeholder={t('placeholders.startDate')}
          allowClear={false}
        />
      </Col>
      <Col span={3}>
        <Form.Date
          label={false}
          required
          name={name + '.endDate'}
          placeholder={t('placeholders.endDate')}
          allowClear={false}
        />
      </Col>
      <Auth role="BFP_ADMIN">
        <Col span={3}>
          <Form.AsyncSelect
            label={false}
            dataSource={clientDataSource}
            name={name + '.clientIds'}
            placeholder={t('placeholders.clientIds')}
            mode="multiple"
            preloadInitial={clientId}
          />
        </Col>
      </Auth>

      <Col span={3}>
        <Form.AsyncSelect
          key={clientIds?.join()}
          dataSource={communityDataSource}
          label={false}
          name={name + '.communityIds'}
          placeholder={t('placeholders.communityIds')}
          mode="multiple"
          preloadInitial={communityId}
        />
      </Col>
      <Col span={3}>
        <Form.AsyncSelect
          label={false}
          key={communityIds?.join()}
          dataSource={projectDataSource}
          name={name + '.projectIds'}
          placeholder={t('placeholders.projectIds')}
          mode="multiple"
          preloadInitial={projectId}
        />
      </Col>
      <Col span={3}>
        <Form.AsyncSelect
          label={false}
          key={projectIds?.concat(communityIds ?? [])?.join()}
          dataSource={homeDataSource}
          name={name + '.homeIds'}
          placeholder={t('placeholders.homeIds')}
          mode="multiple"
        />
      </Col>
      <Col flex="auto" className="text-end">
        <Button icon={<CloseOutlined />} onClick={() => setValue(initialValue)}>
          {t('clearAll')}
        </Button>
      </Col>
    </FieldRow>
  );
}
