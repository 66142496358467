import { Button } from 'antd';
import { ClearOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface ResetFiltersProps {
  handleReset: () => void;
}

export function ResetFiltersButton({ handleReset }: ResetFiltersProps) {
  const { t } = useTranslation();
  return (
    <Button className="mb-3" icon={<ClearOutlined />} onClick={handleReset}>
      {t('resetFilters')}
    </Button>
  );
}
