import { Activity } from '@webinex/activity';
import { useTranslation } from 'react-i18next';

export function ActivityAction(props: { activity: Activity }) {
  const {
    kind,
    values,
    clientNumber,
    communityNumber,
    homeNumber,
    projectNumber,
  } = props.activity;
  const { t, i18n } = useTranslation();
  const normalizedKind = kind.replaceAll(':', '_');
  const key = `activity.actions.${normalizedKind}`;

  if (!i18n.exists(key)) {
    return <>{kind}</>;
  }

  const obj = {};
  Object.assign(obj, values);
  Object.assign(obj, clientNumber ? { clientNumber } : null);
  Object.assign(obj, communityNumber ? { communityNumber } : null);
  Object.assign(obj, homeNumber ? { homeNumber } : null);
  Object.assign(obj, projectNumber ? { projectNumber } : null);

  return <>{t(key, obj)}</>;
}
