import { HomePriceMatrixRowState } from '@/redux';
import { useFiltersFactory } from '@/utils';
import { EnumValue, TableColumnType, getYesNoValue } from '@/components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { useCommunityContext } from '../Communities.Common';
import {
  CommunityPermissions,
  ItemMenuItemType,
  ItemPricePer,
  StringFilterOptions,
} from '@/core';
import { PriceInput } from '../Pricing.List';
import { PricingStandardCredit } from '../Pricing.List/PricingStandardCredit';
import { PricingItemType } from '../Pricing.List/PricingItemType';
import { useHomePriceMatrixRowChangeHandlerFactory } from './useHomeOverridesPriceMatrixRowChangeHandlerFactory';

export function usePricingHomeOverrideColumns(
  communityId: string,
  homePricing: HomePriceMatrixRowState[],
  filterOptions?: StringFilterOptions,
) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pricing.homeOverrides',
  });

  const changeHandlerFactory =
    useHomePriceMatrixRowChangeHandlerFactory(communityId);

  const { hasPermission } = useCommunityContext();
  const userHasPricingManagePermission = hasPermission(
    CommunityPermissions.Pricing.Manage,
  );

  const filters = useFiltersFactory(homePricing, filterOptions);

  return useMemo<TableColumnType<HomePriceMatrixRowState>[]>(() => {
    function getPrevRowValue(
      rowValue: HomePriceMatrixRowState,
    ): HomePriceMatrixRowState | undefined {
      const index = homePricing.indexOf(rowValue);
      return index > 0 ? homePricing.at(index - 1) : undefined;
    }

    function isPrevMatch(
      rowValue: HomePriceMatrixRowState,
      by: (row: HomePriceMatrixRowState) => any[],
    ) {
      const prevRow = getPrevRowValue(rowValue);
      if (!prevRow) return false;
      const current = by(rowValue);
      const prev = by(prevRow);
      return shallowEqual(current, prev);
    }

    function isPrevCategoryMatch(rowValue: HomePriceMatrixRowState) {
      return isPrevMatch(rowValue, (x) => [
        x.categories[0].id,
        x.categories[1]?.id,
        x.categories[2]?.id,
      ]);
    }

    const priceExportValue = (price: number | undefined, itemType: string) => {
      if (price == null && itemType === 'Upgrade') {
        return t('tbd');
      }
      return price;
    };

    const columns: Array<TableColumnType<HomePriceMatrixRowState> | false> = [
      {
        title: t('homeName'),
        key: 'home',
        width: '7%',
        ...filters.select((x) => x.home.name, 'homeName'),
        exportValue: (record) => record.home.name,
        render: (_, record) => {
          if (isPrevMatch(record, (x) => [x.home.id])) {
            return null;
          }

          return record.home.name;
        },
      },
      {
        title: t('category'),
        key: 'category',
        width: '7%',
        ...filters.select((x) => x.categories[0].name, 'category'),
        exportValue: (record) => record.categories[0].name,
        render: (_, record) => {
          if (isPrevCategoryMatch(record)) {
            return null;
          }

          return record.categories[0].name;
        },
      },
      {
        title: t('subcategory1'),
        key: 'subcategory1',
        width: '7%',
        ...filters.select((x) => x.categories[1]?.name, 'subcategory1'),
        exportValue: (record) => record.categories[1]?.name,
        render: (_, record) => {
          if (isPrevCategoryMatch(record)) {
            return null;
          }

          return record.categories[1]?.name || '-';
        },
      },
      {
        title: t('subcategory2'),
        key: 'subcategory2',
        width: '7%',
        ...filters.select((x) => x.categories[2]?.name, 'subcategory2'),
        exportValue: (record) => record.categories[2]?.name,
        render: (_, record) => {
          if (isPrevCategoryMatch(record)) {
            return null;
          }

          return record.categories[2]?.name || '-';
        },
      },
      {
        title: t('itemName'),
        key: 'itemName',
        width: '7%',
        ...filters.select((x) => x.itemName, 'itemName'),
        exportValue: (record) => record.itemName,
        render: (_, record) => {
          if (isPrevMatch(record, (x) => [x.itemId])) {
            return null;
          }

          return record.itemName;
        },
      },
      {
        title: t('itemType'),
        key: 'itemType',
        width: 100,
        ...filters.enumSelect((x) => x.itemType, ItemMenuItemType),
        exportValue: (record) => record.itemType,
        render: (_, record) => {
          if (isPrevMatch(record, (x) => [x.itemId])) {
            return null;
          }

          return (
            <PricingItemType
              initialValue={record.itemType}
              resetOnInitialValueChange
              disabled
            />
          );
        },
      },
      {
        title: t('standardCredit'),
        key: 'standardCredit',
        width: 80,
        className: 'title',
        exportValue: (record) => getYesNoValue(record.standardCredit, t),
        render: (_, record) => {
          if (isPrevMatch(record, (x) => [x.itemId])) {
            return null;
          }

          return (
            <PricingStandardCredit
              initialValue={record.standardCredit}
              resetOnInitialValueChange
              disabled
            />
          );
        },
      },
      {
        title: t('pricePer'),
        key: 'pricePer',
        width: '7%',
        exportValue: (record) => record.itemPricePer,
        render: (_, record) => {
          if (isPrevMatch(record, (x) => [x.itemId])) {
            return null;
          }

          return <EnumValue type={ItemPricePer} value={record.itemPricePer} />;
        },
      },
      {
        title: t('floorplan'),
        key: 'floorplan',
        width: '7%',
        ...filters.select((x) => x.floorplan?.marketingName, 'floorplan'),
        exportValue: (record) => record.floorplan?.marketingName,
        render: (_, record) => {
          if (isPrevMatch(record, (x) => [x.floorplan?.id])) {
            return null;
          }

          return record.floorplan?.marketingName;
        },
      },
      {
        title: t('roomName'),
        key: 'room',
        width: '7%',
        ...filters.select(
          (x) => x.room?.name || (!x.isGeo && t('wholeHome')),
          'room',
          'equal',
          t('wholeHome'),
        ),
        exportValue: (record) =>
          record.room?.name || (!record.isGeo && t('wholeHome')),
        render: (_, record) => {
          return record.room?.name || (!record.isGeo && t('wholeHome'));
        },
      },
      userHasPricingManagePermission && {
        title: t('baseCost'),
        key: 'baseCost',
        width: '10%',
        className: 'title',
        exportValue: (record) =>
          priceExportValue(record.prices?.baseCost?.value, record.itemType),
        render: (_, item) => (
          <PriceInput
            disabled={!userHasPricingManagePermission}
            onSubmit={changeHandlerFactory(item, 'baseCost')}
            initialValue={item.prices?.baseCost?.value}
            resetOnInitialValueChange
            calculated={false}
            tbd={item.prices?.baseCost == null && item.itemType === 'Upgrade'}
          />
        ),
      },
      userHasPricingManagePermission && {
        title: t('ownerPrice'),
        key: 'ownerPrice',
        width: '10%',
        className: 'title',
        exportValue: (record) => record.prices?.ownerPrice?.value,
        render: (_, item) => (
          <PriceInput
            disabled={
              !userHasPricingManagePermission || item.prices?.baseCost == null
            }
            onSubmit={changeHandlerFactory(item, 'ownerPrice')}
            initialValue={item.prices?.ownerPrice?.value}
            calculated={!item.prices?.ownerPrice?.isOverriden}
            resetOnInitialValueChange
          />
        ),
      },
      {
        title: t('residentPrice'),
        key: 'residentPrice',
        width: '10%',
        className: 'title',
        exportValue: (record) =>
          priceExportValue(
            record.prices?.residentPrice?.value,
            record.itemType,
          ),
        render: (_, item) => (
          <PriceInput
            disabled={
              !userHasPricingManagePermission || item.prices?.baseCost == null
            }
            onSubmit={changeHandlerFactory(item, 'residentPrice')}
            initialValue={item.prices?.residentPrice?.value}
            calculated={!item.prices?.residentPrice?.isOverriden}
            tbd={
              !item.prices?.residentPrice?.value && item.itemType === 'Upgrade'
            }
            resetOnInitialValueChange
          />
        ),
      },
    ];
    return columns.filter(
      (x) => !!x,
    ) as TableColumnType<HomePriceMatrixRowState>[];
  }, [
    t,
    filters,
    userHasPricingManagePermission,
    homePricing,
    changeHandlerFactory,
  ]);
}
