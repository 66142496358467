import { Button, Col, Row, Space } from 'antd';
import { Form, Title } from '@/components';
import * as Yup from 'yup';
import { UpdateItemArgs } from '@/redux';
import { ItemPricePer, UpdateItemDto } from '@/core';
import { useCategoryLookupSource } from '@/views';
import { useTranslation } from 'react-i18next';
import styles from './ItemEditPanel.module.scss';
import { SuboptionsList, SuboptionsListProps } from '../Item.SubOptions';
import { useState } from 'react';

const schema = Yup.object().shape({
  categoryId: Yup.string().field().required().nullable(),
  name: Yup.string().field().required().nullable(),
  description: Yup.string().nullable(),
  constructionDescription: Yup.string().nullable(),
  pricePer: Yup.string().nullable().required(),
});

export const INITIAL_ITEM: UpdateItemDto = {
  id: null!,
  categoryId: null!,
  name: null!,
  constructionDescription: null!,
  description: null!,
  pricePer: null!,
  isGeo: false,
  subOptionsIds: [],
};

interface Props {
  onCancel: () => any;
  onSubmit: (value: UpdateItemArgs) => any;
  value?: UpdateItemArgs;
  title: string;
  communityId: string;
  includeUnlistedSuboptions?: string[];
  selectableSuboption?: SuboptionsListProps['selectable'];
  renderSuboptionItem?: SuboptionsListProps['renderItem'];
  subOptions?: SuboptionsListProps['dataSource'];
}

export function ItemEditPanel(props: Props) {
  const {
    onCancel,
    value,
    onSubmit,
    title,
    communityId,
    selectableSuboption,
    includeUnlistedSuboptions,
    renderSuboptionItem,
    subOptions,
  } = props;
  const dataSource = useCategoryLookupSource({ communityId, listed: true });
  const [ids, setIds] = useState<string[]>([]);
  const { t } = useTranslation();

  const id: string | undefined = (value as UpdateItemArgs).id;

  return (
    <Form.Formik<UpdateItemArgs>
      uid="item-details"
      i18n="items.details"
      initialValues={value!}
      validationSchema={schema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values: { subOptionsIds }, setFieldValue }) => (
        <>
          <Row>
            <Col span={12}>
              <Title level={2}>{title}</Title>
              <Form.AsyncSelect
                dataSource={dataSource}
                name="categoryId"
                preloadInitial={value!.categoryId}
                required
              />
              <Form.Input name="name" required />
              <Form.TextArea name="description" />
              <Form.TextArea name="constructionDescription" />
              <Form.EnumSelect type={ItemPricePer} name="pricePer" required />
              <Form.Checkbox name="isGeo" className={styles.geoCheckbox} />
              <SuboptionsList
                name="subOptionsIds"
                readonly={false}
                itemId={id}
                communityId={communityId}
                selectable={selectableSuboption}
                includedUnlistedSuboptions={includeUnlistedSuboptions}
                renderItem={renderSuboptionItem}
                dataSource={subOptions}
                onSubmitHandler={(newSubOptionId: string) => {
                  setFieldValue('subOptionsIds', [
                    ...(subOptionsIds ?? []),
                    newSubOptionId,
                  ]);
                  setIds([...(subOptionsIds ?? []), newSubOptionId]);
                }}
                subOptionsIds={ids}
              />
            </Col>
            <Col span={11} offset={1}>
              <Form.Images
                name="imageActions"
                defer
                ownerId={id}
                ownerType="ItemImage"
                noPreload={!id}
                label={t('items.details.images')}
              />
            </Col>
          </Row>
          <Space className={styles.panelBottom}>
            <Button type="default" onClick={onCancel}>
              {t('items.details.cancelButton')}
            </Button>
            <Form.Submit type="primary">
              {t('items.details.saveButton')}
            </Form.Submit>
          </Space>
        </>
      )}
    </Form.Formik>
  );
}
