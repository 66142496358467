import { Form, notify, useSpin } from '@/components';
import { useUploadItemsMutation } from '@/redux';
import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  excel: Yup.string().nullable().required(),
  attachments: Yup.string().nullable(),
  images: Yup.string().nullable(),
});

interface FormValue {
  excel: string;
  attachments: string;
  images: string;
}

const INITIAL_VALUES: FormValue = {
  excel: undefined!,
  attachments: undefined!,
  images: undefined!,
};

export function FormItemsUpload() {
  const { t } = useTranslation();

  const [upload, { isLoading }] = useUploadItemsMutation();

  useSpin(isLoading);

  const handleSubmit = async (
    value: FormValue,
    { resetForm }: FormikHelpers<FormValue>,
  ) => {
    await upload({
      excelRef: value.excel,
      attachmentsRef: value.attachments,
      imagesRef: value.images,
    })
      .unwrap()
      .then(() => notify.success.t('items.upload.started'));
    resetForm();
  };

  return (
    <Form.Formik<FormValue>
      uid="items-upload"
      i18n="items.upload"
      validationSchema={schema}
      initialValues={INITIAL_VALUES}
      onSubmit={handleSubmit}
    >
      <Form.FileUpload
        name="excel"
        required
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
      <Form.FileUpload
        accept="application/zip,application/x-zip,application/x-zip-compressed"
        name="attachments"
      />
      <Form.FileUpload
        accept="application/zip,application/x-zip,application/x-zip-compressed"
        name="images"
      />

      <Form.Submit uid="items-upload" key="submit" type="primary">
        {t('proceed')}
      </Form.Submit>
    </Form.Formik>
  );
}
