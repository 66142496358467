import { useState } from 'react';
import { CommunityDetailsModal } from '@/views';
import {
  CommunityState,
  useGetCommunityDetailsQuery,
  useUpdateCommunityMutation,
} from '@/redux';
import { useTranslation } from 'react-i18next';
import { notify } from '@/components';

const useSubmit = (closeModal: () => any) => {
  const [updateCommunity] = useUpdateCommunityMutation();

  return async (values: CommunityState) => {
    await updateCommunity(values)
      .unwrap()
      .then(() => {
        notify.success.t('communities.details.saved');
        closeModal();
      });
  };
};

export function useEditCommunityAction() {
  const [id, setId] = useState<string>();
  const { currentData } = useGetCommunityDetailsQuery(
    { id: id! },
    { skip: !id },
  );

  const { t } = useTranslation();
  const submit = useSubmit(() => setId(undefined));

  return {
    openEdit: (id: string) => setId(id),
    editModal: (
      <>
        {id && currentData && (
          <CommunityDetailsModal
            clientSelectionDisabled={true}
            title={t('communities.details.editTitle')}
            buttonSaveName={t('communities.details.editButton')}
            onSubmit={submit}
            value={currentData}
            onClose={() => setId(undefined)}
          />
        )}
      </>
    ),
  };
}
