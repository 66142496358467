export const convertToFormattedPhone = (value?: string | null) => {
  if (!value) {
    return '';
  }
  var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)!;
  return !x[2] ? x[1] : `(${x[1]}) ${x[2]}` + (x[3] ? '-' + x[3] : '');
};

export function PhoneView(props: { value?: string }) {
  return <span>{convertToFormattedPhone(props.value)}</span>;
}
