import { notify } from '@/components';
import { useUpdateCommunitySubcategoryMutation } from '@/redux';
import { useTranslation } from 'react-i18next';
import {
  SubcategoryDetailsForm,
  SubcategoryDetailsFormValue,
} from './SubcategoryDetailsForm';

interface Props {
  communityId: string;
  id: string;
  onCancel: () => any;
  value: SubcategoryDetailsFormValue;
}

const useSubmit = (props: Props) => {
  const { id, onCancel } = props;
  const [update] = useUpdateCommunitySubcategoryMutation();

  return async (value: SubcategoryDetailsFormValue) => {
    await update({ ...value, id })
      .unwrap()
      .then(() => notify.success.t('items.details.subcategories.detailsSaved'))
      .then(onCancel);
  };
};

export function EditSubcategoryForm(props: Props) {
  const { communityId, onCancel, value } = props;
  const { t } = useTranslation();
  const submit = useSubmit(props);

  return (
    <SubcategoryDetailsForm
      communityId={communityId}
      onCancel={onCancel}
      onSubmit={submit}
      title={t('items.details.subcategories.editTitle')}
      value={value}
      isEdit
    />
  );
}
