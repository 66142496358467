import produce from 'immer';
import { first, isEmpty, orderBy, uniq } from 'lodash';
import { DataNode } from 'rc-tree/lib/interface';
import { v4 as uuid } from 'uuid';

export const TREE_NODE_PSEUDO_ID_PREFIX = 'pseudo://';

export interface CustomDataNode extends DataNode {
  custom: string;
}

function newPseudoId(): string {
  return TREE_NODE_PSEUDO_ID_PREFIX + uuid();
}

function newNode(): DataNode {
  return {
    key: newPseudoId(),
    children: [],
    title: null!,
  };
}

function findPath(
  node: DataNode,
  key: DataNode['key'],
  path: DataNode[] = [],
): DataNode[] | undefined {
  if (node.key === key) {
    return [...path, node];
  }

  const children = node.children ?? [];
  for (const childNode of children) {
    const found = findPath(childNode, key, [...path, node]);
    if (found) return found;
  }
}

function findPathInAny(
  rootNodes: DataNode[],
  key: DataNode['key'],
): DataNode[] | undefined {
  for (const rootNode of rootNodes) {
    const found = findPath(rootNode, key);
    if (found) return found;
  }
}

function find(node: DataNode, key: DataNode['key']): DataNode | undefined {
  return findPath(node, key)?.at(-1);
}

function findInAny(
  rootNodes: DataNode[],
  key: DataNode['key'],
): DataNode | undefined {
  for (const rootNode of rootNodes) {
    const found = find(rootNode, key);
    if (found) return found;
  }
}

function findParent(
  node: DataNode,
  key: DataNode['key'],
): DataNode | undefined {
  return node.children?.some((x) => x.key === key)
    ? node
    : node.children?.find((x) => findParent(x, key));
}

function removeChild(rootNode: DataNode, key: DataNode['key']): DataNode {
  return produce(rootNode, (value) => {
    const parent = findParent(value, key)!;
    parent.children = parent.children?.filter((x) => x.key !== key);
    return value;
  });
}

function addChild(
  rootNode: DataNode,
  key: DataNode['key'],
): DataNode | undefined {
  return produce(rootNode, (value) => {
    find(value, key)?.children!.push(newNode());
    return value;
  });
}

export function removePseudo(node: DataNode): DataNode {
  return {
    ...node,
    key: node.key.toString().startsWith(TREE_NODE_PSEUDO_ID_PREFIX)
      ? null!
      : node.key,
    children: node.children?.map(removePseudo),
  };
}

function findChildTitlePath(
  node: DataNode,
  key: DataNode['key'],
  nodePath: string | undefined,
): string | undefined {
  const nodePathPrefix = nodePath ? `${nodePath}.` : '';

  if (node.key === key) {
    return nodePathPrefix + 'title';
  }

  if (isEmpty(node.children)) {
    return undefined;
  }

  for (let i = 0; i < node.children!.length; i++) {
    const child = node.children![i];
    const childNodePath = nodePathPrefix + `children[${i}]`;
    const path = findChildTitlePath(child, key, childNodePath);
    if (path) return path;
  }
}

function findTitlePath(rootNode: DataNode, key: DataNode['key']) {
  return findChildTitlePath(rootNode, key, undefined);
}

function isNew(node: DataNode) {
  return node.key.toString().startsWith(TREE_NODE_PSEUDO_ID_PREFIX);
}

function convertFrom<T>(
  value: T,
  keyBy: Extract<keyof T, string>,
  titleBy: Extract<keyof T, string>,
  childrenBy: Extract<keyof T, string>,
  customBy?: Extract<keyof T, string>,
  orderByKey?: Extract<keyof T, string>,
): CustomDataNode {
  const key: CustomDataNode['key'] = value[keyBy] as any;
  const title: CustomDataNode['title'] = value[titleBy] as any;
  let childrenValues: T[] | undefined = value[childrenBy] as any;
  let custom: CustomDataNode['custom'] = 'false';
  if (customBy) {
    custom = value[customBy] as any;
  }
  if (childrenValues && orderByKey) {
    childrenValues = orderBy(childrenValues, orderByKey);
  }

  return {
    key: key ?? newPseudoId(),
    title,
    children: childrenValues
      ? childrenValues.map((x) =>
          convertFrom(x, keyBy, titleBy, childrenBy, customBy, orderByKey),
        )
      : undefined,
    custom,
  };
}

function convertFromManyCustom<T>(
  value: T[],
  keyBy: Extract<keyof T, string>,
  titleBy: Extract<keyof T, string>,
  childrenBy: Extract<keyof T, string>,
  customBy?: Extract<keyof T, string>,
  orderBy?: Extract<keyof T, string>,
): CustomDataNode[] {
  return value.map((v) =>
    convertFrom(v, keyBy, titleBy, childrenBy, customBy, orderBy),
  );
}

function convertFromMany<T>(
  value: T[],
  keyBy: Extract<keyof T, string>,
  titleBy: Extract<keyof T, string>,
  childrenBy: Extract<keyof T, string>,
  orderBy?: Extract<keyof T, string>,
): DataNode[] {
  return value.map((v) => convertFrom(v, keyBy, titleBy, childrenBy, orderBy));
}

function convertTo<T>(
  node: DataNode,
  keyBy: Extract<keyof T, string>,
  titleBy: Extract<keyof T, string>,
  childrenBy: Extract<keyof T, string>,
): T {
  return {
    [keyBy]: isNew(node) ? null : node.key,
    [childrenBy]: node.children
      ? node.children.map((x) => convertTo(x, keyBy, titleBy, childrenBy))
      : [],
    [titleBy]: node.title!,
  } as any;
}

function filter(nodes: DataNode[], searchString: string): DataNode[] {
  searchString = searchString.trim().toLowerCase();
  if (searchString.length === 0) {
    return nodes;
  }

  function match(node: DataNode): DataNode | false {
    if (node.title?.toString().toLowerCase().includes(searchString)) {
      return node;
    }

    if (!node.children) {
      return false;
    }

    const children = node.children
      .map((child) => match(child))
      .filter((x) => !!x) as DataNode[];

    if (children.length === 0) {
      return false;
    }

    return {
      ...node,
      children,
    };
  }

  return nodes.map((node) => match(node)).filter((x) => !!x) as DataNode[];
}

function merge(tree1: DataNode[], tree2: DataNode[]) {
  function findInOne(tree: DataNode, path: string[]): DataNode | undefined {
    const key = first(path);
    const found = tree.children!.find((x) => x.key === key);
    if (!found) return found;

    return path.length === 1 ? found : findInOne(found, path.slice(1));
  }

  function find(tree: DataNode[], path: string[]): DataNode | undefined {
    const key = first(path);
    const found = tree.find((x) => x.key === key);
    if (!found) return found;

    return path.length === 1 ? found : findInOne(found, path.slice(1));
  }

  function merge(path: string[]): DataNode {
    const tree1Node = find(tree1, path);
    const tree2Node = find(tree2, path);

    if (!tree1Node || !tree2Node) {
      return tree1Node! ?? tree2Node!;
    }

    const children1 = tree1Node?.children ?? [];
    const children2 = tree2Node?.children ?? [];
    const childrenKeys = uniq(
      children1.concat(children2).map((x) => x.key! as string),
    );

    return {
      ...(tree1Node ?? {}),
      ...(tree2Node ?? {}),
      children: childrenKeys.map((key) => merge([...path, key])),
    } as DataNode;
  }

  const rootNodeIds = uniq(tree1.concat(tree2).map((x) => x.key));
  return rootNodeIds.map((key) => merge([key as string]));
}

function flatten(node: DataNode): DataNode[] {
  return [node].concat(node.children?.flatMap((n) => flatten(n)) ?? []);
}

function getNodesCount(nodes?: DataNode[]): number {
  return nodes ? nodes.map((x) => flatten(x)).flat().length : 0;
}

export const dataNodeUtils = {
  findTitlePath,
  isNew,
  newNode,
  find,
  findInAny,
  findParent,
  findPath,
  findPathInAny,
  addChild,
  removeChild,
  convertFrom,
  convertFromMany,
  convertFromManyCustom,
  convertTo,
  filter,
  merge,
  flatten,
  getNodesCount,
};
