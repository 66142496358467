import { TabPanel, Title, useTabPanelNavMenuState } from '@/components';
import { RoomState, WholeHomeRoomState } from '@/core';
import {
  useGetNotFinalizedRoomPersonalizationRoomIdsListQuery,
  useGetPersonalizationRoomsListQuery,
  useGetProjectHomeInfoQuery,
} from '@/redux';
import { array } from '@/utils';
import {
  PersonalizationSteps,
  getDevelopScopeRoomId,
  usePersonalizationSteps,
} from '@/views';
import { Col, Row, Space, Switch } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePersonalizationData } from '@/views/Personalization.Summary/PersonalizationDetails';
import { useGetPersonalizationItemsSubtotalAmount } from '@/views/Personalization.Summary/PersonalizationItemList';
import { isArray } from 'lodash';
import { RoomPersonalizationTabNavIcon } from './RoomPersonalizationTabNavIcon';
import { RoomPersonalizationAlert } from './RoomPersonalizationAlert';
import { RoomPersonalizationAmountInfo } from './RoomPersonalizationAmountInfo';
import { RoomPersonalizationCategoryList } from './RoomPersonalizationCategoryList';

interface Props {
  communityId: string;
  projectId: string;
  homeId: string;
  compact?: boolean;
  selectedRoomId?: string;
}

function useTabNav(
  data: RoomState[],
  notFinalizedRoomIds: (string | null)[],
  isSelectedStep: boolean,
) {
  const { t } = useTranslation();

  return useTabPanelNavMenuState({
    items: [
      {
        id: 'null',
        name: t('developScope.wholeHome'),
      } as WholeHomeRoomState,
      ...data,
    ],
    keyBy: (x) => x.id,
    iconBy: (x) =>
      isSelectedStep && (
        <RoomPersonalizationTabNavIcon
          isAlert={notFinalizedRoomIds.includes(getDevelopScopeRoomId(x.id)!)}
        />
      ),
    titleBy: (x) => x.name,
  });
}

export function useRoomPersonalization(props: Props) {
  const {
    communityId,
    homeId,
    projectId,
    compact,
    selectedRoomId: initialSelectedRoomId,
  } = props;
  const { t } = useTranslation();

  var { data: projectHome } = useGetProjectHomeInfoQuery({
    id: projectId,
    homeId,
  });

  const steps = usePersonalizationSteps({ projectId, projectHome });
  const { selected: selectedStepIds } = steps;

  const selectedSteps = useMemo(() => {
    const stepIds = isArray(selectedStepIds)
      ? selectedStepIds
      : selectedStepIds
      ? [selectedStepIds]
      : undefined;

    const result = steps.steps.filter((x) => stepIds?.includes(x.id));

    const isWholeHomeSelected = result.length !== stepIds?.length;

    return isWholeHomeSelected ? [undefined] : result;
  }, [selectedStepIds, steps.steps]);

  const isWholeHomeSelected = selectedSteps.some((x) => !x?.id);

  const [collapsed, setCollapsed] = useState(false);

  const { data = array.empty<RoomState>() } =
    useGetPersonalizationRoomsListQuery({
      homeId,
      projectId,
    });

  const { data: notFinalizedRoomIds = array.empty<string>() } =
    useGetNotFinalizedRoomPersonalizationRoomIdsListQuery(
      {
        communityId,
        projectId,
        homeId: homeId,
        stepId: selectedSteps[0]?.id!,
      },
      {
        skip:
          !selectedSteps || isWholeHomeSelected || selectedSteps.length !== 1,
      },
    );

  const tabNav = useTabNav(
    data,
    notFinalizedRoomIds,
    selectedSteps.length === 1 && !isWholeHomeSelected,
  );
  const { selected, items } = tabNav;

  const selectedRoomId =
    getDevelopScopeRoomId(initialSelectedRoomId || selected?.id) || undefined;

  const selectedRoomName = items.find(
    (x) => x.id === (initialSelectedRoomId || selected?.id),
  )?.name;

  const { totalHomeData, roomsData, wholeHomeData } = usePersonalizationData({
    homeId,
    projectId,
    stepId: isWholeHomeSelected ? undefined : selectedSteps?.map((x) => x!.id),
  });

  const { amount: totalHomeAmount, isFinalAmount: isTotalHomeAmountFinal } =
    useGetPersonalizationItemsSubtotalAmount(
      totalHomeData,
      (x) => x.totalResidentPrice,
      true,
      true,
    );

  const selectedRoomData = useMemo(() => {
    return selectedRoomId
      ? roomsData.find((x) => x.roomId === selectedRoomId)?.value || []
      : wholeHomeData;
  }, [selectedRoomId, roomsData, wholeHomeData]);

  const { amount: roomAmount, isFinalAmount: isRoomAmountFinal } =
    useGetPersonalizationItemsSubtotalAmount(
      selectedRoomData,
      (x) => x.totalResidentPrice,
      true,
      true,
    );

  const content = (
    <Space direction="vertical" size="large" className="w-100">
      <TabPanel>
        {homeId && !compact && (
          <TabPanel.Nav title={t('developScope.navTitle')}>
            <TabPanel.Menu {...tabNav} />
          </TabPanel.Nav>
        )}
        <TabPanel.Body>
          {selected && selectedRoomName && (
            <>
              <Row>
                <Col span={18}>
                  <Title>{selectedRoomName}</Title>
                </Col>
                <Col span={compact ? 24 : 6}>
                  <Space>
                    <Switch
                      checked={collapsed}
                      onChange={() => setCollapsed(!collapsed)}
                    />
                    {t(
                      collapsed
                        ? 'personalization.item.details.expand'
                        : 'personalization.item.details.collapse',
                    )}
                  </Space>
                </Col>
              </Row>
              {selectedSteps.map((step) => {
                return (
                  <RoomPersonalizationCategoryList
                    key={selectedRoomId}
                    homeId={homeId}
                    projectId={projectId}
                    step={step}
                    roomId={selectedRoomId}
                    collapsed={collapsed}
                    compact={compact}
                  />
                );
              })}
            </>
          )}
        </TabPanel.Body>
      </TabPanel>
    </Space>
  );

  const personalizationAlert = (
    <>
      {!isWholeHomeSelected &&
        selectedSteps.length === 1 &&
        notFinalizedRoomIds.length === 0 && <RoomPersonalizationAlert />}
    </>
  );

  const personalizationSteps = (
    <Row>
      <Col span={compact ? 24 : 10} className="mb-2">
        <PersonalizationSteps {...steps} mode="multiple" />
      </Col>

      <Col span={compact ? 24 : 14}>
        <RoomPersonalizationAmountInfo
          isTotalHomeAmountFinal={isTotalHomeAmountFinal}
          totalHomeAmount={totalHomeAmount}
          currentRoomAmount={roomAmount}
          isCurrentRoomAmountFinal={isRoomAmountFinal}
        />
      </Col>
    </Row>
  );

  const title = (
    <Title level={1}>
      <Row>{t('personalization.title')}</Row>
    </Title>
  );

  return {
    title,
    personalizationSteps,
    content,
    personalizationAlert,
  };
}
