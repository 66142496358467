import { enumObject, EnumValue } from '@/utils';
import { ProjectStepLookupDto } from '@/core';

const TYPE_VALUES = ['Payment', 'Credit', 'Fee', 'Reimbursement'] as const;
export type PaymentType = EnumValue<typeof TYPE_VALUES>;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentType = enumObject('PaymentType', TYPE_VALUES);

export interface PaymentsDto {
  id: string;
  paymentType: PaymentType;
  date: string;
  description: string;
  amount: number;
  homeId: string;
  projectId: string;
  communityId: string;
  clientId: string;
  step: ProjectStepLookupDto;
}

export type AddPaymentDto = Omit<
  PaymentsDto,
  'id' | 'communityId' | 'clientId' | 'step'
> & { stepId: string };
