import { Button, Modal } from 'antd';
import { ChangePasswordPanel } from '../Users.PasswordChange/PasswordChangePanel';
import { useBool } from '@/utils';
import { useTranslation } from 'react-i18next';
import { Form, useAppFindFormik } from '@/components';
import styles from './AuthenticationDetails.module.scss';
import { useCallback } from 'react';

export function ChangePasswordButton() {
  const [visible, , toggleVisible] = useBool(false);
  const { t } = useTranslation();
  const form = useAppFindFormik('password-change');
  const onClose = useCallback(() => {
    toggleVisible();
    form.current?.resetForm();
  }, [form, toggleVisible]);
  return (
    <>
      <Button className={styles.btn} type="link" onClick={toggleVisible}>
        {t('users.passwordChange.change')}
      </Button>
      <Modal
        visible={visible}
        onCancel={onClose}
        title={t('users.passwordChange.title')}
        footer={
          <>
            <Button type="default" onClick={onClose}>
              {t('cancel')}
            </Button>
            <Form.Submit uid="password-change" type="primary">
              {t('save')}
            </Form.Submit>
          </>
        }
      >
        <ChangePasswordPanel onSubmit={toggleVisible} />
      </Modal>
    </>
  );
}
