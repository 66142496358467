import { Form, notify, useSpin } from '@/components';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
import { useUploadHomesMutation } from '@/redux';
import { CodedException } from '@/core';
import { RtkqNotifyError } from '@/redux/rtkq';

const schema = Yup.object().shape({
  excel: Yup.string().nullable().required(),
});

interface FormValue {
  excel?: string;
}

const INITIAL_VALUES: FormValue = {
  excel: undefined,
};

interface Props {
  onUploadFailed: (payload: string[]) => any;
}

export function FormHomesUpload({ onUploadFailed }: Props) {
  const { t } = useTranslation();

  const [upload, { isLoading }] = useUploadHomesMutation();
  useSpin(isLoading);

  const handleSubmit = (
    value: FormValue,
    { resetForm }: FormikHelpers<FormValue>,
  ) => {
    const handleError = (error: any) => {
      const e = CodedException.from(error);
      if (e.code === 'INV.HOMES_UPLOAD.FAILED') {
        onUploadFailed(e.payload);
      } else {
        notify.error.t('uploadFile.invalidData');
      }
    };
    upload({
      excelRef: value.excel!,
      [RtkqNotifyError]: false,
    })
      .unwrap()
      .then(() => notify.success.t('homes.upload.success'))
      .catch(handleError);
    resetForm();
  };

  return (
    <Form.Formik<FormValue>
      uid="homes-upload"
      i18n="homes.upload"
      validationSchema={schema}
      initialValues={INITIAL_VALUES}
      onSubmit={handleSubmit}
    >
      <Form.FileUpload
        name="excel"
        required
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
      <Form.Submit key="submit" type="primary">
        {t('proceed')}
      </Form.Submit>
    </Form.Formik>
  );
}
