import { Checkbox } from 'antd';
import React from 'react';

interface Props {
  column: ColumnState;
  onChange: (column: ColumnState) => any;
}

export interface ColumnState {
  title: React.ReactNode;
  key: React.Key;
  enabled: boolean;
}

export function ColumnMenuItem(props: Props) {
  const { column, onChange } = props;

  const handleClick = () => {
    onChange({ ...column, enabled: !column.enabled });
  };

  return (
    <Checkbox checked={column.enabled} onClick={handleClick}>
      {column.title}
    </Checkbox>
  );
}
