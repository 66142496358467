import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export function useActivityKindTranslation() {
  const { t, i18n } = useTranslation();
  return useCallback(
    (kind: string) => {
      const key = `activity.kind.${kind.replaceAll(':', '_')}`;
      if (!i18n.exists(key)) {
        return kind;
      }

      return t(key);
    },
    [t, i18n],
  );
}
