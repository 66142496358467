import { useMemo, useState } from 'react';
import {
  DefaultNumberSorting,
  Table,
  useDefaultTablePaging,
  useFilters,
  useSorting,
} from '@/components';
import {
  TaskFinancialInfoState,
  useGetTaskFinancialInfoListQuery,
} from '@/redux';
import { useTaskFinancialInfoColumns } from '.';
import { TaskBalanceSummaryTable } from './TaskBalanceSummaryTable';
import { TaskFilterParams } from '@/core';

interface Props {
  projectId: string;
}

export function TaskFinancialInfoListPanel(props: Props) {
  const { projectId } = props;

  const { setTotal, paging, params } = useDefaultTablePaging();
  const {
    filters,
    onFiltersChange,
    resetFilters,
    stringFilterOptions,
    setStringFilterOptions,
  } = useFilters<TaskFilterParams>(
    ['number', 'name', 'home', 'responsiblePerson'],
    undefined,
    ['estimatedCost', 'actualCost', 'budgetBalance'],
  );
  const { sorting, onSortingChange } =
    useSorting<TaskFinancialInfoState>(DefaultNumberSorting);

  const { data, isFetching } = useGetTaskFinancialInfoListQuery({
    projectId,
    paging: params,
    filters,
    sorting,
  });

  const tasks = useMemo(() => {
    if (data) {
      setTotal(data.total);
      setStringFilterOptions(data.stringFilterOptions);
    }
    return data?.items || [];
  }, [data, setStringFilterOptions, setTotal]);

  const columns = useTaskFinancialInfoColumns(tasks, stringFilterOptions);
  const [selectedTaskIds, setSelectedTaskIds] = useState<string[]>([]);

  const selectedTasks = useMemo(
    () => tasks.filter((x) => selectedTaskIds.includes(x.id)),
    [selectedTaskIds, tasks],
  );

  return (
    <div>
      <TaskBalanceSummaryTable
        selected={selectedTasks}
        tasks={tasks}
        className="mb-5"
      />

      <Table<TaskFinancialInfoState>
        name="TasksFinancialInfo"
        rowSelection={{
          type: 'checkbox',
          onChange: (_, rows) => setSelectedTaskIds(rows.map((x) => x.id)),
          selectedRowKeys: selectedTaskIds,
        }}
        onFiltersReset={resetFilters}
        loading={isFetching}
        columns={columns}
        dataSource={tasks}
        rowKey={(row) => row.id}
        pagination={paging}
        onChange={(_, filters, sorting) => {
          onFiltersChange(filters);
          onSortingChange(sorting);
        }}
      />
    </div>
  );
}
