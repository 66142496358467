import { notify } from '@/components';
import {
  SpecialRequestCommentState,
  UpdateSpecialRequestCommentArgs,
  useDeleteSpecialRequestCommentMutation,
  useGetAllSpecialRequestCommentsQuery,
  useUpdateSpecialRequestCommentMutation,
} from '@/redux';
import { Divider, List, ListProps } from 'antd';
import { isEmpty } from 'lodash';
import { SpecialRequestCommentItem } from './SpecialRequestCommentItem';

interface Props {
  specialRequestId: string;
}

const LOCALE: ListProps<SpecialRequestCommentState>['locale'] = {
  emptyText: <></>,
};

export function SpecialRequestCommentsList(props: Props) {
  const { specialRequestId } = props;

  const { data } = useGetAllSpecialRequestCommentsQuery({ specialRequestId });
  const [update] = useUpdateSpecialRequestCommentMutation();
  const [remove] = useDeleteSpecialRequestCommentMutation();

  const handleUpdate = (comment: UpdateSpecialRequestCommentArgs) =>
    update(comment)
      .unwrap()
      .then(() => notify.success.t('specialRequests.comments.saved'));

  const handleDelete = (id: string) =>
    remove({ id })
      .unwrap()
      .then(() => notify.success.t('specialRequests.comments.deleted'));

  return (
    <div>
      {!isEmpty(data) && <Divider dashed />}
      {!isEmpty(data) && (
        <List
          locale={LOCALE}
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <li>
              <SpecialRequestCommentItem
                onDelete={handleDelete}
                onSubmit={handleUpdate}
                value={item}
              />
            </li>
          )}
        />
      )}
    </div>
  );
}
