import { useTranslation } from 'react-i18next';
import { AddButton, Form, notify } from '@/components';
import { FormRadioOption, FormsRadio } from '@/components/Forms/FormsRadio';
import i18next from 'i18next';
import {
  AssignFloorplanToHomeDto,
  FloorplanCreationType,
  FloorplanDetailsDto,
  isChooseExistingOption,
} from '@/core';
import { useCallback, useMemo, useState } from 'react';
import {
  AssignFloorplanToHomeArgs,
  useAddFloorplanMutation,
  useAssignFloorplanToHomeMutation,
} from '@/redux';
import { FloorplanDetailsModal, useFloorplanLookupSource } from '@/views';
import * as Yup from 'yup';

export function useValue(
  communityId: string,
  homeId: string,
  value?: FloorplanDetailsDto,
  copyFromValue?: FloorplanDetailsDto,
): AssignFloorplanToHomeDto {
  return useMemo(
    () => ({
      creationOption: 'existing',
      floorplanId: value?.id!,
      communityId: communityId!,
      marketingName: value?.marketingName!,
      constructionName: copyFromValue
        ? copyFromValue?.marketingName
        : value?.constructionName!,
      type: copyFromValue ? copyFromValue?.type : value?.type!,
      squareFootage: copyFromValue
        ? copyFromValue?.squareFootage
        : value?.squareFootage!,
      description: copyFromValue
        ? copyFromValue?.description
        : value?.description!,
      communityName: null!,
      id: value?.id!,
      homeId: homeId!,
    }),
    [
      communityId,
      copyFromValue,
      homeId,
      value?.constructionName,
      value?.description,
      value?.id,
      value?.marketingName,
      value?.squareFootage,
      value?.type,
    ],
  );
}

export const assignFloorplanSchema = Yup.object().shape({
  creationOption: Yup.mixed<FloorplanCreationType>().nullable().required(),
  floorplanId: Yup.string().when('creationOption', {
    is: 'existing',
    then: Yup.string().field().nullable().required(),
    otherwise: Yup.string().field().nullable().notRequired(),
  }),
  type: Yup.string().when('creationOption', {
    is: (val: FloorplanCreationType) => val === 'new' || val === 'copy',
    then: Yup.string().field().nullable().required(),
    otherwise: Yup.string().field().nullable().notRequired(),
  }),
  marketingName: Yup.string().when('creationOption', {
    is: (val: FloorplanCreationType) => val === 'new' || val === 'copy',
    then: Yup.string().field().nullable().required(),
    otherwise: Yup.string().field().nullable().notRequired(),
  }),
  constructionName: Yup.string().field().nullable(),
  description: Yup.string().field('lg').nullable(),
});

interface Props {
  homeId: string;
  communityId: string;
}

export const useSubmitAssignFloorplanToHome = (props: Props) => {
  const { homeId } = props;
  const [add] = useAddFloorplanMutation();
  const [assign] = useAssignFloorplanToHomeMutation();

  const submitAssign = useCallback(
    (values: AssignFloorplanToHomeDto) => {
      return assign({ homeId: homeId, floorplanId: values.floorplanId })
        .unwrap()
        .then(() => {
          notify.success.t('floorplans.added');
        });
    },
    [assign, homeId],
  );

  const submitAdd = useCallback(
    (values: AssignFloorplanToHomeDto) => {
      return add(values)
        .unwrap()
        .then((id: string) => {
          notify.success.t('floorplans.added');
        });
    },
    [add],
  );

  return { submitAssign, submitAdd };
};

export const OPTIONS: FormRadioOption<FloorplanCreationType>[] = [
  { value: 'existing', label: i18next.t<string>('floorplans.chooseExisting') },
  { value: 'new', label: i18next.t<string>('floorplans.setManually') },
  { value: 'copy', label: i18next.t<string>('floorplans.copy') },
];

export const AssignFloorplanToHomeInModal = (props: Props) => {
  const { t } = useTranslation();
  const { communityId, homeId } = props;
  const floorplansDataSource = useFloorplanLookupSource(communityId);
  const initialValues = useValue(communityId, homeId);
  const { submitAssign, submitAdd } = useSubmitAssignFloorplanToHome(props);
  const [openModal, setOpenModal] = useState(false);

  return (
    <Form.Formik<AssignFloorplanToHomeArgs>
      uid="home-floorplan"
      initialValues={initialValues}
      onSubmit={submitAssign}
      validationSchema={assignFloorplanSchema}
    >
      {({ values }) => (
        <>
          <FormsRadio
            name="creationOption"
            options={OPTIONS.filter((x) => x.value !== 'copy')}
            label={t('floorplans.chooseExistingOrSetManually')}
            inline={false}
          />
          {isChooseExistingOption(values.creationOption) && (
            <>
              <Form.AsyncSelect
                name="floorplanId"
                dataSource={floorplansDataSource}
                required
                preload
                label={t('floorplans.header')}
              />
              <Form.Submit uid="home-floorplan" key="submit" type="primary">
                {t('save')}
              </Form.Submit>
            </>
          )}
          {!isChooseExistingOption(values.creationOption) && (
            <>
              <AddButton onClick={() => setOpenModal(true)}>
                {t('floorplans.details.addButton')}
              </AddButton>
              {openModal && (
                <FloorplanDetailsModal
                  title={t('floorplans.details.addTitle')}
                  buttonSaveName={t('floorplans.details.saveButton')}
                  onSubmit={submitAdd}
                  value={{
                    ...initialValues,
                    creationOption: values.creationOption,
                  }}
                  onClose={() => setOpenModal(false)}
                />
              )}
            </>
          )}
        </>
      )}
    </Form.Formik>
  );
};
