import {
  CancelButton,
  EditIconButton,
  Form,
  notify,
  SaveButton,
} from '@/components';
import * as Yup from 'yup';
import { MAX_MONEY_VALUE } from '@/utils';
import {
  TaskFinancialInfoState,
  useUpdateTaskActualCostMutation,
} from '@/redux';
import { useCallback, useState } from 'react';
import { formatters, CommunityPermissions } from '@/core';
import styles from './TaskFinancialInfoList.module.scss';
import { Space } from 'antd';
import { Assert } from '../Communities.Common';
import { useProjectContext } from '..';

const schema = Yup.object().shape({
  actualCost: Yup.number().min(0).max(MAX_MONEY_VALUE).nullable(),
});

interface Props {
  value: TaskFinancialInfoState;
}

export type TaskActualCostFormValue = OmitIndex<Yup.InferType<typeof schema>>;

const useSubmit = (props: Props, onSubmitted: () => any) => {
  const { id } = props.value ?? {};
  const [update] = useUpdateTaskActualCostMutation();

  return useCallback(
    async ({ actualCost }: TaskActualCostFormValue) => {
      await update({ id: id!, value: actualCost })
        .unwrap()
        .then(() => notify.success.t('tasks.financialInfo.actualCostSaved'))
        .then(onSubmitted);
    },
    [id, update, onSubmitted],
  );
};

export function TaskActualCostEditableValue(props: Props) {
  const { value } = props;
  const [isEdit, setEdit] = useState(false);
  const { isActive: isActiveProject } = useProjectContext();
  const toggleEdit = useCallback(() => setEdit((prev) => !prev), [setEdit]);
  const handleSubmit = useSubmit(props, toggleEdit);

  const { actualCost } = value;

  if (!isEdit) {
    return (
      <div>
        {actualCost !== null ? formatters.price(actualCost) : '-'}
        {isActiveProject && (
          <Assert permission={CommunityPermissions.DevelopScope.Manage} active>
            <EditIconButton type="link" onClick={toggleEdit} />
          </Assert>
        )}
      </div>
    );
  }

  return (
    <Form.Formik<TaskActualCostFormValue>
      uid={`task-actial-cost-${value.id}`}
      initialValues={value}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <div className={styles.actualCost}>
          <Form.Money name="actualCost" label={false} positive />
          <Space className="ps-2">
            <CancelButton type="link" onClick={toggleEdit} />
            <SaveButton type="link" onClick={formik.submitForm} />
          </Space>
        </div>
      )}
    </Form.Formik>
  );
}
