import { enumObject, EnumValue } from '@/utils';
import { Address, CommonFilterType } from '../http';

const COMMUNITY_STATUS = ['Active', 'Deactivated'] as const;

export type CommunityStatus = EnumValue<typeof COMMUNITY_STATUS>;

// eslint-disable-next-line
export const CommunityStatus = enumObject('CommunityStatus', COMMUNITY_STATUS);

export function isActiveCommunityStatus(status: CommunityStatus) {
  return status !== 'Deactivated';
}

export type AddCommunityDto = {
  name: string;
  description?: string;
  address: Address;
  clientId: string;
};

export interface CommunityDto {
  id: string;
  number: string;
  name: string;
  description?: string;
  address: Address;
  status: CommunityStatus;
  clientId: string;
  clientName: string;
  projectCount: number;
  homeCount: number;
  hasSubscription?: boolean;
}

export interface TreeNodeDto {
  id: string;
  name: string;
  custom?: boolean;
  children: TreeNodeDto[];
}

export type UpdateCommunityDto = CommunityDto;

export interface CommunityContextDto {
  communityId: string;
  status: CommunityStatus;
  permissions: string[];
}

export interface CommunitiesFilterParams extends CommonFilterType {
  number?: string[];
  name?: string[];
  clientName?: string[];
  city?: string[];
  state?: string[];
  status?: string[];
  projectCountFrom?: string;
  projectCountTo?: string;
  homeCountFrom?: string;
  homeCountTo?: string;
}
