export const BFP_ORGANIZATION_ID = '6d8acfcc-d6ae-423a-a3df-6ffe167356c7';
export const DATE_FORMAT_MOMENT = 'MM/DD/YYYY';
export const DATE_TIME_FORMAT_MOMENT = 'MM/DD/YYYY hh:mm A';
export const DATE_TIME_UTC_OFFSET = '-06:00';
export const MAX_FILE_SIZE = 18874368; // 18 mb
export const DATE_TIME_UTC_OFFSET_HOURS = -6;

export function isBFP(organizationId?: string) {
  return organizationId === BFP_ORGANIZATION_ID;
}

export function featureEnabled(name: string) {
  return (
    window.appSettings.features[name] === true ||
    getLocalFeatureSettings()[name] === true
  );
}

function getLocalFeatureSettings() {
  const featureSettingsStored = localStorage.getItem('feature-settings');
  return featureSettingsStored ? JSON.parse(featureSettingsStored) : {};
}

(window as any).updateLocalFeature = (name: string, enabled: boolean) => {
  const featureSettings = getLocalFeatureSettings();
  featureSettings[name] = enabled;
  localStorage.setItem('feature-settings', JSON.stringify(featureSettings));

  // eslint-disable-next-line
  window.location.href = window.location.href;
};
