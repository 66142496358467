import {
  DATE_FORMAT_MOMENT,
  DATE_TIME_FORMAT_MOMENT,
  DATE_TIME_UTC_OFFSET,
} from '@/platform';
import { guard } from '@/utils';
import { padStart } from 'lodash';
import moment from 'moment';

type Names = { firstName: string; lastName: string };

function name(names: Names): string;
function name(firstName: string, lastName: string): string;
function name(firstNameOrNames: string | Names, lastName?: string): string {
  if (typeof firstNameOrNames === 'object') {
    return name(firstNameOrNames.firstName, firstNameOrNames.lastName);
  }

  return `${firstNameOrNames} ${lastName}`;
}

function initials(name: string) {
  guard.notNull(name, 'name');

  if (name.length === 0) {
    return '';
  }

  const splitted = name.split(' ');
  if (splitted.length === 1) return name.charAt(0);
  return name.charAt(0) + splitted[splitted.length - 1].charAt(0);
}

function sizeText(size: number) {
  if (size / 1024 / 1024 > 1) {
    return `${Math.round((size / 1024 / 1024) * 100) / 100} MB`;
  }

  if (size / 1024 > 1) {
    return `${Math.round((size / 1024) * 100) / 100} KB`;
  }

  return `${size} Bytes`;
}

export const formatters = {
  id: (value: number) => {
    return padStart(value.toString(), 3, '0');
  },
  number: (value?: number) => {
    return value?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  },
  name,
  initials,
  money: (value?: number) => {
    return value?.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  },
  price: (value?: number) => {
    return value?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  },
  fileSize: (value: number) => {
    return sizeText(value);
  },
  dateTime: (
    value: string | Date,
    format: 'date' | 'date-time' = 'date-time',
  ) => {
    // moment.utc(value) used instead moment(value) to ensure when timezone not specified - it will assume UTC
    const momentDateTime = moment.utc(value).utcOffset(DATE_TIME_UTC_OFFSET);
    const momentFormat =
      format === 'date-time' ? DATE_TIME_FORMAT_MOMENT : DATE_FORMAT_MOMENT;
    return momentDateTime.format(momentFormat);
  },
  footage: (value?: number) => {
    return value?.toFixed(2);
  },
};
