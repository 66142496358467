import { SortResult } from '@/components/Collections.Paging/useSorting';
import {
  AddHomeDto,
  asFileResponse,
  DownloadedFile,
  GenerateHomeOfferingsReportDto,
  HomeDetailsDto,
  HomeDto,
  HomeFilterParams,
  Http,
  Paged,
  Paging,
  UpdateHomeDto,
} from '@/core';
import { guard } from '@/utils';
import { isArray } from 'lodash';
import qs from 'qs';

class HomesHttp extends Http {
  public async getAll(
    paging: Paging,
    communityId?: string | string[],
    projectId?: string | string[],
    filterForAddingAvailability?: boolean,
    filters?: HomeFilterParams,
    sorting?: SortResult,
  ) {
    const query = qs.stringify({
      communityIds: isArray(communityId) ? communityId : [communityId],
      projectIds: isArray(projectId) ? projectId : [projectId],
      filterForAddingAvailability,
      ...paging,
      ...filters,
      ...sorting,
    });
    const url = `/api/home?${query}`;
    const response = await this._axios.get<Paged<HomeDto>>(url);
    return response.data;
  }

  public async get(id: string) {
    guard.notNull(id, 'id');
    const response = await this._axios.get<HomeDetailsDto>(`/api/home/${id}`);
    return response.data;
  }

  public async create(request: AddHomeDto) {
    guard.notNull(request, 'request');
    const response = await this._axios.post<string>(`/api/home`, request);
    return response.data;
  }

  public async update(request: UpdateHomeDto) {
    guard.notNull(request, 'request');
    await this._axios.put(`/api/home`, request);
  }

  public async assignFloorplan(homeId: string, floorplanId: string) {
    guard.notNull(homeId, 'homeId');
    guard.notNull(floorplanId, 'floorplanId');
    await this._axios.put(
      `/api/home/${homeId}/assign-floorplan?floorplanId=${floorplanId}`,
    );
  }

  public async upload(request: { excelRef: string }) {
    guard.notNull(request, 'request');
    const response = await this._axios.post<string>(
      `/api/home/upload`,
      request,
    );
    return response.data;
  }

  public async downloadUploadSample(): Promise<DownloadedFile> {
    const response = await this._axios.get(`/api/home/download-upload-sample`, {
      responseType: 'blob',
    });
    return asFileResponse(response);
  }

  public async assignMenuToHomes(request: {
    homeIds: string[];
    menuId: string;
    projectId: string;
  }) {
    guard.notNull(request, 'request');
    await this._axios.post<string>(`/api/home/assign-menu`, request);
  }

  public generateOfferingsReport = async (
    request: GenerateHomeOfferingsReportDto,
  ): Promise<string> => {
    const response = await this._axios.post('/api/report/offerings', request);
    return response.data;
  };
}

export const homesHttp = new HomesHttp();
