import { useCallback, useRef } from 'react';
import { domUtil } from '../domUtil';

export function useClickOutside(
  options: () => any | { onClickOutside: () => any },
) {
  const optionsObject =
    typeof options === 'function' ? { onClickOutside: options } : options;
  const { onClickOutside } = optionsObject;
  const contentRef = useRef<HTMLDivElement>(null);

  const onContainerClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const target = e.target as HTMLElement;

      if (!domUtil.isChildOf(target, contentRef.current!)) {
        onClickOutside();
      }
    },
    [onClickOutside],
  );

  return { onContainerClick, contentRef };
}
