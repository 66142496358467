import { FilePdfOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  FileDownloadButton,
  FileDownloadButtonProps,
} from './FileDownloadButton';

type Props = Omit<FileDownloadButtonProps, 'icon' | 'name'> & { name?: string };

export function PdfDownloadButton(props: Props) {
  const { name, download } = props;
  const { t } = useTranslation();
  return (
    <FileDownloadButton
      {...props}
      name={name ?? t('downloadPdf')}
      download={download || 'open'}
      icon={<FilePdfOutlined />}
    />
  );
}
