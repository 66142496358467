import {
  AddItemMenuDto,
  Http,
  ItemMenuDetailsDto,
  ItemMenuDto,
  UpdateItemMenuDto,
} from '@/core';
import { guard } from '@/utils';
import qs from 'qs';

class ItemMenuHttp extends Http {
  public async getAll(communityId: string, listed: boolean | null = true) {
    guard.notNull(communityId, 'communityId');
    const uri = `/api/item-menu?${qs.stringify({ communityId, listed })}`;
    const response = await this._axios.get<ItemMenuDto[]>(uri);
    return response.data;
  }

  public async getDetails(id: string, listed: boolean | null = true) {
    guard.notNull(id, 'id');
    const uri = `/api/item-menu/${id}/details?${qs.stringify({ listed })}`;
    const response = await this._axios.get<ItemMenuDetailsDto>(uri);
    return response.data;
  }

  public async add(menu: AddItemMenuDto) {
    guard.notNull(menu, 'menu');
    const uri = `/api/item-menu`;
    const response = await this._axios.post<string>(uri, menu);
    return response.data;
  }

  public async update(menu: UpdateItemMenuDto) {
    guard.notNull(menu, 'menu');
    const uri = `/api/item-menu/${menu.id}`;
    const response = await this._axios.put(uri, menu);
    return response.data;
  }

  public async remove(id: string) {
    guard.notNull(id, 'id');
    const uri = `/api/item-menu/${id}`;
    const response = await this._axios.delete(uri);
    return response.data;
  }
}

export const itemMenuHttp = new ItemMenuHttp();
