import { Form, LookupOption } from '@/components';
import { useCommunityLookupSource } from '@/views';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { SubscriptionFormValue } from './SubscriptionDetailsForm';

interface Props {
  name: string;
  preloadInitial?: LookupOption;
  disabled?: boolean;
  initialClientId?: string;
}

export function FormSelectCommunity(props: Props) {
  const { name, preloadInitial, disabled, initialClientId } = props;
  const { values, setFieldValue } = useFormikContext<SubscriptionFormValue>();
  const { clientId } = values;
  const communityDataSource = useCommunityLookupSource(clientId);

  useEffect(() => {
    if (initialClientId === clientId) {
      return;
    }
    setFieldValue(name, undefined);
  }, [clientId, initialClientId, name, setFieldValue]);

  return (
    <Form.AsyncSelect
      key={clientId}
      dataSource={communityDataSource}
      name={name}
      preloadInitial={preloadInitial}
      disabled={disabled}
      required
    />
  );
}
