import { Form } from '@/components';
import { useTranslation } from 'react-i18next';
import { useActivityUserLookupSource } from './useActivityUserLookupSource';

interface Props {
  name: string;
}

export function ActivityUserSelect(props: Props) {
  const { name } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'activity.table.filter.placeholders',
  });
  const userDataSource = useActivityUserLookupSource();

  return (
    <Form.AsyncSelect
      label={false}
      dataSource={userDataSource}
      name={name}
      placeholder={t('user')}
      mode="multiple"
    />
  );
}
