import { enumObject, EnumValue } from '@/utils';
import { CommonFilterType } from '..';

export const TASK_STATUS_VALUES = [
  'NotStarted',
  'InProgress',
  'Completed',
] as const;

export type TaskStatus = EnumValue<typeof TASK_STATUS_VALUES>;

// eslint-disable-next-line
export const TaskStatus = enumObject('TaskStatus', TASK_STATUS_VALUES);

export interface TaskDto {
  id: string;
  number: string;
  name: string;
  homeName: string;
  homeId?: string;
  startDate?: string;
  endDate?: string;
  responsiblePerson: TaskResponsiblePersonValue;
  estimatedCost: number;
  description?: string;
  projectId: string;
  status: TaskStatus;
}

export interface AddTaskDto
  extends Omit<TaskDto, 'id' | 'number' | 'homeName'> {
  startDate?: string;
  endDate?: string;
  developScopeItemId?: string;
}

export interface AddRowTaskDto
  extends Omit<TaskDto, 'id' | 'number' | 'homeName'> {
  startDate?: string;
  endDate?: string;
  developScopeItemId?: string;
  checked: boolean;
}

export type UpdateTaskDto = Omit<TaskDto, 'number'>;

export interface DuplicateTaskDto {
  projectId: string;
  taskIds: string[];
  homeIds: string[];
}

export interface TaskDetailsDto extends TaskDto {
  projectName: string;
  communityId: string;
  communityName: string;
  previousTaskId?: string;
  nextTaskId?: string;
}

export interface TaskCommentDto {
  id: string;
  content: string;
  createdAt: string;
  createdByName: string;
  createdById: string;
}

export type AddTaskCommentDto = Omit<
  TaskCommentDto,
  'id' | 'createdAt' | 'createdByName' | 'createdById'
> & { projectTaskId: string };

export type UpdateTaskCommentDto = Omit<
  TaskCommentDto,
  'createdAt' | 'createdByName' | 'createdById'
>;

export interface TaskFinancialInfoDto
  extends Pick<
    TaskDto,
    | 'id'
    | 'number'
    | 'name'
    | 'homeName'
    | 'homeId'
    | 'responsiblePerson'
    | 'estimatedCost'
    | 'projectId'
  > {
  actualCost: number | undefined;
}

export interface TaskResponsiblePersonValue {
  userId?: string;
  subcontractorId?: string;
  label?: string;
  otherValue?: string;
}

export function getNextTaskStatus(status: TaskStatus) {
  switch (status) {
    case TaskStatus.NotStarted:
      return TaskStatus.InProgress;
    case TaskStatus.InProgress:
      return TaskStatus.Completed;
    case TaskStatus.Completed:
    default:
      throw new Error(`Unable to resolve next status for TaskStatus ${status}`);
  }
}

export function isFinalTaskStatus(status: TaskStatus) {
  return status === TaskStatus.Completed;
}

export const BULK_UPDATE_TASK_VALUES = [
  'StartDate',
  'EndDate',
  'PersonResponsible',
  'CostEstimate',
  'Status',
] as const;

export type BulkUpdateTaskFieldType = EnumValue<typeof BULK_UPDATE_TASK_VALUES>;

// eslint-disable-next-line
export const BulkUpdateTaskFieldType = enumObject(
  'BulkUpdateTaskFieldType',
  BULK_UPDATE_TASK_VALUES,
);

export interface BulkUpdateTaskField {
  type: BulkUpdateTaskFieldType;
  value: string;
}

export interface BulkUpdateTaskDto {
  taskIds: string[];
  projectId: string;
  fields: BulkUpdateTaskField[];
}

export interface BulkDeleteTaskDto {
  taskIds: string[];
  projectId: string;
}

export interface TaskFilterParams extends CommonFilterType {
  number?: string[];
  name?: string[];
  home?: string[];
  startDateFrom?: string;
  startDateTo?: string;
  endDateFrom?: string;
  endDateTo?: string;
  responsiblePerson?: string[];
  costEstimatedFrom?: string;
  costEstimatedTo?: string;
  actualCostFrom?: string;
  actualCostTo?: string;
  budgetBalanceFrom?: string;
  budgetBalanceTo?: string;
  status?: string[];
}
