import { AddButton, Icon, Tree } from '@/components';
import {
  CategoryState,
  ItemState,
  useGetCategoriesQuery,
  useGetItemsListQuery,
  useUserContextSelector,
} from '@/redux';
import { array, tree } from '@/utils';
import { DataNode } from 'antd/lib/tree';
import React, { useMemo, useState } from 'react';
import { ItemDeleteButton } from '../Items.Details';
import { useTranslation } from 'react-i18next';
import styles from './ItemListPanel.module.scss';
import classNames from 'classnames';
import { DeleteSubcategoryButton } from '../Items.Subcategories';
import { ItemIcon } from '../Items';
import { Assert, useCommunityContext } from '../Communities.Common';
import { CommunityPermissions } from '@/core';

const MAX_LEVEL = 2;

interface DataSource {
  items: ItemState[];
  categories: CategoryState[];
}

interface CustomDataNode extends DataNode {
  name: string;
}

export interface ItemListNavProps {
  dataSource?: DataSource;
  onSelected: (id: string | undefined) => any;
  selectedId: string | undefined;
  onSubcategorySelected: (id: string | undefined) => any;
  selectedSubcategoryId: string | undefined;
  onAdd?: (categoryId: string) => any;
  onAddSubcategory?: (categoryId: string) => any;
  onDeleted?: () => any;
  isGeo?: boolean;
  communityId: string;
}

export function useItemNavSelectedState(): Pick<
  ItemListNavProps,
  | 'onSelected'
  | 'selectedId'
  | 'onSubcategorySelected'
  | 'selectedSubcategoryId'
> {
  const [selectedId, onSelected] = useState<string>();
  const [selectedSubcategoryId, onSubcategorySelected] = useState<string>();
  return {
    selectedId,
    onSelected,
    onSubcategorySelected,
    selectedSubcategoryId,
  };
}

function useDataSource(props: ItemListNavProps) {
  const { communityId, isGeo, dataSource } = props;

  const { data: items = dataSource?.items || array.empty<ItemState>() } =
    useGetItemsListQuery(
      {
        communityId,
        isGeo,
      },
      { skip: !!dataSource },
    );

  const {
    data: categories = dataSource?.categories || array.empty<CategoryState>(),
  } = useGetCategoriesQuery(
    { communityId, listed: true },
    { skip: !!dataSource },
  );

  return useMemo(() => ({ items, categories }), [items, categories]);
}

function useTreeData(
  props: Omit<ItemListNavProps, 'dataSource'>,
  dataSource: DataSource,
) {
  const { t } = useTranslation();
  const { onDeleted, onAdd, onAddSubcategory } = props;

  const hasRole = useUserContextSelector((x) => x.hasRole);
  const { hasPermission } = useCommunityContext();
  const canAddSubcategory =
    hasRole('CLIENT_ADMIN') ||
    hasRole('BFP_ADMIN') ||
    hasPermission(CommunityPermissions.ItemsCategories.Manage);

  const { items, categories } = dataSource;

  return useMemo(() => {
    function mapCategory(
      category: CategoryState,
      level: number,
    ): CustomDataNode {
      const categoryChildren = category!.children
        ? category!.children.map((x) => mapCategory(x, level + 1))
        : [];

      const itemChildren = items!
        .filter((x) => x.categoryId === category.id)
        .map((item) => ({
          key: item.id,
          name: item.name,
          title: (
            <>
              {item.name}
              {onDeleted && (
                <Assert
                  permission={CommunityPermissions.ItemsCategories.Manage}
                  active
                >
                  <ItemDeleteButton id={item.id} onDeleted={onDeleted} />
                </Assert>
              )}
            </>
          ),
          className: 'item-child',
          icon: <ItemIcon />,
        }));
      const childrenNodes = [...categoryChildren, ...itemChildren].sort(
        (a, b) => a.name.localeCompare(b.name),
      );
      return {
        key: category.id,
        name: category.name,
        title: (
          <>
            {category.name}
            {category.custom && (
              <Assert
                permission={CommunityPermissions.ItemsCategories.Manage}
                active
              >
                <DeleteSubcategoryButton id={category.id} />
              </Assert>
            )}
          </>
        ),
        className: 'item-child',
        icon: (
          <>
            {canAddSubcategory && onAddSubcategory && level < MAX_LEVEL && (
              <Assert
                permission={CommunityPermissions.ItemsCategories.Manage}
                active
              >
                <AddButton
                  type="link"
                  title={t('items.addSubcategoryIconTitle')}
                  onClick={(event) => {
                    event.stopPropagation();
                    onAddSubcategory(category.id);
                  }}
                />
              </Assert>
            )}

            {onAdd && (
              <Assert
                permission={CommunityPermissions.ItemsCategories.Manage}
                active
              >
                <AddButton
                  type="link"
                  title={t('items.addIconTitle')}
                  onClick={(event) => {
                    event.stopPropagation();
                    onAdd(category.id);
                  }}
                />
              </Assert>
            )}
          </>
        ),
        children: childrenNodes,
      };
    }

    return categories!.map((category) => mapCategory(category, 0));
  }, [
    categories,
    items,
    onAdd,
    onAddSubcategory,
    onDeleted,
    canAddSubcategory,
    t,
  ]);
}

function findFirst(categories: CategoryState[], id: string) {
  return tree.findFirst(
    categories,
    id,
    (x) => x.id,
    (x) => x.children,
  );
}

export function ItemListNav(props: ItemListNavProps) {
  const {
    selectedId,
    onSelected: onChange,
    onSubcategorySelected,
    selectedSubcategoryId,
  } = props;

  const dataSource = useDataSource(props);
  const treeData: DataNode[] = useTreeData(props, dataSource);

  const { items, categories } = dataSource;

  const selectedKeys = useMemo(
    () =>
      selectedId
        ? [selectedId]
        : selectedSubcategoryId
        ? [selectedSubcategoryId]
        : [],

    [selectedId, selectedSubcategoryId],
  );

  const onSelect = (selectedKeysValue: React.Key[]) => {
    const key = selectedKeysValue[0].toString();
    const selectedItem = items.find((x) => x.id === key);
    const selectedSubcategory = findFirst(categories, key);
    onSubcategorySelected(selectedSubcategory?.id);
    onChange(selectedItem?.id);
  };

  return (
    <Tree
      rootClassName={classNames('nav', styles.nav)}
      treeData={treeData}
      onSelect={onSelect}
      showIcon
      selectedKeys={selectedKeys}
      switcherIcon={<Icon type="arrow-down" />}
    />
  );
}
