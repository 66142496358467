import React, { useMemo, useState } from 'react';
import { useGetDevelopScopeItemsListQuery } from '@/redux';
import {
  DevelopScopeListItem,
  statusFilters,
  useProjectContext,
} from '@/views';
import { Divider, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { groupBy, isEmpty } from 'lodash';
import { DevelopScopeItemDto, DevelopScopeTaskStatus } from '@/core';
import { DevelopScopeTaskStatusFilter } from './DevelopScopeTaskStatusFilter';

const { Text, Title } = Typography;

interface Props {
  homeId: string;
  roomId: string | null;
}

const useItems = (
  filterStatuses: DevelopScopeTaskStatus[] | undefined,
  items?: DevelopScopeItemDto[],
) => {
  const filteredItems = useMemo(
    () =>
      isEmpty(filterStatuses)
        ? items
        : filterStatuses!.flatMap((status) =>
            items!.filter(statusFilters[status]),
          ),
    [filterStatuses, items],
  );

  const itemsByRoomId = useMemo(
    () => groupBy(filteredItems, (x) => x.room.id),
    [filteredItems],
  );
  const roomIds = useMemo(() => Object.keys(itemsByRoomId), [itemsByRoomId]);

  return { itemsByRoomId, roomIds };
};

export function DevelopScopeWholeHomeList(props: Props) {
  const { homeId, roomId } = props;
  const { projectId } = useProjectContext();
  const [selectedStatus, setSelectedStatus] =
    useState<DevelopScopeTaskStatus[]>();
  const { data: items } = useGetDevelopScopeItemsListQuery({
    projectId,
    homeId,
    roomId,
  });
  const { t } = useTranslation();
  const { itemsByRoomId, roomIds } = useItems(selectedStatus, items);

  if (!items) {
    return null;
  }

  return (
    <>
      <Typography.Title level={5}>
        {t('developScope.wholeHome')}
      </Typography.Title>
      <DevelopScopeTaskStatusFilter
        onSelected={setSelectedStatus}
        selected={selectedStatus}
        className="mt-3"
      />
      {roomIds.map((roomId) => {
        const { room } = itemsByRoomId[roomId]![0];

        return (
          <React.Fragment key={roomId}>
            <Title level={5}>
              {room.name}
              {!room.listed && (
                <Text type="secondary" className="ms-2">
                  {t('developScope.unlisted')}
                </Text>
              )}
            </Title>
            {itemsByRoomId[roomId]!.map((item, index) => (
              <React.Fragment key={item.id}>
                <DevelopScopeListItem
                  item={item}
                  className="mb-3"
                  readonly={true}
                />
                {index < items.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </React.Fragment>
        );
      })}
    </>
  );
}
