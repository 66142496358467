import { Http } from '../http/http';
import axios from 'axios';
import {
  UpdateProfileDto,
  UserInviteDto,
  ChangePasswordDto,
  UserContextDto,
  UserDto,
  UpdateUserDto,
  Role,
  ActivityUserDto,
  UserFilterParams,
  UpdateUserAvatarDto,
  GeneratePasswordDto,
} from '.';
import { guard } from '@/utils';
import qs from 'qs';
import { Paging } from '../http/paging';
import { Paged } from '../http/paged';
import { SortResult } from '@/components/Collections.Paging/useSorting';

class UsersHttp extends Http {
  public async context() {
    const response = await this._axios.get<UserContextDto>(`/api/user/context`);
    return response.data;
  }

  public async changePassword(value: ChangePasswordDto) {
    guard.notNull(value, 'value');

    const uri = `${window.appSettings.identityServerUrl}/api/user/password`;
    await this._axios.put(uri, value);
  }

  public async generatePassword(value: GeneratePasswordDto) {
    guard.notNull(value, 'value');

    const url = `/api/user/${value.userId}/generate-password`;
    await this._axios.put(url, value.new, {
      headers: {
        'content-type': 'application/json',
      },
    });
  }

  public async invite(value: UserInviteDto) {
    guard.notNull(value, 'value');

    await this._axios.post(`/api/user/invite`, value);
  }

  public async delete(id: string) {
    guard.notNull(id, 'value');

    await this._axios.delete(`/api/user/${id}`);
  }

  public async updateProfile(value: UpdateProfileDto) {
    guard.notNull(value, 'value');
    const { id } = value;
    const url = `/api/user/profile/${id}`;
    await this._axios.put(url, value);
  }

  public async get(id: string) {
    guard.notNull(id, 'id');

    const url = `/api/user/${id}`;
    const response = await this._axios.get<UserDto>(url);
    return response.data;
  }

  public async update(value: UpdateUserDto) {
    guard.notNull(value, 'value');

    const url = `/api/user/${value.id}`;
    await this._axios.put(url, value);
  }

  public async updateAvatar(value: UpdateUserAvatarDto) {
    guard.notNull(value, 'value');

    const url = `/api/user/${value.id}/avatar`;
    await this._axios.put(url, value);
  }

  public async all(args?: {
    organizationId?: string;
    onlyClient?: boolean;
    active?: boolean;
    roles?: Role[];
    paging: Paging;
    filters?: UserFilterParams;
    sorting?: SortResult;
  }) {
    const queryString = qs.stringify(
      { ...args, ...args?.paging, ...args?.filters, ...args?.sorting } ?? {},
      {
        addQueryPrefix: true,
      },
    );
    const url = `/api/user${queryString}`;
    const response = await this._axios.get<Paged<UserDto>>(url);
    return response.data;
  }

  public async getActivityUsers() {
    const url = `/api/user/activity-users`;
    const response = await this._axios.get<ActivityUserDto[]>(url);
    return response.data;
  }

  public async changeActive(id: string, value: boolean) {
    guard.notNull(id, 'id');
    guard.notNull(value, 'value');

    const url = `/api/user/${id}/active`;
    await this._axios.put(url, JSON.stringify(value), {
      headers: {
        'content-type': 'application/json',
      },
    });
  }

  public async unlock(id: string) {
    guard.notNull(id, 'id');

    const url = `/api/user/${id}/unlock`;
    await this._axios.put(url);
  }

  public async exists(email: string) {
    guard.notNull(email, 'email');
    const url = `/api/user/exists/${encodeURI(email)}`;
    const response = await axios.get<boolean>(url);
    return response.data;
  }
}

export const usersHttp = new UsersHttp();
