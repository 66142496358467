function isChildOf(element: HTMLElement, of: HTMLElement): boolean {
  if (element === of) {
    return true;
  }

  if (element.parentElement == null) {
    return false;
  }

  return isChildOf(element.parentElement!, of);
}

export const domUtil = { isChildOf };
