import { useUserContextSelector } from '@/redux';

export function useGetReportTemplateOwnerId(): string {
  const [isAdmin, id, organizationId] = useUserContextSelector((x) => [
    x.isAdmin,
    x.id,
    x.organizationId,
  ]);

  return isAdmin ? organizationId! : id;
}
