import {
  DeleteIconButton,
  EnumValue,
  ImageBox,
  StyledRow,
  useImageBoxLazy,
} from '@/components';
import { CommunityPermissions, ItemMenuItemType, formatters } from '@/core';
import { PersonalizationItemState } from '@/redux';
import { Card, Col, Space, Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Assert } from '../Communities.Common';
import { EditPersonalizationItem } from './Item.Details/EditPersonalizationItem';
import { PersonalizationItemFormValue } from './Item.Details/PersonalizationItemDetailsModal';
import { PersonalizationItemSuboption } from './PersonalizationItemSuboption';
import styles from './PersonalizationListItem.module.scss';

const { Text, Title, Paragraph } = Typography;

export interface PersonalizationListItemProps {
  item: PersonalizationItemState;
  className?: string;
  onDelete: (id: string) => any;
  onSubmit: (
    value: PersonalizationItemFormValue,
    item: PersonalizationItemState,
  ) => any;
  readonly?: boolean;
  compact?: boolean;
}

export function PersonalizationListItem(props: PersonalizationListItemProps) {
  const { item, onDelete, onSubmit, readonly, className, compact } = props;
  const { t } = useTranslation();
  const { name, description, notes, step, pricing, id, subOption, number } =
    item;
  const { squareFootage, pricePer, totalResidentPrice, type, tbd, notInMenu } =
    pricing ?? {};

  const clippoImagesProps = useImageBoxLazy({
    defer: false,
    ownerId: item.itemId,
    ownerType: 'ItemImage',
  });

  return (
    <div className={className}>
      <Card className={styles.cardBody} bordered={false}>
        <StyledRow wrap={compact}>
          <Col>
            <ImageBox
              {...clippoImagesProps}
              compact={compact}
              inCard
              readonly
            />
          </Col>
          <Col flex="auto">
            <StyledRow justify="space-between" wrap={compact} align="middle">
              <Col flex="auto">
                <Title level={3} ellipsis={{ rows: 3, tooltip: true }}>
                  {number} {name}
                </Title>
              </Col>
              <Col>
                <Title level={5} className="text-end">
                  <EnumValue type={ItemMenuItemType} value={type} />
                </Title>
              </Col>
            </StyledRow>
            <StyledRow>
              <Col>
                <Paragraph
                  className={styles.description}
                  ellipsis={{ tooltip: true, rows: 1 }}
                >
                  {description}
                </Paragraph>
                <div className={styles.notes}>{notes}</div>
              </Col>
            </StyledRow>
            <StyledRow justify="space-between">
              <Col>
                <Tag color="blue">
                  <span className={styles.label}>
                    {t('personalization.item.details.step')}
                  </span>
                  : {step?.name}
                </Tag>
              </Col>
              <Col>
                <Title level={2} className="mb-0">
                  {notInMenu && (
                    <Text type="danger">
                      {t('personalization.item.details.notInMenu')}
                    </Text>
                  )}
                  {!notInMenu && !tbd && formatters.money(totalResidentPrice)}
                  {!notInMenu && tbd && (
                    <Text type="danger">
                      {t('personalization.item.details.tbd')}
                    </Text>
                  )}
                </Title>
                {!!totalResidentPrice &&
                  pricePer === 'Foot' &&
                  !!squareFootage && (
                    <Text type="secondary">
                      {t('personalization.item.details.priceFor', {
                        squareFootage: formatters.number(squareFootage),
                      })}
                    </Text>
                  )}
              </Col>
            </StyledRow>
            <PersonalizationItemSuboption
              suboption={subOption}
              className="mt-3"
            />
          </Col>
          <Col className="text-end">
            {!readonly && (
              <Assert permission={CommunityPermissions.Personalization.Manage}>
                <Space>
                  <EditPersonalizationItem value={item} onSubmit={onSubmit} />
                  <DeleteIconButton
                    onClick={() => onDelete(id)}
                    confirm
                    entityName={t('personalization.item.details.itemFullPath')}
                  />
                </Space>
              </Assert>
            )}
          </Col>
        </StyledRow>
      </Card>
    </div>
  );
}
