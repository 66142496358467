import { TextBox, EditIconButton, DeleteIconButton, Title } from '@/components';
import {
  useGetTaskTemplateDetailsQuery,
  useUserContextSelector,
} from '@/redux';
import { useTranslation } from 'react-i18next';
import { TaskTemplateTaskList, useBoundUnlistTaskTemplate } from '@/views';
import { useNavigate } from 'react-router-dom';

interface Props {
  id: string;
  readonly?: boolean;
  editRoute: (id: string) => string;
}

export function TaskTemplateDetailsPanel(props: Props) {
  const { id, readonly, editRoute } = props;
  const { data } = useGetTaskTemplateDetailsQuery({ id });
  const [isBFP, , hasRole] = useUserContextSelector((x) => [
    x.isBFP,
    x.organizationId,
    x.hasRole,
  ]);
  const { t } = useTranslation(undefined, { keyPrefix: 'taskTemplates.details', });
  const push = useNavigate();
  const handleUnlist = useBoundUnlistTaskTemplate(data?.id);

  if (!data) {
    return null;
  }

  const { name } = data;
  const canModify =
    (isBFP ||
      (hasRole('CLIENT_ADMIN'))) &&
    !readonly;

  return (
    <div>
      <Title className="mt-0">
        {t('general')}

        <Title.Actions inline space>
          {canModify && (
            <EditIconButton type="link" onClick={() => push(editRoute(id))} />
          )}
          {canModify && <DeleteIconButton onClick={handleUnlist}/>}
        </Title.Actions>
      </Title>
      <TextBox label={t('name')}>{name}</TextBox>
      <TextBox label={t('taskDetails')}>
        <TaskTemplateTaskList value={data} templateId={data.id} editRoute={() => `/communities/${
          data!.communityId
        }/task-templates/${id}/edit`
        } />
      </TextBox>
    </div>
  );
}
