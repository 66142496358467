import {
  createEntireLoadLookupSource,
  LookupOption,
  LookupSource,
} from '@/components';
import { DEFAULT_PAGING } from '@/core';
import {
  RtkqSpin,
  TaskTemplateLookupState,
  useLazyGetTaskTemplatesListQuery,
} from '@/redux';
import { useMemo } from 'react';

export function mapTaskTemplateLookup(
  value: TaskTemplateLookupState,
): LookupOption {
  return {
    label: value.name,
    value: value.id,
  };
}

export function useTaskTemplateLookupSource(
  communityId?: string,
): LookupSource {
  const [fetchList] = useLazyGetTaskTemplatesListQuery();

  return useMemo(() => {
    const entireSource = createEntireLoadLookupSource({
      initial: (id: string) =>
        fetchList(
          { communityId, paging: DEFAULT_PAGING, [RtkqSpin]: false },
          true,
        )
          .unwrap()
          .then((values) =>
            mapTaskTemplateLookup(values.items.find((x) => x.id === id)!),
          ),
      load: () =>
        fetchList(
          { communityId, paging: DEFAULT_PAGING, [RtkqSpin]: false },
          true,
        )
          .unwrap()
          .then((values) => values.items.map((x) => mapTaskTemplateLookup(x))),
    });
    return { ...entireSource };
  }, [fetchList, communityId]);
}
