import { CategoryLookupDto, ItemMenuItemType } from '@/core';
import { TreeDataNode } from 'antd';
import { last } from 'lodash';
import { CategoryLookupState, ItemMenuItemDetailsState } from '@/redux';
import { ItemIcon } from '../Items';

export interface ItemMenuTreeNode {
  id: string;
  name: string;
  type: 'category' | 'item';
  children: ItemMenuTreeNode[];
}

const COLOR_BY_TYPE: Record<ItemMenuItemType, string> = {
  Standard: '#1fb197',
  Upgrade: '#efac4e',
};

const defaultMapItem = (item: ItemMenuItemDetailsState): TreeDataNode => {
  return {
    key: item.id,
    className: 'item',
    title: item.name,
    icon: <ItemIcon color={COLOR_BY_TYPE[item.type]} />,
  };
};

const defaultMapCategory = (category: CategoryLookupDto) => ({
  key: category.id,
  title: category.name,
  children: [],
});

interface Options {
  mapItem?: (item: ItemMenuItemDetailsState) => TreeDataNode;
  mapCategory?: (category: CategoryLookupState) => TreeDataNode;
}

export function convertItemMenuItemsToTreeData(
  items: ItemMenuItemDetailsState[],
  options?: Options,
) {
  const { mapCategory = defaultMapCategory, mapItem = defaultMapItem } =
    options ?? {};

  const treeData: TreeDataNode[] = [];
  const categoriesById: Record<string, TreeDataNode> = {};

  function isCategoryExists(category: CategoryLookupDto) {
    return !!categoriesById[category.id];
  }

  function mapCategoryToTreeDataNode(
    category: CategoryLookupDto,
  ): TreeDataNode {
    return {
      ...mapCategory(category),
      children: [],
    };
  }

  function pushCategory(
    parent: CategoryLookupDto | undefined,
    category: CategoryLookupDto,
  ) {
    const node = mapCategoryToTreeDataNode(category);
    categoriesById[category.id] = node;

    if (!parent) {
      treeData.push(node);
    } else {
      categoriesById[parent.id].children!.push(node);
    }
  }

  function ensureCategoryExists(
    parent: CategoryLookupDto | undefined,
    category: CategoryLookupDto,
  ) {
    if (isCategoryExists(category)) {
      return;
    }

    pushCategory(parent, category);
  }

  function ensureCategoriesExists(item: ItemMenuItemDetailsState) {
    const { categoryPath } = item;

    categoryPath.forEach((item, index) =>
      ensureCategoryExists(
        index > 0 ? categoryPath[index - 1] : undefined,
        item,
      ),
    );
  }

  function visit(item: ItemMenuItemDetailsState) {
    const { categoryPath } = item;
    ensureCategoriesExists(item);
    const category = categoriesById[last(categoryPath)!.id];
    category.children!.push(mapItem(item));
  }

  items.forEach(visit);
  return treeData;
}
