import { ReactNode } from 'react';
import styles from './IconButton.module.scss';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
interface Props {
  buttonIcon: ReactNode;
  title: string;
  url?: string;
  bgColor?: 'navy' | 'cyan' | 'blue' | 'emerald' | undefined
  path?: string;
}

export function IconButton(props: Props){
  const { buttonIcon, title, bgColor, path} = props;
  const push = useNavigate();

  return (
    <div className={styles.iconButton} onClick={() => path && push(path)}>
      <div className={styles.icon}>
        <div className={classNames(styles.container, bgColor)}>
          {buttonIcon}
        </div>
      </div>
      <div className={styles.title}>
        {title}
      </div>
    </div>
  )
}