import { useTranslation } from 'react-i18next';
import { Icon, Page } from '@/components';
import { useParams } from 'react-router-dom';
import { useGetProjectPathDetailsQuery } from '@/redux';
import { ProjectPathDetailsPanel } from '@/views';

export function ProjectPathDetailsPage() {
  const { t } = useTranslation();
  const { id } = useParams<'id'>();
  const { data } = useGetProjectPathDetailsQuery({ projectId: id! });
  const title = data?.name ?? t('fetchFallbackTitle');

  return (
    <Page.Card title={title} icon={<Icon type="template" />} color="#EFAC4E">
      {id && <ProjectPathDetailsPanel projectId={id} />}
    </Page.Card>
  );
}
