import { dataNodeUtils, Tree } from '@/components';
import { TreeNodeDto } from '@/core';
import {
  PathTemplateDetailsState,
  PathTemplateStepDetailsState,
  ProjectPathDetailsState,
} from '@/redux';
import { array } from '@/utils';
import { Steps } from 'antd';
import { useMemo } from 'react';

interface Props {
  value: PathTemplateDetailsState | ProjectPathDetailsState;
  className?: string;
}

const { Step } = Steps;

function StepTitle(props: { value: PathTemplateStepDetailsState }) {
  const { value } = props;
  const { name, selectedCategories = array.empty<TreeNodeDto>() } = value;

  const treeData = useMemo(
    () =>
      dataNodeUtils.convertFromMany(
        selectedCategories,
        'id',
        'name',
        'children',
        'name',
      ),
    [selectedCategories],
  );

  return (
    <>
      <div className="pt-2">{name}</div>
      <Tree treeData={treeData} selectable={false} virtual={false} />
    </>
  );
}

export function PathTemplateStepList(props: Props) {
  const { value, className } = props;
  return (
    <Steps className={className} direction="vertical" size="small" current={0}>
      {value.steps.map((step, index) => (
        <Step key={index} status="process" title={<StepTitle value={step} />} />
      ))}
    </Steps>
  );
}
