import { AttachmentDto } from '@/core';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteIconButton } from '../../Styled';
import styles from '../ImageBox.module.scss';
import { ImageBoxImage } from '../ImageBoxImage';

interface Props {
  item: AttachmentDto;
  onOpenPreview: (item: AttachmentDto) => any;
  readonly?: boolean;
  onDelete?: (item: AttachmentDto) => any;
}

function _ImageBoxGalleryItem(props: Props) {
  const { item, onOpenPreview, readonly, onDelete } = props;
  const { t } = useTranslation();

  const handleDelete = useCallback(() => onDelete!(item), [item, onDelete]);

  const handleClick = useCallback(
    () => onOpenPreview(item),
    [item, onOpenPreview],
  );

  return (
    <div key={item.id} className={styles.galleryItem}>
      <ImageBoxImage onClick={handleClick} value={item} />

      <div className={styles.galleryItemActions}>
        {!readonly && onDelete && (
          <DeleteIconButton
            onClick={handleDelete}
            entityName={t('image.entityName')}
            confirm
          >
            {t('delete')}
          </DeleteIconButton>
        )}
      </div>
    </div>
  );
}

export const ImageBoxGalleryItem = memo(_ImageBoxGalleryItem);
