import { Icon, PdfDownloadButton, TabPanel } from '@/components';
import { developScopeHttp, RoomState } from '@/core';
import {
  HomeRoomFinalizationDetailsState,
  useGetDevelopScopeRoomsListQuery,
  useGetNotFinalizedRoomIdsListQuery,
  useGetProjectContextQuery,
  useGetProjectQuery,
} from '@/redux';
import {
  SearchProjectHomeInput,
  useHomeSelectedState,
  DevelopScopeListPanel,
  DevelopScopeHomeFinalizedAlert,
  ProjectContext,
  FloorplanPersonalizationPanel,
  useDevScopeTabNav,
  RoomPersonalizationPanel,
} from '@/views';
import { array } from '@/utils';
import { isEmpty } from 'lodash';
import { Button, Col, Row, Tooltip } from 'antd';
import { useState } from 'react';
import styles from './Workspace.module.scss';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

const MIN_HEIGHT_FOR_ROOM = 40;

interface Props {
  projectId: string;
  homeId?: string;
}

export function WorkspacePanel(props: Props) {
  const { projectId, homeId } = props;
  const selectedState = useHomeSelectedState(homeId);
  const { selectedId: selectedHomeId } = selectedState;

  const { data: project } = useGetProjectQuery({ id: projectId });
  const { data = array.empty<RoomState>(), isFetching: isFetchingRooms } =
    useGetDevelopScopeRoomsListQuery(
      {
        projectId,
        homeId: selectedHomeId!,
      },
      { skip: !selectedHomeId },
    );

  const {
    data: notFinalizedRoomIds = array.empty<HomeRoomFinalizationDetailsState>(),
    isFetching: isFetchingNotFinalizedRoomIds,
  } = useGetNotFinalizedRoomIdsListQuery(
    {
      projectId,
      homeId: selectedHomeId!,
    },
    { skip: !selectedHomeId },
  );

  const tabNav = useDevScopeTabNav(
    data,
    notFinalizedRoomIds,
    !isFetchingRooms && !isFetchingNotFinalizedRoomIds && !!selectedHomeId,
  );

  const { selected, select: selectByRoomId } = tabNav;
  const { communityId } = project ?? {};
  const [personalizationCollapsed, setPersonalizationCollapsed] =
    useState(true);
  const [developScopeCollapsed, setDevelopScopeCollapsed] = useState(false);
  const { data: projectContext } = useGetProjectContextQuery(
    {
      id: projectId,
    },
    { skip: !projectId },
  );

  const { t } = useTranslation();

  const minHeighForSider = data.length * MIN_HEIGHT_FOR_ROOM;

  return (
    <TabPanel>
      (
      <TabPanel.Nav>
        <div className="mt-4">
          <TabPanel.Menu {...tabNav} />
        </div>
      </TabPanel.Nav>
      )
      <TabPanel.Body
        pre={
          communityId ? (
            <>
              <PdfDownloadButton
                action={() =>
                  developScopeHttp.downloadPdf(projectId, selectedHomeId!)
                }
                className="mb-3"
                disabled={!selectedHomeId}
              />
              <SearchProjectHomeInput
                className="w-100 mb-5"
                communityId={communityId}
                projectId={projectId}
                showResidentNames
                {...selectedState}
              />
              {selectedHomeId &&
                !isEmpty(data) &&
                isEmpty(notFinalizedRoomIds) && (
                  <DevelopScopeHomeFinalizedAlert className="mb-5" />
                )}
            </>
          ) : undefined
        }
      >
        <Row justify="space-between">
          {!developScopeCollapsed && (
            <Col span={!personalizationCollapsed ? 11 : 22}>
              {selected && (
                <DevelopScopeListPanel
                  projectId={projectId}
                  homeId={selectedHomeId!}
                  roomId={selected.id}
                  isWholeHome={selected.id === undefined}
                  isFloorplan={!!selected.isFloorplan}
                  onFloorplanItemAdd={() => selectByRoomId('null')}
                  compact
                />
              )}
            </Col>
          )}
          <Col span={1} className="ms-1">
            {selected && (
              <>
                <Tooltip placement="right" title={t('developScope.title')}>
                  <Button
                    className={classnames(
                      styles.sider,
                      styles['content-end'],
                      personalizationCollapsed &&
                        !developScopeCollapsed &&
                        styles.disable,
                    )}
                    style={{ minHeight: minHeighForSider }}
                    icon={
                      <Icon
                        type={
                          developScopeCollapsed
                            ? 'side-nav-expand'
                            : 'side-nav-collapse'
                        }
                        className={styles.icon}
                        fill="#e4f4fc"
                      />
                    }
                    onClick={() =>
                      setDevelopScopeCollapsed(!developScopeCollapsed)
                    }
                  />
                </Tooltip>
                <Tooltip placement="right" title={t('personalization.title')}>
                  <Button
                    className={classnames(
                      styles.sider,
                      styles['content-start'],
                      !personalizationCollapsed &&
                        developScopeCollapsed &&
                        styles.disable,
                    )}
                    style={{ minHeight: minHeighForSider }}
                    icon={
                      <Icon
                        type={
                          personalizationCollapsed
                            ? 'side-nav-collapse'
                            : 'side-nav-expand'
                        }
                        className={styles.icon}
                        fill="#e4f4fc"
                      />
                    }
                    onClick={() =>
                      setPersonalizationCollapsed(!personalizationCollapsed)
                    }
                  />
                </Tooltip>
              </>
            )}
          </Col>
          {!personalizationCollapsed && (
            <Col span={!developScopeCollapsed ? 11 : 22}>
              {selected && projectContext && (
                <ProjectContext.Provider value={projectContext}>
                  {selected.isFloorplan ? (
                    <FloorplanPersonalizationPanel
                      homeId={selectedHomeId!}
                      projectId={projectId}
                      communityId={communityId!}
                      compact
                    />
                  ) : (
                    <RoomPersonalizationPanel
                      communityId={communityId!}
                      homeId={selectedHomeId!}
                      projectId={projectId}
                      selectedRoomId={selected.id}
                      compact
                    />
                  )}
                </ProjectContext.Provider>
              )}
            </Col>
          )}
        </Row>
      </TabPanel.Body>
    </TabPanel>
  );
}
