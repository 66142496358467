import { rtkq, RtkqRequest } from '../rtkq';
import {
  AddHomeDto,
  HomeDto,
  HomeDetailsDto,
  UpdateHomeDto,
  homesHttp,
  GenerateHomeOfferingsReportDto,
  Paged,
  Paging,
  HomeFilterParams,
} from '@/core';
import { api } from '../api';
import { SortResult } from '@/components/Collections.Paging/useSorting';

export type HomeState = HomeDto;
export type HomeDetailsState = HomeDetailsDto;
export type AddHomeArgs = AddHomeDto;
export type UpdateHomeArgs = UpdateHomeDto;
export type GenerateHomeOfferingsReportArgs = GenerateHomeOfferingsReportDto;

const homesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getHomesList: build.query<
      Paged<HomeState>,
      RtkqRequest<{
        communityId?: string | string[];
        projectId?: string | string[];
        filterForAddingAvailability?: boolean;
        paging: Paging;
        filters?: HomeFilterParams;
        sorting?: SortResult;
      }>
    >({
      queryFn: async (args) => {
        const {
          communityId,
          projectId,
          paging,
          filters,
          sorting,
          filterForAddingAvailability,
        } = args;
        return rtkq(args).exec(() =>
          homesHttp.getAll(
            paging,
            communityId,
            projectId,
            filterForAddingAvailability,
            filters,
            sorting,
          ),
        );
      },
      providesTags: (result, _, args) => [
        { type: 'home', id: 'list' },
        ...(result?.items || []).map(({ id }) => ({
          type: 'home' as const,
          id,
        })),
        ...(result?.items || []).map(({ projectId }) => ({
          type: 'project' as const,
          id: projectId ?? 'never',
        })),
        ...(result?.items || []).map(({ menu }) => ({
          type: 'itemMenu' as const,
          id: menu?.id ?? 'never',
        })),
        { type: 'project', id: args.projectId?.at(0) ?? 'never' },
        ...(result?.items || [])
          .map(({ residents = [] }) =>
            residents.map((r) => ({ type: 'resident' as const, id: r.id })),
          )
          .flat(),
        { type: 'project', id: 'project_homes' },
      ],
    }),

    getHomeDetails: build.query<HomeDetailsState, RtkqRequest<{ id: string }>>({
      queryFn: async (args) => {
        const { id } = args;
        return rtkq(args).exec(async () => homesHttp.get(id));
      },
      providesTags: (home) => [
        { type: 'home', id: home?.id ?? 'none' },
        { type: 'project', id: home?.projectId ?? 'none' },
        { type: 'itemMenu', id: home?.menu?.id ?? 'none' },
        ...(home?.residents?.map((r) => ({
          type: 'resident' as const,
          id: r.id,
        })) ?? []),
      ],
    }),

    updateHome: build.mutation<void, RtkqRequest<UpdateHomeArgs>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => homesHttp.update(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'home', id: 'list' },
        { type: 'home', id: args.id },
        { type: 'project', id: args.projectId ?? 'never' },
        { type: 'task' },
        { type: 'floorplan', id: 'list' },
      ],
    }),

    addHome: build.mutation<string, RtkqRequest<AddHomeArgs>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => homesHttp.create(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'home', id: 'list' },
        { type: 'project', id: args.projectId ?? 'never' },
        { type: 'community', id: args.communityId },
        { type: 'task' },
        { type: 'floorplan', id: 'list' },
      ],
    }),

    assignFloorplanToHome: build.mutation<
      void,
      RtkqRequest<{ homeId: string; floorplanId: string }>
    >({
      queryFn: (args) => {
        const { homeId, floorplanId } = args;
        return rtkq(args).exec(() =>
          homesHttp.assignFloorplan(homeId, floorplanId),
        );
      },
      invalidatesTags: (_, __, args) => [
        { type: 'home', id: 'list' },
        { type: 'home', id: args.homeId },
        { type: 'personalization-item-room', id: 'list' },
        { type: 'develop-scope-room', id: 'list' },
        { type: 'projectHomeInfo', id: args.homeId },
      ],
    }),

    assignMenuToHomes: build.mutation<
      void,
      RtkqRequest<{
        homeIds: string[];
        menuId: string;
        projectId: string;
      }>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() => homesHttp.assignMenuToHomes(args));
      },
      invalidatesTags: (_, __, args) => [
        ...args.homeIds.map((id) => ({ type: 'home' as const, id })),
        ...args.homeIds.map((id) => ({ type: 'projectHomeInfo' as const, id })),
      ],
    }),

    uploadHomes: build.mutation<string, RtkqRequest<{ excelRef: string }>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => homesHttp.upload(args));
      },
      invalidatesTags: () => [{ type: 'home', id: 'list' }],
    }),

    generateOfferingsReport: build.mutation<
      string,
      RtkqRequest<GenerateHomeOfferingsReportArgs>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(async () =>
          homesHttp.generateOfferingsReport(args),
        );
      },
    }),
  }),
});

export const {
  useGetHomesListQuery,
  useLazyGetHomesListQuery,
  useAddHomeMutation,
  useGetHomeDetailsQuery,
  useLazyGetHomeDetailsQuery,
  useUpdateHomeMutation,
  useAssignFloorplanToHomeMutation,
  useUploadHomesMutation,
  useAssignMenuToHomesMutation,
  useGenerateOfferingsReportMutation,
} = homesApi;
