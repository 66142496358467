import {
  AddHomeArgs,
  useGetFloorplanDetailsByHomeQuery,
  useGetHomeDetailsQuery,
  useGetProjectContextQuery,
  useUpdateHomeMutation,
} from '@/redux';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HomeDetailsModal } from '@/views';
import { LookupOption, notify } from '@/components';
import { isNotStartedProjectStatus } from '@/core';

const useSubmit = (closeModal: () => any, id: string) => {
  const [updateHome] = useUpdateHomeMutation();

  return async (values: AddHomeArgs) => {
    await updateHome({ ...values, id })
      .unwrap()
      .then(() => {
        notify.success.t('homes.updated');
        closeModal();
      });
  };
};

export function useEditHomeAction() {
  const { t } = useTranslation();
  const [id, setId] = useState<string>();
  const { data } = useGetHomeDetailsQuery({ id: id! }, { skip: !id });
  const { data: floorplan } = useGetFloorplanDetailsByHomeQuery(
    { homeId: id! },
    { skip: !id },
  );
  const { data: project } = useGetProjectContextQuery(
    { id: data?.projectId! },
    { skip: !data?.projectId },
  );

  const enableReassign = !project || isNotStartedProjectStatus(project.status);

  const submit = useSubmit(() => setId(undefined), id!);

  const preloadValue: LookupOption | undefined = useMemo(
    () =>
      data?.projectId
        ? {
            label: data.projectName,
            value: data.projectId,
          }
        : undefined,
    [data?.projectId, data?.projectName],
  );

  return {
    openEdit: (id: string) => setId(id),
    editModal: (
      <>
        {id && data && (
          <HomeDetailsModal
            title={t('homes.details.editTitle')}
            buttonSaveName={t('homes.details.editButton')}
            onSubmit={submit}
            value={data}
            preloadInitialProjectValue={preloadValue}
            onClose={() => setId(undefined)}
            floorplan={floorplan}
            showSaveAndAddButton={false}
            enableReassign={enableReassign}
          />
        )}
      </>
    ),
  };
}
