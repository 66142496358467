import { AddButton, confirmation, notify } from '@/components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomLegalLanguageDetailsModal } from './CustomLegalLanguageDetailsModal';
import {
  AddCustomLegalLanguageState,
  useAddCustomLegalLanguageMutation,
} from '@/redux/customLegalLanguage';

export interface Props {
  clientId?: string;
  communityId?: string;
}

const useSubmit = (closeModal: () => any) => {
  const [addLegalLanguage] = useAddCustomLegalLanguageMutation();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'customLegalLanguage.confirmation.edit',
  });

  return async (values: AddCustomLegalLanguageState) => {
    confirmation.show({
      body: t('body'),
      onConfirm: async () =>
        await addLegalLanguage(values)
          .unwrap()
          .then(() => {
            notify.success.t('customLegalLanguage.details.added');
            closeModal();
          }),
      onCancel: closeModal(),
    });
  };
};

export function AddCustomLegalLanguageButton(props: Props) {
  const { t } = useTranslation();
  const [display, setDisplay] = useState(false);
  const submit = useSubmit(() => setDisplay(false));

  return (
    <>
      <AddButton onClick={() => setDisplay(true)}>
        {t('customLegalLanguage.details.addButton')}
      </AddButton>
      {display && (
        <CustomLegalLanguageDetailsModal
          onSubmit={submit}
          onClose={() => setDisplay(false)}
        />
      )}
    </>
  );
}
