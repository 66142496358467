import { confirmation, notify } from '@/components';
import {
  ProjectState,
  useUpdateDeadlinesMutation,
  ProjectStepLookupState,
} from '@/redux';
import { useState } from 'react';
import { useProjectContext } from '@/views';
import { FormProjectDeadlines } from './FormProjectDeadlines';
import { ViewProjectDeadlines } from './ViewProjectDeadlines';
import { isStepsOrderValid } from './isStepsOrderValid';
import { useTranslation } from 'react-i18next';
import { UpdateProjectDeadlineDto } from '@/core';

interface Props {
  data: ProjectState;
  steps: ProjectStepLookupState[];
}

export const ProjectDeadlines = (props: Props) => {
  const { data, steps } = props;
  const [isEdit, setEdit] = useState(false);
  const [update] = useUpdateDeadlinesMutation();
  const { isActive } = useProjectContext();
  const { t } = useTranslation();
  const handleSave = (result: UpdateProjectDeadlineDto) => {
    update(result)
      .unwrap()
      .then(() => {
        notify.success.t('projects.saved');
        setEdit(false);
      });
  };

  const handleSubmit = ({ steps }: { steps: ProjectStepLookupState[] }) => {
    const result = {
      id: data.id,
      items: steps.map((step) => ({
        stepId: step.id,
        date: step.deadlineDate,
      })),
    };

    const { isValid, firstStepName, secondStepName } = isStepsOrderValid(
      steps.map((x) => ({ id: x.id, name: x.name, categories: [] })),
      steps,
    );

    if (!isValid) {
      confirmation.show({
        title: t('pathTemplates.details.title'),
        body: t('pathTemplates.details.body', {
          firstStepName,
          secondStepName,
        }),
        onConfirm: () => handleSave(result),
      });
    } else {
      handleSave(result);
    }
  };

  return (
    <>
      {isEdit ? (
        <FormProjectDeadlines
          steps={steps}
          onSubmit={handleSubmit}
          onCancel={() => setEdit(false)}
        />
      ) : (
        <ViewProjectDeadlines
          steps={steps}
          onEdit={() => setEdit(true)}
          isActiveProject={isActive}
        />
      )}
    </>
  );
};
