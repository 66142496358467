import {
  DefaultNumberSorting,
  Table,
  TabPanel,
  useDefaultTablePaging,
  useFilters,
  useSorting,
} from '@/components';
import { useGetUsersQuery, UserState } from '@/redux';
import { useUsersColumns } from './useUsersColumns';
import {
  useEditUserActionBase,
  UseEditUserActionBaseArgs,
} from './useEditUsersAction';
import { BFP_ORGANIZATION_ID } from '@/platform';
import { AddBFPUserButton } from './AddUserButton';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { UserFilterParams } from '@/core';

const EDIT_ACTION_ARGS: UseEditUserActionBaseArgs = {
  noRoleSelect: true,
  noClientSelect: true,
  roles: ['BFP_ADMIN'],
};

export function BFPUserListTabPanel() {
  const { t } = useTranslation();
  const { editModal, openEdit } = useEditUserActionBase(EDIT_ACTION_ARGS);

  const { setTotal, paging, params } = useDefaultTablePaging();
  const {
    filters,
    onFiltersChange,
    resetFilters,
    stringFilterOptions,
    setStringFilterOptions,
  } = useFilters<UserFilterParams>(['number', 'name', 'email']);
  const { sorting, onSortingChange } =
    useSorting<UserState>(DefaultNumberSorting);

  const { data, isLoading } = useGetUsersQuery({
    organizationId: BFP_ORGANIZATION_ID,
    paging: params,
    filters,
    sorting,
  });

  const users = useMemo(() => {
    if (data) {
      setTotal(data.total);
      setStringFilterOptions(data.stringFilterOptions);
    }
    return data?.items;
  }, [data, setStringFilterOptions, setTotal]);

  const columns = useUsersColumns(users, openEdit, stringFilterOptions);

  return (
    <TabPanel min="auto">
      <TabPanel.Title>{t('users.bfpTitle')}</TabPanel.Title>
      <TabPanel.Actions>
        <AddBFPUserButton />
      </TabPanel.Actions>
      <TabPanel.Body>
        <Table<UserState>
          name="Users"
          loading={isLoading}
          columns={columns}
          dataSource={users}
          rowKey={(row) => row.id}
          pagination={paging}
          onChange={(_, filters, sorting) => {
            onFiltersChange(filters);
            onSortingChange(sorting);
          }}
          onFiltersReset={resetFilters}
        />
        {editModal}
      </TabPanel.Body>
    </TabPanel>
  );
}
