import { rtkq, RtkqRequest } from '../rtkq';
import { EditRoomArgs, roomsHttp, RoomState } from '@/core';
import { api } from '../api';

const roomsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRoomsList: build.query<
      RoomState[],
      RtkqRequest<{
        floorplanId?: string;
        homeId?: string;
        communityId?: string;
      }>
    >({
      queryFn: async (args) => {
        return await rtkq(args).exec(() => roomsHttp.getAll(args));
      },
      providesTags: (result = []) => [
        { type: 'room', id: 'list' },
        ...result.map(({ id }) => ({ type: 'room' as const, id })),
      ],
    }),

    updateRoom: build.mutation<void, RtkqRequest<EditRoomArgs>>({
      queryFn: async (args) => {
        return await rtkq(args).exec(() => roomsHttp.update(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'room', id: args.id },
        { type: 'personalization-item-room', id: args.id },
        { type: 'develop-scope-room', id: args.id },
        { type: 'personalizationItem-category', id: 'list' },
        { type: 'personalizationItem', id: 'list' },
        { type: 'personalization-item-price' },
      ],
    }),

    createRoom: build.mutation<string, RtkqRequest<EditRoomArgs>>({
      queryFn: async (args) => {
        return await rtkq(args).exec(() => roomsHttp.create(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'room', id: 'list' },
        { type: 'personalization-item-room', id: 'list' },
        { type: 'personalization-item-price', id: 'list' },
        { type: 'personalizationItem-category', id: 'list' },
        ...args.categories.map((roomCategory) => ({ type: 'category' as const, id: roomCategory.id})),
        { type: 'personalizationItem', id: 'list' },
        { type: 'develop-scope-room', id: 'list' }
      ],
    }),

    unlistRoom: build.mutation<void, RtkqRequest<{ id: string }>>({
      queryFn: async (args) => {
        return await rtkq(args).exec(() => roomsHttp.unlist(args.id));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'room', id: args.id },
        { type: 'personalization-item-room', id: args.id },
        { type: 'develop-scope-room', id: args.id },
        { type: 'personalizationItem-category', id: 'list' },
        { type: 'personalizationItem', id: 'list' },
      ],
    }),

    getRoomDetails: build.query<RoomState, RtkqRequest<{ id: string }>>({
      queryFn: async (args) => {
        const { id } = args;
        return await rtkq(args).exec(() => roomsHttp.getDetails(id));
      },
      providesTags: (room) => {
        if (!room) {
          return [{ type: 'room' as const, id: 'none' }];
        }

        return [{ type: 'room' as const, id: room.id }];
      },
    }),
  }),
});

export const {
  useGetRoomsListQuery,
  useUpdateRoomMutation,
  useCreateRoomMutation,
  useUnlistRoomMutation,
  useGetRoomDetailsQuery,
} = roomsApi;
