import { NavLink } from '@/components';
import { useGetTaskDetailsQuery } from '@/redux';
import { Button, Col, Row, Space } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from './TaskNavigationPanel.module.scss';

interface Props {
  id: string;
  projectId: string;
  className?: string;
}

export function TaskNavigationPanel(props: Props) {
  const { id, projectId, className } = props;
  const { t } = useTranslation();
  const { data } = useGetTaskDetailsQuery({ id });
  const push = useNavigate();

  if (!data) {
    return null;
  }

  return (
    <Row justify="space-between" className={className} align="middle">
      <Col span={6}>
        <Button
          type="link"
          onClick={() => push(`/projects/${projectId}/task-management`)}
          className={styles.backButton}
        >
          <>
            <LeftOutlined className="me-1" />
            Back to all Tasks
          </>
        </Button>
      </Col>
      <Col>
        <Space size="large" align="center">
          <NavLink
            to={`/projects/${data.projectId}/task-management/${data.previousTaskId}`}
            disabled={!data.previousTaskId}
            disabledClassName="text-muted"
          >
            <>
              <LeftOutlined className="me-3" />
              {t('tasks.details.previousTask')}
            </>
          </NavLink>
          <NavLink
            to={`/projects/${data.projectId}/task-management/${data.nextTaskId}`}
            disabled={!data.nextTaskId}
            disabledClassName="text-muted"
          >
            <>
              {t('tasks.details.nextTask')}
              <RightOutlined className="ms-3" />
            </>
          </NavLink>
        </Space>
      </Col>
    </Row>
  );
}
