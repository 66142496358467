import axios from 'axios';

class SettingsHttp {
  public async get() {
    const response = await axios.get<AppSettings>('/api/settings');
    return response.data;
  }
}

export const settingsHttp = new SettingsHttp();
