import { Role, formatters, BFP_ROLES, CLIENT_ROLES, ADMIN_ROLES } from '@/core';
import { useSelector } from 'react-redux';
import { usersApi } from './usersApi';

function createUserContextSelectors(state: any) {
  const context = usersApi.endpoints.getContext.select()(state).data!;
  const isClient = CLIENT_ROLES.includes(context.role);
  const isAdmin = ADMIN_ROLES.includes(context.role);
  const isBFP = BFP_ROLES.includes(context.role);

  const selectors = Object.assign({}, context, {
    hasRole: (role: Role) => context.role === role,

    get isClient() {
      return isClient;
    },

    get isAdmin() {
      return isAdmin;
    },

    get isBFP() {
      return isBFP;
    },

    get name() {
      return formatters.name(context);
    },

    get clientId() {
      return isClient ? context.organizationId : undefined;
    },

    get hasReportsAccess() {
      return context.hasReportsAccess;
    },
  });

  return selectors;
}

export function useUserContextSelector<TResult>(
  select: (selectors: ReturnType<typeof createUserContextSelectors>) => TResult,
) {
  return useSelector((state) => {
    return select(createUserContextSelectors(state));
  });
}
