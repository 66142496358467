import {
  DeleteIconButton,
  EditIconButton,
  TableColumnType,
  useDefaultTablePaging,
  useFilters,
  useSorting,
} from '@/components';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ProjectState,
  useGetProjectsListQuery,
  useUnlistProjectMutation,
  useUserContextSelector,
} from '@/redux';
import {
  confirmation,
  DateTimeValue,
  EnumValue,
  notify,
  Table,
  YesNo,
} from '@/components';
import { NavLink } from 'react-router-dom';
import {
  isActiveProjectStatus,
  ProjectFilterParams,
  ProjectStatus,
  ProjectType,
  StringFilterOptions,
} from '@/core';
import { useEditProjectModal } from './useEditProjectModal';
import { ProjectDetailsFormVisibilityProps } from './ProjectDetailsForm';
import { Space } from 'antd';
import { sort, useFiltersFactory } from '@/utils';
import { CommunitiesAccessAlert } from '../Communities.List';

interface Props extends ProjectDetailsFormVisibilityProps {
  communityId?: string;
  clientId?: string;
  noCreatedAt?: boolean;
  readonly?: boolean;
}

function useColumns(
  props: Props,
  data: ProjectState[] | undefined,
  showEdit: (id: string) => any,
  textFilterOptions?: StringFilterOptions,
) {
  const { noCreatedAt, noCommunity, readonly } = props;
  const { t } = useTranslation();
  const [unlist] = useUnlistProjectMutation();
  const filters = useFiltersFactory(data, textFilterOptions);
  const isBFP = useUserContextSelector((x) => x.isBFP);

  const handleUnlist = useCallback(
    (id: string) => {
      const perform = () =>
        unlist({ id })
          .unwrap()
          .then(() => notify.success.t('projects.deleted'));
      confirmation.show({
        title: t('confirmation.delete.title'),
        body: t('projects.deleteConfirmation'),
        onConfirm: perform,
      });
    },
    [t, unlist],
  );

  return useMemo<TableColumnType<ProjectState>[]>(() => {
    const cols: Array<TableColumnType<ProjectState> | false> = [
      {
        title: t('projects.number'),
        key: 'number',
        sorter: (a, b) => a.number.localeCompare(b.number),
        render: (_, item) => item.number,
        ...filters.select((x) => x.number, 'number'),
        width: isBFP ? '7%' : '10%',
      },
      {
        title: t('projects.name'),
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        ...filters.select((x) => x.name, 'name'),
        render: (_, { id, name }) => (
          <NavLink to={`/projects/${id}/${'administration'}`}>{name}</NavLink>
        ),
        width: isBFP ? '10%' : '15%',
      },
      noCreatedAt !== true && {
        title: t('projects.created'),
        key: 'created',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
        ...filters.dateTimeRange((x) => x.createdAt),
        render: (_, { createdAt }) => (
          <DateTimeValue value={createdAt} format="date" />
        ),
        width: '10%',
      },
      isBFP &&
        !!!noCommunity && {
          title: t('projects.client'),
          key: 'client',
          sorter: (a, b) =>
            (a.clientName || '').localeCompare(b.clientName || ''),
          ...filters.select((x) => x.clientName, 'client'),
          render: (_, item) => item.clientName,
        },
      noCommunity !== true && {
        title: t('projects.community'),
        key: 'community',
        sorter: (a, b) => a.communityName.localeCompare(b.communityName),
        ...filters.select((x) => x.communityName, 'community'),
        render: (_, item) => (
          <NavLink to={`/communities/${item.communityId}`}>
            {item.communityName}
          </NavLink>
        ),
      },
      {
        title: t('projects.homes'),
        key: 'homeCount',
        sorter: (a, b) => a.homeIds.length - b.homeIds.length,
        ...filters.numberRange((x) => x.homeIds.length),
        render: (_, item) => item.homeIds.length,
        width: '10%',
      },
      {
        title: t('projects.type'),
        key: 'type',
        sorter: (a, b) => a.type.localeCompare(b.type),
        ...filters.enumSelect((x) => x.type, ProjectType),
        render: (_, { type }) => <EnumValue type={ProjectType} value={type} />,
      },
      {
        title: t('projects.status'),
        key: 'status',
        sorter: (a, b) => a.status.localeCompare(b.status),
        ...filters.enumSelect((x) => x.status, ProjectStatus),
        render: (_, { status }) => (
          <EnumValue type={ProjectStatus} value={status} />
        ),
      },
      {
        title: t('projects.pmLite'),
        key: 'pmLite',
        sorter: (a, b) => +a.pmLiteEnabled - +b.pmLiteEnabled,
        ...filters.yesNo((x) => x.pmLiteEnabled),
        render: (_, { pmLiteEnabled }) => <YesNo>{pmLiteEnabled}</YesNo>,
        width: isBFP ? '10%' : '15%',
      },
      {
        title: t('projects.path.tableTitle'),
        key: 'path',
        sorter: (a, b) => sort(a.pathName, b.pathName),
        ...filters.select((x) => x.pathName, 'path'),
        render: (_, item) => (
          <NavLink to={`/projects/${item.id}/path`}>{item.pathName}</NavLink>
        ),
      },
      {
        title: t('projects.actions'),
        key: 'actions',
        render: (_, { id, status, hasManagePermissions: canManage }) =>
          canManage &&
          !readonly &&
          isActiveProjectStatus(status) && (
            <Space size="large">
              <EditIconButton
                type="link"
                onClick={() => showEdit(id)}
                className="table-action"
              />
              {status === 'NotStarted' && (
                <DeleteIconButton
                  onClick={() => handleUnlist(id)}
                  className="table-action"
                />
              )}
            </Space>
          ),
      },
    ];

    return cols.filter((x) => !!x) as TableColumnType<ProjectState>[];
  }, [
    t,
    filters,
    noCreatedAt,
    isBFP,
    noCommunity,
    readonly,
    showEdit,
    handleUnlist,
  ]);
}

export function ProjectsListPanel(props: Props) {
  const { communityId, clientId } = props;

  const { setTotal, paging, params } = useDefaultTablePaging();
  const {
    filters,
    onFiltersChange,
    resetFilters,
    stringFilterOptions,
    setStringFilterOptions,
  } = useFilters<ProjectFilterParams>(
    ['number', 'name', 'client', 'community', 'path'],
    undefined,
    ['created', 'homeCount'],
  );
  const { sorting, onSortingChange } = useSorting<ProjectState>({
    sortBy: 'created',
    sortOrder: 'descend',
  });

  const { data, isFetching } = useGetProjectsListQuery({
    communityId,
    clientId,
    paging: params,
    filters,
    sorting,
  });

  const projects = useMemo(() => {
    if (data) {
      setTotal(data.total);
      setStringFilterOptions(data.stringFilterOptions);
    }
    return data?.items;
  }, [data, setStringFilterOptions, setTotal]);

  const { editProjectModal, showEdit } = useEditProjectModal();
  const columns = useColumns(props, projects, showEdit, stringFilterOptions);

  const tableName = useMemo(
    () => (communityId ? 'CommunityProjects' : 'Projects'),
    [communityId],
  );

  return (
    <div>
      <CommunitiesAccessAlert />
      <Table<ProjectState>
        scroll={{ x: 1500 }}
        name={tableName}
        loading={isFetching}
        columns={columns}
        dataSource={projects}
        rowKey={(row) => row.id}
        pagination={paging}
        onChange={(_, filters, sorting) => {
          onFiltersChange(filters);
          onSortingChange(sorting);
        }}
        onFiltersReset={resetFilters}
      />
      {editProjectModal}
    </div>
  );
}
