import { AsyncSelect, TabPanel } from '@/components';
import { PricingListPanel } from './PricingListPanel';
import { useEffect, useState } from 'react';
import { useItemMenuLookupSource } from '@/views';
import { t } from 'i18next';
import { usePricingSessionValues } from './usePricingSessionValues';

interface Props {
  communityId: string;
  title: React.ReactElement;
}
export function PricingListTabPanel(props: Props) {
  const { communityId, title } = props;
  const {
    pricingCommunityId,
    pricingMenuId,
    savePricingMenuId,
    savePricingCommunityId,
  } = usePricingSessionValues();
  const [selectedMenuId, setSelectedMenuId] = useState<string | undefined>(
    pricingMenuId,
  );
  const dataSource = useItemMenuLookupSource(communityId);

  useEffect(() => {
    if (pricingCommunityId !== communityId) {
      savePricingCommunityId(communityId);
      setSelectedMenuId(undefined);
    }
  }, [communityId, pricingCommunityId, savePricingCommunityId]);

  return (
    <TabPanel>
      <TabPanel.Title>{title}</TabPanel.Title>
      <TabPanel.Body>
        <AsyncSelect
          style={{ width: '25%' }}
          value={selectedMenuId}
          onChange={(id) => {
            setSelectedMenuId(id);
            savePricingMenuId(id);
          }}
          dataSource={dataSource}
          preload
          placeholder={t('pricing.menu')}
        />
        {selectedMenuId && (
          <PricingListPanel
            className="mt-4"
            communityId={communityId}
            menuId={selectedMenuId}
          />
        )}
      </TabPanel.Body>
    </TabPanel>
  );
}
