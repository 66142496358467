import { MAX_MONEY_VALUE } from '@/utils';
import { Form } from '@/components';

interface CostEstimateInputProps {
  name: string;
}

export function CostEstimateInput(props: CostEstimateInputProps) {
  const { name } = props;

  return (
    <Form.Number
      min={0}
      max={MAX_MONEY_VALUE}
      prefix="$"
      name={`${name}.estimatedCost`}
      label={false}
    />
  )
}