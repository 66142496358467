import { rtkq, RtkqRequest } from '../rtkq';
import {
  AddSuboptionBookletLinkDto,
  AddSubOptionDto,
  AddSuboptionExternalLinkDto,
  DeleteSuboptionBookletLinkDto,
  DeleteSuboptionExternalLinkDto,
  SubOptionDto,
  subOptionsHttp,
  UpdateSubOptionDto,
} from '@/core';
import { api } from '../api';

export type SubOptionState = SubOptionDto;
export type AddSubOptionArgs = AddSubOptionDto;
export type UpdateSubOptionArgs = UpdateSubOptionDto;

export const subOptionsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSubOptionsList: build.query<
      SubOptionState[],
      RtkqRequest<{ itemId?: string; includedUnlistedSuboptions?: string[] }>
    >({
      queryFn: async (args) =>
        await rtkq(args).exec(() =>
          subOptionsHttp.getAll(args.itemId, args.includedUnlistedSuboptions),
        ),
      providesTags: (result = []) => [
        { type: 'subOption', id: 'list' },
        ...result.map(({ id }) => ({ type: 'subOption' as const, id })),
        ...result.flatMap((item) =>
          item.bookletLinks.map(({ attachmentId }) => ({
            type: 'booklet-link-attachment-id' as const,
            id: attachmentId,
          })),
        ),
      ],
    }),

    getSubOptionsByIdsList: build.query<
      SubOptionState[],
      RtkqRequest<{ ids?: string[] }>
    >({
      queryFn: async (args) =>
        await rtkq(args).exec(() => subOptionsHttp.getAllByIds(args.ids)),
      providesTags: (result = []) => [
        { type: 'subOption', id: 'listById' },
        ...result.map(({ id }) => ({ type: 'subOption' as const, id })),
        ...result.flatMap((item) =>
          item.bookletLinks.map(({ attachmentId }) => ({
            type: 'booklet-link-attachment-id' as const,
            id: attachmentId,
          })),
        ),
      ],
    }),

    addSubOption: build.mutation<string, RtkqRequest<AddSubOptionArgs>>({
      queryFn: async (args) =>
        await rtkq(args).exec(() => subOptionsHttp.add(args)),
      invalidatesTags: () => [
        { type: 'subOption', id: 'list' },
        { type: 'subOption', id: 'listById' },
      ],
    }),

    updateSubOption: build.mutation<void, RtkqRequest<UpdateSubOptionArgs>>({
      queryFn: async (args) =>
        await rtkq(args).exec(() => subOptionsHttp.update(args)),
      invalidatesTags: (_, __, args) => [
        { type: 'subOption', id: 'list' },
        { type: 'subOption', id: args.id },
      ],
    }),

    deleteSubOption: build.mutation<void, RtkqRequest<{ id: string }>>({
      queryFn: async (args) =>
        await rtkq(args).exec(() => subOptionsHttp.unlist(args.id)),
      invalidatesTags: (_, __, args) => [
        { type: 'subOption', id: 'list' },
        { type: 'subOption', id: args.id },
      ],
    }),

    addSuboptionBookletLink: build.mutation<
      void,
      RtkqRequest<AddSuboptionBookletLinkDto>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() => subOptionsHttp.addBookletLink(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'subOption', id: args.suboptionId },
      ],
    }),

    deleteSuboptionBookletLink: build.mutation<
      void,
      RtkqRequest<DeleteSuboptionBookletLinkDto>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() => subOptionsHttp.deleteBookletLink(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'subOption', id: args.suboptionId },
      ],
    }),

    addSuboptionExternalLink: build.mutation<
      void,
      RtkqRequest<AddSuboptionExternalLinkDto>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() => subOptionsHttp.addExternalLink(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'subOption', id: args.suboptionId },
      ],
    }),

    deleteSuboptionExternalLink: build.mutation<
      void,
      RtkqRequest<DeleteSuboptionExternalLinkDto>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() => subOptionsHttp.deleteExternalLink(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'subOption', id: args.suboptionId },
      ],
    }),
  }),
});

export const {
  useGetSubOptionsListQuery,
  useGetSubOptionsByIdsListQuery,
  useAddSubOptionMutation,
  useUpdateSubOptionMutation,
  useDeleteSubOptionMutation,
  useAddSuboptionBookletLinkMutation,
  useDeleteSuboptionBookletLinkMutation,
  useAddSuboptionExternalLinkMutation,
  useDeleteSuboptionExternalLinkMutation,
} = subOptionsApi;
