import {
  AddDevelopScopeItemDto,
  DevelopScopeItemDto,
  DownloadedFile,
  Http,
  asFileResponse,
  UpdateDevelopScopeItemDto,
  RoomState,
  HomeRoomFinalizationDetailsDto,
} from '@/core';
import { guard } from '@/utils';
import qs from 'qs';

class DevelopScopeHttp extends Http {
  public async getAll(
    projectId: string,
    homeId: string,
    roomId: string | null,
    onlyWholeHome?: boolean,
    onlyFloorplan?: boolean,
  ) {
    const query = qs.stringify({
      projectId,
      homeId,
      roomId,
      onlyWholeHome,
      onlyFloorplan,
    });
    const response = await this._axios.get<DevelopScopeItemDto[]>(
      `/api/project/develop-scope-item?${query}`,
    );
    return response.data;
  }

  public async create(request: AddDevelopScopeItemDto) {
    guard.notNull(request, 'request');
    const response = await this._axios.post<string>(
      `/api/project/develop-scope-item`,
      request,
    );
    return response.data;
  }

  public async get(id: string) {
    guard.notNull(id, 'id');
    const response = await this._axios.get<DevelopScopeItemDto>(
      `/api/project/develop-scope-item/${id}`,
    );
    return response.data;
  }

  public async update(request: UpdateDevelopScopeItemDto) {
    guard.notNull(request, 'request');
    await this._axios.put(`/api/project/develop-scope-item`, request);
  }

  public async delete(id: string) {
    guard.notNull(id, 'id');
    await this._axios.delete(`/api/project/develop-scope-item/${id}`);
  }

  public async getNotFinalizedRoomIdsAll(projectId: string, homeId: string) {
    const query = qs.stringify({ projectId, homeId });
    const response = await this._axios.get<HomeRoomFinalizationDetailsDto[]>(
      `/api/project/develop-scope-item/finalization-summary?${query}`,
    );
    return response.data;
  }

  public async downloadPdf(
    projectId: string,
    homeId: string,
  ): Promise<DownloadedFile> {
    const query = qs.stringify({ projectId, homeId });
    const response = await this._axios.get(
      `/api/project/develop-scope-item/pdf?${query}`,
      { responseType: 'blob' },
    );
    return asFileResponse(response);
  }

  public async getDevelopScopeRooms(args: {
    projectId: string;
    homeId: string;
  }) {
    const query = qs.stringify({ ...args });
    const url = `/api/project/develop-scope-item/room?${query}`;
    const response = await this._axios.get<RoomState[]>(url);
    return response.data;
  }
}

export const developScopeHttp = new DevelopScopeHttp();
