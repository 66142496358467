import { useTranslation } from 'react-i18next';
import {
  CommunityDetailsTabKeys,
  Assert,
  SubcontractorDetailsPanel,
  SubcontractorItemsDetailsPanel,
} from '@/views';
import { Page } from '@/components';
import { useGetSubcontractorDetailsQuery } from '@/redux';
import { useParams } from 'react-router-dom';
import { SettingOutlined } from '@ant-design/icons';
import { CommunityPermissions } from '@/core';

const useId = () => {
  const { id } = useParams<'id'>();
  return id!;
};

function _SubcontractorDetailsPage() {
  const { t } = useTranslation();
  const id = useId();
  const { data } = useGetSubcontractorDetailsQuery({ id });
  const title = data?.name ?? t('fetchFallbackTitle');

  return (
    <Page htmlTitle={title}>
      <Page.Paths>
        <Page.Path href="/communities">{t('communities.title')}</Page.Path>
        <Page.Path
          href={`/communities/${data?.communityId}#${CommunityDetailsTabKeys.subcontractors}`}
        >
          {data?.communityName ?? t('fetchFallbackTitle')}
        </Page.Path>
        <Page.Path
          href={`/communities/${data?.communityId}#${CommunityDetailsTabKeys.subcontractors}`}
        >
          {t('subcontractors.title')}
        </Page.Path>
        <Page.Path>{title}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card
          collapsible
          defaultCollapsed
          title={title}
          icon={<SettingOutlined />}
          color="#f57c00"
        >
          <SubcontractorDetailsPanel id={id} />
        </Page.Card>

        <Page.Card title={t('subcontractors.details.itemsTitle')}>
          {data && <SubcontractorItemsDetailsPanel data={data} />}
        </Page.Card>
      </Page.Body>
    </Page>
  );
}

export const SubcontractorDetailsPage = Assert.Banner(
  CommunityPermissions.Subcontractors.View,
)(_SubcontractorDetailsPage);
