import { Page } from '@/components';

export function Error404Page() {
  return (
    <Page htmlTitle="#404">
      <Page.Paths>
        <Page.Path>#404</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card title="#404">
          <div className="text-center">
            <h3>Oh no! This page can't be found.</h3>
            <p>
              We're sorry but this page is either missing or the web address
              isn't quite right.
            </p>
          </div>
        </Page.Card>
      </Page.Body>
    </Page>
  );
}
