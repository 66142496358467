import {
  AddSpecialRequestCommentDto,
  AddSpecialRequestDto,
  eventBus,
  SpecialRequestCommentDto,
  SpecialRequestDto,
  specialRequestsHttp,
  UpdateSpecialRequestCommentDto,
  UpdateSpecialRequestDto,
} from '@/core';
import { isEmpty } from 'lodash';
import { api } from '../api';
import { rtkq, RtkqRequest } from '../rtkq';

export type AddSpecialRequestArgs = AddSpecialRequestDto;
export type SpecialRequestState = SpecialRequestDto;
export type UpdateSpecialRequestArgs = UpdateSpecialRequestDto;
export type AddSpecialRequestCommentArgs = AddSpecialRequestCommentDto;
export type UpdateSpecialRequestCommentArgs = UpdateSpecialRequestCommentDto;
export type SpecialRequestCommentState = SpecialRequestCommentDto;

export const specialRequestsApi = api.injectEndpoints({
  endpoints: (build) => ({
    addSpecialRequest: build.mutation<
      string,
      RtkqRequest<AddSpecialRequestArgs>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() =>
          specialRequestsHttp.addSpecialRequest(args),
        );
      },
      invalidatesTags: (_, __, args) => [
        { type: 'special-request', id: 'list' },
        { type: 'special-request', id: 'project-' + args.projectId },
        { type: 'personalizationStep-finalized', id: args.stepId },
      ],
    }),

    getSpecialRequests: build.query<
      SpecialRequestState[],
      RtkqRequest<{
        homeId: string;
        projectId: string;
        isFloorplan?: boolean;
        stepId?: string | string[];
        roomId?: string;
        wholeHome?: boolean;
        categoryId?: string;
        asItem?: boolean;
      }>
    >({
      queryFn: async (args) => {
        return rtkq(args).exec(() =>
          specialRequestsHttp.getAllSpecialRequests(args),
        );
      },
      providesTags: (result = [], _, args) => [
        { type: 'special-request', id: 'list' },
        // { type: 'special-request', id: args.stepId },
        ...result.flatMap(({ id }) => [
          {
            type: 'special-request' as const,
            id,
          },
          {
            type: 'special-request-price' as const,
            id,
          },
        ]),
      ],
    }),

    deleteSpecialRequest: build.mutation<
      void,
      RtkqRequest<{ id: string; stepId: string }>
    >({
      queryFn: (args) => {
        const { id } = args;
        return rtkq(args).exec(() =>
          specialRequestsHttp.deleteSpecialRequests(id),
        );
      },
      invalidatesTags: (_, __, { id, stepId }) => {
        return [
          { type: 'special-request', id },
          { type: 'personalizationStep-finalized', id: stepId },
        ];
      },
    }),

    getSpecialRequestDetails: build.query<
      SpecialRequestDto,
      RtkqRequest<{ id: string }>
    >({
      queryFn: async (args) => {
        return rtkq(args).exec(() =>
          specialRequestsHttp.getSpecialRequestDetails(args.id),
        );
      },
      providesTags: (specialRequest) => [
        { type: 'special-request', id: 'list' },
        {
          type: 'special-request',
          id: specialRequest?.id,
        },
        {
          type: 'special-request-price' as const,
          id: specialRequest?.id,
        },
      ],
    }),

    updateSpecialRequest: build.mutation<
      void,
      RtkqRequest<UpdateSpecialRequestArgs>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() =>
          specialRequestsHttp.updateSpecialRequest(args),
        );
      },
      onQueryStarted: async ({ id, imageActions }, { queryFulfilled }) => {
        try {
          await queryFulfilled;

          if (!isEmpty(imageActions))
            eventBus.publish('special-request-images-updated', id);
        } catch {}
      },
      invalidatesTags: (_, __, { id, stepId }) => [
        { type: 'special-request', id: id },
        { type: 'personalizationStep-finalized', id: stepId },
      ],
    }),

    addSpecialRequestComment: build.mutation<
      void,
      RtkqRequest<AddSpecialRequestCommentArgs>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() =>
          specialRequestsHttp.addSpecialRequestComment(args),
        );
      },
      invalidatesTags: () => [{ type: 'special-request-comment', id: 'list' }],
    }),

    updateSpecialRequestComment: build.mutation<
      void,
      RtkqRequest<UpdateSpecialRequestCommentArgs>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() =>
          specialRequestsHttp.updateSpecialRequestComment(args),
        );
      },
      invalidatesTags: () => [{ type: 'special-request-comment', id: 'list' }],
    }),

    getAllSpecialRequestComments: build.query<
      SpecialRequestCommentState[],
      RtkqRequest<{
        specialRequestId: string;
      }>
    >({
      queryFn: async (args) => {
        return rtkq(args).exec(() =>
          specialRequestsHttp.getAllSpecialRequestComments(args),
        );
      },
      providesTags: (result = []) => [
        { type: 'special-request-comment', id: 'list' },
        ...result.map(({ id }) => ({
          type: 'special-request-comment' as const,
          id,
        })),
      ],
    }),

    deleteSpecialRequestComment: build.mutation<
      void,
      RtkqRequest<{ id: string }>
    >({
      queryFn: (args) => {
        const { id } = args;
        return rtkq(args).exec(() =>
          specialRequestsHttp.deleteSpecialRequestComment(id),
        );
      },
      invalidatesTags: (_, __, { id }) => {
        return [{ type: 'special-request-comment', id }];
      },
    }),
  }),
});

export const {
  useAddSpecialRequestMutation,
  useGetSpecialRequestsQuery,
  useDeleteSpecialRequestMutation,
  useUpdateSpecialRequestMutation,
  useAddSpecialRequestCommentMutation,
  useUpdateSpecialRequestCommentMutation,
  useGetAllSpecialRequestCommentsQuery,
  useDeleteSpecialRequestCommentMutation,

  useGetSpecialRequestDetailsQuery,
} = specialRequestsApi;
