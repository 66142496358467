import { Button, Dropdown, Menu, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useMemo } from 'react';
import { Icon } from '@/components';

interface Props {
  onBulkUpdate: () => any;
  onDuplicate: () => any;
  onBulkDelete: () => any;
  disabled?: boolean;
}

export function TasksListActions(props: Props) {
  const { onBulkUpdate, onDuplicate, onBulkDelete, disabled } = props;

  const { t } = useTranslation();

  const items: ItemType[] = useMemo(() => {
    return [
      {
        key: `tasks_bulkUpdate_action`,
        onClick: onBulkUpdate,
        label: t('tasks.bulkUpdate.action'),
        disabled: disabled,
      },
      {
        key: `tasks_duplicate_action`,
        onClick: onDuplicate,
        label: t('tasks.duplicate.action'),
        disabled: disabled,
      },
      {
        key: 'tasks_delete_action',
        onClick: onBulkDelete,
        label: t('tasks.bulkDelete.action'),
        disabled: disabled,
      },
    ];
  }, [onBulkUpdate, onDuplicate, onBulkDelete, disabled, t]);

  return (
    <Dropdown
      className="mb-3"
      trigger={['click']}
      overlay={<Menu items={items} />}
    >
      <Button>
        <Space>
          {t('actions')}
          <Icon type="arrow-down" />
        </Space>
      </Button>
    </Dropdown>
  );
}
