import {
  Table,
  notify,
  useDefaultTablePaging,
  useFilters,
  useSorting,
} from '@/components';
import { useSignaturesColumns } from './useSignaturesColumns';
import { useCallback, useMemo } from 'react';
import {
  SignatureState,
  UpdateSignatureArgs,
  useGetSignatureListQuery,
  useUpdateSignatureMutation,
} from '@/redux';
import { SignatureFilterParams } from '@/core';

const useOnTypeChange = () => {
  const [update] = useUpdateSignatureMutation();
  return useCallback(
    (value: UpdateSignatureArgs) => {
      update(value)
        .unwrap()
        .then(() => notify.success.t('summarySettings.signatures.updated'));
    },
    [update],
  );
};

export function SignatureListPanel() {
  const { setTotal, paging, params } = useDefaultTablePaging();
  const {
    filters,
    onFiltersChange,
    resetFilters,
    setStringFilterOptions,
    stringFilterOptions,
  } = useFilters<SignatureFilterParams>(['client', 'community']);
  const { sorting, onSortingChange } = useSorting<SignatureState>();

  const { data } = useGetSignatureListQuery({
    paging: params,
    filters,
    sorting,
  });

  const signatures = useMemo(() => {
    if (data) {
      setStringFilterOptions(data.stringFilterOptions);
      setTotal(data.total);
    }
    return data?.items || [];
  }, [data, setStringFilterOptions, setTotal]);

  const onTypeChange = useOnTypeChange();

  const columns = useSignaturesColumns(
    signatures,
    onTypeChange,
    stringFilterOptions,
  );

  return (
    <>
      <Table<SignatureState>
        name={'signatures'}
        columns={columns}
        dataSource={signatures}
        rowKey={(row) => row.clientId + row.communityId}
        pagination={paging}
        onChange={(_, filters, sorting) => {
          onFiltersChange(filters);
          onSortingChange(sorting);
        }}
        onFiltersReset={resetFilters}
      />
    </>
  );
}
