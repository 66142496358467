import { useTranslation } from 'react-i18next';
import { TabPanel } from '@/components';
import { SignatureListPanel } from './SignatureListPanel';

export function SignaturesListTabPanel() {
  const { t } = useTranslation();
  const title = t('summarySettings.signatures.title');

  return (
    <TabPanel min="auto">
      <TabPanel.Title>{title}</TabPanel.Title>
      <TabPanel.Body>
        <SignatureListPanel />
      </TabPanel.Body>
    </TabPanel>
  );
}
