import { rtkq, RtkqRequest } from '../rtkq';
import { SearchByNumberDto, searchHttp } from '@/core';
import { api } from '../api';

export type SearchByNumberState = SearchByNumberDto;

const searchByNumberApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSearchResult: build.query<
      SearchByNumberState,
      RtkqRequest<{ entityNumber: string }>
    >({
      queryFn: async (args) => {
        return await rtkq(args).exec(() => searchHttp.get(args.entityNumber));
      },
    }),
  }),
});

export const { useGetSearchResultQuery, useLazyGetSearchResultQuery } =
  searchByNumberApi;
