import { Role } from '@/core';
import { useSelector } from 'react-redux';
import { useUserContextSelector } from '../users';
import { communitiesApi, CommunityContext } from './communitiesApi';

export function hasPermission(
  context: CommunityContext,
  role: Role,
  name: string,
) {
  const allAccess = role === 'BFP_ADMIN' || role === 'CLIENT_ADMIN';
  if (allAccess) {
    return true;
  }
  return context?.permissions ? context.permissions.includes(name) : false;
}

function createCommunityContextSelectors(
  communityId: string,
  state: any,
  role: Role,
) {
  const context = communitiesApi.endpoints.getCommunityContext.select({
    id: communityId,
  })(state).data!;

  const selectors = Object.assign({}, context, {
    hasPermission: (permission: string) => {
      return hasPermission(context, role, permission);
    },
  });

  return selectors;
}

export function useCommunityContextSelector<TResult>(
  communityId: string,
  select: (
    selectors: ReturnType<typeof createCommunityContextSelectors>,
  ) => TResult,
) {
  const role = useUserContextSelector((x) => x.role);
  return useSelector((state) => {
    return select(createCommunityContextSelectors(communityId, state, role));
  });
}
