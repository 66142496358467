import { Modal, Row, Tree as AntdTree } from 'antd';
import { Icon } from '../Icon';

export const StyledRow = Object.assign({}, Row);
StyledRow.defaultProps = {
  ...StyledRow.defaultProps,
  gutter: [10, 10],
};

export const FieldRow = Object.assign({}, Row);
FieldRow.defaultProps = {
  ...FieldRow.defaultProps,
  gutter: [20, 10],
};

export const CustomTree = Object.assign({}, AntdTree);
CustomTree.defaultProps = {
  ...CustomTree.defaultProps,
  switcherIcon: <Icon type="arrow-down" />,
};

Modal.defaultProps = {
  width: 620,
};
