import classNames from 'classnames';
import { EditOutlined, ZoomInOutlined } from '@ant-design/icons';
import { Dispatch, SetStateAction } from 'react';
import styles from './UserAvatarEdit.module.scss';
import { Picker } from './AvatarPicker';

export interface ImagePickerProps {
  notFound: boolean;
  onPreview: () => void;
  onSelect: Dispatch<SetStateAction<File | undefined>>;
  onDelete?: () => void;
}

export function ImagePicker(props: ImagePickerProps) {
  const { notFound, onPreview, onSelect } = props;
  return (
    <div className={styles.imagePicker}>
      <div className={styles.imagePickerHolder}>
        {!notFound && (
          <div
            onClick={onPreview}
            className={classNames(styles.imagePickerInnerItem)}
          >
            <ZoomInOutlined style={{ fontSize: 16, color: '#fff' }} />
          </div>
        )}
        <Picker onSelected={onSelect} className={styles.imagePickerInnerItem}>
          <EditOutlined style={{ fontSize: 16, color: '#fff' }} />
        </Picker>
      </div>
    </div>
  );
}
