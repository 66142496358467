import {
  createEntireLoadLookupSource,
  EMPTY_LOOKUP_SOURCE,
  LookupOption,
  LookupSource,
  SELECT_OTHER_VALUE_PREFIX,
} from '@/components';
import { RtkqSpin, useLazyGetResponsiblePersonsListQuery } from '@/redux';
import { useMemo } from 'react';
import { Typography } from 'antd';
import { TaskResponsiblePersonValue } from '@/core';

const { Text } = Typography;

export function mapResponsiblePersonValue(value: TaskResponsiblePersonValue) {
  if (value?.subcontractorId) {
    return `subcontractor://${value.subcontractorId}`;
  }

  if (value?.userId) {
    return `user://${value.userId}`;
  }

  if (value?.otherValue) {
    return `${SELECT_OTHER_VALUE_PREFIX}${value.otherValue}`;
  }

  throw new Error('Unable to map value');
}

export function parseResponsiblePersonValue(
  value: string | undefined,
): TaskResponsiblePersonValue {
  if (value?.startsWith('subcontractor://')) {
    return { subcontractorId: value.substring('subcontractor://'.length) };
  }
  if (value?.startsWith('user://')) {
    return { userId: value.substring('user://'.length) };
  }
  if (value?.startsWith(SELECT_OTHER_VALUE_PREFIX)) {
    return { otherValue: value.substring(SELECT_OTHER_VALUE_PREFIX.length) };
  }

  throw new Error('Unable to parse value');
}

function map(value: TaskResponsiblePersonValue): LookupOption {
  return {
    _search: value.label,
    value: mapResponsiblePersonValue(value),
    label: value.label,
  };
}

function onNewValue(search: string): LookupOption {
  return {
    _search: search,
    value: search,
    label: <Text type="secondary">Create: "{search}"</Text>,
  };
}

export function useResponsiblePersonsLookupSource(
  communityId?: string,
): LookupSource {
  const [fetchList] = useLazyGetResponsiblePersonsListQuery();

  return useMemo(() => {
    if (!communityId) {
      return EMPTY_LOOKUP_SOURCE;
    }
    const entireSource = createEntireLoadLookupSource({
      initial: (id: string) =>
        fetchList(
          {
            communityId,
            [RtkqSpin]: false,
          },
          true,
        )
          .unwrap()
          .then((values) =>
            map(
              values.find((x) => x.subcontractorId === id || x.userId === id)!,
            ),
          ),
      load: () =>
        fetchList(
          {
            communityId,
            [RtkqSpin]: false,
          },
          true,
        )
          .unwrap()
          .then((values) => values.map(map)),
    });

    return {
      ...entireSource,
      onNewValue,
      searchField: '_search',
    };
  }, [fetchList, communityId]);
}
