import { dataNodeUtils, Tree } from '@/components';
import { Input } from 'antd';
import { DataNode } from 'antd/lib/tree';
import classNames from 'classnames';
import { useField } from 'formik';
import { isEmpty } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SubcontractorDetails.module.scss';

interface Props {
  treeData: DataNode[];
}

function useHandleCheck(props: Props, filteredTreeData: DataNode[]) {
  const { treeData } = props;
  const [{ value }, , { setValue }] = useField<string[]>('itemIds');

  return useCallback(
    (checked: string[]) => {
      const nodes = checked.map(
        (key) => dataNodeUtils.findInAny(treeData, key)!,
      );
      const leafNodes = nodes.filter((x) => isEmpty(x.children));
      const result = leafNodes.map((x) => x.key as string);
      const hiddenSelected = value.filter(
        (x) => !dataNodeUtils.findInAny(filteredTreeData, x),
      );
      setValue([...hiddenSelected, ...result]);
    },
    [filteredTreeData, setValue, treeData, value],
  );
}

function useTreeData(data: DataNode[], searchString: string) {
  const filteredTreeData = useMemo(
    () => dataNodeUtils.filter(data, searchString),
    [data, searchString],
  );

  return useMemo(() => ({ treeData: filteredTreeData }), [filteredTreeData]);
}

export function FormSubcontractorTree(props: Props) {
  const { treeData } = props;
  const [{ value }] = useField<string[]>('itemIds');
  const [searchString, setSearchString] = useState('');
  const { treeData: filteredTreeData } = useTreeData(treeData, searchString);
  const handleCheck = useHandleCheck(props, filteredTreeData);
  const { t } = useTranslation();
  if (isEmpty(treeData)) {
    return null;
  }

  return (
    <>
      <Input
        className={styles.search}
        placeholder={t('subcontractors.details.search')}
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
      />
      <Tree
        rootClassName={classNames('nav', styles.nav)}
        treeData={filteredTreeData}
        checkable
        onCheck={handleCheck as any}
        checkedKeys={value}
        selectable={false}
      />
    </>
  );
}
