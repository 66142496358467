import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { Button, Space, Tooltip } from 'antd';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Pricing.module.scss';

const MAX_ZOOM_INDEX = 1;
const MIN_ZOOM_INDEX = 0;
const ZOOM_STEP = 0.05;

export function useZoomActions() {
  const { t } = useTranslation(undefined, { keyPrefix: 'pricing' });
  const [zoomIndex, setZoomIndex] = useState<number>(MAX_ZOOM_INDEX);

  const handleZoomIn = useCallback(() => {
    zoomIndex < MAX_ZOOM_INDEX && setZoomIndex((prev) => (prev += ZOOM_STEP));
  }, [zoomIndex]);

  const handleZoomOut = useCallback(() => {
    zoomIndex > MIN_ZOOM_INDEX && setZoomIndex((prev) => (prev -= ZOOM_STEP));
  }, [zoomIndex]);

  return {
    zoomIndex: zoomIndex,
    zoomActions: (
      <Space className={styles.zoomBtn} size="middle">
        <Tooltip placement="top" title={t('zoomIn')} mouseEnterDelay={0.2}>
          <Button
            type="link"
            icon={<ZoomInOutlined />}
            onClick={handleZoomIn}
          />
        </Tooltip>
        <Tooltip placement="top" title={t('zoomOut')} mouseEnterDelay={0.2}>
          <Button
            type="link"
            icon={<ZoomOutOutlined />}
            onClick={handleZoomOut}
          />
        </Tooltip>
      </Space>
    ),
  };
}
