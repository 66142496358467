import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import AntIcon, { KeyOutlined } from '@ant-design/icons';
import { IconType } from './IconType.auto-created';
import { IconMap } from './IconMap.auto-created';
import classNames from 'classnames';

const ICON_MAP = {
  key: KeyOutlined,
};

const ICON_MAP_VALUES = ICON_MAP as Record<string, typeof AntIcon>;

export type CustomIconType = IconType | keyof typeof ICON_MAP;

export interface IconProps
  extends Omit<Partial<CustomIconComponentProps>, 'component'> {
  type: CustomIconType;
}

export function Icon({ type: icon, fill, ...props }: IconProps) {
  const IconComponent = ICON_MAP_VALUES[icon];
  const IconForAntd = IconMap[icon as IconType];

  if (IconComponent) {
    return <IconComponent type={'key'} {...props} />;
  }

  return (
    <AntIcon
      component={() => <IconForAntd fill={fill || 'none'} />}
      {...props}
      className={classNames(props.className, `icon-${icon}`)}
    />
  );
}
