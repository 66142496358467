import {
  ChangePasswordDto,
  UpdateProfileDto,
  UpdateUserDto,
  UserContextDto,
  UserDto,
  usersHttp,
  UserInviteDto,
  openid,
  Role,
  ActivityUserDto,
  Paging,
  Paged,
  UserFilterParams,
  UpdateUserAvatarDto,
  GeneratePasswordDto,
} from '@/core';
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { api } from '../api';
import { rtkq, RtkqRequest } from '../rtkq';
import { SortResult } from '@/components/Collections.Paging/useSorting';

export type UserState = UserDto;
export type ActivityUserState = ActivityUserDto;
export type UserContextState = UserContextDto;
export type UpdateProfileArgs = UpdateProfileDto;
export type UpdateUserArgs = UpdateUserDto;
export type ChangePasswordArgs = ChangePasswordDto;
export type GeneratePasswordArgs = GeneratePasswordDto;
export type UserInviteArgs = UserInviteDto;
export type GetUsersArgs = {
  organizationId?: string;
  onlyClient?: boolean;
  active?: boolean;
  roles?: Role[];
  paging: Paging;
  filters?: UserFilterParams;
  sorting?: SortResult;
};

function selectContext(args: BaseQueryApi): UserContextState {
  const state = args.getState() as any;
  return usersApi.endpoints.getContext.select()(state)?.data!;
}

export const usersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getContext: build.query<UserContextState, RtkqRequest<void>>({
      queryFn: async (args) => {
        return rtkq(args).exec(() => usersHttp.context());
      },
      providesTags: ['context'],
    }),

    getProfile: build.query<UserState, RtkqRequest<void>>({
      queryFn: async (args, api) => {
        const id = selectContext(api).id;
        return await rtkq(args).exec(() => usersHttp.get(id));
      },
      providesTags: (user) => [{ type: 'user', id: user?.id ?? 'none' }],
    }),

    getDetails: build.query<UserState, RtkqRequest<{ id: string }>>({
      queryFn: async (args) =>
        await rtkq(args).exec(() => usersHttp.get(args.id)),
      providesTags: (user) => [{ type: 'user', id: user?.id ?? 'none' }],
    }),

    updateProfile: build.mutation<void, RtkqRequest<UpdateProfileArgs>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => usersHttp.updateProfile(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'user', id: 'list' },
        { type: 'user', id: args.id },
        { type: 'context' },
      ],
    }),

    getUsers: build.query<Paged<UserState>, RtkqRequest<GetUsersArgs>>({
      queryFn: async (args) => {
        return rtkq(args).exec(() => usersHttp.all(args));
      },
      providesTags: (result) => [
        { type: 'user', id: 'list' },
        ...(result?.items || []).map(({ id }) => ({
          type: 'user' as const,
          id,
        })),
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        for (const user of data.items) {
          dispatch(
            usersApi.util.updateQueryData(
              'getUser',
              { id: user.id },
              () => user,
            ),
          );
        }
      },
    }),

    getActivityUsers: build.query<ActivityUserState[], RtkqRequest<void>>({
      queryFn: async (args) => {
        return rtkq(args).exec(() => usersHttp.getActivityUsers());
      },
      providesTags: (result = []) => [
        { type: 'user', id: 'list' },
        ...result.map(({ id }) => ({ type: 'user' as const, id })),
      ],
    }),

    getUser: build.query<UserState, RtkqRequest<{ id: string }>>({
      queryFn: async (args) => {
        const { id } = args;
        return await rtkq(args).exec(() => usersHttp.get(id));
      },
      providesTags: (_, __, { id }) => [{ type: 'user', id }],
    }),

    updateUser: build.mutation<void, RtkqRequest<UpdateUserArgs>>({
      queryFn: async (args, api) => {
        return rtkq(args).exec({
          query: () => usersHttp.update(args),
          meta: () => ({ isCurrentUser: selectContext(api).id === args.id }),
        });
      },
      invalidatesTags: (_, __, { id }, metaArg) => {
        const meta = metaArg as { isCurrentUser: boolean };
        const tags = [
          { type: 'user' as const, id },
          { type: 'communityUser' as const, id },
        ];

        return meta.isCurrentUser ? [{ type: 'context' }, ...tags] : tags;
      },
    }),

    updateUserAvatar: build.mutation<void, RtkqRequest<UpdateUserAvatarDto>>({
      queryFn: async (args, api) => {
        return rtkq(args).exec({
          query: () => usersHttp.updateAvatar(args),
          meta: () => ({ isCurrentUser: selectContext(api).id === args.id }),
        });
      },
      invalidatesTags: (_, __, { id }, metaArg) => {
        const meta = metaArg as { isCurrentUser: boolean };
        const tags = [
          { type: 'user' as const, id },
          { type: 'communityUser' as const, id },
        ];

        return meta.isCurrentUser ? [{ type: 'context' }, ...tags] : tags;
      },
    }),

    changePassword: build.mutation<void, RtkqRequest<ChangePasswordArgs>>({
      queryFn: async (args) => {
        return rtkq(args).exec(() => usersHttp.changePassword(args));
      },
    }),

    generatePassword: build.mutation<void, RtkqRequest<GeneratePasswordArgs>>({
      queryFn: async (args) => {
        return rtkq(args).exec(() => usersHttp.generatePassword(args));
      },
    }),

    changeActive: build.mutation<
      void,
      RtkqRequest<{ id: string; value: boolean }>
    >({
      queryFn: async (args, api) => {
        const { id } = selectContext(api);
        return rtkq(args).exec(async () => {
          await usersHttp.changeActive(args.id, args.value);
          id === args.id && (await openid.logout());
        });
      },
      invalidatesTags: (_, __, { id }) => [
        { type: 'user', id: 'list' },
        { type: 'user', id },
        { type: 'task', id: 'responsible-persons' },
      ],
    }),

    unlockUser: build.mutation<void, RtkqRequest<{ id: string }>>({
      queryFn: async (args) => {
        const { id } = args;
        return rtkq(args).exec(() => usersHttp.unlock(id));
      },
      invalidatesTags: (_, __, { id }) => [
        { type: 'user', id: 'list' },
        { type: 'user', id },
      ],
    }),

    inviteUser: build.mutation<void, RtkqRequest<UserInviteArgs>>({
      queryFn: async (args) => {
        return rtkq(args).exec(() => usersHttp.invite(args));
      },
      invalidatesTags: [{ type: 'user', id: 'list' }],
    }),

    deleteUser: build.mutation<void, RtkqRequest<{ id: string }>>({
      queryFn: async (args) => {
        return rtkq(args).exec(() => usersHttp.delete(args.id));
      },
      invalidatesTags: [{ type: 'user', id: 'list' }],
    }),
  }),
});

export const {
  useGetProfileQuery,
  useLazyGetDetailsQuery,
  useGetContextQuery,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useGetUserQuery,
  useChangePasswordMutation,
  useChangeActiveMutation,
  useUnlockUserMutation,
  useInviteUserMutation,
  useUpdateUserMutation,
  useUpdateProfileMutation,
  useLazyGetActivityUsersQuery,
  useDeleteUserMutation,
  useUpdateUserAvatarMutation,
  useGeneratePasswordMutation,
} = usersApi;
