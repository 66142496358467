import { notify } from '@/components';
import {
  TaskCommentState,
  useAddTaskCommentMutation,
  useDeleteTaskCommentMutation,
  useGetTaskCommentListQuery,
  useUpdateTaskCommentMutation,
} from '@/redux';
import { Divider, List, ListProps } from 'antd';
import { isEmpty } from 'lodash';
import {
  AddTaskCommentFormValue,
  FormTaskComment,
  UpdateTaskCommentFormValue,
} from './FormTaskComment';
import { TaskCommentItem } from './TaskCommentItem';

interface Props {
  taskId: string;
  canAdd?: boolean;
}

const LOCALE: ListProps<TaskCommentState>['locale'] = {
  emptyText: <></>,
};

export function TaskCommentsPanel(props: Props) {
  const { taskId, canAdd } = props;

  const { data } = useGetTaskCommentListQuery({ taskId });
  const [update] = useUpdateTaskCommentMutation();
  const [add] = useAddTaskCommentMutation();
  const [remove] = useDeleteTaskCommentMutation();

  const handleAdd = (comment: AddTaskCommentFormValue) =>
    add({ projectTaskId: taskId, ...comment })
      .unwrap()
      .then(() => notify.success.t('tasks.comments.added'));

  const handleUpdate = (comment: UpdateTaskCommentFormValue) =>
    update(comment)
      .unwrap()
      .then(() => notify.success.t('tasks.comments.saved'));

  const handleDelete = (comment: TaskCommentState) =>
    remove({ id: comment.id })
      .unwrap()
      .then(() => notify.success.t('tasks.comments.deleted'));

  return (
    <div>
      {canAdd && <FormTaskComment onSubmit={handleAdd} />}
      {!isEmpty(data) && <Divider dashed />}
      {!isEmpty(data) && (
        <List
          locale={LOCALE}
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <li>
              <TaskCommentItem
                canAdd={canAdd}
                onDelete={handleDelete}
                onSubmit={handleUpdate}
                value={item}
              />
            </li>
          )}
        />
      )}
    </div>
  );
}
