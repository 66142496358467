import { ContextMenu, TableColumnType } from '@/components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { CustomLegalLanguageState } from '@/redux/customLegalLanguage/customLegalLanguageApi';
import { useFiltersFactory } from '@/utils';
import styles from './CustomLegalLanguage.module.scss';
import { StringFilterOptions } from '@/core';

export function useCustomLegalLanguageColumns(
  openEdit: (legalLanguage: CustomLegalLanguageState) => any,
  handleDelete: (id: string) => void,
  data: CustomLegalLanguageState[],
  languagesStringFilterOptions?: StringFilterOptions,
) {
  const { t } = useTranslation(undefined, { keyPrefix: 'customLegalLanguage' });
  const filters = useFiltersFactory(data, languagesStringFilterOptions);
  return useMemo<TableColumnType<CustomLegalLanguageState>[]>(() => {
    const cols: Array<TableColumnType<CustomLegalLanguageState> | false> = [
      {
        title: t('language'),
        key: 'legalLanguage',
        ...filters.plainText((x) => x.legalLanguageText),
        sorter: (a, b) =>
          a.legalLanguageText.localeCompare(b.legalLanguageText),
        render: (_, item) => (
          <div className={styles.language}>{item.legalLanguageText}</div>
        ),
        width: '40%',
        ellipsis: true,
      },
      {
        title: t('client'),
        key: 'client',
        ...filters.select((x) => x.clientName, 'client'),
        sorter: (a, b) => a.clientName.localeCompare(b.clientName),
        render: (_, { clientId, clientName }) => (
          <NavLink to={`/clients/${clientId}`}>{clientName}</NavLink>
        ),
        width: '25%',
      },
      {
        title: t('community'),
        key: 'community',
        ...filters.select((x) => x.communityName, 'community'),
        sorter: (a, b) => a.communityName.localeCompare(b.communityName),
        render: (_, { communityId, communityName }) => (
          <NavLink to={`/communities/${communityId}`}>{communityName}</NavLink>
        ),
        width: '25%',
      },
      {
        title: t('actions'),
        key: 'actions',
        render: (_, item) => (
          <ContextMenu
            items={[
              {
                onClick: () => openEdit(item),
                label: t('edit'),
              },
              {
                onClick: () => handleDelete(item.id),
                label: t('delete'),
              },
            ]}
          />
        ),
        width: '10%',
        align: 'center',
      },
    ];

    return cols.filter(
      (x) => !!x,
    ) as TableColumnType<CustomLegalLanguageState>[];
  }, [filters, handleDelete, openEdit, t]);
}
