import { Tabs } from '@/components';
import { ProjectHomesDeadlinesListPanel } from './ProjectHomesDeadlinesListPanel';
import { enumObject } from '@/utils';
import { useTranslation } from 'react-i18next';
import { ProjectLocationDeadlinesListPanel } from './ProjectLocationDeadlinesListPanel';

const { TabPane } = Tabs;

interface Props {
  projectId: string;
  communityId: string;
  pathName?: string;
}

const TAB_KEYS = ['home-deadlines', 'location-deadlines'] as const;

export const OverrideContentKeys = enumObject(
  'OverrodesTabContentKeys',
  TAB_KEYS,
);

export function ProjectOverrideDeadlinesTabPanel(props: Props) {
  const { pathName, projectId, communityId } = props;
  const { t } = useTranslation();
  return (
    <>
      <Tabs
        tabKeys={OverrideContentKeys}
        defaultActiveKey={OverrideContentKeys['location-deadlines']}
        activeKey={OverrideContentKeys['location-deadlines']}
        destroyInactiveTabPane
      >
        {pathName && (
          <TabPane
            tab={t('projects.locationDeadlines.title')}
            key={OverrideContentKeys['location-deadlines']}
          >
            <ProjectLocationDeadlinesListPanel
              projectId={projectId}
              communityId={communityId}
            />
          </TabPane>
        )}
        {pathName && (
          <TabPane
            tab={t('projects.homeDeadlines.title')}
            key={OverrideContentKeys['home-deadlines']}
          >
            <ProjectHomesDeadlinesListPanel
              projectId={projectId}
              communityId={communityId}
            />
          </TabPane>
        )}
      </Tabs>
    </>
  );
}
