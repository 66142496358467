import { Button, ButtonProps, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styles from './Button.module.scss';

export type EditIconButtonProps = ButtonProps & {
  icon?: React.ReactNode;
  title?: string;
};

export function EditIconButton(props: EditIconButtonProps) {
  const { icon, title } = props;
  const { t } = useTranslation();

  return (
    <Tooltip
      placement="top"
      title={title ?? t('edit')}
      mouseEnterDelay={0.2}
      className={styles.editAction}
    >
      <Button type="link" {...props} icon={icon ?? <EditOutlined />} />
    </Tooltip>
  );
}
