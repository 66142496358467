import { useGetProjectHomeInfoQuery } from '@/redux';
import { useMemo } from 'react';

export const useIsFinalized = (
  homeId: string,
  projectId?: string,
  stepId?: string,
) => {
  const { data: context } = useGetProjectHomeInfoQuery(
    {
      id: projectId!,
      homeId,
    },
    { skip: !projectId },
  );

  return useMemo(() => {
    if (!context) return null;
    if (!stepId) return !!context.finalizedAt;
    return context.steps.find((x) => x.stepId === stepId)?.isFinalized;
  }, [context, stepId]);
};
