import { EditIconButton, TextBox, Title } from '@/components';
import { UserState } from '@/redux';
import { useTranslation } from 'react-i18next';
import { useEditUserActionBase } from '../Users.List';
import { Role } from '@/core';

interface UserGeneralDetailsProps {
  user: UserState;
  roles: Role[];
  noEditButton?: boolean;
}

export function UserGeneralDetails({
  user,
  roles,
  noEditButton,
}: UserGeneralDetailsProps) {
  const { t } = useTranslation();
  const { firstName, lastName, email, clientName, phone } = user;
  const { editModal, openEdit } = useEditUserActionBase({
    noRoleSelect: user.role === 'BFP_ADMIN',
    noClientSelect: user.role === 'BFP_ADMIN',
    roles: roles,
  });
  return (
    <div>
      <Title
        actions={
          !noEditButton && (
            <EditIconButton type="link" onClick={() => openEdit(user.id)} />
          )
        }
        inlineActions
      >
        {t('users.profile.general')}
      </Title>
      <TextBox label={t('email')}>{email}</TextBox>
      <TextBox label={t('firstName')}>{firstName}</TextBox>
      <TextBox label={t('lastName')}>{lastName}</TextBox>
      {clientName && (
        <TextBox label={t('users.details.client')}>{clientName}</TextBox>
      )}
      <TextBox label={t('users.details.phone')}>{phone}</TextBox>
      {editModal}
    </div>
  );
}
