export class CommunityPermissions {
  private static Manage = '.Manage';
  private static View = '.View';
  private static FullAccess = '.FullAccess';

  static Subscriptions = class {
    private static Prefix = 'Subscriptions';

    public static Manage = this.Prefix + CommunityPermissions.Manage;
    public static View = this.Prefix + CommunityPermissions.View;
  };

  static Locations = class {
    private static Prefix = 'Locations';

    public static Manage = this.Prefix + CommunityPermissions.Manage;
    public static View = this.Prefix + CommunityPermissions.View;
  };

  static Projects = class {
    private static Prefix = 'Projects';

    public static Manage = this.Prefix + CommunityPermissions.Manage;
    public static View = this.Prefix + CommunityPermissions.View;
  };

  static Path = class {
    private static Prefix = 'Path';

    public static Manage = this.Prefix + CommunityPermissions.Manage;
    public static View = this.Prefix + CommunityPermissions.View;
  };

  static Homes = class {
    private static Prefix = 'Homes';

    public static Manage = this.Prefix + CommunityPermissions.Manage;
    public static View = this.Prefix + CommunityPermissions.View;
  };

  static Residents = class {
    private static Prefix = 'Residents';

    public static Manage = this.Prefix + CommunityPermissions.Manage;
    public static View = this.Prefix + CommunityPermissions.View;
  };

  static Users = class {
    private static Prefix = 'Users';

    public static Manage = this.Prefix + CommunityPermissions.Manage;
    public static View = this.Prefix + CommunityPermissions.View;
  };

  static Subcontractors = class {
    private static Prefix = 'Subcontractors';

    public static Manage = this.Prefix + CommunityPermissions.Manage;
    public static View = this.Prefix + CommunityPermissions.View;
  };

  static ItemsCategories = class {
    private static Prefix = 'ItemsCategories';

    public static Manage = this.Prefix + CommunityPermissions.Manage;
    public static View = this.Prefix + CommunityPermissions.View;
  };

  static Menus = class {
    private static Prefix = 'Menus';

    public static Manage = this.Prefix + CommunityPermissions.Manage;
    public static View = this.Prefix + CommunityPermissions.View;
  };

  static Floorplans = class {
    private static Prefix = 'Floorplans';

    public static Manage = this.Prefix + CommunityPermissions.Manage;
    public static View = this.Prefix + CommunityPermissions.View;
  };

  static Amenities = class {
    private static Prefix = 'Amenities';

    public static Manage = this.Prefix + CommunityPermissions.Manage;
    public static View = this.Prefix + CommunityPermissions.View;
  };

  static Reports = class {
    private static Prefix = 'Reports';

    public static Manage = this.Prefix + CommunityPermissions.Manage;
    public static View = this.Prefix + CommunityPermissions.View;
  };

  static Personalization = class {
    private static Prefix = 'Personalization';

    public static Manage = this.Prefix + CommunityPermissions.Manage;
    public static View = this.Prefix + CommunityPermissions.View;
    public static TabloidPdf = this.Prefix + '.TabloidPdf';
    public static SpecialRequestComment =
      this.Prefix + '.SpecialRequestComment';
  };

  static DevelopScope = class {
    private static Prefix = 'DevelopScope';

    public static Manage = this.Prefix + CommunityPermissions.Manage;
    public static View = this.Prefix + CommunityPermissions.View;
  };

  static Pricing = class {
    private static Prefix = 'Pricing';

    public static Manage = this.Prefix + CommunityPermissions.Manage;
    public static View = this.Prefix + CommunityPermissions.View;
  };

  static ResidentPriceOverride = class {
    private static Prefix = 'ResidentPriceOverride';

    public static FullAccess = this.Prefix + CommunityPermissions.FullAccess;
  };

  static HomeDocuments = class {
    private static Prefix = 'HomeDocuments';

    public static Manage = this.Prefix + CommunityPermissions.Manage;
    public static View = this.Prefix + CommunityPermissions.View;
  };

  static TaskManagement = class {
    private static Prefix = 'TaskManagement';

    public static Manage = this.Prefix + CommunityPermissions.Manage;
    public static View = this.Prefix + CommunityPermissions.View;
  };

  static BaseCost = class {
    private static Prefix = 'BaseCost';

    public static FullAccess = this.Prefix + CommunityPermissions.FullAccess;
  };

  static BaseCostAndOwnerPrice = class {
    private static Prefix = 'BaseCostAndOwnerPrice';

    public static FullAccess = this.Prefix + CommunityPermissions.FullAccess;
  };
}
