import { orderBy } from 'lodash';
import { ProjectHomeInfoDto, ProjectStepLookupDto } from '@/core';

export function getStepWithNearestDeadline(
  projectHome?: ProjectHomeInfoDto,
  data?: ProjectStepLookupDto[],
) {
  const nonFinalizedStepIds = projectHome?.steps
    .filter((x) => !x.isFinalized)
    ?.map((x) => x.stepId);
  const filteredStepLookupData = data?.filter((d) =>
    nonFinalizedStepIds?.find((x) => x === d.id),
  );
  const sortedStepLookupData = orderBy(filteredStepLookupData, (x) => x.index);
  return sortedStepLookupData.length > 0 ? sortedStepLookupData[0] : undefined;
}
