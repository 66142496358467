import { notify } from '@/components';
import {
  DevelopScopeItemState,
  UpdateDevelopScopeItemArgs,
  useUpdateDevelopScopeItemMutation,
} from '@/redux';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DevelopScopeItemEditView, DevelopScopeItemReadView } from '@/views';

interface Props {
  item: DevelopScopeItemState;
  className?: string;
  readonly?: boolean;
  compact?: boolean;
}

const useUpdate = (setEditItem: (value: boolean) => any) => {
  const [update] = useUpdateDevelopScopeItemMutation();

  return async (values: UpdateDevelopScopeItemArgs) =>
    await update(values)
      .unwrap()
      .then(() => notify.success.t('developScope.details.saved'))
      .then(() => setEditItem(false));
};

const useEditValue = ({ item }: Props) =>
  useMemo<UpdateDevelopScopeItemArgs>(
    () => ({
      ...item,
      imageActions: [],
      specialRequestRequired: !!item.specialRequest,
    }),
    [item],
  );

export function DevelopScopeListItem(props: Props) {
  const { item, className, readonly, compact } = props;
  const { t } = useTranslation();
  const [isEditMode, setIsEditMode] = useState(false);
  const update = useUpdate(setIsEditMode);
  const editValue = useEditValue(props);

  return (
    <div className={className}>
      {!isEditMode && (
        <DevelopScopeItemReadView
          item={item}
          onEditClick={() => setIsEditMode(true)}
          readonly={readonly}
        />
      )}
      {isEditMode && (
        <DevelopScopeItemEditView
          onCancel={() => setIsEditMode(false)}
          onSubmit={update}
          value={editValue}
          saveButtonName={t('developScope.details.editSaveButton')}
          compact={compact}
        />
      )}
    </div>
  );
}
