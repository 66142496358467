import { useState } from 'react';
import { useDuplicateTaskMutation, DuplicateTaskArgs } from '@/redux';
import { notify } from '@/components';
import { DuplicateTaskModal } from './DuplicateTaskModal';

export function useDuplicateTaskAction() {
  const [taskIds, setTaskIds] = useState<string[]>([]);
  const [projectId, setProjectId] = useState<string>();
  const [duplicate] = useDuplicateTaskMutation();

  const reset = () => {
    setTaskIds([]);
    setProjectId(undefined);
  };

  const handleSubmit = (value: DuplicateTaskArgs) => {
    duplicate(value)
      .unwrap()
      .then(() => {
        notify.success.t('tasks.details.duplicate.success');
        reset();
      });
  };

  return {
    openDuplicate: (taskIds: string[], projectId: string) => {
      setTaskIds(taskIds);
      setProjectId(projectId);
    },
    duplicateModal: (
      <>
        {taskIds.length > 0 && projectId && (
          <DuplicateTaskModal
            onSubmit={handleSubmit}
            taskIds={taskIds}
            projectId={projectId}
            onClose={reset}
          />
        )}
      </>
    ),
  };
}
