import { SpecialRequestState, useGetSpecialRequestsQuery } from '@/redux';
import { array } from '@/utils';
import { Collapse, Divider, Space, Typography } from 'antd';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { SpecialRequestListItem } from '../SpecialRequest';

interface Props {
  projectId: string;
  homeId: string;
  stepId?: string;
  collapsed: boolean;
  roomId?: string;
  readonly?: boolean;
  compact?: boolean;
}

const { Text } = Typography;
const { Panel } = Collapse;

export const RoomPersonalizationSpecialRequests = (props: Props) => {
  const { projectId, homeId, stepId, collapsed, roomId, readonly, compact } =
    props;
  const { t } = useTranslation();

  const { data: specialRequests = array.empty<SpecialRequestState>() } =
    useGetSpecialRequestsQuery({
      projectId,
      homeId,
      stepId,
      isFloorplan: false,
      roomId,
      wholeHome: !roomId,
      asItem: false,
    });

  const [openRequests, setOpenRequests] = useState<string[]>([]);

  const key = 'special-requests' + homeId;

  useEffect(() => {
    if (collapsed) {
      setOpenRequests([]);
    } else {
      setOpenRequests([key]);
    }
  }, [collapsed, key]);

  const onChange = (key: string | string[]) => {
    setOpenRequests(key as string[]);
  };

  if (isEmpty(specialRequests)) return null;

  return (
    <Collapse ghost activeKey={openRequests} onChange={onChange}>
      <Panel
        key={key}
        header={
          <Space>
            <Text strong>
              {t('personalization.item.details.specialRequests')}:
            </Text>
            <Text type="secondary">
              {t('personalization.item.details.subheading', {
                count: specialRequests.length,
              })}
            </Text>
          </Space>
        }
      >
        {specialRequests.map((item, index) => (
          <>
            <SpecialRequestListItem
              item={item}
              className="mb-4"
              readonly={readonly}
              compact={compact}
            />
            {index !== specialRequests.length - 1 && <Divider />}
          </>
        ))}
      </Panel>
    </Collapse>
  );
};
