import { useCallback, useEffect, useMemo, useState } from 'react';
import { PagingProps } from './PagingProps';
import { PAGINATION_DEFAULT } from '../Table';
import { Paging, pagingFrom } from '@/core';

export interface UsePagingArgs {
  total?: number;
  size?: number;
  current?: number;
}

export type UsePagingResult = PagingProps & {
  pageSize: number;
};

export function usePaging(args: UsePagingArgs): UsePagingResult {
  const { total, size: sizeArg, current: pageArgs } = args;
  const [page, setPage] = useState(pageArgs ?? 1);
  const [size, setSize] = useState(
    sizeArg ?? PAGINATION_DEFAULT.defaultPageSize,
  );

  const handlePageChange = useCallback(
    (page: number, size: number) => {
      setPage(page);
      setSize(size);
    },
    [setPage, setSize],
  );

  return {
    current: page,
    onChange: handlePageChange,
    total: total ?? 0,
    pageSize: size,
    showSizeChanger: true,
  };
}

export function useDefaultTablePaging() {
  const [total, setTotal] = useState(0);
  const paging = usePaging({ total, size: 10 });
  const [params, setParams] = useState<Paging>({
    skip: 0,
    take: 10,
    includeTotal: true,
  });

  useEffect(() => {
    setParams(pagingFrom(paging, true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging.current, paging.pageSize]);

  return useMemo(() => {
    return { setTotal, paging, params };
  }, [paging, params]);
}
