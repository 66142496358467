import { useAddTaskTemplateMutation } from '@/redux';
import { notify } from '@/components';
import { FormTaskTemplate } from './Form';
import { useMemo } from 'react';
import { CreateTaskTemplateArgs } from "@/redux/taskTemplates";
import { parseResponsiblePersonValue } from "@/views";

interface Props {
  communityId?: string;
  onCreated?: () => any;
  onCancel?: () => any;
}

const useSubmit = (props: Props) => {
  const [add] = useAddTaskTemplateMutation();

  return (args: CreateTaskTemplateArgs) => {
    args.tasks.map(task => task.responsiblePerson = parseResponsiblePerson(task));

    add(args)
      .unwrap()
      .then(props.onCreated)
      .then(() => notify.success.t('taskTemplates.details.added'));
  }
};

export const parseResponsiblePerson = (data: any) =>  {
  return data.responsiblePerson !== undefined ? parseResponsiblePersonValue(data.responsiblePerson) : undefined;
};

function useValue(props: Props): CreateTaskTemplateArgs {
  const { communityId } = props;
  return useMemo(
    () => ({ name: null!, tasks: [], communityId }),
    [communityId],
  );
}

export function AddTaskTemplatePanel(props: Props) {
  const { communityId, onCancel } = props;
  const submit = useSubmit(props);
  const value = useValue(props);

  return (
    <FormTaskTemplate<CreateTaskTemplateArgs>
      onSubmit={submit}
      value={value}
      communityId={communityId}
      onCancel={onCancel}
      isAdding
    />
  );
}
