import { BulkDeleteTaskArgs, useBulkDeleteTasksMutation } from '@/redux';
import { useCallback } from 'react';
import { confirmation, notify } from '@/components';

export function useBulkDeleteTasksAction() {
  const [bulkDelete] = useBulkDeleteTasksMutation();

  const handleDelete = useCallback(
    (value: BulkDeleteTaskArgs) => {
      bulkDelete(value)
        .unwrap()
        .then(() => notify.success.t('tasks.bulkDelete.deleted'));
    },
    [bulkDelete],
  );

  return useCallback(
    (value: BulkDeleteTaskArgs) => {
      confirmation.delete({ key: 'tasks.bulkDelete.name' }, () =>
        handleDelete(value),
      );
    },
    [handleDelete],
  );
}
