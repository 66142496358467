import { Http } from '../http/http';
import { guard } from '@/utils';
import {
  AddRoomTypeDto,
  RoomTypeDto,
  RoomTypeStatus,
  UpdateRoomTypeDto,
} from './roomTypeTypes';
import qs from 'qs';

class RoomTypesHttp extends Http {
  public async getAll(onlyActive?: boolean) {
    const query = qs.stringify({ onlyActive });
    const url = `/api/room-type?${query}`;
    const response = await this._axios.get<RoomTypeDto[]>(url);
    return response.data;
  }

  public async create(request: AddRoomTypeDto) {
    guard.notNull(request, 'request');
    const response = await this._axios.post<string>(`/api/room-type`, request);
    return response.data;
  }

  public async update(request: UpdateRoomTypeDto) {
    guard.notNull(request, 'request');
    await this._axios.put(`/api/room-type`, request);
  }

  public async changeStatus(id: string, status: RoomTypeStatus) {
    guard.notNull(id, 'id');
    guard.notNull(status, 'status');
    const response = await this._axios.put<string>('/api/room-type/status', {
      id,
      status,
    });
    return response.data;
  }
}

export const roomTypesHttp = new RoomTypesHttp();
