import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Input,
  Row,
  Space,
  Tree,
  TreeProps,
} from 'antd';
import {
  ColumnFilterItem,
  FilterDropdownProps,
} from 'antd/lib/table/interface';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { FieldDataNode } from 'rc-tree';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

type FilterTreeDataNode = FieldDataNode<{
  title: React.ReactNode;
  key: string;
}>;

const getTreeData = (filters?: ColumnFilterItem[]): FilterTreeDataNode[] =>
  (filters || []).map((filter, index) => {
    const key = String(filter.value);
    const item: FilterTreeDataNode = {
      title: filter.text,
      key: filter.value !== undefined ? key : String(index),
    };
    if (filter.children) {
      item.children = getTreeData(filter.children);
    }
    return item;
  });

const _TreeFilter: React.FC<FilterDropdownProps> = ({
  setSelectedKeys,
  visible,
  confirm,
  filters = [],
}) => {
  const { t } = useTranslation();

  const [searchString, setSearchString] = useState<string | undefined>();
  const [selectedResult, setSelectedResult] = useState<string[]>([]);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const reset = () => {
    setSearchString(undefined);
    setSelectedResult([]);
  };

  useEffect(() => {
    if (!visible) {
      setSelectedKeys(selectedResult.length > 0 ? selectedResult : []);
      confirm({ closeDropdown: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const onCheckAll = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      const allFilterKeys = filteredTreeOptions.map((filter) => filter.key);
      setSelectedResult(allFilterKeys);
    } else {
      setSelectedResult([]);
    }
  };

  const onCheck: TreeProps['onCheck'] = (_, info) => {
    setSelectedResult(info.checkedNodes.map((x) => String(x.key)));
  };

  const filteredTreeOptions = useMemo(() => {
    const data = getTreeData(filters);
    if (searchString) {
      const selectedData = data.filter((x) => selectedResult.includes(x.key));
      const filteredData = data.filter(
        (x) =>
          x.key.toLowerCase().includes(searchString.toLowerCase()) &&
          !selectedResult.includes(x.key),
      );

      return [...selectedData, ...filteredData];
    }

    return data;
  }, [filters, searchString, selectedResult]);

  return (
    <div>
      <Space style={{ padding: 8 }}>
        <Input
          prefix={<SearchOutlined />}
          placeholder={t('searchInFilters')}
          onChange={onSearch}
          value={searchString}
        />
      </Space>
      <Divider style={{ margin: 0 }} />
      <Space style={{ padding: 8, width: '100%' }} direction="vertical">
        <Checkbox
          checked={selectedResult.length === filters.length}
          indeterminate={
            selectedResult.length > 0 && selectedResult.length < filters.length
          }
          onChange={onCheckAll}
        >
          {t('selectAll')}
        </Checkbox>
        <Tree<FilterTreeDataNode>
          checkable
          selectable={false}
          blockNode
          checkedKeys={selectedResult}
          selectedKeys={selectedResult}
          showIcon={false}
          treeData={filteredTreeOptions}
          autoExpandParent
          defaultExpandAll
          onCheck={onCheck}
          height={200}
          virtual
        />
      </Space>
      <Row justify="space-between" style={{ padding: 8 }}>
        <Col>
          <Button onClick={reset} size="small" type="link">
            {t('reset')}
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => confirm({ closeDropdown: true })}
            size="small"
            type="primary"
          >
            {t('ok')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export const TreeFilter = Object.assign(_TreeFilter);
