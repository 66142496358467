import { DateValue, EditIconButton, TableColumnType } from '@/components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectHomeDeadlineInfo, useGetProjectPathQuery } from '@/redux';
import { NavLink } from 'react-router-dom';
import { Row } from 'antd';
import { CommunityPermissions, ProjectHomeStepDeadlineLookupDto } from '@/core';
import { useCommunityContext } from '../Communities.Common';
import { useProjectContext } from '..';

interface Props {
  projectId: string;
  communityId: string;
  data?: ProjectHomeDeadlineInfo[];
  onEditButtonClick: () => void;
  setProjectHome: (home: ProjectHomeDeadlineInfo) => void;
  setDeadline: (deadline: ProjectHomeStepDeadlineLookupDto) => void;
}

export function useProjectHomesDeadlinesColumns(args: Props) {
  const {
    communityId,
    projectId,
    data,
    onEditButtonClick,
    setDeadline,
    setProjectHome,
  } = args;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'projects.homeDeadlines',
  });
  const { currentData: projectPath } = useGetProjectPathQuery({
    id: projectId,
  });
  const { hasStatus } = useProjectContext();
  const { assert } = useCommunityContext();
  const canManagePaths =
    assert(CommunityPermissions.Path.Manage, true) &&
    hasStatus(['NotStarted', 'InProgress']);

  return useMemo<TableColumnType<ProjectHomeDeadlineInfo>[]>(() => {
    const columns: Array<TableColumnType<ProjectHomeDeadlineInfo> | false> = [
      {
        title: t('homeNumber'),
        key: 'home',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => a.home.homeNumber.localeCompare(b.home.homeNumber),
        render: (_, { home }) => (
          <NavLink to={`/communities/${communityId}/homes/${home.id}`}>
            {home.homeNumber}
          </NavLink>
        ),
      },
      {
        title: t('pathName'),
        key: 'path',
        render: (_, { projectId }) => (
          <NavLink to={`/projects/${projectId}/path`}>
            {projectPath?.name}
          </NavLink>
        ),
      },
      ...(projectPath
        ? projectPath.steps.map((s) => ({
            title: s.name,
            key: s.id,
            width: 100,
            render: (_: any, item: ProjectHomeDeadlineInfo) => {
              const projectStep = item.steps.find(
                (projStep) => projStep.id === s.id,
              );
              const deadlineDate = projectStep?.deadlineDate;
              return (
                <Row wrap={false}>
                  <DateValue value={deadlineDate} />
                  {canManagePaths && !projectStep?.isFinalized && (
                    <EditIconButton
                      type="link"
                      onClick={() => {
                        onEditButtonClick();
                        setDeadline(projectStep!);
                        setProjectHome(item);
                      }}
                    />
                  )}
                </Row>
              );
            },
          }))
        : []),
    ];

    return columns.filter(
      (x) => !!x,
    ) as TableColumnType<ProjectHomeDeadlineInfo>[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    t,
    projectPath,
    communityId,
    data,
    onEditButtonClick,
    setDeadline,
    setProjectHome,
  ]);
}
