import { confirmation, EditIconButton, Icon } from '@/components';
import { RoomTypeDto, RoomTypeStatus } from '@/core/http.roomTypes';
import { Space, Switch, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './RoomType.module.scss';

interface Props {
  onChangeStatus: (id: string, status: RoomTypeStatus) => void;
  item: RoomTypeDto;
  setEditRoomType: (value: RoomTypeDto) => any;
}

export function RoomTypeActions(props: Props) {
  const { item, onChangeStatus, setEditRoomType } = props;
  const { t } = useTranslation();

  const switchStatusTooltip = () =>
    item.status === RoomTypeStatus.Active
      ? t('roomTypes.actions.deactivate')
      : t('roomTypes.actions.activate');

  const handleChangeStatus = (checked: boolean) => {
    checked
      ? onChangeStatus(
          item.id,
          item.status === 'Active' ? 'Deactivated' : 'Active',
        )
      : confirmation.deactivate(t('roomTypes.entityName'), () =>
          onChangeStatus(
            item.id,
            item.status === 'Active' ? 'Deactivated' : 'Active',
          ),
        );
  };

  return (
    <Space size="large" align="end">
      <Tooltip placement="top" title={switchStatusTooltip}>
        <Switch
          checked={item.status === 'Active'}
          onChange={handleChangeStatus}
        />
      </Tooltip>
      <EditIconButton
        onClick={() => setEditRoomType(item)}
        icon={<Icon type="edit" />}
        className={styles.editAction}
      />
    </Space>
  );
}
