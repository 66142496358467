import { DownloadedFile } from '@/core/http/file';
import { browserFileDownload, openFileInNewWindow } from '@/utils';
import { Button } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { useState } from 'react';

export interface FileDownloadButtonProps {
  className?: string;
  action: () => Promise<DownloadedFile>;
  name: string;
  disabled?: boolean;
  icon?: React.ReactElement;
  download?: 'open' | 'download';
  type?: ButtonType;
}

export function FileDownloadButton(props: FileDownloadButtonProps) {
  const { action, name, className, disabled, icon, download, type } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = () => {
    setLoading(true);

    action()
      .then((file) => {
        if (download === 'download') {
          browserFileDownload(file);
        } else {
          openFileInNewWindow(file);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Button
      className={className}
      loading={loading}
      onClick={handleClick}
      disabled={disabled}
      icon={icon}
      type={type}
    >
      {name}
    </Button>
  );
}
