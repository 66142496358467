import { notify } from '@/components';
import {
  PersonalizationItemState,
  CategoryState,
  RtkqSpin,
  useDeleteRoomPersonalizationItemMutation,
  useUpdateRoomPersonalizationItemMutation,
  ProjectStepLookupState,
  useGetRoomPersonalizationAvailableItemsQuery,
  useGetPersonalizationItemsQuery,
  useGetCategoryExistingItemQuery,
} from '@/redux';
import { Col, Divider, Spin } from 'antd';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { PersonalizationItemFormValue } from '../Item.Details/PersonalizationItemDetailsModal';
import { PersonalizationListItem } from '../PersonalizationListItem';
import { RoomPersonalizationItemToAdd } from './RoomPersonalizationItemToAdd';
import { AddSpecialRequestButton, useProjectContext } from '@/views';
import { ExistingItem, KeepExistingButton } from '../ExistingItems';
import { ProjectType } from '@/core';

interface Props {
  category: CategoryState;
  homeId: string;
  projectId: string;
  step?: ProjectStepLookupState;
  roomId?: string;
  readonly?: boolean;
  compact?: boolean;
}

function useCanAdd(props: Props) {
  const { readonly } = props;
  return !readonly;
}

function useCategoryItems(props: Props) {
  const { category, homeId, projectId, step, roomId } = props;

  const { data: availableItems, isFetching: isAvailableItemsLoading } =
    useGetRoomPersonalizationAvailableItemsQuery({
      homeId,
      categoryId: category.id,
      roomId,
      stepId: step?.id,
      projectId,
      [RtkqSpin]: false,
    });

  const { data: allAddedItems, isFetching: isAddedItemsLoading } =
    useGetPersonalizationItemsQuery({
      homeId,
      projectId,
      stepId: step?.id,
      roomId,
      [RtkqSpin]: false,
      isFloorplan: false,
      wholeHome: !roomId,
    });

  const { data: existingItem, isFetching: isExistingItemsLoading } =
    useGetCategoryExistingItemQuery(
      {
        homeId,
        categoryId: category.id,
        roomId,
        stepId: step?.id,
        projectId,
        [RtkqSpin]: false,
      },
      { skip: !step },
    );

  const addedItems = useMemo(
    () =>
      (
        allAddedItems?.filter((x) => x.category.category.id === category.id) ??
        []
      )
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name)),
    [allAddedItems, category],
  );

  const sortedAvailableItems = useMemo(
    () =>
      (availableItems ?? [])
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name)),
    [availableItems],
  );

  const [deleteItem] = useDeleteRoomPersonalizationItemMutation();
  const handleDelete = (id: string) => {
    deleteItem({ id, categoryId: category.id, stepId: step?.id! })
      .unwrap()
      .then(() => notify.success.t('personalization.item.details.deleted'));
  };

  return {
    availableItems: sortedAvailableItems,
    addedItems: addedItems,
    existingItem: existingItem,
    handleDelete,
    isAvailableItemsLoading,
    isAddedItemsLoading,
    isExistingItemsLoading,
  };
}

const useUpdate = () => {
  const [update] = useUpdateRoomPersonalizationItemMutation();

  return (args: PersonalizationItemFormValue, item: PersonalizationItemState) =>
    update({ ...args, id: item!.id, categoryId: item.category.category.id })
      .unwrap()
      .then(() =>
        notify.success.t('personalization.item.details.detailsSaved'),
      );
};

export function RoomPersonalizationItems(props: Props) {
  const { homeId, roomId, step, readonly, projectId, category, compact } =
    props;
  const {
    availableItems,
    addedItems,
    existingItem,
    handleDelete,
    isAvailableItemsLoading,
    isAddedItemsLoading,
    isExistingItemsLoading,
  } = useCategoryItems(props);

  const canAdd = useCanAdd(props);
  const handleSave = useUpdate();
  const isLoading =
    isAvailableItemsLoading || isAddedItemsLoading || isExistingItemsLoading;
  const isKeepExistingButtonAvailable = !existingItem;
  const { type } = useProjectContext();
  const isKeepExistingAvailable = type === ProjectType.Renovation;

  return (
    <div>
      {!readonly &&
        (!isEmpty(availableItems) || !isEmpty(addedItems)) &&
        isKeepExistingButtonAvailable && (
          <AddSpecialRequestButton
            stepId={step?.id}
            homeId={homeId}
            projectId={projectId}
            roomId={roomId}
            categoryId={category.id}
            disabledCategory
            asItem
          />
        )}
      {existingItem && <ExistingItem item={existingItem} readonly={readonly} />}
      {isKeepExistingAvailable &&
        !readonly &&
        (!isEmpty(availableItems) || !isEmpty(addedItems)) && (
          <KeepExistingButton
            id={existingItem?.id}
            stepId={step?.id}
            projectId={projectId}
            homeId={homeId}
            categoryId={category.id}
            roomId={roomId}
            isKeepExistingAvailable={isKeepExistingButtonAvailable}
          />
        )}
      {!isAddedItemsLoading &&
        !isEmpty(addedItems) &&
        addedItems.map((item) => (
          <PersonalizationListItem
            readonly={readonly}
            key={item.id}
            className="mb-4"
            item={item}
            onDelete={handleDelete}
            onSubmit={handleSave}
            compact={compact}
          />
        ))}
      {!isEmpty(availableItems) &&
        isKeepExistingButtonAvailable &&
        availableItems.map((item, index) => (
          <>
            <Divider />
            <RoomPersonalizationItemToAdd
              key={item.id}
              item={item}
              homeId={homeId}
              roomId={roomId}
              step={step!}
              projectId={projectId}
              disabled={!canAdd}
              categoryId={category.id}
              compact={compact}
            />
            {index === availableItems.length - 1 && <Divider />}
          </>
        ))}

      {isLoading && (
        <Col offset={10}>
          <Spin />
        </Col>
      )}
    </div>
  );
}
