import { useInView } from 'react-intersection-observer';
import { useImageBoxImageBlobURL } from './Context';
import { Image as AntImage } from 'antd';
import styles from './ImageBox.module.scss';
import loading from '@/assets/images/load.gif';
import classNames from 'classnames';
import { AttachmentDto } from '@/core';
import { memo } from 'react';

export interface ImageBoxImageProps {
  value: AttachmentDto;
  onClick?: () => any;
}

function _ImageBoxImage(props: ImageBoxImageProps) {
  const { value, onClick } = props;
  const { id } = value;
  const blobURL = useImageBoxImageBlobURL(id);

  if (!blobURL) {
    return (
      <AntImage
        key={`preview-${id}`}
        className={classNames(styles.image, '--loading')}
        onClick={onClick}
        preview={false}
        src={loading}
      />
    );
  }

  return (
    <AntImage
      key={id}
      className={styles.image}
      onClick={onClick}
      preview={{ visible: false }}
      src={blobURL}
    />
  );
}

function LazyImage(props: ImageBoxImageProps) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '0px 0px',
  });

  return <div ref={ref}>{inView && <_ImageBoxImage {...props} />}</div>;
}

export const ImageBoxImage = Object.assign(memo(_ImageBoxImage), {
  Lazy: memo(LazyImage),
});
