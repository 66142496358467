import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
}

export function RoomPersonalizationAlert(props: Props) {
  const { className } = props;
  const { t } = useTranslation();

  return (
    <Alert
      message={t('personalization.item.details.stepFinalized')}
      type="success"
      className={className}
      showIcon
    />
  );
}
