import axios, { AxiosInstance } from 'axios';
import { Http } from '@/core';
import {
  useActivity,
  useActivityKinds,
  useActivityList,
} from '@webinex/activity';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: '/api/custom-activity',
});

axiosInstance.interceptors.request.use(async (request) => {
  const { key, value } = await Http.getAuthHeader();
  request.headers![key] = value;
  return request;
});

export function useAppActivityList() {
  return useActivityList({ axios: axiosInstance });
}

export function useAppActivity(id: string) {
  return useActivity(id, { axios: axiosInstance });
}

export function useAppActivityKinds() {
  return useActivityKinds({ axios: axiosInstance });
}
