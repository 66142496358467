import * as Yup from 'yup';
import { Form, StyledRow } from '@/components';
import {
  GenerateCustomReportArgs,
  useGenerateCustomReportMutation,
  useUserContextSelector,
} from '@/redux';
import { FormCustomReportColumns } from './FormCustomReportColumns';
import { FormCustomReportOptions } from './FormCustomReportOptions';
import { Button, Col, Divider, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormCustomReportFilter } from './FormCustomReportFilter';
import { FileExcelOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { FormCustomReportTemplateOptions } from './FormCustomReportTemplateOptions';
import {
  NO_SUBTOTAL_TEMPLATE,
  PREDEFINED_REPORT_PREFIX,
} from './useReportExcelTemplateLookupSource';
import moment from 'moment';
import { DATE_TIME_UTC_OFFSET_HOURS } from '@/platform';

interface Props {
  onCancel: () => any;
  clientId?: string;
  communityId?: string;
  projectId?: string;
}

interface FormValue extends GenerateCustomReportArgs {
  isBFPAdmin: boolean;
}

const INITIAL_CUSTOM_REPORT: GenerateCustomReportArgs = {
  columns: [],
  filter: {
    clientIds: [],
    communityIds: [],
    endDate: null!,
    startDate: null!,
    homeIds: [],
    projectIds: [],
  },
  options: {
    type: null!,
    homeState: ['Finalized', 'UnFinalized'],
    itemType: ['Standard', 'Upgrade', 'SpecialRequest'],
    location: ['FloorplanItems', 'RoomItems'],
    hasSubcontractorAssigned: false,
    pathStepIds: null!,
    excelTemplateAttachmentId: NO_SUBTOTAL_TEMPLATE,
    subcontractorIds: undefined!,
  },
};

const SCHEMA = Yup.object().shape({
  columns: Yup.array().of(Yup.string().nullable().required()).min(1),
  filter: Yup.object()
    .shape({
      isBFPAdmin: Yup.boolean(),
      clientIds: Yup.array().of(Yup.string().nullable()),
      communityIds: Yup.array().of(Yup.string().nullable()),
      startDate: Yup.date().nullable().required(),
      endDate: Yup.date()
        .when('startDate', (startDate, yup) => {
          if (startDate)
            return yup.min(startDate, {
              key: 'reports_end_date_must_be_after_start_date',
            });
        })
        .nullable()
        .required(),
      projectIds: Yup.array().of(Yup.string().nullable()),
      homeIds: Yup.array().of(Yup.string().nullable()),
    })
    .required(),
  options: Yup.object().shape({
    homeSubTotal: Yup.string().nullable(),
    homeState: Yup.array().of(Yup.string().nullable()),
    location: Yup.array().of(Yup.string().nullable()),
    itemType: Yup.array().of(Yup.string().nullable()),
    pathStepIds: Yup.array().of(Yup.string().nullable().required()),
    hasSubcontractorAssigned: Yup.bool().nullable(),
    subcontractorIds: Yup.array().of(Yup.string().nullable()),
  }),
});

const useSubmit = () => {
  const [generate] = useGenerateCustomReportMutation();

  return (args: GenerateCustomReportArgs) =>
    generate({
      ...args,
      filter: {
        ...args.filter,
        clientIds: isEmpty(args.filter.clientIds)
          ? undefined
          : args.filter.clientIds,
        communityIds: isEmpty(args.filter.communityIds)
          ? undefined
          : args.filter.communityIds,
        projectIds: isEmpty(args.filter.projectIds)
          ? undefined
          : args.filter.projectIds,
        homeIds: isEmpty(args.filter.homeIds) ? undefined : args.filter.homeIds,
        startDate: moment(args.filter.startDate)
          .add(-DATE_TIME_UTC_OFFSET_HOURS, 'hours')
          .toISOString(true),
        endDate: moment(args.filter.endDate)
          .add(1, 'day')
          .add(-DATE_TIME_UTC_OFFSET_HOURS, 'hours')
          .toISOString(true),
      },
      options: {
        ...args.options,
        excelTemplateAttachmentId:
          args.options.excelTemplateAttachmentId?.includes(
            PREDEFINED_REPORT_PREFIX,
          )
            ? undefined
            : args.options.excelTemplateAttachmentId,
        pathStepIds: isEmpty(args.options.pathStepIds)
          ? undefined
          : args.options.pathStepIds,
        subcontractorIds: isEmpty(args.options.subcontractorIds)
          ? undefined
          : args.options.subcontractorIds,
      },
    })
      .unwrap()
      .then((uri) => {
        window.open(
          '/api/report/inline?src=' + encodeURIComponent(uri),
          '_blank',
        );
      });
};

export function GenerateCustomReportPanel(props: Props) {
  const { onCancel, communityId, clientId, projectId } = props;
  const { t } = useTranslation();
  const submit = useSubmit();
  const hasRole = useUserContextSelector((x) => x.hasRole);
  const initialFilters = {
    clientIds: clientId ? [clientId] : undefined,
    communityIds: communityId ? [communityId] : undefined,
    endDate: null!,
    startDate: null!,
    homeIds: [],
    projectIds: projectId ? [projectId] : undefined,
  }

  return (
    <Form.Formik<FormValue>
      uid="reports-custom-details"
      i18n="reports.custom.details"
      initialValues={{
        ...INITIAL_CUSTOM_REPORT,
        isBFPAdmin: hasRole('BFP_ADMIN'),
        filter: initialFilters,
      }}
      validationSchema={SCHEMA}
      onSubmit={submit}
    >
      {({ values }) => (
        <>
          <Typography.Title level={3}>
            {t('reports.custom.title')}
          </Typography.Title>
          <FormCustomReportFilter
            className="pt-4"
            name="filter"
            initialValue={INITIAL_CUSTOM_REPORT.filter}
            clientId={clientId}
            communityId={communityId}
            projectId={projectId}
          />
          <Divider />
          <FormCustomReportTemplateOptions
            className="mb-2"
            name="options.excelTemplateAttachmentId"
          />
          <FormCustomReportColumns
            name="columns"
            className="pt-3"
            excelTemplateAttachmentId={values.options.excelTemplateAttachmentId}
            reportType={values.options.type}
          />
          <FormCustomReportOptions name="options" className="pt-4" />
          <Divider />

          <StyledRow align="middle" className="mt-3">
            <Col flex="auto" className="text-end">
              <Space size="middle">
                <Button onClick={onCancel}>{t('cancel')}</Button>
                <Form.Submit
                  uid="reports-custom-details"
                  type="primary"
                  icon={<FileExcelOutlined />}
                >
                  {t('reports.custom.runReport')}
                </Form.Submit>
              </Space>
            </Col>
          </StyledRow>
        </>
      )}
    </Form.Formik>
  );
}
