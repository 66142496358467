import { AddReportButton } from '@/components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GenerateTrendReportModal } from './GenerateTrendReportModal';

export function GenerateTrendReportButton() {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <AddReportButton onClick={() => setOpenModal(true)}>
        {t('reports.trendReport.title')}
      </AddReportButton>
      {openModal && (
        <GenerateTrendReportModal onClose={() => setOpenModal(false)} />
      )}
    </>
  );
}
