import { useTranslation } from 'react-i18next';

export function YesNo({ children }: { children?: boolean }) {
  const { t } = useTranslation();
  const valueKey = children == null ? 'null' : children.toString();
  return <>{t(`yesNo.${valueKey}`)}</>;
}

export const getYesNoValue = (value: boolean, t: any): string => {
  const valueKey = value.toString();
  return t(`yesNo.${valueKey}`);
};
