import { TableColumnType } from '@/components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ContextMenu, EnumValue } from '@/components';
import { NavLink } from 'react-router-dom';
import {
  FloorplanStatus,
  FloorplanType,
  FloorplanDto,
  CommunityPermissions,
  StringFilterOptions,
} from '@/core';
import { sort, useFiltersFactory } from '@/utils';
import { Assert } from '../Communities.Common';

export function useFloorplansColumns(
  openEdit: (id: string) => any,
  deactivate: (id: string) => any,
  floorplans: FloorplanDto[] | undefined,
  floorplansStringFilterOptions?: StringFilterOptions,
) {
  const { t } = useTranslation();

  const filters = useFiltersFactory(floorplans, floorplansStringFilterOptions);

  return useMemo<TableColumnType<FloorplanDto>[]>(() => {
    const columns: Array<TableColumnType<FloorplanDto> | false> = [
      {
        title: t('floorplans.number'),
        key: 'number',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.number.localeCompare(b.number),
        render: (_, item) => item.number,
        ...filters.select((x) => x.number, 'number'),
      },
      {
        title: t('floorplans.marketingName'),
        key: 'marketingName',
        ...filters.select((x) => x.marketingName, 'marketingName'),
        sorter: (a, b) => a.marketingName.localeCompare(b.marketingName),
        render: (_, { id, marketingName, communityId }) => (
          <NavLink to={`/communities/${communityId}/floorplans/${id}`}>
            {marketingName}
          </NavLink>
        ),
      },
      {
        title: t('floorplans.constructionName'),
        key: 'constructionName',
        ...filters.select((x) => x.constructionName, 'constructionName'),
        sorter: (a, b) => sort(a.constructionName, b.constructionName),
        render: (_, item) => item.constructionName,
      },
      {
        title: t('floorplans.type'),
        key: 'type',
        ...filters.enumSelect((x) => x.type, FloorplanType),
        sorter: (a, b) => a.marketingName.localeCompare(b.marketingName),
        render: (_, item) => (
          <EnumValue type={FloorplanType} value={item.type} />
        ),
      },
      {
        title: t('floorplans.status'),
        key: 'status',
        ...filters.enumSelect((x) => x.status, FloorplanStatus),
        sorter: (a, b) => a.status.localeCompare(b.status),
        render: (_, item) => (
          <EnumValue type={FloorplanStatus} value={item.status} />
        ),
      },
      {
        title: t('floorplans.squareFootage'),
        key: 'squareFootage',
        ...filters.select((x) => x.squareFootage, 'squareFootage'),
        sorter: (a, b) => a.squareFootage - b.squareFootage,
        render: (_, item) => item.squareFootage,
      },
      {
        title: t('floorplans.actions'),
        key: 'actions',
        render: (_, { id, status }) => (
          <Assert permission={CommunityPermissions.Floorplans.Manage} active>
            <ContextMenu
              items={[
                {
                  label: t('edit'),
                  onClick: () => openEdit(id),
                },
                ...(status === FloorplanStatus.Active
                  ? [
                      {
                        label: t('floorplans.action.deactivate'),
                        onClick: () => deactivate(id),
                      },
                    ]
                  : []),
              ]}
            />
          </Assert>
        ),
      },
    ];
    return columns.filter((x) => !!x) as TableColumnType<FloorplanDto>[];
  }, [t, filters, openEdit, deactivate]);
}
