import { useTranslation } from 'react-i18next';
import { Auth, Page, Icon } from '@/components';
import { CommunitiesListPanel, AddCommunityButton } from '@/views';

export function CommunitiesListPage() {
  const { t } = useTranslation();
  const title = t('communities.title');

  return (
    <Page htmlTitle={title}>
      <Page.Paths>
        <Page.Path>{title}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card
          title={title}
          icon={<Icon type="map-pin" />}
          color="#814A93"
          actions={
            <Auth role="BFP_ADMIN">
              <AddCommunityButton />
            </Auth>
          }
        >
          <CommunitiesListPanel showClientName />
        </Page.Card>
      </Page.Body>
    </Page>
  );
}
