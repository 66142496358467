import { ProjectPathStep, ProjectStepLookupDto } from '@/core';

interface StepInfo {
  id: string;
  name: string;
  deadlineDate?: string;
}

const compareByDate = (a: StepInfo, b: StepInfo): number => {
  const timeFn = (value: StepInfo) => new Date(value.deadlineDate!).getTime();

  if (!a.deadlineDate && b.deadlineDate) {
    return 1;
  }
  if (a.deadlineDate && !b.deadlineDate) {
    return -1;
  }

  if (a.deadlineDate && b.deadlineDate) {
    return timeFn(a) - timeFn(b);
  }

  return 0;
};

export function isStepsOrderValid(
  values: ProjectPathStep[],
  steps: ProjectStepLookupDto[],
) {
  const newOrder = values.map((x) => {
    const deadlineDate = steps.find((s) => s.id === x.id)?.deadlineDate;
    return { id: x.id, name: x.name, deadlineDate };
  });

  const mismatchedSteps = newOrder
    .map((value, index, array) => {
      if (index < array.length - 1) {
        const compareResult = compareByDate(value, array[index + 1]);
        if (compareResult > 0) {
          return { firstStep: value, secondStep: array[index + 1] };
        }
      }
      return null;
    })
    .filter((item) => item);

  if (mismatchedSteps.length > 0) {
    const fisrtMismatched = mismatchedSteps[0];
    const firstStepName = fisrtMismatched?.firstStep.name;
    const secondStepName = fisrtMismatched?.secondStep.name;
    return { isValid: false, firstStepName, secondStepName };
  }
  return { isValid: true };
}
