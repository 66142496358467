import { Button, Modal } from 'antd';
import { Form } from '@/components';
import { useTranslation } from 'react-i18next';
import { CopyPricingForm, CopyPricingFormValue } from '@/views';
import { useCallback } from 'react';

interface CopyPricingModalProps {
  currentMenuId: string;
  communityId: string;
  onSubmit: (props: any) => any;
  onClose?: () => any;
}

function useSubmit(props: CopyPricingModalProps) {
  const { onSubmit, currentMenuId } = props;
  return useCallback(
    (data: CopyPricingFormValue) => {
      onSubmit({ fromMenuId: data.menuId, toMenuId: currentMenuId });
    },
    [onSubmit, currentMenuId]
  )
}

export function CopyPricingModal(props: CopyPricingModalProps) {
  const { currentMenuId, communityId, onClose } = props;
  const { t } = useTranslation();
  const onSubmit = useSubmit(props);

  return currentMenuId ?
    <Modal
      title={t('pricing.copyPricing.title')}
      visible
      onCancel={onClose}
      footer={[
        <Button key="back" type="default" onClick={onClose}>
          {t('pricing.copyPricing.close')}
        </Button>,
        <Form.Submit uid="copy-pricing" key="submit" type="primary">
          {t('pricing.copyPricing.submit')}
        </Form.Submit>,
      ]}
    >
      <CopyPricingForm onSubmit={onSubmit} communityId={communityId} currentMenuId={currentMenuId} />
    </Modal> :
    null
}