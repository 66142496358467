import {
  FormsSelect,
  FormsSelectOption,
  FormsSelectProps,
} from './FormsSelect';
import { EnumObject, enumValues, EnumValue } from '@/utils';
import { useMemo } from 'react';
import { useEnumTranslation } from '@/utils/useEnumTranslation';

export type FormsEnumSelectProps<T extends Readonly<string[]>> = Omit<
  FormsSelectProps,
  'options'
> & {
  type: EnumObject<T>;
  values?: EnumValue<T>[];
};

function useOptions<T extends Readonly<string[]>>(
  props: FormsEnumSelectProps<T>,
): FormsSelectOption[] {
  const { values, type } = props;
  const et = useEnumTranslation();

  return useMemo(() => {
    const enumVals = enumValues(type);
    const filtered =
      values != null ? enumVals.filter((x) => values.includes(x)) : enumVals;
    return filtered.map((x) => ({ value: x, label: et(type, x) }));
  }, [et, values, type]);
}

export function FormsEnumSelect<T extends Readonly<string[]>>(
  props: FormsEnumSelectProps<T>,
) {
  const { type, values, ...selectProps } = props;
  const options = useOptions(props);

  return <FormsSelect options={options} {...selectProps} />;
}
