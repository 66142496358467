import { useTranslation } from 'react-i18next';
import {
  Assert,
  CommunityDetailsTabKeys,
  HomeDetailsPanel,
  HomeDetailsTabsPanel,
  HomeMenuLink,
  HomeProjectLink,
} from '@/views';
import { Page, Icon } from '@/components';
import { useGetHomeDetailsQuery } from '@/redux';
import { useParams } from 'react-router-dom';
import { HomeNavigationPanel } from '@/views/Homes.Details/HomeNavigationPanel';
import { CommunityPermissions } from '@/core';
import { Col, Row } from 'antd';
import { HomeFloorplanLink } from '@/views/Homes.Details/HomeFloorplanLink';

const useId = () => {
  const { id } = useParams<'id'>();
  return id!;
};

function _HomeDetailsPage() {
  const { t } = useTranslation();
  const id = useId();
  const { data } = useGetHomeDetailsQuery({ id });
  const title = data?.homeNumber ?? t('fetchFallbackTitle');
  const titleSecondary =
    data?.residents?.map((x) => x.name).join(', ') ?? t('fetchFallbackTitle');

  return (
    <Page htmlTitle={title}>
      <Page.Paths>
        <Page.Path href="/communities">{t('communities.title')}</Page.Path>
        <Page.Path
          href={`/communities/${data?.communityId}#${CommunityDetailsTabKeys.homes}`}
        >
          {data?.communityName ?? t('fetchFallbackTitle')}
        </Page.Path>
        <Page.Path
          href={`/communities/${data?.communityId}#${CommunityDetailsTabKeys.homes}`}
        >
          {t('homes.title')}
        </Page.Path>
        <Page.Path>{title}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <HomeNavigationPanel id={id} className="mb-3" />

        <Page.Card
          collapsible
          defaultCollapsed
          title={title}
          subTitle={titleSecondary}
          actions={
            <Row gutter={[14, 0]}>
              <Col>
                <HomeFloorplanLink
                  homeId={id}
                  communityId={data?.communityId}
                  floorplanId={data?.floorplan?.id}
                  floorplanName={data?.floorplan?.marketingName}
                />
              </Col>
              <Col>
                <HomeMenuLink
                  homeId={id}
                  projectId={data?.projectId}
                  menu={data?.menu}
                  communityId={data?.communityId}
                />
              </Col>
              <Col>
                <HomeProjectLink
                  homeId={id}
                  projectId={data?.projectId}
                  projectName={data?.projectName}
                />
              </Col>
            </Row>
          }
          icon={<Icon type="home" />}
          color="#107CC7"
        >
          <HomeDetailsPanel id={id} />
        </Page.Card>

        <Page.TabCard>
          <HomeDetailsTabsPanel id={id} />
        </Page.TabCard>
      </Page.Body>
    </Page>
  );
}

export const HomeDetailsPage = Assert.Banner(CommunityPermissions.Homes.View)(
  _HomeDetailsPage,
);
