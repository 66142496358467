import {
  CommunityState,
  useChangeStatusCommunityMutation,
  useGetCommunitiesListQuery,
} from '@/redux';
import { useEditCommunityAction } from '../Communities.Details/useEditCommunityAction';
import { useCommunitiesColumns } from './useCommunitiesColumns';
import { CommunitiesFilterParams, CommunityStatus } from '@/core';
import {
  confirmation,
  DefaultNumberSorting,
  notify,
  Table,
  useDefaultTablePaging,
  useFilters,
  useSorting,
} from '@/components';
import { useTranslation } from 'react-i18next';
import { CommunitiesAccessAlert } from './CommunitiesAccessAlert';
import { useMemo } from 'react';

export interface CommunitiesListPanelProps {
  clientId?: string | null | undefined;
  showClientName?: boolean;
}

const useChangeStatusAction = () => {
  const { t } = useTranslation();
  const [changeStatus] = useChangeStatusCommunityMutation();

  return async (id: string, status: CommunityStatus) => {
    if (status === CommunityStatus.Deactivated) {
      confirmation.show({
        title: t('communities.deactivateModalTitle'),
        body: t('communities.deactivateModalBody'),
        onConfirm: async () =>
          await changeStatus({ id, status })
            .unwrap()
            .then(() => notify.success.t(`communities.savedStatus.${status}`)),
      });
    } else {
      await changeStatus({ id, status })
        .unwrap()
        .then(() => notify.success.t(`communities.savedStatus.${status}`));
    }
  };
};

export function CommunitiesListPanel(props: CommunitiesListPanelProps) {
  const { clientId, showClientName } = props;
  const { openEdit, editModal } = useEditCommunityAction();
  const changeStatus = useChangeStatusAction();
  const { setTotal, paging, params } = useDefaultTablePaging();
  const {
    filters,
    onFiltersChange,
    resetFilters,
    stringFilterOptions,
    setStringFilterOptions,
  } = useFilters<CommunitiesFilterParams>(
    ['name', 'number', 'clientName'],
    undefined,
    ['projectCount', 'homeCount'],
  );
  const { sorting, onSortingChange } =
    useSorting<CommunityState>(DefaultNumberSorting);
  const { currentData, isFetching } = useGetCommunitiesListQuery({
    clientId: clientId ?? undefined,
    deactivated: null,
    paging: params,
    filters,
    sorters: sorting,
  });

  const communities = useMemo(() => {
    if (currentData) {
      setStringFilterOptions(currentData.stringFilterOptions);
      setTotal(currentData.total);
    }
    return currentData?.items;
  }, [currentData, setStringFilterOptions, setTotal]);

  const columns = useCommunitiesColumns(
    openEdit,
    changeStatus,
    communities,
    showClientName,
    stringFilterOptions,
  );

  return (
    <div>
      <CommunitiesAccessAlert />
      <Table<CommunityState>
        name="Communities"
        loading={isFetching}
        columns={columns}
        dataSource={communities}
        rowKey={(row) => row.id}
        pagination={paging}
        onChange={(_, filters, sorters) => {
          onFiltersChange(filters);
          onSortingChange(sorters);
        }}
        onFiltersReset={resetFilters}
      />
      {editModal}
    </div>
  );
}
