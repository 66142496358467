import { useTranslation } from 'react-i18next';
import { Address, UsaStates } from '@/core';
import { getEnumValue } from '@/components';

interface Props {
  value: Address;
}
export function AddressValue(props: Props) {
  const { value } = props;
  const { t } = useTranslation();

  const address = [
    value.street,
    value.city,
    getEnumValue(UsaStates, value.state, t),
    value.zipCode,
  ].join(', ');
  return <span>{address}</span>;
}
