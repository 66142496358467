import { DateValue, EditIconButton, TextBox, Title } from '@/components';
import { CommunityPermissions, ProjectHomeStepDeadlineLookupDto } from '@/core';
import { ProjectStepLookupState } from '@/redux';
import { isEmpty, orderBy } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Assert } from '../Communities.Common';

interface Props {
  steps: (ProjectStepLookupState | ProjectHomeStepDeadlineLookupDto)[];
  onEdit?: () => any;
  isActiveProject?: boolean;
}

export const ViewProjectDeadlines = (props: Props) => {
  const { steps: stepsProp, onEdit, isActiveProject } = props;
  const { t } = useTranslation();
  const steps = useMemo(() => orderBy(stepsProp, (x) => x.index), [stepsProp]);

  return (
    <>
      <Title
        actions={
          onEdit &&
          isActiveProject &&
          !isEmpty(steps) && (
            <Assert permission={CommunityPermissions.Path.Manage} active>
              <EditIconButton type="link" onClick={onEdit} />
            </Assert>
          )
        }
        inlineActions
      >
        {t('projects.details.viewDeadlinesTitle')}
      </Title>
      {!isEmpty(steps) &&
        steps.map((step) => (
          <TextBox
            key={step.index}
            label={t('projects.details.deadlineName', { name: step.name })}
          >
            <DateValue value={step.deadlineDate!} />
          </TextBox>
        ))}
    </>
  );
};
