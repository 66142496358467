import {
  AddressValue,
  TextBox,
  EnumValue,
  Attach,
  StyledRow,
  EditIconButton,
  Title,
  useImageBox,
  ImageBox,
} from '@/components';
import { useGetCommunityDetailsQuery, useUserContextSelector } from '@/redux';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditCommunityButton, useCommunityContext } from '@/views';
import { CommunityStatus, useAppClippo } from '@/core';

export function CommunityDetailsPanel(props: { id: string }) {
  const { id } = props;
  const { data } = useGetCommunityDetailsQuery({ id });
  const { t } = useTranslation();
  const { active } = useCommunityContext();
  const hasRole = useUserContextSelector((x) => x.hasRole);

  const canModify = (hasRole('BFP_ADMIN') || hasRole('CLIENT_ADMIN')) && active;

  const clippoProps = useAppClippo({
    defer: false,
    ownerId: id,
    ownerType: 'Community',
  });

  const imageBoxProps = useImageBox({
    defer: false,
    ownerId: id,
    ownerType: 'CommunityImage',
  });

  if (!data) {
    return null;
  }

  const { name, address, status, description, homeCount, clientName } = data;

  return (
    <div>
      <StyledRow>
        <Col span={11}>
          <Title>
            {t('communities.details.general')}

            <Title.Actions inline>
              {canModify && <EditCommunityButton id={id} />}
            </Title.Actions>
          </Title>

          <TextBox label="Name">{name}</TextBox>
          <TextBox label="Address">
            <AddressValue value={address} />
          </TextBox>
          <TextBox label="Status">
            <EnumValue type={CommunityStatus} value={status} />
          </TextBox>
          <TextBox label="Homes">{homeCount}</TextBox>
          <TextBox label="Description">
            <div className="text-multiline">{description}</div>
          </TextBox>
          <TextBox label="Client">{clientName}</TextBox>
        </Col>

        <Col span={12} offset={1} flex="auto">
          <Title
            actions={
              canModify && (
                <EditIconButton
                  type="link"
                  onClick={imageBoxProps.toggleGallery}
                />
              )
            }
            inlineActions
          >
            {t('communities.details.images')}
          </Title>
          <ImageBox {...imageBoxProps} readonly={!canModify} />
        </Col>
      </StyledRow>

      <Title className="mt-4">{t('communities.details.attachments')}</Title>
      <Attach className="mt-3" {...clippoProps} readonly={!canModify} />
    </div>
  );
}
