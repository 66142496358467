import { useTranslation } from 'react-i18next';
import {
  FloorplanPersonalizationPanel,
  ProjectContext,
  useRoomPersonalization,
} from '@/views';
import { Col, Radio, RadioChangeEvent, Row, Space } from 'antd';
import { useState } from 'react';
import { AppstoreOutlined, ExpandOutlined } from '@ant-design/icons';
import styles from './PersonalizationTab.module.scss';
import { useGetProjectContextQuery } from '@/redux';
import classNames from 'classnames';

interface Props {
  homeId: string;
  projectId: string;
  communityId: string;
  className?: string;
}

export function PersonalizationTabs(props: Props) {
  const { className, communityId, homeId, projectId } = props;
  const { t } = useTranslation();
  const [tab, setTab] = useState<string>('floorplan');
  const { data: projectContext } = useGetProjectContextQuery(
    {
      id: projectId,
    },
    { skip: !projectId },
  );

  const onChange = (e: RadioChangeEvent) => {
    setTab(e.target.value);
  };

  const { personalizationSteps, content, personalizationAlert } =
    useRoomPersonalization({
      communityId: communityId,
      projectId: projectId,
      homeId: homeId,
    });
  if (!projectContext) {
    return null;
  }

  return (
    <>
      <Row className={className} justify="space-between">
        <Col className={classNames(tab === 'room' && 'mb-4')}>
          <Radio.Group value={tab} onChange={onChange} className={styles.tab}>
            <Radio.Button value="floorplan">
              <Space size="small">
                <ExpandOutlined />
                {t('personalization.tabs.floorplan')}
              </Space>
            </Radio.Button>
            <Radio.Button value="room">
              <Space size="small">
                <AppstoreOutlined />
                {t('personalization.tabs.rooms')}
              </Space>
            </Radio.Button>
          </Radio.Group>
        </Col>

        {tab === 'room' && <Col span={17}>{personalizationSteps}</Col>}
      </Row>
      {tab === 'floorplan' && (
        <ProjectContext.Provider value={projectContext}>
          <FloorplanPersonalizationPanel
            homeId={homeId}
            projectId={projectId}
            communityId={communityId}
          />
        </ProjectContext.Provider>
      )}
      {tab === 'room' && (
        <ProjectContext.Provider value={projectContext}>
          {personalizationAlert}
          {content}
        </ProjectContext.Provider>
      )}
    </>
  );
}
