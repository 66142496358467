import { Form, useAppFindFormik } from '@/components';
import { useTranslation } from 'react-i18next';
import { useFloorplanLookupSource } from '../Floorplans.Common';
import { useEffect, useMemo, useState } from 'react';
import { useGetFloorplanDetailsQuery } from '@/redux';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';

export const useFloorplanCopyMenu = (name: string) => {
  const [needToCopy, setNeedToCopy] = useState<boolean>(false);
  const [floorplanId, setFloorplanId] = useState<string>();

  const { data: floorplan } = useGetFloorplanDetailsQuery({
    id: floorplanId ?? '',
  });

  const formik = useAppFindFormik(name);
  const copyFormik = useAppFindFormik('floorplan-copy');

  function resetCopyFields() {
    formik.current?.resetForm();
    formik.current?.setValues({
      ...formik.current.values,
      marketingName: null,
      constructionName: null,
      type: null,
      squareFootage: null,
      description: null,
      exampleFloorplanId: null,
    });

    if (!needToCopy) {
      copyFormik.current?.setFieldValue('floorplanId', undefined);
    }
  }

  useEffect(() => {
    resetCopyFields();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik, needToCopy]);

  return useMemo(
    () => ({
      needToCopy,
      setNeedToCopy,
      floorplanId,
      setFloorplanId,
      floorplan,
      resetCopyFields,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [floorplan, floorplanId, needToCopy],
  );
};

type FloorplanCopy = OmitIndex<Yup.InferType<typeof schema>>;

interface FloorplanCopyMenuFormProps {
  communityId: string;
  needToCopy: boolean;
  setNeedToCopy: (value: boolean) => void;
  floorplanId: string | undefined;
  setFloorplanId: (value?: string) => void;
}

const schema = Yup.object().shape({
  needToCopy: Yup.boolean().nullable().required(),
  floorplanId: Yup.string().when('needToCopy', {
    is: true,
    then: Yup.string().field().nullable().required(),
    otherwise: Yup.string().field().nullable().notRequired(),
  }),
});

export function FloorplanCopyMenuForm(props: FloorplanCopyMenuFormProps) {
  const { needToCopy, floorplanId } = props;

  return (
    <Form.Formik<FloorplanCopy>
      uid="floorplan-copy"
      initialValues={{ needToCopy, floorplanId }}
      onSubmit={() => {}}
      validateOnChange
      enableReinitialize
      i18n="floorplans.details"
    >
      <FloorplanCopyContent {...props} />
    </Form.Formik>
  );
}

function FloorplanCopyContent(props: FloorplanCopyMenuFormProps) {
  const { communityId, needToCopy, setNeedToCopy, setFloorplanId } = props;
  const floorplans = useFloorplanLookupSource(communityId);
  const { t } = useTranslation(undefined, { keyPrefix: 'floorplans.details' });

  const { values } = useFormikContext<FloorplanCopy>();

  useEffect(() => {
    setNeedToCopy(values.needToCopy);
    setFloorplanId(values.floorplanId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <>
      <Form.Radio
        name="needToCopy"
        options={[
          { value: true, label: t('yes') },
          { value: false, label: t('no') },
        ]}
        inline={false}
      />
      {needToCopy && (
        <Form.AsyncSelect
          name="floorplanId"
          dataSource={floorplans}
          required
          preload
        />
      )}
    </>
  );
}
