import { RoomCategory } from '@/core';
import { Col, Divider, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { RoomCategoryFootageItem } from './RoomCategoryFootageItem';
import { useRoomCategoryTreeData } from './useRoomCategoryTreeData';

interface Props {
  communityId: string;
  categories: RoomCategory[];
}

export function RoomCategoryFootageList(props: Props) {
  const { communityId, categories } = props;
  const { t } = useTranslation();
  const treeData = useRoomCategoryTreeData(
    communityId,
    categories,
    true,
    categories,
  );

  return (
    <div>
      <Row>
        <Col span={12}>{t('rooms.categorySelected')}</Col>
        <Col span={12}>{t('rooms.squareFootage')}</Col>
      </Row>
      <Divider className="mt-1" />
      {treeData.map((node) => (
        <RoomCategoryFootageItem
          id={node.key as string}
          treeData={treeData}
          categories={categories}
        />
      ))}
    </div>
  );
}
