import { rtkq, RtkqRequest } from '../rtkq';
import {
  AddItemBookletLinkDto,
  AddItemExternalLinkDto,
  communitiesHttp,
  CreateItemDto,
  DeleteItemBookletLinkDto,
  DeleteItemExternalLinkDto,
  ItemDetailsDto,
  ItemDto,
  UpdateItemDto,
  UploadItemsDto,
} from '@/core';
import { api } from '../api';

export type ItemState = ItemDto;
export type ItemDetailsState = ItemDetailsDto;
export type CreateItemArgs = CreateItemDto;
export type UpdateItemArgs = UpdateItemDto;
export type UploadItemsArgs = UploadItemsDto;

export const itemsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getItemsList: build.query<
      ItemState[],
      RtkqRequest<{
        communityId: string;
        isGeo?: boolean;
        selectedItems?: ItemState[];
      }>
    >({
      queryFn: async (args) => {
        return rtkq(args).exec(() =>
          communitiesHttp.getAllItems(args.communityId, args?.isGeo),
        );
      },
      providesTags: (result = []) => [
        { type: 'item', id: 'list' },
        ...result.map(({ id }) => ({ type: 'item' as const, id })),
        ...result.flatMap((item) =>
          item.bookletLinks.map(({ attachmentId }) => ({
            type: 'booklet-link-attachment-id' as const,
            id: attachmentId,
          })),
        ),
      ],
    }),

    getItemDetails: build.query<ItemState, RtkqRequest<{ id: string }>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => communitiesHttp.getItem(args.id, null));
      },
      providesTags: (item) => [
        { type: 'item', id: item?.id ?? 'none' },
        ...(item?.bookletLinks.map(({ attachmentId }) => ({
          type: 'booklet-link-attachment-id' as const,
          id: attachmentId,
        })) ?? []),
      ],
    }),

    addItem: build.mutation<string, RtkqRequest<CreateItemArgs>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => communitiesHttp.createItem(args));
      },
      invalidatesTags: () => [{ type: 'item', id: 'list' }],
    }),

    updateItem: build.mutation<void, RtkqRequest<UpdateItemArgs>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => communitiesHttp.updateItem(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'item', id: 'list' },
        { type: 'item', id: args.id },
        { type: 'personalizationItem-category', id: args.categoryId },
      ],
    }),

    deleteItem: build.mutation<void, RtkqRequest<{ id: string }>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => communitiesHttp.unlistItem(args.id));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'item', id: 'list' },
        { type: 'item', id: args.id },
      ],
    }),

    addItemBookletLink: build.mutation<
      void,
      RtkqRequest<AddItemBookletLinkDto>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() => communitiesHttp.addBookletLink(args));
      },
      invalidatesTags: (_, __, args) => [{ type: 'item', id: args.itemId }],
    }),

    deleteItemBookletLink: build.mutation<
      void,
      RtkqRequest<DeleteItemBookletLinkDto>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() => communitiesHttp.deleteBookletLink(args));
      },
      invalidatesTags: (_, __, args) => [{ type: 'item', id: args.itemId }],
    }),

    addItemExternalLink: build.mutation<
      void,
      RtkqRequest<AddItemExternalLinkDto>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() => communitiesHttp.addExternalLink(args));
      },
      invalidatesTags: (_, __, args) => [{ type: 'item', id: args.itemId }],
    }),

    deleteItemExternalLink: build.mutation<
      void,
      RtkqRequest<DeleteItemExternalLinkDto>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() => communitiesHttp.deleteExternalLink(args));
      },
      invalidatesTags: (_, __, args) => [{ type: 'item', id: args.itemId }],
    }),

    uploadItems: build.mutation<void, RtkqRequest<UploadItemsArgs>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => communitiesHttp.uploadItems(args));
      },
    }),
  }),
});

export const {
  useGetItemsListQuery,
  useGetItemDetailsQuery,
  useLazyGetItemDetailsQuery,
  useAddItemMutation,
  useUpdateItemMutation,
  useDeleteItemMutation,
  useAddItemBookletLinkMutation,
  useDeleteItemBookletLinkMutation,
  useUploadItemsMutation,
  useAddItemExternalLinkMutation,
  useDeleteItemExternalLinkMutation,
} = itemsApi;
