import { Form } from '@/components';
import { useCommunityLookupSource } from '@/views/Communities.Common';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
}

export function ActivityCommunitySelect(props: Props) {
  const { name } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'activity.table.filter.placeholders',
  });
  const communityDataSource = useCommunityLookupSource(false);

  return (
    <Form.AsyncSelect
      dataSource={communityDataSource}
      label={false}
      name={name}
      placeholder={t('community')}
      mode="multiple"
    />
  );
}
