import {
  PersonalizationItemState,
  ProjectStepLookupState,
  CategoryState,
  useGetPersonalizationItemsQuery,
  useGetSpecialRequestsQuery,
  SpecialRequestState,
  useGetExistingItemsQuery,
} from '@/redux';
import { array } from '@/utils';
import { Col, Collapse, Row, Typography } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { RoomPersonalizationItems } from './RoomPersonalizationItemList';
import { RoomPersonalizationItemsAddedCount } from './RoomPersonalizationItemsAddedCount';
import { CategorySpecialRequestList } from '@/views';
import { ExistingItemDto, SpecialRequestStatus } from '@/core';
import styles from './RoomPersonalization.module.scss';

interface Props {
  category: CategoryState;
  homeId: string;
  projectId: string;
  step?: ProjectStepLookupState;
  roomId?: string;
  collapsed: boolean;
  readonly?: boolean;
  isRoot?: boolean;
  hasItemsInTree?: boolean;
  isKeepExisting?: boolean;
  compact?: boolean;
}

const { Panel } = Collapse;
const { Text } = Typography;

function useCount(props: Props) {
  const { homeId, projectId, step, roomId, category } = props;
  const categoryId = category.id;

  const { data = array.empty<PersonalizationItemState>(), isLoading } =
    useGetPersonalizationItemsQuery({
      homeId,
      projectId,
      stepId: step?.id,
      roomId,
      isFloorplan: false,
      wholeHome: !roomId,
    });

  return useMemo(
    () => ({
      count: data.filter((x) => x.category.category.id === categoryId).length,
      isLoading,
    }),
    [data, isLoading, categoryId],
  );
}

function useSpecialRequestsCount(props: Props) {
  const { homeId, projectId, step, roomId, category } = props;
  const categoryId = category.id;

  const { data = array.empty<SpecialRequestState>(), isLoading } =
    useGetSpecialRequestsQuery({
      projectId: projectId,
      homeId: homeId,
      stepId: step?.id,
      isFloorplan: false,
      wholeHome: !roomId,
      roomId: roomId,
      asItem: true,
    });

  return useMemo(
    () => ({
      specialRequestCount: data.filter(
        (x) =>
          x.category.category.id === categoryId &&
          x.status !== SpecialRequestStatus.Denied,
      ).length,
      specialRequestLoading: isLoading,
    }),
    [data, isLoading, categoryId],
  );
}

function useIsKeepExisting(props: Props) {
  const { homeId, projectId, step, roomId, category } = props;
  const categoryId = category.id;

  const { data = array.empty<ExistingItemDto>(), isLoading } =
    useGetExistingItemsQuery({
      homeId,
      projectId,
      stepId: step?.id,
      roomId,
    });

  return useMemo(
    () => ({
      keepExistingCount: data.filter(
        (x) => x.category.category.id === categoryId,
      ).length,
      isKeepsExistingLoading: isLoading,
      existInCurrentCategory: !!data.find(
        (x) => x.category.category.id === categoryId,
      ),
    }),
    [data, isLoading, categoryId],
  );
}

export function RoomPersonalizationCategory(props: Props) {
  const {
    category,
    homeId,
    projectId,
    step,
    roomId,
    collapsed,
    readonly,
    isRoot = true,
    compact,
  } = props;

  const [openItems, setOpenItems] = useState<string[]>([]);
  const { count, isLoading } = useCount(props);
  const { specialRequestCount, specialRequestLoading } =
    useSpecialRequestsCount(props);
  const { keepExistingCount, isKeepsExistingLoading, existInCurrentCategory } =
    useIsKeepExisting(props);

  const hasItemsInTree = isRoot
    ? count + specialRequestCount + keepExistingCount > 0
    : props.hasItemsInTree! || specialRequestCount > 0;

  useEffect(() => {
    if (collapsed) {
      setOpenItems([]);
    } else {
      setOpenItems([category.id]);
    }
  }, [collapsed, category.id]);

  const onChange = (key: string | string[]) => {
    setOpenItems(key as string[]);
  };

  return (
    <Collapse
      ghost
      className={styles.collapse}
      activeKey={openItems}
      onChange={onChange}
      expandIconPosition="right"
    >
      <Panel
        key={category.id}
        header={
          <Row justify="space-between">
            <Col span={16}>
              <Text strong className={styles.name}>
                {category.name}
              </Text>
            </Col>
            <Col span={8}>
              {!isLoading &&
                !specialRequestLoading &&
                !isKeepsExistingLoading && (
                  <RoomPersonalizationItemsAddedCount
                    count={count + specialRequestCount + keepExistingCount}
                    showNoSelectionsWarning={!hasItemsInTree}
                    showKeepExisting={existInCurrentCategory}
                  />
                )}
            </Col>
          </Row>
        }
      >
        <RoomPersonalizationItems {...props} step={step} compact={compact} />
        <CategorySpecialRequestList
          categoryId={category.id}
          projectId={projectId}
          homeId={homeId}
          roomId={roomId}
          stepId={step?.id}
          readonly={readonly}
        />
      </Panel>
    </Collapse>
  );
}
