import {
  DefaultNumberSorting,
  EnumValue,
  Table,
  useDefaultTablePaging,
  useFilters,
  useSorting,
} from '@/components';
import {
  useGetCommunityAccessesQuery,
  useGetUsersQuery,
  UserCommunityAccessDetails,
  UserState,
} from '@/redux';
import {
  useEditUserActionBase,
  UseEditUserActionBaseArgs,
} from './useEditUsersAction';
import { useCallback, useMemo } from 'react';
import { useFiltersFactory } from '@/utils';
import { keyBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useUsersColumns } from './useUsersColumns';
import { Role, StringFilterOptions, UserFilterParams } from '@/core';
import { Alert } from 'antd';
import i18next from 'i18next';
import { TableColumnType } from '@/components';

interface Props {
  organizationId?: string;
  includeUnlisted?: boolean;
  showClientName?: boolean;
}

function useClientUsers({ organizationId }: Props) {
  const { setTotal, paging, params } = useDefaultTablePaging();
  const {
    filters,
    onFiltersChange,
    resetFilters,
    stringFilterOptions,
    setStringFilterOptions,
  } = useFilters<UserFilterParams>([
    'number',
    'client',
    'name',
    'email',
    'communitiesFilter',
  ]);
  const { sorting, onSortingChange } =
    useSorting<ClientUserListItemType>(DefaultNumberSorting);

  const { currentData, isFetching: isUsersLoading } = useGetUsersQuery({
    organizationId,
    onlyClient: true,
    paging: params,
    filters,
    sorting,
  });

  const users = useMemo(() => {
    if (currentData) {
      setTotal(currentData?.total);
      setStringFilterOptions(currentData?.stringFilterOptions);
    }

    return currentData?.items;
  }, [currentData, setStringFilterOptions, setTotal]);

  const { currentData: accesses, isFetching: isAccessesLoading } =
    useGetCommunityAccessesQuery(
      { userIds: users?.map((x) => x.id)! },
      { skip: !users },
    );

  const isLoading = isUsersLoading || isAccessesLoading;

  const data = useMemo<ClientUserListItemType[]>(() => {
    if (isUsersLoading || isAccessesLoading) {
      return [];
    }

    const accessByUserId = keyBy(accesses, (x) => x.userId);
    return users!.map((x) => ({ ...x, access: accessByUserId[x.id] }));
  }, [users, accesses, isUsersLoading, isAccessesLoading]);

  return {
    data,
    stringFilterOptions,
    isLoading,
    paging,
    onFiltersChange,
    onSortingChange,
    resetFilters,
  };
}

export type ClientUserListItemType = UserState & {
  access: UserCommunityAccessDetails;
};

function useCommunityText() {
  const { t } = useTranslation();

  return useCallback(
    (item: ClientUserListItemType) => {
      return item.access.unrestricted
        ? t('all')
        : item.access.restrictedTo
            .map((x) => x.communityName)
            .sort()
            .join(', ');
    },
    [t],
  );
}

export function useClientUsersColumns(
  users: ClientUserListItemType[],
  openEdit: (id: string) => any,
  showClientName?: boolean,
  stringFilteOptions?: StringFilterOptions,
) {
  const { t } = useTranslation();
  const columns = useUsersColumns<ClientUserListItemType>(
    users,
    openEdit,
    stringFilteOptions,
  );
  const filters = useFiltersFactory(users, stringFilteOptions);
  const communityText = useCommunityText();

  return useMemo(() => {
    const roleColumn: TableColumnType<ClientUserListItemType> = {
      title: t('users.role'),
      key: 'role',
      sorter: (a, b) => a.role.localeCompare(b.role),
      ...filters.enumSelect((x) => x.role, Role, [
        'CLIENT_ADMIN',
        'CLIENT_USER',
      ]),
      render: (_, record) => <EnumValue type={Role} value={record.role} />,
      width: 100,
      dataIndex: 'role',
    };

    const clientNameColumn: TableColumnType<ClientUserListItemType> = {
      title: t('users.client'),
      key: 'client',
      width: 130,
      ...filters.select((x) => x.clientName, 'client'),
      sorter: (a, b) => (a.clientName || '').localeCompare(b.clientName || ''),
      render: (_, record) => record.clientName,
    };

    const communitiesColumn: TableColumnType<ClientUserListItemType> = {
      title: t('users.communities'),
      key: 'communities',
      sorter: (a, b) => communityText(a).localeCompare(communityText(b)),
      ...filters.select(
        (x) => communityText(x),
        'communitiesFilter',
        'include',
        'All',
      ),
      render: (_, item) => communityText(item),
    };

    const result = [...columns];

    if (showClientName) {
      result.splice(
        result.findIndex((x) => x.key === 'phone'),
        0,
        roleColumn,
        clientNameColumn,
        communitiesColumn,
      );
    } else {
      result.splice(
        result.findIndex((x) => x.key === 'phone'),
        0,
        roleColumn,
        communitiesColumn,
      );
    }

    return result;
  }, [t, filters, columns, showClientName, communityText]);
}

const EDIT_ACTION_ARGS: UseEditUserActionBaseArgs = {
  roles: ['CLIENT_ADMIN', 'CLIENT_USER'],
  noRoleSelect: false,
  formFooter: (initial, value) => {
    if (initial.role !== 'CLIENT_ADMIN' || value.role === initial.role)
      return null;

    return (
      <Alert description={i18next.t<string>('users.adminToUserEditTip')} />
    );
  },
};

export function ClientUsersListPanel(props: Props) {
  const { editModal, openEdit } = useEditUserActionBase(EDIT_ACTION_ARGS);
  const {
    data,
    isLoading,
    paging,
    stringFilterOptions,
    onFiltersChange,
    onSortingChange,
    resetFilters,
  } = useClientUsers(props);
  const { showClientName } = props;
  const columns = useClientUsersColumns(
    data,
    openEdit,
    showClientName,
    stringFilterOptions,
  );

  return (
    <div>
      <Table<ClientUserListItemType>
        name="Users"
        loading={isLoading}
        columns={columns}
        dataSource={data}
        rowKey={(row) => row.id}
        pagination={paging}
        onChange={(_, filters, sorting) => {
          onFiltersChange(filters);
          onSortingChange(sorting);
        }}
        onFiltersReset={resetFilters}
      />
      {editModal}
    </div>
  );
}
