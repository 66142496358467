import { formatters } from '@/core';
import { TaskFinancialInfoState } from '@/redux';
import { Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { sumBy } from 'lodash';
import React from 'react';

export interface TaskBalanceSummaryTableProps {
  className?: string;
  tasks: TaskFinancialInfoState[] | undefined;
  selected: TaskFinancialInfoState[] | undefined;
}

const calcBalance = (tasks?: TaskFinancialInfoState[]) => {
  const estimated = tasks ? sumBy(tasks, (x) => x.estimatedCost ?? 0) : 0;
  const actual = tasks ? sumBy(tasks, (x) => x.actualCost ?? 0) : 0;
  const balance = estimated - actual;

  return { estimated, actual, balance };
};

function useColumns(
  tasks?: TaskFinancialInfoState[],
  selected?: TaskFinancialInfoState[],
): ColumnType<RowType>[] {
  if (!tasks || !selected) {
    return [];
  }

  const totalBalance = calcBalance(tasks);
  const selectedBalance = calcBalance(selected);
  const { price } = formatters;

  function createRender(selected: React.ReactNode, total: React.ReactNode) {
    return (_1: any, row: RowType) =>
      row.type === 'selected' ? selected : total;
  }

  const columns: ColumnType<RowType>[] = [
    {
      title: 'Totals',
      key: 'totals',
      render: createRender('Selected', 'Total'),
    },
    {
      title: <div className="text-end">Number of records</div>,
      key: 'number',
      className: 'text-end',
      render: createRender(selected.length, tasks.length),
    },
    {
      title: <div className="text-end">Cost estimate</div>,
      key: 'estimate',
      className: 'text-end',
      render: createRender(
        price(selectedBalance.estimated),
        price(totalBalance.estimated),
      ),
    },
    {
      title: <div className="text-end">Actual cost</div>,
      key: 'actual',
      className: 'text-end',
      render: createRender(
        price(selectedBalance.actual),
        price(totalBalance.actual),
      ),
    },
    {
      title: <div className="text-end">Budget balance</div>,
      key: 'balance',
      className: 'text-end',
      render: createRender(
        price(selectedBalance.balance),
        price(totalBalance.balance),
      ),
    },
  ];

  return columns;
}

type RowType = { type: 'selected' | 'total' };
const ROWS: RowType[] = [{ type: 'selected' }, { type: 'total' }];

export function TaskBalanceSummaryTable(props: TaskBalanceSummaryTableProps) {
  const { tasks, selected, className } = props;
  const columns = useColumns(tasks, selected);

  if (!tasks) {
    return null;
  }

  return (
    <Table
      className={className}
      dataSource={ROWS}
      columns={columns}
      pagination={false}
    />
  );
}
