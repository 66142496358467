import {
  UpdateProjectPathArgs,
  useGetProjectPathQuery,
  useGetProjectStepsQuery,
  useUpdateProjectPathMutation,
} from '@/redux';
import { confirmation, notify } from '@/components';
import { FormPathTemplate } from '@/views/PathTemplates.Details/Form';
import { useProjectContext } from '..';
import { useTranslation } from 'react-i18next';
import { isStepsOrderValid } from './isStepsOrderValid';

interface Props {
  id: string;
  onSaved?: () => any;
}

const useSubmit = (props: Props) => {
  const { data: steps } = useGetProjectStepsQuery({ ids: props.id });
  const [update] = useUpdateProjectPathMutation();
  const { t } = useTranslation();
  const handleSave = async (values: UpdateProjectPathArgs) => {
    await update(values)
      .unwrap()
      .then(props.onSaved)
      .then(() => notify.success.t('pathTemplates.details.saved'));
  };

  return async (values: UpdateProjectPathArgs) => {
    const { isValid, firstStepName, secondStepName } = isStepsOrderValid(
      values.steps,
      steps || [],
    );

    if (!isValid) {
      confirmation.show({
        title: t('pathTemplates.details.title'),
        body: t('pathTemplates.details.body', {
          firstStepName,
          secondStepName,
        }),
        onConfirm: async () => await handleSave(values),
      });
    } else {
      await handleSave(values);
    }
  };
};

export function EditProjectPathPanel(props: Props) {
  const submit = useSubmit(props);
  const { id, onSaved } = props;
  const { currentData } = useGetProjectPathQuery({ id });
  const { hasStatus, communityId } = useProjectContext();

  if (!currentData) {
    return null;
  }

  return (
    <FormPathTemplate<UpdateProjectPathArgs>
      onSubmit={submit}
      value={currentData}
      noTemplate
      onCancel={onSaved}
      freezeExisting={!hasStatus(['NotStarted', 'InProgress'])}
      communityId={communityId}
    />
  );
}
