import {
  LookupSource,
  LookupOption,
  createEntireLoadLookupSource,
  EMPTY_LOOKUP_SOURCE,
} from '@/components';
import { DEFAULT_PAGING } from '@/core';
import {
  RtkqSpin,
  useLazyGetClientsListQuery,
  useUserContextSelector,
} from '@/redux';
import { useMemo } from 'react';

export function mapClientLookup(value: {
  name: string;
  id: string;
}): LookupOption {
  return {
    label: value.name,
    value: value.id,
  };
}

export function useClientLookupSource(): LookupSource {
  const [fetchList] = useLazyGetClientsListQuery();
  const isBfpAdmin = useUserContextSelector((x) => x.isBFP);
  return useMemo(() => {
    if (!isBfpAdmin) {
      return EMPTY_LOOKUP_SOURCE;
    }

    return createEntireLoadLookupSource({
      initial: (id: string) =>
        fetchList({ paging: DEFAULT_PAGING, [RtkqSpin]: false }, true)
          .unwrap()
          .then((values) =>
            mapClientLookup(values.items.find((x) => x.id === id)!),
          ),
      load: () =>
        fetchList({ paging: DEFAULT_PAGING, [RtkqSpin]: false }, true)
          .unwrap()
          .then((values) => values.items.map(mapClientLookup)),
    });
  }, [fetchList, isBfpAdmin]);
}
