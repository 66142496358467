import { Form } from '@/components';
import { RoomState } from '@/core';
import { useGetRoomsListQuery } from '@/redux';
import { array } from '@/utils';
import { useField, useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';

interface Props {
  i18n: string;
  communityId?: string;
  categoriesFieldName: string;
  roomTypeFieldName: string;
  setRoomTypeId: (id: string) => void;
  setRoomTypeName: (name: string) => void;
}

function useSources({ communityId }: Props) {
  const { data: rooms = array.empty<RoomState>() } = useGetRoomsListQuery({
    communityId,
  });

  return rooms;
}

function useOptions(props: Props) {
  const rooms = useSources(props);

  return useMemo(
    () =>
      rooms.map((room) => ({
        value: room.id,
        label: room.floorplanName
          ? `${room.floorplanName} / ${room.name}`
          : room.name,
      })),
    [rooms],
  );
}

function useHandleSourceTemplateIdChange(props: Props) {
  const { categoriesFieldName, setRoomTypeId, setRoomTypeName } = props;
  const sources = useSources(props);
  const [, , { setValue: setCategoriesValue }] = useField(categoriesFieldName);
  const [, , { setValue: setRoomTypeValue }] = useField('roomTypeId');

  return useCallback(
    (_: any, newValue: string | null) => {
      if (!newValue) return;

      const source = sources!.find((x) => x.id === newValue)!;

      setCategoriesValue(
        source.categories.map((category) => ({
          id: category.id,
          implicit: category.implicit,
        })),
      );
      setRoomTypeId(source.roomTypeId);
      setRoomTypeValue(source.roomTypeId);
      setRoomTypeName(source.roomTypeName);
    },
    [
      sources,
      setCategoriesValue,
      setRoomTypeName,
      setRoomTypeId,
      setRoomTypeValue,
    ],
  );
}

function useHandleUseTemplateChange() {
  const { resetForm } = useFormikContext();
  return useCallback(
    (_: any, newValue: boolean) => {
      if (!newValue) resetForm();
    },
    [resetForm],
  );
}

export function FormRoomSource(props: Props) {
  const options = useOptions(props);
  const handleSourceTemplateIdChange = useHandleSourceTemplateIdChange(props);
  const handleUseTemplateChange = useHandleUseTemplateChange();
  const [{ value: useTemplate }] = useField<boolean>('useTemplate');

  return (
    <>
      <Form.FieldChange
        name="sourceTemplateId"
        onChange={handleSourceTemplateIdChange}
      />

      <Form.FieldChange name="useTemplate" onChange={handleUseTemplateChange} />

      <Form.YesNo name="useTemplate" />

      {useTemplate && (
        <Form.Select name="sourceTemplateId" options={options} required />
      )}
    </>
  );
}
