import { TabPanel } from '@/components';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { PersonalizationHistorySelect } from '../Personalization.Details/PersonalizationHistorySelect';
import {
  PersonalizationHistoryState,
  useGetProjectContextQuery,
} from '@/redux';
import { PersonalizationSummaryContent } from './PersonalizationSummaryContent';
import { ProjectContext } from '../Projects.Common';
import { useState } from 'react';

interface Props {
  homeId: string;
  personalizationHistory: PersonalizationHistoryState[];
  communityId: string;
}

export function PersonalizationSummaryTabContent(props: Props) {
  const { homeId, personalizationHistory, communityId } = props;
  const { t } = useTranslation();

  const [selectedProjectId, setSelectedProjectId] = useState<string>(
    personalizationHistory.at(0)?.projectId!,
  );

  const { data: projectContext } = useGetProjectContextQuery({
    id: selectedProjectId,
  });

  if (!projectContext) {
    return null;
  }

  return (
    <TabPanel>
      <TabPanel.Title>{t('personalization.summary.title')}</TabPanel.Title>
      <TabPanel.Body>
        <Space direction="vertical" size="middle" className="w-100">
          <PersonalizationHistorySelect
            onSelect={setSelectedProjectId}
            selected={selectedProjectId}
            value={personalizationHistory}
          />
          <ProjectContext.Provider value={projectContext}>
            <PersonalizationSummaryContent
              communityId={communityId}
              homeId={homeId}
              projectId={selectedProjectId}
              key={selectedProjectId}
            />
          </ProjectContext.Provider>
        </Space>
      </TabPanel.Body>
    </TabPanel>
  );
}
