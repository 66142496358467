import { Table, TabPanel } from '@/components';
import { CategoryState, ItemState } from '@/redux';
import { useTranslation } from 'react-i18next';
import { useItemListGridDataSource } from './useItemListGridDataSource';
import { useItemsColumns } from './useItemsColumns';

interface Props {
  communityId: string;
}

export interface ItemTableRowValues {
  categories: CategoryState[];
  item?: ItemState;
}

export function ItemListTabPanelGridView(props: Props) {
  const { t } = useTranslation();
  const { items, loading } = useItemListGridDataSource(props);

  const columns = useItemsColumns();

  return (
    <TabPanel min="auto">
      <TabPanel.Title>{t('items.title')}</TabPanel.Title>
      <TabPanel.Body>
        <Table<ItemTableRowValues>
          name="ItemsAndCategories"
          loading={loading}
          columns={columns}
          dataSource={items}
          rowKey={(row) => row.categories.at(-1)!.id + row.item?.id}
        />
      </TabPanel.Body>
    </TabPanel>
  );
}
