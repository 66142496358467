import { Form } from '@/components';
import { Typography } from 'antd';
import { useField, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSubcontractorLookupSource } from '..';

interface Props {
  name: string;
  communityIds?: string[];
}

export function AdditionalOptionLimitBySubcontractorSection(props: Props) {
  const { name, communityIds } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'reports.custom.details.options',
  });

  const dataSource = useSubcontractorLookupSource(communityIds, true);
  const [{ value: hasSubcontractorAssigned }] = useField<boolean>(
    `${name}.hasSubcontractorAssigned`,
  );

  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!hasSubcontractorAssigned)
      setFieldValue(`${name}.subcontractorIds`, undefined, false);
  }, [setFieldValue, hasSubcontractorAssigned, name]);

  return (
    <div>
      <Typography.Title level={5} className="pb-3">
        {t('limitItems')}
      </Typography.Title>
      <Form.YesNo
        name={`${name}.hasSubcontractorAssigned`}
        label={false}
        direction="vertical"
      />
      {hasSubcontractorAssigned && (
        <Form.AsyncSelect
          key={communityIds?.join()}
          label={false}
          dataSource={dataSource}
          name={`${name}.subcontractorIds`}
          placeholder={t('placeholders.subcontractorIds')}
          mode="multiple"
        />
      )}
    </div>
  );
}
