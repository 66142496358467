import {
  Attach,
  confirmation,
  DateTimeValue,
  EditIconButton,
  EnumValue,
  notify,
  TextBox,
  Title,
  YesNo,
} from '@/components';
import { useTranslation } from 'react-i18next';
import { ProjectStatus, useAppClippo, CommunityPermissions } from '@/core';
import { useGetProjectQuery, useUpdateProjectStatusMutation } from '@/redux';
import {
  useCommunityContext,
  useEditProjectModal,
  useProjectContext,
} from '@/views';
import { ProjectStatusControl } from './ProjectStatusControl';

interface Props {
  id: string;
}

export function ProjectDetailsPanel(props: Props) {
  const { id } = props;
  const { t } = useTranslation(undefined, { keyPrefix: 'projects.details' });
  const { data } = useGetProjectQuery({ id });
  const { editProjectModal, showEdit } = useEditProjectModal();
  const { assert } = useCommunityContext();
  const { isActive } = useProjectContext();

  const attach = useAppClippo({
    ownerId: id,
    ownerType: 'Project',
    defer: false,
  });

  const [updateStatus] = useUpdateProjectStatusMutation();

  const handleStatusChanged = (status: ProjectStatus) => {
    if (
      status === ProjectStatus.Canceled ||
      status === ProjectStatus.Completed
    ) {
      confirmation.show({
        title: t(`changeStatusConfirmation.title.${status}`),
        body: t(`changeStatusConfirmation.body.${status}`),
        onConfirm: () =>
          updateStatus({ id, status })
            .unwrap()
            .then(() =>
              notify.success.t(`projects.details.savedStatus.${status}`),
            ),
      });
    } else
      updateStatus({ id, status })
        .unwrap()
        .then(() => notify.success.t(`projects.details.savedStatus.${status}`));
  };

  const readonly =
    !assert(CommunityPermissions.Projects.Manage, true) || !isActive;

  if (!data) {
    return null;
  }

  const {
    number,
    createdAt,
    communityName,
    pmLiteEnabled,
    description,
    homeIds,
  } = data!;

  return (
    <>
      <Title
        actions={!readonly && <EditIconButton onClick={() => showEdit(id)} />}
        inlineActions
      >
        {t('generalInfo')}
      </Title>

      <TextBox label={t('number')}>{number}</TextBox>
      <TextBox label={t('createdAt')}>
        <DateTimeValue format="date" value={createdAt} />
      </TextBox>
      <TextBox label={t('status')}>
        {readonly && <EnumValue type={ProjectStatus} value={data.status} />}
        {!readonly && (
          <ProjectStatusControl project={data} onChange={handleStatusChanged} />
        )}
      </TextBox>
      <TextBox label={t('community')}>{communityName}</TextBox>
      <TextBox label={t('homes')}>{homeIds.length}</TextBox>
      <TextBox label={t('pmLiteEnabled_view')}>
        <YesNo>{pmLiteEnabled}</YesNo>
      </TextBox>
      <TextBox label={t('description')}>
        <div className="text-multiline">{description}</div>
      </TextBox>

      <Title>{t('attachmentsTitle')}</Title>
      <Attach {...attach} readonly={readonly} />

      {editProjectModal}
    </>
  );
}
