import { AxiosResponse } from 'axios';

export interface DownloadedFile {
  blob: Blob;
  fileName: string;
}

export function asFileResponse(response: AxiosResponse) {
  return {
    blob: response.data,
    fileName: response.headers['content-disposition']
      .split('filename=')[1]
      .split(';')[0]
      .replaceAll('"', ''),
  };
}
