import * as Yup from 'yup';
import { AddButton, Form, StyledRow } from '@/components';
import { useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';
import { PathTemplateStep, TreeNodeDto } from '@/core';
import {
  CreatePathTemplateArgs,
  UpdatePathTemplateArgs,
  UpdateProjectPathArgs,
  useGetPathTemplateDetailsQuery,
} from '@/redux';
import { useTranslation } from 'react-i18next';
import { FromPathTemplateStepList } from './FormPathTemplateStepList';
import { FormPathTemplateSource } from './FormPathTemplateSource';
import { Button, Space } from 'antd';
import { get } from 'lodash';

interface Props<T extends CreatePathTemplateArgs | UpdatePathTemplateArgs> {
  organizationId?: string;
  communityId?: string;
  value: T;
  onSubmit: (values: T) => any;
  noTemplate?: boolean;
  onCancel?: () => any;
  isAdding?: boolean;
  freezeExisting?: boolean;
}

export interface FormPathTemplateStepValue {
  id?: string;
  name: string;
  categories: string[];
  initialSelectedCategories?: TreeNodeDto[];
}

export type FormPathTemplateValue<
  T extends Omit<CreatePathTemplateArgs | UpdatePathTemplateArgs, 'steps'>,
> = T & {
  sourceTemplateId: string | null;
  useTemplate: boolean;
  steps: FormPathTemplateStepValue[];
};

const INITIAL_PATH_TEMPLATE_STEP: PathTemplateStep = {
  id: undefined!,
  name: null!,
  categories: [],
};

const SCHEMA = Yup.object().shape({
  name: Yup.string().field().nullable().required(),
  useTemplate: Yup.boolean(),
  sourceTemplateId: Yup.string().when('useTemplate', {
    is: true,
    then: Yup.string().field().nullable().required(),
    otherwise: Yup.string().field().nullable().notRequired(),
  }),
  steps: Yup.array()
    .of(
      Yup.object().shape({ name: Yup.string().field().nullable().required() }),
    )
    .required(),
});

function useHandleAddStep() {
  const { setValues, values } = useFormikContext<CreatePathTemplateArgs>();

  return useCallback(() => {
    setValues({
      ...values,
      steps: [...values.steps, { ...INITIAL_PATH_TEMPLATE_STEP }],
    });
  }, [setValues, values]);
}

function AddStepButton() {
  const { t } = useTranslation();
  const handleClick = useHandleAddStep();

  return (
    <AddButton onClick={handleClick} ghost>
      {t('pathTemplates.details.form.addStepButton')}
    </AddButton>
  );
}

function useValue<
  TValue extends CreatePathTemplateArgs | UpdatePathTemplateArgs,
>(props: Props<TValue>): FormPathTemplateValue<TValue> | null {
  const id: string | undefined = get(props.value, 'id');
  const { data } = useGetPathTemplateDetailsQuery(
    { id: id!, includeCommunityLevel: !!props.communityId },
    { skip: !id },
  );

  return useMemo<FormPathTemplateValue<TValue> | null>(() => {
    if (!id) {
      return {
        ...props.value,
        useTemplate: false,
        sourceTemplateId: null,
        steps: props.value.steps,
      };
    }

    if (!data) {
      return null;
    }

    return {
      ...props.value,
      useTemplate: false,
      sourceTemplateId: null,
      name: props.value.name,
      steps: data.steps.map((step, index) => ({
        name: step.name,
        categories: props.value.steps[index].categories,
        initialSelectedCategories: step.selectedCategories,
      })),
    };
  }, [data, id, props.value]);
}

export function FormPathTemplate<
  TValue extends
    | CreatePathTemplateArgs
    | UpdatePathTemplateArgs
    | UpdateProjectPathArgs,
>(props: Props<TValue>) {
  const {
    onSubmit,
    organizationId,
    noTemplate,
    communityId,
    isAdding,
    onCancel,
    freezeExisting,
  } = props;

  const { t } = useTranslation();
  const formValue = useValue(props);

  if (formValue == null) {
    return null;
  }

  return (
    <Form.Formik<FormPathTemplateValue<TValue>>
      uid="pathTemplate-details"
      i18n="pathTemplates.details.form"
      initialValues={formValue}
      validationSchema={SCHEMA}
      onSubmit={({ useTemplate, sourceTemplateId, ...value }) =>
        onSubmit(value as unknown as TValue)
      }
    >
      <Form.Input
        required
        name="name"
        placeholder={t('pathTemplates.details.placeholders.name')}
      />

      {!noTemplate && (
        <FormPathTemplateSource
          i18n="pathTemplates.details.form"
          organizationId={organizationId}
          stepsFieldName="steps"
          communityId={communityId}
        />
      )}

      <FromPathTemplateStepList
        name="steps"
        communityId={communityId}
        freezeExisting={freezeExisting}
      />

      <div className="mt-4">
        <AddStepButton />
      </div>

      {isAdding && (
        <StyledRow justify="end" className="mt-3">
          <Space size="middle">
            <Button onClick={onCancel}>{t('cancel')}</Button>
            <Form.Submit uid="pathTemplate-details" type="primary">
              {t('save')}
            </Form.Submit>
          </Space>
        </StyledRow>
      )}
    </Form.Formik>
  );
}
