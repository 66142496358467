import { rtkq, RtkqRequest } from '../rtkq';
import {
  RoomTypeDto,
  roomTypesHttp,
  AddRoomTypeDto,
  UpdateRoomTypeDto,
  ChangeStatusRoomTypeDto,
} from '@/core';
import { api } from '../api';

export type AddRoomTypeArgs = AddRoomTypeDto;
export type UpdateRoomTypeArgs = UpdateRoomTypeDto;
export type ChangeStatusRoomTypeArgs = ChangeStatusRoomTypeDto;

const roomTypesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRoomTypeList: build.query<
      RoomTypeDto[],
      RtkqRequest<{ onlyActive?: boolean }>
    >({
      queryFn: async (args) => {
        return await rtkq(args).exec(() =>
          roomTypesHttp.getAll(args.onlyActive),
        );
      },
      providesTags: (result = []) => [
        { type: 'roomType', id: 'list' },
        ...result.map(({ id }) => ({ type: 'roomType' as const, id })),
      ],
    }),

    updateRoomType: build.mutation<void, RtkqRequest<UpdateRoomTypeArgs>>({
      queryFn: async (args) => {
        return await rtkq(args).exec(() => roomTypesHttp.update(args));
      },
      invalidatesTags: (_, __, args) => [{ type: 'roomType', id: args.id }],
    }),

    createRoomType: build.mutation<string, RtkqRequest<AddRoomTypeArgs>>({
      queryFn: async (args) => {
        return await rtkq(args).exec(() => roomTypesHttp.create(args));
      },
      invalidatesTags: () => [{ type: 'roomType', id: 'list' }],
    }),

    changeStatusRoomType: build.mutation<
      string,
      RtkqRequest<ChangeStatusRoomTypeArgs>
    >({
      queryFn: (args) => {
        const { id, status } = args;
        return rtkq(args).exec(() => roomTypesHttp.changeStatus(id, status));
      },
      invalidatesTags: (_, __, args) => [{ type: 'roomType', id: args.id }],
    }),
  }),
});

export const {
  useGetRoomTypeListQuery,
  useLazyGetRoomTypeListQuery,
  useUpdateRoomTypeMutation,
  useChangeStatusRoomTypeMutation,
  useCreateRoomTypeMutation,
} = roomTypesApi;
