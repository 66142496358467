import { EnumValue, getYesNoValue, TableColumnType } from '@/components';
import {
  CommunityPermissions,
  ItemMenuItemType,
  ItemPricePer,
  Paging,
  pagingFrom,
  PriceMatrixFilterParams,
  StringFilterOptions,
} from '@/core';
import { PriceMatrixRowState } from '@/redux';
import { useFiltersFactory } from '@/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { PriceInput } from './PriceInput';
import { PricingItemType } from './PricingItemType';
import { PricingStandardCredit } from './PricingStandardCredit';
import {
  usePriceMatrixPricingInfoChangeHandlerFactory,
  usePriceMatrixRowChangeHandlerFactory,
} from './usePriceMatrixRowChangeHandlerFactory';
import { useCommunityContext } from '../Communities.Common/CommunityContext';
import { UsePagingResult } from '@/components/Collections.Paging';

export function usePricingColumns(
  communityId: string,
  menuId: string,
  pricing: PriceMatrixRowState[],
  collapseFloorplan: boolean,
  collapseRoom: boolean,
  collapseCost: boolean,
  zoomIndex: number,
  pagingResult: UsePagingResult,
  onusePriceMatrixRowChange: () => any,
  searchFilters?: PriceMatrixFilterParams,
  stringFilterOptions?: StringFilterOptions,
) {
  const paging = useMemo<Paging>(() => {
    return pagingFrom(pagingResult, true);
  }, [pagingResult]);
  const { t } = useTranslation();
  const priceHandlerFactory = usePriceMatrixRowChangeHandlerFactory(
    communityId,
    menuId,
    onusePriceMatrixRowChange,
    paging,
    searchFilters,
  );
  const { hasPermission } = useCommunityContext();
  const userHasPricingManagePermission = hasPermission(
    CommunityPermissions.Pricing.Manage,
  );

  const pricingInfoHandlerFactory =
    usePriceMatrixPricingInfoChangeHandlerFactory(
      communityId,
      menuId,
      onusePriceMatrixRowChange,
      paging,
    );

  const filters = useFiltersFactory(pricing, stringFilterOptions);

  return useMemo<TableColumnType<PriceMatrixRowState>[]>(() => {
    function getPrevRowValue(
      rowValue: PriceMatrixRowState,
    ): PriceMatrixRowState | undefined {
      const index = pricing.indexOf(rowValue);
      return index > 0 ? pricing.at(index - 1) : undefined;
    }

    function isPrevMatch(
      rowValue: PriceMatrixRowState,
      by: (row: PriceMatrixRowState) => any[],
    ) {
      const prevRow = getPrevRowValue(rowValue);
      if (!prevRow) return false;
      const current = by(rowValue);
      const prev = by(prevRow);
      return shallowEqual(current, prev);
    }

    function isPrevCategoryMatch(rowValue: PriceMatrixRowState) {
      return isPrevMatch(rowValue, (x) => [
        x.categories[0].id,
        x.categories[1]?.id,
        x.categories[2]?.id,
      ]);
    }

    function isHasStandardCreditInCategory(
      rowValue: PriceMatrixRowState,
      exceptItemId: string,
    ) {
      return pricing.some(
        (x) =>
          x.standardCredit &&
          x.itemId !== exceptItemId &&
          x.categories.at(-1)!.id === rowValue.categories.at(-1)!.id,
      );
    }

    const priceExportValue = (price: number | undefined, itemType: string) => {
      if (price == null && itemType === 'Upgrade') {
        return t('pricing.tbd');
      }
      return price;
    };

    const columns: Array<TableColumnType<PriceMatrixRowState> | false> = [
      {
        title: t('pricing.category'),
        key: 'category',
        width: 100 * zoomIndex,
        ...filters.select((x) => x.categories[0].name, 'category'),
        exportValue: (record) => record.categories[0].name,
        render: (_, record) => {
          if (isPrevCategoryMatch(record)) {
            return null;
          }
          return record.categories[0].name;
        },
        defaultFilteredValue: searchFilters?.category,
      },
      {
        title: t('pricing.subcategory1'),
        key: 'subcategory1',
        width: 150 * zoomIndex,
        ...filters.select((x) => x.categories[1]?.name, 'subcategory1'),
        exportValue: (record) => record.categories[1]?.name,
        render: (_, record) => {
          if (isPrevCategoryMatch(record)) {
            return null;
          }

          return record.categories[1]?.name || '-';
        },
        defaultFilteredValue: searchFilters?.subcategory1,
      },
      {
        title: t('pricing.subcategory2'),
        key: 'subcategory2',
        width: 150 * zoomIndex,
        ...filters.select((x) => x.categories[2]?.name, 'subcategory2'),
        exportValue: (record) => record.categories[2]?.name,
        render: (_, record) => {
          if (isPrevCategoryMatch(record)) {
            return null;
          }

          return record.categories[2]?.name || '-';
        },
        defaultFilteredValue: searchFilters?.subcategory2,
      },
      {
        title: t('pricing.itemName'),
        key: 'itemName',
        width: 120 * zoomIndex,
        ...filters.select((x) => x.itemName, 'itemName'),
        exportValue: (record) => record.itemName,
        render: (_, record) => {
          if (isPrevMatch(record, (x) => [x.itemId])) {
            return null;
          }

          return record.itemName;
        },
        defaultFilteredValue: searchFilters?.itemName,
      },
      {
        title: t('pricing.itemType'),
        key: 'itemType',
        ...filters.enumSelect((x) => x.itemType, ItemMenuItemType),
        exportValue: (record) => record.itemType,
        width: 100 * zoomIndex,
        render: (_, record) => {
          if (isPrevMatch(record, (x) => [x.itemId])) {
            return null;
          }

          return (
            <PricingItemType
              initialValue={record.itemType}
              resetOnInitialValueChange
              onSubmit={pricingInfoHandlerFactory(record, 'type')}
              disabled={!userHasPricingManagePermission}
            />
          );
        },
        defaultFilteredValue: searchFilters?.itemType,
      },
      {
        title: t('pricing.standardCredit'),
        key: 'standardCredit',
        width: 100 * zoomIndex,
        className: 'title',
        exportValue: (record) => getYesNoValue(record.standardCredit, t),
        render: (_, record) => {
          if (isPrevMatch(record, (x) => [x.itemId])) {
            return null;
          }
          const hasStandardCreditInCategory = isHasStandardCreditInCategory(
            record,
            record.itemId,
          );

          return (
            <PricingStandardCredit
              initialValue={record.standardCredit}
              resetOnInitialValueChange
              disabled={
                !userHasPricingManagePermission ||
                hasStandardCreditInCategory ||
                record.itemType === 'Upgrade'
              }
              onSubmit={pricingInfoHandlerFactory(record, 'standardCredit')}
            />
          );
        },
      },
      {
        title: t('pricing.pricePer'),
        key: 'pricePer',
        className: 'title',
        width: 120 * zoomIndex,
        exportValue: (record) => record.itemPricePer,
        render: (_, record) => {
          if (isPrevMatch(record, (x) => [x.itemId])) {
            return null;
          }

          return <EnumValue type={ItemPricePer} value={record.itemPricePer} />;
        },
      },
      {
        title: t('pricing.floorplan'),
        key: 'floorplan',
        width: 120 * zoomIndex,
        ...filters.select(
          (x) => x.floorplan?.marketingName ?? t('pricing.allFloorplans'),
          'floorplan',
          'equal',
          'All',
        ),
        exportValue: (record) =>
          record.floorplan?.marketingName! ?? t('pricing.allFloorplans'),
        render: (_, record) => {
          if (isPrevMatch(record, (x) => [x.itemId, x.floorplan?.id])) {
            return null;
          }

          return record.floorplan?.marketingName ?? t('pricing.allFloorplans');
        },
        defaultFilteredValue: searchFilters?.floorplan,
      },
      !collapseRoom &&
        !collapseFloorplan && {
          title: t('pricing.room'),
          ...filters.select((x) => x.room?.name, 'room'),
          key: 'room',
          width: 120 * zoomIndex,
          exportValue: (record) => record.room?.name!,
          render: (_, record) => {
            if (isPrevMatch(record, (x) => [x.room?.id])) {
              return null;
            }
            return record.room?.name;
          },
          defaultFilteredValue: searchFilters?.room,
        },
      !collapseCost &&
        userHasPricingManagePermission && {
          title: t('pricing.baseCost'),
          key: 'baseCost',
          className: 'title',
          exportValue: (record) =>
            priceExportValue(record.prices?.baseCost, record.itemType),
          width: 150 * zoomIndex,
          render: (_, item) => (
            <PriceInput
              disabled={!userHasPricingManagePermission}
              onSubmit={priceHandlerFactory(item, 'baseCost')}
              initialValue={item.prices?.baseCost}
              resetOnInitialValueChange
              calculated={false}
              tbd={item.prices?.baseCost == null && item.itemType === 'Upgrade'}
            />
          ),
        },
      !collapseCost &&
        userHasPricingManagePermission && {
          title: t('pricing.ownerPrice'),
          key: 'ownerPrice',
          className: 'title',
          exportValue: (record) => record.prices?.ownerPrice?.value!,
          width: 150 * zoomIndex,
          render: (_, item) => (
            <PriceInput
              disabled={
                !userHasPricingManagePermission || item.prices?.baseCost == null
              }
              onSubmit={priceHandlerFactory(item, 'ownerPrice')}
              initialValue={item.prices?.ownerPrice?.value}
              calculated={!item.prices?.ownerPrice?.isOverriden}
              resetOnInitialValueChange
            />
          ),
        },
      {
        title: t('pricing.residentPrice'),
        key: 'residentPrice',
        className: 'title',
        exportValue: (record) =>
          priceExportValue(
            record.prices?.residentPrice?.value,
            record.itemType,
          ),
        width: 150 * zoomIndex,
        render: (_, item) => (
          <PriceInput
            disabled={
              !userHasPricingManagePermission || item.prices?.baseCost == null
            }
            onSubmit={priceHandlerFactory(item, 'residentPrice')}
            initialValue={item.prices?.residentPrice?.value}
            calculated={!item.prices?.residentPrice?.isOverriden}
            tbd={
              !item.prices?.residentPrice?.value && item.itemType === 'Upgrade'
            }
            resetOnInitialValueChange
          />
        ),
      },
    ];

    return columns.filter((x) => !!x) as TableColumnType<PriceMatrixRowState>[];
  }, [
    t,
    zoomIndex,
    filters,
    searchFilters?.category,
    searchFilters?.subcategory1,
    searchFilters?.subcategory2,
    searchFilters?.itemName,
    searchFilters?.itemType,
    searchFilters?.floorplan,
    searchFilters?.room,
    collapseRoom,
    collapseFloorplan,
    collapseCost,
    userHasPricingManagePermission,
    pricing,
    pricingInfoHandlerFactory,
    priceHandlerFactory,
  ]);
}
