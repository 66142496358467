import { confirmation, notify } from '@/components';
import { useDeleteTaskMutation } from '@/redux';
import { guard } from '@/utils';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export function useDeleteTask(goBack: boolean) {
  const [remove] = useDeleteTaskMutation();
  const push = useNavigate();

  const performDelete = useCallback(
    (id: string) =>
      remove({ id })
        .unwrap()
        .then(() => notify.success.t('tasks.deleted')),
    [remove],
  );

  return useCallback(
    (id: string, projectId: string | undefined) =>
      confirmation.delete({ key: 'tasks.name' }, () => {
        performDelete(id);
        if (goBack) {
          guard.notNull(projectId, 'projectId');
          push(`/projects/${projectId}/task-management`);
        }
      }),
    [goBack, performDelete, push],
  );
}
