import {
  LookupSource,
  LookupOption,
  createEntireLoadLookupSource,
  EMPTY_LOOKUP_SOURCE,
} from '@/components';
import {
  RtkqSpin,
  useLazyGetAvailableCommunitiesForProjectsListQuery,
} from '@/redux';
import { useMemo } from 'react';

export function mapCommunityLookup(value: {
  name: string;
  id: string;
}): LookupOption {
  return {
    label: value.name,
    value: value.id,
  };
}

export function useAvailableCommunityForProjectsLookupSource(
  clientId: string | false,
): LookupSource {
  const [fetchList] = useLazyGetAvailableCommunitiesForProjectsListQuery();

  return useMemo(() => {
    if (!clientId && clientId !== false) {
      return EMPTY_LOOKUP_SOURCE;
    }

    const clientIdValue = typeof clientId === 'boolean' ? undefined : clientId;

    return createEntireLoadLookupSource({
      initial: (id: string) =>
        fetchList({ clientId: clientIdValue, [RtkqSpin]: false }, true)
          .unwrap()
          .then((values) =>
            mapCommunityLookup(values.find((x) => x.id === id)!),
          ),
      load: () =>
        fetchList({ clientId: clientIdValue, [RtkqSpin]: false }, true)
          .unwrap()
          .then((values) => values.map(mapCommunityLookup)),
    });
  }, [clientId, fetchList]);
}
