import {
  createEntireLoadLookupSource,
  LookupOption,
  LookupSource,
} from '@/components';
import {
  ProjectStepLookupState,
  RtkqSpin,
  useLazyGetProjectStepsQuery,
} from '@/redux';
import { orderBy } from 'lodash';
import { useMemo } from 'react';

function map(
  value: ProjectStepLookupState,
  includeProjectName?: boolean,
): LookupOption {
  return {
    label: includeProjectName
      ? `${value.projectName} - ${value.name}`
      : value.name,
    value: value.id,
  };
}

export function useProjectStepLookupSource(
  projectIds?: string[],
  includeProjectName: boolean = false,
): LookupSource {
  const [fetchList] = useLazyGetProjectStepsQuery();

  return useMemo(() => {
    const entireSource = createEntireLoadLookupSource({
      initial: (id: string) =>
        fetchList({ ids: projectIds, [RtkqSpin]: false }, true)
          .unwrap()
          .then((values) =>
            map(values.find((x) => x.id === id)!, includeProjectName),
          ),
      load: () =>
        fetchList({ ids: projectIds, [RtkqSpin]: false }, true)
          .unwrap()
          .then((values) =>
            orderBy(values, (x) => x.name.toLowerCase(), 'asc').map((x) =>
              map(x, includeProjectName),
            ),
          ),
    });

    return { ...entireSource, searchField: '_search' };
  }, [fetchList, includeProjectName, projectIds]);
}
