import { Trans } from 'react-i18next';
import { NotificationSettings } from './useNotification';
import { NavLink } from 'react-router-dom';

const LinkData = [
  {
    index: 0,
    to: (payload: any) =>
      `/communities/${payload.CommunityId}/homes/${payload.HomeId}#personalizationSummary`,
    text: (payload: any) => payload.StepName,
  },
  {
    index: 1,
    to: (payload: any) =>
      `/communities/${payload.CommunityId}/homes/${payload.HomeId}`,
    text: (payload: any) => payload.HomeNumber,
  },
  {
    index: 2,
    to: (payload: any) => `/projects/${payload.ProjectId}/administration`,
    text: (payload: any) => payload.ProjectNumber ?? payload.ProjectName,
  },
  {
    index: 3,
    to: (payload: any) =>
      `/communities/${payload.CommunityId}#items-and-categories`,
    text: (payload: any) => payload.ItemName,
  },
  {
    index: 4,
    to: (payload: any) => `/communities/${payload.CommunityId}`,
    text: (payload: any) => payload.CommunityNumber,
  },
  {
    index: 5,
    to: (payload: any) =>
      `/projects/${payload.ProjectId}/task-management/${payload.ProjectTaskId}`,
    text: (payload: any) => payload.ProjectTaskNumber,
  },
  {
    index: 6,
    to: (payload: any) =>
      `/communities/${payload.CommunityId}/homes/${payload.HomeId}#personalization`,
    text: (payload: any) => payload.ItemName,
  },
];

export const BasicNotificationSubject = ({
  payload,
  type,
}: {
  payload: any;
  type: string;
}) => {
  const isLinksSupported = payload.CommunityId || payload.ProjectId;
  return (
    <Trans
      i18nKey={`notificationSettings.${type}.subject`}
      values={payload}
      defaults="test"
    >
      {isLinksSupported &&
        LinkData.map((link) => (
          <NavLink key={link.index} to={link.to(payload)}>
            {link.text(payload)}
          </NavLink>
        ))}
    </Trans>
  );
};

export function createBasicNotificationSettings(
  type: string,
): NotificationSettings<any> {
  return {
    Subject: (props) => <BasicNotificationSubject type={type} {...props} />,
    Body: undefined,
  };
}
