import { rtkq, RtkqRequest } from '../rtkq';
import {
  AddDevelopScopeItemDto,
  developScopeHttp,
  DevelopScopeItemDto,
  HomeRoomFinalizationDetailsDto,
  RoomState,
  UpdateDevelopScopeItemDto,
} from '@/core';
import { api } from '../api';

export type DevelopScopeItemState = DevelopScopeItemDto;
export type AddDevelopScopeItemArgs = AddDevelopScopeItemDto;
export type UpdateDevelopScopeItemArgs = UpdateDevelopScopeItemDto;
export type HomeRoomFinalizationDetailsState = HomeRoomFinalizationDetailsDto;

const developScopeItemsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDevelopScopeItemsList: build.query<
      DevelopScopeItemState[],
      RtkqRequest<{
        projectId: string;
        homeId: string;
        roomId: string | null;
        onlyWholeHome?: boolean;
        onlyFloorplan?: boolean;
      }>
    >({
      queryFn: async (args) => {
        return rtkq(args).exec(() =>
          developScopeHttp.getAll(
            args.projectId,
            args.homeId,
            args.roomId,
            args.onlyWholeHome,
            args.onlyFloorplan,
          ),
        );
      },
      providesTags: (result = []) => [
        { type: 'developScopeItem', id: 'list' },
        { type: 'task', id: 'list' },
        ...result.map(({ id }) => ({ type: 'developScopeItem' as const, id })),
        ...result.map(({ specialRequest }) => ({
          type: 'special-request' as const,
          id: specialRequest?.id,
        })),
        ...result.map(({ projectTaskId }) => ({
          type: 'task' as const,
          id: projectTaskId ?? 'none',
        })),
      ],
    }),

    addDevelopScopeItem: build.mutation<
      string,
      RtkqRequest<AddDevelopScopeItemArgs>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() => developScopeHttp.create(args));
      },
      invalidatesTags: () => [
        { type: 'developScopeItem', id: 'list' },
        { type: 'special-request', id: 'list' },
      ],
    }),

    updateDevelopScopeItem: build.mutation<
      void,
      RtkqRequest<UpdateDevelopScopeItemArgs>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() => developScopeHttp.update(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'developScopeItem', id: 'list' },
        { type: 'developScopeItem', id: args.id },
        { type: 'special-request', id: 'list' },
      ],
    }),

    deleteDevelopScopeItem: build.mutation<void, RtkqRequest<{ id: string }>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => developScopeHttp.delete(args.id));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'developScopeItem', id: 'list' },
        { type: 'developScopeItem', id: args.id },
        { type: 'develop-scope-room', id: 'list' },
      ],
    }),

    getNotFinalizedRoomIdsList: build.query<
      HomeRoomFinalizationDetailsState[],
      RtkqRequest<{ projectId: string; homeId: string }>
    >({
      queryFn: async (args) => {
        return await rtkq(args).exec(
          async () =>
            await developScopeHttp.getNotFinalizedRoomIdsAll(
              args.projectId,
              args.homeId,
            ),
        );
      },
      providesTags: () => [
        { type: 'developScopeItem', id: 'list' },
        { type: 'task', id: 'list' },
      ],
    }),

    getDevelopScopeRoomsList: build.query<
      RoomState[],
      RtkqRequest<{ projectId: string; homeId: string }>
    >({
      queryFn: async (args) => {
        return await rtkq(args).exec(() =>
          developScopeHttp.getDevelopScopeRooms(args),
        );
      },
      providesTags: (result = []) => [
        { type: 'room', id: 'list' },
        { type: 'develop-scope-room', id: 'list' },
        ...result.map(({ id }) => ({
          type: 'develop-scope-room' as const,
          id,
        })),
      ],
    }),
  }),
});

export const {
  useGetDevelopScopeItemsListQuery,
  useGetNotFinalizedRoomIdsListQuery,
  useAddDevelopScopeItemMutation,
  useDeleteDevelopScopeItemMutation,
  useUpdateDevelopScopeItemMutation,
  useGetDevelopScopeRoomsListQuery,
} = developScopeItemsApi;
