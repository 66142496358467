import { useEffect } from 'react';
import { spinner } from '@/components';
import { useAppActivityList } from '@/core';
import { Activity } from '@webinex/activity';
import { ActivityOperationStateContext } from './ActivityOperationStateContext';
import { ActivityOperationItem } from './ActivityOperationItem';

export interface ActivityOperationPanelProps {
  id: string;
}

function useViewState({ id }: ActivityOperationPanelProps) {
  const { state, fetch } = useAppActivityList();

  useEffect(() => {
    if (!id) return;

    spinner.show();
    fetch({
      filter: { fieldId: 'operationId', operator: '=', value: id },
    }).finally(() => spinner.hide());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { state };
}

function sortByPerformedAt(values: Activity[]) {
  values = [...values];
  const timeFn = (value: Activity) => new Date(value.performedAt).getTime();
  values.sort((a, b) => timeFn(a) - timeFn(b));
  return values;
}

export function ActivityOperationPanel(props: ActivityOperationPanelProps) {
  const { state } = useViewState(props);
  let values = Object.values(state.itemById).filter(
    (x: Activity) => !x.parentId,
  );
  values = sortByPerformedAt(values);

  return (
    <ActivityOperationStateContext.Provider value={state}>
      {values.map((item) => (
        <ActivityOperationItem key={item.id} id={item.id} />
      ))}
    </ActivityOperationStateContext.Provider>
  );
}
