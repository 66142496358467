import { notify } from '@/components';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetProjectQuery, useUpdateProjectHomesMutation } from '@/redux';
import { useBool } from '@/utils';
import { ProjectHomesModal } from '../Projects.List/ProjectHomesModal';
import { Assert } from '../Communities.Common';
import { CommunityPermissions } from '@/core';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';

interface Props {
  projectId: string;
}

const useSubmit = (toggleModalOpen: () => any, props: Props) => {
  const { projectId } = props;
  const [update] = useUpdateProjectHomesMutation();

  return useCallback(
    (homeIds: string[]) => {
      update({ homeIds, projectId })
        .unwrap()
        .then(() => notify.success.t('projects.editHomes.updated'))
        .then(() => toggleModalOpen());
    },
    [update, toggleModalOpen, projectId],
  );
};

export function EditProjectHomesButton(props: Props) {
  const { projectId } = props;
  const { t } = useTranslation(undefined, { keyPrefix: 'projects.editHomes' });
  const [modalOpen, , toggleModalOpen] = useBool(false);
  const submit = useSubmit(toggleModalOpen, props);

  const { currentData: project } = useGetProjectQuery({ id: projectId });
  const { communityId, homeIds } = project ?? {};

  return (
    <>
      <Assert permission={CommunityPermissions.Projects.Manage}>
        <Button
          type="primary"
          onClick={toggleModalOpen}
          icon={<EditOutlined />}
        >
          {t('btn')}
        </Button>
      </Assert>
      {modalOpen && project && (
        <ProjectHomesModal
          communityId={communityId!}
          projectId={projectId}
          homeIds={homeIds!}
          onCancel={toggleModalOpen}
          onSave={submit}
        />
      )}
    </>
  );
}
