import { Modal, Button, Col } from 'antd';
import { FieldRow, Form } from '@/components';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Contact } from '@/core';

const schema = Yup.object().contact();

interface Props {
  onClose: () => any;
  onSubmit: (contact: Contact) => any;
  value: Contact;
  buttonSaveName: string;
  title: string;
}

export function ContactDetailsModal(props: Props) {
  const { onClose: onCancel, value, onSubmit, buttonSaveName, title } = props;
  const { t } = useTranslation();

  return (
    <>
      <Modal
        title={title}
        visible
        onCancel={onCancel}
        footer={[
          <Button key="back" type="default" onClick={onCancel}>
            {t('clients.details.cancel')}
          </Button>,
          <Form.Submit uid="client-details-contact" key="submit" type="primary">
            {buttonSaveName}
          </Form.Submit>,
        ]}
      >
        <Form.Formik<Contact>
          uid="client-details-contact"
          i18n="clients.details.contacts"
          initialValues={value}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          <>
            <FieldRow>
              <Col span={12}>
                <Form.Input name="name" required />
              </Col>
              <Col span={12}></Col>
            </FieldRow>
            <FieldRow>
              <Col span={12}>
                <Form.Phone name="phone" required />
              </Col>
              <Col span={12}>
                <Form.Input name="email" required />
              </Col>
            </FieldRow>
          </>
        </Form.Formik>
      </Modal>
    </>
  );
}
