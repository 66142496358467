import { Tabs } from '@/components';
import { useGetHomeDetailsQuery } from '@/redux';
import { useTranslation } from 'react-i18next';
import {
  HomeDetailsTabKeys,
  PersonalizationSummaryTabContent,
  PersonalizationTabContent,
  useCommunityContext,
} from '@/views';
import { CommunityPermissions } from '@/core';

interface Props {
  title: React.ReactNode;
  projectId: string;
  homeId: string;
}

const { TabPane } = Tabs;

export function ProjectHomePersonalizationTabsPanel(props: Props) {
  const { homeId } = props;
  const { t } = useTranslation();
  const { data, isFetching } = useGetHomeDetailsQuery({ id: homeId });
  const { hasPermission } = useCommunityContext();

  if (!data || isFetching) {
    return null;
  }

  const hasAnyHistory = data.personalizationHistory.length > 0;

  return (
    <Tabs tabKeys={HomeDetailsTabKeys} destroyInactiveTabPane>
      {hasAnyHistory &&
        hasPermission(CommunityPermissions.Personalization.View) && (
          <TabPane
            tab={t('homes.details.tabs.personalization')}
            key={HomeDetailsTabKeys.personalization}
          >
            <PersonalizationTabContent
              personalizationHistory={data.personalizationHistory}
              homeId={homeId}
              communityId={data.communityId}
            />
          </TabPane>
        )}

      {hasAnyHistory && (
        <TabPane
          tab={t('homes.details.tabs.personalizationSummary')}
          key={HomeDetailsTabKeys.personalizationSummary}
        >
          <PersonalizationSummaryTabContent
            personalizationHistory={data.personalizationHistory}
            homeId={homeId}
            communityId={data.communityId}
          />
        </TabPane>
      )}
    </Tabs>
  );
}
