import { CheckableTree, dataNodeUtils } from '@/components';
import { TreeNodeDto } from '@/core';
import { CategoryState, useGetCategoriesQuery } from '@/redux';
import { array, tree } from '@/utils';
import { useField } from 'formik';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';

interface Props {
  name: string;
  communityId?: string;
  initialSelectedCategories?: TreeNodeDto[];
}

function useTreeData(props: Props) {
  const { communityId, initialSelectedCategories } = props;
  const { data: categories = array.empty<CategoryState>() } =
    useGetCategoriesQuery({ listed: true, communityId });

  const treeData = initialSelectedCategories
    ? tree.merge(initialSelectedCategories, categories)
    : categories;

  return useMemo(
    () =>
      dataNodeUtils.convertFromManyCustom(
        treeData,
        'id',
        'name',
        'children',
        'custom',
        'name',
      ),
    [treeData],
  );
}

export function FormPathTemplateStepCategories(props: Props) {
  const { name } = props;
  const [{ value }, , { setValue }] = useField<string[]>(name);
  const treeData = useTreeData(props);

  if (isEmpty(treeData)) {
    return null;
  }

  return (
    <CheckableTree
      treeData={treeData}
      onCheck={setValue}
      checkedKeys={value}
      selectable={false}
      mode="any"
      virtual={false}
    />
  );
}
