import {
  AddButton,
  TabPanel,
  TreeView,
  TreeViewTexts,
  useTabPanelNavMenuState,
  notify,
} from '@/components';
import { useTranslation } from 'react-i18next';
import {
  CategoryState,
  useGetCategoriesQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} from '@/redux';
import { useMemo, useState } from 'react';
import { array } from '@/utils';
import { Empty } from 'antd';
import { isEmpty } from 'lodash';

const INITIAL_CATEGORY: CategoryState = {
  id: null!,
  children: [],
  name: null!,
  listed: null!,
};

function useTexts(): TreeViewTexts {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      addChildBtnText: t('categories.details.addChild'),
      addTitle: t('categories.details.addTitle'),
      editTitle: t('categories.details.editTitle'),
      title: t('categories.details.title'),
      nameFieldTitle: t('categories.details.name'),
      childNameFieldTitle: t('categories.details.childName'),
      entityName: t('categories.entityName'),
    }),
    [t],
  );
}

export function CategoryListPanel() {
  const { data = array.empty<CategoryState>() } = useGetCategoriesQuery({
    listed: true,
  });

  const [update] = useUpdateCategoryMutation();
  const [create] = useCreateCategoryMutation();
  const [remove] = useDeleteCategoryMutation();

  const { t } = useTranslation();
  const [add, setAdd] = useState(false);
  const texts = useTexts();

  const items = useMemo(
    () => (add ? [...data, INITIAL_CATEGORY] : data),
    [data, add],
  );

  const tabNav = useTabPanelNavMenuState({
    items,
    keyBy: (x) => x.id ?? 'new',
    titleBy: (x) => x.name ?? t('categories.new'),
  });

  const { select, selected } = tabNav;

  const handleAddClick = () => {
    setAdd(true);
    select('new');
  };

  const handleSelect = (id: string) => {
    setAdd(false);
    select(id);
  };

  const handleDelete = (category: CategoryState) =>
    remove({ id: category.id })
      .unwrap()
      .then(() => notify.success.t('categories.deleted'));

  const handleUpdate = (category: CategoryState) =>
    update({ category })
      .unwrap()
      .then(() => notify.success.t('categories.updated'));

  const handleAdd = (category: CategoryState) =>
    create({ category })
      .unwrap()
      .then(() => setAdd(false))
      .then(() => notify.success.t('categories.added'));

  const empty = isEmpty(items);

  return (
    <TabPanel min="auto">
      <TabPanel.Title>{t('categories.title')}</TabPanel.Title>
      <TabPanel.Actions>
        <AddButton onClick={handleAddClick}>{t('categories.add')}</AddButton>
      </TabPanel.Actions>
      <TabPanel.Nav title={t('categories.navTitle')}>
        {!empty && (
          <TabPanel.Menu<CategoryState> {...tabNav} onSelected={handleSelect} />
        )}
      </TabPanel.Nav>
      <TabPanel.Body>
        {empty && <Empty />}
        {!empty && selected && (
          <TreeView
            key={selected.id}
            uid="category-details"
            value={selected}
            texts={texts}
            keyBy="id"
            titleBy="name"
            childrenBy="children"
            onSubmit={selected.id ? handleUpdate : handleAdd}
            onDelete={selected.id ? handleDelete : undefined}
            onCancel={() => setAdd(false)}
          />
        )}
      </TabPanel.Body>
    </TabPanel>
  );
}
