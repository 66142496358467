import { useNavigate } from 'react-router-dom';
import { useHomeLookupSource } from '@/views';
import { AsyncSelect } from '@/components';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  communityId: string;
  className?: string;
}

function useHandleChange(communityId: string) {
  const push = useNavigate();
  return useCallback(
    (id: string | undefined) => push(`/communities/${communityId}/homes/${id}`),
    [push, communityId],
  );
}

export function SearchHomeInput(props: Props) {
  const { communityId, className } = props;
  const dataSource = useHomeLookupSource(communityId);
  const handleChange = useHandleChange(communityId);
  const { t } = useTranslation();

  return (
    <AsyncSelect
      className={className}
      value={undefined}
      onChange={handleChange}
      dataSource={dataSource}
      placeholder={t('developScope.selectHome')}
      allowClear={false}
    />
  );
}
