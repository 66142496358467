import { DateValue, EditIconButton, TableColumnType } from '@/components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectLocationDeadlineInfo, useGetProjectPathQuery } from '@/redux';
import { NavLink } from 'react-router-dom';
import { Row } from 'antd';
import {
  CommunityPermissions,
  ProjectLocationStepDeadlineLookupDto,
  StringFilterOptions,
} from '@/core';
import { useCommunityContext } from '../Communities.Common';
import { useProjectContext } from '..';
import { useFiltersFactory } from '@/utils';

interface Props {
  projectId: string;
  communityId: string;
  data: ProjectLocationDeadlineInfo[];
  onEditButtonClick: () => void;
  setSelectedValue: (value: ProjectLocationDeadlineInfo) => void;
  setDeadline: (deadline: ProjectLocationStepDeadlineLookupDto) => void;
  stringFilterOptions?: StringFilterOptions;
}

export function useProjectLocationDeadlinesColumns(args: Props) {
  const {
    communityId,
    projectId,
    data,
    onEditButtonClick,
    setSelectedValue,
    setDeadline,
    stringFilterOptions,
  } = args;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'projects.locationDeadlines',
  });
  const { currentData: projectPath } = useGetProjectPathQuery({
    id: projectId,
  });
  const { hasStatus } = useProjectContext();
  const { assert } = useCommunityContext();
  const canManagePaths =
    assert(CommunityPermissions.Path.Manage, true) &&
    hasStatus(['NotStarted', 'InProgress']);
  const filters = useFiltersFactory(data, stringFilterOptions);

  return useMemo<TableColumnType<ProjectLocationDeadlineInfo>[]>(() => {
    function getPrevRowValue(
      rowValue: ProjectLocationDeadlineInfo,
    ): ProjectLocationDeadlineInfo | undefined {
      const index = data.indexOf(rowValue);
      return index > 0 ? data.at(index - 1) : undefined;
    }

    function isPrevMatch(
      rowValue: ProjectLocationDeadlineInfo,
      by: (row: ProjectLocationDeadlineInfo) => any[],
      level: number,
    ) {
      const prevRow = getPrevRowValue(rowValue);
      if (!prevRow) return false;
      const current = by(rowValue);
      const prev = by(prevRow);
      return current[level] === prev[level];
    }

    function isPrevLocationMatch(
      rowValue: ProjectLocationDeadlineInfo,
      level: number,
    ) {
      return isPrevMatch(
        rowValue,
        (x) => [
          x.location.location1Name,
          x.location.location2Name,
          x.location.location3Name,
        ],
        level,
      );
    }

    const columns: Array<TableColumnType<ProjectLocationDeadlineInfo> | false> =
      [
        {
          title: t('location1'),
          key: 'location1',
          ...filters.select((x) => x.location.location1Name, 'location1'),
          sorter: (a, b) =>
            a.location.location1Name.localeCompare(b.location.location1Name),
          render: (_, item) => {
            if (isPrevLocationMatch(item, 0)) {
              return null;
            }
            return item.location.location1Name;
          },
        },
        {
          title: t('location2'),
          key: 'location2',
          ...filters.select(
            (x) =>
              x.location.location2Name !== ''
                ? x.location.location2Name
                : 'All',
            'location2',
            'equal',
            'All',
          ),
          sorter: (a, b) =>
            (a.location.location2Name || '').localeCompare(
              b.location.location2Name || '',
            ),
          render: (_, item) => {
            if (isPrevLocationMatch(item, 1)) {
              return null;
            }
            return item.location.location2Name !== ''
              ? item.location.location2Name
              : 'All';
          },
        },
        {
          title: t('location3'),
          key: 'location3',
          ...filters.select(
            (x) =>
              x.location.location3Name !== ''
                ? x.location.location3Name
                : 'All',
            'location3',
            'equal',
            'All',
          ),
          sorter: (a, b) =>
            (a.location.location3Name || '').localeCompare(
              b.location.location3Name || '',
            ),
          render: (_, item) => {
            return item.location.location3Name !== ''
              ? item.location.location3Name
              : 'All';
          },
        },
        {
          title: t('pathName'),
          key: 'path',
          render: (_, { projectId }) => (
            <NavLink to={`/projects/${projectId}/path`}>
              {projectPath?.name}
            </NavLink>
          ),
        },
        ...(projectPath
          ? projectPath.steps.map((s) => ({
              title: s.name,
              key: s.id,
              width: 100,
              render: (_: any, item: ProjectLocationDeadlineInfo) => {
                const projectStep = item.steps.find(
                  (projStep) => projStep.id === s.id,
                );
                const deadlineDate = projectStep?.deadlineDate;
                return (
                  <Row wrap={false}>
                    <DateValue value={deadlineDate} />
                    {canManagePaths && (
                      <EditIconButton
                        type="link"
                        onClick={() => {
                          onEditButtonClick();
                          setDeadline(projectStep!);
                          setSelectedValue(item);
                        }}
                      />
                    )}
                  </Row>
                );
              },
            }))
          : []),
      ];

    return columns.filter(
      (x) => !!x,
    ) as TableColumnType<ProjectLocationDeadlineInfo>[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, projectPath, communityId, data, onEditButtonClick, setDeadline]);
}
