import { Form } from '@/components';
import { DuplicateTaskArgs } from '@/redux';
import { Button, Modal } from 'antd';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useHomeLookupSource } from '../Homes.Common';

interface DuplicateTaskModalProps {
  taskIds: string[];
  projectId: string;
  onSubmit: (value: DuplicateTaskArgs) => any;
  onClose: () => any;
}

interface DuplicateTaskFormValue {
  homeIds: string[];
}

const INITIAL_VALUES: DuplicateTaskFormValue = {
  homeIds: [],
};

const schema = Yup.object().shape({
  homeIds: Yup.array().min(1, i18next.t('errors.mixed.required')),
});

export function DuplicateTaskModal(props: DuplicateTaskModalProps) {
  const { onClose, onSubmit, taskIds, projectId } = props;
  const { t } = useTranslation();

  const homesDataSource = useHomeLookupSource(undefined, projectId);

  return (
    <Modal
      title={t('tasks.details.duplicate.modalTitle')}
      visible
      onCancel={onClose}
      footer={[
        <Button key="back" type="default" onClick={onClose}>
          {t('cancel')}
        </Button>,
        <Form.Submit uid="duplicate-task" key="submit" type="primary">
          {t('save')}
        </Form.Submit>,
      ]}
    >
      <Form.Formik<DuplicateTaskFormValue>
        uid="duplicate-task"
        i18n="tasks.details.duplicate"
        initialValues={INITIAL_VALUES}
        validationSchema={schema}
        onSubmit={(value) => onSubmit({ projectId, taskIds, ...value })}
      >
        <Form.AsyncSelect
          mode="multiple"
          name="homeIds"
          required
          preload
          dataSource={homesDataSource}
        />
      </Form.Formik>
    </Modal>
  );
}
