import axios from 'axios';
import { Http, AttachmentDto } from '@/core';
import { useClippo, ClippoOptions, ClippoHttp } from '@webinex/clippo';

const axiosInstance = axios.create({ baseURL: '/api/clippo' });

axiosInstance.interceptors.request.use(async (request) => {
  const { key, value } = await Http.getAuthHeader();
  request.headers![key] = value;
  return request;
});

export const clippoAppHttp = new ClippoHttp<AttachmentDto>(axiosInstance);

export function useAppClippo(options: ClippoOptions<AttachmentDto>) {
  return useClippo({ axios: axiosInstance, ...options });
}
