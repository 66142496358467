import { array } from '@/utils';
import { LookupOption } from './lookupOption';

export interface LookupSource {
  searchField?: string;
  search: (searchString: string | undefined) => Promise<LookupOption[]>;
  initial: (value: string) => Promise<LookupOption>;
  onNewValue?: (search: string) => LookupOption;
}

interface EntireLoadConfig {
  load: () => Promise<LookupOption[]>;
  initial: (value: string) => Promise<LookupOption>;
}

export const EMPTY_LOOKUP_SOURCE: LookupSource = {
  initial: () => Promise.resolve(null!),
  search: () => Promise.resolve(array.empty<LookupOption>()),
};

export function createEntireLoadLookupSource(
  config: EntireLoadConfig,
): LookupSource {
  const source = new EntireLoadLookupSource(config);
  return { initial: source.initial, search: source.search };
}

class EntireLoadLookupSource implements LookupSource {
  private _config: EntireLoadConfig;
  private _source: Promise<LookupOption[]>;
  private _resolve!: (value: LookupOption[]) => any;
  private _resolved: boolean = false;
  private _fetching: boolean = false;

  constructor(config: EntireLoadConfig) {
    this._config = config;

    this._source = new Promise((resolve) => {
      this._resolve = resolve;
    });
  }

  public search = async (searchString: string | undefined) => {
    if (this._resolved || this._fetching) {
      return await this._source;
    }

    this._fetching = true;
    this._config
      .load()
      .then(this._resolve)
      .finally(() => (this._fetching = false));

    return this._source;
  };

  public initial = (value: string) => {
    return this._config.initial(value);
  };
}
