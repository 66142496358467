import { useClientLookupSource } from '@/views/Clients.Common';
import { AsyncSelect, LookupOption } from '@/components';
import { useCallback, useState } from 'react';

export interface ClientSelectPanelProps {
  className?: string;
  selected?: string;
  setSelected: (client: string | undefined) => any;
}

export function useClientSelectPanel(
  initialClientId?: string,
): Omit<ClientSelectPanelProps, 'className'> {
  const [selected, setSelected] = useState<string | undefined>(initialClientId);
  return { selected, setSelected };
}

export function ClientSelectPanel(props: ClientSelectPanelProps) {
  const { className, selected, setSelected } = props;
  const clientDataSource = useClientLookupSource();

  const handleSearchLoad = useCallback(
    (options: LookupOption[]) => !selected && setSelected(options[0].value),
    [selected, setSelected],
  );

  return (
    <div className={className}>
      <AsyncSelect
        value={selected}
        onChange={setSelected}
        dataSource={clientDataSource}
        preload
        onSearchLoad={handleSearchLoad}
      />
    </div>
  );
}
