import { createEntireLoadLookupSource, LookupOption } from '@/components';
import { AttachmentDto } from '@/core';
import { RtkqSpin, useLazyGetReportExcelTemplatesQuery } from '@/redux';
import i18next from 'i18next';
import { uniqueId } from 'lodash';
import { useMemo } from 'react';
import { useGetReportTemplateOwnerId } from './useGetReportTemplateOwnerId';

function map(attachment: AttachmentDto): LookupOption {
  return {
    label: attachment.fileName,
    value: attachment.id,
  };
}

export const PREDEFINED_REPORT_PREFIX = 'predefined://';
export const NO_SUBTOTAL_TEMPLATE = `${PREDEFINED_REPORT_PREFIX}noSubtotal`;
export const HOME_SUBTOTAL_TEMPLATE = `${PREDEFINED_REPORT_PREFIX}homeSubtotal`;

const predefinedTemplateOptions: LookupOption[] = [
  {
    label: i18next.t<string>('reports.templates.predefined.noSubtotals'),
    value: NO_SUBTOTAL_TEMPLATE,
  },
  {
    label: i18next.t<string>('reports.templates.predefined.homeSubtotals'),
    value: HOME_SUBTOTAL_TEMPLATE,
  },
];

export function useReportExcelTemplateLookupSource() {
  const ownerId = useGetReportTemplateOwnerId();
  const [fetch, { isFetching }] = useLazyGetReportExcelTemplatesQuery();

  return useMemo(
    () => ({
      source: createEntireLoadLookupSource({
        load: () =>
          fetch({ [RtkqSpin]: false, id: ownerId }, true)
            .unwrap()
            .then((items) => predefinedTemplateOptions.concat(items.map(map))),
        initial: () => {
          throw new Error('Not implemented');
        },
      }),
      sourceId: isFetching ? 'fetching' : uniqueId('source-id-'),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFetching],
  );
}
export { useGetReportTemplateOwnerId };
