import { Modal } from 'antd';
import React from 'react';
import { UpdateSpecialRequestArgs } from '@/redux';
import {
  useSpecialRequestSubmit,
  useSpecialReuqestCommentInitialValue,
} from './SpecialRequestCommentsPanel';
import { FormSpecialRequestComment } from './FormSpecialRequestComment';
import { SpecialRequestCommentsList } from './SpecialRequestCommentsList';

interface Props {
  title: React.ReactNode;
  onClose: () => any;
  value: UpdateSpecialRequestArgs | undefined;
}

export function SpecialRequestCommentsModal(props: Props) {
  const { title, onClose, value } = props;
  const id: string | undefined = (value as UpdateSpecialRequestArgs).id;

  const INITIAL_VALUES = useSpecialReuqestCommentInitialValue({
    specialRequestId: id,
  });
  const submit = useSpecialRequestSubmit(() => {});

  return (
    <Modal title={title} visible onCancel={onClose} footer={null}>
      <FormSpecialRequestComment
        value={INITIAL_VALUES}
        onCancel={onClose}
        onSubmit={submit}
        separateForm
      />
      <SpecialRequestCommentsList specialRequestId={id} />
    </Modal>
  );
}
