import { CommunityPermissions } from '@/core';
import { useGetProjectHomeInfoQuery } from '@/redux';
import { Space, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { Assert } from '@/views';

export const useFloorplanPersonalizationNeededValue = (
  homeId: string,
  projectId: string,
  selectedStep?: string,
) => {
  const { data: context } = useGetProjectHomeInfoQuery({
    id: projectId,
    homeId,
  });

  if (!context) return null;

  const step = context?.steps.find((x) => x.stepId === selectedStep);
  return step == null
    ? !context.steps.some((x) => x.isFloorplanPersonalizationNeeded)
    : !step.isFloorplanPersonalizationNeeded;
};

interface Props {
  homeId: string;
  projectId: string;
  selectedStep?: string;
  handleUpdatePersonalizationNeeded: (
    currentValue: boolean,
    selectedStep?: string,
  ) => any;
}

export const FloorplanPersonalizationNeededSwitch = (props: Props) => {
  const { selectedStep, handleUpdatePersonalizationNeeded, homeId, projectId } =
    props;
  const { t } = useTranslation();

  const checkedValue = useFloorplanPersonalizationNeededValue(
    homeId,
    projectId,
    selectedStep,
  );

  if (checkedValue === null) return null;

  return (
    <Space>
      <Assert permission={CommunityPermissions.Personalization.Manage}>
        <Switch
          checked={checkedValue}
          onChange={(currentValue) =>
            handleUpdatePersonalizationNeeded(currentValue, selectedStep)
          }
        />
      </Assert>
      {t('personalization.floorplan.noPersonalizationNeededCheckbox')}
    </Space>
  );
};
