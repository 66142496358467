import { FieldRow, Form, LookupOption } from '@/components';
import * as Yup from 'yup';
import { AddSubscriptionArgs } from '@/redux';
import { Col } from 'antd';
import { useClientLookupSource } from '@/views';
import { FormSelectCommunity } from './FormSelectCommunity';

const schema = Yup.object().shape({
  clientId: Yup.string().nullable().required(),
  communityId: Yup.string().nullable().required(),
  pmLiteEnabled: Yup.bool().required(),
  renovationProjectsEnabled: Yup.bool().required(),
  newConstructionProjectsEnabled: Yup.bool().required(),
});

export type SubscriptionFormValue = AddSubscriptionArgs;

export const INITIAL_SUBSCRIPTION: SubscriptionFormValue = {
  clientId: null!,
  communityId: null!,
  newConstructionProjectsEnabled: false,
  pmLiteEnabled: false,
  renovationProjectsEnabled: false,
};

interface Props {
  value: SubscriptionFormValue;
  onSubmit: (value: SubscriptionFormValue) => any;
  disabledClient?: boolean;
  disabledCommunity?: boolean;
  initialClient?: LookupOption;
  initialCommunity?: LookupOption;
}

export function SubscriptionDetailsForm(props: Props) {
  const {
    value,
    onSubmit,
    disabledClient,
    disabledCommunity,
    initialClient,
    initialCommunity,
  } = props;

  const clientDataSource = useClientLookupSource();

  return (
    <Form.Formik<SubscriptionFormValue>
      uid="subscription-details"
      i18n="subscriptions.details"
      initialValues={value}
      validationSchema={schema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <FieldRow>
        <Col span={12}>
          <Form.AsyncSelect
            dataSource={clientDataSource}
            name="clientId"
            required
            preloadInitial={initialClient?.value}
            disabled={disabledClient}
          />
        </Col>
      </FieldRow>
      <FieldRow>
        <Col span={12}>
          <FormSelectCommunity
            name="communityId"
            preloadInitial={initialCommunity}
            disabled={disabledCommunity}
            initialClientId={initialClient?.value}
          />
        </Col>
      </FieldRow>
      <Form.Switch name="renovationProjectsEnabled" />
      <Form.Switch name="newConstructionProjectsEnabled" />
      <Form.Switch name="pmLiteEnabled" />
    </Form.Formik>
  );
}
