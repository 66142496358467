import { StyledRow } from '@/components';
import { formatters } from '@/core';
import { Col, Row, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
  currentRoomAmount: number | undefined;
  isCurrentRoomAmountFinal: boolean | undefined;
  totalHomeAmount: number | undefined;
  isTotalHomeAmountFinal: boolean | undefined;
}

const { Title, Text } = Typography;

export function RoomPersonalizationAmountInfo(props: Props) {
  const {
    currentRoomAmount,
    isCurrentRoomAmountFinal,
    totalHomeAmount,
    isTotalHomeAmountFinal,
  } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'personalization',
  });

  return (
    <StyledRow justify="end">
      <Col>
        <Row justify="end">
          <Text>
            {t('currentRoom', { amount: formatters.price(currentRoomAmount) })}
          </Text>
          {!isCurrentRoomAmountFinal && (
            <Tooltip placement="right" title={t('warning')}>
              *
            </Tooltip>
          )}
        </Row>
        <Row justify="end">
          <Title level={4}>
            {t('totalHome', { amount: formatters.price(totalHomeAmount) })}
          </Title>
          {!isTotalHomeAmountFinal && (
            <Tooltip placement="right" title={t('warning')}>
              *
            </Tooltip>
          )}
        </Row>
      </Col>
    </StyledRow>
  );
}
