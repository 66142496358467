import { Page } from '@/components/Page';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

export function AboutUsPage() {
  const { t } = useTranslation(undefined, { keyPrefix: 'aboutUsPage', });
  const { Text, Paragraph, Link} = Typography

  return (
    <Page htmlTitle="About Us">
      <Page.Paths>
        <Page.Path>{t('path')}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card title={t('title')}>
          <Text>{t('body.firstLine')}</Text>
          <br/>
          <Text>{t('body.secondLine')}</Text>
          <br/>
          <br/>
          <Paragraph className={'text-center'}>
            <Text>{t('learnMore')}</Text> <Link href={t('url')}>{t('urlLabel')}</Link>
          </Paragraph>
        </Page.Card>
      </Page.Body>
    </Page>
  );
}
