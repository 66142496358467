import { Space, Switch } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export interface ImageMarkerBoxLockProps {
  locked: boolean;
  onChange: (locked: boolean) => any;
  className?: string;
}

export function ImageMarkerBoxLock(props: ImageMarkerBoxLockProps) {
  const { locked, onChange, className } = props;
  const { t } = useTranslation();
  const handleChange = useCallback(() => onChange(!locked), [locked, onChange]);

  return (
    <Space direction="horizontal">
      <Switch checked={!locked} onChange={handleChange} />
      <span className={className}> {t('imageMarkerBox.unlock')}</span>
    </Space>
  );
}
