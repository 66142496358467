import { confirmation, notify } from '@/components';
import { useDeleteCustomLegalLanguageMutation } from '@/redux/customLegalLanguage/customLegalLanguageApi';
import { useTranslation } from 'react-i18next';

const useDelete = () => {
  const [deleteLegalLanguage] = useDeleteCustomLegalLanguageMutation();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'customLegalLanguage.confirmation.delete',
  });

  return async (id: string) => {
    confirmation.show({
      body: t('body'),
      onConfirm: async () =>
        await deleteLegalLanguage({ id })
          .unwrap()
          .then(() => {
            notify.success.t('customLegalLanguage.details.deleted');
          }),
    });
  };
};

export function useDeleteLegalLanguageAction() {
  const handleDelete = useDelete();

  return {
    handleDelete,
  };
}
