import { Form } from '@/components';

export interface NameInputProps {
  name: string;
}

export function NameInput(props: NameInputProps){
  const { name } = props;

  return(
    <Form.Input
      name={`${name}.name`}
      className="w-50"
      label={false}
    />
  );
}