import { Form, notify, useAppFindFormik } from '@/components';
import { OPTIONS, useFloorplanLookupSource } from '@/views';
import { useTranslation } from 'react-i18next';
import { FloorplanDetailsDto, isChooseExistingOption } from '@/core';
import {
  AssignFloorplanToHomeArgs,
  useAddFloorplanMutation,
  useAssignFloorplanToHomeMutation,
} from '@/redux';
import { Button, Modal } from 'antd';
import { useBool } from '@/utils';
import { FormsRadio } from '@/components/Forms/FormsRadio';
import { FloorplanDetailsFormikForm } from '../Floorplans.Details/FloorplanDetailsFormikForm';
import { useCallback, useMemo } from 'react';
import styles from './HomeFloorplanButton.module.scss';
import {
  FloorplanCopyMenuForm,
  useFloorplanCopyMenu,
} from '../Floorplans.Details/FloorplanCopyMenuForm';

interface ReassignFloorplanButtonProps {
  floorplan: FloorplanDetailsDto;
  homeId: string;
}

const useSubmit = () => {
  const [addFloorplan] = useAddFloorplanMutation();
  const [assign] = useAssignFloorplanToHomeMutation();
  const floorplanFormik = useAppFindFormik('floorplan-details');
  return useCallback(
    (value: AssignFloorplanToHomeArgs) => {
      if (isChooseExistingOption(value.creationOption)) {
        assign({ floorplanId: value.floorplanId, homeId: value.homeId! })
          .unwrap()
          .then(() => notify.success.t('floorplans.saved'));
      } else {
        addFloorplan({
          ...floorplanFormik.current?.values,
          homeId: value.homeId,
        })
          .unwrap()
          .then(() => notify.success.t('floorplans.saved'));
      }
    },
    [addFloorplan, assign, floorplanFormik],
  );
};

export function ReassignFloorplanButton(props: ReassignFloorplanButtonProps) {
  const { floorplan, homeId } = props;
  const [reassignModal, , toggleReassignModal] = useBool(false);
  const floorplansDataSource = useFloorplanLookupSource(floorplan.communityId);
  const { t } = useTranslation();
  const floorplanCopyProps = useFloorplanCopyMenu('home-floorplan');
  const { floorplan: copyFromFloorplan } = floorplanCopyProps;

  const initialValues = useMemo(() => {
    return {
      ...floorplan,
      constructionName: copyFromFloorplan?.constructionName,
      type: copyFromFloorplan?.type,
      squareFootage: copyFromFloorplan?.squareFootage,
      description: copyFromFloorplan?.description,
      creationOption: 'existing',
      floorplanId: floorplan.id,
      homeId,
      exampleFloorplanId: copyFromFloorplan?.id,
    } as any;
  }, [
    copyFromFloorplan?.constructionName,
    copyFromFloorplan?.description,
    copyFromFloorplan?.id,
    copyFromFloorplan?.squareFootage,
    copyFromFloorplan?.type,
    floorplan,
    homeId,
  ]);

  const formik = useAppFindFormik('home-floorplan');
  const onCancel = () => {
    toggleReassignModal();
    formik?.current?.resetForm();
  };

  const submit = useSubmit();

  return (
    <>
      <Button type="link" onClick={toggleReassignModal} className={styles.link}>
        {t('floorplans.details.reassign')}
      </Button>
      <Modal
        visible={reassignModal}
        onCancel={onCancel}
        title={t('floorplans.details.reassign')}
        footer={
          <>
            <Button onClick={onCancel}>{t('cancel')}</Button>
            <Form.Submit type="primary" uid="home-floorplan">
              {t('save')}
            </Form.Submit>
          </>
        }
      >
        <Form.Formik<AssignFloorplanToHomeArgs>
          uid="home-floorplan"
          i18n="homes.details"
          initialValues={initialValues}
          onSubmit={(values, formik) => {
            submit(values);
            formik.resetForm();
            toggleReassignModal();
          }}
        >
          {({ values }) => (
            <>
              <FormsRadio
                name="creationOption"
                options={OPTIONS}
                label={t('floorplans.chooseExistingOrSetManually')}
                inline={false}
              />
              {isChooseExistingOption(values.creationOption) && (
                <>
                  <Form.AsyncSelect
                    name="floorplanId"
                    dataSource={floorplansDataSource}
                    required
                    preload
                    label={t('floorplans.header')}
                  />
                </>
              )}
              {values.creationOption === 'copy' && (
                <FloorplanCopyMenuForm
                  communityId={values.communityId}
                  {...floorplanCopyProps}
                />
              )}
              {!isChooseExistingOption(values.creationOption) && (
                <FloorplanDetailsFormikForm
                  onSubmit={submit}
                  initialValues={initialValues}
                />
              )}
            </>
          )}
        </Form.Formik>
      </Modal>
    </>
  );
}
