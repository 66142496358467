import { TaskTemplateMatrixRowState } from '@/redux';
import { Table } from '@/components';
import { useImportTaskTemplateColumns } from '@/views/Tasks.Details/ImportTasks/useImportTaskTemplateColumns';
import { useProjectContext } from '@/views';
import { Key, useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import styles from './ImportTask.module.scss';

interface Props {
  name: string;
  data: TaskTemplateMatrixRowState[];
  isFetching: boolean;
}

export function ImportTasksListPanel(props: Props) {
  const { data, isFetching, name } = props;
  const { communityId, projectId } = useProjectContext();
  const columns = useImportTaskTemplateColumns(communityId, projectId);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>();
  const [{ value }] = useField<TaskTemplateMatrixRowState[]>('rows');
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (selectedKeys) {
      const selectedKeyValues = selectedKeys.map((x) => x.toString());
      setFieldValue(
        'rows',
        value.map((x, index) => ({
          ...x,
          checked: selectedKeyValues?.includes(index.toString()),
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKeys, setFieldValue]);

  return (
    <>
      <Table<TaskTemplateMatrixRowState>
        name={name}
        rowSelection={{
          type: 'checkbox',
          onChange: (keys) => {
            setSelectedKeys(keys);
          },
        }}
        loading={isFetching}
        columns={columns}
        dataSource={data}
        rowKey={(_, index) => `${index}`}
        pagination={false}
        scroll={{ x: 1700, y: 350 }}
        hideDefaultActions
        className={styles.table}
      />
    </>
  );
}
