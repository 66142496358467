import { Form } from '@/components';
import {
  AddSpecialRequestCommentArgs,
  UpdateSpecialRequestCommentArgs,
} from '@/redux';
import { Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props<
  TValue extends AddSpecialRequestCommentArgs | UpdateSpecialRequestCommentArgs,
> {
  value: TValue;
  onSubmit: (value: TValue) => any;
  onCancel: () => any;
  separateForm?: boolean;
}

export function FormSpecialRequestComment<
  TValue extends AddSpecialRequestCommentArgs | UpdateSpecialRequestCommentArgs,
>(props: Props<TValue>) {
  const { t } = useTranslation();
  const { value, onSubmit, onCancel, separateForm } = props;

  return (
    <Form.Formik<TValue>
      uid="request-comment"
      initialValues={value}
      onSubmit={(value, formik) => {
        onSubmit(value);
        separateForm && formik.resetForm();
      }}
    >
      <Form.TextArea
        name="content"
        required
        label={false}
        autoSize={false}
        rows={4}
      />

      <Space>
        {onCancel && <Button onClick={onCancel}>{t('cancel')}</Button>}
        <Form.Submit type="primary">{t('save')}</Form.Submit>
      </Space>
    </Form.Formik>
  );
}
