import { Icon, Page, StyledRow } from '@/components';
import { Col, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@/components/Styled/IconButton';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { IndexImage, IndexNotificationsPanel, IndexSelectPanel } from '@/views';
import { useGetProjectQuery, useUserContextSelector } from '@/redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '@/views/Index.Common/IndexImage.module.scss';
import { Content } from 'antd/lib/layout/layout';

const { Link, Title } = Typography;

const getFromSessionStorage = (key: string) =>
  sessionStorage.getItem(key) || undefined;

export function IndexPage() {
  const { t } = useTranslation(undefined, { keyPrefix: 'index' });

  const hasRole = useUserContextSelector((x) => x.hasRole);
  const isAdmin = hasRole('BFP_ADMIN');
  const [userId, setUserId] = useState<string | undefined>(
    getFromSessionStorage('userId'),
  );
  const currentUserId = useUserContextSelector((x) => x.id);
  const [usePreload, setUserPreload] = useState(userId === currentUserId);

  const [clientId, setClientId] = useState<string | undefined>(
    usePreload ? getFromSessionStorage('clientId') : undefined,
  );
  const [communityId, setCommunityId] = useState<string | undefined>(
    usePreload ? getFromSessionStorage('communityId') : undefined,
  );
  const [projectId, setProjectId] = useState<string | undefined>(
    usePreload ? getFromSessionStorage('projectId') : undefined,
  );

  useEffect(() => {
    if (currentUserId !== userId) {
      setUserId(currentUserId);
      sessionStorage.removeItem('userId');
      currentUserId && sessionStorage.setItem('userId', currentUserId);
    }

    setUserPreload(userId === currentUserId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, projectId, userId]);

  const { data: projectDetails } = useGetProjectQuery(
    { id: projectId! },
    { skip: !projectId },
  );
  const pmLiteEnabled = projectDetails?.pmLiteEnabled;

  const push = useNavigate();

  const tooltip = !(pmLiteEnabled || !projectId) && t('pmDisabledTooltip');

  return (
    <Page htmlTitle="Index">
      <Page.Paths>
        <Page.Path>{t('path')}</Page.Path>
      </Page.Paths>
      <Page.Body>
        <Content>
          <StyledRow className={'mb-3'}>
            <Col span={24}>
              <div className={styles.container}>
                <StyledRow>
                  <Col span={20}>
                    <IndexSelectPanel
                      clientId={clientId}
                      communityId={communityId}
                      projectId={projectId}
                      setClientId={setClientId}
                      setCommunityId={setCommunityId}
                      setProjectId={setProjectId}
                      isAdmin={isAdmin}
                    />
                  </Col>
                </StyledRow>
                {communityId && <IndexImage communityId={communityId} />}
              </div>
            </Col>
          </StyledRow>
          <StyledRow className={'mb-3'}>
            <Col xl={9} lg={9} md={12} sm={12} xs={12}>
              <IndexNotificationsPanel />
            </Col>
            <Col xl={15} lg={15} md={12} sm={12} xs={12}>
              <StyledRow className={'mb-3'}>
                <Col span={6}>
                  <IconButton
                    buttonIcon={<Icon type={'home'} />}
                    title={t('buttons.homes')}
                    bgColor="navy"
                    path={communityId && `/communities/${communityId}#homes`}
                  />
                </Col>
                <Col span={6}>
                  <IconButton
                    buttonIcon={<Icon type="template" />}
                    title={t('buttons.workspace')}
                    bgColor="cyan"
                    path={
                      projectId &&
                      (projectDetails?.pmLiteEnabled
                        ? `/projects/${projectId}/workspace`
                        : `/projects/${projectId}/home-personalization`)
                    }
                  />
                </Col>
                <Col span={6}>
                  <IconButton
                    buttonIcon={<ExclamationCircleOutlined />}
                    title={t('buttons.specialRequests')}
                    bgColor="blue"
                    path={
                      communityId &&
                      `/communities/${communityId}#special-request-pricing`
                    }
                  />
                </Col>
                <Col span={6}>
                  <IconButton
                    buttonIcon={<Icon type={'clipboard'} />}
                    title={t('buttons.tasks')}
                    bgColor="emerald"
                    path={projectId && `/projects/${projectId}/task-management`}
                  />
                </Col>
              </StyledRow>
              <StyledRow wrap>
                <Col xl={8} lg={8} md={12} sm={12} xs={12}>
                  <Page.Card className={'h-100'}>
                    <Title level={3}>{t('cards.projectExecution')}</Title>
                    <Tooltip title={tooltip}>
                      <Link
                        href={projectId && `/projects/${projectId}/workspace`}
                        disabled={!projectId || !pmLiteEnabled}
                      >
                        {t('links.combinedWorkspace')}
                      </Link>
                    </Tooltip>
                    <br />
                    <Link
                      href={
                        projectId &&
                        `/projects/${projectId}/home-personalization`
                      }
                      disabled={!projectId || !pmLiteEnabled}
                    >
                      {t('links.personalization')}
                    </Link>
                    <br />
                    <Tooltip title={tooltip}>
                      <Link
                        href={
                          projectId && `/projects/${projectId}/develop-scope`
                        }
                        disabled={!projectId || !pmLiteEnabled}
                      >
                        {t('links.developScope')}
                      </Link>
                    </Tooltip>
                    <br />
                    <Link
                      href={
                        communityId &&
                        `/communities/${communityId}#special-request-pricing`
                      }
                      disabled={!communityId}
                    >
                      {t('links.specialRequests')}
                    </Link>
                    <br />
                    <Tooltip title={tooltip}>
                      <Link
                        href={
                          projectId && `/projects/${projectId}/task-management`
                        }
                        disabled={!projectId || !pmLiteEnabled}
                      >
                        {t('links.taskManagement')}
                      </Link>
                    </Tooltip>
                    <br />
                    <Tooltip title={tooltip}>
                      <Link
                        href={
                          projectId &&
                          `/projects/${projectId}/financial-management`
                        }
                        disabled={!projectId || !pmLiteEnabled}
                      >
                        {t('links.costManagement')}
                      </Link>
                    </Tooltip>
                    <br />
                    <Link
                      onClick={() =>
                        (projectId || communityId || clientId) &&
                        push('/reports', {
                          state: { projectId, communityId, clientId },
                        })
                      }
                    >
                      {t('links.reports')}
                    </Link>
                    <br />
                  </Page.Card>
                </Col>
                <Col xl={8} lg={8} md={12} sm={12} xs={12}>
                  <Page.Card className={'h-100'}>
                    <Title level={3}>{t('cards.communitySetUp')}</Title>
                    <Link
                      href={communityId && `/communities/${communityId}#homes`}
                      disabled={!communityId}
                    >
                      {t('links.homes')}
                    </Link>
                    <br />
                    <Link
                      href={
                        communityId && `/communities/${communityId}#projects`
                      }
                      disabled={!communityId}
                    >
                      {t('links.projects')}
                    </Link>
                    <br />
                    <Link
                      href={
                        communityId &&
                        `/communities/${communityId}#items-and-categories`
                      }
                      disabled={!communityId}
                    >
                      {t('links.itemsAndCategories')}
                    </Link>
                    <br />
                    <Link
                      href={
                        communityId && `/communities/${communityId}#pricing`
                      }
                      disabled={!communityId}
                    >
                      {t('links.pricing')}
                    </Link>
                    <br />
                    <Link
                      href={
                        communityId &&
                        `/communities/${communityId}#subcontractors`
                      }
                      disabled={!communityId}
                    >
                      {t('links.subcontractors')}
                    </Link>
                    <br />
                  </Page.Card>
                </Col>
                <Col xl={8} lg={8} md={12} sm={12} xs={12}>
                  <Page.Card className={'h-100'}>
                    <Title level={3}>{t('cards.administration')}</Title>
                    <Link
                      href={communityId && `/communities/${communityId}#roles`}
                      disabled={!communityId}
                    >
                      {t('links.roles')}
                    </Link>
                    <br />
                    <Link
                      href={
                        communityId &&
                        `/communities/${communityId}#path-templates`
                      }
                      disabled={!communityId}
                    >
                      {t('links.pathTemplates')}
                    </Link>
                    <br />
                    <Link
                      href={communityId && `/communities/${communityId}#menus`}
                      disabled={!communityId}
                    >
                      {t('links.menus')}
                    </Link>
                    <br />
                  </Page.Card>
                </Col>
              </StyledRow>
            </Col>
          </StyledRow>
        </Content>
      </Page.Body>
    </Page>
  );
}
