import { EnumValue } from '@/components';
import { DevelopScopeTaskStatus } from '@/core';
import { enumValues } from '@/utils';
import { Col, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './DevelopScope.module.scss';

interface Props {
  onSelected: (value: DevelopScopeTaskStatus[] | undefined) => any;
  selected: DevelopScopeTaskStatus[] | undefined;
  className?: string;
  compact?: boolean;
}

export function DevelopScopeTaskStatusFilter(props: Props) {
  const { onSelected, selected, className, compact } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'developScope.details',
  });

  return (
    <Row className={className} justify="end">
      <Col span={compact ? 20 : 6} className={styles.filter}>
        <Select<DevelopScopeTaskStatus[]>
          className="w-100"
          value={selected}
          placeholder={t('statusPlaceholder')}
          labelInValue={false}
          onChange={onSelected}
          mode="multiple"
          allowClear
          showSearch={false}
        >
          {enumValues(DevelopScopeTaskStatus).map((value) => (
            <Select.Option key={value}>
              <EnumValue type={DevelopScopeTaskStatus} value={value} />
            </Select.Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
}
