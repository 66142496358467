import { Button, ButtonProps } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

export type CopyIconButtonProps = Omit<ButtonProps, 'icon' | 'children'> & {};

export function CopyIconButton(props: CopyIconButtonProps) {
  const { onClick, ...others } = props;
  return (
    <Button type="link" {...others} onClick={onClick} icon={<CopyOutlined />} />
  );
}
