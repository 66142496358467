import { useField } from 'formik';
import { useCallback } from 'react';
import { AsyncSelect, AsyncSelectProps } from '../AsyncSelect';
import { FormsGroup } from './FormsGroup';
import { useFormLabel } from './FormsI18nContext';

export interface FormsAsyncSelectProps
  extends Omit<AsyncSelectProps, 'value' | 'onChange'> {
  name: string;
  label?: React.ReactNode;
  required?: boolean;
  mode?: 'multiple';
  disabled?: boolean;
}

function useHandleChange(props: FormsAsyncSelectProps) {
  const { name } = props;
  const [, , { setValue, setTouched }] = useField(name);

  return useCallback(
    (value: string | undefined) => {
      setTouched(true);
      setValue(value == null ? null : value, true);
    },
    [setValue, setTouched],
  );
}

function useHandleClear(props: FormsAsyncSelectProps) {
  const { mode, name } = props;
  const [, , { setValue, setTouched }] = useField(name);

  return useCallback(() => {
    setValue(mode === 'multiple' ? [] : null, true);
    setTouched(true, true);
  }, [setValue, setTouched, mode]);
}

export function FormsAsyncSelect(props: FormsAsyncSelectProps) {
  const {
    name,
    label: labelOverride,
    required,
    disabled,
    ...asyncSelectProps
  } = props;
  const [{ value }, , { setTouched }] = useField(name);
  const label = useFormLabel(name, labelOverride);
  const handleChange = useHandleChange(props);
  const handleBlur = useCallback(() => setTouched(true, true), [setTouched]);
  const handleClear = useHandleClear(props);

  return (
    <FormsGroup label={label} name={name} required={required}>
      <AsyncSelect
        {...asyncSelectProps}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onClear={handleClear}
        disabled={disabled}
      />
    </FormsGroup>
  );
}
