import { Title } from '@/components';
import React, { FC, PropsWithChildren } from 'react';
import { ReactChildren } from '@/utils';
import { TabPanelNavMenu } from './Menu/TabPanelNavMenu';
import styles from './TabPanel.module.scss';
import classNames from 'classnames';

export const TabPanelTitle: FC<PropsWithChildren<{}>> = () => <></>;
export const TabPanelActions: FC<PropsWithChildren<{}>> = () => <></>;

export const TabPanelBody: FC<
  PropsWithChildren<{ pre?: React.ReactNode; compact?: boolean }>
> = () => <></>;

export const TabPanelNav: FC<
  PropsWithChildren<{ title?: React.ReactNode; className?: string }>
> = () => <></>;

function _TabPanel({
  children,
  min = 'no',
}: PropsWithChildren<{ min?: 'auto' | 'no' }>) {
  const title = ReactChildren.of(children)
    .find(TabPanelTitle)
    ?.props()?.children;

  const actions = ReactChildren.of(children)
    .find(TabPanelActions)
    ?.props()?.children;

  const body = ReactChildren.of(children).find(TabPanelBody)?.props();
  const nav = ReactChildren.of(children).find(TabPanelNav)?.props();

  return (
    <div>
      {title && (
        <Title actions={actions} level={1}>
          {title}
        </Title>
      )}
      {body?.pre && <div>{body.pre}</div>}
      <div className={classNames(styles.content, min === 'auto' && 'min-auto')}>
        {nav && (
          <div className={classNames(styles.nav, nav.className)}>
            {nav.title && (
              <Title className={styles.navTitle}>{nav.title}</Title>
            )}
            {nav.children}
          </div>
        )}
        <div className={classNames(styles.body, body.compact && '--compact')}>
          {body.children}
        </div>
      </div>
    </div>
  );
}

export const TabPanel = Object.assign(_TabPanel, {
  Title: TabPanelTitle,
  Body: TabPanelBody,
  Actions: TabPanelActions,
  Menu: TabPanelNavMenu,
  Nav: TabPanelNav,
});
