import {
  SubOptionState,
  useGetSubOptionsByIdsListQuery,
  useGetSubOptionsListQuery,
} from '@/redux';
import { AddSubOptionButton } from './AddSubOptionButton';
import { SubOptionItem } from './SubOptionDetails';
import { Checkbox, Col, List, Radio, Row } from 'antd';
import styles from './SuboptionsList.module.scss';
import { Assert } from '../Communities.Common';
import { CommunityPermissions } from '@/core';

export interface SuboptionsListSelectableRadio {
  type: 'radio';
  selected?: string;
  onSelect: (id: string) => any;
}

export interface SuboptionsListSelectableCheck {
  type: 'check';
  selected: string[];
  onSelect: (ids: string[]) => any;
  disabled?: boolean;
}

export interface SuboptionsListProps {
  name: string;
  itemId?: string;
  communityId?: string;
  className?: string;
  readonly?: boolean;
  includedUnlistedSuboptions?: string[];
  selectable?: SuboptionsListSelectableRadio | SuboptionsListSelectableCheck;
  renderItem?: (args: {
    item: SubOptionState;
    index: number;
    content: React.ReactElement;
  }) => React.ReactElement;
  dataSource?: SubOptionState[];
  onSubmitHandler?: (subOptionId: string) => void;
  subOptionsIds?: string[];
}

function ListItem(
  props: Pick<SuboptionsListProps, 'selectable' | 'readonly'> & {
    suboption: SubOptionState;
  },
) {
  const { readonly, suboption, selectable } = props;
  const content = <SubOptionItem readonly={readonly} subOption={suboption} />;

  if (!selectable) {
    return content;
  }

  if (selectable.type === 'check') {
    const { selected, onSelect, disabled } = selectable;

    return (
      <Row align="middle" wrap={false}>
        <Col flex="none" className="pe-3">
          <Checkbox
            className="mb-1"
            disabled={disabled}
            checked={selected.includes(suboption.id)}
            onChange={(e) =>
              e.target.checked
                ? onSelect([...selected, suboption.id])
                : onSelect(selected.filter((x) => x !== suboption.id))
            }
          />
        </Col>

        <Col flex="auto" className={styles.listItemContent}>
          {content}
        </Col>
      </Row>
    );
  }

  return (
    <div className={styles.listItemSelectable}>
      <div className={styles.listItemRadio}>
        <Radio
          checked={selectable.selected === suboption.id}
          onChange={() => selectable.onSelect(suboption.id)}
        />
      </div>

      <div className={styles.listItemContent}>{content}</div>
    </div>
  );
}

export function SuboptionsList(props: SuboptionsListProps) {
  const {
    name,
    itemId,
    communityId,
    className,
    readonly,
    includedUnlistedSuboptions,
    renderItem,
    dataSource,
    onSubmitHandler,
    subOptionsIds,
  } = props;
  const { data: subOptions } = useGetSubOptionsListQuery(
    {
      itemId,
      includedUnlistedSuboptions,
    },
    { skip: !!dataSource || itemId == null },
  );
  const { data: subOptionsWithoutItem } = useGetSubOptionsByIdsListQuery(
    {
      ids: subOptionsIds,
    },
    { skip: !!dataSource || itemId != null },
  );

  const dataSouce = dataSource || subOptions || subOptionsWithoutItem;
  if (!dataSouce) {
    return null;
  }

  return (
    <div className={className}>
      <div>
        {!readonly && (
          <Assert permission={CommunityPermissions.ItemsCategories.Manage}>
            <AddSubOptionButton
              key={name}
              itemId={itemId}
              communityId={communityId}
              className="mb-3"
              onSubmitHandler={onSubmitHandler}
            />
          </Assert>
        )}
      </div>
      <List
        itemLayout="horizontal"
        dataSource={dataSouce}
        rowKey={(row) => row.id}
        renderItem={(subOption, index) => {
          const listItemEl = <ListItem {...props} suboption={subOption} />;
          return renderItem
            ? renderItem({ item: subOption, content: listItemEl, index })
            : listItemEl;
        }}
      />
    </div>
  );
}
