import { StyledRow } from '@/components';
import { formatters } from '@/core';
import { Col, Typography } from 'antd';
import styles from './PersonalizationSummary.module.scss';

interface Props {
  title: string;
  value: number;
}

const Title = Typography.Title;

export function PersonalizationSummaryInfo(props: Props) {
  const { title, value } = props;

  return (
    <StyledRow className={styles.summaryInfo}>
      <Col span={17}>
        <Title className={styles.subtotalTitle} level={4}>
          {title}
        </Title>
      </Col>
      <Col span={6}>
        <Title level={4} className={styles.subtotalValue}>
          {formatters.money(value)}
        </Title>
      </Col>
    </StyledRow>
  );
}
