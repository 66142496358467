import { notify } from '@/components';
import { useGetItemMenuDetailsQuery, useUpdateItemMenuMutation } from '@/redux';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ItemMenuDetailsForm,
  ItemMenuDetailsFormValue,
  ItemMenuDetailsSubmitValue,
} from './ItemMenuDetailsForm';

interface Props {
  onCancel: () => any;
  id: string;
}

const useSubmit = (props: Props) => {
  const { id, onCancel } = props;
  const [update] = useUpdateItemMenuMutation();

  return (args: ItemMenuDetailsSubmitValue) =>
    update({ ...args, id })
      .unwrap()
      .then(() => notify.success.t('itemMenus.details.detailsSaved'))
      .then(onCancel);
};

export function EditItemMenu(props: Props) {
  const { onCancel, id } = props;
  const submit = useSubmit(props);
  const { t } = useTranslation();
  const { data } = useGetItemMenuDetailsQuery({ id, listed: null });

  const formValue = useMemo(() => {
    if (!data) return null;

    const value: ItemMenuDetailsFormValue = {
      name: data.name,
      items: data.items.map((x) => ({
        itemId: x.id,
        type: x.type,
        suboptions: { all: false, values: x.suboptions },
        pristine: true,
        noSuboptions: null,
        initialSuboptions: x.suboptions,
        standardCredit: x.standardCredit,
        categoryId: x.categoryPath.at(-1)!.id,
      })),
    };

    return value;
  }, [data]);

  if (!data) {
    return null;
  }

  const { communityId, items } = data;

  return (
    <ItemMenuDetailsForm
      texts={{
        saveBtn: t('itemMenus.details.editButton'),
        title: t('itemMenus.details.editTitle'),
      }}
      value={formValue!}
      communityId={communityId}
      onSubmit={submit}
      onCancel={onCancel}
      initialItems={items}
    />
  );
}
