import { SortResult } from '@/components/Collections.Paging/useSorting';
import {
  AddSubcontractorDto,
  Http,
  Paged,
  Paging,
  SubcontractorDetailsDto,
  SubcontractorDto,
  SubcontractorFilterParams,
  UpdateSubcontractorDto,
} from '@/core';
import { guard } from '@/utils';
import { isArray } from 'lodash';
import qs from 'qs';

class SubcontractorsHttp extends Http {
  public async getAll(
    paging: Paging,
    filters?: SubcontractorFilterParams,
    sorting?: SortResult,
    communityId?: string | string[],
  ) {
    const query = qs.stringify({
      communityIds: isArray(communityId) ? communityId : [communityId],
      ...paging,
      ...filters,
      ...sorting,
    });
    const response = await this._axios.get<Paged<SubcontractorDto>>(
      `/api/subcontractor?${query}`,
    );
    return response.data;
  }

  public async get(id: string) {
    guard.notNull(id, 'id');
    const response = await this._axios.get<SubcontractorDetailsDto>(
      `/api/subcontractor/${id}`,
    );
    return response.data;
  }

  public async create(request: AddSubcontractorDto) {
    guard.notNull(request, 'request');
    const response = await this._axios.post<string>(
      `/api/subcontractor`,
      request,
    );
    return response.data;
  }

  public async update(request: UpdateSubcontractorDto) {
    guard.notNull(request, 'request');
    await this._axios.put(`/api/subcontractor`, request);
  }

  public async unlist(id: string) {
    guard.notNull(id, 'id');
    await this._axios.put(`/api/subcontractor/${id}/unlist`);
  }
}

export const subcontractorsHttp = new SubcontractorsHttp();
