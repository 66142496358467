import { Http } from '../http/http';
import { guard } from '@/utils';
import {
  AddPathToProjectDto,
  CreateProjectDto,
  LocationDeadlineFilterParams,
  ProjectContextDto,
  ProjectDto,
  ProjectFilterParams,
  ProjectHomeDeadlineInfoDto,
  ProjectHomeInfoDto,
  ProjectHomeStepDeadlineLookupDto,
  ProjectLocationDeadlineInfoDto,
  ProjectLocationStepDeadlineLookupDto,
  ProjectPathDetailsDto,
  ProjectPathDto,
  ProjectStepLookupDto,
  UpdateProjectDeadlineDto,
  UpdateProjectDto,
  UpdateProjectHomeFloorplanImageDto,
  UpdateProjectPathDto,
  UpdateProjectStatusDto,
} from './projectTypes';
import { ItemMenuItemDto, Paged, Paging } from '..';
import qs from 'qs';
import { isArray } from 'lodash';
import { SortResult } from '@/components/Collections.Paging/useSorting';

class ProjectsHttp extends Http {
  public async getAll(args?: {
    clientId?: string;
    communityId?: string | string[];
    isActive?: boolean;
    paging: Paging;
    filters?: ProjectFilterParams;
    sorting?: SortResult;
  }) {
    // TODO: Add ability to pass multiple communities filter to backend
    const query = qs.stringify({
      ...args,
      ...args?.paging,
      ...args?.filters,
      ...args?.sorting,
    });
    const url = `/api/project?${query}`;

    const response = await this._axios.get<Paged<ProjectDto>>(url);
    return response.data;
  }

  public async get(id: string) {
    guard.notNull(id, 'id');
    const response = await this._axios.get<ProjectDto>(`/api/project/${id}`);
    return response.data;
  }

  public async getMenuItems(projectId: string, homeId: string) {
    guard.notNull(projectId, 'projectId');
    guard.notNull(homeId, 'homeId');
    const uri = `/api/project/home/menu-items?${qs.stringify({
      projectId,
      homeId,
    })}`;
    const response = await this._axios.get<ItemMenuItemDto[]>(uri);
    return response.data;
  }

  public async create(request: CreateProjectDto) {
    guard.notNull(request, 'request');
    const response = await this._axios.post<string>('/api/project', request);
    return response.data;
  }

  public async update(request: UpdateProjectDto) {
    guard.notNull(request, 'request');
    const { id } = request;
    await this._axios.put(`/api/project/${id}`, request);
  }

  public async updateStatus(request: UpdateProjectStatusDto) {
    guard.notNull(request, 'request');
    const { id } = request;
    await this._axios.put(`/api/project/${id}/status`, request);
  }

  public async addPath(request: AddPathToProjectDto) {
    guard.notNull(request, 'request');
    const { projectId } = request;
    await this._axios.put(`/api/project/${projectId}/add-path`, request);
  }

  public async updatePath(request: UpdateProjectPathDto) {
    guard.notNull(request, 'request');
    const { projectId } = request;
    await this._axios.put(`/api/project/${projectId}/path`, request);
  }

  public async getPath(projectId: string) {
    guard.notNull(projectId, 'projectId');
    const response = await this._axios.get<ProjectPathDto>(
      `/api/project/${projectId}/path`,
    );
    return response.data;
  }

  public async getPathDetails(projectId: string) {
    guard.notNull(projectId, 'projectId');
    const response = await this._axios.get<ProjectPathDetailsDto>(
      `/api/project/${projectId}/path-details`,
    );
    return response.data;
  }

  public async updateHomes(projectId: string, homeIds: string[]) {
    guard.notNull(projectId, 'projectId');
    guard.notNull(homeIds, 'homeIds');
    await this._axios.put(`/api/project/${projectId}/homes`, homeIds);
  }

  public async updateDeadlines(request: UpdateProjectDeadlineDto) {
    guard.notNull(request, 'request');
    await this._axios.put(`/api/project/${request.id}/deadlines`, request);
  }

  public async unlist(id: string) {
    guard.notNull(id, 'id');
    await this._axios.put(`/api/project/${id}/unlist`);
  }

  public async getSteps(projectIds?: string | string[]) {
    const query = qs.stringify({
      projectIds: isArray(projectIds) ? projectIds : [projectIds],
    });
    const response = await this._axios.get<ProjectStepLookupDto[]>(
      `/api/project/steps?${query}`,
    );
    return response.data;
  }

  public async deletePath(projectId: string) {
    guard.notNull(projectId, 'projectId');
    await this._axios.delete(`/api/project/${projectId}/path`);
  }

  public async getContext(id: string) {
    guard.notNull(id, 'id');
    const response = await this._axios.get<ProjectContextDto>(
      `/api/project/${id}/context`,
    );
    return response.data;
  }

  public async getProjectHomeContext(args: { id: string; homeId: string }) {
    guard.notNull(args.id, 'id');
    guard.notNull(args.homeId, 'homeId');

    const response = await this._axios.get<ProjectHomeInfoDto>(
      `/api/project/${args.id}/home/${args.homeId}/info`,
    );
    return response.data;
  }

  public async getProjectsHomesDeadlinesInfo(args: {
    projectId: string;
    paging: Paging;
    sorting?: SortResult;
  }) {
    guard.notNull(args.projectId, 'projectId');

    const response = await this._axios.get<Paged<ProjectHomeDeadlineInfoDto>>(
      `/api/project/${args.projectId}/homes-deadlines?${qs.stringify({
        ...args.paging,
        ...args.sorting,
      })}`,
    );
    return response.data;
  }

  public async getProjectLocationDeadlinesInfo(args: {
    projectId: string;
    paging: Paging;
    sorting?: SortResult;
    filters?: LocationDeadlineFilterParams,
  }) {
    guard.notNull(args.projectId, 'projectId');

    const response = await this._axios.get<
      Paged<ProjectLocationDeadlineInfoDto>
    >(
      `/api/project/${args.projectId}/location-deadlines?${qs.stringify({
        ...args.paging,
        ...args.sorting,
        ...args.filters
      })}`,
    );
    return response.data;
  }

  public async updateProjectHomeFloorplanImage(
    request: UpdateProjectHomeFloorplanImageDto,
  ) {
    guard.notNull(request, 'request');
    const { homeId } = request;
    await this._axios.put(`/api/project/home/${homeId}/image`, request);
  }

  public async updateProjectHomePathStepDeadline(
    request: ProjectHomeStepDeadlineLookupDto,
  ) {
    guard.notNull(request, 'request');
    const { projectId } = request;
    await this._axios.put(`/api/project/${projectId}/homes-deadlines`, request);
  }

  public async updateProjectLocationPathStepDeadline(
    request: ProjectLocationStepDeadlineLookupDto,
  ) {
    guard.notNull(request, 'request');
    const { projectId } = request;
    await this._axios.put(
      `/api/project/${projectId}/location-deadlines`,
      request,
    );
  }

  public async getProjectHomeSteps(args: {
    projectId: string;
    homeId: string;
  }) {
    const query = qs.stringify(args);
    const response = await this._axios.get<ProjectHomeStepDeadlineLookupDto[]>(
      `/api/project/home-steps?${query}`,
    );
    return response.data;
  }
}

export const projectsHttp = new ProjectsHttp();
