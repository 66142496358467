import { CommunityPermissions } from '@/core';
import { Checkbox, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCommunityContext } from '../Communities.Common';

import styles from './PersonalizationSummary.module.scss';

export interface PriceColumnsCheckboxGroupProps {
  showResidentPrice: boolean;
  onShowResidentPriceChange: (e: CheckboxChangeEvent) => any;

  showBaseCost: boolean;
  onShowBaseCostChange: (e: CheckboxChangeEvent) => any;
}

export function usePriceColumnsCheckboxGroup(): PriceColumnsCheckboxGroupProps {
  const [showResidentPrice, setShowResidentPrice] = useState(true);
  const [showBaseCost, setShowBaseCost] = useState(false);

  const onShowResidentPriceChange = useCallback(
    (e: CheckboxChangeEvent) => setShowResidentPrice(e.target.checked),
    [],
  );
  const onShowBaseCostChange = useCallback(
    (e: CheckboxChangeEvent) => setShowBaseCost(e.target.checked),
    [],
  );

  return {
    showResidentPrice,
    showBaseCost,
    onShowBaseCostChange,
    onShowResidentPriceChange,
  };
}

export function PriceColumnsCheckboxGroup(
  props: PriceColumnsCheckboxGroupProps,
) {
  const {
    showResidentPrice,
    showBaseCost,
    onShowBaseCostChange,
    onShowResidentPriceChange,
  } = props;

  const { t } = useTranslation(undefined, {
    keyPrefix: 'personalization.summary',
  });

  const { hasPermission } = useCommunityContext();

  return (
    <div className={styles.priceColumnsCheckboxGroup}>
      <Space size="large">
        {hasPermission(CommunityPermissions.BaseCost.FullAccess) && (
          <Checkbox onChange={onShowBaseCostChange} checked={showBaseCost}>
            {t('showBaseCost')}
          </Checkbox>
        )}
        <Checkbox
          checked={showResidentPrice}
          onChange={onShowResidentPriceChange}
        >
          {t('showResidentPrice')}
        </Checkbox>
      </Space>
    </div>
  );
}
