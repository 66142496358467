import { Icon, Page } from '@/components';
import { useParams } from 'react-router-dom';
import { TaskFinancialInfoListPanel } from '@/views';
import { useTranslation } from 'react-i18next';

export function ProjectFinancialInfoPage() {
  const { t } = useTranslation();
  const { id } = useParams<'id'>();

  return (
    <Page.Card
      title={t('tasks.financialInfo.title')}
      icon={<Icon type="dollar" />}
      color="#44A2B4"
    >
      {id && <TaskFinancialInfoListPanel projectId={id!} />}
    </Page.Card>
  );
}
