import {
  TextBox,
  EditIconButton,
  notify,
  Attach,
  ImageBox,
  Title,
  useImageBox,
  YesNo,
  EnumValue,
} from '@/components';
import {
  useAppClippo,
  CommunityPermissions,
  ItemPricePer,
  ItemMenuItemType,
} from '@/core';
import {
  UpdateItemArgs,
  useGetItemDetailsQuery,
  useUpdateItemMutation,
} from '@/redux';
import { Col, Row, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Assert } from '../Communities.Common';
import { SuboptionsList, SuboptionsListProps } from '../Item.SubOptions';
import { ItemDetailsBookletLinks } from './ItemDetailsBookletLinks';
import { ItemEditPanel } from './ItemEditPanel';
import { ItemDetailsExternalLinks } from './ItemDetailsExternalLinks';

export interface ItemDetailsPanelProps {
  id: string;
  readonly?: boolean;
  includeUnlistedSuboptions?: string[];
  selectableSuboption?: SuboptionsListProps['selectable'];
  detailsFooter?: React.ReactElement;
  renderSuboptionItem?: SuboptionsListProps['renderItem'];
  subOptions?: SuboptionsListProps['dataSource'];
  itemMenuItemType?: ItemMenuItemType;
}

const useSubmit = (setEdit: (value: boolean) => any) => {
  const [update] = useUpdateItemMutation();

  return async (values: UpdateItemArgs) => {
    await update(values)
      .unwrap()
      .then(() => notify.success.t('items.details.saved'))
      .then(() => setEdit(false));
  };
};

export function ItemDetailsPanel(props: ItemDetailsPanelProps) {
  const {
    id,
    readonly,
    selectableSuboption,
    includeUnlistedSuboptions,
    detailsFooter,
    renderSuboptionItem,
    subOptions,
    itemMenuItemType,
  } = props;
  const { currentData: data } = useGetItemDetailsQuery({ id });
  const { t } = useTranslation();

  const attach = useAppClippo({
    ownerId: id,
    ownerType: 'Item',
    defer: false,
    noPreload: true,
  });

  const imageBox = useImageBox({
    defer: false,
    ownerId: id,
    ownerType: 'ItemImage',
    noPreload: true,
  });

  const [edit, setEdit] = useState(false);
  const submit = useSubmit(setEdit);

  useEffect(() => {
    attach.fetch();
    imageBox.fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (!data) {
    return null;
  }

  const {
    constructionDescription,
    description,
    name,
    pricePer,
    number,
    isGeo,
    communityId,
  } = data;

  return (
    <div>
      {!edit && (
        <>
          <Row>
            <Col span={12}>
              {!readonly && (
                <Title
                  level={2}
                  actions={
                    !readonly && (
                      <Assert
                        permission={CommunityPermissions.ItemsCategories.Manage}
                        active
                      >
                        <EditIconButton
                          type="link"
                          onClick={() => setEdit(true)}
                        />
                      </Assert>
                    )
                  }
                  inlineActions
                >
                  {t('items.details.title')}
                </Title>
              )}
              {readonly && (
                <Typography.Title level={2}>{name}</Typography.Title>
              )}
              <TextBox label={t('items.details.id')}>{number}</TextBox>
              {!readonly && (
                <TextBox label={t('items.details.name')}>{name}</TextBox>
              )}
              <TextBox label={t('items.details.description')}>
                <div className="text-multiline">{description}</div>
              </TextBox>
              <TextBox label={t('items.details.constructionDescription')}>
                <div className="text-multiline">{constructionDescription}</div>
              </TextBox>
              <TextBox label={t('items.details.pricePer')}>
                <EnumValue type={ItemPricePer} value={pricePer} />
              </TextBox>
              <TextBox label={t('items.details.isGeo')}>
                <YesNo>{isGeo}</YesNo>
              </TextBox>
              {itemMenuItemType && (
                <div className="mt-3">
                  <EnumValue type={ItemMenuItemType} value={itemMenuItemType} />
                </div>
              )}
            </Col>
            <Col span={11} offset={1}>
              <Title
                level={2}
                actions={
                  !readonly && (
                    <Assert
                      permission={CommunityPermissions.ItemsCategories.Manage}
                      active
                    >
                      <EditIconButton
                        type="link"
                        onClick={imageBox.toggleGallery}
                      />
                    </Assert>
                  )
                }
                inlineActions
              >
                {t('items.details.images')}
              </Title>
              <ImageBox {...imageBox} readonly={readonly} />

              <div className="mt-4">
                <ItemDetailsBookletLinks readonly={readonly} itemId={id} />
              </div>
              <div className="mt-4">
                <ItemDetailsExternalLinks readonly={readonly} itemId={id} />
              </div>
            </Col>
          </Row>

          <Typography.Title className="mt-4" level={4}>
            {t('items.details.attachments')}
          </Typography.Title>
          <Attach {...attach} readonly={readonly} />

          {detailsFooter}

          <Typography.Title level={4} className="mt-4">
            {t('subOptions.title')}
          </Typography.Title>
          <SuboptionsList
            name="subOptions"
            readonly={readonly}
            itemId={id}
            communityId={communityId}
            selectable={selectableSuboption}
            includedUnlistedSuboptions={includeUnlistedSuboptions}
            renderItem={renderSuboptionItem}
            dataSource={subOptions}
          />
        </>
      )}
      {edit && (
        <ItemEditPanel
          onCancel={() => setEdit(false)}
          onSubmit={submit}
          title={t('items.details.editTitle')}
          value={data}
          communityId={communityId}
          selectableSuboption={selectableSuboption}
          includeUnlistedSuboptions={includeUnlistedSuboptions}
          renderSuboptionItem={renderSuboptionItem}
          subOptions={subOptions}
        />
      )}
    </div>
  );
}
