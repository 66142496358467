import { DeleteIconButton, Form, Icon } from '@/components';
import { Col, Row, Button, Space } from 'antd';
import { useField } from 'formik';
import { useCallback } from 'react';
import { PathTemplateStep, PathTemplateStepDetailsDto } from '@/core';
import { FormPathTemplateStepCategories } from './FormPathTemplateStepCategories';
import styles from '../FormPathTemplate.module.scss';
import classNames from 'classnames';
import { FormPathTemplateStepValue } from './FormPathTemplate';

export interface StepItemProps {
  name: string;
  communityId?: string;
  freezeExisting?: boolean;
  step?: PathTemplateStepDetailsDto;
}

function useHandleDelete(props: StepItemProps) {
  const { name } = props;
  const [{ value }] = useField<PathTemplateStep>(name);
  const collectionField = useField<PathTemplateStep[]>(getCollectionName(name));
  const [{ value: collection }, , { setValue: setCollection }] =
    collectionField;

  return useCallback(() => {
    setCollection(collection.filter((x) => x !== value));
  }, [collection, setCollection, value]);
}

function getCollectionName(itemPath: string) {
  return itemPath.replace(/\[\d+\]$/, '');
}

function useMove(props: StepItemProps, moveValue: number) {
  const { name } = props;
  const collectionField = useField<PathTemplateStep[]>(getCollectionName(name));
  const [{ value: collection }, , { setValue: setCollection }] =
    collectionField;
  const [{ value }] = useField<PathTemplateStep>(name);

  const onMove = useCallback(() => {
    const newCollection = [...collection];
    const index = collection.indexOf(value);
    const item = newCollection.splice(index, 1);
    newCollection.splice(index + moveValue, 0, item[0]);
    setCollection(newCollection);
  }, [collection, setCollection, value, moveValue]);

  const newIndex = collection.indexOf(value) + moveValue;
  const isFreezedReplacedItem = () =>
    collection[newIndex].id && props.freezeExisting;
  const canMove =
    newIndex > -1 && newIndex < collection.length && !isFreezedReplacedItem();

  return { onMove, canMove };
}

export function FormPathTemplateStep(props: StepItemProps) {
  const { name, communityId, freezeExisting, step } = props;
  const handleDelete = useHandleDelete(props);
  const { onMove: handleUp, canMove: canUp } = useMove(props, -1);
  const { onMove: handleDown, canMove: canDown } = useMove(props, +1);
  const [{ value }] = useField<FormPathTemplateStepValue>(name);
  const freezed = freezeExisting && value.id;

  return (
    <div>
      <Row>
        <Col flex={1}>
          <Form.Input className="pt-1" name={`${name}.name`} label={false} />
        </Col>
        <Col className={classNames('ps-3 mt-2', styles.stepActions)}>
          <Space size="middle">
            {!freezed && (
              <>
                <DeleteIconButton onClick={handleDelete} />
                {canUp && (
                  <Button
                    type="link"
                    icon={<Icon type="arrow-up" />}
                    onClick={handleUp}
                  />
                )}
                {canDown && (
                  <Button
                    type="link"
                    icon={<Icon type="arrow-down" />}
                    onClick={handleDown}
                  />
                )}
              </>
            )}
          </Space>
        </Col>
      </Row>
      <FormPathTemplateStepCategories
        communityId={communityId}
        name={name + '.categories'}
        initialSelectedCategories={
          step?.selectedCategories ?? value.initialSelectedCategories
        }
      />
    </div>
  );
}
