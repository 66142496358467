import { Http } from '../http/http';
import { guard } from '@/utils';
import {
  AddFloorplanDto,
  FloorplanDetailsDto,
  FloorplanDto,
  FloorplanStatus,
  UpdateWholeHomeFloorplanDto,
  UpdateFloorplanDto,
  FloorplanFilterParams,
} from './floorplanTypes';
import qs from 'qs';
import { StoreClipDto } from '@webinex/clippo';
import { AttachmentDto } from '../http.misc';
import { Paging } from '../http/paging';
import { Paged } from '../http/paged';
import { SortResult } from '@/components/Collections.Paging/useSorting';

class FloorplansHttp extends Http {
  public async getAll(args?: {
    communityId: string;
    onlyActive?: boolean;
    paging: Paging;
    filters?: FloorplanFilterParams;
    sorting?: SortResult;
  }) {
    const query = qs.stringify({
      ...args,
      ...args?.paging,
      ...args?.filters,
      ...args?.sorting,
    });
    const url = `/api/floorplan?${query}`;

    const response = await this._axios.get<Paged<FloorplanDto>>(url);
    return response.data;
  }

  public async get(id: string) {
    guard.notNull(id, 'id');
    const response = await this._axios.get<FloorplanDetailsDto>(
      `/api/floorplan/${id}`,
    );
    return response.data;
  }

  public async byHome(id: string) {
    guard.notNull(id, 'id');
    const response = await this._axios.get<FloorplanDetailsDto | undefined>(
      `/api/floorplan/home/${id}`,
    );
    return response.data;
  }

  public async create(request: AddFloorplanDto) {
    guard.notNull(request, 'request');
    const response = await this._axios.post<string>(`/api/floorplan`, request);
    return response.data;
  }

  public async update(request: UpdateFloorplanDto) {
    guard.notNull(request, 'request');
    const { id } = request;
    await this._axios.put(`/api/floorplan/${id}`, request);
  }

  public async updateWholeHome(request: UpdateWholeHomeFloorplanDto) {
    guard.notNull(request, 'request');
    const { id } = request;
    await this._axios.put(`/api/floorplan/${id}/whole-home`, request);
  }

  public async updateStatus(id: string, status: FloorplanStatus) {
    guard.notNull(id, 'id');
    guard.notNull(status, 'status');
    const response = await this._axios.put<string>(
      '/api/floorplan/update-status',
      { id, status },
    );
    return response.data;
  }

  public async store(args: StoreClipDto) {
    if (args == null) throw new Error('`args` might not be null');
    if (args.file == null) throw new Error('`args.file` might not be null');

    const payload = { actions: args.actions ?? [], values: args.values ?? {} };
    const payloadJson = JSON.stringify(payload);

    const formData = new FormData();
    formData.append('file', args.file);
    formData.append('payload', payloadJson);

    const response = await this._axios.post<AttachmentDto>(
      `/api/floorplan/store`,
      formData,
    );
    return response.data;
  }
}

export const floorplansHttp = new FloorplansHttp();
