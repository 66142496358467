import {
  SubcategoryDetailsForm,
  SubcategoryDetailsFormValue,
} from './SubcategoryDetailsForm';
import { notify } from '@/components';
import { useCreateCommunitySubcategoryMutation } from '@/redux';
import { useTranslation } from 'react-i18next';

interface Props {
  onSubmitted: () => any;
  onCancel: () => any;
  communityId: string;
  value: SubcategoryDetailsFormValue;
}

const useSubmit = (props: Props) => {
  const { onSubmitted, communityId } = props;
  const [create] = useCreateCommunitySubcategoryMutation();

  return (value: SubcategoryDetailsFormValue) => {
    return create({ ...value, communityId })
      .unwrap()
      .then(() => notify.success.t('items.details.subcategories.added'))
      .then(() => onSubmitted());
  };
};

export function AddSubcategoryForm(props: Props) {
  const { onCancel, communityId, value } = props;
  const submit = useSubmit(props);
  const { t } = useTranslation();

  return (
    <SubcategoryDetailsForm
      onCancel={onCancel}
      onSubmit={submit}
      title={t('items.details.subcategories.createTitle')}
      value={value}
      communityId={communityId}
    />
  );
}
