import { TextBox, Title } from '@/components';
import {
  HomeDetailsState,
  useGetHomeDetailsQuery,
  useGetProjectHomeStepsQuery,
} from '@/redux';
import { useTranslation } from 'react-i18next';
import { Assert, EditHomeButton } from '@/views';
import { CommunityPermissions } from '@/core';
import { ViewProjectDeadlines } from '../Projects.Details/ViewProjectDeadlines';
import { Col, Row } from 'antd';

interface Props {
  id: string;
}

function formatLocation(state: HomeDetailsState) {
  return [
    state?.locationLevel1Name,
    state?.locationLevel2Name,
    state?.locationLevel3Name,
  ]
    .filter((x) => !!x)
    .join(' - ');
}

export function HomeDetailsPanel(props: Props) {
  const { id } = props;
  const { data, isFetching } = useGetHomeDetailsQuery({ id });
  const { homeNumber, constructionNumber, number, projectId } = data ?? {};
  const { data: steps } = useGetProjectHomeStepsQuery(
    { projectId: projectId!, homeId: id },
    { skip: !projectId || isFetching },
  );
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  return (
    <div>
      <Row>
        <Col span={10}>
          <Title
            actions={
              <Assert permission={CommunityPermissions.Homes.Manage} active>
                <EditHomeButton id={id} />
              </Assert>
            }
            inlineActions
          >
            {t('homes.details.general')}
          </Title>
          <TextBox label={t('homes.details.homeNumber')}>{homeNumber}</TextBox>
          <TextBox label={t('homes.details.number')}>{number}</TextBox>
          <TextBox label={t('homes.details.constructionNumber')}>
            {constructionNumber}
          </TextBox>
          <TextBox label={t('homes.details.location.level1Id')}>
            {formatLocation(data)}
          </TextBox>
        </Col>
        <Col span={8}>
          {steps && projectId && <ViewProjectDeadlines steps={steps} />}
        </Col>
      </Row>
    </div>
  );
}
