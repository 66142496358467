import { rtkq, RtkqRequest } from '../rtkq';
import { clientSettingsHttp, UserTableSettingsDto } from '@/core';
import { api } from '../api';

export type UserTableSettingsState = UserTableSettingsDto;
export type SetUserTableSettingsArgs = UserTableSettingsState;

const clientSettingsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserTableSettings: build.query<
      UserTableSettingsState,
      RtkqRequest<{ userId: string; table: string }>
    >({
      queryFn: async (args) => {
        const { userId, table } = args;

        return rtkq(args).exec(() =>
          clientSettingsHttp.getUserTableSettings(userId, table),
        );
      },
      providesTags: (setting) => [
        {
          type: 'userTableSettings',
          id: `${setting?.userId}${setting?.table}` ?? 'none',
        },
      ],
    }),

    setUserTableSettings: build.mutation<
      void,
      RtkqRequest<SetUserTableSettingsArgs>
    >({
      queryFn: async (args) => {
        return await rtkq(args).exec(() =>
          clientSettingsHttp.setUserTableSettings(args),
        );
      },
      invalidatesTags: (_, __, args) => [
        { type: 'userTableSettings', id: `${args?.userId}${args?.table}` },
      ],
    }),
  }),
});

export const { useGetUserTableSettingsQuery, useSetUserTableSettingsMutation } =
  clientSettingsApi;
