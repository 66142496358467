import { FieldRow, Form } from '@/components';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';

export interface FormsAddressProps {
  name: string;
  required?: boolean;
}

export const FormsAddress = (props: FormsAddressProps) => {
  const { name, required } = props;
  const { t } = useTranslation();

  return (
    <>
      <FieldRow>
        <Col span={12}>
          <Form.Input
            name={`${name}.street`}
            placeholder={t('address.placeholder.street')}
            required={required}
          />
        </Col>
        <Col span={12}>
          <Form.Input
            name={`${name}.city`}
            placeholder={t('address.placeholder.city')}
            required={required}
          />
        </Col>
      </FieldRow>
      <FieldRow>
        <Col span={12}>
          <Form.StateSelect name="address.state" required={required} />
        </Col>
        <Col span={12}>
          <Form.Input
            name={`${name}.zipCode`}
            placeholder={t('address.placeholder.zipCode')}
            required={required}
          />
        </Col>
      </FieldRow>
    </>
  );
};
