import {
  DeleteIconButton,
  EditIconButton,
  TabPanel,
  TableColumnType,
} from '@/components';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetClientDetailsQuery, useUpdateClientMutation } from '@/redux';
import { AddContactButton, useEditContactAction } from '@/views';
import { Auth, confirmation, notify, Table } from '@/components';
import { Contact, formatters } from '@/core';
import { v4 as uuid } from 'uuid';
import { array, PhoneView } from '@/utils';
import { Space } from 'antd';

interface Props {
  clientId: string;
}

function useColumns(props: Props, openEdit: (contact: Contact) => any) {
  const handleDelete = useHandleDelete(props);
  const contacts = useContacts(props);
  const { t } = useTranslation();

  return useMemo<TableColumnType<Contact>[]>(() => {
    const columns: Array<TableColumnType<Contact> | false> = [
      {
        title: t('clients.details.contacts.number'),
        key: 'number',
        defaultSortOrder: 'descend',
        sorter: (a, b) => contacts.indexOf(a) - contacts.indexOf(b),
        render: (_, item) => formatters.id(contacts.indexOf(item) + 1),
      },
      {
        title: t('clients.details.contacts.name'),
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (_, item) => item.name,
      },
      {
        title: t('clients.details.contacts.phone'),
        key: 'phone',
        sorter: (a, b) => a.phone.localeCompare(b.phone),
        render: (_, { phone }) => <PhoneView value={phone} />,
      },
      {
        title: t('clients.details.contacts.email'),
        key: 'email',
        sorter: (a, b) => a.email.localeCompare(b.email),
        render: (_, item) => item.email,
      },
      {
        title: t('clients.actions'),
        key: 'actions',
        render: (_, item) => (
          <Space size="large">
            <EditIconButton
              type="link"
              onClick={() => openEdit(item)}
              className="table-action"
            />
            {contacts.length > 1 && (
              <DeleteIconButton
                onClick={() => handleDelete(item)}
                className="table-action"
              />
            )}
          </Space>
        ),
      },
    ];
    return columns.filter((x) => !!x) as TableColumnType<Contact>[];
  }, [t, contacts, openEdit, handleDelete]);
}

function useHandleDelete(props: Props) {
  const contacts = useContacts(props);
  const updateContacts = useUpdateContacts(props);

  return useCallback(
    (contact: Contact) => {
      confirmation.delete({ key: 'clients.contactEntityName' }, () => {
        const result = contacts.filter((x) => x !== contact);
        updateContacts(result);
      });
    },
    [contacts, updateContacts],
  );
}

function useHandleAdd(props: Props) {
  const contacts = useContacts(props);
  const updateContacts = useUpdateContacts(props);

  return useCallback(
    (contact: Contact) => {
      return updateContacts([...contacts, contact]);
    },
    [contacts, updateContacts],
  );
}

function useHandleEdit(props: Props) {
  const contacts = useContacts(props);
  const updateContacts = useUpdateContacts(props);

  return useCallback(
    (newValue: Contact, prevValue: Contact) => {
      const result = [...contacts];
      const index = result.indexOf(prevValue);
      result[index] = newValue;
      return updateContacts(result);
    },
    [contacts, updateContacts],
  );
}

function useClientDetails({ clientId }: Props) {
  const { data } = useGetClientDetailsQuery({ id: clientId });
  return data;
}

function useContacts(props: Props) {
  const client = useClientDetails(props);
  return client?.contacts ?? array.empty<Contact>();
}

function useUpdateContacts(props: Props) {
  const [updateClient] = useUpdateClientMutation();
  const client = useClientDetails(props);

  return useCallback(
    (contacts: Contact[]) => {
      return updateClient({ ...client!, contacts })
        .unwrap()
        .then(() => notify.success.t('clients.details.saved'));
    },
    [client, updateClient],
  );
}

export function ContactListPanel(props: Props) {
  const { t } = useTranslation();
  const contacts = useContacts(props);
  const handleEdit = useHandleEdit(props);
  const handleAdd = useHandleAdd(props);

  const { openEditContact, editContactModal } = useEditContactAction({
    onSubmit: handleEdit,
  });

  const columns = useColumns(props, openEditContact);

  const contactId = useMemo(
    () => contacts.map((value) => ({ value, id: uuid() })),
    [contacts],
  );

  return (
    <TabPanel>
      <TabPanel.Title>{t('clients.details.contacts.header')}</TabPanel.Title>
      <TabPanel.Actions>
        <Auth role="BFP_ADMIN">
          <AddContactButton onAdd={handleAdd} />
        </Auth>
      </TabPanel.Actions>
      <TabPanel.Body>
        <Table<Contact>
          scroll={{ x: 1500 }}
          name="Contacts"
          columns={columns}
          dataSource={contacts}
          rowKey={(x) => contactId.find(({ value }) => value === x)!.id}
        />
        {editContactModal}
      </TabPanel.Body>
    </TabPanel>
  );
}
