import { createBasicNotificationSettings } from './BasicNotificationSubject';
import { notificationSettings } from './useNotification';

notificationSettings['TaskAssignedToYou'] =
  createBasicNotificationSettings('TaskAssignedToYou');

notificationSettings['TaskMovedToCompleteStatus'] =
  createBasicNotificationSettings('TaskMovedToCompleteStatus');

notificationSettings['TaskCompletedSoon'] =
  createBasicNotificationSettings('TaskCompletedSoon');

notificationSettings['TaskOverdue'] =
  createBasicNotificationSettings('TaskOverdue');

notificationSettings['FloorplanSpecialRequestAdded'] =
  createBasicNotificationSettings('FloorplanSpecialRequestAdded');

notificationSettings['RoomSpecialRequestAdded'] =
  createBasicNotificationSettings('RoomSpecialRequestAdded');

notificationSettings['SpecialRequestStatusChangedToMgmtApproved'] =
  createBasicNotificationSettings('SpecialRequestStatusChangedToMgmtApproved');

notificationSettings['SpecialRequestStatusChangedToDrawing'] =
  createBasicNotificationSettings('SpecialRequestStatusChangedToDrawing');

notificationSettings['SpecialRequestStatusChangedToConstApproved'] =
  createBasicNotificationSettings('SpecialRequestStatusChangedToConstApproved');

notificationSettings['FloorplanSpecialRequestEdited'] =
  createBasicNotificationSettings('FloorplanSpecialRequestEdited');

notificationSettings['RoomSpecialRequestEdited'] =
  createBasicNotificationSettings('RoomSpecialRequestEdited');

notificationSettings['FloorplanSpecialRequestStatusChanged'] =
  createBasicNotificationSettings('FloorplanSpecialRequestStatusChanged');

notificationSettings['RoomSpecialRequestStatusChanged'] =
  createBasicNotificationSettings('RoomSpecialRequestStatusChanged');

notificationSettings['PathStepFinalized'] =
  createBasicNotificationSettings('PathStepFinalized');

notificationSettings['PathStepUnfinalized'] = createBasicNotificationSettings(
  'PathStepUnfinalized',
);

notificationSettings['DeadlineChanged'] =
  createBasicNotificationSettings('DeadlineChanged');

notificationSettings['FloorplanOfferedItemAdded'] =
  createBasicNotificationSettings('FloorplanOfferedItemAdded');

notificationSettings['RoomOfferedItemAdded'] = createBasicNotificationSettings(
  'RoomOfferedItemAdded',
);

notificationSettings['ProjectAdded'] =
  createBasicNotificationSettings('ProjectAdded');

notificationSettings['ProjectStatusChangedToComplete'] =
  createBasicNotificationSettings('ProjectStatusChangedToComplete');

notificationSettings['DocumentAddedToTheHome'] =
  createBasicNotificationSettings('DocumentAddedToTheHome');

notificationSettings['TaskAssignedToUser'] =
  createBasicNotificationSettings('TaskAssignedToUser');

notificationSettings['SpecialRequestStatusChangedToDenied'] =
  createBasicNotificationSettings('SpecialRequestStatusChangedToDenied');
