import { Form, Title } from '@/components';
import {} from '@/core';
import {
  ItemState,
  SubcontractorDetailsState,
  UpdateSubcontractorItemsDtoArgs,
} from '@/redux';
import { Button, Space } from 'antd';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FormSubcontractorTree } from './FormSubcontractorTree';
import { useSubcontractorTreeData } from './SubcontractorItemsDetailsPanel';

interface Props {
  items: ItemState[];
  onCancel: () => void;
  onSubmit: (value: UpdateSubcontractorItemsDtoArgs) => void;
  value: SubcontractorDetailsState;
}

const SCHEMA = Yup.object().shape({
  itemIds: Yup.array().of(Yup.string().nullable().required()).required(),
});

export function FormSubcontractorItems(props: Props) {
  const { items, onSubmit, onCancel, value } = props;
  const { t } = useTranslation();

  const itemsWithSelectedItems = useMemo(
    () => items.concat(value.items.filter((item) => !item.listed)),
    [items, value.items],
  );

  const treeData = useSubcontractorTreeData(
    value.communityId,
    itemsWithSelectedItems,
  );

  const initialValues = useMemo(
    () =>
      ({
        itemIds: value.items.map((item) => item.id),
      } as UpdateSubcontractorItemsDtoArgs),
    [value],
  );

  if (isEmpty(treeData)) {
    return null;
  }

  return (
    <Form.Formik
      uid="subcontractor-details-items"
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={SCHEMA}
      i18n="subcontractors"
    >
      <Title>{t('subcontractors.details.editItemsTitle')}</Title>
      <FormSubcontractorTree treeData={treeData} />
      <Space direction="horizontal" className="mt-3">
        <Button onClick={onCancel}>{t('cancel')}</Button>
        <Form.Submit type="primary">{t('save')}</Form.Submit>
      </Space>
    </Form.Formik>
  );
}
