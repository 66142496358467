import { useMemo, useState } from 'react';
import { AssignmentDetailsModal, AssignmentDetailsModalValue } from '@/views';
import {
  CommunityUserState,
  useUpdateAssignToCommunityMutation,
} from '@/redux';
import { useTranslation } from 'react-i18next';
import { notify } from '@/components';
import { CommunityRole } from '@/core';

interface UseEditAssignmentArgs {
  clientId: string;
  communityId: string;
}

const useSubmit = (
  setEditCommunityUser: () => any,
  args: UseEditAssignmentArgs,
) => {
  const { communityId } = args;
  const [updateAssignment] = useUpdateAssignToCommunityMutation();

  return (args: AssignmentDetailsModalValue) =>
    updateAssignment({ ...args, communityId })
      .unwrap()
      .then(() => notify.success.t('communityUsers.details.saved'))
      .then(() => setEditCommunityUser());
};

const useValue = (id: string, communityRoles: CommunityRole[]) => {
  return useMemo(
    () =>
      id
        ? {
            userId: id,
            roles: communityRoles,
          }
        : undefined,
    [id, communityRoles],
  );
};

export function useEditAssignment(args: UseEditAssignmentArgs) {
  const { clientId } = args;
  const [editAssignedUser, setEditAssignedUser] =
    useState<CommunityUserState>();
  const { t } = useTranslation();
  const submit = useSubmit(() => setEditAssignedUser(undefined), args);
  const { id, communityRoles } = editAssignedUser ?? {};
  const value = useValue(id!, communityRoles!);

  return {
    openEdit: (user: CommunityUserState) => setEditAssignedUser(user),
    editModal: value ? (
      <AssignmentDetailsModal
        disabledUserSearch={true}
        buttonSaveName={t('communityUsers.details.editButton')}
        onClose={() => setEditAssignedUser(undefined)}
        title={t('communityUsers.details.editTitle')}
        onSubmit={submit}
        clientId={clientId}
        value={value}
      />
    ) : null,
  };
}
