import {
  AddPersonalizationItemDto,
  Http,
  ItemDto,
  CategoryDto,
  UpdateGeoPersonalizationItemsPositionDto,
  UpdatePersonalizationItemDto,
  PersonalizationItemDto,
  RoomState,
  SubOptionDto,
  SavePersonalizationItemManualResidentPriceDto,
  asFileResponse,
  AddExistingItemDto,
  ExistingBaseItemDto,
  UpdateExistingItemDto,
  ExistingItemDto,
  Language,
} from '@/core';
import { guard } from '@/utils';
import { isArray } from 'lodash';
import qs from 'qs';

class PersonalizationsHttp extends Http {
  public async getAll(args: {
    homeId: string;
    projectId: string;
    isFloorplan?: boolean;
    roomId?: string;
    wholeHome?: boolean;
    stepId?: string | string[];
    isGeo?: boolean;
  }) {
    const stepId = isArray(args.stepId)
      ? args.stepId
      : args.stepId
      ? [args.stepId]
      : undefined;
    const query = qs.stringify({ ...args, stepId: stepId });
    const response = await this._axios.get<PersonalizationItemDto[]>(
      `/api/home/personalization?${query}`,
    );
    return response.data;
  }

  public async create(request: AddPersonalizationItemDto) {
    guard.notNull(request, 'request');
    const response = await this._axios.post<string>(
      `/api/home/personalization`,
      request,
    );
    return response.data;
  }

  public async updateGeo(request: UpdateGeoPersonalizationItemsPositionDto) {
    guard.notNull(request, 'request');
    const response = await this._axios.put<void>(
      `/api/home/personalization/position`,
      request,
    );
    return response.data;
  }

  public async update(request: UpdatePersonalizationItemDto) {
    guard.notNull(request, 'request');
    const response = await this._axios.put<void>(
      `/api/home/personalization`,
      request,
    );
    return response.data;
  }

  public async unlist(id: string) {
    guard.notNull(id, 'id');
    await this._axios.put(`/api/home/personalization/${id}`);
  }

  public async getAvailableItems(args: {
    homeId: string;
    projectId: string;
    floorplanPersonalization: boolean;
    categoryId?: string;
    roomId?: string;
    stepId?: string;
  }) {
    const query = qs.stringify(args);
    const uri = `/api/home/personalization/items?${query}`;
    const response = await this._axios.get<ItemDto[]>(uri);
    return response.data;
  }

  public async getCategories(args: {
    homeId: string;
    projectId: string;
    floorplanPersonalization: boolean;
    roomId?: string;
    stepId?: string;
  }) {
    const query = qs.stringify(args);
    const uri = `/api/home/personalization/categories?${query}`;
    const response = await this._axios.get<CategoryDto[]>(uri);
    return response.data;
  }

  public async getNotFinalizedRoomIdsAll(
    community: string,
    projectId: string,
    homeId: string,
    stepId: string,
  ) {
    const query = qs.stringify({ community, projectId, homeId, stepId });
    const response = await this._axios.get<string[]>(
      `/api/home/personalization/finalization-summary?${query}`,
    );
    return response.data;
  }

  public async updateFloorplanPersonalizationNeededState(args: {
    homeId: string;
    projectId: string;
    value: boolean;
    stepId?: string;
  }) {
    guard.notNull(args, 'args');
    await this._axios.put(
      `/api/home/personalization/floorplan-personalization-needed?${qs.stringify(
        args,
      )}`,
    );
  }

  public async getAvailableSubOptions(
    homeId: string,
    projectId: string,
    itemId: string,
    includeUnlisted?: string[],
  ) {
    guard.notNull(itemId, 'itemId');
    const response = await this._axios.get<SubOptionDto[]>(
      `/api/home/personalization/suboptions?${qs.stringify({
        homeId,
        projectId,
        includeUnlisted,
        itemId,
      })}`,
    );
    return response.data;
  }

  public async getPersonalizationRooms(args: {
    homeId: string;
    projectId: string;
  }) {
    const query = qs.stringify({ ...args });
    const url = `/api/home/personalization/room?${query}`;
    const response = await this._axios.get<RoomState[]>(url);
    return response.data;
  }

  public async updateFinalizationState(args: {
    homeId: string;
    projectId: string;
    value: boolean;
    stepId: string;
  }) {
    guard.notNull(args, 'args');
    await this._axios.put(
      `/api/home/personalization/finalization?${qs.stringify(args)}`,
    );
  }

  public async saveManualResidentPrice(
    args: SavePersonalizationItemManualResidentPriceDto,
  ) {
    guard.notNull(args, 'args');
    const url = `/api/home/personalization/manual-resident-price`;
    await this._axios.put(url, args);
  }

  public async downloadPdf(
    projectId: string,
    homeId: string,
    showBaseCost: boolean,
    showResidentPrice: boolean,
    showResidentInfo: boolean,
    isPortraitOrientation: boolean,
    language: Language,
    stepId?: string,
  ) {
    guard.notNull(projectId, 'projectId');
    guard.notNull(homeId, 'homeId');

    const query = qs.stringify({
      projectId,
      homeId,
      stepId,
      showBaseCost,
      showResidentInfo,
      isPortraitOrientation,
      language,
      showResidentPrice,
    });
    const response = await this._axios.get(
      `/api/home/personalization/pdf?${query}`,
      { responseType: 'blob' },
    );
    return asFileResponse(response);
  }

  public async downloadTabloidPdf(
    projectId: string,
    homeId: string,
    language: Language,
    showResidentInfo: boolean,
    stepId?: string,
  ) {
    guard.notNull(projectId, 'projectId');
    guard.notNull(homeId, 'homeId');

    const query = qs.stringify({
      projectId,
      homeId,
      language,
      stepId,
      showResidentInfo,
    });
    const response = await this._axios.get(
      `/api/home/personalization/tabloid-pdf?${query}`,
      { responseType: 'blob' },
    );
    return asFileResponse(response);
  }

  public async keepExisting(request: AddExistingItemDto) {
    guard.notNull(request, 'request');
    const response = await this._axios.post<string>(
      `/api/home/personalization/keep-existing`,
      request,
    );
    return response.data;
  }

  public async getExistingItem(args: {
    homeId: string;
    projectId: string;
    categoryId: string;
    stepId?: string;
    roomId?: string;
  }) {
    const query = qs.stringify(args);
    const url = `/api/home/personalization/existing?${query}`;
    const response = await this._axios.get<ExistingBaseItemDto>(url);
    return response.data;
  }

  public async updateExistingItem(request: UpdateExistingItemDto) {
    guard.notNull(request, 'request');
    const response = await this._axios.put<void>(
      `/api/home/personalization/update-existing`,
      request,
    );
    return response.data;
  }

  public async unkeepExistingItem(request: { id: string }) {
    guard.notNull(request, 'request');
    const response = await this._axios.put<void>(
      `/api/home/personalization/unkeep-existing`,
      request,
    );
    return response.data;
  }

  public async getAllExistingItems(args: {
    homeId: string;
    projectId: string;
    stepId?: string | string[];
    roomId?: string;
  }) {
    const stepId = isArray(args.stepId)
      ? args.stepId
      : args.stepId
      ? [args.stepId]
      : undefined;
    const query = qs.stringify({ ...args, stepId: stepId });
    const url = `/api/home/personalization/all-existing?${query}`;
    const response = await this._axios.get<ExistingItemDto[]>(url);
    return response.data;
  }
}

export const personalizationsHttp = new PersonalizationsHttp();
