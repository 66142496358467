import { DeleteIconButton, EditIconButton } from '@/components';
import {
  TaskTemplateDetailsState,
  TaskTemplateTaskDetailsState,
} from '@/redux';
import { Space, Steps } from 'antd';
import { useNavigate } from "react-router-dom";
import { useBoundDeleteTemplateTask } from "@/views/TaskTemplates.Details/useDeleteTemplateTask";

interface Props {
  value: TaskTemplateDetailsState;
  className?: string;
  templateId: string;
  editRoute: (id: string) => string;
}

const { Step } = Steps;

function StepTitle(props: { value: TaskTemplateTaskDetailsState, templateId: string, editRoute: (id: string) => string}) {
  const { value, editRoute, templateId } = props;
  const { name, id} = value;
  const push = useNavigate();
  const handleDelete = useBoundDeleteTemplateTask(id);

  return (
    <>
      <div className="pt-2">
        <span className="me-4">{name}</span>
        <Space size="large">
          <EditIconButton
            className="table-action"
            type="link"
            onClick={() => push(editRoute(templateId))}
          />
          <DeleteIconButton
            className="table-action"
            type="link"
            onClick={handleDelete}/>
        </Space>
      </div>
    </>
  );
}

export function TaskTemplateTaskList(props: Props) {
  const { value, className, templateId, editRoute } = props;
  return (
    <Steps className={className} direction="vertical" size="small" current={0}>
      {value.tasks.map((step, index) => (
        <Step key={index} status="process" title={<StepTitle value={step} templateId={templateId} editRoute={editRoute}/>
        } />
      ))}
    </Steps>
  );
}