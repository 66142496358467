import { FileDownloadButton, TabPanel } from '@/components';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormHomesUpload, HomeUploadFailedModal } from '@/views';
import { homesHttp } from '@/core';
import { useState } from 'react';

export function HomesUploadPanel() {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<string[]>();

  return (
    <TabPanel min="auto">
      <TabPanel.Title>{t('homes.upload.title')}</TabPanel.Title>
      <TabPanel.Body>
        <Row>
          <FileDownloadButton
            className="mb-3"
            download="download"
            name={t('homes.upload.downloadSample')}
            action={() => homesHttp.downloadUploadSample()}
            type="link"
          />
        </Row>
        <Row>
          <Col span={6}>
            <FormHomesUpload onUploadFailed={setErrors} />
          </Col>
        </Row>
        {errors && (
          <HomeUploadFailedModal
            errorPayload={errors}
            onClose={() => setErrors(undefined)}
          />
        )}
      </TabPanel.Body>
    </TabPanel>
  );
}
