import { useTranslation } from 'react-i18next';
import { Auth, Page } from '@/components';
import { ClientsListPanel, AddClientButton } from '@/views';
import { ShareAltOutlined } from '@ant-design/icons';

function _ClientsListPage() {
  const { t } = useTranslation();
  const title = t('clients.title');

  return (
    <Page htmlTitle={title}>
      <Page.Paths>
        <Page.Path>{title}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card
          title={title}
          icon={<ShareAltOutlined />}
          color="#f57c00"
          actions={<AddClientButton />}
        >
          <ClientsListPanel />
        </Page.Card>
      </Page.Body>
    </Page>
  );
}

export const ClientsListPage = Auth.Banner('BFP_ADMIN')(_ClientsListPage);
