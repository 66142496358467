import { notify } from '@/components';
import {
  useGetCommunityContextQuery,
  useGetProjectQuery,
  useUpdateProjectMutation,
} from '@/redux';
import { useCallback, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CommunityContext } from '../Communities.Common';
import { ProjectDetailsFormValue } from './ProjectDetailsForm';
import { ProjectDetailsModal } from './ProjectDetailsModal';

function useSave(onSaved: () => any) {
  const [update] = useUpdateProjectMutation();

  return useCallback(
    (value: ProjectDetailsFormValue) => {
      update({ ...value, id: value.id! })
        .unwrap()
        .then(() => notify.success.t('projects.saved'))
        .then(onSaved);
    },
    [update, onSaved],
  );
}

export function useEditProjectModal() {
  const { t } = useTranslation();
  const [id, setId] = useState<string>();
  const onSaved = useCallback(() => setId(undefined), [setId]);
  const handleSave = useSave(onSaved);
  const { currentData } = useGetProjectQuery({ id: id! }, { skip: !id });
  const handleCancel = useCallback(() => setId(undefined), [setId]);
  const existingContext = useContext(CommunityContext);
  const { data } = useGetCommunityContextQuery(
    { id: currentData?.communityId! },
    { skip: !currentData?.communityId || !!existingContext },
  );
  const context = existingContext ?? data;

  return {
    editProjectModal: id && currentData && context && (
      <CommunityContext.Provider value={context}>
        <ProjectDetailsModal
          value={currentData!}
          onSave={handleSave}
          onCancel={handleCancel}
          title={t('projects.details.editTitle')}
          saveBtnText={t('projects.details.editBtnText')}
          noCommunity
          disabledPathTemplate={!!currentData.pathName}
        />
      </CommunityContext.Provider>
    ),
    showEdit: setId,
  };
}
