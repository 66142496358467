import {
  DateValue,
  DeleteIconButton,
  EnumValue,
  TableColumnType,
} from '@/components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentsState } from '@/redux';
import { array, sort, useFiltersFactory } from '@/utils';
import { CommunityPermissions, PaymentType, formatters } from '@/core';
import { Assert } from '@/views';

export function usePaymentsColumns(
  canModify: boolean,
  onDeletePaymentButton: (id: string) => void,
) {
  const { t } = useTranslation();

  const filters = useFiltersFactory(array.empty<PaymentsState>());

  return useMemo<TableColumnType<PaymentsState>[]>(() => {
    const columns: Array<TableColumnType<PaymentsState> | false> = [
      {
        title: t('payments.step'),
        key: 'step',
        width: 240,
        ...filters.plainText((x) => x.step.name),
        sorter: (a, b) => a.step.name.localeCompare(b.step.name),
        render: (_, item) => item.step.name,
      },
      {
        title: t('payments.type'),
        key: 'type',
        ...filters.enumSelect((x) => x.paymentType, PaymentType),
        sorter: (a, b) => a.paymentType.localeCompare(b.paymentType),
        render: (_, item) => (
          <EnumValue type={PaymentType} value={item.paymentType} />
        ),
      },
      {
        title: t('payments.date'),
        key: 'date',
        align: 'left',
        sorter: (a, b) =>
          new Date(a.date).getTime() - new Date(b.date).getTime(),
        ...filters.dateTimeRange((x) => x.date),
        render: (_, item) => <DateValue value={item.date} />,
      },
      {
        title: t('payments.description'),
        key: 'description',
        ...filters.plainText((x) => x.description),
        sorter: (a, b) => sort(a.description, b.description),
        render: (_, item) => item.description,
      },
      {
        title: t('payments.amount'),
        key: 'amount',
        align: 'right',
        sorter: (a, b) => a.amount - b.amount,
        ...filters.numberRange((x) => x.amount),
        render: (_, { paymentType, amount}) =>
          (paymentType === PaymentType.Payment || paymentType === PaymentType.Credit) && amount > 0
            ? formatters.price(-amount)
            : formatters.price(amount),
      },
      {
        title: t('payments.actions'),
        key: 'actions',
        align: 'right',
        render: (_, { id }) =>
          canModify && (
            <Assert
              permission={CommunityPermissions.Personalization.Manage}
              active
            >
              <DeleteIconButton
                confirm
                entityName={t('payments.entityName').toLowerCase()}
                stopPropagation
                title={t('delete')}
                onClick={() => onDeletePaymentButton(id)}
              />
            </Assert>
          ),
      },
    ];

    return columns.filter((x) => !!x) as TableColumnType<PaymentsState>[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, canModify]);
}
