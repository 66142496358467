import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteIconButton } from '../Styled';
import { AttachProps } from './attachProps';
import { FileOutlined } from '@ant-design/icons';
import styles from './Attach.module.scss';

export function AttachList(props: AttachProps) {
  const { remove, readonly, open, value, noDeleteConfirm } = props;
  const { items, fetchPending } = value;
  const { t } = useTranslation();

  return (
    <ul className={styles.list}>
      {items.map((item) => (
        <li key={item.id} title={item.fileName}>
          <span className={styles.title}>
            <Button
              icon={<FileOutlined />}
              disabled={fetchPending}
              type="link"
              onClick={() => open(item.id)}
            >
              <span className={styles.fileName}>{item.fileName}</span>
            </Button>
          </span>
          <DeleteIconButton
            disabled={readonly || fetchPending}
            className={styles.delete}
            onClick={() => remove({ id: item.id })}
            entityName={t('attach.entityName')}
            confirm={!noDeleteConfirm}
          />
        </li>
      ))}
    </ul>
  );
}
