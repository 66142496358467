import { EditIconButton, TableColumnType } from '@/components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CategoryLookupState, PersonalizationItemState } from '@/redux';
import { array, sort, useBool, useFiltersFactory } from '@/utils';
import { CommunityPermissions, formatters } from '@/core';
import { ManualResidentPriceModal } from './ManualResidentPriceModal';
import { DollarCircleOutlined, StarFilled } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import { Assert } from '../Communities.Common';
import { PersonalizationTableRowValues } from './PersonalizationDetails';
import styles from './PersonalizationSummary.module.scss';
import { useIsFinalized } from '../Personalization.Details';
import { SpecialRequestStatusTag } from './SpecialRequestStatusTag';

const { Text } = Typography;

function buildPath(path: CategoryLookupState[]): string {
  return path.map((x) => x.name).join(' / ');
}

function TBDPrice() {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'personalization.summary',
  });

  return <Text type="danger">{t('tbd')}</Text>;
}

function ManualResidentPriceSign({ item }: { item: PersonalizationItemState }) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'personalization.summary.manualResidentPrice',
  });
  const { residentPriceOverridenBy, residentPriceOverridenAt } = item.pricing;
  if (!residentPriceOverridenAt) return null;

  const manualPriceData = {
    overridenBy: residentPriceOverridenBy,
    overridenAt: formatters.dateTime(residentPriceOverridenAt!),
  };

  return (
    <Tooltip title={<pre>{t('tooltip', manualPriceData)}</pre>}>
      <DollarCircleOutlined
        className="me-2"
        style={{ color: 'var(--ant-info-color)' }}
      />
    </Tooltip>
  );
}

function ResidentPriceCell({ item }: { item: PersonalizationItemState }) {
  const { totalResidentPrice } = item.pricing;
  const { homeId, projectId, step } = item;
  const [manualPriceVisible, , toggleManualPriceVisible] = useBool(false);
  const isFinalized = useIsFinalized(homeId, projectId, step.id);

  return (
    <span>
      <ManualResidentPriceSign item={item} />
      {totalResidentPrice != null ? (
        formatters.price(totalResidentPrice)
      ) : (
        <TBDPrice />
      )}

      {!isFinalized && (
        <Assert
          permission={CommunityPermissions.ResidentPriceOverride.FullAccess}
          active
        >
          <EditIconButton
            className="table-action ms-2"
            onClick={toggleManualPriceVisible}
          />
        </Assert>
      )}
      {manualPriceVisible && (
        <ManualResidentPriceModal
          item={item}
          onClose={toggleManualPriceVisible}
        />
      )}
    </span>
  );
}

function sortByPrice(
  a: PersonalizationTableRowValues,
  b: PersonalizationTableRowValues,
  select: (x: PersonalizationTableRowValues) => number | undefined,
) {
  const priceA = select(a) ?? 0;
  const priceB = select(b) ?? 0;

  return priceA - priceB;
}

export function usePersonalizationColumns(
  useNumbers: boolean,
  showResidentPrice: boolean,
  showBaseCost: boolean,
) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'personalization.summary',
  });

  const filters = useFiltersFactory(
    array.empty<PersonalizationTableRowValues>(),
  );

  return useMemo<TableColumnType<PersonalizationTableRowValues>[]>(() => {
    const columns: Array<
      TableColumnType<PersonalizationTableRowValues> | false
    > = [
      {
        title: null,
        width: 50,
        key: 'number',
        render: (_, { specialRequest }) =>
          specialRequest && (
            <Tooltip
              placement="right"
              title={t('specialRequestTooltip')}
              className={styles.specialRequestIcon}
            >
              <StarFilled />
            </Tooltip>
          ),
      },
      useNumbers && {
        title: t('number'),
        width: 50,
        key: 'number',
        sorter: (a, b) => a.number! - b.number!,
        render: (_, { number }) => number,
        defaultSortOrder: 'ascend',
      },
      {
        title: t('step'),
        key: 'step',
        width: 200,
        ...filters.plainText((x) => x.stepName),
        sorter: (a, b) => a.stepName.localeCompare(b.stepName),
        render: (_, { stepName }) => stepName,
      },
      {
        title: t('category'),
        key: 'category',
        width: 200,
        ...filters.plainText((x) => buildPath(x.categoryPath)),
        sorter: (a, b) => {
          const pathA = buildPath(a.categoryPath);
          const pathB = buildPath(b.categoryPath);

          const comparison = pathA.localeCompare(pathB);

          if (comparison !== 0) {
            return comparison;
          }

          return pathA.length - pathB.length;
        },
        render: (_, { categoryPath }) => buildPath(categoryPath),
        defaultSortOrder: useNumbers ? null : 'ascend',
      },
      {
        title: t('itemName'),
        key: 'itemName',
        width: 200,
        ...filters.plainText((x) => x.name),
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (_, { name }) => name,
        defaultSortOrder: useNumbers ? null : 'ascend',
      },
      {
        title: t('description'),
        key: 'description',
        width: 200,
        ...filters.plainText((x) => x.description),
        sorter: (a, b) => sort(a.description, b.description),
        render: (_, item) => (
          <div className={styles.description}>{item.description}</div>
        ),
      },
      {
        title: t('notes'),
        key: 'notes',
        width: 150,
        ...filters.plainText((x) => x.personalizationItem?.notes),
        sorter: (a, b) =>
          sort(a.personalizationItem?.notes, b.personalizationItem?.notes),
        render: (_, item) => (
          <div className={styles.description}>
            {item.personalizationItem?.notes}
          </div>
        ),
      },
      {
        title: t('color'),
        key: 'subOption',
        ...filters.plainText((x) => x.personalizationItem?.subOption?.name),
        sorter: (a, b) =>
          sort(
            a.personalizationItem?.subOption?.name,
            b.personalizationItem?.subOption?.name,
          ),
        render: (_, item) => item.personalizationItem?.subOption?.name,
      },
      showBaseCost && {
        title: t('baseCost'),
        key: 'baseCost',
        width: 150,
        ...filters.numberRange((x) => x.totalBaseCost),
        sorter: (a, b) => sortByPrice(a, b, (x) => x.totalBaseCost),
        render: (_, item) =>
          item.totalBaseCost != null
            ? formatters.price(item.totalBaseCost)
            : !item.isExistingItem && <TBDPrice />,
        className: 'text-end',
      },
      showResidentPrice && {
        title: t('residentPrice'),
        key: 'residentPrice',
        width: 150,
        ...filters.numberRange((x) => x.totalResidentPrice),
        sorter: (a, b) => sortByPrice(a, b, (x) => x.totalResidentPrice),
        render: (_, item) => (
          <div>
            <div>
              {item.personalizationItem ? (
                <ResidentPriceCell item={item.personalizationItem} />
              ) : (
                formatters.price(item.totalResidentPrice) ??
                (!item.isExistingItem && <TBDPrice />)
              )}
            </div>
            <div>
              <SpecialRequestStatusTag item={item.specialRequest} />
            </div>
          </div>
        ),

        className: 'text-end',
      },
    ];

    return columns.filter(
      (x) => !!x,
    ) as TableColumnType<PersonalizationTableRowValues>[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, useNumbers, showBaseCost, showResidentPrice]);
}
