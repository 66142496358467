import axios from 'axios';
import { openid, Http } from '.';
import {
  WispoClient,
  UseWispoListParams,
  useWispoUnreadCount,
  useWispoList,
} from '@webinex/wispo';

const axiosInstance = axios.create({ baseURL: '/api/wispo' });

axiosInstance.interceptors.request.use(async (request) => {
  const { key, value } = await Http.getAuthHeader();
  request.headers![key] = value;
  return request;
});

export const wispoClient = new WispoClient({
  signalR: {
    accessTokenFactory: openid.token,
  },
  http: {
    axios: axiosInstance,
  },
});

export const useAppUnreadCount = () => useWispoUnreadCount(wispoClient);
export const useAppListState = (config: UseWispoListParams) =>
  useWispoList({ ...config, client: wispoClient });
