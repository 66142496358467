import {
  TaskTemplateMatrixState,
  useGetTaskTemplateToImportQuery
} from '@/redux';
import { confirmation, Form } from '@/components';
import { useTranslation } from 'react-i18next';
import {
  ImportTasksListPanel,
  ImportTasksModalInitialValue,
  parseResponsiblePersonValue
} from '@/views';
import * as Yup from 'yup';
import { useCallback, useMemo } from 'react';
import { AddRowTaskDto, TaskStatus } from '@/core';
import { Button, Modal } from 'antd';

interface ImportTasksModalProps {
  title: string;
  value?: ImportTasksModalInitialValue
  onSubmit: (props?: any) => any;
  onClose: () => any;
  initialValue?: TaskTemplateMatrixState;
}

export interface ImportTasksFormValue {
  rows: AddRowTaskDto[];
}

const MAX_VALUE = 100000000;

const schema = Yup.object().shape({
  rows: Yup.array()
    .of(
      Yup.object().shape({
        checked: Yup.boolean(),
        name: Yup.string().field().when('checked', {
          is: true,
          then: Yup.string().nullable().required(),
          otherwise: Yup.string().nullable()
        }),
        homeId: Yup.string().field().when('checked', {
          is: true,
          then: Yup.string().nullable().required(),
          otherwise: Yup.string().nullable()
        }),
        startDate: Yup.date()
          .max(Yup.ref('endDate'), {
            key: 'tasks_start_date_must_be_before_end_date',
          })
          .nullable(),
        endDate: Yup.date()
          .min(Yup.ref('startDate'), {
            key: 'tasks_end_date_must_be_after_start_date',
          })
          .nullable(),
        costEstimate: Yup.number().min(0).max(MAX_VALUE).nullable(),
        responsiblePerson: Yup.string().field().when('checked', {
          is: true,
          then: Yup.string().nullable().required(),
          otherwise: Yup.string().nullable()
        }),
        status: Yup.string().field().when('checked', {
          is: true,
          then: Yup.string().nullable().required(),
          otherwise: Yup.string().nullable()
        })
      }),
    )
    .required(),
})

function useSubmit(props: ImportTasksModalProps) {
  const { onSubmit, onClose } = props;
  const { t } = useTranslation();

  return useCallback(
    (data: ImportTasksFormValue) => {
      const checkedRows = data.rows.filter(x => x.checked)
      if (checkedRows.length === 0) {
        confirmation.show({
          title: t('tasks.import.submitTitle'),
          body: t('tasks.import.submitMessage'),
          onConfirm: () => onClose(),
        });
      }
      else {
        data.rows = checkedRows;
        data.rows.map((row) => row.responsiblePerson = parseResponsiblePerson(row));
        onSubmit(data);
      }
    },
    [onClose, onSubmit, t]
  )
}

function parseResponsiblePerson(data: any) {
  return parseResponsiblePersonValue(data.responsiblePerson);
}

function useClose(props: ImportTasksModalProps) {
  const { onClose } = props;
  const { t } = useTranslation();

  return useCallback(() => {
    confirmation.show({
      title: t('tasks.import.closeTitle'),
      body: t('tasks.import.closeMessage'),
      onConfirm: () => onClose(),
    });
  }, [onClose, t])
}

function useValue(props: ImportTasksModalProps): ImportTasksFormValue | null {
  const projectId = props.value?.projectId;
  const { data } = useGetTaskTemplateToImportQuery({ templateIds: props.value?.templateIds!})

  return useMemo<ImportTasksFormValue | null>(() => {

    if (!data || !projectId) {
      return null;
    }

    return {
      rows: data.rows.map((row) => ({
        projectId: projectId,
        name: row.name,
        responsiblePerson: null!,
        estimatedCost: row.costEstimate,
        status: TaskStatus.NotStarted,
        homeId: null!,
        startDate: row.startDate,
        endDate: row.endDate,
        checked: false
      })),
    };
  }, [data, projectId])
}

export function ImportTasksModal(props: ImportTasksModalProps){
  const { title, value} = props;
  const { t } = useTranslation();
  const { data, isFetching } = useGetTaskTemplateToImportQuery({ templateIds: value?.templateIds!});
  const formValue = useValue(props);
  const onSubmit = useSubmit(props);
  const onClose = useClose(props);


  return (
    <Modal
      title={title}
      visible
      onCancel={onClose}
      width={1500}
      footer={[
        <Button key="back" type="default" onClick={onClose}>
          {t('tasks.details.cancel')}
        </Button>,
        <Form.Submit uid="import-tasks" key="submit" type="primary">
          {t('tasks.details.save')}
        </Form.Submit>,
      ]}
    >
      {formValue && (
        <Form.Formik<ImportTasksFormValue>
          uid="import-tasks"
          initialValues={formValue}
          validationSchema={schema}
          onSubmit={onSubmit}>
          <ImportTasksListPanel name="rows" data={data!.rows} isFetching={isFetching} />
        </Form.Formik>
      )}
    </Modal>
  )
}