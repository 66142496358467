import {
  DeleteIconButton,
  EditIconButton,
  ImageBox,
  NavLink,
  TextBox,
  notify,
  useImageBoxLazy,
} from '@/components';
import {
  DevelopScopeItemState,
  UpdateSpecialRequestArgs,
  useDeleteDevelopScopeItemMutation,
  useGetSpecialRequestDetailsQuery,
  useUpdateSpecialRequestMutation,
} from '@/redux';
import { Col, Collapse, Row, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Assert, DevelopScopeItemTaskStatus } from '@/views';
import { CommunityPermissions } from '@/core';
import { useState } from 'react';
import styles from './DevelopScope.module.scss';
import { SpecialRequestDetailsModal } from '../Personalization.Details/SpecialRequest/SpecialRequestDetailsModal';

interface Props {
  item: DevelopScopeItemState;
  readonly?: boolean;
  onEditClick: () => any;
}

const { Title } = Typography;
const { Panel } = Collapse;

const EXPANDABLE_SETTINGS = {
  rows: 1,
  expandable: true,
  tooltip: true,
  symbol: ' ',
};

const useDelete = (props: Props) => {
  const { item } = props;
  const [deleteMutation] = useDeleteDevelopScopeItemMutation();

  return () =>
    deleteMutation({ id: item.id })
      .unwrap()
      .then(() => notify.success.t('developScope.details.deleted'));
};

const useSubmit = (onClose: () => any) => {
  const [add] = useUpdateSpecialRequestMutation();

  return (args: UpdateSpecialRequestArgs) => {
    add(args)
      .unwrap()
      .then(() => {
        notify.success.t('specialRequests.saved');
        onClose();
      })
      .finally(onClose);
  };
};

export function DevelopScopeItemReadView(props: Props) {
  const { item, readonly, onEditClick } = props;
  const { t } = useTranslation();
  const handleDelete = useDelete(props);

  const clippoImagesProps = useImageBoxLazy({
    defer: false,
    ownerId: item.id,
    ownerType: 'DevelopScopeItemImage',
  });
  const [openEditModal, setOpenEditModal] = useState(false);
  const { data: selectedSpecialRequest, isFetching } =
    useGetSpecialRequestDetailsQuery(
      { id: item.specialRequest?.id ?? '' },
      { skip: !item.specialRequest },
    );
  const submit = useSubmit(() => setOpenEditModal(false));

  const [activeKey, setActiveKey] = useState<string[]>([]);

  return (
    <>
      <Collapse
        ghost
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key as string[])}
        className={styles.collapse}
      >
        <Panel
          key={item.id}
          header={
            <Row justify="space-between">
              <Col>
                <Title
                  style={{ maxWidth: '150px' }}
                  level={4}
                  className="text-multiline"
                  ellipsis={EXPANDABLE_SETTINGS}
                >
                  {item.description}
                </Title>
              </Col>
              <Col>
                <Assert
                  permission={CommunityPermissions.DevelopScope.Manage}
                  active
                >
                  <Space size="middle">
                    <EditIconButton onClick={onEditClick}>
                      {t('developScope.details.editButton')}
                    </EditIconButton>
                    <DeleteIconButton
                      onClick={handleDelete}
                      confirm
                      entityName={t('developScope.details.entityName')}
                    >
                      {t('developScope.details.deleteButton')}
                    </DeleteIconButton>
                  </Space>
                </Assert>
              </Col>
            </Row>
          }
        >
          <Row>
            <Col offset={1} span={24}>
              {item.specialRequest && (
                <TextBox label={t('developScope.details.specialRequest')}>
                  <NavLink to="" onClick={() => setOpenEditModal(true)}>
                    {item.specialRequest?.name}
                  </NavLink>
                </TextBox>
              )}
              <DevelopScopeItemTaskStatus item={item} readonly={readonly} />
            </Col>
          </Row>
          <Row>
            <Col offset={1} className="mt-2">
              <ImageBox {...clippoImagesProps} readonly compact hideEmpty />
            </Col>
          </Row>
        </Panel>
      </Collapse>
      {openEditModal && selectedSpecialRequest && !isFetching && (
        <SpecialRequestDetailsModal
          isEdit
          title={t('specialRequests.editTitle')}
          stepId={selectedSpecialRequest.step.id}
          projectId={selectedSpecialRequest.projectId}
          homeId={selectedSpecialRequest.homeId}
          onSubmit={submit}
          onClose={() => setOpenEditModal(false)}
          value={{
            ...selectedSpecialRequest,
            stepId: selectedSpecialRequest.step.id,
            categoryId: selectedSpecialRequest.category.category.id,
          }}
        />
      )}
    </>
  );
}
