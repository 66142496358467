import { Button, Space } from 'antd';
import { Form, Title } from '@/components';
import * as Yup from 'yup';
import { useCategoryLookupSource } from '@/views';
import { useTranslation } from 'react-i18next';

const schema = Yup.object().shape({
  name: Yup.string().field().required().nullable(),
  parentId: Yup.string().field().required().nullable(),
});

export interface SubcategoryDetailsFormValue {
  parentId: string;
  name: string;
}

export const INITIAL_SUBCATEGORY: SubcategoryDetailsFormValue = {
  parentId: null!,
  name: null!,
};

interface Props {
  onCancel: () => any;
  onSubmit: (value: SubcategoryDetailsFormValue) => any;
  value: SubcategoryDetailsFormValue;
  title: string;
  communityId: string;
  isEdit?: boolean;
}

export function SubcategoryDetailsForm(props: Props) {
  const { onCancel, value, onSubmit, title, communityId, isEdit } = props;
  const dataSource = useCategoryLookupSource({
    communityId,
    listed: true,
    canAddChild: true,
  });

  const { t } = useTranslation();

  return (
    <Form.Formik<SubcategoryDetailsFormValue>
      uid="subcategory-details"
      i18n="items.details.subcategories"
      initialValues={value}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <Title level={2}>{title}</Title>
      <Form.AsyncSelect
        dataSource={dataSource}
        name="parentId"
        preloadInitial={value.parentId}
        required
        disabled={isEdit}
      />
      <Form.Input name="name" required />
      <Space>
        <Button type="default" onClick={onCancel}>
          {t('items.details.subcategories.cancelButton')}
        </Button>
        <Form.Submit type="primary">
          {t('items.details.subcategories.saveButton')}
        </Form.Submit>
      </Space>
    </Form.Formik>
  );
}
