import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { AssignTaskToDevelopScopeButton } from '@/views';
import { EnumValue, NavLink, TextBox } from '@/components';
import { TaskStatus } from '@/core';
import { DevelopScopeItemState } from '@/redux';
import styles from './DevelopScope.module.scss';

interface Props {
  item: DevelopScopeItemState;
  readonly?: boolean;
}

export const DevelopScopeItemTaskStatus = (props: Props) => {
  const { item, readonly } = props;
  const { t } = useTranslation();
  const { Text } = Typography;

  if (!item.taskRequired) {
    return <Text>{t('developScope.details.taskNotRequired')}</Text>;
  }
  if (item.taskRequired && item.projectTaskId != null) {
    return (
      <>
        <TextBox label={t('tasks.details.name')}>
          <NavLink to={`/tasks/${item.projectTaskId}`}>{item.taskName}</NavLink>
        </TextBox>
        <TextBox label={t('developScope.details.responsible')}>
          {item.responsiblePerson?.label}
        </TextBox>
        <TextBox label={t('tasks.details.status')}>
          <Text className={styles.status}>
            <EnumValue type={TaskStatus} value={item.taskStatus} />
          </Text>
        </TextBox>
      </>
    );
  }
  if (readonly) {
    return <>{t('developScope.details.taskRequired')}</>;
  }
  return (
    <AssignTaskToDevelopScopeButton
      homeId={item.homeId}
      projectId={item.projectId}
      developScopeItemId={item.id}
    />
  );
};
