import { DeleteIconButton, Form, LookupOption } from '@/components';
import { Col, Row, Space } from 'antd';
import { useField } from 'formik';
import { useCallback, useMemo } from 'react';
import { TaskResponsiblePersonValue, TemplateTaskDetailsDto } from '@/core';
import styles from '../FormTaskTemplate.module.scss';
import classNames from 'classnames';
import { TemplateTaskDto } from "@/core";
import {
  mapResponsiblePersonValue,
  MAX_VALUE,
  useResponsiblePersonsLookupSource
} from "@/views";
import { useTranslation } from "react-i18next";

export interface TemplateTaskProps {
  name: string;
  communityId?: string;
  initResponsiblePerson?: TaskResponsiblePersonValue;
  step?: TemplateTaskDetailsDto;
}

function useHandleDelete(props: TemplateTaskProps) {
  const { name } = props;
  const [{ value }] = useField<TemplateTaskDto>(name);
  const collectionField = useField<TemplateTaskDto[]>(getCollectionName(name));
  const [{ value: collection }, , { setValue: setCollection }] =
    collectionField;

  return useCallback(() => {
    setCollection(collection.filter((x) => x !== value));
  }, [collection, setCollection, value]);
}

function getCollectionName(itemPath: string) {
  return itemPath.replace(/\[\d+]$/, '');
}

export function FormTemplateTask(props: TemplateTaskProps) {
  const {
    name,
    communityId,
    initResponsiblePerson
  } = props;
  const handleDelete = useHandleDelete(props);
  const responsiblePersonDataSource = useResponsiblePersonsLookupSource(
    communityId,
  )
  const { t } = useTranslation(undefined, { keyPrefix: 'taskTemplates.details', });

  const responsiblePersonPreload: LookupOption | undefined = useMemo(
    () =>
      initResponsiblePerson
        ? {
          label: initResponsiblePerson.label,
          value: mapResponsiblePersonValue(initResponsiblePerson),
        }
        : undefined,
      [initResponsiblePerson]
  );

  return (
    <div>
      <Row>
        <Col flex={1}>
          <Form.Input name={`${name}.name`} label={t('task')} required/>
          <Form.CreatableAsyncSelect
            name={`${name}.responsiblePerson`}
            label={t('responsiblePerson')}
            dataSource={responsiblePersonDataSource}
            preloadInitial={responsiblePersonPreload}
            />
          <Form.Number
            name={`${name}.costEstimate`}
            label={t('costEstimate')}
            prefix="$"
            min={0}
            max={MAX_VALUE}
            placeholder={t('placeholders.estimatedCost')}/>
        </Col>
        <Col className={classNames('ps-3', styles.stepActions)}>
          <Space size="middle">
            <DeleteIconButton className="mt-5" onClick={handleDelete} />
          </Space>
        </Col>
      </Row>
    </div>
  );
}