import { Button, Dropdown, Menu } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

export interface ContextMenuAction {
  if?: boolean | (() => boolean);
  label: React.ReactNode;
  onClick: () => any;
}

export interface ContextMenuProps {
  items: ContextMenuAction[];
}

function shouldRenderAction(action: ContextMenuAction) {
  return (
    action.if === undefined ||
    action.if === true ||
    (typeof action.if === 'function' && action.if())
  );
}

export function ContextMenu(props: ContextMenuProps) {
  const { items: itemsProp } = props;

  const items = useMemo(() => {
    const filtered = itemsProp.filter(shouldRenderAction);
    return filtered.map(({ label, onClick }) => ({
      key: uuid(),
      label,
      onClick,
    }));
  }, [itemsProp]);

  if (isEmpty(items)) {
    return <></>;
  }

  return (
    <Dropdown overlay={<Menu items={items} />} placement="bottomRight" arrow>
      <Button type="link" icon={<MoreOutlined />} />
    </Dropdown>
  );
}
