import { Page } from '@/components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRedirect, useSearchResult } from '.';

export function OpenItemByNumberPage() {
  const { t } = useTranslation();
  const { number } = useParams<'number'>();
  const { searchResult, searchPending } = useSearchResult(number!);

  const redirect = useRedirect(searchResult);
  useEffect(() => {
    redirect();
  }, [searchResult, redirect]);

  const title =
    !searchPending && !searchResult
      ? t('itemNotFound', { value: number })
      : t('redirectingTo', { value: number });
  return (
    <Page htmlTitle={number}>
      <Page.Paths>
        <Page.Path>{number}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card title={title} color="#814A93"></Page.Card>
      </Page.Body>
    </Page>
  );
}
