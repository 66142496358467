import { Modal, Button, Col, Alert } from 'antd';
import {
  DeleteIconButton,
  EditIconButton,
  FieldRow,
  Form,
  LookupOption,
  TextBox,
  useAppFindFormik,
} from '@/components';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { AddHomeArgs } from '@/redux';
import {
  AssignFloorplanToHomeInForm,
  FormSelectLocation,
  useProjectLookupSource,
} from '@/views';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  AssignFloorplanToHomeDto,
  FloorplanDetailsDto,
  isChooseExistingOption,
} from '@/core';
import Link from 'antd/lib/typography/Link';

const schema = Yup.object().shape({
  homeNumber: Yup.string().field().nullable().required(),
  constructionNumber: Yup.string().field().nullable(),
  locations: Yup.object().shape({
    level1Id: Yup.string().nullable(),
    level2Id: Yup.string().nullable(),
    level3Id: Yup.string().nullable(),
  }),
  projectId: Yup.string().field().nullable(),
});

interface Props {
  onClose: () => any;
  onSubmit: (props?: any, addAnotherHouse?: boolean) => any;
  value: AddHomeArgs & { id?: string };
  floorplan?: FloorplanDetailsDto;
  title: string;
  buttonSaveName: string;
  buttonSaveAndAddName?: string;
  preloadInitialProjectValue?: LookupOption;
  showSaveAndAddButton?: boolean;
  enableReassign?: boolean;
}

export function HomeDetailsModal(props: Props) {
  const {
    onClose: onCancel,
    value,
    onSubmit,
    title,
    buttonSaveAndAddName,
    buttonSaveName,
    preloadInitialProjectValue,
    showSaveAndAddButton,
    floorplan,
    enableReassign,
  } = props;
  const { t } = useTranslation();
  const [anotherHouse, setAnotherHouse] = useState(false);
  const formik = useAppFindFormik('home-details');

  const handleSubmit = useCallback(
    (value: AddHomeArgs) => {
      if (value.location?.level1Id == null) {
        value = { ...value, location: null! };
      }
      onSubmit(value, anotherHouse);
      formik.current?.resetForm();
      clearFloorplanData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [anotherHouse, formik, onSubmit],
  );

  const projectsDataSource = useProjectLookupSource(value.communityId, true);

  const location = value.location;
  const usedLocationIds = useMemo(
    () =>
      [location?.level1Id, location?.level2Id, location?.level3Id].filter(
        (x) => !!x,
      ) as string[],
    [location],
  );

  const [showFloorplanPanel, setShowFloorplanPanel] = useState(false);
  const [floorplanName, setFloorplanName] = useState<string>();
  const [floorplanId, setFloorplanId] = useState<string>();

  function setFloorplanData(floorplanData: AssignFloorplanToHomeDto) {
    const isChooseExisting = isChooseExistingOption(
      floorplanData.creationOption,
    );
    isChooseExisting
      ? formik.current?.setFieldValue('floorplanId', floorplanData.floorplanId)
      : formik.current?.setFieldValue('floorplanData', floorplanData);

    setShowFloorplanPanel(false);
    setFloorplanName(floorplanData.marketingName);
    setFloorplanId(isChooseExisting ? floorplanData.floorplanId : undefined);
  }

  function clearFloorplanData() {
    formik.current?.setFieldValue('floorplanId', undefined);
    formik.current?.setFieldValue('floorplanData', undefined);
    setFloorplanName(floorplan?.marketingName);
    setShowFloorplanPanel(false);
  }

  useEffect(() => {
    setFloorplanName(floorplan?.marketingName);
    setFloorplanId(floorplan?.id);
  }, [floorplan]);

  return (
    <Form.Formik<AddHomeArgs>
      uid="home-details"
      i18n="homes.details"
      initialValues={value}
      validationSchema={schema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ submitForm }) => (
        <Modal
          title={title}
          visible
          onCancel={onCancel}
          footer={
            showSaveAndAddButton
              ? [
                  <Button key="back" type="default" onClick={onCancel}>
                    {t('homes.details.cancel')}
                  </Button>,
                  <Button
                    type="primary"
                    onClick={() => {
                      setAnotherHouse(true);
                      submitForm();
                    }}
                  >
                    {buttonSaveAndAddName}
                  </Button>,
                  <Button
                    type="primary"
                    onClick={() => {
                      setAnotherHouse(false);
                      submitForm();
                    }}
                  >
                    {buttonSaveName}
                  </Button>,
                ]
              : [
                  <Button key="back" type="default" onClick={onCancel}>
                    {t('homes.details.cancel')}
                  </Button>,
                  <Button
                    type="primary"
                    onClick={() => {
                      setAnotherHouse(false);
                      submitForm();
                    }}
                  >
                    {buttonSaveName}
                  </Button>,
                ]
          }
        >
          <>
            <FieldRow>
              <Col span={12}>
                <Form.Input
                  name="homeNumber"
                  placeholder={t('homes.details.placeholder.homeNumber')}
                  required
                />
              </Col>
              <Col span={12}>
                <Form.Input name="constructionNumber" />
              </Col>
            </FieldRow>
            <FieldRow>
              <Col span={12}>
                <FormSelectLocation
                  name="location"
                  communityId={value.communityId}
                  includeUnlistedIds={usedLocationIds}
                />
              </Col>
            </FieldRow>
            <FieldRow>
              <Col span={12}>
                <Form.AsyncSelect
                  name="projectId"
                  dataSource={projectsDataSource}
                  preloadInitial={preloadInitialProjectValue}
                  className="w-100"
                />
              </Col>
            </FieldRow>
            <FieldRow>
              {!showFloorplanPanel && !floorplanName && showSaveAndAddButton && (
                <Button
                  type="link"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setShowFloorplanPanel(true);
                  }}
                  className="mb-1"
                >
                  {t('homes.details.addFloorplan')}
                </Button>
              )}
              {floorplanName && (
                <>
                  <Col span={12}>
                    <TextBox label={t('homes.details.floorplan')}>
                      <Link
                        target="blank"
                        href={
                          floorplanId &&
                          `/communities/${value.communityId}/floorplans/${floorplanId}`
                        }
                        disabled={!floorplanId}
                      >
                        {floorplanName}
                      </Link>
                    </TextBox>
                  </Col>
                  <Col span={3}>
                    {floorplan && enableReassign && (
                      <EditIconButton
                        title={t('floorplans.details.reassign')}
                        onClick={() => {
                          setFloorplanName(undefined);
                          setShowFloorplanPanel(true);
                        }}
                      />
                    )}
                    {!floorplan && (
                      <DeleteIconButton onClick={clearFloorplanData} />
                    )}
                  </Col>
                </>
              )}
            </FieldRow>
            {showFloorplanPanel && (
              <AssignFloorplanToHomeInForm
                communityId={value.communityId}
                homeId={value.id}
                onCancel={clearFloorplanData}
                onSubmit={setFloorplanData}
                floorplan={floorplan}
              />
            )}
            {value.projectId && (
              <Alert
                className="mt-2"
                description={t('homes.details.changeProjectTip')}
              />
            )}
          </>
        </Modal>
      )}
    </Form.Formik>
  );
}
