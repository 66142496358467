import { ProjectHomePersonalizationTabsPanel } from './ProjectHomePersonalizationTabsPanel';

interface Props {
  projectId: string;
  selectedHomeId?: string;
}

export function ProjectHomePersonalizationPanel(props: Props) {
  const { projectId, selectedHomeId } = props;

  return (
    <>
      {selectedHomeId && (
        <ProjectHomePersonalizationTabsPanel
          projectId={projectId}
          homeId={selectedHomeId!}
          title={'home title'}
        />
      )}
    </>
  );
}
