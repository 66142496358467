import { Icon, NavLink, notify } from '@/components';
import { CommunityPermissions } from '@/core';
import {
  ItemMenuLookupState,
  useAssignMenuToHomesMutation,
  useGetProjectQuery,
} from '@/redux';
import { useBool } from '@/utils';
import { Button } from 'antd';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { mapItemMenuLookup, useCommunityContext } from '@/views';
import { CommunityDetailsTabKeys } from '../Communities.Details';
import {
  AssignMenuDetailsModalValue,
  AssignMenuModal,
} from '../Projects.Homes.Details';
import styles from './HomeProjectLink.module.scss';

interface Props {
  communityId: string;
  menu: ItemMenuLookupState;
  homeId: string;
  projectId: string;
}

const useSubmit = (toggleModalOpen: () => any, props: Props) => {
  const { projectId, homeId } = props;
  const [assign] = useAssignMenuToHomesMutation();

  return useCallback(
    async (args: AssignMenuDetailsModalValue) => {
      return assign({ ...args, homeIds: [homeId], projectId })
        .unwrap()
        .then(() => notify.success.t('projects.homeList.assignMenu.assigned'))
        .then(() => toggleModalOpen());
    },
    [assign, projectId, homeId, toggleModalOpen],
  );
};

export const HomeMenuDetails = (props: Props) => {
  const { communityId, menu, projectId } = props;
  const { data: project } = useGetProjectQuery({ id: projectId });
  const [modalOpen, , toggleModalOpen] = useBool(false);
  const { t } = useTranslation();
  const submit = useSubmit(toggleModalOpen, props);
  const { assert } = useCommunityContext();

  const value = useMemo(() => ({ menuId: menu.id }), [menu]);
  const preloadValue = mapItemMenuLookup(menu);

  const canReassign =
    project?.status === 'NotStarted' &&
    assert(CommunityPermissions.Homes.Manage, true);

  return (
    <>
      <div>
        <span className={styles.label}> {t('homes.details.menu')}</span>
        <NavLink
          to={`/communities/${communityId}#${CommunityDetailsTabKeys.menus}`}
        >
          {menu.name}
        </NavLink>
        {canReassign && (
          <Button
            type="link"
            icon={<Icon type="edit" className={styles.icon} />}
            onClick={toggleModalOpen}
          />
        )}
      </div>

      {modalOpen && (
        <AssignMenuModal
          onCancel={toggleModalOpen}
          title={t('homes.details.reassignMenu')}
          onSubmit={submit}
          value={value}
          preloadInitialValue={preloadValue}
          communityId={communityId}
        />
      )}
    </>
  );
};
