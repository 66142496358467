import { StyledRow, Title } from '@/components';
import { useGetProfileQuery, useUserContextSelector } from '@/redux';
import { Col, Row } from 'antd';
import { UserGeneralDetails } from './UserGeneralDetails';
import { formatters } from '@/core';
import { UserAvatarEditComponent } from '..';
import { AuthenticationDetails } from '../Users.Details/AuthenticationDetails';

export function UserProfilePanel() {
  const { data, isLoading } = useGetProfileQuery();
  const isAdmin = useUserContextSelector((x) => x.isAdmin);
  if (isLoading || !data) {
    return null;
  }

  return (
    <>
      <StyledRow className="mb-4">
        <Col span={24}>
          <Row align="middle">
            <Col>
              <UserAvatarEditComponent user={data} />
            </Col>
            <Col offset={1}>
              <Title level={2}>{formatters.name(data)}</Title>
            </Col>
          </Row>
        </Col>
      </StyledRow>
      <StyledRow>
        <Col flex="auto">
          <UserGeneralDetails
            noEditButton={!isAdmin}
            user={data}
            roles={['BFP_ADMIN', 'CLIENT_ADMIN', 'CLIENT_USER']}
          />
        </Col>
        <Col flex="auto">
          <AuthenticationDetails user={data} />
        </Col>
      </StyledRow>
    </>
  );
}
