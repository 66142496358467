import { Modal, Button, Col } from 'antd';
import {
  FieldRow,
  Form,
  LookupOption,
  SELECT_OTHER_VALUE_PREFIX,
} from '@/components';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  AddTaskArgs,
  UpdateTaskArgs,
  useGetProjectQuery,
  useLazyGetTasksListQuery,
} from '@/redux';
import {
  mapResponsiblePersonValue,
  parseResponsiblePersonValue,
  useHomeLookupSource,
  useResponsiblePersonsLookupSource,
} from '@/views';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormsAutoCompleteOption } from '@/components/Forms/FormsAutoComplete';
import { DEFAULT_PAGING } from '@/core';
import i18next from 'i18next';

export const MAX_VALUE = 100_000_000;

const schema = Yup.object().shape({
  homeId: Yup.string().field().nullable().required(),
  name: Yup.string().field().nullable().required(),
  startDate: Yup.date()
    .max(Yup.ref('endDate'), {
      key: 'tasks_start_date_must_be_before_end_date',
    })
    .nullable(),
  endDate: Yup.date()
    .min(Yup.ref('startDate'), {
      key: 'tasks_end_date_must_be_after_start_date',
    })
    .nullable(),
  estimatedCost: Yup.number().min(0).max(MAX_VALUE).nullable(),
  responsiblePerson: Yup.string()
    .test('has-value', i18next.t('errors.mixed.required'), function (value) {
      if (value && value.startsWith(SELECT_OTHER_VALUE_PREFIX)) {
        const suffix = value.substring(SELECT_OTHER_VALUE_PREFIX.length).trim();
        return suffix.length > 0;
      } else {
        return !!value ? value.length > 0 : false;
      }
    })
    .required()
    .nullable(),
  description: Yup.string().field('lg').nullable(),
});

export function useGetTaskNameOptions(communityId?: string) {
  const [getClientDetails] = useLazyGetTasksListQuery();
  const [searchResult, setSearchResult] = useState<FormsAutoCompleteOption[]>();

  useEffect(() => {
    getClientDetails({ communityId, paging: DEFAULT_PAGING })
      .unwrap()
      .then((result) =>
        setSearchResult(result.items.map((e) => ({ value: e.name }))),
      );
  }, [getClientDetails, communityId]);

  return searchResult;
}

interface TaskDetailsModalProps {
  onClose: () => any;
  onSubmit: (props?: any) => any;
  value: AddTaskArgs | UpdateTaskArgs;
  title: string;
  buttonSaveName: string;
  homeDisabled?: boolean;
}

function useSubmit(props: TaskDetailsModalProps) {
  const { onSubmit } = props;

  return useCallback(
    (data: any) => {
      data.responsiblePerson = parseResponsiblePersonValue(
        data.responsiblePerson,
      );
      onSubmit(data);
    },
    [onSubmit],
  );
}

export function TaskDetailsModal(props: TaskDetailsModalProps) {
  const {
    onClose: onCancel,
    value,
    title,
    buttonSaveName,
    homeDisabled,
  } = props;
  const { t } = useTranslation();
  const { data: projectData } = useGetProjectQuery(
    { id: value.projectId! },
    { skip: !value.projectId },
  );
  const homesDataSource = useHomeLookupSource(
    projectData?.communityId,
    projectData?.id,
  );

  const responsiblePersonPreload: LookupOption | undefined = useMemo(
    () =>
      value.responsiblePerson
        ? {
            label: value.responsiblePerson.label,
            value: mapResponsiblePersonValue(value.responsiblePerson),
          }
        : undefined,
    [value],
  );

  const responsiblePersonDataSource = useResponsiblePersonsLookupSource(
    projectData?.communityId,
  );

  const options = useGetTaskNameOptions(projectData?.communityId);

  const onSubmit = useSubmit(props);

  return projectData ? (
    <Modal
      title={title}
      visible
      onCancel={onCancel}
      footer={[
        <Button key="back" type="default" onClick={onCancel}>
          {t('tasks.details.cancel')}
        </Button>,
        <Form.Submit uid="task-details" key="submit" type="primary">
          {buttonSaveName}
        </Form.Submit>,
      ]}
    >
      <Form.Formik<AddTaskArgs | UpdateTaskArgs>
        uid="task-details"
        i18n="tasks.details"
        initialValues={value}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        <FieldRow>
          <Col span={12}>
            <Form.AsyncSelect
              key={value.homeId}
              name="homeId"
              dataSource={homesDataSource}
              required
              preload
              style={{ width: '100%' }}
              disabled={homeDisabled}
            />
          </Col>
        </FieldRow>
        <FieldRow>
          <Col span={12}>
            <Form.AutoComplete
              options={options}
              name="name"
              required
              filterOption
              placeholder={t('tasks.details.placeholder.name')}
            />
          </Col>
        </FieldRow>
        <FieldRow>
          <Col span={12}>
            <Form.Date
              name="startDate"
              placeholder={t('tasks.details.placeholder.startDate')}
              allowClear={false}
            />
          </Col>
          <Col span={12}>
            <Form.Date
              name="endDate"
              placeholder={t('tasks.details.placeholder.endDate')}
              allowClear={false}
            />
          </Col>
        </FieldRow>
        <FieldRow>
          <Col span={12}>
            <Form.CreatableAsyncSelect
              required
              name="responsiblePerson"
              dataSource={responsiblePersonDataSource}
              preloadInitial={responsiblePersonPreload}
              className="w-100"
            />
          </Col>
          <Col span={12}>
            <Form.Number
              name="estimatedCost"
              prefix="$"
              min={0}
              max={MAX_VALUE}
              placeholder={t('tasks.details.placeholder.estimatedCost')}
            />
          </Col>
        </FieldRow>
        <FieldRow>
          <Col span={24}>
            <Form.TextArea
              name="description"
              placeholder={t('tasks.details.placeholder.description')}
            />
          </Col>
        </FieldRow>
      </Form.Formik>
    </Modal>
  ) : (
    <></>
  );
}
