import { PricingNotCompletedPayload } from '@/core';
import { Button, Modal, Space } from 'antd';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

interface Props {
  errorPayload: PricingNotCompletedPayload;

  onClose: () => any;
}

export function PricingNotCompletedModal(props: Props) {
  const { onClose } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pricing.notCompletedModal',
  });

  const {
    tbdItems,
    notInMenuItems,
    tbdSpecialRequests,
    invalidStatusSpecialRequests,
  } = props.errorPayload;

  const tbd = tbdItems.concat(tbdSpecialRequests);

  return (
    <Modal
      title={t('title')}
      visible
      onCancel={onClose}
      footer={[
        <Button key="close" type="primary" onClick={onClose}>
          {t('close')}
        </Button>,
      ]}
    >
      <Space direction="vertical" size="large">
        {!isEmpty(tbd) && (
          <div>
            {t('tbd.header')}
            <ul className="mt-2">
              {tbd.map(({ name, number }, index) => (
                <li key={index}>
                  {number && `${number}: `}
                  {name}
                </li>
              ))}
            </ul>
            {t('tbd.footer')}
          </div>
        )}

        {!isEmpty(notInMenuItems) && (
          <div>
            {t('notInMenu.header')}
            <ul className="mt-2">
              {notInMenuItems.map(({ name, number }, index) => (
                <li key={index}>
                  {number}: {name}
                </li>
              ))}
            </ul>
            {t('notInMenu.footer')}
          </div>
        )}

        {!isEmpty(invalidStatusSpecialRequests) && (
          <div>
            {t('invalidStatusSpecialRequests.header')}
            <ul className="mt-2">
              {invalidStatusSpecialRequests.map(({ name }, index) => (
                <li key={index}>{name}</li>
              ))}
            </ul>
          </div>
        )}
      </Space>
    </Modal>
  );
}
