import { FormsGroup } from './FormsGroup';
import { FormsInput } from './FormsInput';
import { FormsText } from './FormsText';
import { FormsFormik } from './FormsFormik';
import { FormsSubmit } from './FormsSubmit';
import { FormsFieldSet } from './FormsFieldSet';
import { Form as FormsForm } from 'antd';
import { FormsSelect } from './FormsSelect';
import { FormsCheckbox } from './FormsCheckbox';
import { FormsStateSelect } from './FormsStateSelect';
import { FormsAddress } from './FormsAddress';
import { FormsEnumSelect } from './FormsEnumSelect';
import { FormsContacts } from './FormsContacts';
import { FormsTextArea } from './FormsTextArea';
import { FormsTree } from './FormsEditTree';
import { FormsAsyncSelect } from './FormsAsyncSelect';
import { FormsDate } from './FormsDate';
import { FormsNumber } from './FormsNumber';
import { FormsRadio } from './FormsRadio';
import { FormsYesNo } from './FormsYesNo';
import { FormsFieldChange } from './FormsFieldChange';
import { FormsMoney } from './FormsMoney';
import { FormsImages } from './FormsImages';
import { FormsCreatableAsyncSelect } from './FormsCreatableAsyncSelect';
import { FormsDeferAttach } from './FormsDeferAttach';
import { FormsErrorMessage } from './FormsErrorMessage';
import { FormsFileUpload } from './FormsFileUpload';
import { FormsPhoneInput as FormsPhone } from './FormsPhoneInput';
import { FormsSwitch } from './FormsSwitch';
import { FormsAutoComplete } from './FormsAutoComplete';

export const Form = Object.assign(FormsForm, {
  Group: FormsGroup,
  Input: FormsInput,
  TextArea: FormsTextArea,
  Text: FormsText,
  Formik: FormsFormik,
  Submit: FormsSubmit,
  FieldSet: FormsFieldSet,
  Select: FormsSelect,
  Checkbox: FormsCheckbox,
  StateSelect: FormsStateSelect,
  Address: FormsAddress,
  EnumSelect: FormsEnumSelect,
  Contacts: FormsContacts,
  Phone: FormsPhone,
  Tree: FormsTree,
  AsyncSelect: FormsAsyncSelect,
  CreatableAsyncSelect: FormsCreatableAsyncSelect,
  Date: FormsDate,
  Number: FormsNumber,
  Radio: FormsRadio,
  YesNo: FormsYesNo,
  FieldChange: FormsFieldChange,
  Money: FormsMoney,
  Images: FormsImages,
  DeferAttach: FormsDeferAttach,
  ErrorMessage: FormsErrorMessage,
  FileUpload: FormsFileUpload,
  Switch: FormsSwitch,
  AutoComplete: FormsAutoComplete,
});

export { useAppFindFormik, useAppSubmitFormik } from './FormsContext';
export { useFieldSet } from './FormsFieldSet';
export { INITIAL_CONTACT } from './FormsContacts';
export { dataNodeUtils } from './FormsEditTree';
export type { FormRadioOption } from './FormsRadio';
