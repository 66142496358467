import { guard } from '@/utils';
import qs from 'qs';
import { Http } from '../http';
import {
  AddSpecialRequestCommentDto,
  AddSpecialRequestDto,
  SpecialRequestCommentDto,
  SpecialRequestDto,
  UpdateSpecialRequestCommentDto,
  UpdateSpecialRequestDto,
} from './specialRequestTypes';
import { isArray } from 'lodash';

class SpecialRequestsHttp extends Http {
  public async addSpecialRequest(request: AddSpecialRequestDto) {
    guard.notNull(request, 'request');
    const response = await this._axios.post<string>(
      `/api/home/personalization/special-request`,
      request,
    );
    return response.data;
  }

  public async getAllSpecialRequests(args: {
    homeId: string;
    projectId: string;
    isFloorplan?: boolean;
    stepId?: string | string[];
    roomId?: string;
    wholeHome?: boolean;
    categoryId?: string;
    asItem?: boolean;
  }) {
    const stepId = isArray(args.stepId)
      ? args.stepId
      : args.stepId
      ? [args.stepId]
      : undefined;
    const query = qs.stringify({ ...args, stepId: stepId });
    const response = await this._axios.get<SpecialRequestDto[]>(
      `/api/home/personalization/special-request?${query}`,
    );
    return response.data;
  }

  public async deleteSpecialRequests(id: string) {
    const query = qs.stringify({ id });
    const response = await this._axios.delete(
      `/api/home/personalization/special-request?${query}`,
    );
    return response.data;
  }

  public async updateSpecialRequest(request: UpdateSpecialRequestDto) {
    guard.notNull(request, 'request');
    await this._axios.put(`/api/home/personalization/special-request`, request);
  }

  public async addSpecialRequestComment(request: AddSpecialRequestCommentDto) {
    guard.notNull(request, 'request');
    await this._axios.post(
      `/api/home/personalization/special-request/comment`,
      request,
    );
  }

  public async updateSpecialRequestComment(
    request: UpdateSpecialRequestCommentDto,
  ) {
    guard.notNull(request, 'request');
    await this._axios.put(
      `/api/home/personalization/special-request/comment`,
      request,
    );
  }

  public async getAllSpecialRequestComments(args: {
    specialRequestId: string;
  }) {
    const query = qs.stringify({ ...args });
    const response = await this._axios.get<SpecialRequestCommentDto[]>(
      `/api/home/personalization/special-request/comment?${query}`,
    );
    return response.data;
  }

  public async deleteSpecialRequestComment(id: string) {
    guard.notNull(id, 'id');
    await this._axios.delete(
      `/api/home/personalization/special-request/comment/${id}`,
    );
  }

  public async getSpecialRequestDetails(id: string) {
    guard.notNull(id, 'id');
    const response = await this._axios.get<SpecialRequestDto>(
      `/api/home/personalization/special-request/details?${qs.stringify({
        id,
      })}`,
    );
    return response.data;
  }
}

export const specialRequestsHttp = new SpecialRequestsHttp();
