import { FieldRow, Form } from '@/components';
import { Col } from 'antd';
import { useTaskTemplateLookupSource } from '@/views';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

interface Props {
  onSubmit: (props: any) => any;
  communityId: string;
}

export interface SelectTaskTemplateFormValue {
  templateIds: string[];
}

const initialValue = {
  templateIds: [],
}

const schema = Yup.object().shape({
  templateIds: Yup.array()
    .min(1, i18next.t('errors.mixed.required'))
});

export function SelectTaskTemplateForm(props: Props) {
  const { onSubmit, communityId } = props;
  const taskTemplateDataSource = useTaskTemplateLookupSource(communityId);
  const { t } = useTranslation()

  return (
    <Form.Formik<SelectTaskTemplateFormValue>
      uid="select-task-template"
      initialValues={initialValue}
      onSubmit={onSubmit}
      validationSchema={schema}>
      <FieldRow>
        <Col span={12}>
          <Form.AsyncSelect
            name="templateIds"
            dataSource={taskTemplateDataSource}
            label={t('tasks.details.taskTemplateName')}
            mode={"multiple"}
            required
          />
        </Col>
      </FieldRow>
    </Form.Formik>
  );
}
