import { AddReportButton } from '@/components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GeneratePersonalizationSummaryReportModal } from './GeneratePersonalizationSummaryReportModal';

export function GeneratePersonalizationSummaryReportButton() {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <AddReportButton onClick={() => setOpenModal(true)}>
        {t('reports.personalizationSummaryReport')}
      </AddReportButton>
      {openModal && (
        <GeneratePersonalizationSummaryReportModal
          onClose={() => setOpenModal(false)}
        />
      )}
    </>
  );
}
