import { Auth, Form, Icon, notify, useAppFindFormik } from '@/components';
import { useGeneratePasswordMutation, UserState } from '@/redux';
import { generateSafePassword, useBool } from '@/utils';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './AuthenticationDetails.module.scss';
import * as Yup from 'yup';
import i18next from 'i18next';
import { useCallback } from 'react';

const schema = Yup.object().shape({
  new: Yup.string()
    .password()
    .nullable()
    .required()
    .notOneOf(
      [Yup.ref('current'), null],
      i18next.t('users.passwordChange.passwordCurrentMatchError'),
    ),
  newConfirmation: Yup.string()
    .oneOf(
      [Yup.ref('new'), null],
      i18next.t('users.passwordChange.passwordConfirmationMatchError'),
    )
    .nullable()
    .required(),
});

type FormValue = OmitIndex<Yup.InferType<typeof schema>>;

const useSubmit = (props: ResetPasswordButtonProps, onSubmit: () => void) => {
  const [changePassword] = useGeneratePasswordMutation();
  const form = useAppFindFormik('password-reset');
  const { user } = props;

  const submit = useCallback(
    async (value: FormValue) => {
      await changePassword({ userId: user.id, ...value })
        .unwrap()
        .then(() => notify.success.t('users.passwordChange.success'))
        .then(() => onSubmit());
      form.current!.resetForm();
    },
    [changePassword, form, onSubmit, user.id],
  );

  return { submit };
};

const INITIAL_VALUE: FormValue = {
  new: null!,
  newConfirmation: null!,
};

interface ResetPasswordButtonProps {
  user: UserState;
}

const useGeneratePassword = () => {
  const form = useAppFindFormik('password-reset');
  const generate = useCallback(() => {
    const newPassword = generateSafePassword();
    form.current?.setFieldValue('new', newPassword);
  }, [form]);

  return { generate };
};

function _ResetPasswordButton(props: ResetPasswordButtonProps) {
  const { t } = useTranslation();
  const [visible, , toggleVisible] = useBool(false);
  const { submit } = useSubmit(props, toggleVisible);
  const { generate } = useGeneratePassword();
  const form = useAppFindFormik('password-reset');
  const onClose = useCallback(() => {
    toggleVisible();
    form.current?.resetForm();
  }, [form, toggleVisible]);

  return (
    <>
      <Button type="link" className={styles.btn} onClick={toggleVisible}>
        {t('reset')}
      </Button>
      <Modal
        visible={visible}
        onCancel={onClose}
        title={t('users.details.authentication.generate')}
        footer={
          <>
            <Button onClick={onClose}>{t('cancel')}</Button>
            <Form.Submit uid="password-reset" type="primary">
              {t('save')}
            </Form.Submit>
          </>
        }
      >
        <Form.Formik<FormValue>
          uid="password-reset"
          i18n="users.passwordChange"
          initialValues={{ ...INITIAL_VALUE }}
          validationSchema={schema}
          onSubmit={submit}
        >
          <Form.Input name="new" type="password" required />
          <Form.Input name="newConfirmation" type="password" required />
          <Button type="link" onClick={generate} icon={<Icon type="key" />}>
            {t('users.details.authentication.generate')}
          </Button>
        </Form.Formik>
      </Modal>
    </>
  );
}

export const ResetPasswordButton = Auth.Banner(['BFP_ADMIN'])(
  _ResetPasswordButton,
);
