import {
  AddButton,
  TabPanel,
  useTabPanelNavMenuState,
  notify,
  EditIconButton,
} from '@/components';
import { useTranslation } from 'react-i18next';
import {
  useCreateRoomMutation,
  useGetRoomsListQuery,
  useUnlistRoomMutation,
  useUpdateRoomMutation,
} from '@/redux/rooms';
import { useMemo, useState } from 'react';
import { array } from '@/utils';
import { RoomItem } from './RoomItem';
import { INITIAL_VALUES } from './FormRoom';
import { RoomState, CommunityPermissions, RoomCategory } from '@/core';
import { UpdateWholeHomeFloorplanArgs } from '@/redux';
import { Assert, useCommunityContext } from '../Communities.Common';
import { Space } from 'antd';

interface Props {
  communityId: string;
  floorplanId: string;
  wholeHomeSquareFootage: number;
  wholeHomeCategories: RoomCategory[] | undefined;
  onWholeHomeUpdate?: (value: UpdateWholeHomeFloorplanArgs) => Promise<void>;
  readonly?: boolean;
  onEdit?: () => void;
}

const NEW_ROOM_ID = 'new';
const WHOLE_HOME_NAV_ID = 'whole-home';

export function RoomListTabPanel(props: Props) {
  const {
    communityId,
    floorplanId,
    wholeHomeSquareFootage,
    wholeHomeCategories,
    onWholeHomeUpdate: handleWholeHomeUpdate,
    readonly,
    onEdit,
  } = props;
  const { t } = useTranslation();

  const { data = array.empty<RoomState>() } = useGetRoomsListQuery({
    floorplanId,
  });

  const [update] = useUpdateRoomMutation();
  const [create] = useCreateRoomMutation();
  const [remove] = useUnlistRoomMutation();

  const [add, setAdd] = useState(false);

  const items = useMemo(
    () => (add ? ([...data, { ...INITIAL_VALUES }] as RoomState[]) : data),
    [data, add],
  );

  const tabNav = useTabPanelNavMenuState({
    items: [
      {
        id: WHOLE_HOME_NAV_ID,
        name: t('developScope.wholeHome'),
        squareFootage: wholeHomeSquareFootage,
        categories: wholeHomeCategories,
      } as RoomState,
      ...items,
    ],
    keyBy: (x) => x.id ?? NEW_ROOM_ID,
    titleBy: (x) => x.name ?? t('rooms.new'),
  });

  const { assert } = useCommunityContext();
  const isEditAvailable =
    onEdit && assert(CommunityPermissions.Floorplans.Manage, true);

  const { select, selected } = tabNav;

  const handleAddClick = () => {
    setAdd(true);
    select('new');
  };

  const handleSelect = (id: string) => {
    if (id !== NEW_ROOM_ID) {
      setAdd(false);
    }
    select(id);
  };

  const handleDelete = (room: RoomState) =>
    remove({ id: room.id })
      .unwrap()
      .then(() => notify.success.t('rooms.deleted'));

  const handleUpdate = (room: RoomState) =>
    update(room)
      .unwrap()
      .then(() => notify.success.t('rooms.saved'));

  const handleAdd = (room: RoomState) => {
    room.floorplanId = floorplanId;
    return create(room)
      .unwrap()
      .then(() => setAdd(false))
      .then(() => notify.success.t('rooms.added'));
  };

  if (!selected) {
    return null;
  }

  const handleSubmit =
    selected.id === WHOLE_HOME_NAV_ID
      ? handleWholeHomeUpdate
      : selected.id
      ? handleUpdate
      : handleAdd;

  return (
    <div>
      <TabPanel min="auto">
        <TabPanel.Title>
          <Space>
            <span>{t('floorplans.details.homeCategories')}</span>
            {isEditAvailable && <EditIconButton onClick={onEdit} />}
          </Space>
        </TabPanel.Title>
        <TabPanel.Actions>
          {!readonly && (
            <Assert permission={CommunityPermissions.Floorplans.Manage} active>
              <AddButton onClick={handleAddClick}>{t('rooms.add')}</AddButton>
            </Assert>
          )}
        </TabPanel.Actions>
        <TabPanel.Nav title={t('rooms.navTitle')}>
          <TabPanel.Menu<RoomState> {...tabNav} onSelected={handleSelect} />
        </TabPanel.Nav>
        <TabPanel.Body compact={!!selected}>
          <RoomItem
            key={selected.id}
            onDelete={handleDelete}
            onSubmit={handleSubmit}
            onCancel={() => setAdd(false)}
            value={selected}
            communityId={communityId}
            isWholeHome={selected.id === WHOLE_HOME_NAV_ID}
            readonly={readonly}
          />
        </TabPanel.Body>
      </TabPanel>
    </div>
  );
}
