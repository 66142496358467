import { useGetCommunityContextQuery } from '@/redux';
import { CommunityContext } from '@/views';
import { Outlet, useParams } from 'react-router-dom';

export function CommunitiesAreaPage() {
  const { communityId } = useParams<'communityId'>();
  const { data, isLoading } = useGetCommunityContextQuery({ id: communityId! });

  if (isLoading) {
    return null;
  }

  return (
    <CommunityContext.Provider value={data!}>
      <Outlet />
    </CommunityContext.Provider>
  );
}
