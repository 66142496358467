import { TreeProps } from 'antd';
import { DataNode } from 'antd/lib/tree';
import { useMemo } from 'react';
import { CustomTree, dataNodeUtils } from '@/components';
import { MIN_COUNT_TO_USE_VIRTUAL, DEFAULT_TREE_HEIGHT } from './Tree';

export function DirectoryTree<T extends DataNode = DataNode>(
  props: TreeProps<T>,
) {
  const height = useMemo(() => {
    return props.height ||
      dataNodeUtils.getNodesCount(props.treeData) > MIN_COUNT_TO_USE_VIRTUAL
      ? DEFAULT_TREE_HEIGHT
      : undefined;
  }, [props.height, props.treeData]);
  return <CustomTree.DirectoryTree {...props} height={height} />;
}
