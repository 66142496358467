import {
  AddButton,
  DeleteIconButton,
  EditIconButton,
  NavLink,
  notify,
  TabPanel,
  TextBox,
} from '@/components';
import {
  ProjectState,
  useDeleteProjectPathMutation,
  useGetProjectStepsQuery,
} from '@/redux';
import { useTranslation } from 'react-i18next';
import { AddPathToProjectPanel } from '@/views/Projects.Details/AddPathToProjectPanel';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Space } from 'antd';
import { ProjectDeadlines } from './ProjectDeadlines';
import { CommunityPermissions, ProjectStatus } from '@/core';
import { useCommunityContext } from '../Communities.Common';
import { useProjectContext } from '..';
import styles from './ProjectDetails.module.scss';

interface Props {
  data: ProjectState;
}

const useDeletePath = (projectId: string) => {
  const [remove] = useDeleteProjectPathMutation();

  return useCallback(() => {
    remove({ projectId })
      .unwrap()
      .then(() => notify.success.t('projects.path.deleted'));
  }, [projectId, remove]);
};

export const ProjectPathTabPanel = (props: Props) => {
  const { data } = props;
  const { data: steps } = useGetProjectStepsQuery({ ids: data.id });
  const { hasStatus, isActive } = useProjectContext();

  const { t } = useTranslation();
  const [add, setAdd] = useState(false);
  const push = useNavigate();
  const handleDelete = useDeletePath(data.id);

  const { assert } = useCommunityContext();
  const canManagePaths = assert(CommunityPermissions.Path.Manage, true);
  const canAddPath = hasStatus(['NotStarted']) && canManagePaths;
  const showAddPathPanel = add && canAddPath;

  return (
    <TabPanel>
      <TabPanel.Title>{t('projects.path.title')}</TabPanel.Title>
      {!data.pathName && (
        <TabPanel.Actions>
          {canAddPath && (
            <AddButton className={styles.add} onClick={() => setAdd(true)}>
              {t('projects.path.add')}
            </AddButton>
          )}
        </TabPanel.Actions>
      )}
      <TabPanel.Body>
        {data.pathName && steps && (
          <>
            <TextBox label={t('projects.path.title')}>
              <Space size="middle">
                <NavLink to={`/projects/${data.id}/path`}>
                  {data.pathName}
                </NavLink>
                {canManagePaths && isActive && (
                  <EditIconButton
                    type="link"
                    onClick={() => push(`/projects/${data.id}/path/edit`)}
                  />
                )}
                {data.status === ProjectStatus.NotStarted && canManagePaths && (
                  <DeleteIconButton
                    onClick={handleDelete}
                    confirm
                    entityName={t('projects.path.title')}
                  />
                )}
              </Space>
            </TextBox>
            <ProjectDeadlines data={data} steps={steps} />
          </>
        )}
        {showAddPathPanel && (
          <AddPathToProjectPanel
            projectId={data.id}
            communityId={data.communityId}
            onAdded={() => setAdd(false)}
          />
        )}
      </TabPanel.Body>
    </TabPanel>
  );
};
