import { FC } from 'react';
import PropTypes from 'prop-types';
import { useCommunityContext } from './CommunityContext';
import { AccessDeniedBanner } from '@/components';

export interface PermissionProps {
  permission: string;
  active?: true;
  children: (() => React.ReactElement) | React.ReactElement;
}

const _Assert: FC<PermissionProps> = (props) => {
  const { permission, children, active } = props;
  const { assert } = useCommunityContext();

  if (!assert(permission, active)) {
    return null;
  }

  if (typeof children === 'function') {
    return children();
  }

  return children;
};

_Assert.propTypes = {
  permission: PropTypes.string.isRequired,
};

function createWrap(fallbackElement: React.ReactElement | null) {
  return (permission: string, active?: true) => {
    return <T extends React.ComponentType<any>>(Component: T): T => {
      const Wrapped: React.FC = (props: any) => {
        const { assert } = useCommunityContext();
        return assert(permission, active) ? (
          <Component {...props} />
        ) : (
          fallbackElement
        );
      };
      return Wrapped as T;
    };
  };
}

export const Assert = Object.assign(_Assert, {
  Banner: createWrap(<AccessDeniedBanner />),
});
