import { StyledRow } from '@/components';
import { formatters } from '@/core';
import { Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './PersonalizationSummary.module.scss';

interface Props {
  title: string;
  baseCostSubtotal: number | undefined;
  residentPriceSubtotal: number | undefined;
}

const Title = Typography.Title;

export function PersonalizationSummarySubTotalInfo(props: Props) {
  const { title, baseCostSubtotal, residentPriceSubtotal } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'personalization.summary',
  });

  const showResidentPrice = residentPriceSubtotal !== undefined;
  const showBaseCost = baseCostSubtotal !== undefined;

  if (!showResidentPrice && !showBaseCost) {
    return null;
  }

  return (
    <StyledRow className={styles.summaryInfo}>
      <Col span={17}>
        <Title className={styles.subtotalTitle} level={4}>
          {title} {'('}
          {showBaseCost && t('baseCost')}
          {showResidentPrice && showBaseCost && '/'}
          {showResidentPrice && t('residentPrice')}
          {')'}
        </Title>
      </Col>
      <Col span={6}>
        <Title level={4} className={styles.subtotalValue}>
          {'$'}
          {showBaseCost && formatters.price(baseCostSubtotal)}
          {showBaseCost && showResidentPrice && '/'}
          {showResidentPrice && formatters.price(residentPriceSubtotal)}
        </Title>
      </Col>
    </StyledRow>
  );
}
