import { DateTimeValue, spinner, Table, TableColumnType } from '@/components';
import { useAppActivityList } from '@/core';
import { Activity } from '@webinex/activity';
import { FilterRule, NotificationFieldValue } from '@webinex/wispo';
import { Divider } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ActivityAction } from '../Activity/ActivityAction';
import { ActivityKind } from '../Activity/ActivityKind';
import { ActivityTableSearchPanel } from './Search/ActivityTableSearchPanel';
import { useSorting } from '@/components/Collections.Paging/useSorting';

const DEFAULT_PAGING: TablePaginationConfig = {
  pageSize: 20,
  current: 1,
  pageSizeOptions: ['10', '20', '50'],
};

export const DEFAULT_FILTERS: FilterRule = {
  fieldId: 'success',
  operator: '=',
  value: true,
};

export const DEFAULT_SORT = {
  fieldId: 'performedAt',
  dir: 'desc',
};

interface Props {
  className?: string;
}

function useColumns() {
  const { t } = useTranslation();

  return useMemo<TableColumnType<Activity>[]>(() => {
    return [
      {
        title: t('activity.table.key'),
        key: 'kind',
        dataIndex: 'kind',
        render: (_, { kind, id }) => (
          <NavLink to={`/activity/${id}`}>
            <ActivityKind>{kind}</ActivityKind>
          </NavLink>
        ),
      },
      {
        title: t('activity.table.actions'),
        key: 'audit-actions',
        dataIndex: 'audit-actions',
        render: (_, activity) => <ActivityAction activity={activity} />,
      },
      {
        title: t('activity.table.community'),
        key: 'community',
        dataIndex: 'communityNumber',
        render: (_, { communityNumber }) => (
          <NavLink target="_blank" to={`/item/${communityNumber}`}>
            {communityNumber}
          </NavLink>
        ),
      },
      {
        title: t('activity.table.home'),
        key: 'home',
        dataIndex: 'homeNumber',
        render: (_, { homeNumber }) => (
          <NavLink target="_blank" to={`/item/${homeNumber}`}>
            {homeNumber}
          </NavLink>
        ),
      },
      {
        title: t('activity.table.project'),
        key: 'project',
        dataIndex: 'projectNumber',
        render: (_, { projectNumber }) => (
          <NavLink target="_blank" to={`/item/${projectNumber}`}>
            {projectNumber}
          </NavLink>
        ),
      },
      {
        title: t('activity.table.performedAt'),
        key: 'performedAt',
        dataIndex: 'performedAt',
        sorter: (a, b) => a.performedAt.localeCompare(b.performedAt),
        render: (_, { performedAt }) => <DateTimeValue value={performedAt} />,
      },
      {
        title: t('activity.table.user'),
        key: 'userName',
        dataIndex: 'userName',
        render: (_, { userName }) => userName,
      },
    ];
  }, [t]);
}

function useViewState() {
  const { state, fetch } = useAppActivityList();
  const [paging, setPaging] = useState<TablePaginationConfig>(DEFAULT_PAGING);
  const [filters, setFilters] = useState<FilterRule>(DEFAULT_FILTERS);
  const [sort, setSort] = useState<any>(DEFAULT_SORT);
  const { sorting, onSortingChange } = useSorting<Activity>();

  useEffect(() => {
    if (sorting?.sortBy && sorting.sortOrder)
      setSort({
        fieldId: sorting.sortBy as NotificationFieldValue,
        dir: sorting.sortOrder === 'descend' ? 'desc' : 'asc',
      });
  }, [sorting?.sortBy, sorting?.sortOrder]);

  useEffect(() => {
    spinner.show();

    setPaging({ ...paging, current: 1 });
    fetch({
      paging: {
        skip: 0,
        take: paging.pageSize!,
      },
      filter: filters,
      includeTotal: true,
      sort: sort,
    }).finally(spinner.hide);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sort]);

  useEffect(() => {
    spinner.show();

    fetch({
      paging: {
        skip: (paging.current! - 1) * paging.pageSize!,
        take: paging.pageSize!,
      },
      filter: filters,
      includeTotal: true,
      sort: sort,
    }).finally(spinner.hide);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, sort]);

  return {
    items: Object.values(state.itemById),
    onTableChange: setPaging,
    paging: { ...paging, total: state.total },
    onFilterChange: setFilters,
    onSortingChange,
  };
}

export function ActivityTablePanel(props: Props) {
  const { className } = props;
  const { items, onTableChange, paging, onFilterChange, onSortingChange } =
    useViewState();
  const columns = useColumns();

  return (
    <div>
      <ActivityTableSearchPanel className="pb-3" onSubmit={onFilterChange} />
      <Divider className={className} />
      <Table<Activity>
        name="Activities"
        columns={columns}
        rowKey={(row) => row.id}
        dataSource={items}
        onChange={(value, _, sort) => {
          onTableChange(value);
          onSortingChange(sort);
        }}
        pagination={paging}
      />
    </div>
  );
}
