import { SpecialRequestState, useGetSpecialRequestsQuery } from '@/redux';
import { array } from '@/utils';
import { SpecialRequestListItem } from '@/views';

interface Props {
  projectId: string;
  homeId: string;
  stepId?: string;
  roomId?: string;
  categoryId?: string;
  readonly?: boolean;
}

export const CategorySpecialRequestList = (props: Props) => {
  const { projectId, homeId, stepId, roomId, categoryId, readonly } = props;

  const { data: specialRequests = array.empty<SpecialRequestState>() } =
    useGetSpecialRequestsQuery({
      projectId: projectId,
      homeId: homeId,
      stepId: stepId,
      isFloorplan: false,
      wholeHome: !roomId,
      categoryId: categoryId,
      roomId: roomId,
      asItem: true,
    });

  return (
    <>
      {specialRequests.map((item) => (
        <SpecialRequestListItem
          item={item}
          className="mb-4"
          readonly={readonly}
          specialRequestMark
        />
      ))}
    </>
  );
};