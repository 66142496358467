import {
  PdfDownloadButton,
  TabPanel,
  useTabPanelNavMenuState,
} from '@/components';
import { developScopeHttp, RoomState } from '@/core';
import {
  HomeRoomFinalizationDetailsState,
  useGetDevelopScopeRoomsListQuery,
  useGetNotFinalizedRoomIdsListQuery,
  useGetProjectQuery,
} from '@/redux';
import { useTranslation } from 'react-i18next';
import {
  SearchProjectHomeInput,
  useHomeSelectedState,
  DevelopScopeListPanel,
  DevelopScopeTabNavIcon,
  DevelopScopeHomeFinalizedAlert,
  getDevelopScopeRoomId,
} from '@/views';
import { array } from '@/utils';
import { isEmpty } from 'lodash';

interface Props {
  projectId: string;
}

export function useDevScopeTabNav(
  data: RoomState[],
  notFinalizedRoomIds: HomeRoomFinalizationDetailsState[],
  ready: boolean,
) {
  const { t } = useTranslation();

  return useTabPanelNavMenuState({
    items: ready
      ? [
          { id: 'null', name: t('developScope.floorplan'), isFloorplan: true },
          ...data,
          { id: 'null', name: t('developScope.wholeHome'), isFloorplan: false },
        ]
      : data,
    keyBy: (x) => x.id + x.isFloorplan,
    iconBy: (x) =>
      !notFinalizedRoomIds.some(
        (i) =>
          i.roomId === getDevelopScopeRoomId(x.id) &&
          i.isFloorplan === (x.isFloorplan ?? false),
      ) ? (
        <DevelopScopeTabNavIcon />
      ) : undefined,
    titleBy: (x) => x.name,
  });
}

export function DevelopScopePanel(props: Props) {
  const { projectId } = props;
  const selectedState = useHomeSelectedState();
  const { selectedId: selectedHomeId } = selectedState;

  const { data: project } = useGetProjectQuery({ id: projectId });
  const { data = array.empty<RoomState>(), isFetching: isFetchingRooms } =
    useGetDevelopScopeRoomsListQuery(
      {
        projectId,
        homeId: selectedHomeId!,
      },
      { skip: !selectedHomeId },
    );

  const {
    data: notFinalizedRoomIds = array.empty<HomeRoomFinalizationDetailsState>(),
    isFetching: isFetchingNotFinalizedRoomIds,
  } = useGetNotFinalizedRoomIdsListQuery(
    {
      projectId,
      homeId: selectedHomeId!,
    },
    { skip: !selectedHomeId },
  );

  const tabNav = useDevScopeTabNav(
    data,
    notFinalizedRoomIds,
    !isFetchingRooms && !isFetchingNotFinalizedRoomIds && !!selectedHomeId,
  );

  const { selected, select: selectByRoomId } = tabNav;
  const { communityId } = project ?? {};

  return (
    <TabPanel>
      {selectedHomeId && (
        <TabPanel.Nav>
          <div className="mt-4">
            <TabPanel.Menu {...tabNav} />
          </div>
        </TabPanel.Nav>
      )}
      <TabPanel.Body
        pre={
          communityId ? (
            <>
              <PdfDownloadButton
                action={() =>
                  developScopeHttp.downloadPdf(projectId, selectedHomeId!)
                }
                className="mb-3"
                disabled={!selectedHomeId}
              />
              <SearchProjectHomeInput
                className="w-100 mb-5"
                communityId={communityId}
                projectId={projectId}
                {...selectedState}
              />
              {selectedHomeId &&
                !isEmpty(data) &&
                isEmpty(notFinalizedRoomIds) && (
                  <DevelopScopeHomeFinalizedAlert className="mb-5" />
                )}
            </>
          ) : undefined
        }
      >
        {selected && (
          <DevelopScopeListPanel
            projectId={projectId}
            homeId={selectedHomeId!}
            roomId={selected.id}
            isWholeHome={selected.id === undefined}
            isFloorplan={!!selected.isFloorplan}
            onFloorplanItemAdd={() => selectByRoomId('null')}
          />
        )}
      </TabPanel.Body>
    </TabPanel>
  );
}
