import { Http } from '../http/http';
import { guard } from '@/utils';
import { TreeNodeDto } from '../http.communities';
import {
  CreateCommunitySubcategoryArgs,
  UpdateCommunitySubcategoryArgs,
} from '@/redux';
import qs from 'qs';

class CategoriesHttp extends Http {
  public async createCategory(treeNode: TreeNodeDto) {
    guard.notNull(treeNode, 'treeNode');

    await this._axios.post(`/api/category`, treeNode);
  }

  public async updateCategory(treeNode: TreeNodeDto) {
    guard.notNull(treeNode, 'treeNode');
    guard.notNull(treeNode.id, 'treeNode.id');

    await this._axios.put(`/api/category/${treeNode.id}`, treeNode);
  }

  public async deleteCategory(id: string) {
    guard.notNull(id, 'id');

    await this._axios.delete(`/api/category/${id}`);
  }

  public async getCategories(communityId?: string, listed?: boolean) {
    const query = qs.stringify({ communityId, listed });
    const response = await this._axios.get<TreeNodeDto[]>(
      `/api/category?${query}`,
    );

    return response.data;
  }

  public async createCommunitySubcategory(
    args: CreateCommunitySubcategoryArgs,
  ) {
    guard.notNull(args, 'args');

    await this._axios.post(`/api/category/community-subcategory`, args);
  }

  public async updateCommunitySubcategory(
    args: UpdateCommunitySubcategoryArgs,
  ) {
    guard.notNull(args, 'args');

    await this._axios.put(
      `/api/category/community-subcategory/${args.id}`,
      args,
    );
  }

  public async deleteCommunitySubcategory(id: string) {
    guard.notNull(id, 'id');

    await this._axios.delete(`/api/category/community-subcategory/${id}`);
  }
}

export const categoriesHttp = new CategoriesHttp();
