import { Button, Space } from 'antd';
import * as Yup from 'yup';
import { Form } from '@/components';
import { useTranslation } from 'react-i18next';
import { AddTaskCommentArgs, UpdateTaskCommentArgs } from '@/redux';
import { isEmpty } from 'lodash';
import { FormikHelpers } from 'formik';

export type AddTaskCommentFormValue = Omit<AddTaskCommentArgs, 'projectTaskId'>;
export type UpdateTaskCommentFormValue = UpdateTaskCommentArgs;

interface Props<
  T extends AddTaskCommentFormValue | UpdateTaskCommentFormValue,
> {
  value?: T;
  onCancel?: () => any;
  onSubmit: (value: T) => any;
}

const SCHEMA = Yup.object().shape({
  content: Yup.string().field('xl').nullable(),
});

const INITIAL_VALUES: AddTaskCommentFormValue = {
  content: undefined!,
};

export function FormTaskComment<
  T extends AddTaskCommentFormValue | UpdateTaskCommentFormValue,
>(props: Props<T>) {
  const { value, onCancel, onSubmit } = props;
  const { t } = useTranslation();

  const handleSubmit = (
    value: NonNullable<T>,
    { resetForm }: FormikHelpers<NonNullable<T>>,
  ) => {
    if (isEmpty(value.content)) {
      return;
    }

    onSubmit(value);
    resetForm();
  };

  const taskCommentId = (value as UpdateTaskCommentFormValue)?.id;

  return (
    <Form.Formik
      uid={taskCommentId || 'task-comment'}
      initialValues={value! || INITIAL_VALUES}
      onSubmit={handleSubmit}
      validationSchema={SCHEMA}
      i18n="tasks.comments"
    >
      <Form.TextArea
        name="content"
        required
        label={false}
        autoSize={false}
        rows={4}
      />
      <Space>
        {onCancel && <Button onClick={onCancel}>{t('cancel')}</Button>}
        <Form.Submit type="primary">
          {taskCommentId ? t('tasks.comments.save') : t('tasks.comments.add')}
        </Form.Submit>
      </Space>
    </Form.Formik>
  );
}
