import { EditIconButton, TextBox } from '@/components';
import { PersonalizationItemState } from '@/redux';
import { Col, Row, Space } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PersonalizationItemFormValue,
  PersonalizationItemDetailsModal,
} from './PersonalizationItemDetailsModal';

interface Props {
  value: PersonalizationItemState;
  onSubmit: (
    value: PersonalizationItemFormValue,
    item: PersonalizationItemState,
  ) => any;
}

const useSubmit = (
  item: PersonalizationItemState,
  onSubmit: (
    value: PersonalizationItemFormValue,
    item: PersonalizationItemState,
  ) => any,
  toggleModalVisible: () => any,
) => {
  return (value: PersonalizationItemFormValue) => {
    onSubmit(value!, item);
    toggleModalVisible();
  };
};

function useFormValue(value: PersonalizationItemState | undefined) {
  return useMemo<PersonalizationItemFormValue | undefined>(
    () =>
      value
        ? {
            id: value.id,
            itemId: value.itemId,
            notes: value.notes,
            suboptionId: value.suboptionId,
            attachments: [],
          }
        : undefined,
    [value],
  );
}

export function useEditPersonalizationItemAction(onSubmit: Props['onSubmit']) {
  const [item, setItem] = useState<PersonalizationItemState>();
  const formValue = useFormValue(item);
  const { t } = useTranslation();
  const submit = useSubmit(item!, onSubmit, () => setItem(undefined));

  return {
    open: setItem,
    modal: item && (
      <PersonalizationItemDetailsModal
        head={
          <Row>
            <Col span={10}>
              <TextBox label={t('personalization.item.details.step')}>
                {item.step?.name}
              </TextBox>
              <TextBox label={t('personalization.item.details.itemFullPath')}>
                <Space split="/">
                  {item.category.path.map(({ id, name }) => (
                    <span key={id}>{name}</span>
                  ))}
                  {item.name}
                </Space>
              </TextBox>
            </Col>
          </Row>
        }
        initialValue={formValue!}
        communityId={item.communityId}
        projectId={item.projectId}
        homeId={item.homeId}
        floorplanPersonalization={item.isFloorplan}
        texts={{
          saveBtn: t('personalization.item.details.editButton'),
          title: t('personalization.item.details.editTitle'),
        }}
        onClose={() => setItem(undefined)}
        onSubmit={submit}
        noItemSelection
      />
    ),
  };
}

export function EditPersonalizationItem(props: Props) {
  const { value } = props;
  const { modal, open } = useEditPersonalizationItemAction(props.onSubmit);

  return (
    <>
      <EditIconButton onClick={() => open(value)} />
      {modal}
    </>
  );
}
