import { DeleteIconButton, notify } from '@/components';
import { useTranslation } from 'react-i18next';
import { useDeleteItemMutation } from '@/redux';
import { useCallback } from 'react';

interface Props {
  id: string;
  onDeleted: () => any;
  className?: string;
}

export function ItemDeleteButton(props: Props) {
  const { id, onDeleted, className } = props;
  const { t } = useTranslation();
  const [deleteItem] = useDeleteItemMutation();

  const handleClick = useCallback(() => {
    deleteItem({ id })
      .unwrap()
      .then(() => notify.success.t('items.deleted'))
      .then(onDeleted);
  }, [id, deleteItem, onDeleted]);

  return (
    <DeleteIconButton
      className={className}
      confirm
      entityName={t('items.entityName')}
      stopPropagation
      title={t('delete')}
      onClick={handleClick}
    />
  );
}
