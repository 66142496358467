import {
  Http,
  AddSubOptionDto,
  UpdateSubOptionDto,
  SubOptionDto,
  AddSuboptionBookletLinkDto,
  DeleteSuboptionBookletLinkDto,
  DeleteSuboptionExternalLinkDto,
  AddSuboptionExternalLinkDto,
} from '@/core';
import { guard } from '@/utils';
import qs from 'qs';

class SubOptionsHttp extends Http {
  public async getAll(itemId?: string, includeUnlisted?: string[]) {
    guard.notNull(itemId, 'itemId');
    const response = await this._axios.post<SubOptionDto[]>(
      `/api/item/suboption/get-all?${qs.stringify({
        itemId,
      })}`,
      includeUnlisted || [],
    );
    return response.data;
  }

  public async getAllByIds(ids?: string[]) {
    guard.notNull(ids, 'ids');
    const response = await this._axios.post<SubOptionDto[]>(
      `/api/item/suboption/get-all-by-ids?${qs.stringify({
        ids,
      })}`,
    );
    return response.data || [];
  }

  public async get(id: string) {
    guard.notNull(id, 'id');
    const url = `/api/item/suboption/${id}`;
    const response = await this._axios.get<SubOptionDto>(url);
    return response.data;
  }

  public async add(request: AddSubOptionDto) {
    guard.notNull(request, 'request');
    const response = await this._axios.post<string>(
      `/api/item/suboption`,
      request,
    );
    return response.data;
  }

  public async update(request: UpdateSubOptionDto) {
    guard.notNull(request, 'request');
    await this._axios.put(`/api/item/suboption`, request);
  }

  public async unlist(id: string) {
    guard.notNull(id, 'id');
    await this._axios.put(`/api/item/suboption/${id}/unlist`);
  }

  public async addBookletLink(request: AddSuboptionBookletLinkDto) {
    guard.notNull(request, 'request');
    await this._axios.post(
      `/api/item/suboption/${request.suboptionId}/booklet-link`,
      request,
    );
  }

  public async deleteBookletLink(request: DeleteSuboptionBookletLinkDto) {
    guard.notNull(request, 'request');
    await this._axios.put(
      `/api/item/suboption/${request.suboptionId}/booklet-link`,
      request,
    );
  }

  public async addExternalLink(request: AddSuboptionExternalLinkDto) {
    guard.notNull(request, 'request');
    await this._axios.post(
      `/api/item/suboption/${request.suboptionId}/external-link`,
      request,
    );
  }

  public async deleteExternalLink(request: DeleteSuboptionExternalLinkDto) {
    guard.notNull(request, 'request');
    await this._axios.put(
      `/api/item/suboption/${request.suboptionId}/external-link`,
      request,
    );
  }
}

export const subOptionsHttp = new SubOptionsHttp();
