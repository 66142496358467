import {
  NavLink as RouterNavLink,
  NavLinkProps as RouterNavLinkProps,
} from 'react-router-dom';
import classNames from 'classnames';

export type NavLinkProps = RouterNavLinkProps & {
  disabled?: boolean;
  disabledClassName?: string;
};

export function NavLink(props: NavLinkProps) {
  const { disabled, disabledClassName, ...otherProps } = props;

  if (disabled) {
    return (
      <span className="disabled-anchor-container">
        <RouterNavLink
          {...otherProps}
          className={classNames(
            otherProps.className,
            'disabled',
            disabledClassName,
          )}
          to="/disabled"
        />
      </span>
    );
  }

  return <RouterNavLink {...otherProps} />;
}
