import { EnumValue } from '@/components';
import { SpecialRequestStatus } from '@/core';
import { enumValues } from '@/utils';
import { Select } from 'antd';
import { useCallback, useEffect, useState } from 'react';

interface Props {
  initialStatus: SpecialRequestStatus;
  onSubmit: (value: SpecialRequestStatus) => any;
  disabled: boolean;
}

export function PricingSpecialRequestStatusValue(props: Props) {
  const { initialStatus, onSubmit, disabled } = props;
  const [status, setStatus] = useState(initialStatus);

  useEffect(() => {
    status !== initialStatus && setStatus(initialStatus);
  }, [initialStatus, status]);

  const handleSubmit = useCallback(
    (status: SpecialRequestStatus) => {
      setStatus(status);
      onSubmit(status);
    },
    [onSubmit],
  );

  return (
    <Select<SpecialRequestStatus>
      className="w-100"
      value={status}
      labelInValue={false}
      onChange={handleSubmit}
      showSearch={false}
      disabled={disabled}
    >
      {enumValues(SpecialRequestStatus).map((value) => (
        <Select.Option key={value}>
          <EnumValue type={SpecialRequestStatus} value={value} />
        </Select.Option>
      ))}
    </Select>
  );
}
