import * as yup from 'yup';
import { ValidationError } from 'yup';
import moment from 'moment';
import { BulkUpdateTaskFieldType } from '@/core';
import i18next from 'i18next';

yup.addMethod(yup.array, 'bulkUpdateDateRange', function () {
  return this.test(
    'dateRange',
    () => ({}),
    (fields, context) => {
      const startDate = fields!.find(
        (x: any) => x.type === BulkUpdateTaskFieldType.StartDate,
      );
      const endDate = fields!.find(
        (x: any) => x.type === BulkUpdateTaskFieldType.EndDate,
      );
      if (!startDate?.startDate || !endDate?.endDate) {
        return true;
      }
      if (moment(startDate.startDate).isSameOrBefore(moment(endDate.endDate))) {
        return true;
      }
      const errors = [
        context.createError({
          message: i18next.t('errors.tasks_end_date_must_be_after_start_date'),
          path: `fields[${fields!.indexOf(endDate)}].endDate`,
        }),
        context.createError({
          message: i18next.t('errors.tasks_start_date_must_be_before_end_date'),
          path: `fields[${fields!.indexOf(startDate)}].startDate`,
        }),
      ];
      return new ValidationError(errors);
    },
  );
});
