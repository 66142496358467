import { Modal, Button, Col } from 'antd';
import { FieldRow, Form } from '@/components';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  UpdateUserArgs,
  UserInviteArgs,
  useUserContextSelector,
} from '@/redux';
import { Role } from '@/core';
import { useMemo } from 'react';
import { useClientLookupSource } from '../Clients.Common';

export interface UserDetailsModalProps<
  TValue extends UserInviteArgs | UpdateUserArgs,
> {
  onClose: () => any;
  onSubmit: (value: TValue) => any;
  value: TValue;
  title: string;
  isEdit?: boolean;
  noRoleSelect: boolean;
  saveBtnText: React.ReactNode;
  roles: Role[];
  noClientSelect?: boolean;
  formFooter?: (initialValue: TValue, value: TValue) => React.ReactNode;
  disableClientSelect?: boolean;
}

function useSchema<TValue extends UserInviteArgs | UpdateUserArgs>(
  props: UserDetailsModalProps<TValue>,
) {
  const { roles } = props;

  return useMemo(
    () =>
      Yup.object().shape({
        role: Yup.string().oneOf(roles).nullable().required(),
        firstName: Yup.string().field().nullable().required(),
        lastName: Yup.string().field().nullable().required(),
        email: Yup.string().email().nullable().required(),
        phone: Yup.string().phone().required(),
      }),
    [roles],
  );
}

export function UserDetailsModal<
  TValue extends UserInviteArgs | UpdateUserArgs,
>(props: UserDetailsModalProps<TValue>) {
  const {
    onClose,
    value: initialValue,
    onSubmit,
    title,
    saveBtnText,
    isEdit,
    formFooter,
    roles,
    noRoleSelect,
    noClientSelect,
    disableClientSelect,
  } = props;

  const { t } = useTranslation();
  const schema = useSchema(props);
  const isBFP = useUserContextSelector((x) => x.isBFP);
  const clientDataSource = useClientLookupSource();
  return (
    <Modal
      title={title}
      visible
      onCancel={onClose}
      footer={[
        <Button key="back" type="default" onClick={onClose}>
          {t('users.details.cancelButton')}
        </Button>,
        <Form.Submit uid="user-details" key="submit" type="primary">
          {saveBtnText}
        </Form.Submit>,
      ]}
    >
      <Form.Formik<TValue>
        uid="user-details"
        i18n="users.details"
        initialValues={initialValue}
        validationSchema={schema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values }) => (
          <>
            {isBFP && !noClientSelect && (
              <FieldRow>
                <Col span={12}>
                  <Form.AsyncSelect
                    name="organizationId"
                    dataSource={clientDataSource}
                    required
                    preloadInitial={values.organizationId}
                    disabled={disableClientSelect}
                  />
                </Col>
              </FieldRow>
            )}
            {!noRoleSelect && (
              <FieldRow>
                <Col span={12}>
                  <Form.EnumSelect
                    name="role"
                    type={Role}
                    values={roles}
                    required
                  />
                </Col>
              </FieldRow>
            )}
            <FieldRow>
              <Col span={12}>
                <Form.Input
                  name="firstName"
                  required
                  placeholder={t('users.details.firstName')}
                />
              </Col>
              <Col span={12}>
                <Form.Input
                  name="lastName"
                  required
                  placeholder={t('users.details.lastName')}
                />
              </Col>
            </FieldRow>
            <FieldRow>
              <Col span={12}>
                <Form.Input name="email" disabled={isEdit} required />
              </Col>
              <Col span={12}>
                <Form.Phone name="phone" required />
              </Col>
            </FieldRow>

            {formFooter && formFooter(initialValue, values)}
          </>
        )}
      </Form.Formik>
    </Modal>
  );
}
