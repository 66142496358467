import { dataNodeUtils } from '@/components';
import { RoomCategory } from '@/core';
import { CategoryState, useGetCategoriesQuery } from '@/redux';
import { array } from '@/utils';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';

function removeEmptyNodes(
  nodes: CategoryState[],
  selectedIds: string[],
  initialSelectedIds: string[],
  noNotSelected: boolean,
  parentSelected: boolean,
): CategoryState[] {
  let ar: CategoryState[] = [];

  for (const node of nodes) {
    const explicitlySelected = selectedIds.includes(node.id);
    const initialSelected = initialSelectedIds.includes(node.id);
    const selected = parentSelected || explicitlySelected;

    const newChildren = !isEmpty(node.children)
      ? removeEmptyNodes(
          node.children!,
          selectedIds,
          initialSelectedIds,
          noNotSelected,
          selected,
        )
      : [];

    const childSelected = !isEmpty(newChildren);
    const show =
      childSelected ||
      explicitlySelected ||
      (noNotSelected
        ? parentSelected && (node.listed || initialSelected)
        : node.listed);

    if (show) {
      ar.push({
        ...node,
        children: newChildren,
      });
    }
  }

  return ar;
}

export function useRoomCategoryTreeData(
  communityId: string,
  selected: RoomCategory[],
  noNotSelected: boolean,
  initialSelected?: RoomCategory[],
) {
  const { data: categories = array.empty<CategoryState>() } =
    useGetCategoriesQuery({ communityId });
  const selectedIds = useMemo(() => selected.map((x) => x.id), [selected]);
  const initialSelectedIds = useMemo(
    () => initialSelected?.map((x) => x.id) ?? [],
    [initialSelected],
  );

  return useMemo(() => {
    const nodes = removeEmptyNodes(
      categories,
      selectedIds,
      initialSelectedIds,
      noNotSelected,
      false,
    );

    return dataNodeUtils.convertFromManyCustom(
      nodes,
      'id',
      'name',
      'children',
      'custom',
      'name',
    );
  }, [categories, noNotSelected, selectedIds, initialSelectedIds]);
}
