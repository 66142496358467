import { SortResult } from '@/components/Collections.Paging/useSorting';
import {
  AddTaskCommentDto,
  AddTaskDto,
  Http,
  TaskCommentDto,
  TaskDetailsDto,
  TaskDto,
  UpdateTaskCommentDto,
  TaskFinancialInfoDto,
  UpdateTaskDto,
  TaskStatus,
  DuplicateTaskDto,
  BulkUpdateTaskDto,
  TaskResponsiblePersonValue,
  Paging,
  Paged,
  TaskFilterParams,
  BulkDeleteTaskDto,
} from '@/core';
import { guard } from '@/utils';
import qs from 'qs';

class TasksHttp extends Http {
  public async getAll(args?: {
    projectId?: string;
    communityId?: string;
    paging: Paging;
    filters?: TaskFilterParams;
    sorting?: SortResult;
  }) {
    const query = qs.stringify({
      ...args,
      ...args?.paging,
      ...args?.filters,
      ...args?.sorting,
    });
    const response = await this._axios.get<Paged<TaskDto>>(
      `/api/project/task?${query}`,
    );
    return response.data;
  }

  public async create(request: AddTaskDto) {
    guard.notNull(request, 'request');
    const response = await this._axios.post<string>(
      `/api/project/task`,
      request,
    );
    return response.data;
  }

  public async createRange(request: AddTaskDto[]) {
    guard.notNull(request, 'request');
    await this._axios.post(`/api/project/task/add-range`, request);
  }

  public async get(id: string) {
    guard.notNull(id, 'id');
    const response = await this._axios.get<TaskDetailsDto>(
      `/api/project/task/${id}`,
    );
    return response.data;
  }

  public async getResponsiblePersonsAll(communityId: string) {
    guard.notNull(communityId, 'communityId');
    const response = await this._axios.get<TaskResponsiblePersonValue[]>(
      `/api/project/task/responsible-persons?communityId=${communityId}`,
    );
    return response.data;
  }

  public async update(request: UpdateTaskDto) {
    guard.notNull(request, 'request');
    await this._axios.put(`/api/project/task`, request);
  }

  public async delete(id: string) {
    guard.notNull(id, 'id');
    await this._axios.delete(`/api/project/task/${id}`);
  }

  public async duplicate(request: DuplicateTaskDto) {
    guard.notNull(request, 'request');
    await this._axios.post(`/api/project/task/duplicate`, request);
  }

  public async bulkDelete(request: BulkDeleteTaskDto) {
    guard.notNull(request, 'request');
    const query = qs.stringify(request);
    await this._axios.delete(`/api/project/task/bulk-delete?${query}`);
  }

  public async bulkUpdate(request: BulkUpdateTaskDto) {
    guard.notNull(request, 'request');
    await this._axios.put(`/api/project/task/bulk-update`, request);
  }

  public async getAllComments(taskId: string) {
    const url = `/api/project-task-comment?projectTaskId=${taskId}`;
    const response = await this._axios.get<TaskCommentDto[]>(url);
    return response.data;
  }

  public async addComment(request: AddTaskCommentDto) {
    guard.notNull(request, 'request');
    const response = await this._axios.post<string>(
      `/api/project-task-comment`,
      request,
    );
    return response.data;
  }

  public async updateComment(request: UpdateTaskCommentDto) {
    guard.notNull(request, 'request');
    await this._axios.put(`/api/project-task-comment`, request);
  }

  public async deleteComment(id: string) {
    guard.notNull(id, 'id');
    await this._axios.delete(`/api/project-task-comment/${id}`);
  }

  public async updateActualCost(id: string, value: number | null | undefined) {
    guard.notNull(id, 'id');
    await this._axios.put(`/api/project/task/${id}/actual-cost`, value, {
      headers: {
        'content-type': 'application/json',
      },
    });
  }

  public async getAllFinancialInfo(
    projectId: string,
    paging: Paging,
    filters?: TaskFilterParams,
    sorting?: SortResult,
  ) {
    guard.notNull(projectId, 'projectId');
    const response = await this._axios.get<Paged<TaskFinancialInfoDto>>(
      `/api/project/task/financial-info?${qs.stringify({
        projectId,
        ...paging,
        ...filters,
        ...sorting,
      })}`,
    );
    return response.data;
  }

  public async updateTaskStatus(id: string, status: TaskStatus) {
    guard.notNull(id, 'request');
    await this._axios.put(`/api/project/task/${id}/status`, status, {
      headers: {
        'content-type': 'application/json',
      },
    });
  }
}

export const tasksHttp = new TasksHttp();
