import {
  categoriesHttp,
  CategoryDto,
  CategoryLookupDto,
  CreateCommunitySubcategoryDto,
  UpdateCommunitySubcategoryDto,
} from '@/core';
import { tree } from '@/utils';
import { api } from '../api';
import { rtkq, RtkqRequest } from '../rtkq';

export type CategoryState = CategoryDto;
export type CreateCommunitySubcategoryArgs = CreateCommunitySubcategoryDto;
export type UpdateCommunitySubcategoryArgs = UpdateCommunitySubcategoryDto;
export type CategoryLookupState = CategoryLookupDto;

const categoriesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCategories: build.query<
      CategoryState[],
      RtkqRequest<{ communityId?: string; listed?: boolean }> | void | undefined
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() =>
          categoriesHttp.getCategories(args?.communityId, args?.listed),
        );
      },
      providesTags: (result = [], __) => [
        { type: 'category', id: 'list' },
        ...tree
          .flatten(result)
          .map(({ id }) => ({ type: 'category' as const, id })),
      ],
    }),

    updateCategory: build.mutation<
      void,
      RtkqRequest<{ category: CategoryState }>
    >({
      queryFn: (args) => {
        const { category } = args;
        return rtkq(args).exec(() => categoriesHttp.updateCategory(category));
      },
      invalidatesTags: (_, __, { category: { id } }) => {
        return [{ type: 'category', id }];
      },
    }),

    createCategory: build.mutation<
      void,
      RtkqRequest<{ category: CategoryState }>
    >({
      queryFn: (args) => {
        const { category } = args;
        return rtkq(args).exec(() => categoriesHttp.createCategory(category));
      },
      invalidatesTags: () => [{ type: 'category', id: 'list' }],
    }),

    createCommunitySubcategory: build.mutation<
      void,
      RtkqRequest<CreateCommunitySubcategoryArgs>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() =>
          categoriesHttp.createCommunitySubcategory(args),
        );
      },
      invalidatesTags: () => [{ type: 'category', id: 'list' }],
    }),

    updateCommunitySubcategory: build.mutation<
      void,
      RtkqRequest<UpdateCommunitySubcategoryArgs>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() =>
          categoriesHttp.updateCommunitySubcategory(args),
        );
      },
      invalidatesTags: (_, __, args) => [{ type: 'category', id: args.id }],
    }),

    deleteCommunitySubcategory: build.mutation<
      void,
      RtkqRequest<{ id: string }>
    >({
      queryFn: (args) => {
        const { id } = args;
        return rtkq(args).exec(() =>
          categoriesHttp.deleteCommunitySubcategory(id),
        );
      },
      invalidatesTags: (_, __, args) => [
        { type: 'category', id: 'list' },
        { type: 'category', id: args.id },
      ],
    }),

    deleteCategory: build.mutation<void, RtkqRequest<{ id: string }>>({
      queryFn: (args) => {
        const { id } = args;
        return rtkq(args).exec(() => categoriesHttp.deleteCategory(id));
      },
      invalidatesTags: (_, __, { id }) => {
        return [
          { type: 'category', id: 'list' },
          { type: 'category', id },
        ];
      },
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useUpdateCategoryMutation,
  useCreateCategoryMutation,
  useDeleteCategoryMutation,
  useLazyGetCategoriesQuery,
  useCreateCommunitySubcategoryMutation,
  useUpdateCommunitySubcategoryMutation,
  useDeleteCommunitySubcategoryMutation,
} = categoriesApi;
