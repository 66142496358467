import { useTranslation } from 'react-i18next';
import { UserDetailsPanel } from '@/views';
import { Page } from '@/components';
import { useGetUserQuery } from '@/redux';
import { useParams } from 'react-router-dom';
import { formatters } from '@/core';

const useId = () => {
  const { userId } = useParams<'userId'>();
  return userId!;
};

export function UserDetailsPage() {
  const { t } = useTranslation();
  const id = useId();
  const { data: user } = useGetUserQuery({ id });
  if (!user) return null;

  return (
    <Page htmlTitle={formatters.name(user)}>
      <Page.Paths>
        <Page.Path>
          {t('users.title')} / {formatters.name(user)}
        </Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card className="p-4">
          <UserDetailsPanel id={id} />
        </Page.Card>
      </Page.Body>
    </Page>
  );
}
