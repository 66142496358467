import { useState } from 'react';
import { UserDetailsModal, UserDetailsModalProps } from '@/views';
import {
  UpdateUserArgs,
  useGetUserQuery,
  useUpdateUserMutation,
} from '@/redux';
import { useTranslation } from 'react-i18next';
import { notify } from '@/components';

const useSubmit = (closeModal: () => any) => {
  const [updateUser] = useUpdateUserMutation();

  return async (values: UpdateUserArgs) => {
    await updateUser(values)
      .unwrap()
      .then(closeModal)
      .then(() => notify.success.t('users.saved'));
  };
};

export type UseEditUserActionBaseArgs = Pick<
  UserDetailsModalProps<UpdateUserArgs>,
  'roles' | 'formFooter' | 'noRoleSelect' | 'noClientSelect'
>;

export function useEditUserActionBase(args: UseEditUserActionBaseArgs) {
  const { t } = useTranslation();
  const [id, setId] = useState<string>();

  const { currentData: user } = useGetUserQuery({ id: id! }, { skip: !id });
  const submit = useSubmit(() => setId(undefined));

  return {
    openEdit: (id: string) => setId(id),
    editModal: (
      <>
        {user && (
          <UserDetailsModal<UpdateUserArgs>
            title={t('users.details.editTitle')}
            saveBtnText={t('users.details.saveBtnText')}
            onSubmit={submit}
            value={user}
            isEdit
            onClose={() => setId(undefined)}
            {...args}
          />
        )}
      </>
    ),
  };
}
