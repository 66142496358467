import { LocationState, useGetLocationsQuery } from '@/redux';
import { useEffect, useMemo } from 'react';
import { array, nameOf, useFirstRender } from '@/utils';
import { Form } from '@/components';
import { useField, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

function mapOptions(locations: LocationState[]) {
  return locations.map((d) => ({
    value: d.id,
    label: d.name,
  }));
}

interface FormState {
  level1Id?: string;
  level2Id?: string;
  level3Id?: string;
}

interface SelectLocationProps {
  communityId: string;
  name: string;
  includeUnlistedIds?: string[];
}

export const FormSelectLocation = (props: SelectLocationProps) => {
  const { communityId, name, includeUnlistedIds } = props;
  const { data = array.empty<LocationState>() } = useGetLocationsQuery({
    communityId,
    includeUnlistedIds,
  });

  const [field] = useField<FormState>(name);
  const { level1Id, level2Id } = field.value ?? {};
  const { setFieldValue } = useFormikContext<FormState>();
  const firstRender = useFirstRender();

  function find(
    locations: LocationState[] | undefined,
    id: string | undefined,
  ): LocationState | undefined {
    return locations?.find((x) => x.id === id);
  }

  const level1Options = useMemo(() => mapOptions(data), [data]);
  const level2Options = useMemo(
    () => mapOptions(find(data, level1Id)?.children ?? []),
    [data, level1Id],
  );
  const level3Options = useMemo(
    () =>
      mapOptions(
        find(find(data, level1Id)?.children, level2Id)?.children ?? [],
      ),
    [data, level1Id, level2Id],
  );

  useEffect(() => {
    if (!firstRender) {
      setFieldValue(`${name}.${nameOf<FormState>('level2Id')}`, null);
      setFieldValue(`${name}.${nameOf<FormState>('level3Id')}`, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level1Id, name]);

  useEffect(() => {
    if (!firstRender) {
      setFieldValue(`${name}.${nameOf<FormState>('level3Id')}`, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level2Id, name]);

  return (
    <>
      <Form.Select name={`${name}.level1Id`} options={level1Options} />
      {!isEmpty(level2Options) && (
        <Form.Select name={`${name}.level2Id`} options={level2Options} />
      )}
      {!isEmpty(level3Options) && !isEmpty(level2Options) && (
        <Form.Select name={`${name}.level3Id`} options={level3Options} />
      )}
    </>
  );
};
