import { notify, TableColumnType } from '@/components';
import {
  Alignment,
  Cell,
  Column,
  TableProperties,
  Workbook,
  Worksheet,
} from 'exceljs';
import Excel from 'exceljs';
import { meanBy } from 'lodash';
import { ReactElement, ReactNode } from 'react';
import { browserDownload } from '@/utils';
import { BrowserRouter } from 'react-router-dom';
import i18next from 'i18next';

export async function exportToExcel<T>(
  columnDefinitions: TableColumnType<T>[],
  data?: readonly T[],
) {
  const workbook = new Workbook();
  const sheet = workbook.addWorksheet('Table');

  const { renderToStaticMarkup } = await import('react-dom/server');

  const table = buildTable(columnDefinitions, data || [], renderToStaticMarkup);
  sheet.addTable(table);

  normalizeColumns(sheet, columnDefinitions);
  generateAndExport(workbook);
}

function buildTable<T>(
  columnDefinitions: TableColumnType<T>[],
  data: readonly T[],
  renderToStaticMarkup: (element: ReactElement) => string,
): TableProperties {
  return {
    name: 'Table',
    ref: 'A1',
    headerRow: true,
    style: {
      showRowStripes: true,
      theme: 'TableStyleMedium2',
    },
    columns: getColumns(columnDefinitions),
    rows: getRows(columnDefinitions, data, renderToStaticMarkup),
  };
}

function getColumns<T>(columnDefinitions: TableColumnType<T>[]) {
  return columnDefinitions.map((x) => ({
    name: (x.title || '').toString(),
    filterButton: true,
    key: x.key,
  }));
}

function getRows<T>(
  columnDefinitions: TableColumnType<T>[],
  data: readonly T[],
  renderToStaticMarkup: (element: ReactElement) => string,
) {
  return data.map((item, index) =>
    createDataRow(columnDefinitions, item, index, renderToStaticMarkup),
  );
}

function createDataRow<T>(
  columnDefinitions: TableColumnType<T>[],
  item: T,
  itemIndex: number,
  renderToStaticMarkup: (element: ReactElement) => string,
) {
  return columnDefinitions.map((x) =>
    getStringContent(x, item, itemIndex, renderToStaticMarkup),
  );
}

function normalizeColumns<T>(
  sheet: Worksheet,
  columnDefinitions: TableColumnType<T>[],
) {
  sheet.columns.forEach((column, index) =>
    normalizeColumn(columnDefinitions, column, index),
  );
}

function normalizeColumn<T>(
  columnsDefinitions: TableColumnType<T>[],
  excelColumn: Partial<Column>,
  index: number,
) {
  const definition = columnsDefinitions[index];
  (excelColumn || []).eachCell!((cell, index) =>
    normalizeColumnCell(cell, index, definition),
  );

  const averageCellContentLength = meanBy(
    (excelColumn.values || []).slice(1).filter((v) => !!v),
    (x) => (x || '').toString().length,
  );
  excelColumn.width = calculateColumnWidth(averageCellContentLength);
}

function normalizeColumnCell<T>(
  cell: Cell,
  index: number,
  definition: TableColumnType<T>,
) {
  const alignment: Partial<Alignment> = { wrapText: true };

  const headerCellIndex = 1;
  if (index !== headerCellIndex) {
    alignment.horizontal = definition.align;
  }

  cell.alignment = alignment;
}

function calculateColumnWidth(averageCellLength: number) {
  const minimalColumnWidth = 7;
  const multiplier = 1.5;
  return minimalColumnWidth + averageCellLength * multiplier;
}

function getStringContent<T>(
  columnDefinition: TableColumnType<T>,
  item: T,
  itemIndex: number,
  renderToStaticMarkup: (element: ReactElement) => string,
) {
  if (columnDefinition.exportValue) {
    return columnDefinition.exportValue(item);
  }

  if (columnDefinition.render) {
    const element = (
      <BrowserRouter>
        {columnDefinition.render(item, item, itemIndex) as ReactNode}
      </BrowserRouter>
    );
    const markup = renderToStaticMarkup(element);
    return extractTextContent(markup);
  }

  if (columnDefinition.dataIndex) {
    return (item as any)[columnDefinition.dataIndex.toString()];
  }
}

const generateAndExport = (workbook: Workbook) => {
  workbook.xlsx
    .writeBuffer()
    .then((result: Excel.Buffer) => download(result))
    .catch(function (error) {
      console.log(error.message);
      notify.error(i18next.t('errors.exportExcelError'));
    });
};

const download = (xls64: Excel.Buffer) => {
  var data = new Blob([xls64], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  browserDownload('Export.xlsx', data);
};

function extractTextContent(html: string) {
  const document = new DOMParser().parseFromString(html, 'text/html');
  return document.documentElement.textContent;
}
