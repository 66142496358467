import classNames from 'classnames';
import { useDrag } from 'react-dnd';
import styles from './ImageMarkerBox.module.scss';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useCallback, useEffect } from 'react';
import { ImageMarkerValue } from './ImageMarkerBox';
import { DeleteIconButton, EditIconButton } from '../Styled';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';

export interface ImageMarkerProps<T extends ImageMarkerValue> {
  index: number;
  value: T;
  readonly?: boolean;
  onEdit?: (value: T) => any;
  onDelete?: (value: T) => any;
}

export interface ImageMarkerDropValue {
  index: number;
}

export function ImageMarker<T extends ImageMarkerValue>(
  props: ImageMarkerProps<T>,
) {
  const { index, value, readonly, onDelete, onEdit } = props;
  const { label, x, y, number } = value;
  const { t } = useTranslation();
  const dropValue: ImageMarkerDropValue = { index };

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: 'marker',
      item: dropValue,
      canDrag: !readonly,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [readonly],
  );

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = useCallback(
    () => onDelete && onDelete(value),
    [value, onDelete],
  );

  const handleEdit = useCallback(
    () => onEdit && onEdit(value),
    [value, onEdit],
  );

  const noActions = readonly || (!onDelete && !onEdit);

  const className = classNames(
    styles.marker,
    isDragging && '--dragging',
    !readonly && '--draggable',
  );

  return (
    <span
      ref={drag}
      title={label}
      className={className}
      style={{ top: `${y}%`, left: `${x}%` }}
    >
      {number}
      {!noActions && (
        <span className={styles.markerActionsWrap}>
          <span className={styles.markerActions}>
            <Space>
              {onEdit && <EditIconButton onClick={handleEdit} />}
              {onDelete && (
                <DeleteIconButton
                  onClick={handleDelete}
                  confirm
                  entityName={t('imageMarkerBox.entityName')}
                />
              )}
            </Space>
          </span>
        </span>
      )}
    </span>
  );
}
