import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddTaskArgs, useAddTaskMutation } from '@/redux';
import { useNavigate } from 'react-router-dom';
import { TaskDetailsModal } from '.';
import { AddButton, notify } from '@/components';
import { useProjectContext } from '..';

interface AddTasksButtonProps {
  className?: string;
  projectId: string;
}

function useValue(props: AddTasksButtonProps): AddTaskArgs {
  const { projectId } = props;

  return useMemo(
    () => ({
      projectId: projectId!,
      name: null!,
      homeId: null!,
      startDate: null!,
      endDate: null!,
      responsiblePersonLabel: null!,
      responsiblePerson: null!,
      estimatedCost: null!,
      description: null!,
      status: null!,
    }),
    [projectId],
  );
}

const useSubmit = () => {
  const [add] = useAddTaskMutation();

  const push = useNavigate();
  return (args: AddTaskArgs) =>
    add(args)
      .unwrap()
      .then((id) => {
        notify.success.t('tasks.added');
        push(`/tasks/${id}`);
      });
};

export function AddTaskButton(props: AddTasksButtonProps) {
  const submit = useSubmit();
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const { className } = props;

  const { isActive } = useProjectContext();

  const initialValue: AddTaskArgs = useValue(props);

  if (!isActive) return null;

  return (
    <div className={className}>
      <AddButton onClick={() => setOpenModal(true)}>
        {t('tasks.details.addButton')}
      </AddButton>
      {openModal && (
        <TaskDetailsModal
          title={t('tasks.details.addTitle')}
          buttonSaveName={t('tasks.details.add')}
          onSubmit={submit}
          value={initialValue}
          onClose={() => setOpenModal(false)}
        />
      )}
    </div>
  );
}
