import { FC, useState } from 'react';
import { AsyncSelectBase, AsyncSelectBaseProps } from './AsyncSelectBase';

export type AsyncSelectProps = Omit<
  AsyncSelectBaseProps,
  'searchValue' | 'onSearchChange'
>;

export const AsyncSelect: FC<AsyncSelectProps> = (props) => {
  const [searchValue, onSearchChange] = useState<string>('');
  return (
    <AsyncSelectBase
      {...props}
      searchValue={searchValue}
      onSearchChange={onSearchChange}
    />
  );
};
