import React from 'react';
import { Form } from 'antd';
import classNames from 'classnames';
import { useFormErrorMessage } from './FormsErrorMessage';

export interface FormsGroupProps {
  name: string;
  label?: React.ReactNode;
  required?: boolean;
  children: React.ReactNode;
  inline?: boolean;
  noValidation?: boolean;
  helpMessage?: string;
}

export const FormsGroup = ({
  children,
  name,
  label,
  required,
  inline,
  noValidation,
  helpMessage,
}: FormsGroupProps) => {
  const error = useFormErrorMessage({ name, label });

  return (
    <Form.Item
      label={label}
      required={required}
      colon={false}
      validateStatus={error.show ? 'error' : ''}
      help={error.show ? error.error : helpMessage}
      className={classNames(
        { 'form-item-vertical': !inline },
        'form-group',
        noValidation && 'mb-0',
      )}
    >
      {children}
    </Form.Item>
  );
};
