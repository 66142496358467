import { Icon, Page } from '@/components';
import { useParams } from 'react-router-dom';
import { ProjectHomeListPanel, Assert, useProjectContext } from '@/views';
import { useTranslation } from 'react-i18next';
import { EditProjectHomesButton } from '@/views/Projects.EditHomes';
import { CommunityPermissions } from '@/core';

export function ProjectHomesPage() {
  const { id } = useParams<'id'>();
  const { t } = useTranslation();
  const { isActive } = useProjectContext();

  return (
    <Page.Card
      title={t('homes.title')}
      color="#107CC7"
      icon={<Icon type="home" />}
      actions={
        isActive && (
          <Assert permission={CommunityPermissions.Projects.Manage} active>
            <EditProjectHomesButton projectId={id!} />
          </Assert>
        )
      }
    >
      <ProjectHomeListPanel projectId={id!} />
    </Page.Card>
  );
}
