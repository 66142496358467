import { Modal, Button, Col } from 'antd';
import { Form, LookupOption } from '@/components';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { CustomLegalLanguageFilterPanel } from './CustomLegalLanguageFilterPanel';
import {
  AddCustomLegalLanguageState,
  CustomLegalLanguageState,
  UpdateLegalLanguageArgs,
} from '@/redux';

const schema = Yup.object().shape({
  clientId: Yup.string().field().nullable().required(),
  communityId: Yup.string().field().nullable().required(),
  legalLanguageText: Yup.string().nullable(),
});

interface Props {
  onClose?: () => any;
  onSubmit: (props?: any) => any;
  value?: CustomLegalLanguageState;
  initialClient?: LookupOption;
  initialCommunity?: LookupOption;
}

function useInitialValue(
  defaultLegalLanguage: string,
  value?: CustomLegalLanguageState,
) {
  return useMemo<UpdateLegalLanguageArgs | AddCustomLegalLanguageState>(
    () => ({
      id: value?.id,
      clientId: value?.clientId!,
      communityId: value?.communityId!,
      legalLanguageText: value?.legalLanguageText || defaultLegalLanguage,
    }),
    [
      defaultLegalLanguage,
      value?.clientId,
      value?.communityId,
      value?.id,
      value?.legalLanguageText,
    ],
  );
}

export function CustomLegalLanguageDetailsModal(props: Props) {
  const {
    onClose: onCancel,
    value,
    onSubmit,
    initialClient,
    initialCommunity,
  } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'customLegalLanguage.details',
  });
  const defaultLegalLanguage = t('default');
  const initialValue = useInitialValue(defaultLegalLanguage, value);

  return (
    <Modal
      title={t(value ? 'editTitle' : 'addTitle')}
      visible
      onCancel={onCancel}
      footer={[
        <Button key="back" type="default" onClick={onCancel}>
          {t('cancel')}
        </Button>,
        <Form.Submit
          uid="customLegalLanguage-details"
          key="submit"
          type="primary"
        >
          {t(value ? 'save' : 'add')}
        </Form.Submit>,
      ]}
    >
      <Form.Formik<AddCustomLegalLanguageState | UpdateLegalLanguageArgs>
        uid="customLegalLanguage-details"
        i18n="customLegalLanguage.details"
        initialValues={initialValue}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        <CustomLegalLanguageFilterPanel
          isEdit={!!value}
          initialClient={initialClient}
          initialCommunity={initialCommunity}
        />
        <Col span={24}>
          <Form.TextArea
            name="legalLanguageText"
            placeholder={t('placeholder.legalLanguage')}
          />
        </Col>
      </Form.Formik>
    </Modal>
  );
}
