import { Form, StyledRow } from '@/components';
import { ItemMenuItemType } from '@/core';
import { ItemMenuItemDetailsState } from '@/redux';
import { Alert, Button, Col, Space, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useItemNavSelectedState } from '../Items.List';
import { FormItemMenuItems } from './FormItemMenuItems';
import {
  FormItemMenuItemSuboptions,
  MenuItemFormValue,
  MenuItemSuboptionsFormValue,
} from './FormItemMenuItemSuboptions';

const schema = Yup.object().shape({
  name: Yup.string().field().nullable().required(),
  items: Yup.array().of(
    Yup.object().shape({
      itemId: Yup.string().nullable().required(),
      type: Yup.string().nullable().required(),
      standardCredit: Yup.bool().nullable(),
      suboptions: Yup.object().shape({
        all: Yup.bool().required(),
        pristine: Yup.bool(),
        noSuboptions: Yup.bool().nullable(),
        values: Yup.array()
          .of(
            Yup.object().shape({
              id: Yup.string().nullable().required(),
              type: Yup.string().nullable().required(),
            }),
          )
          .when(['all', 'pristine', 'noSuboptions'], {
            is: (
              all: boolean,
              pristine: boolean,
              noSuboptions: boolean | null,
            ) => !all && !pristine && noSuboptions === false,
            then: (x) => x.required().min(1),
          }),
      }),
    }),
  ),
});

export interface ItemMenuDetailsFormValue {
  name: string;
  items: MenuItemFormValue[];
}

export interface ItemMenuDetailsSubmitValue {
  name: string;
  items: Array<{
    itemId: string;
    type: ItemMenuItemType;
    suboptions: MenuItemSuboptionsFormValue;
    standardCredit: boolean;
  }>;
}

interface Props {
  value: ItemMenuDetailsFormValue;
  onSubmit: (value: ItemMenuDetailsSubmitValue) => any;
  onCancel: () => any;
  communityId: string;
  initialItems?: ItemMenuItemDetailsState[];
  texts: {
    title: React.ReactNode;
    saveBtn: React.ReactNode;
  };
}

function Suboptions({ selectedId }: { selectedId: string | undefined }) {
  const {
    values: { items },
  } = useFormikContext<ItemMenuDetailsFormValue>();
  const itemIds = items.map((x) => x.itemId);

  if (!selectedId || !itemIds.includes(selectedId)) {
    return null;
  }

  const itemIndex = items.findIndex((x) => x.itemId === selectedId);
  const item = items[itemIndex];
  const standardCreditDisabled = items.some(
    (x) =>
      (x.itemId !== selectedId &&
        x.categoryId === item.categoryId &&
        !!x.standardCredit) ||
      item.type !== ItemMenuItemType.Standard,
  );

  return (
    <FormItemMenuItemSuboptions
      itemName={`items[${itemIndex}]`}
      itemId={selectedId}
      key={selectedId}
      standardCreditDisabled={standardCreditDisabled}
    />
  );
}

export function ItemMenuDetailsForm(props: Props) {
  const { onSubmit, onCancel, value, texts, communityId, initialItems } = props;
  const { t } = useTranslation();
  const select = useItemNavSelectedState();

  const submit = useCallback(
    ({ name, items }: ItemMenuDetailsFormValue) => {
      return onSubmit({
        name,
        items: items.map(({ itemId, type, suboptions, standardCredit }) => ({
          itemId,
          type,
          suboptions,
          standardCredit,
        })),
      });
    },
    [onSubmit],
  );

  return (
    <div>
      <Typography.Title className="mb-3" level={5}>
        {texts.title}
      </Typography.Title>
      <Form.Formik<ItemMenuDetailsFormValue>
        uid="itemMenu-details"
        i18n="itemMenus.details"
        initialValues={{ ...value }}
        validationSchema={schema}
        onSubmit={submit}
      >
        <Alert
          type="info"
          className="mb-3"
          description={t('itemMenus.details.editTip')}
        />
        <StyledRow gutter={[40, 0]}>
          <Col span={10}>
            <Form.Input required name="name" />

            <FormItemMenuItems
              name="items"
              communityId={communityId}
              className="mb-4"
              initialItems={initialItems}
              select={select}
            />
          </Col>
          <Col span={14}>
            <Suboptions selectedId={select.selectedId} />
          </Col>
        </StyledRow>
        <StyledRow justify="end" className="mt-3">
          <Space size="middle">
            <Button onClick={onCancel}>
              {t('itemMenus.details.cancelButton')}
            </Button>
            <Form.Submit type="primary">{texts.saveBtn}</Form.Submit>
          </Space>
        </StyledRow>
      </Form.Formik>
    </div>
  );
}
