import { useUpdateUserAvatarMutation } from '@/redux';
import { UserAvatarEdit } from './UserAvatarEdit';
import { useCallback } from 'react';
import { formatters, UserDto } from '@/core';
import { notify } from '@/components';

function useHandleAvatarChange(props: UserAvatarEditProps) {
  const { user } = props;
  const [update] = useUpdateUserAvatarMutation();

  return useCallback(
    async (avatar: string) =>
      await update({ avatar, id: user.id })
        .unwrap()
        .then(() => notify.success.t('users.profile.saved')),
    [update, user.id],
  );
}

interface UserAvatarEditProps {
  user: UserDto;
}

export function UserAvatarEditComponent(props: UserAvatarEditProps) {
  const { user } = props;
  const { avatar } = user;
  const handleChange = useHandleAvatarChange(props);

  return (
    <UserAvatarEdit
      onChange={handleChange}
      reference={avatar}
      fullName={formatters.name(user)}
      size={80}
    />
  );
}
