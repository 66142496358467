import { useMemo, useState } from 'react';
import { mapClientLookup } from '@/views';
import { LookupOption, confirmation, notify } from '@/components';
import { CustomLegalLanguageDetailsModal } from './CustomLegalLanguageDetailsModal';
import {
  CustomLegalLanguageState,
  UpdateLegalLanguageArgs,
  useUpdateCustomLegalLanguageMutation,
} from '@/redux/customLegalLanguage/customLegalLanguageApi';
import { useTranslation } from 'react-i18next';
import { Paging } from '@/core';

const useSubmit = (closeModal: () => any, paging: Paging) => {
  const [updateLegalLanguage] = useUpdateCustomLegalLanguageMutation();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'customLegalLanguage.confirmation.edit',
  });

  return async (values: UpdateLegalLanguageArgs) => {
    confirmation.show({
      body: t('body'),
      onConfirm: async () =>
        await updateLegalLanguage({ ...values, paging })
          .unwrap()
          .then(() => {
            notify.success.t('customLegalLanguage.details.saved');
            closeModal();
          }),
      onCancel: closeModal(),
    });
  };
};

export function useEditLegalLanguageAction(paging: Paging) {
  const [legalLanguage, setLegalLanguage] =
    useState<CustomLegalLanguageState>();

  const initialClientValue: LookupOption | undefined = useMemo(
    () =>
      legalLanguage
        ? mapClientLookup({
            name: legalLanguage.clientName,
            id: legalLanguage.clientId,
          })
        : undefined,
    [legalLanguage],
  );

  const initialCommunityValue: LookupOption | undefined = useMemo(
    () =>
      legalLanguage
        ? mapClientLookup({
            name: legalLanguage.communityName,
            id: legalLanguage.communityId,
          })
        : undefined,
    [legalLanguage],
  );

  const submit = useSubmit(() => setLegalLanguage(undefined), paging);

  return {
    openEdit: (legalLanguage: CustomLegalLanguageState) =>
      setLegalLanguage(legalLanguage),
    editModal: (
      <>
        {legalLanguage && (
          <CustomLegalLanguageDetailsModal
            initialClient={initialClientValue}
            initialCommunity={initialCommunityValue}
            onSubmit={submit}
            value={legalLanguage}
            onClose={() => setLegalLanguage(undefined)}
          />
        )}
      </>
    ),
  };
}
