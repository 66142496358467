import { FC, useCallback, useState } from 'react';
import { Avatar, Button, Card, Space, Typography } from 'antd';
import styles from './Page.module.scss';
import classNames from 'classnames';
import { Icon } from '../Icon';

export interface PageCardProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  actions?: React.ReactNode;
  actionsPosition?: 'top' | 'bottom';
  icon?: React.ReactNode;
  color?: string;
  subTitle?: React.ReactNode;
  collapsible?: boolean;
  defaultCollapsed?: boolean;
}

function useCollapsible(props: PageCardProps): {
  collapsed: boolean;
  collapseBtn?: React.ReactNode;
} {
  const { collapsible, defaultCollapsed } = props;
  const initialValue = collapsible ? !!defaultCollapsed : false;
  const [collapsed, setCollapsed] = useState(initialValue);
  const toggle = useCallback(
    () => setCollapsed((prev) => !prev),
    [setCollapsed],
  );

  if (!collapsible) {
    return { collapsed: false };
  }

  return {
    collapsed,
    collapseBtn: (
      <Button
        onClick={toggle}
        type="link"
        icon={collapsed ? <Icon type="arrow-down" /> : <Icon type="arrow-up" />}
      />
    ),
  };
}

const PageCard: FC<PageCardProps> = (props) => {
  const {
    actions,
    icon,
    title: titleProp,
    subTitle,
    color,
    actionsPosition,
    collapsible,
    defaultCollapsed,
    ...cardProps
  } = props;

  const { collapsed, collapseBtn } = useCollapsible(props);
  const noBodyClassName = !cardProps.children || collapsed ? 'no-body' : '';

  const title =
    actions || icon || titleProp ? (
      <Space>
        {icon && (
          <Avatar
            size={36}
            icon={icon}
            style={{
              backgroundColor: color,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
        )}
        <Space align="baseline">
          {titleProp && (
            <Typography.Title level={2} className="mb-0">
              {titleProp}
            </Typography.Title>
          )}
          {subTitle && (
            <Typography.Title level={4} type="secondary" className="mb-0">
              {subTitle}
            </Typography.Title>
          )}
        </Space>
      </Space>
    ) : undefined;

  let extra = actionsPosition === 'top' ? actions : <></>;
  extra = collapseBtn ? (
    <Space size="large">
      {extra} {collapseBtn}
    </Space>
  ) : (
    extra
  );

  return (
    <Card
      bordered={false}
      {...cardProps}
      extra={extra}
      actions={actionsPosition === 'bottom' ? [actions] : undefined}
      title={title}
      children={collapsed ? undefined : cardProps.children}
      className={classNames(
        props.className,
        styles.card,
        noBodyClassName,
        'ant-card-shadowed',
      )}
    />
  );
};

PageCard.defaultProps = {
  actionsPosition: 'top',
};

export const PageCardExports = {
  PageCard,
};
