import { AttachmentDto, useAppClippo } from '@/core';
import { useUploadImageMutation } from '@/redux';
import {
  ActivateNewClipAction,
  ClippoOptions,
  StoreClipDto,
  UseClippoResult,
} from '@webinex/clippo';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { spinner } from '../Spinner';
import { ImageBoxProps } from './ImageBox';

const COMPRESS_OPTIONS = {
  maxSizeMB: 0.5,
  maxWidthOrHeight: 1200,
};

function useHandleStore(clippo: UseClippoResult<AttachmentDto>) {
  const { store } = clippo;

  const compress = useCallback(async (files: File[]) => {
    spinner.show();

    try {
      const { default: compress } = await import('browser-image-compression');
      const promises = files.map(
        (file) =>
          compress(file, COMPRESS_OPTIONS) as Promise<Blob & { name: string }>,
      );
      const blobs = await Promise.all(promises);
      return blobs.map(
        (blob) => new File([blob], blob.name, { type: blob.type }),
      );
    } finally {
      spinner.hide();
    }
  }, []);

  return useCallback(
    async (files: File[]) => {
      const compressed = await compress(files);
      await store(compressed);
    },
    [store, compress],
  );
}

export const useUploadImagesFromPdf = (
  imageBox: ImageBoxProps & UseClippoResult<AttachmentDto>,
  id: string,
  ownerId: string,
) => {
  const [add] = useUploadImageMutation();

  return useMemo(
    () => async (args: File[]) => {
      const stored: Array<AttachmentDto | null> = [];

      for (const arg of args) {
        const args: StoreClipDto = {
          file: arg,
          actions: [new ActivateNewClipAction(ownerId, id)],
        };

        add(args)
          .unwrap()
          .then((e) => {
            stored.push(e);
            imageBox.update((prev) => ({ items: [...prev.items, e] }));
          });
      }

      return stored;
    },
    [ownerId, id, add, imageBox],
  );
};

function useImageBoxInternal(clippo: UseClippoResult<AttachmentDto>) {
  const [galleryVisible, onGalleryVisibleChange] = useState(false);
  const { remove, value } = clippo;
  const { items } = value;

  const toggleGallery = useCallback(
    () => onGalleryVisibleChange((prev) => !prev),
    [onGalleryVisibleChange],
  );

  const handleRemove = useCallback(
    ({ id }: AttachmentDto) => remove({ id }),
    [remove],
  );

  const handleStore = useHandleStore(clippo);

  return useMemo(
    () => ({
      onStore: handleStore,
      galleryVisible,
      onGalleryVisibleChange,
      toggleGallery,
      onRemove: handleRemove,
      items,
      ...clippo,
    }),
    [galleryVisible, items, toggleGallery, handleRemove, handleStore, clippo],
  );
}

export function useImageBox(
  args: ClippoOptions<AttachmentDto>,
): ImageBoxProps & UseClippoResult<AttachmentDto> {
  const clippo = useAppClippo(args);
  return useImageBoxInternal(clippo);
}

export type UseImageBoxLazyArgs = Omit<
  ClippoOptions<AttachmentDto>,
  'noPreload'
>;

export function useImageBoxLazy(args: UseImageBoxLazyArgs): ImageBoxProps {
  const clippo = useAppClippo({ ...args, noPreload: true });
  const imageBox = useImageBoxInternal(clippo);
  const { fetch } = clippo;

  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '0px 0px',
  });

  useEffect(() => {
    inView && fetch();

    // eslint-disable-next-line
  }, [inView]);

  return useMemo(
    () => ({
      ...imageBox,
      rootRef: ref,
      notInitialized: !inView,
      fetch,
    }),
    [imageBox, ref, inView, fetch],
  );
}

export function useImageBoxClippo(args: ClippoOptions<AttachmentDto>): {
  imageBox: ImageBoxProps;
  clippo: UseClippoResult<AttachmentDto>;
} {
  const clippo = useAppClippo(args);
  const imageBox = useImageBoxInternal(clippo);
  return useMemo(() => ({ clippo, imageBox }), [clippo, imageBox]);
}
