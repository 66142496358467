import { Button, ButtonProps, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';
import { confirmation } from '../Confirmation';
import { useTranslation } from 'react-i18next';
import styles from './Button.module.scss';

export type DeleteIconButtonProps = ButtonProps & {
  entityName?: string;
  confirm?: boolean;
  stopPropagation?: boolean;
};

export function DeleteIconButton(props: DeleteIconButtonProps) {
  const { entityName, confirm, onClick, stopPropagation, ...others } = props;
  const { t } = useTranslation();

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (stopPropagation) {
        e.stopPropagation();
      }

      if (confirm && onClick) {
        confirmation.delete(entityName!, () => onClick(e));
      } else {
        onClick && onClick(e);
      }
    },
    [confirm, entityName, onClick, stopPropagation],
  );

  return (
    <Tooltip
      placement="top"
      title={t('delete')}
      mouseEnterDelay={0.2}
      className={styles.deleteAction}
    >
      <Button
        type="link"
        icon={<DeleteOutlined />}
        {...others}
        onClick={handleClick}
      />
    </Tooltip>
  );
}
