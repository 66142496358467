import { TabPanel } from '@/components';
import { AssignFloorplanToHomeInModal } from '@/views';
import { useTranslation } from 'react-i18next';
import { HomeDetailsDto, ProjectStatus } from '@/core';
import { RoomListTabPanel } from '../Rooms.List/RoomListTabPanel';
import {
  useGetFloorplanDetailsQuery,
  useGetProjectContextQuery,
} from '@/redux';
import { useNavigate } from 'react-router-dom';
import { ReassignFloorplanButton } from './ReassignFloorplanButton';

interface Props {
  id: string;
  data?: HomeDetailsDto;
  projectId?: string;
}

export const HomeFloorplanPanel = (props: Props) => {
  const { id, data, projectId } = props;
  const floorplanId = data?.floorplan?.id;

  const { data: floorplanData } = useGetFloorplanDetailsQuery(
    { id: floorplanId! },
    { skip: !floorplanId },
  );

  const { data: projectContext } = useGetProjectContextQuery(
    {
      id: projectId!,
    },
    { skip: !projectId },
  );

  const isProjectActive =
    !projectContext || projectContext.status === ProjectStatus.NotStarted;
  const { t } = useTranslation();
  const push = useNavigate();

  const handleEdit = () => {
    if (!data) return;
    push(`/communities/${data.communityId}/floorplans/${floorplanId}`);
  };

  return (
    <TabPanel>
      <TabPanel.Title>{t('floorplans.header')}</TabPanel.Title>
      {isProjectActive && floorplanData && (
        <TabPanel.Actions>
          <ReassignFloorplanButton floorplan={floorplanData} homeId={id} />
        </TabPanel.Actions>
      )}
      <TabPanel.Body>
        {data && (
          <>
            {!data.floorplan && (
              <AssignFloorplanToHomeInModal
                communityId={data.communityId}
                homeId={id}
              />
            )}
            {floorplanData && (
              <RoomListTabPanel
                communityId={floorplanData.communityId}
                floorplanId={floorplanId!}
                wholeHomeSquareFootage={floorplanData?.wholeHomeSquareFootage}
                wholeHomeCategories={floorplanData?.categories}
                readonly
                onEdit={handleEdit}
              />
            )}
          </>
        )}
      </TabPanel.Body>
    </TabPanel>
  );
};
