import { EditIconButton, Title, Tree } from '@/components';
import { ItemDto, CommunityPermissions } from '@/core';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Assert } from '../Communities.Common';
import styles from './SubcontractorDetails.module.scss';
import { useSubcontractorTreeData } from './SubcontractorItemsDetailsPanel';

interface Props {
  communityId: string;
  onEdit: () => void;
  selectedItems: ItemDto[];
}

export function ViewSubcontractorItems(props: Props) {
  const { communityId, onEdit, selectedItems } = props;
  const { t } = useTranslation();
  const treeData = useSubcontractorTreeData(communityId, selectedItems);

  return (
    <>
      <Title
        actions={
          <Assert
            permission={CommunityPermissions.Subcontractors.Manage}
            active
          >
            <EditIconButton type="link" onClick={() => onEdit()} />
          </Assert>
        }
        inlineActions
      >
        {t('subcontractors.details.viewItemsTitle')}
      </Title>
      {!isEmpty(treeData) && (
        <Tree
          rootClassName={classNames('nav', styles.nav)}
          treeData={treeData}
          selectable={false}
        />
      )}
    </>
  );
}
