import { Navigate, Route, Routes } from 'react-router-dom';
import { ActivityDetailsPage } from './Activity.Details/ActivityDetailsPage';
import { ActivityListPage } from './Activity.List/ActivityListPage';
import { AboutUsPage } from './Misc.AboutUs/AboutUsPage';
import { Error404Page } from './Misc.Errors.404/Error404Page';
import { TermsPage } from './Misc.Terms/TermsPage';
import { UserChangePasswordPage } from './Users.ChangePassword/UserChangePasswordPage';
import { UserProfilePage } from './Users.Profile/UserProfilePage';
import { CommunitiesListPage } from './Communities.List/CommunitiesListPage';
import { CommunityDetailsPage } from './Communities.Details/CommunityDetailsPage';
import { HomeDetailsPage } from './Homes.Details/HomeDetailsPage';
import { ClientsListPage } from './Clients.List/ClientsListPage';
import { ClientDetailsPage } from './Clients.Details/ClientDetailsPage';
import { FloorplanDetailsPage } from './Floorplans.Details/FloorplanDetailsPage';
import { ProjectDetailsPage } from './Projects.Details/ProjectDetailsPage';
import { ProjectListPage } from './Projects.List/ProjectListPage';
import { AdminPage } from './Admin/AdminPage';
import { TaskDetailsPage } from './Tasks.Details/TaskDetailsPage';
import { PathTemplateEditPage } from './PathTemplates.Edit/PathTemplateEditPage';
import { PathTemplateDetailsPage } from './PathTemplates.Details/PathTemplateDetailsPage';
import { ProjectPage } from './Projects.Project/ProjectPage';
import { ProjectDevelopScopePage } from './Projects.DevelopScope/ProjectDevelopScopePage';
import { ProjectTasksPage } from './Projects.Tasks/ProjectTasksPage';
import { ProjectFinancialInfoPage } from './Projects.FinancialInfo/ProjectFinancialInfoPage';
import { TaskNavigatePage } from './Tasks.Navigate/TaskNavigatePage';
import { ClientUsersListPage } from './Users.Client.List/ClientUsersListPage';
import { ProjectHomesPage } from './Projects.Homes/ProjectHomesPage';
import { SubcontractorDetailsPage } from './Subcontractors.Details/SubcontractorDetailsPage';
import { ProjectPathDetailsPage } from './Projects.Details/ProjectPathDetailsPage';
import { ProjectPathEditPage } from '@/pages/Projects.Details/ProjectPathEditPage';
import { CommunitiesAreaPage } from './Communities.Area/CommunitiesAreaPage';
import { CustomPathTemplateDetailsPage } from './PathTemplates.Details/CustomPathTemplateDetailsPage';
import { CustomPathTemplateEditPage } from './PathTemplates.Edit/CustomPathTemplateEditPage';
import { ReportsPage } from './Reports/ReportsPage';
import { NotificationsListPage } from './Activity.Details/Notifications.List/NotificationsListPage';
import { OpenItemByNumberPage } from '@/views';
import { ProjectHomePersonalizationPage } from './Projects.HomePersonalization/ProjectHomePersonalizationPage';
import { TaskTemplateEditPage } from './TaskTemplates.Edit/TaskTemplateEditPage';
import { TaskTemplateDetailsPage } from './TaskTemplates.Details/TaskTemplateDetailsPage';
import { ProjectWorkspacePage } from './Project.Workspace/ProjectWorkspacePage';
import { IndexPage } from '@/pages/Index/IndexPage';
import { UserDetailsPage } from './Users.Details/UserDetailsPage';

export function Pages() {
  return (
    <Routes>
      <Route path="/index" element={<IndexPage />} />
      <Route path="/item/:number" element={<OpenItemByNumberPage />} />
      <Route path="/terms-and-conditions" element={<TermsPage />} />
      <Route path="/about-us" element={<AboutUsPage />} />
      <Route path="/me" element={<UserProfilePage />} />
      <Route path="/change-password" element={<UserChangePasswordPage />} />
      <Route path="/admin" element={<AdminPage />} />
      <Route path="/reports" element={<ReportsPage />} />
      <Route path="/users" element={<ClientUsersListPage />} />
      <Route path="/users/:userId" element={<UserDetailsPage />} />
      <Route path="/activity" element={<ActivityListPage />} />
      <Route path="/notifications" element={<NotificationsListPage />} />
      <Route path="/activity/:id" element={<ActivityDetailsPage />} />
      <Route path="/communities" element={<CommunitiesListPage />} />
      <Route path="/communities/:communityId" element={<CommunitiesAreaPage />}>
        <Route index element={<CommunityDetailsPage />} />
        <Route path="homes/:id" element={<HomeDetailsPage />} />
        <Route
          path="subcontractors/:id"
          element={<SubcontractorDetailsPage />}
        />
        <Route path="floorplans/:id" element={<FloorplanDetailsPage />} />
        <Route
          path="custom-path-templates/:id/edit"
          element={<CustomPathTemplateEditPage />}
        />
        <Route
          path="custom-path-templates/:id"
          element={<CustomPathTemplateDetailsPage />}
        />
        <Route
          path="task-templates/:id/edit"
          element={<TaskTemplateEditPage />}
        />
        <Route
          path="task-templates/:id"
          element={<TaskTemplateDetailsPage />}
        />
      </Route>
      <Route path="/tasks/:id" element={<TaskNavigatePage />} />
      <Route path="/clients" element={<ClientsListPage />} />
      <Route path="/clients/:id" element={<ClientDetailsPage />} />
      <Route path="/projects" element={<ProjectListPage />} />
      <Route path="/projects/:id" element={<ProjectPage />}>
        <Route path="administration" element={<ProjectDetailsPage />} />
        <Route path="homes" element={<ProjectHomesPage />} />
        <Route path="develop-scope" element={<ProjectDevelopScopePage />} />
        <Route path="workspace" element={<ProjectWorkspacePage />} />
        <Route path="workspace/:homeId" element={<ProjectWorkspacePage />} />
        <Route path="task-management" element={<ProjectTasksPage />} />
        <Route path="task-management/:taskId" element={<TaskDetailsPage />} />
        <Route
          path="home-personalization"
          element={<ProjectHomePersonalizationPage />}
        />

        <Route
          path="financial-management"
          element={<ProjectFinancialInfoPage />}
        />
        <Route path="path" element={<ProjectPathDetailsPage />} />
        <Route path="path/edit" element={<ProjectPathEditPage />} />
      </Route>
      <Route path="*" element={<Error404Page />} />
      <Route path="/" element={<Navigate to="/index" replace />} />
      <Route
        path="/admin/path-templates/:id/edit"
        element={<PathTemplateEditPage />}
      />
      <Route
        path="/admin/path-templates/:id"
        element={<PathTemplateDetailsPage />}
      />
    </Routes>
  );
}
