import { useField } from "formik";
import { TemplateTaskDto } from "@/core";
import { useGetTaskTemplateDetailsQuery } from "@/redux";
import { Steps } from "antd";
import { FormTemplateTask } from "@/views";

const { Step } = Steps;

interface Props {
  name: string;
  communityId?: string;
}

export function FormTaskTemplateTaskList(props: Props) {
  const { name, communityId } = props;
  const [{ value: templateId }] = useField<string>('id');
  const [{ value: tasks }] = useField<TemplateTaskDto[]>(name);
  const { data } = useGetTaskTemplateDetailsQuery(
    { id: templateId! },
    { skip: !templateId },
  );
  return (
    <Steps direction="vertical" size="small" current={0}>
      {tasks.map((task, index) => (
        <Step
          key={task.id}
          status="process"
          title={
            <FormTemplateTask
              name={`tasks[${index}]`}
              communityId={communityId}
              initResponsiblePerson={data?.tasks.find(x => x.id === task.id)?.responsiblePerson}
            />
          }
        />
      ))}
    </Steps>
  );
}