import { Button, Col, Input, Row, Space } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function _PlainTextFilter(props: FilterDropdownProps) {
  const { t } = useTranslation();
  const { setSelectedKeys, visible, confirm } = props;
  const [values, setValues] = useState<string[]>(['']);

  function reset() {
    setValues(['']);
  }

  function add() {
    setValues((prev) => [...prev, '']);
  }

  function updateValue(index: number, value: string) {
    setValues((prev) => {
      const result = [...prev];
      result[index] = value;
      return result;
    });
  }

  function flush() {
    const normalizedValues = values.map((x) => x.trim());
    const selectedKeys =
      normalizedValues.length === 1 && isEmpty(normalizedValues[0])
        ? []
        : normalizedValues;

    setSelectedKeys(selectedKeys);
    confirm({ closeDropdown: false });
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    flush();
  }

  useEffect(() => {
    if (visible) {
      return;
    }

    flush();

    // eslint-disable-next-line
  }, [visible]);

  return (
    <div>
      <form onSubmit={onSubmit}>
        <Space style={{ padding: 8 }} direction="vertical">
          {values.map((value, index) => (
            <Input
              value={value}
              onChange={(e) => updateValue(index, e.target.value)}
            />
          ))}
        </Space>
      </form>
      <Row justify="space-between" style={{ padding: 8 }}>
        <Col>
          <Button onClick={reset} size="small" type="link">
            {t('reset')}
          </Button>
        </Col>
        <Col>
          <Button onClick={add} size="small" type="link">
            {t('add')}
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => confirm({ closeDropdown: true })}
            size="small"
            type="primary"
          >
            {t('ok')}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

function onFilter<T>(filterValue: string | number | boolean, recordValue: T) {
  if (!recordValue) return false;
  const value = String(recordValue).toLowerCase();
  const searchString = filterValue.toString().toLowerCase();
  return value.includes(searchString);
}

export const PlainTextFilter = Object.assign(_PlainTextFilter, {
  onFilter,
});
