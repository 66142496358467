import { rtkq, RtkqRequest } from '../rtkq';
import {
  AddSubscriptionDto,
  Paged,
  Paging,
  SubscriptionDetailsDto,
  SubscriptionDto,
  SubscriptionFilterParams,
  subscriptionHttp,
  UpdateSubscriptionDto,
} from '@/core';
import { api } from '../api';
import { SortResult } from '@/components/Collections.Paging/useSorting';

export type SubscriptionState = SubscriptionDto;
export type AddSubscriptionArgs = AddSubscriptionDto;
export type UpdateSubscriptionArgs = UpdateSubscriptionDto;
export type SubscriptionDetailsState = SubscriptionDetailsDto;

const subscriptionsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSubscriptionsList: build.query<
      Paged<SubscriptionState>,
      RtkqRequest<{
        paging: Paging;
        filters?: SubscriptionFilterParams;
        sorting?: SortResult;
      }>
    >({
      queryFn: async (args) => {
        return rtkq(args).exec(() =>
          subscriptionHttp.getAll(args.paging, args.filters, args.sorting),
        );
      },
      providesTags: (result) => [
        { type: 'subscription', id: 'list' },
        ...(result?.items || []).map(({ communityId }) => ({
          type: 'subscription' as const,
          communityId,
        })),
      ],
    }),

    getSubscriptionDetails: build.query<
      SubscriptionDetailsState,
      RtkqRequest<{ id: string }>
    >({
      queryFn: async (args) => {
        const { id } = args;
        return rtkq(args).exec(() => subscriptionHttp.get(id));
      },
      providesTags: (subscription) => [
        { type: 'subscription', id: subscription?.communityId ?? 'none' },
      ],
    }),

    addSubscription: build.mutation<string, RtkqRequest<AddSubscriptionArgs>>({
      queryFn: async (args) => {
        return rtkq(args).exec(() => subscriptionHttp.add(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'subscription', id: 'list' },
        { type: 'community', id: args.communityId },
      ],
    }),

    updateSubscription: build.mutation<
      void,
      RtkqRequest<UpdateSubscriptionArgs>
    >({
      queryFn: async (args) => {
        return rtkq(args).exec(() => subscriptionHttp.update(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'subscription', id: 'list' },
        { type: 'subscription', id: args.communityId },
      ],
    }),
  }),
});

export const {
  useGetSubscriptionsListQuery,
  useAddSubscriptionMutation,
  useGetSubscriptionDetailsQuery,
  useUpdateSubscriptionMutation,
} = subscriptionsApi;
