import { TextBox, EditIconButton, Title } from '@/components';
import { useGetProjectPathDetailsQuery } from '@/redux';
import { useTranslation } from 'react-i18next';
import {
  PathTemplateStepList,
  useCommunityContext,
  useProjectContext,
} from '@/views';
import { useNavigate } from 'react-router-dom';
import { CommunityPermissions } from '@/core';

interface Props {
  projectId: string;
}

export function ProjectPathDetailsPanel(props: Props) {
  const { projectId } = props;
  const { data } = useGetProjectPathDetailsQuery({ projectId: projectId });
  const { t } = useTranslation();
  const push = useNavigate();
  const { assert } = useCommunityContext();
  const { isActive } = useProjectContext();

  if (!data) {
    return null;
  }

  const { name } = data;
  const canModify = assert(CommunityPermissions.Path.Manage, true) && isActive;

  return (
    <div>
      <Title className="mt-0">
        {t('pathTemplates.details.general')}

        <Title.Actions inline space>
          {canModify && (
            <EditIconButton
              type="link"
              onClick={() => push(`/projects/${data.projectId}/path/edit`)}
            />
          )}
        </Title.Actions>
      </Title>
      <TextBox label={t('pathTemplates.details.name')}>{name}</TextBox>
      <TextBox label={t('pathTemplates.details.stepsDetails')}>
        <PathTemplateStepList value={data} />
      </TextBox>
    </div>
  );
}
