import { CategoryListPanel } from '../Categories.List/CategoryListPanel';
import { RoomTypesListTabPanel } from '../RoomTypes.List/RoomTypesListTabPanel';
import {
  BFPUserListTabPanel,
  HomesUploadPanel,
  PathTemplatesListTabPanel,
  SubscriptionListTabPanel,
  SummarySettingsTabContent,
} from '@/views';
import { BFP_ORGANIZATION_ID } from '@/platform';
import { Tabs } from '@/components';
import { enumObject } from '@/utils';
import { useTranslation } from 'react-i18next';
import { ItemsUploadPanel } from '../Items.Upload/ItemsUploadPanel';

const { TabPane } = Tabs;

interface Props {
  className?: string;
}

const TAB_KEYS = [
  'subscriptions',
  'admins',
  'categories',
  'roomTypes',
  'path-templates',
  'homes-upload',
  'items-upload',
  'summary-settings',
] as const;
export const AdminTabKeys = enumObject('AdminTabKeys', TAB_KEYS);

export const AdminTabs = (props: Props) => {
  const { className } = props;
  const { t } = useTranslation();

  return (
    <Tabs className={className} tabKeys={AdminTabKeys} destroyInactiveTabPane>
      <TabPane tab={t('subscriptions.title')} key={AdminTabKeys.subscriptions}>
        <SubscriptionListTabPanel />
      </TabPane>
      <TabPane tab={t('users.bfpTitle')} key={AdminTabKeys.admins}>
        <BFPUserListTabPanel />
      </TabPane>
      <TabPane tab={t('categories.title')} key={AdminTabKeys.categories}>
        <CategoryListPanel />
      </TabPane>
      <TabPane tab={t('roomTypes.title')} key={AdminTabKeys.roomTypes}>
        <RoomTypesListTabPanel />
      </TabPane>
      <TabPane
        tab={t('pathTemplates.tabTitle')}
        key={AdminTabKeys['path-templates']}
      >
        <PathTemplatesListTabPanel
          organizationId={BFP_ORGANIZATION_ID}
          detailsRoute={(id) => `/admin/path-templates/${id}`}
          editRoute={(id) => `/admin/path-templates/${id}/edit`}
        />
      </TabPane>
      <TabPane tab={t('homes.upload.title')} key={AdminTabKeys['homes-upload']}>
        <HomesUploadPanel />
      </TabPane>
      <TabPane tab={t('items.upload.title')} key={AdminTabKeys['items-upload']}>
        <ItemsUploadPanel />
      </TabPane>
      <TabPane
        tab={t('summarySettings.title')}
        key={AdminTabKeys['summary-settings']}
      >
        <SummarySettingsTabContent />
      </TabPane>
    </Tabs>
  );
};
