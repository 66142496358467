import { Button, Col, Row } from 'antd';
import { Form, notify } from '@/components';
import * as Yup from 'yup';
import {
  AddDevelopScopeItemArgs,
  AddSpecialRequestArgs,
  UpdateDevelopScopeItemArgs,
  useAddSpecialRequestMutation,
} from '@/redux';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { SpecialRequestDetailsModal } from '../Personalization.Details/SpecialRequest/SpecialRequestDetailsModal';
import { CodedException, SpecialRequestStatus } from '@/core';
import { RtkqNotifyError } from '@/redux/rtkq';

const schema = Yup.object().shape({
  description: Yup.string().field('lg').nullable().required(),
  imageActions: Yup.array().required(),
});

interface Props<
  TValue extends UpdateDevelopScopeItemArgs | AddDevelopScopeItemArgs,
> {
  onCancel: () => any;
  onSubmit: (value: TValue) => any;
  value: TValue;
  className?: string;
  saveButtonName: string;
  roomId?: string;
  compact?: boolean;
}

function useSubmit<
  TValue extends UpdateDevelopScopeItemArgs | AddDevelopScopeItemArgs,
>(props: Props<TValue>) {
  const { onSubmit } = props;

  return useCallback(
    (value: TValue) => {
      onSubmit(value);
    },
    [onSubmit],
  );
}

function useSpecialRequestSubmit<
  TValue extends UpdateDevelopScopeItemArgs | AddDevelopScopeItemArgs,
>(onClose: () => any, submit: (item: TValue) => void) {
  const [add] = useAddSpecialRequestMutation();

  return (args: AddSpecialRequestArgs, item: TValue) => {
    const handleError = (error: any) => {
      const e = CodedException.from(error);
      if (e.code === 'INV.PROJECT_STEP.PASSED') {
        notify.error.coded({
          ...e,
          code: 'INV.PROJECT_STEP.SELECTED_IS_PASSED',
        });
        return;
      }

      throw error;
    };

    add({
      ...args,
      position: args.isFloorplan ? { x: 10, y: 10 } : undefined,
      [RtkqNotifyError]: false,
    })
      .unwrap()
      .then((id: string) =>
        submit({
          ...item,
          roomId: undefined,
          isFloorplan: true,
          specialRequestId: id,
        }),
      )
      .then(() => {
        notify.success.t('specialRequests.added');
        onClose();
      })
      .catch(handleError);
  };
}

function useInitialValue({
  stepId,
  projectId,
  homeId,
  description,
}: {
  stepId: string;
  projectId: string;
  homeId: string;
  description?: string;
}) {
  return useMemo<AddSpecialRequestArgs>(
    () => ({
      name: null!,
      stepId: stepId!,
      categoryId: null!,
      imageActions: [],
      projectId,
      homeId,
      status: SpecialRequestStatus.Pending,
      isFloorplan: true,
      fee: false,
      description: description,
    }),
    [stepId, projectId, homeId, description],
  );
}

export function DevelopScopeItemEditView<
  TValue extends UpdateDevelopScopeItemArgs | AddDevelopScopeItemArgs,
>(props: Props<TValue>) {
  const { onCancel, value, saveButtonName, compact } = props;
  const { t } = useTranslation();
  const id: string | undefined = (value as UpdateDevelopScopeItemArgs).id;
  const [openModal, setOpenModal] = useState(false);
  const handleSubmit = useSubmit(props);
  const [description, setDescription] = useState<string>();
  const specialRequestInitialValue = useInitialValue({
    stepId: '',
    projectId: value.projectId,
    homeId: value.homeId,
    description,
  });
  const specialRequestSubmit = useSpecialRequestSubmit(
    () => setOpenModal(false),
    handleSubmit,
  );

  return (
    <Form.Formik<TValue>
      uid="developScope-details"
      i18n="developScope.details"
      initialValues={value}
      validationSchema={schema}
      onSubmit={(value) => {
        if (value.specialRequestRequired && !value.specialRequest) {
          setDescription(value.description);
          setOpenModal(true);
        } else {
          handleSubmit(value);
        }
      }}
      enableReinitialize
    >
      {({ values }) => (
        <>
          <Form.TextArea name="description" required />

          <Form.Images
            name="imageActions"
            defer
            ownerId={id}
            ownerType="DevelopScopeItemImage"
            noPreload={!id}
            compact={compact}
          />

          <div className="mt-3">
            <Form.Checkbox
              name="taskRequired"
              disabled={values.taskRequired && !!values.projectTaskId}
            />
          </div>
          <div className="mt-3">
            <Form.Checkbox name="specialRequestRequired" />
          </div>
          <Row wrap>
            <Col className="mb-2 me-2">
              <Button type="default" onClick={onCancel}>
                {t('developScope.details.cancelButton')}
              </Button>
            </Col>
            <Col>
              <Form.Submit type="primary">{saveButtonName}</Form.Submit>
            </Col>
          </Row>

          {openModal && (
            <SpecialRequestDetailsModal
              title={t('specialRequests.addTitle')}
              projectId={value.projectId}
              homeId={value.homeId}
              onSubmit={(specialRequest) =>
                specialRequestSubmit(specialRequest, values)
              }
              onClose={() => setOpenModal(false)}
              value={specialRequestInitialValue}
              isFloorplan
            />
          )}
        </>
      )}
    </Form.Formik>
  );
}
