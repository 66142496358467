import { useGetCommunitiesListQuery, useUserContextSelector } from '@/redux';
import { useTranslation } from 'react-i18next';
import { Alert } from 'antd';
import { isEmpty } from 'lodash';
import { DEFAULT_PAGING } from '@/core';

export const CommunitiesAccessAlert = () => {
  const paging = DEFAULT_PAGING;
  const { currentData: communities } = useGetCommunitiesListQuery({
    deactivated: null,
    paging,
  });
  const { t } = useTranslation();
  const hasRole = useUserContextSelector((x) => x.hasRole);
  const isVisible = hasRole('CLIENT_USER') && isEmpty(communities);
  if (!isVisible) return null;
  return (
    <Alert description={t('communities.hasNoAccessWarning')} className="mb-3" />
  );
};
