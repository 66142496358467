import {
  SearchByNumberState,
  useLazyGetClientDetailsQuery,
  useLazyGetCommunityDetailsQuery,
  useLazyGetDetailsQuery,
  useLazyGetFloorplanDetailsQuery,
  useLazyGetHomeDetailsQuery,
  useLazyGetItemDetailsQuery,
  useLazyGetProjectQuery,
  useLazyGetSearchResultQuery,
  useLazyGetSubcontractorDetailsQuery,
  useLazyGetTaskDetailsQuery,
} from '@/redux';
import { Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import { CommunityDetailsTabKeys } from '../Communities.Details';
import { EntityNumberLetter, ENTITY_NUMBER_LENGTH } from '@/core';
import { isBFP } from '@/platform';
import { AdminTabKeys } from '../Admin/AdminTabs';

interface Props {
  className?: string;
}

export function useSearchResult(searchString: string) {
  const [search] = useLazyGetSearchResultQuery();
  const [searchPending, setSearchPending] = useState(false);
  const [searchResult, setSearchResult] = useState<SearchByNumberState>();

  useEffect(() => {
    if (searchString.length !== ENTITY_NUMBER_LENGTH) {
      setSearchResult(undefined);
      setSearchPending(false);
      return;
    }

    setSearchPending(true);

    search({ entityNumber: searchString }, true)
      .unwrap()
      .then((result) => setSearchResult(result))
      .finally(() => setSearchPending(false));
  }, [searchString, search]);

  return { searchResult, searchPending };
}

function getOpenText(number: string) {
  const letter = number.charAt(0);
  switch (letter) {
    case 'H':
      return t('searchByNumber.openTexts.home', { number: number });
    case 'X':
      return t('searchByNumber.openTexts.client', { number: number });
    case 'P':
      return t('searchByNumber.openTexts.project', { number: number });
    case 'F':
      return t('searchByNumber.openTexts.floorplan', { number: number });
    case 'C':
      return t('searchByNumber.openTexts.community', { number: number });
    case 'Y':
      return t('searchByNumber.openTexts.subcontractor', { number: number });
    case 'I':
      return t('searchByNumber.openTexts.item', { number: number });
    default:
      return number;
  }
}

function getOptions(
  data: SearchByNumberState | undefined,
): DefaultOptionType[] {
  if (data == null || data.id == null) {
    return [];
  }
  return [
    {
      label: getOpenText(data.number),
      value: '',
    },
  ];
}

function useRedirectToClient() {
  const [getClientDetails] = useLazyGetClientDetailsQuery();
  const push = useNavigate();

  return useCallback(
    ({ id }: SearchByNumberState) => {
      return getClientDetails({ id })
        .unwrap()
        .then(({ id }) => push(`/clients/${id}`));
    },
    [getClientDetails, push],
  );
}

function useRedirectToCommunity() {
  const [getCommunityDetails] = useLazyGetCommunityDetailsQuery();
  const push = useNavigate();

  return useCallback(
    ({ id }: SearchByNumberState) => {
      return getCommunityDetails({ id })
        .unwrap()
        .then(({ id }) => push(`/communities/${id}`));
    },
    [getCommunityDetails, push],
  );
}

function useRedirectToProject() {
  const [getProjectDetails] = useLazyGetProjectQuery();
  const push = useNavigate();

  return useCallback(
    ({ id }: SearchByNumberState) => {
      return getProjectDetails({ id })
        .unwrap()
        .then(({ id }) => push(`/projects/${id}/administration`));
    },
    [getProjectDetails, push],
  );
}

function useRedirectToHome() {
  const [getHomeDetails] = useLazyGetHomeDetailsQuery();
  const push = useNavigate();

  return useCallback(
    ({ id }: SearchByNumberState) => {
      return getHomeDetails({ id })
        .unwrap()
        .then(({ communityId }) =>
          push(`/communities/${communityId}/homes/${id}`),
        );
    },
    [getHomeDetails, push],
  );
}

function useRedirectToFloorplan() {
  const [getFloorplanDetails] = useLazyGetFloorplanDetailsQuery();
  const push = useNavigate();

  return useCallback(
    ({ id }: SearchByNumberState) => {
      return getFloorplanDetails({ id })
        .unwrap()
        .then(({ communityId }) =>
          push(`/communities/${communityId}/floorplans/${id}`),
        );
    },
    [getFloorplanDetails, push],
  );
}

function useRedirectToSubcontractor() {
  const [getSubcontractorDetails] = useLazyGetSubcontractorDetailsQuery();
  const push = useNavigate();

  return useCallback(
    ({ id }: SearchByNumberState) => {
      return getSubcontractorDetails({ id })
        .unwrap()
        .then(({ communityId }) =>
          push(`/communities/${communityId}/subcontractors/${id}`),
        );
    },
    [getSubcontractorDetails, push],
  );
}

function useRedirectToItem() {
  const [getItemDetails] = useLazyGetItemDetailsQuery();
  const push = useNavigate();

  return useCallback(
    ({ id }: SearchByNumberState) => {
      return getItemDetails({ id })
        .unwrap()
        .then(({ communityId }) =>
          push(
            `/communities/${communityId}#${CommunityDetailsTabKeys['items-and-categories']}`,
          ),
        );
    },
    [getItemDetails, push],
  );
}

function useRedirectToUser() {
  const [getUserDetails] = useLazyGetDetailsQuery();
  const push = useNavigate();

  return useCallback(
    ({ id }: SearchByNumberState) => {
      return getUserDetails({ id })
        .unwrap()
        .then(({ organizationId }) =>
          push(
            isBFP(organizationId)
              ? `/admin#${AdminTabKeys.admins}`
              : `/users?clientId=${organizationId}`,
          ),
        );
    },
    [getUserDetails, push],
  );
}

function useRedirectToTask() {
  const [getTaskDetails] = useLazyGetTaskDetailsQuery();
  const push = useNavigate();

  return useCallback(
    ({ id }: SearchByNumberState) => {
      return getTaskDetails({ id })
        .unwrap()
        .then(({ id, projectId }) =>
          push(`/projects/${projectId}/task-management/${id}`),
        );
    },
    [getTaskDetails, push],
  );
}

export function useRedirect(
  searchResult: SearchByNumberState | undefined,
  onRedirected?: () => any,
) {
  const redirectToClient = useRedirectToClient();
  const redirectToCommunity = useRedirectToCommunity();
  const redirectToProject = useRedirectToProject();
  const redirectToHome = useRedirectToHome();
  const redirectToFloorplan = useRedirectToFloorplan();
  const redirectToSubcontractor = useRedirectToSubcontractor();
  const redirectToItem = useRedirectToItem();
  const redirectToUser = useRedirectToUser();
  const redirectToTask = useRedirectToTask();

  return useCallback(() => {
    if (searchResult == null) {
      return;
    }
    const letter = searchResult.number.charAt(0) as EntityNumberLetter;

    switch (letter) {
      case 'H':
        return redirectToHome(searchResult).then(onRedirected);
      case 'X':
        return redirectToClient(searchResult).then(onRedirected);
      case 'P':
        return redirectToProject(searchResult).then(onRedirected);
      case 'F':
        return redirectToFloorplan(searchResult).then(onRedirected);
      case 'C':
        return redirectToCommunity(searchResult).then(onRedirected);
      case 'Y':
        return redirectToSubcontractor(searchResult).then(onRedirected);
      case 'I':
        return redirectToItem(searchResult).then(onRedirected);
      case 'U':
        return redirectToUser(searchResult).then(onRedirected);
      case 'T':
        return redirectToTask(searchResult).then(onRedirected);
      default:
        throw new Error(`Unable to find number`);
    }
  }, [
    searchResult,
    redirectToClient,
    redirectToCommunity,
    redirectToProject,
    redirectToHome,
    redirectToFloorplan,
    redirectToSubcontractor,
    redirectToItem,
    redirectToUser,
    redirectToTask,
    onRedirected,
  ]);
}

export function SearchByNumberField(props: Props) {
  const { className } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'searchByNumber',
  });
  const [searchValue, setSearchValue] = useState('');
  const { searchResult, searchPending } = useSearchResult(searchValue);
  const [options, setOptions] = useState<DefaultOptionType[]>();
  const handleSelect = useRedirect(searchResult, () => setSearchValue(''));

  useEffect(() => {
    setOptions(getOptions(searchResult));
  }, [searchResult]);

  return (
    <Select
      className={className}
      loading={searchPending}
      showSearch
      placeholder={
        <>
          <SearchOutlined />
          {t('placeholder')}
        </>
      }
      filterOption={false}
      searchValue={searchValue}
      value={null}
      onSearch={setSearchValue}
      onSelect={handleSelect}
      showArrow={false}
      notFoundContent={t('notFoundContentText')}
      allowClear={false}
      options={options}
    />
  );
}
