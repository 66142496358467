import React, { FC } from 'react';
import { Formik, FormikConfig, FormikContextType } from 'formik';
import { FormI18nContext } from './FormsI18nContext';
import { useFormikFormRegistration } from './FormsContext';

interface Extension<T> {
  uid: string;
  children:
    | React.ReactNode
    | ((formik: FormikContextType<T>) => React.ReactNode);
  i18n?: string;
}

type FormikWithoutChildren<T> = Omit<FormikConfig<T>, 'children'>;
export type FormsFormikProps<T = any> = FormikWithoutChildren<T> & Extension<T>;

const FormikLifecycle: FC<{ uid: string }> = ({ uid }) => {
  useFormikFormRegistration(uid);
  return <></>;
};

export function FormsFormik<T>(props: FormsFormikProps<T>) {
  const { uid, children, i18n, ...formikProps } = props;

  const renderChildren =
    typeof children === 'function'
      ? (formikContext: FormikContextType<T>) => children(formikContext)
      : () => children;

  return (
    <FormI18nContext.Provider value={i18n}>
      <Formik<T> {...formikProps}>
        {(formikContext) => (
          <>
            <FormikLifecycle uid={uid} />
            {renderChildren(formikContext)}
          </>
        )}
      </Formik>
    </FormI18nContext.Provider>
  );
}
