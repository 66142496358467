import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImportTasksModal, SelectTaskTemplateFormValue, TaskTemplateModal } from '@/views';
import { AddButton, notify } from '@/components';
import { useProjectContext } from '@/views';
import { useAddTasksMutation } from '@/redux';
import { AddRowTaskDto } from '@/core';

interface ImportTasksButtonProps {
  className?: string;
  projectId: string;
  communityId: string;
}

export interface ImportTasksModalInitialValue {
  projectId?: string;
  templateIds?: string[];
}

const useSubmitTaskTemplate = (setTaskTemplateVisible: (value: boolean) => any,
                               setImportTasksVisible: (value: boolean) => any,
                               setImportTasksModalInitialValue: (value: ImportTasksModalInitialValue) => any) => {
  const { projectId } = useProjectContext()
  return async (args: SelectTaskTemplateFormValue) => {
    await setImportTasksModalInitialValue({ projectId: projectId, templateIds: args.templateIds });
    await setTaskTemplateVisible(false);
    await setImportTasksVisible(true);
  };
};

const useSubmitImportTasks = (props: ImportTasksButtonProps, setImportTasksVisible: (value: boolean) => any) => {
  const [add] = useAddTasksMutation()
  return (args: AddRowTaskDto[]) => {
    add(args)
      .unwrap()
      .then(() => {
        notify.success.t('tasks.imported');
        setImportTasksVisible(false)
      });
  }
}

export function ImportTasksButton(props: ImportTasksButtonProps) {
  const [openSelectTemplateModal, setOpenTemplateModal] = useState(false);
  const [openImportTasksModal, setOpenImportTasksModal] = useState(false);
  const [importTasksModalInitialValue, setImportTasksModalInitialValue] = useState<ImportTasksModalInitialValue>()
  const submitTaskTemplate = useSubmitTaskTemplate(setOpenTemplateModal, setOpenImportTasksModal, setImportTasksModalInitialValue);
  const { t } = useTranslation();
  const { className } = props;
  const submitImportTasks = useSubmitImportTasks(props, setOpenImportTasksModal);
  const { isActive } = useProjectContext();

  if (!isActive) return null;

  return (
    <div className={className}>
      <AddButton
        onClick={() => setOpenTemplateModal(true)}
        ghost>
        {t('tasks.details.importButton')}
      </AddButton>
      {openSelectTemplateModal && (
        <TaskTemplateModal
          title={t('tasks.details.importTitle')}
          onClose={() => setOpenTemplateModal(false)}
          onSubmit={submitTaskTemplate}
        />
      )}
      {openImportTasksModal && (
        <ImportTasksModal
          title={t('tasks.details.importTitle')}
          onClose={() => setOpenImportTasksModal(false)}
          onSubmit={submitImportTasks}
          value={importTasksModalInitialValue}
        />
      )}
    </div>
  );
}