import { isActiveCommunityStatus } from '@/core';
import {
  CommunityContext as CommunityContextType,
  hasPermission,
  useUserContextSelector,
} from '@/redux';
import React, { useContext, useMemo } from 'react';

export const CommunityContext = React.createContext<CommunityContextType>(
  null!,
);

export interface UseCommunityContextResult extends CommunityContextType {
  assert: (permission: string, active?: true) => boolean;
  active: boolean;
  hasPermission: (name: string) => boolean;
}

export function useCommunityContext(): UseCommunityContextResult {
  const ctx = useContext(CommunityContext);
  const role = useUserContextSelector((x) => x.role);

  if (!ctx) {
    throw new Error(
      'useCommunityContext could not be used outside CommunityContext',
    );
  }

  return useMemo(
    () => ({
      ...ctx,
      active: isActiveCommunityStatus(ctx.status),
      hasPermission: (name: string) => hasPermission(ctx, role, name),
      assert: (permission: string, active?: true) =>
        hasPermission(ctx, role, permission) &&
        (active !== true || isActiveCommunityStatus(ctx.status)),
    }),
    [ctx, role],
  );
}
