import { EnumValue } from '@/components';
import { SaleTaxApplyTo } from '@/core';
import { array, enumValues } from '@/utils';
import { Select } from 'antd';
import { useCallback, useEffect, useState } from 'react';

interface Props {
  initialValue: SaleTaxApplyTo[] | undefined;
  resetOnInitialValueChange: true;
  disabled?: boolean;
  onSubmit: (value: SaleTaxApplyTo[] | undefined) => any;
}

export function PricingSettingsApplyTo(props: Props) {
  const { initialValue, disabled, onSubmit } = props;
  const [value, setValue] = useState(initialValue);
  useEffect(() => setValue(initialValue), [initialValue]);

  const handleSubmit = useCallback(
    (value: SaleTaxApplyTo[] | undefined) => {
      setValue(value);
      onSubmit(value);
    },
    [onSubmit],
  );

  return (
    <Select<SaleTaxApplyTo[]>
      className="w-100"
      value={value ?? array.empty<SaleTaxApplyTo>()}
      labelInValue={false}
      onChange={handleSubmit}
      allowClear
      showSearch={false}
      disabled={disabled}
      mode="multiple"
    >
      {enumValues(SaleTaxApplyTo).map((value) => (
        <Select.Option key={value}>
          <EnumValue type={SaleTaxApplyTo} value={value} />
        </Select.Option>
      ))}
    </Select>
  );
}
