import { useField } from 'formik';
import { FormsGroup } from './FormsGroup';
import { useFormLabel } from './FormsI18nContext';
import React, { useEffect } from 'react';
import { Input, InputProps } from 'antd';

export type FormsInputProps = {
  name: string;
  label?: React.ReactNode;
  type?: InputProps['type'];
  required?: boolean;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  helpMessage?: string;
};

export const FormsInput = ({
  name,
  type,
  label: labelOverride,
  required,
  disabled,
  className,
  placeholder,
  helpMessage,
}: FormsInputProps) => {
  const [field, , helpers] = useField(name);
  const label = useFormLabel(name, labelOverride);

  function handleOnBlur(e: React.ChangeEvent<HTMLInputElement>) {
    if (field.value) {
      const value = e.target.value?.trim();
      helpers.setValue(value);
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;

    if (value?.length === 0) {
      helpers.setValue(null);
    } else {
      field.onChange(e);
    }
  }

  useEffect(() => {
    const value = field.value?.trim();
    helpers.setValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormsGroup
      label={label}
      name={name}
      required={required}
      helpMessage={helpMessage}
    >
      {type === 'password' ? (
        <Input.Password
          type={type}
          {...field}
          value={field.value ?? ''}
          onChange={handleChange}
          onBlur={handleOnBlur}
          className={className}
          disabled={disabled}
          placeholder={placeholder}
        />
      ) : (
        <Input
          type={type}
          {...field}
          value={field.value ?? ''}
          onChange={handleChange}
          onBlur={handleOnBlur}
          className={className}
          disabled={disabled}
          placeholder={placeholder}
        />
      )}
    </FormsGroup>
  );
};
