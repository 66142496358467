import { useField } from 'formik';
import { FormsGroup } from './FormsGroup';
import { useFormLabel } from './FormsI18nContext';
import { Space, Switch } from 'antd';

export type FormsSwitchProps = {
  name: string;
  label?: React.ReactNode;
  required?: boolean;
  noValidation?: boolean;
};

export const FormsSwitch = ({
  name,
  label: labelOverride,
  required,
  noValidation
}: FormsSwitchProps) => {
  const [field, , helpers] = useField(name);
  const label = useFormLabel(name, labelOverride);

  function handleChange(value: boolean) {
    helpers.setValue(value || false);
  }

  return (
    <FormsGroup name={name} required={required} noValidation={noValidation}>
      <Space direction="horizontal">
        <Switch checked={field.value === true} onChange={handleChange} />
        {label}
      </Space>
    </FormsGroup>
  );
};
