import { AddResidentDto, Http, ResidentDto, UpdateResidentDto } from '@/core';
import { guard } from '@/utils';

class ResidentsHttp extends Http {
  public async getAll(homeId: string) {
    const response = await this._axios.get<ResidentDto[]>(
      `/api/home/resident?homeId=${homeId}`,
    );
    return response.data;
  }

  public async get(id: string) {
    guard.notNull(id, 'id');
    const response = await this._axios.get<ResidentDto>(
      `/api/home/resident/${id}`,
    );
    return response.data;
  }

  public async create(request: AddResidentDto) {
    guard.notNull(request, 'request');
    const response = await this._axios.post(`/api/home/resident`, request);
    return response.data;
  }

  public async update(request: UpdateResidentDto) {
    guard.notNull(request, 'request');
    await this._axios.put(`/api/home/resident`, request);
  }

  public async delete(id: string) {
    guard.notNull(id, 'id');
    await this._axios.delete(`/api/home/resident/${id}`);
  }

  public async reassign(request: { id: string; homeId: string }) {
    guard.notNull(request, 'request');
    await this._axios.post(
      `/api/home/resident/${request.id}/reassign`,
      request,
    );
  }
}

export const residentsHttp = new ResidentsHttp();
