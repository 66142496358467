import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EnumValue, TableColumnType } from '@/components';
import { ItemTableRowValues } from './ItemListTabPanelGridView';
import { ItemPricePer } from '@/core';

export function useItemsColumns() {
  const { t } = useTranslation();

  return useMemo<TableColumnType<ItemTableRowValues>[]>(() => {
    return [
      {
        title: t('items.table.category'),
        key: 'category',
        render: (_, { categories }) => categories[0].name,
        width: '10%',
      },
      {
        title: t('items.table.subcategory1'),
        key: 'subcategory1',
        render: (_, { categories }) => categories[1]?.name,
        width: '12%',
      },
      {
        title: t('items.table.subcategory2'),
        key: 'subcategory2',
        render: (_, { categories }) => categories[2]?.name,
        width: '12%',
      },
      {
        title: t('items.table.name'),
        key: 'name',
        render: (_, { item }) => item?.name,
        width: '26%',
      },
      {
        title: t('items.table.description'),
        key: 'description',
        render: (_, { item }) => item?.description,
        width: '10%',
      },
      {
        title: t('items.table.constructionDescription'),
        key: 'constructionDescription',
        render: (_, { item }) => item?.constructionDescription,
        width: '20%',
      },
      {
        title: t('items.table.pricePer'),
        key: 'pricePer',
        render: (_, { item }) => (
          <EnumValue type={ItemPricePer} value={item?.pricePer} />
        ),
        width: '10%',
      },
    ];
  }, [t]);
}
