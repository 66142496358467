import { confirmation, notify } from '@/components';
import { useUnlistPathTemplateMutation } from '@/redux';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export function useUnlistPathTemplate(goBack: boolean) {
  const [unlist] = useUnlistPathTemplateMutation();
  const push = useNavigate();

  const performUnlist = useCallback(
    (id: string) =>
      unlist({ id }).then(() =>
        notify.success.t('pathTemplates.details.unlisted'),
      ),
    [unlist],
  );

  return useCallback(
    (id: string) =>
      confirmation.delete({ key: 'pathTemplates.entityName' }, () => {
        performUnlist(id);
        if (goBack) push(-1);
      }),
    [goBack, performUnlist, push],
  );
}

export function useBoundUnlistPathTemplate(id: string | undefined) {
  const unlist = useUnlistPathTemplate(true);
  return useCallback(() => (id ? unlist(id) : Promise.reject()), [id, unlist]);
}
