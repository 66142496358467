import { flippoClient } from '@/core';
import { useEffect, useState } from 'react';

export function useFlippoBlobUrl(reference: string | undefined) {
  const [loading, setLoading] = useState<boolean>(false);
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    if (!reference) {
      return;
    }

    url && URL.revokeObjectURL(url);
    setLoading(true);

    flippoClient
      .fetch(reference)
      .then((blob) => setUrl(URL.createObjectURL(blob)))
      .finally(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference]);

  useEffect(
    () => () => {
      url && URL.revokeObjectURL(url);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return { loading, url };
}
