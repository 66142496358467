import { useTranslation } from 'react-i18next';
import { Table, TabPanel } from '@/components';
import { useRolesPermissionsColumns } from './useRolesPermissionsColumns';
import { rolesPermissions } from './rolesPermissions';
import { RolesPermissions } from '@/core';

export function RolesPermissionsListPanel() {
  const { t } = useTranslation();
  const columns = useRolesPermissionsColumns();

  return (
    <TabPanel>
      <TabPanel.Title>{t('rolesPermissions.title')}</TabPanel.Title>
      <TabPanel.Body>
        <Table<RolesPermissions>
          hideDefaultActions
          name="rolesPermissions"
          columns={columns}
          dataSource={rolesPermissions}
        />
      </TabPanel.Body>
    </TabPanel>
  );
}
