import { Modal, Button } from 'antd';
import { Form } from '@/components';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  GenerateHomeStatusReportArgs,
  useGenerateHomeStatusReportMutation,
} from '@/redux';
import { FileExcelOutlined } from '@ant-design/icons';
import { HomeReportFilterForm } from './HomeReportFilterForm';

const schema = Yup.object().shape({
  projectId: Yup.array().of(Yup.string().nullable().required()).required(),
});

const INITIAL_VALUE: GenerateHomeStatusReportArgs = {
  projectId: [],
};

interface Props {
  onClose: () => any;
}

const useSubmit = (props: Props) => {
  const { onClose } = props;
  const [generate] = useGenerateHomeStatusReportMutation();

  return (args: GenerateHomeStatusReportArgs) => {
    generate(args)
      .unwrap()
      .then((uri) => {
        window.open(
          '/api/report/inline?src=' + encodeURIComponent(uri),
          '_blank',
        );

        onClose();
      });
  };
};

export function GenerateHomeStatusReportModal(props: Props) {
  const { onClose: onCancel } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'reports.homeStatus',
  });

  const handleSubmit = useSubmit(props);

  return (
    <Modal
      title={t('title')}
      visible
      onCancel={onCancel}
      footer={[
        <Button key="back" type="default" onClick={onCancel}>
          {t('cancel')}
        </Button>,
        <Form.Submit
          uid="reports-homeStatus"
          key="submit"
          type="primary"
          icon={<FileExcelOutlined />}
        >
          {t('runReport')}
        </Form.Submit>,
      ]}
    >
      <Form.Formik<GenerateHomeStatusReportArgs>
        uid="reports-homeStatus"
        i18n="reports.homeStatus"
        initialValues={INITIAL_VALUE}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <HomeReportFilterForm />
      </Form.Formik>
    </Modal>
  );
}
