import { ClippoAction } from '@webinex/clippo';
import { TaskResponsiblePersonValue, TASK_STATUS_VALUES } from '../http.tasks';
import { RoomLookupDto, SpecialRequestLookupDto } from '@/core';
import { enumObject, EnumValue } from '@/utils';

const DEVELOP_SCOPE_TASK_STATUS_VALUES = [
  ...TASK_STATUS_VALUES,
  'TaskNotRequired',
  'TaskNotAdded',
] as const;

export type DevelopScopeTaskStatus = EnumValue<
  typeof DEVELOP_SCOPE_TASK_STATUS_VALUES
>;

// eslint-disable-next-line
export const DevelopScopeTaskStatus = enumObject(
  'DevelopScopeTaskStatusFilter',
  DEVELOP_SCOPE_TASK_STATUS_VALUES,
);

export interface DevelopScopeItemDto {
  projectId: string;
  homeId: string;
  id: string;
  createdAt: string;
  description: string;
  taskRequired: boolean;
  projectTaskId?: string;
  taskName?: string;
  responsiblePerson?: TaskResponsiblePersonValue;
  taskStatus?: string;
  room: RoomLookupDto;
  specialRequest?: SpecialRequestLookupDto;
}

export type UpdateDevelopScopeItemDto = DevelopScopeItemDto & {
  specialRequestRequired?: boolean;
  imageActions: ClippoAction[];
  specialRequestId?: string;
};

export type AddDevelopScopeItemDto = Omit<
  DevelopScopeItemDto,
  'id' | 'createdAt' | 'room'
> & {
  specialRequestRequired?: boolean;
  roomId?: string | null;
  isFloorplan?: boolean;
  specialRequestId?: string;
};

export interface HomeRoomFinalizationDetailsDto {
  roomId?: string;
  isFloorplan: boolean;
}
