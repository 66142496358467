import {
  ResidentState,
  useGetProjectHomeInfoQuery,
  useGetResidentsListQuery,
} from '@/redux';
import { StyledRow } from '@/components';
import { Alert, Col, Divider, Typography } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { array } from '@/utils/array';
import { isEmpty } from 'lodash';

interface Props {
  projectId: string;
  homeId: string;
  selectedStepId?: string;
}

const { Text } = Typography;

const Signature = (props: { name?: string; date: string }) => {
  const { t } = useTranslation();
  const { name, date } = props;

  return (
    <StyledRow className="mt-3" align="bottom">
      <Col span={5}>
        <Text strong>{name}</Text>
        <Divider style={{ margin: 0 }} />
        <Text>{t('residents.title')}</Text>
      </Col>
      <Col offset={2} span={5} flex="auto">
        <Text strong>{moment(date).format('LL')}</Text>
        <Divider style={{ margin: 0 }} />
        <Text>{t('date')}</Text>
      </Col>
    </StyledRow>
  );
};

export const FinalizationResidents = (props: Props) => {
  const { projectId, homeId, selectedStepId } = props;
  const { data: context } = useGetProjectHomeInfoQuery({
    id: projectId,
    homeId,
  });
  const { t } = useTranslation();

  const selectedStep = context?.steps.find((x) => x.stepId === selectedStepId);
  const stepFinalizedAt = selectedStep?.finalizedAt;
  const stepLegalLanguage =
    selectedStep?.customLegalLanguage?.legalLanguageText;
  const homeLegalLanguage = context?.customLegalLanguage?.legalLanguageText;
  const defaultLegalLanguage = t(
    'personalization.summary.finalizationResidentsAlert',
  );
  const finalizedAt = stepFinalizedAt || context?.finalizedAt;
  const { data: residents = array.empty<ResidentState>() } =
    useGetResidentsListQuery({ homeId }, { skip: !finalizedAt });

  if (!finalizedAt) return null;

  return (
    <>
      <Alert
        type="info"
        message={stepLegalLanguage || homeLegalLanguage || defaultLegalLanguage}
      />
      <div className="mt-4">
        {isEmpty(residents) ? (
          <Signature date={finalizedAt!} />
        ) : (
          <>
            {residents.map((resident) => (
              <Signature name={resident.name} date={finalizedAt!} />
            ))}
          </>
        )}
      </div>
    </>
  );
};
