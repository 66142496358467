import { RolesPermissions } from '@/core';

export const rolesPermissions: RolesPermissions[] = [
  {
    area: 'Subscriptions',
    action: 'manage',
    manageRole: '-',
    constructionRole: '-',
    personalizationRole: '-',
    projectManagementRole: '-',
    designerRole: '-',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    action: 'view',
    manageRole: '-',
    constructionRole: '-',
    personalizationRole: '-',
    projectManagementRole: '-',
    designerRole: '-',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    area: 'Locations',
    action: 'manage',
    manageRole: '+',
    constructionRole: '-',
    personalizationRole: '-',
    projectManagementRole: '-',
    designerRole: '+',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    action: 'view',
    manageRole: '+',
    constructionRole: '+',
    personalizationRole: '+',
    projectManagementRole: '+',
    designerRole: '+',
    viewRole: '+',
    reportsRole: '-',
  },
  {
    area: 'Projects',
    action: 'manage (including the possibility to modify statuses)',
    manageRole: '+',
    constructionRole: '-',
    personalizationRole: '-',
    projectManagementRole: '-',
    designerRole: '-',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    action: 'view',
    manageRole: '+',
    constructionRole: '+',
    personalizationRole: '+',
    projectManagementRole: '+',
    designerRole: '+',
    viewRole: '+',
    reportsRole: '-',
  },
  {
    area: 'Path',
    action: 'manage',
    manageRole: '-',
    constructionRole: '+',
    personalizationRole: '-',
    projectManagementRole: '+',
    designerRole: '-',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    action: 'view',
    manageRole: '+',
    constructionRole: '+',
    personalizationRole: '+',
    projectManagementRole: '+',
    designerRole: '+',
    viewRole: '+',
    reportsRole: '-',
  },
  {
    area: 'Homes',
    action: 'manage',
    manageRole: '+',
    constructionRole: '-',
    personalizationRole: '-',
    projectManagementRole: '-',
    designerRole: '+',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    action: 'view',
    manageRole: '+',
    constructionRole: '+',
    personalizationRole: '+',
    projectManagementRole: '+',
    designerRole: '+',
    viewRole: '+',
    reportsRole: '-',
  },
  {
    area: 'Resident tab',
    action: 'manage',
    manageRole: '+',
    constructionRole: '-',
    personalizationRole: '-',
    projectManagementRole: '-',
    designerRole: '-',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    action: 'view',
    manageRole: '+',
    constructionRole: '+',
    personalizationRole: '+',
    projectManagementRole: '+',
    designerRole: '+',
    viewRole: '+',
    reportsRole: '-',
  },
  {
    area: 'Users',
    action: 'manage',
    manageRole: '-',
    constructionRole: '-',
    personalizationRole: '-',
    projectManagementRole: '-',
    designerRole: '-',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    action: 'view',
    manageRole: '-',
    constructionRole: '-',
    personalizationRole: '+',
    projectManagementRole: '-',
    designerRole: '-',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    area: 'Subcontractors',
    action: 'manage',
    manageRole: '-',
    constructionRole: '+',
    personalizationRole: '-',
    projectManagementRole: '-',
    designerRole: '-',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    action: 'view',
    manageRole: '+',
    constructionRole: '+',
    personalizationRole: '+',
    projectManagementRole: '+',
    designerRole: '+',
    viewRole: '+',
    reportsRole: '-',
  },
  {
    area: 'Items and categories',
    action: 'manage',
    manageRole: '+',
    constructionRole: '-',
    personalizationRole: '-',
    projectManagementRole: '-',
    designerRole: '+',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    action: 'view',
    manageRole: '+',
    constructionRole: '+',
    personalizationRole: '+',
    projectManagementRole: '+',
    designerRole: '+',
    viewRole: '+',
    reportsRole: '-',
  },
  {
    area: 'Menus',
    action: 'manage',
    manageRole: '+',
    constructionRole: '-',
    personalizationRole: '-',
    projectManagementRole: '-',
    designerRole: '+',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    action: 'view',
    manageRole: '+',
    constructionRole: '+',
    personalizationRole: '+',
    projectManagementRole: '+',
    designerRole: '+',
    viewRole: '+',
    reportsRole: '-',
  },
  {
    area: 'Floorplans',
    action: 'manage',
    manageRole: '+',
    constructionRole: '-',
    personalizationRole: '-',
    projectManagementRole: '-',
    designerRole: '+',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    action: 'view',
    manageRole: '+',
    constructionRole: '+',
    personalizationRole: '+',
    projectManagementRole: '+',
    designerRole: '+',
    viewRole: '+',
    reportsRole: '-',
  },
  {
    area: 'Amenities',
    action: 'manage',
    manageRole: '+',
    constructionRole: '-',
    personalizationRole: '-',
    projectManagementRole: '-',
    designerRole: '+',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    action: 'view',
    manageRole: '+',
    constructionRole: '+',
    personalizationRole: '+',
    projectManagementRole: '+',
    designerRole: '+',
    viewRole: '+',
    reportsRole: '-',
  },
  {
    area: 'Reports',
    action: 'manage',
    manageRole: '-',
    constructionRole: '-',
    personalizationRole: '-',
    projectManagementRole: '-',
    designerRole: '-',
    viewRole: '-',
    reportsRole: '+',
  },
  {
    action: 'view',
    manageRole: '-',
    constructionRole: '-',
    personalizationRole: '-',
    projectManagementRole: '-',
    designerRole: '-',
    viewRole: '-',
    reportsRole: '+',
  },
  {
    area: 'Personalization',
    action: 'manage',
    manageRole: '-',
    constructionRole: '-',
    personalizationRole: '+',
    projectManagementRole: '-',
    designerRole: '-',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    action: 'view',
    manageRole: '+',
    constructionRole: '+',
    personalizationRole: '+',
    projectManagementRole: '+',
    designerRole: '+',
    viewRole: '+',
    reportsRole: '-',
  },
  {
    area: 'Personalization Summary',
    action: 'manage',
    manageRole: '-',
    constructionRole: '-',
    personalizationRole: '+',
    projectManagementRole: '-',
    designerRole: '-',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    action: 'view',
    manageRole: '+',
    constructionRole: '+',
    personalizationRole: '+',
    projectManagementRole: '+',
    designerRole: '+',
    viewRole: '+',
    reportsRole: '-',
  },
  {
    area: 'Un-Finalize Path Step',
    action: 'manage',
    manageRole: '-',
    constructionRole: '+',
    personalizationRole: '-',
    projectManagementRole: '-',
    designerRole: '-',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    action: 'view',
    manageRole: '+',
    constructionRole: '+',
    personalizationRole: '+',
    projectManagementRole: '+',
    designerRole: '+',
    viewRole: '+',
    reportsRole: '+',
  },
  {
    area: 'Develop Scope',
    action: 'manage',
    manageRole: '-',
    constructionRole: '-',
    personalizationRole: '-',
    projectManagementRole: '+',
    designerRole: '-',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    action: 'view',
    manageRole: '-',
    constructionRole: '-',
    personalizationRole: '-',
    projectManagementRole: '+',
    designerRole: '-',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    area: 'Task Management',
    action: 'manage',
    manageRole: '-',
    constructionRole: '-',
    personalizationRole: '-',
    projectManagementRole: '+',
    designerRole: '-',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    action: 'view',
    manageRole: '-',
    constructionRole: '-',
    personalizationRole: '-',
    projectManagementRole: '+',
    designerRole: '-',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    area: 'Financial Management',
    action: 'manage',
    manageRole: '-',
    constructionRole: '-',
    personalizationRole: '-',
    projectManagementRole: '+',
    designerRole: '-',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    action: 'view',
    manageRole: '-',
    constructionRole: '-',
    personalizationRole: '-',
    projectManagementRole: '+',
    designerRole: '-',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    area: 'Pricing',
    action: 'manage',
    manageRole: '-',
    constructionRole: '+',
    personalizationRole: '-',
    projectManagementRole: '-',
    designerRole: '-',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    action: 'view',
    manageRole: '-',
    constructionRole: '+',
    personalizationRole: '-',
    projectManagementRole: '-',
    designerRole: '-',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    area: 'Pricing without Base Cost and Owner Price',
    action: 'manage',
    manageRole: '-',
    constructionRole: '+',
    personalizationRole: '-',
    projectManagementRole: '-',
    designerRole: '-',
    viewRole: '-',
    reportsRole: '-',
  },
  {
    action: 'view',
    manageRole: '+',
    constructionRole: '+',
    personalizationRole: '+',
    projectManagementRole: '-',
    designerRole: '-',
    viewRole: '-',
    reportsRole: '-',
  },
];
