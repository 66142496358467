import { Button, Modal } from 'antd';
import { Form } from '@/components';
import { useTranslation } from 'react-i18next';
import { SelectTaskTemplateFormValue, useCommunityContext } from '@/views';
import { SelectTaskTemplateForm } from '@/views';

interface TaskTemplateModalProps {
  title: string;
  onSubmit: (props: SelectTaskTemplateFormValue) => any;
  onClose?: () => any;
}

export function TaskTemplateModal(props: TaskTemplateModalProps) {
  const { title, onSubmit, onClose } = props;
  const { communityId } = useCommunityContext();
  const { t } = useTranslation();

  return communityId ? (
    <Modal
      title = {title}
      visible
      onCancel={onClose}
      footer={[
        <Button key="back" type="default" onClick={onClose}>
          {t('tasks.details.cancel')}
        </Button>,
        <Form.Submit uid="select-task-template" key="submit" type="primary">
          {t('tasks.details.save')}
        </Form.Submit>,
      ]}
    >
      <SelectTaskTemplateForm onSubmit={onSubmit} communityId={communityId} />
    </Modal>
  ) : ( <></> )
}