import { CreatePathTemplateArgs, useAddPathTemplateMutation } from '@/redux';
import { notify } from '@/components';
import { FormPathTemplate } from './Form';
import { useMemo } from 'react';

interface Props {
  organizationId: string;
  communityId?: string;
  onCreated?: () => any;
  onCancel?: () => any;
}

const useSubmit = (props: Props) => {
  const [add] = useAddPathTemplateMutation();

  return (args: CreatePathTemplateArgs) =>
    add(args)
      .unwrap()
      .then(props.onCreated)
      .then(() => notify.success.t('pathTemplates.details.added'));
};

function useValue(props: Props): CreatePathTemplateArgs {
  const { organizationId, communityId } = props;
  return useMemo(
    () => ({ name: null!, steps: [], organizationId, communityId }),
    [communityId, organizationId],
  );
}

export function AddPathTemplatePanel(props: Props) {
  const { organizationId, communityId, onCancel } = props;
  const submit = useSubmit(props);
  const value = useValue(props);

  return (
    <FormPathTemplate<CreatePathTemplateArgs>
      organizationId={organizationId}
      onSubmit={submit}
      value={value}
      communityId={communityId}
      onCancel={onCancel}
      isAdding
    />
  );
}
