import { Http } from '../http/http';
import { guard } from '@/utils';
import { UserNotificationSettings } from './userNotificationSettingsTypes';

class UserNotificationSettingsHttp extends Http {
  public async getUserNotificationSettings(id: string) {
    guard.notNull(id, 'id');
    const url = `/api/user-notification-settings/${id}`;
    const response = await this._axios.get<UserNotificationSettings[]>(url);
    return response.data;
  }

  public async setUserNotificationSettings(request: {
    notificationSettings: UserNotificationSettings[];
    userId: string;
  }) {
    guard.notNull(request, 'request');
    const response = await this._axios.post(
      `/api/user-notification-settings`,
      request,
    );
    return response.data;
  }
}

export const userNotificationSettingsHttp = new UserNotificationSettingsHttp();
