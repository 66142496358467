import {
  CategoryState,
  ItemState,
  useGetCategoriesQuery,
  useGetItemsListQuery,
} from '@/redux';
import { array, tree } from '@/utils';
import { flatten, orderBy, uniqBy } from 'lodash';
import { useMemo } from 'react';
import { ItemTableRowValues } from './ItemListTabPanelGridView';

interface Props {
  communityId: string;
}

interface CategoryItem extends CategoryState {
  parent1?: CategoryState;
  parent2?: CategoryState;
}

function findCategory(categories: CategoryState[], id: string) {
  return tree.findFirst(
    categories,
    id,
    (x) => x.id,
    (x) => x.children,
  )!;
}

function findParentCategory(categories: CategoryState[], id: string) {
  return tree.findFirstParent(
    categories,
    id,
    (x) => x.id,
    (x) => x.children,
  );
}

function useItemsDataSource(items: ItemState[], categories: CategoryState[]) {
  return useMemo(() => {
    const mapped: ItemTableRowValues[] = items.map((item) => {
      const category = findCategory(categories, item.categoryId);
      let parentCategory = findParentCategory(categories, category.id);
      parentCategory = parentCategory ?? category;
      let rootCategory = parentCategory
        ? findParentCategory(categories, parentCategory.id)
        : parentCategory;
      rootCategory = rootCategory ?? parentCategory;

      const categoryPath = uniqBy(
        [rootCategory, parentCategory, category],
        (x) => x.id,
      );

      return {
        categories: categoryPath,
        item: item,
      };
    });

    return mapped;
  }, [categories, items]);
}

function useCategoriesDataSource(
  categories: CategoryState[],
  items: ItemTableRowValues[],
) {
  return useMemo(() => {
    function map(category: CategoryItem): CategoryState[] {
      const { parent1, parent2 } = category;
      const parentCategory = parent2 ?? category;
      const rootCategory = parent1 ?? parentCategory;

      const categoryPath = uniqBy(
        [rootCategory, parentCategory, category],
        (x) => x.id,
      );
      return categoryPath;
    }

    function mapMany(values: CategoryState[]) {
      function project(
        value: CategoryState,
        path: CategoryState[],
      ): CategoryItem[] {
        const nextPath = [...path, value];
        const current = { ...value, parent1: path.at(0), parent2: path.at(1) };
        const children = value.children?.map((child) =>
          project(child, nextPath),
        );
        return flatten([current, ...children]);
      }
      const items = flatten(values.map((v) => project(v, [])));
      return items.map(map);
    }

    const mappedCategories: ItemTableRowValues[] = mapMany(categories).map(
      (x) => ({ categories: x }),
    );
    const itemsCategories = items.map((x) => x.categories);
    const filteredCategories = mappedCategories.filter(
      (categories) =>
        !itemsCategories.some(
          (x) => x.at(-1)!.id === categories.categories.at(-1)!.id,
        ),
    );

    return filteredCategories;
  }, [categories, items]);
}

export function useItemListGridDataSource(props: Props) {
  const { communityId } = props;

  const { data: items = array.empty<ItemState>() } = useGetItemsListQuery({
    communityId,
  });
  const { data: categories = array.empty<CategoryState>(), isLoading } =
    useGetCategoriesQuery({ communityId, listed: true });

  const rowItems = useItemsDataSource(items, categories);
  const rowCategories = useCategoriesDataSource(categories, rowItems);

  return useMemo(() => {
    const rowValues = [...rowItems, ...rowCategories];

    const orderedrowValues = orderBy(
      rowValues,
      [
        (x) => x.categories[0].name,
        (x) => x.categories[1]?.name,
        (x) => x.categories[2]?.name,
        (x) => x.item?.name,
      ],
      ['asc', 'desc', 'desc', 'asc'],
    );

    return {
      items: orderedrowValues,
      loading: isLoading,
    };
  }, [rowCategories, rowItems, isLoading]);
}
