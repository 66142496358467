import { Icon, Page } from '@/components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { WorkspacePanel } from './WorkspacePanel';
import { useEffect } from 'react';

export function ProjectWorkspacePage() {
  const { t } = useTranslation();
  const { id } = useParams<'id'>();
  const { homeId } = useParams<'homeId'>();

  useEffect(() => {
    if (homeId) {
      const currentUrl = window.location.href;
      const newUrl = currentUrl.replace(`/${homeId}`, '');
      window.history.replaceState({}, '', newUrl);
    }
  }, [homeId]);

  return (
    <Page.Card
      title={t('workspace.title')}
      icon={<Icon type="template" />}
      color="#1FB197"
    >
      {id && <WorkspacePanel projectId={id!} homeId={homeId} />}
    </Page.Card>
  );
}
