import { rtkq, RtkqRequest } from '../rtkq';
import { api } from '../api';
import {
  AddCustomLegalLanguageDto,
  CustomLegalLanguageDto,
  CustomLegalLanguageFilterParams,
  customLegalLanguageHttp,
  CustomLegalLanguageLookupDto,
  UpdateLegalLanguageDto,
} from '@/core/http.customLegalLanguage';
import { Paged, Paging } from '@/core';
import { SortResult } from '@/components/Collections.Paging/useSorting';

export type CustomLegalLanguageState = CustomLegalLanguageDto;
export type AddCustomLegalLanguageState = AddCustomLegalLanguageDto;
export type UpdateLegalLanguageArgs = UpdateLegalLanguageDto & {
  paging: Paging;
};
export type CustomLegalLanguageLookupState = CustomLegalLanguageLookupDto;

const customLegalLanguageApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCustomLegalLanguageList: build.query<
      Paged<CustomLegalLanguageState>,
      RtkqRequest<{
        paging: Paging;
        filters?: CustomLegalLanguageFilterParams;
        sorting?: SortResult;
      }>
    >({
      queryFn: async (args) => {
        return rtkq(args).exec(() =>
          customLegalLanguageHttp.getAll(
            args.paging,
            args.filters,
            args.sorting,
          ),
        );
      },
      providesTags: [{ type: 'custom-legal-language', id: 'list' }],
    }),

    updateCustomLegalLanguage: build.mutation<
      CustomLegalLanguageLookupState,
      RtkqRequest<UpdateLegalLanguageArgs>
    >({
      queryFn: async (args) => {
        return rtkq(args).exec(() => customLegalLanguageHttp.update(args));
      },
      invalidatesTags: [{ type: 'custom-legal-language', id: 'list' }],
    }),

    addCustomLegalLanguage: build.mutation<
      string,
      RtkqRequest<AddCustomLegalLanguageState>
    >({
      queryFn: async (args) => {
        return rtkq(args).exec(() => customLegalLanguageHttp.add(args));
      },
      invalidatesTags: [{ type: 'custom-legal-language', id: 'list' }],
    }),

    deleteCustomLegalLanguage: build.mutation<
      void,
      RtkqRequest<{ id: string }>
    >({
      queryFn: async (args) => {
        return rtkq(args).exec(() => customLegalLanguageHttp.delete(args.id));
      },
      invalidatesTags: [{ type: 'custom-legal-language', id: 'list' }],
    }),
  }),
});

export const {
  useGetCustomLegalLanguageListQuery,
  useUpdateCustomLegalLanguageMutation,
  useAddCustomLegalLanguageMutation,
  useDeleteCustomLegalLanguageMutation,
} = customLegalLanguageApi;
