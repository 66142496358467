import { usePricingColumns } from './usePricingColumns';
import { Col, Row, Space, Switch } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { orderBy } from 'lodash';
import { PriceMatrixRowState, useGetPriceMatrixQuery } from '@/redux';
import { useTranslation } from 'react-i18next';
import { useBool } from '@/utils';
import styles from './Pricing.module.scss';
import { Table, useFilters, usePaging } from '@/components';
import { useZoomActions } from './useZoomActions';
import { Assert } from '../Communities.Common/Assert';
import { CommunityPermissions } from '@/core/permissions.communities/communityPermissions';
import { useCopyPricing } from '@/views';
import { Paging, PriceMatrixFilterParams, pagingFrom } from '@/core';
import { usePricingSessionValues } from './usePricingSessionValues';
import { FilterValue } from 'antd/lib/table/interface';

export const PRICING_DEFAULT_PAGE_SIZE_OPTIONS = [
  '10',
  '20',
  '50',
  '100',
  '250',
  '500',
  '1000',
  '2500',
  '5000',
  '10000',
];

interface Props {
  communityId: string;
  menuId: string;
  className?: string;
}

export function usePriceMatrix(props: Props) {
  const { communityId, menuId } = props;

  const { pagingState, savePagingState, filtersState, saveFiltersState } =
    usePricingSessionValues();

  const [items, setItems] = useState<PriceMatrixRowState[]>([]);
  const [total, setTotal] = useState(0);
  const paging = usePaging(
    pagingState.size ? { ...pagingState, total } : { total, size: 10 },
  );
  const [params, setParams] = useState<Paging>({
    skip: 0,
    take: 10,
    includeTotal: true,
  });
  const {
    filters,
    onFiltersChange,
    resetFilters,
    stringFilterOptions,
    setStringFilterOptions,
  } = useFilters<PriceMatrixFilterParams>(
    [
      'category',
      'subcategory1',
      'subcategory2',
      'itemName',
      'floorplan',
      'room',
    ],
    filtersState,
  );

  const { data: matrix } = useGetPriceMatrixQuery({
    communityId,
    menuId,
    paging: params,
    filters,
  });

  useEffect(() => {
    setParams(pagingFrom(paging, true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging.current, paging.pageSize]);

  return useMemo(() => {
    if (matrix) {
      setItems(matrix.rows.items);
      setTotal(matrix.rows.total);
      setStringFilterOptions(matrix.rows.stringFilterOptions);
      savePagingState({
        size: paging.pageSize,
        current: paging.current,
      });
    }

    return {
      rows: orderBy(
        items,
        [
          (x) => x.categories[0].name,
          (x) => x.categories[1]?.name,
          (x) => x.categories[2]?.name,
          (x) => x.itemName,
          (x) => x.itemPricePer,
          (x) => x.floorplan?.marketingName ?? 0,
          (x) => x.room?.name ?? 0,
        ],
        'asc',
      ),
      filterOptions: stringFilterOptions,
      paging,
      filters,
      onFiltersChange: (filterValue: Record<string, FilterValue | null>) => {
        onFiltersChange(filterValue);
        saveFiltersState(filterValue);
      },
      resetFilters: () => {
        resetFilters();
        saveFiltersState({});
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, matrix, filters, paging.pageSize, paging.current]);
}

export function useRowsValues(
  props: Props,
  collapseRoom: boolean,
  collapseFloorplan: boolean,
) {
  const {
    rows: pricing,
    paging,
    filters,
    filterOptions,
    onFiltersChange,
    resetFilters,
  } = usePriceMatrix(props);

  return useMemo(() => {
    if (collapseFloorplan) {
      return {
        pricing: pricing.filter(
          (x) => x.floorplan?.id == null && x.room?.id == null,
        ),
        paging,
        filters,
        filterOptions,
        onFiltersChange,
        resetFilters,
      };
    }
    if (collapseRoom) {
      return {
        pricing: pricing.filter((x) => x.room?.id == null),
        paging,
        filters,
        filterOptions,
        onFiltersChange,
        resetFilters,
      };
    }

    return {
      pricing,
      paging,
      filters,
      filterOptions,
      onFiltersChange,
      resetFilters,
    };
  }, [
    collapseFloorplan,
    collapseRoom,
    pricing,
    paging,
    filters,
    filterOptions,
    onFiltersChange,
    resetFilters,
  ]);
}

export function PricingListPanel(props: Props) {
  const { menuId, className, communityId } = props;
  const {
    collapseCostState,
    collapseFloorplanState,
    collapseRoomState,
    saveCollapseCostState,
    saveCollapseFloorplanState,
    saveCollapseRoomState,
  } = usePricingSessionValues();
  const { t } = useTranslation();
  const [collapseFloorplan, , toggleCollapseFloorplan] = useBool(
    collapseFloorplanState,
  );
  const [collapseRoom, setCollapseRoom, toggleCollapseRoom] =
    useBool(collapseRoomState);
  const [collapseCost, , toggleCollapseCost] = useBool(collapseCostState);

  useEffect(() => {
    if (collapseFloorplan) setCollapseRoom(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapseFloorplan]);

  const {
    pricing,
    paging,
    filters,
    filterOptions,
    onFiltersChange,
    resetFilters,
  } = useRowsValues(props, collapseRoom, collapseFloorplan);

  const [scrollTop, setScrollTop] = useState<number>(0);

  function setScrollValue() {
    if (!!document.querySelector('div.ant-table-body'))
      setScrollTop(document.querySelector('div.ant-table-body')!.scrollTop);
  }

  function scrollToRow() {
    if (
      !!document.querySelector('div.ant-table-body') &&
      document.querySelector('div.ant-table-body')!.scrollTop !== scrollTop
    )
      document.querySelector('div.ant-table-body')!.scrollTop = scrollTop;
  }

  const { zoomActions, zoomIndex } = useZoomActions();

  const copyPricing = useCopyPricing({ currentMenuId: menuId, communityId });

  const columns = usePricingColumns(
    communityId,
    menuId,
    pricing,
    collapseFloorplan,
    collapseRoom,
    collapseCost,
    zoomIndex,
    paging,
    setScrollValue,
    filters,
    filterOptions,
  );

  return (
    <div className={className}>
      <Row>
        <Col span={12}>
          <Space direction="horizontal" size={50}>
            <Space>
              <Switch
                checked={collapseFloorplan}
                onChange={(value) => {
                  toggleCollapseFloorplan();
                  saveCollapseFloorplanState(value);
                }}
              />
              {t('pricing.collapseFloorplan')}
            </Space>
            <Space>
              <Switch
                checked={collapseRoom}
                onChange={(value) => {
                  toggleCollapseRoom();
                  saveCollapseRoomState(value);
                }}
              />
              {t('pricing.collapseRoom')}
            </Space>
            <Assert
              permission={CommunityPermissions.BaseCostAndOwnerPrice.FullAccess}
              active
            >
              <Space direction="horizontal" size={50}>
                <Space>
                  <Switch
                    checked={collapseCost}
                    onChange={(value) => {
                      toggleCollapseCost();
                      saveCollapseCostState(value);
                    }}
                  />
                  {t('pricing.specialRequest.collapseCost')}
                </Space>
              </Space>
            </Assert>
          </Space>
        </Col>
        <Col flex="auto" className="text-end">
          {zoomActions}
        </Col>
      </Row>
      <Table<PriceMatrixRowState>
        actions={copyPricing}
        name="Pricing"
        onDataSourceChange={scrollToRow}
        className={styles.scaledContainer}
        columns={columns}
        dataSource={pricing}
        style={
          {
            '--zoom-index': zoomIndex,
          } as any
        }
        rowKey={(row) => row.itemId + row.floorplan?.id + row.room?.id}
        pagination={{
          ...paging,
          pageSizeOptions: PRICING_DEFAULT_PAGE_SIZE_OPTIONS,
        }}
        scroll={{ x: 'max-content' }}
        onChange={(_, filters) => onFiltersChange(filters)}
        onFiltersReset={resetFilters}
      />
    </div>
  );
}
