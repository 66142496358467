import { formatters } from '@/core';
import { Auth, StyledRow, Title } from '@/components';
import { useGetUserQuery } from '@/redux';
import { UserAvatarEditComponent } from '../Users.General';
import { Col, Row } from 'antd';
import { useUserRoleValues } from '../Users/useUserRoleValues';
import { AuthenticationDetails } from './AuthenticationDetails';
import { UserGeneralDetails } from '../Users.Profile/UserGeneralDetails';

function _UserDetailsPanel({ id }: { id: string }) {
  const { data: user } = useGetUserQuery({ id });
  const roles = useUserRoleValues(user?.organizationId);

  if (user == null) {
    return null;
  }

  return (
    <>
      <StyledRow className="mb-4">
        <Col span={24}>
          <Row align="middle">
            <Col>
              <UserAvatarEditComponent user={user} />
            </Col>
            <Col offset={1}>
              <Title level={2}>{formatters.name(user)}</Title>
            </Col>
          </Row>
        </Col>
      </StyledRow>
      <StyledRow>
        <Col flex="auto">
          <UserGeneralDetails user={user} roles={roles} />
        </Col>
        <Col flex="auto">
          <AuthenticationDetails user={user} />
        </Col>
      </StyledRow>
    </>
  );
}

export const UserDetailsPanel = Auth.Banner(['BFP_ADMIN', 'CLIENT_ADMIN'])(
  _UserDetailsPanel,
);
