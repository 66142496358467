import { useCallback, useState } from 'react';
import { SortOrder, SorterResult } from 'antd/lib/table/interface';
import { isArray } from 'lodash';

export type SortResult = {
  sortBy?: string;
  sortOrder?: SortOrder;
};

export const DefaultNumberSorting: SortResult = {
  sortBy: 'number',
  sortOrder: 'descend',
};

export type UseSortResult<T> = {
  sorting?: SortResult;
  onSortingChange: (args: SorterResult<T> | SorterResult<T>[]) => void;
};

export function useSorting<TSorter>(
  defaultSorting?: SortResult,
): UseSortResult<TSorter> {
  const [sorting, setSorting] = useState<SortResult | undefined>(
    defaultSorting,
  );

  const handleSorterChange = useCallback(
    (args: SorterResult<TSorter> | SorterResult<TSorter>[]) => {
      const sorterResult = isArray(args)
        ? args[0]
        : (args as unknown as SorterResult<TSorter>);
      setSorting({
        sortOrder: sorterResult.order,
        sortBy: sorterResult.columnKey as any,
      });
    },
    [],
  );

  return { sorting, onSortingChange: handleSorterChange };
}
