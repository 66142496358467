import qs from 'qs';
import { Http } from '../http/http';
import { SearchByNumberDto } from './searchByNumberTypes';

class SearchHttp extends Http {
  public async get(entityNumber: string) {
    const query = qs.stringify({ entityNumber });
    const response = await this._axios.get<SearchByNumberDto>(
      `/api/search?${query}`,
    );
    return response.data;
  }
}

export const searchHttp = new SearchHttp();
