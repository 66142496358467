import { useTranslation } from 'react-i18next';
import {
  useUpdateStatusFloorplanMutation,
  useGetFloorplansListQuery,
} from '@/redux';
import {
  confirmation,
  DefaultNumberSorting,
  notify,
  Table,
  TabPanel,
  useDefaultTablePaging,
  useFilters,
  useSorting,
} from '@/components';
import { useEditFloorplanAction, AddFloorplanButton, Assert } from '@/views';
import { useFloorplansColumns } from './useFloorplansColumns';
import {
  FloorplanDto,
  FloorplanStatus,
  CommunityPermissions,
  FloorplanFilterParams,
} from '@/core';
import { useCallback, useMemo } from 'react';

interface FloorplansListPanelProps {
  communityId: string;
}

const useDeactivateAction = () => {
  const [updateStatus] = useUpdateStatusFloorplanMutation();

  return useCallback(
    async (id: string) => {
      const action = async () => {
        await updateStatus({ id, status: FloorplanStatus.Deactivated });
        notify.success.t('floorplans.deactivated');
      };

      confirmation.deactivate(
        { key: 'floorplans.entityName' },
        async () => await action(),
      );
    },
    [updateStatus],
  );
};

export function FloorplansListPanel(props: FloorplansListPanelProps) {
  const { communityId } = props;
  const { t } = useTranslation();
  const { openEdit, editModal } = useEditFloorplanAction();

  const { setTotal, paging, params } = useDefaultTablePaging();
  const {
    filters,
    onFiltersChange,
    resetFilters,
    stringFilterOptions,
    setStringFilterOptions,
  } = useFilters<FloorplanFilterParams>([
    'number',
    'marketingName',
    'constructionName',
    'squareFootage',
  ]);
  const { sorting, onSortingChange } =
    useSorting<FloorplanDto>(DefaultNumberSorting);

  const { data, isFetching } = useGetFloorplansListQuery({
    communityId,
    paging: params,
    filters,
    sorting,
  });

  const floorplans = useMemo(() => {
    if (data) {
      setTotal(data.total);
      setStringFilterOptions(data.stringFilterOptions);
    }
    return data?.items;
  }, [data, setStringFilterOptions, setTotal]);

  const columns = useFloorplansColumns(
    openEdit,
    useDeactivateAction(),
    floorplans,
    stringFilterOptions,
  );

  return (
    <TabPanel>
      <TabPanel.Title> {t('floorplans.title')}</TabPanel.Title>
      <TabPanel.Actions>
        <Assert permission={CommunityPermissions.Floorplans.Manage} active>
          <AddFloorplanButton communityId={communityId} />
        </Assert>
      </TabPanel.Actions>
      <TabPanel.Body>
        <Table<FloorplanDto>
          name="Floorplans"
          loading={isFetching}
          columns={columns}
          dataSource={floorplans}
          rowKey={(row) => row.id}
          pagination={paging}
          onChange={(_, filters, sorting) => {
            onFiltersChange(filters);
            onSortingChange(sorting);
          }}
          onFiltersReset={resetFilters}
        />
        {editModal}
      </TabPanel.Body>
    </TabPanel>
  );
}
