import {
  LookupSource,
  LookupOption,
  createEntireLoadLookupSource,
} from '@/components';
import { RtkqSpin, useLazyGetRoomTypeListQuery } from '@/redux';
import { useMemo } from 'react';

export function mapRoomTypeLookup(value: {
  id: string;
  name: string;
}): LookupOption {
  return {
    label: value.name,
    value: value.id,
  };
}

export function useRoomTypeLookupSource(): LookupSource {
  const [fetchList] = useLazyGetRoomTypeListQuery();

  return useMemo(() => {
    return createEntireLoadLookupSource({
      initial: (id: string) =>
        fetchList({ onlyActive: true, [RtkqSpin]: false }, true)
          .unwrap()
          .then((values) =>
            mapRoomTypeLookup(values.find((x) => x.id === id)!),
          ),
      load: () =>
        fetchList({ onlyActive: true, [RtkqSpin]: false })
          .unwrap()
          .then((values) => values.map(mapRoomTypeLookup)),
    });
  }, [fetchList]);
}
