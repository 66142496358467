import { useField } from 'formik';

import { FormsGroup } from './FormsGroup';
import { useFormLabel } from './FormsI18nContext';
import { useTranslation } from 'react-i18next';
import { Select, SelectProps } from 'antd';
import { useCallback } from 'react';

export interface FormsSelectOption {
  value: string;
  label: string;
}

export type FormsSelectProps = {
  name: string;
  label?: React.ReactNode;
  required?: boolean;
  options: FormsSelectOption[];
  mode?: 'multiple';
  showSearch?: boolean;
  disabled?: boolean;
  allowClear?: boolean;
  placeholder?: string;
  onBlur?: SelectProps['onBlur'];
};

export const FormsSelect = ({
  name,
  label: labelOverride,
  required,
  options,
  mode,
  showSearch = true,
  disabled = false,
  allowClear = true,
  placeholder,
  onBlur,
}: FormsSelectProps) => {
  const { t } = useTranslation();
  const [field, , helpers] = useField(name);
  const label = useFormLabel(name, labelOverride);

  function handleChange(value: string) {
    helpers.setValue(value || null);
  }

  const { onBlur: fieldOnBlur } = field;

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      fieldOnBlur(e);
      onBlur && onBlur(e);
    },
    [onBlur, fieldOnBlur],
  );

  return (
    <FormsGroup label={label} name={name} required={required}>
      <Select
        mode={mode}
        showSearch={showSearch}
        value={field.value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder ?? t('selectPlaceholder')}
        optionFilterProp="children"
        allowClear={allowClear}
        disabled={disabled}
      >
        {options.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </FormsGroup>
  );
};
