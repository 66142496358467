import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddPaymentState, useAddPaymentMutation } from '@/redux';
import { AddButton, notify } from '@/components';
import { PaymentDetailsModal } from './PaymentDetailsModal';

interface Props {
  className?: string;
  homeId: string;
  projectId: string;
}

const useSubmit = (setOpenModal: (value: boolean) => any) => {
  const [add] = useAddPaymentMutation();

  return (args: AddPaymentState) => {
    add(args)
      .unwrap()
      .then((id) => {
        notify.success.t('payments.saved');
      })
      .then(() => setOpenModal(false));
  };
};

export function AddPaymentButton(props: Props) {
  const [openModal, setOpenModal] = useState(false);
  const submit = useSubmit(setOpenModal);
  const { t } = useTranslation();
  const { className, homeId, projectId } = props;

  const initialValue: AddPaymentState = useMemo(
    () => ({
      homeId,
      projectId,
      amount: null!,
      date: null!,
      description: null!,
      paymentType: null!,
      stepId: null!,
    }),
    [homeId, projectId],
  );

  return (
    <div className={className}>
      <AddButton size="small" type="primary" onClick={() => setOpenModal(true)}>
        {t('payments.addButton')}
      </AddButton>
      {openModal && (
        <PaymentDetailsModal
          onSubmit={submit}
          value={initialValue}
          onClose={() => setOpenModal(false)}
        />
      )}
    </div>
  );
}
