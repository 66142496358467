import { TextBox, EditIconButton, Title } from '@/components';
import { useGetSubcontractorDetailsQuery } from '@/redux';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Assert, useEditSubcontractorAction } from '@/views';
import { CommunityPermissions } from '@/core';

interface Props {
  id: string;
}

export function SubcontractorDetailsPanel(props: Props) {
  const { openEdit, editModal } = useEditSubcontractorAction();
  const { id } = props;
  const { data, isLoading } = useGetSubcontractorDetailsQuery({ id });
  const { t } = useTranslation();

  if (isLoading || !data) {
    return null;
  }

  const { name, email, phone, workType } = data;

  return (
    <div>
      <Row>
        <Col span={10}>
          <Title
            actions={
              <Assert
                permission={CommunityPermissions.Subcontractors.Manage}
                active
              >
                <EditIconButton type="link" onClick={() => openEdit(id)} />
              </Assert>
            }
            inlineActions
          >
            {t('subcontractors.details.general')}
          </Title>
          <TextBox label={t('subcontractors.details.name')}>{name}</TextBox>
          <TextBox label={t('subcontractors.details.email')}>{email}</TextBox>
          <TextBox label={t('subcontractors.details.contactPhone')}>
            {phone}
          </TextBox>
          <TextBox label={t('subcontractors.details.workType')}>
            {workType}
          </TextBox>
        </Col>
      </Row>
      {editModal}
    </div>
  );
}
