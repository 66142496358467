import { Form } from '@/components';
import { FormsSelectOption } from '@/components/Forms/FormsSelect';
import { useAppActivityKinds } from '@/core';
import { useActivityKindTranslation } from '@/views/Activity/useActivityKindTranslation';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

function useKinds(): FormsSelectOption[] {
  const tKind = useActivityKindTranslation();

  const {
    state: { values: kinds },
    fetch,
  } = useAppActivityKinds();

  useEffect(() => {
    fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(
    () =>
      kinds.map((kind) => ({
        value: kind,
        label: tKind(kind),
      })),
    [tKind, kinds],
  );
}

export interface ActivityKindSelectProps {
  name: string;
}

export function ActivityKindSelect(props: ActivityKindSelectProps) {
  const { name } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'activity.table.filter.placeholders',
  });
  const kinds = useKinds();

  return (
    <Form.Select
      name={name}
      options={kinds}
      placeholder={t('key')}
      label={false}
      mode="multiple"
    />
  );
}
