import { rtkq, RtkqRequest } from '../rtkq';
import { api } from '../api';
import {
  UserNotificationSettings,
  userNotificationSettingsHttp,
} from '@/core/http.userNotificationSettings';

export type UserNotificationSettingsState = UserNotificationSettings;

const userNotificationSettingsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserNotificationSettings: build.query<
      UserNotificationSettingsState[],
      RtkqRequest<{ userId: string }>
    >({
      queryFn: async (args) => {
        const { userId } = args;

        return rtkq(args).exec(() =>
          userNotificationSettingsHttp.getUserNotificationSettings(userId),
        );
      },
      providesTags: (_, __, { userId }) => [
        {
          type: 'userNotificationSettings',
          id: userId,
        },
      ],
    }),
    setUserNotificationSettings: build.mutation<
      void,
      RtkqRequest<{
        notificationSettings: UserNotificationSettingsState[];
        userId: string;
      }>
    >({
      queryFn: async (args) => {
        return await rtkq(args).exec(() =>
          userNotificationSettingsHttp.setUserNotificationSettings(args),
        );
      },
      invalidatesTags: (_, __, { userId }) => [
        { type: 'userNotificationSettings', id: userId },
      ],
    }),
  }),
});

export const {
  useGetUserNotificationSettingsQuery,
  useSetUserNotificationSettingsMutation,
} = userNotificationSettingsApi;
