import { Attach } from '@/components';
import { useAppClippo } from '@/core';
import { useBool } from '@/utils';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { SettingOutlined } from '@ant-design/icons';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { api } from '@/redux/api';
import { useGetReportTemplateOwnerId } from './useReportExcelTemplateLookupSource';

function ManageReportExcelTemplatesModal(props: { onClose: () => any }) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'reports.templates.manage',
  });
  const { onClose: onCloseProp } = props;
  const dispatch = useDispatch();

  const ownerId = useGetReportTemplateOwnerId();

  const clippo = useAppClippo({
    ownerId,
    ownerType: 'ReportExcelTemplate',
    defer: false,
  });

  const onClose = useCallback(() => {
    dispatch(api.util.invalidateTags(['report-templates']));
    onCloseProp();
  }, [onCloseProp, dispatch]);

  return (
    <Modal
      title={t('title')}
      visible
      onCancel={onClose}
      footer={[
        <Button key="back" type="default" onClick={onClose}>
          {t('cancel')}
        </Button>,
      ]}
    >
      <Attach
        {...clippo}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
    </Modal>
  );
}

export function ManageReportExcelTemplatesButton({
  className,
}: {
  className?: string;
}) {
  const [showModal, , toggleShowModal] = useBool(false);

  return (
    <>
      <Button
        className={className}
        onClick={toggleShowModal}
        type="link"
        icon={<SettingOutlined />}
      />
      {showModal && (
        <ManageReportExcelTemplatesModal onClose={toggleShowModal} />
      )}
    </>
  );
}
