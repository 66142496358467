import { useTranslation } from 'react-i18next';
import { Alert } from 'antd';
import { useGetProjectHomeInfoQuery } from '@/redux';
import moment from 'moment';

interface Props {
  projectId: string;
  homeId: string;
  className?: string;
}

export const FinalizationAlert = (props: Props) => {
  const { className, projectId, homeId } = props;
  const { t } = useTranslation();
  const { data } = useGetProjectHomeInfoQuery({
    id: projectId,
    homeId,
  });

  if (!data || !data.finalizedAt) return null;

  return (
    <Alert
      className={className}
      message={t('personalization.summary.finalizationAlert', {
        date: moment(data.finalizedAt).format('L'),
      })}
      type="success"
      showIcon
    />
  );
};
