import { flippoClient } from '@/core';
import { UploadRequestOption } from 'rc-upload/lib/interface';

export function useFileUpload(onChange: (value?: string | null) => any) {
  const handleUpload = async (options: UploadRequestOption) => {
    const { file, onSuccess } = options;

    const reference = await flippoClient.store(
      new File([file], options.filename!),
    );

    onChange(reference);
    onSuccess && onSuccess('Ok');
  };

  const handleRemove = () => {
    onChange(null);
  };

  return {
    handleUpload,
    handleRemove,
  };
}
