import { useCallback, useState } from 'react';
import { FilterValue } from 'antd/lib/table/interface';
import { CommonFilterType, StringFilterOptions } from '@/core';
export const SEPARATOR = '///';
export type UseFiltersResult<T> = {
  onFiltersChange: (filters: Record<string, FilterValue | null>) => void;
  filters?: T;
  resetFilters: () => void;
  stringFilterOptions?: StringFilterOptions;
  setStringFilterOptions: (value: StringFilterOptions) => void;
};

export function useFilters<TFilters extends CommonFilterType>(
  stringFilterColumnKeys: string[],
  defaultFilters?: TFilters,
  rangeKeys?: string[],
): UseFiltersResult<TFilters> {
  const [filters, setFilters] = useState<TFilters | undefined>(
    defaultFilters
      ? { ...defaultFilters, stringFilterColumnKeys }
      : ({ stringFilterColumnKeys } as TFilters),
  );

  const [stringFilterOptions, setStringFilterOptions] = useState<
    StringFilterOptions | undefined
  >();

  const handleFiltersChange = useCallback(
    (args: Record<string, FilterValue | null>) => {
      const nonEmptyFilters = Object.fromEntries(
        Object.entries(args).filter(([_, value]) => value != null),
      ) as unknown as TFilters;

      if (rangeKeys) {
        const filteredRangeObjects: Record<string, FilterValue> = {};
        Object.entries(args).forEach(([key, value]) => {
          if (rangeKeys && rangeKeys.includes(key) && value) {
            const [start, end] = value.toString().split(SEPARATOR);
            filteredRangeObjects[key + 'From'] =
              start as unknown as FilterValue;
            filteredRangeObjects[key + 'To'] = end as unknown as FilterValue;
          }
        });

        setFilters({
          ...nonEmptyFilters,
          ...filteredRangeObjects,
        } as unknown as TFilters);
      } else
        setFilters({
          ...nonEmptyFilters,
        } as unknown as TFilters);
    },
    [rangeKeys],
  );

  const resetFilters = useCallback(() => {
    setFilters(undefined);
  }, []);

  const handleStringFilterOptions = useCallback(
    (value: StringFilterOptions) => {
      Object.keys(value).length > 0 && setStringFilterOptions(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    filters,
    stringFilterOptions,
    onFiltersChange: handleFiltersChange,
    resetFilters,
    setStringFilterOptions: handleStringFilterOptions,
  };
}
