import { Title, TextBox } from '@/components';
import { UserState, useUserContextSelector } from '@/redux';
import { useTranslation } from 'react-i18next';
import { ResetPasswordButton } from './ResetPasswordButton';
import { ChangePasswordButton } from './ChangePasswordButton';

export function AuthenticationDetails({ user }: { user: UserState }) {
  const { t } = useTranslation();
  const [id, isBFP] = useUserContextSelector((x) => [x.id, x.isBFP]);
  if (id !== user.id && !isBFP) return null;
  return (
    <>
      <Title>{t('users.details.authentication.title')}</Title>
      <TextBox label={t('password')}>
        {id === user.id && <ChangePasswordButton />}
        {id !== user.id && <ResetPasswordButton user={user} />}
      </TextBox>
    </>
  );
}
