import { EnumValue } from '@/components';
import { EnumObject, enumValues } from '@/utils';
import { Checkbox, Space, SpaceProps, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useField } from 'formik';
import { useCallback } from 'react';

interface AdditionalOptionCheckBoxGroupProps<TValue extends readonly string[]> {
  valueType: EnumObject<TValue>;
  values?: TValue[number][];
  enabledValues?: TValue[number][];
  fieldName: string;
  direction?: SpaceProps['direction'];
}

export function AdditionalOptionCheckBoxGroup<TValue extends readonly string[]>(
  props: AdditionalOptionCheckBoxGroupProps<TValue>,
) {
  const {
    valueType,
    fieldName,
    values: valuesProp,
    direction = 'vertical',
    enabledValues,
  } = props;
  const [{ value }, , { setValue }] = useField<TValue>(fieldName);
  const values = valuesProp ?? enumValues(valueType);

  const isDisabled = useCallback(
    (item: TValue[number]) => enabledValues && !enabledValues?.includes(item),
    [enabledValues],
  );

  const handleChange = (e: CheckboxChangeEvent, item: TValue[number]) => {
    return e.target.checked
      ? setValue([...value, item] as any as TValue)
      : setValue(value.filter((x) => x !== item) as any as TValue);
  };

  return (
    <Space direction={direction}>
      {values.map((item) => (
        <Checkbox
          key={item}
          checked={value.includes(item)}
          disabled={isDisabled(item)}
          onChange={(e) => handleChange(e, item)}
        >
          <EnumValue type={valueType} value={item} />
        </Checkbox>
      ))}
    </Space>
  );
}

interface AdditionalOptionCheckBoxGroupSectionProps<
  TValue extends readonly string[],
> extends AdditionalOptionCheckBoxGroupProps<TValue> {
  title?: React.ReactNode;
  className?: string;
}

export function AdditionalOptionCheckBoxGroupSection<
  TValue extends readonly string[],
>(props: AdditionalOptionCheckBoxGroupSectionProps<TValue>) {
  const { title, className, ...checkboxGroupProps } = props;
  return (
    <div>
      <Typography.Title level={5} className={className}>
        {title}
      </Typography.Title>

      <AdditionalOptionCheckBoxGroup {...checkboxGroupProps} />
    </div>
  );
}
