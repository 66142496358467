import { HomePriceMatrixRowState, useGetHomePriceMatrixQuery } from '@/redux';
import { TableProps } from 'antd';
import { useMemo, useState } from 'react';
import { usePricingHomeOverrideColumns } from './usePricingHomeOverrideColumns';
import { isEmpty, orderBy } from 'lodash';
import { HomePriceMatrixFilterParams, HomePriceMatrixRowDto } from '@/core';

import { Table, useDefaultTablePaging, useFilters } from '@/components';
import { PRICING_DEFAULT_PAGE_SIZE_OPTIONS } from '../Pricing.List';

interface Props {
  communityId: string;
  homeIds?: string[];
  className?: string;
}

function useRowValues(props: Props) {
  const { homeIds } = props;

  const { setTotal, paging, params } = useDefaultTablePaging();
  const {
    filters,
    onFiltersChange,
    resetFilters,
    stringFilterOptions,
    setStringFilterOptions,
  } = useFilters<HomePriceMatrixFilterParams>([
    'homeName',
    'category',
    'subcategory1',
    'subcategory2',
    'itemName',
    'floorplan',
    'room',
  ]);

  const { data } = useGetHomePriceMatrixQuery(
    {
      homeIds: homeIds ?? [],
      paging: params,
      filters,
    },
    { skip: !homeIds || homeIds.length < 1 },
  );

  const items = useMemo(() => {
    if (data) {
      setTotal(data.rows.total);
      setStringFilterOptions(data.rows.stringFilterOptions);
    }
    if (!homeIds || homeIds.length < 1) {
      setTotal(0);
      return [];
    }
    return data?.rows.items;
  }, [data, homeIds, setStringFilterOptions, setTotal]);

  const ordered = useOrderedValues(items || []);

  return {
    pricing:
      homeIds?.length! > 0
        ? ordered.filter((x) => homeIds?.includes(x.home.id))
        : ordered,
    paging: paging,
    filterOptions: stringFilterOptions,
    onFiltersChange,
    resetFilters,
  };
}

function useOrderedValues(rows: HomePriceMatrixRowDto[]) {
  return useMemo(() => {
    return orderBy(
      rows,
      [
        (x) => x.home.id,
        (x) => x.categories[0].name,
        (x) => x.categories[1]?.name,
        (x) => x.categories[2]?.name,
        (x) => x.itemName,
        (x) => x.itemPricePer,
        (x) => x.room?.name ?? 0,
      ],
      'asc',
    );
  }, [rows]);
}

export function PricingHomeOverrideListPanel(props: Props) {
  const { communityId, className } = props;
  const { pricing, paging, onFiltersChange, resetFilters, filterOptions } =
    useRowValues(props);

  const [currentData, setCurrentData] = useState<HomePriceMatrixRowState[]>([]);

  const handleChange: TableProps<HomePriceMatrixRowState>['onChange'] = (
    _pagination,
    _filters,
    _sorter,
    extra,
  ) => {
    onFiltersChange(_filters);
    setCurrentData(extra.currentDataSource);
  };

  const data = useMemo(
    () =>
      !isEmpty(currentData)
        ? currentData.flatMap((c) =>
            pricing.filter(
              (p) =>
                c.categories.at(-1)!.id === p.categories.at(-1)!.id &&
                c.itemId === p.itemId &&
                c.home.id === p.home.id &&
                c.room?.id === p.room?.id,
            ),
          )
        : pricing,
    [currentData, pricing],
  );

  const columns = usePricingHomeOverrideColumns(
    communityId,
    data,
    filterOptions,
  );

  return (
    <div className={className}>
      <Table<HomePriceMatrixRowState>
        name="HomeOverridesPricing"
        onChange={handleChange}
        onFiltersReset={resetFilters}
        columns={columns}
        dataSource={pricing}
        rowKey={(row) => row.itemId + row.home.id + row.room?.id}
        pagination={{
          ...paging,
          pageSizeOptions: PRICING_DEFAULT_PAGE_SIZE_OPTIONS,
        }}
        scroll={{ x: 1000 }}
      />
    </div>
  );
}
