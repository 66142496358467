import { notify } from '@/components';
import { AddBookletLinkDto, BookletLinkDto, useAppClippo } from '@/core';
import {
  SubOptionState,
  useAddSuboptionBookletLinkMutation,
  useDeleteSuboptionBookletLinkMutation,
} from '@/redux';
import { openPdfWithPageInNewWindow } from '@/utils';
import { ClipContentDto } from '@webinex/clippo';
import { useCallback } from 'react';
import { BookletLinks } from '../Items.BookletLink/BookletLinks';

const useBookletLinkOpen = (
  content: (id: string) => Promise<ClipContentDto>,
) => {
  return useCallback(
    async (bookletLink: BookletLinkDto) => {
      const clip = await content(bookletLink.attachmentId);
      openPdfWithPageInNewWindow(
        {
          fileName: clip.fileName,
          blob: clip.blob,
        },
        bookletLink.page,
      );
    },
    [content],
  );
};

const useBookletLinkSubmit = (suboptionId: string) => {
  const [add] = useAddSuboptionBookletLinkMutation();

  return (args: AddBookletLinkDto) =>
    add({ ...args, suboptionId })
      .unwrap()
      .then(() => notify.success.t('bookletLinks.added'));
};

const useBookletLinkDelete = (suboptionId: string) => {
  const [remove] = useDeleteSuboptionBookletLinkMutation();

  return (id: string) =>
    remove({ suboptionId, id })
      .unwrap()
      .then(() => notify.success.t('bookletLinks.deleted'));
};

interface Props {
  suboption: SubOptionState;
  readonly?: boolean;
}

export function SubOptionBookletLinks(props: Props) {
  const { suboption, readonly } = props;
  const bookletLinkSubmit = useBookletLinkSubmit(suboption.id);
  const onBookletLinkDelete = useBookletLinkDelete(suboption.id);

  const { value, content } = useAppClippo({
    defer: false,
    ownerId: suboption.communityId,
    ownerType: 'CommunityAmenities',
  });

  const handleOpen = useBookletLinkOpen(content);

  return (
    <BookletLinks
      readonly={readonly}
      onOpen={handleOpen}
      bookletLinks={suboption.bookletLinks}
      attachments={value.items}
      onCreate={bookletLinkSubmit}
      onDelete={onBookletLinkDelete}
    />
  );
}
