import { Form } from '@/components';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { AssignFloorplanToHomeDto, isChooseExistingOption } from '@/core';
import { useSubmitAssignFloorplanToHome } from './AssignFloorplanToHomeInModal';
import { AddFloorplanToHomeInForm } from './AddFloorplanToHomeInForm';

export function AddFloorplanModal(props: {
  communityId: string;
  homeId: string;
  onClose: () => void;
}) {
  const { communityId, homeId, onClose } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'projects.homeList.assignFloorplan',
  });
  const { submitAdd, submitAssign } = useSubmitAssignFloorplanToHome({
    homeId,
    communityId,
  });

  const handleSubmit = (floorplanData: AssignFloorplanToHomeDto) => {
    isChooseExistingOption(floorplanData.creationOption)
      ? submitAssign(floorplanData)
      : submitAdd({ ...floorplanData });
    onClose();
  };
  return (
    <Modal
      title={t('title')}
      visible
      onCancel={onClose}
      footer={[
        <Button key="back" type="default" onClick={onClose}>
          {t('cancel')}
        </Button>,
        <Form.Submit uid="home-floorplan" key="submit" type="primary">
          {t('buttonSaveName')}
        </Form.Submit>,
      ]}
    >
      <AddFloorplanToHomeInForm
        communityId={communityId}
        homeId={homeId}
        onSubmit={(floorplan: AssignFloorplanToHomeDto) => {
          handleSubmit(floorplan);
        }}
      />
    </Modal>
  );
}
