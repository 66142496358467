import { rtkq, RtkqRequest } from '../rtkq';
import {
  AddClientDto,
  clientsHttp,
  ClientDto,
  UpdateClientDto,
  Paged,
  Paging,
  ClientFilterParams,
} from '@/core';
import { api } from '../api';
import { SortResult } from '@/components/Collections.Paging/useSorting';

export type ClientState = ClientDto;
export type AddClientArgs = AddClientDto;
export type UpdateClientArgs = UpdateClientDto;

const clientsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getClientsList: build.query<
      Paged<ClientState>,
      RtkqRequest<{
        paging: Paging;
        filters?: ClientFilterParams;
        sorting?: SortResult;
      }>
    >({
      queryFn: async (args) => {
        return rtkq(args).exec(() =>
          clientsHttp.getAll(args.paging, args.filters, args.sorting),
        );
      },
      providesTags: (result) => [
        { type: 'client', id: 'list' },
        ...(result?.items || []).map(({ id }) => ({
          type: 'client' as const,
          id,
        })),
      ],
    }),

    getClientDetails: build.query<ClientState, RtkqRequest<{ id: string }>>({
      queryFn: async (args) => {
        const { id } = args;
        return await rtkq(args).exec(() => clientsHttp.get(id));
      },
      providesTags: (client) => [{ type: 'client', id: client?.id ?? 'none' }],
    }),

    updateClient: build.mutation<void, RtkqRequest<UpdateClientArgs>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => clientsHttp.update(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'client', id: 'list' },
        { type: 'client', id: args.id },
      ],
    }),

    addClient: build.mutation<string, RtkqRequest<AddClientArgs>>({
      queryFn: (args) => {
        args.contacts = args.contacts.filter((x) => x.name);
        return rtkq(args).exec(() => clientsHttp.create(args));
      },
      invalidatesTags: () => [{ type: 'client', id: 'list' }],
    }),

    removeClient: build.mutation<void, RtkqRequest<{ id: string }>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => clientsHttp.remove(args.id));
      },
      invalidatesTags: () => [{ type: 'client', id: 'list' }],
    }),
  }),
});

export const {
  useLazyGetClientsListQuery,
  useGetClientsListQuery,
  useAddClientMutation,
  useGetClientDetailsQuery,
  useLazyGetClientDetailsQuery,
  useUpdateClientMutation,
  useRemoveClientMutation,
} = clientsApi;
