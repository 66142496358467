import { rtkq, RtkqRequest } from '../rtkq';
import {
  AddFloorplanDto,
  AssignFloorplanToHomeDto,
  AttachmentDto,
  FloorplanDetailsDto,
  FloorplanDto,
  FloorplanFilterParams,
  floorplansHttp,
  FloorplanStatus,
  Paged,
  Paging,
  UpdateFloorplanDto,
  UpdateWholeHomeFloorplanDto,
} from '@/core';
import { api } from '../api';
import { StoreClipDto } from '@webinex/clippo';
import { SortResult } from '@/components/Collections.Paging/useSorting';

export type UpdateFloorplanArgs = UpdateFloorplanDto;
export type UpdateWholeHomeFloorplanArgs = UpdateWholeHomeFloorplanDto;
export type AddFloorplanArgs = AddFloorplanDto;
export type AssignFloorplanToHomeArgs = AssignFloorplanToHomeDto;
export type FloorplanState = FloorplanDto;
export type FloorplanDetailsState = FloorplanDetailsDto;

const floorplansApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFloorplansList: build.query<
      Paged<FloorplanState>,
      RtkqRequest<{
        communityId: string;
        onlyActive?: boolean;
        paging: Paging;
        filters?: FloorplanFilterParams;
        sorting?: SortResult;
      }>
    >({
      queryFn: async (args) => {
        return await rtkq(args).exec(() => floorplansHttp.getAll(args));
      },
      providesTags: (result) => [
        { type: 'floorplan', id: 'list' },
        ...(result?.items || []).map(({ id }) => ({
          type: 'floorplan' as const,
          id,
        })),
      ],
    }),

    getFloorplanDetails: build.query<
      FloorplanDetailsState,
      RtkqRequest<{ id: string }>
    >({
      queryFn: async (args) => {
        const { id } = args;
        return await rtkq(args).exec(() => floorplansHttp.get(id));
      },
      providesTags: (floorplan) => [
        { type: 'floorplan', id: floorplan?.id ?? 'none' },
      ],
    }),

    updateFloorplan: build.mutation<void, RtkqRequest<UpdateFloorplanArgs>>({
      queryFn: async (args) => {
        return await rtkq(args).exec(() => floorplansHttp.update(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'floorplan', id: 'list' },
        { type: 'floorplan', id: args.id },
      ],
    }),

    updateWholeHomeFloorplan: build.mutation<
      void,
      RtkqRequest<UpdateWholeHomeFloorplanArgs>
    >({
      queryFn: async (args) => {
        return await rtkq(args).exec(() =>
          floorplansHttp.updateWholeHome(args),
        );
      },
      invalidatesTags: (_, __, args) => [
        { type: 'floorplan', id: args.id },
        { type: 'personalizationItem-category', id: 'list' },
        { type: 'personalizationItem', id: 'list' },
        { type: 'personalization-item-price', id: 'list' },
        ...args.categories.map((roomCategory) => ({
          type: 'category' as const,
          id: roomCategory.id,
        })),
      ],
    }),

    addFloorplan: build.mutation<string, RtkqRequest<AddFloorplanArgs>>({
      queryFn: async (args) => {
        return await rtkq(args).exec(() => floorplansHttp.create(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'floorplan', id: 'list' },
        { type: 'home', id: args.homeId ?? 'none' },
        { type: 'projectHomeInfo', id: args.homeId ?? 'none' },
      ],
    }),

    uploadImage: build.mutation<AttachmentDto, RtkqRequest<StoreClipDto>>({
      queryFn: async (args) => {
        return await rtkq(args).exec(() => floorplansHttp.store(args));
      },
    }),

    updateStatusFloorplan: build.mutation<
      string,
      RtkqRequest<{ id: string; status: FloorplanStatus }>
    >({
      queryFn: (args) => {
        const { id, status } = args;
        return rtkq(args).exec(() => floorplansHttp.updateStatus(id, status));
      },
      invalidatesTags: () => [{ type: 'floorplan', id: 'list' }],
    }),

    getFloorplanDetailsByHome: build.query<
      FloorplanDetailsState | undefined,
      RtkqRequest<{ homeId: string }>
    >({
      queryFn: (args) => {
        const { homeId } = args;
        return rtkq(args).exec(() => floorplansHttp.byHome(homeId));
      },
      providesTags: (floorplan, _, args) => [
        { type: 'floorplan', id: floorplan?.id ?? 'none' },
        { type: 'home', id: args.homeId },
        { type: 'home', id: 'personalization-' + args.homeId },
      ],
    }),
  }),
});

export const {
  useGetFloorplansListQuery,
  useLazyGetFloorplansListQuery,
  useAddFloorplanMutation,
  useUploadImageMutation,
  useGetFloorplanDetailsQuery,
  useLazyGetFloorplanDetailsQuery,
  useUpdateFloorplanMutation,
  useUpdateWholeHomeFloorplanMutation,
  useUpdateStatusFloorplanMutation,
  useGetFloorplanDetailsByHomeQuery,
} = floorplansApi;
