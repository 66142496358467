import { useTranslation } from 'react-i18next';
import { Page } from '@/components';
import { useParams } from 'react-router-dom';
import { useGetTaskDetailsQuery } from '@/redux';
import { TaskDetailsPanel } from '@/views';
import { TaskNavigationPanel } from '@/views/Tasks.Details/TaskNavigationPanel';

export function TaskDetailsPage() {
  const { t } = useTranslation();
  const { taskId } = useParams<'taskId'>();
  const { data } = useGetTaskDetailsQuery({ id: taskId! });
  const title = data?.name ?? t('fetchFallbackTitle');

  if (!data) return null;

  return (
    <div>
      {taskId && (
        <TaskNavigationPanel
          id={taskId}
          projectId={data.projectId}
          className="mb-3"
        />
      )}
      <Page.Card title={title}>
        {taskId && <TaskDetailsPanel taskId={taskId!} />}
      </Page.Card>
    </div>
  );
}
