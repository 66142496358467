import { Col, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  PersonalizationItemState,
  ProjectStepLookupState,
  useAddPersonalizationItemMutation,
  useDeletePersonalizationItemMutation,
  useGetProjectHomeInfoQuery,
  useUpdateFloorplanPersonalizationNeededStateMutation,
  useUpdatePersonalizationItemMutation,
} from '@/redux';
import { confirmation, notify, StyledRow, Title } from '@/components';
import {
  AddSpecialRequestButton,
  Assert,
  FloorplanGeoPersonalizationPanel,
  PersonalizationList,
  PersonalizationSteps,
  useCommunityContext,
  useDeleteSpecialRequest,
  useIsFinalized,
  usePersonalizationSteps,
  useProjectContext,
} from '@/views';
import {
  AddPersonalizationItemButton,
  PersonalizationItemFormValue,
  useEditPersonalizationItemAction,
} from '@/views/Personalization.Details/Item.Details';
import { useCallback } from 'react';
import { CommunityPermissions } from '@/core';
import {
  FloorplanPersonalizationNeededSwitch,
  useFloorplanPersonalizationNeededValue,
} from './FloorplanPersonalizationNeededSwitch';
import { FloorplanPersonalizationImagesControl } from './FloorplanPersonalizationImagesControl';
import { useEditSpecialRequestAction } from './SpecialRequest/EditSpecialRequestButton';
import { isArray } from 'lodash';

interface Props {
  projectId: string;
  homeId: string;
  communityId: string;
  compact?: boolean;
}

const useUpdateFloorplanPersonalizationNeededState = (props: Props) => {
  const [update] = useUpdateFloorplanPersonalizationNeededStateMutation();
  const { homeId, projectId } = props;
  const { t } = useTranslation();

  return useCallback(
    (currentValue: boolean, stepId?: string) => {
      const perform = () =>
        update({ homeId, projectId, value: !currentValue, stepId })
          .unwrap()
          .then(() => notify.success.t('personalization.updated'));

      if (currentValue) {
        confirmation.show({
          title: t('confirmation.delete.title'),
          body: t('personalization.clearConfirmation'),
          onConfirm: perform,
        });
      } else {
        perform();
      }
    },
    [t, update, homeId, projectId],
  );
};

const useUpdate = () => {
  const [update] = useUpdatePersonalizationItemMutation();

  return (args: PersonalizationItemFormValue, item: PersonalizationItemState) =>
    update({ ...args, id: item!.id })
      .unwrap()
      .then(() =>
        notify.success.t('personalization.item.details.detailsSaved'),
      );
};

const useAdd = (
  homeId: string,
  stepId: string | undefined,
  projectId: string,
) => {
  const [add] = useAddPersonalizationItemMutation();
  return (args: PersonalizationItemFormValue) => {
    add({
      ...args,
      stepId: stepId!,
      homeId,
      position: { x: 10, y: 10 },
      projectId,
      isFloorplan: true,
    })
      .unwrap()
      .then(() => notify.success.t('personalization.item.details.saved'));
  };
};

const useDelete = () => {
  const [deleteItem] = useDeletePersonalizationItemMutation();

  return (id: string) => {
    deleteItem({ id })
      .unwrap()
      .then(() => notify.success.t('personalization.item.details.deleted'));
  };
};

export const useStepDeadlinePassed = (
  homeId: string,
  projectId: string,
  selectedStep?: ProjectStepLookupState,
) => {
  const { data: context } = useGetProjectHomeInfoQuery({
    id: projectId,
    homeId,
  });

  if (!selectedStep || !context) return null;
  return context.steps.find((x) => x.stepId === selectedStep.id)
    ?.isDeadlinePassed;
};

export function FloorplanPersonalizationPanel(props: Props) {
  const { homeId, projectId, communityId, compact } = props;
  const { assert } = useCommunityContext();
  const steps = usePersonalizationSteps({ projectId });
  const { selected } = steps;
  const selectedStepId = isArray(selected) ? selected[0] : selected;
  const selectedStep = steps.steps.find((x) => x.id === selectedStepId);
  const { isActive } = useProjectContext();
  const { modal: editItemModal, open: openItemEdit } =
    useEditPersonalizationItemAction(useUpdate());

  const { modal: editSpecialRequestModal, open: openSpecialRequestEdit } =
    useEditSpecialRequestAction();

  const handleSpecialRequestDelete = useDeleteSpecialRequest();
  const handleAdd = useAdd(homeId, selectedStep?.id, projectId);
  const handleDelete = useDelete();
  const handleUpdatePersonalizationNeeded =
    useUpdateFloorplanPersonalizationNeededState(props);
  const checkedValue = useFloorplanPersonalizationNeededValue(
    homeId,
    projectId,
    selectedStep?.id,
  );

  const stepDeadlinePassed = useStepDeadlinePassed(
    homeId,
    projectId,
    selectedStep,
  );

  const isStepFinalized = useIsFinalized(homeId, projectId, selectedStep?.id);
  const isHomeFinalized = useIsFinalized(homeId, projectId);

  const readonly =
    selectedStep?.id === undefined ||
    checkedValue === null ||
    !isActive ||
    checkedValue ||
    stepDeadlinePassed === null ||
    stepDeadlinePassed ||
    !!isStepFinalized;

  const itemManagementRestricted =
    readonly || !assert(CommunityPermissions.Personalization.Manage, true);

  const personalizationNeededSwitchVisible =
    (selectedStep && !isStepFinalized) || (!selectedStep && !isHomeFinalized);

  const { t } = useTranslation();

  return (
    <>
      {compact && (
        <Title level={1}>
          <Row>{t('personalization.title')}</Row>
        </Title>
      )}

      <Space direction="vertical" size="large" className="w-100">
        <Row justify="space-between">
          <Col
            xl={compact ? 20 : 6}
            md={compact ? 20 : 8}
            xs={20}
            className="mb-2"
          >
            <PersonalizationSteps {...steps} />
          </Col>
          <Col className="text-end">
            {!itemManagementRestricted && (
              <Space wrap>
                <AddPersonalizationItemButton
                  homeId={homeId}
                  communityId={communityId}
                  floorplanPersonalization
                  projectId={projectId}
                  step={selectedStep}
                  onlyGeoItems
                  onSubmit={handleAdd}
                />
                <AddSpecialRequestButton
                  stepId={selectedStep?.id}
                  homeId={homeId}
                  projectId={projectId}
                  isFloorplan
                />
              </Space>
            )}
          </Col>
        </Row>
        <StyledRow justify="space-between">
          <Col>
            <Assert
              permission={CommunityPermissions.Personalization.Manage}
              active
            >
              <Space direction="vertical" size="large">
                {!compact && personalizationNeededSwitchVisible && (
                  <FloorplanPersonalizationNeededSwitch
                    homeId={homeId}
                    projectId={projectId}
                    handleUpdatePersonalizationNeeded={
                      handleUpdatePersonalizationNeeded
                    }
                    selectedStep={selectedStep?.id}
                  />
                )}
                {!isHomeFinalized && (
                  <FloorplanPersonalizationImagesControl
                    projectId={projectId}
                    homeId={homeId}
                    className="pb-2"
                  />
                )}
              </Space>
            </Assert>
          </Col>
        </StyledRow>
      </Space>

      <FloorplanGeoPersonalizationPanel
        readonly={itemManagementRestricted}
        homeId={homeId}
        projectId={projectId}
        onItemEdit={openItemEdit}
        onSpecialRequestEdit={openSpecialRequestEdit}
        selectedStepId={selectedStep?.id}
        onItemDelete={handleDelete}
        onSpecialRequestDelete={handleSpecialRequestDelete}
      />
      {compact && personalizationNeededSwitchVisible && (
        <FloorplanPersonalizationNeededSwitch
          homeId={homeId}
          projectId={projectId}
          handleUpdatePersonalizationNeeded={handleUpdatePersonalizationNeeded}
          selectedStep={selectedStep?.id}
        />
      )}
      <PersonalizationList
        className="mb-4 mt-2"
        readonly={readonly}
        homeId={homeId}
        projectId={projectId}
        stepId={selectedStep?.id}
        onDelete={handleDelete}
        compact={compact}
      />
      {editItemModal}
      {editSpecialRequestModal}
    </>
  );
}
