import './bulkUpdateDateRange';
import { AddButton, Form } from '@/components';
import { BulkUpdateTaskArgs } from '@/redux';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
  BulkUpdateTaskFormField,
  fieldsPerType,
  FormBulkUpdateProperty,
  INITIAL_FIELD,
} from './FormBulkUpdateProperty';
import {
  BulkUpdateTaskFieldType,
  BULK_UPDATE_TASK_VALUES,
  TaskStatus,
} from '@/core';
import { MAX_VALUE } from './TaskDetailsModal';

interface BulkUpdateTaskModalProps {
  taskIds: string[];
  projectId: string;
  communityId: string;
  onSubmit: (value: BulkUpdateTaskArgs) => any;
  onClose: () => any;
}

export interface BulkUpdateFormValue {
  fields: BulkUpdateTaskFormField[];
}

const INITIAL_VALUES: BulkUpdateFormValue = {
  fields: [{ ...INITIAL_FIELD }],
};

const schema = Yup.object().shape({
  fields: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.string().field().nullable().required(),
        startDate: Yup.date().when('type', {
          is: BulkUpdateTaskFieldType.StartDate,
          then: Yup.date().nullable().required(),
          otherwise: Yup.date().nullable().notRequired(),
        }),
        endDate: Yup.date().when('type', {
          is: BulkUpdateTaskFieldType.EndDate,
          then: Yup.date().nullable().required(),
          otherwise: Yup.date().nullable().notRequired(),
        }),
        personResponsible: Yup.string().when('type', {
          is: BulkUpdateTaskFieldType.PersonResponsible,
          then: Yup.string().field().nullable().required(),
          otherwise: Yup.string().nullable().notRequired(),
        }),
        costEstimate: Yup.number().when('type', {
          is: BulkUpdateTaskFieldType.CostEstimate,
          then: Yup.number().min(0).max(MAX_VALUE).nullable().required(),
          otherwise: Yup.number().nullable().notRequired(),
        }),
        status: Yup.mixed<TaskStatus>().when('type', {
          is: BulkUpdateTaskFieldType.Status,
          then: Yup.mixed<TaskStatus>().nullable().required(),
          otherwise: Yup.mixed<TaskStatus>().nullable().notRequired(),
        }),
      }),
    )
    .bulkUpdateDateRange()
    .min(1)
    .required(),
});

export function BulkUpdateTasksModal(props: BulkUpdateTaskModalProps) {
  const { onClose, onSubmit, taskIds, projectId, communityId } = props;
  const { t } = useTranslation();

  const handleSubmit = (value: BulkUpdateFormValue) => {
    const fields = value.fields.map((f) => ({
      type: f.type,
      value: fieldsPerType[f.type](f),
    }));
    onSubmit({ taskIds, projectId, fields });
  };

  return (
    <Modal
      title={t('tasks.bulkUpdate.modalTitle')}
      visible
      onCancel={onClose}
      footer={[
        <Button key="back" type="default" onClick={onClose}>
          {t('cancel')}
        </Button>,
        <Form.Submit uid="bulk-update-tasks" key="submit" type="primary">
          {t('save')}
        </Form.Submit>,
      ]}
    >
      <Form.Formik<BulkUpdateFormValue>
        uid="bulk-update-tasks"
        i18n="tasks.bulkUpdate"
        initialValues={INITIAL_VALUES}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ values: { fields }, setFieldValue }) => (
          <>
            <h4>{t('tasks.bulkUpdate.selectField')}</h4>
            {fields.map((field, index) => (
              <FormBulkUpdateProperty
                deletable={fields.length > 1 && index > 0}
                key={index}
                name={`fields[${index}]`}
                required
                onDelete={() => {
                  const result = fields.filter((x) => x !== field);
                  setFieldValue('fields', result);
                }}
                communityId={communityId}
              />
            ))}
            {fields.length < BULK_UPDATE_TASK_VALUES.length && (
              <AddButton
                onClick={() =>
                  setFieldValue('fields', [...fields, { ...INITIAL_FIELD }])
                }
                type="link"
              >
                {t('tasks.bulkUpdate.addField')}
              </AddButton>
            )}
          </>
        )}
      </Form.Formik>
    </Modal>
  );
}
