import {
  useGetResidentDetailsQuery,
  useUpdateResidentMutation,
  ResidentState,
} from '@/redux';
import { useTranslation } from 'react-i18next';
import { notify } from '@/components';
import { ResidentDetailsForm } from '@/views';
import { RtkqNotifyError } from '@/redux/rtkq';
import { useCallback } from 'react';

interface Props {
  id: string;
  onCancel: () => any;
}

const useSubmit = (props: Props) => {
  const { onCancel } = props;
  const [updateResident] = useUpdateResidentMutation();

  return useCallback(
    (values: ResidentState) => {
      updateResident({ ...values, [RtkqNotifyError]: false })
        .unwrap()
        .then(() => notify.success.t('residents.details.saved'))
        .then(onCancel);
    },
    [onCancel, updateResident],
  );
};

export function EditResidentPanel(props: Props) {
  const { id, onCancel } = props;
  const { t } = useTranslation();
  const { currentData } = useGetResidentDetailsQuery(
    { id: id! },
    { skip: !id },
  );
  const submit = useSubmit(props);

  if (!id || !currentData) {
    return null;
  }

  return (
    <ResidentDetailsForm
      onCancel={onCancel}
      onSubmit={submit}
      saveButtonName={t('residents.details.editSaveButton')}
      value={currentData}
    />
  );
}
