import { Form } from '@/components';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  ProjectDetailsForm,
  ProjectDetailsFormValue,
  ProjectDetailsFormVisibilityProps,
} from './ProjectDetailsForm';

export interface SaveProjectModalProps
  extends Required<ProjectDetailsFormVisibilityProps> {
  value: ProjectDetailsFormValue;
  onSave: (value: ProjectDetailsFormValue) => any;
  onCancel: () => any;
  disabledPathTemplate?: boolean;

  title: React.ReactNode;
  saveBtnText: React.ReactNode;
}

export function ProjectDetailsModal(props: SaveProjectModalProps) {
  const {
    value,
    title,
    saveBtnText,
    onSave,
    onCancel,
    noCommunity,
    disabledPathTemplate,
  } = props;
  const { t } = useTranslation();

  return (
    <Modal
      title={title}
      visible
      onCancel={onCancel}
      footer={[
        <Button key="back" type="default" onClick={onCancel}>
          {t('projects.details.cancel')}
        </Button>,
        <Form.Submit uid="project-details" key="submit" type="primary">
          {saveBtnText}
        </Form.Submit>,
      ]}
    >
      <ProjectDetailsForm
        value={value}
        onSubmit={onSave}
        noCommunity={noCommunity}
        disabledPathTemplate={disabledPathTemplate}
      />
    </Modal>
  );
}
