import { enumObject, EnumValue } from '@/utils';
import { Address, CommonFilterType, Contact } from '../http';

const CLIENT_TYPE_VALUES = ['Developer', 'Owner', 'GeneralContractor'] as const;

export type ClientType = EnumValue<typeof CLIENT_TYPE_VALUES>;

// eslint-disable-next-line
export const ClientType = enumObject('ClientType', CLIENT_TYPE_VALUES);

export type AddClientDto = {
  name: string;
  type: ClientType;
  address: Address;
  contacts: Contact[];
};

export interface ClientDto {
  id: string;
  number: string;
  name: string;
  type: ClientType;
  address: Address;
  contacts: Contact[];
  communityCount?: number;
  userCount?: number;
}

export interface ClientFilterParams extends CommonFilterType {
  number?: string[];
  name?: string[];
  type?: string[];
  city?: string[];
  state?: string[];
}

export type UpdateClientDto = ClientDto;
