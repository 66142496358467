import { EnumValue, Form } from '@/components';
import {
  isAllowedSuboptionType,
  ItemMenuItemType,
  ItemMenuSuboptionType,
  resolveDefaultSuboptionType,
} from '@/core';
import { SubOptionState, useGetSubOptionsListQuery } from '@/redux';
import { Col, Row, Typography } from 'antd';
import { useField } from 'formik';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { ItemDetailsPanel } from '../Items.Details';
import { useTranslation } from 'react-i18next';
import { useFirstRender } from '@/utils';

interface Props {
  itemName: string;
  itemId: string;
  standardCreditDisabled?: boolean;
}

export interface MenuItemSuboptionFormValue {
  id: string;
  type: ItemMenuSuboptionType;
}

export interface MenuItemSuboptionsFormValue {
  all: boolean;
  values: MenuItemSuboptionFormValue[] | undefined;
}

export interface MenuItemFormValue {
  itemId: string;
  suboptions: MenuItemSuboptionsFormValue;
  type: ItemMenuItemType;
  initialSuboptions?: MenuItemSuboptionFormValue[];
  pristine: boolean;
  noSuboptions: boolean | null;
  standardCredit: boolean;
  categoryId: string;
}

function useSelected(props: Props) {
  const { itemId, itemName } = props;
  const [{ value: item }, , { setValue }] =
    useField<MenuItemFormValue>(itemName);

  const { currentData: subOptions } = useGetSubOptionsListQuery(
    { itemId },
    { skip: !item.suboptions.all },
  );

  useEffect(() => {
    if (subOptions != null) {
      const newValue: MenuItemFormValue = {
        ...item,
        noSuboptions: isEmpty(subOptions),
        suboptions: {
          all: false,
          values: subOptions.map((sb) => ({
            id: sb.id,
            type: resolveDefaultSuboptionType(item.type),
          })),
        },
      };

      setValue(newValue, true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subOptions]);

  const { all, values } = item.suboptions;

  return useMemo(
    () =>
      all ? subOptions?.map((x) => x.id) ?? [] : values?.map((x) => x.id) ?? [],
    [all, values, subOptions],
  );
}

function useSelect(props: Props) {
  const { itemName } = props;
  const [{ value }, , { setValue }] = useField<MenuItemFormValue>(itemName);

  return useCallback(
    (ids: string[]) => {
      let values = value.suboptions.values ?? [];
      values = values.filter((x) => ids.includes(x.id));
      const newValueIds = ids.filter((id) => !values.some((v) => v.id === id));
      const newValueType = resolveDefaultSuboptionType(value.type);
      const newValues = newValueIds.map((id) => ({ id, type: newValueType }));

      values = [...values, ...newValues];
      setValue({ ...value, suboptions: { all: false, values } }, true);
    },
    [value, setValue],
  );
}

export function useUpdateSuboptionTypesWhenItemTypeChanged(props: Props) {
  const { itemName } = props;
  const [{ value: item }, , { setValue }] =
    useField<MenuItemFormValue>(itemName);
  const { type, suboptions } = item;
  const prevTypeRef = useRef<ItemMenuItemType>(null!);

  useEffect(() => {
    if (prevTypeRef.current == null || prevTypeRef.current === type) {
      prevTypeRef.current = type;
      return;
    }

    prevTypeRef.current = type;

    setValue({
      ...item,
      suboptions: {
        ...suboptions,
        values: suboptions.values?.map((so) => ({
          ...so,
          type: isAllowedSuboptionType(type, so.type)
            ? so.type
            : resolveDefaultSuboptionType(type),
        })),
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);
}

function SuboptionItem(props: {
  itemName: string;
  suboption: SubOptionState;
  content: React.ReactElement;
}) {
  const { content, itemName, suboption } = props;
  const [{ value: item }] = useField<MenuItemFormValue>(itemName);
  const index = item.suboptions.values?.findIndex((x) => x.id === suboption.id);

  return (
    <Row wrap={false}>
      <Col flex="auto">{content}</Col>
      <Col flex="none">
        {index !== undefined && index >= 0 && (
          <Typography.Text strong>
            <EnumValue type={ItemMenuSuboptionType} value={item.type} />
          </Typography.Text>
        )}
      </Col>
    </Row>
  );
}

function useResetStandardCreditOnTypeChange({ itemName }: Props) {
  const [{ value: type }] = useField<ItemMenuItemType>(`${itemName}.type`);
  const [, , { setValue: setStandardCredit }] = useField<boolean>(
    `${itemName}.standardCredit`,
  );
  const isFirst = useFirstRender();

  useEffect(() => {
    if (!isFirst && type !== 'Upgrade') {
      setStandardCredit(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);
}

export function FormItemMenuItemSuboptions(props: Props) {
  const { itemId, itemName, standardCreditDisabled } = props;
  const [{ value: item }] = useField<MenuItemFormValue>(itemName);
  const { t } = useTranslation();

  const selected = useSelected(props);
  const select = useSelect(props);
  useUpdateSuboptionTypesWhenItemTypeChanged(props);
  useResetStandardCreditOnTypeChange(props);

  return (
    <ItemDetailsPanel
      id={itemId}
      readonly
      includeUnlistedSuboptions={item.initialSuboptions?.map((x) => x.id)}
      detailsFooter={
        <>
          <Form.Checkbox
            name={`${itemName}.standardCredit`}
            disabled={standardCreditDisabled}
            label={t('itemMenus.details.standardCredit')}
          />
          <Form.EnumSelect
            name={`${itemName}.type`}
            type={ItemMenuItemType}
            label={false}
            showSearch={false}
            allowClear={false}
          />
        </>
      }
      renderSuboptionItem={({ content, item: sb }) => (
        <SuboptionItem content={content} itemName={itemName} suboption={sb} />
      )}
      selectableSuboption={{
        type: 'check',
        selected,
        onSelect: select,
      }}
    />
  );
}
