import { FieldRow, Form } from '@/components';
import { ProjectType } from '@/core';
import { Col } from 'antd';
import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { FormProjectHomes } from './FormProjectHomes';
import { useTranslation } from 'react-i18next';
import { useCustomAndDefaultPathTemplateLookupSource } from '@/views/PathTemplates.Common';
import { useAvailableCommunityForProjectsLookupSource } from '../Communities.Common/useAvailableCommunityForProjectsLookupSource';
import { useUserContextSelector } from '@/redux';
import { useClientLookupSource } from '../Clients.Common';

const schema = Yup.object().shape({
  id: Yup.string().nullable(),
  clientId: Yup.string().nullable().required(),
  communityId: Yup.string().nullable().required(),
  name: Yup.string().field().nullable().required(),
  type: Yup.string().oneOf(Object.values(ProjectType)).nullable().required(),
  pmLiteEnabled: Yup.bool().required(),
  description: Yup.string().field('lg').nullable(),
  homeIds: Yup.array().of(Yup.string().nullable().required()).required(),
});

export interface ProjectDetailsFormVisibilityProps {
  noCommunity?: boolean;
}

export type ProjectDetailsFormValue = Omit<
  OmitIndex<Yup.InferType<typeof schema>>,
  'type' | 'project'
> & {
  type: ProjectType;
  pathTemplateId?: string;
};

export interface ProjectDetailsFormProps
  extends ProjectDetailsFormVisibilityProps {
  value: ProjectDetailsFormValue;
  onSubmit: (value: ProjectDetailsFormValue) => any;
  disabledPathTemplate?: boolean;
}

export const INITIAL_PROJECT_DETAILS_FORM_VALUE: ProjectDetailsFormValue = {
  id: null!,
  clientId: null!,
  communityId: null!,
  description: null!,
  name: null!,
  pmLiteEnabled: false,
  type: null!,
  homeIds: [],
  pathTemplateId: null!,
};

function CommunitySelect({
  initialValue,
  initialClientId,
}: {
  initialValue: string;
  initialClientId: string;
}) {
  const prevValue = useRef<string>();
  const { setFieldValue, values } = useFormikContext<ProjectDetailsFormValue>();
  const { clientId } = values;
  const communityDataSource = useAvailableCommunityForProjectsLookupSource(
    clientId!,
  );

  useEffect(() => {
    if (!prevValue.current) {
      prevValue.current = clientId;
      return;
    }

    if (prevValue.current !== clientId) {
      setFieldValue('communityId', null);
      prevValue.current = clientId;
    }

    // eslint-disable-next-line
  }, [clientId]);

  return (
    <Form.AsyncSelect
      required
      key={clientId}
      name="communityId"
      preloadInitial={clientId === initialClientId ? initialValue : undefined}
      dataSource={communityDataSource}
    />
  );
}

function PathTemplateSelect({ communityId }: { communityId: string }) {
  const { t } = useTranslation();
  const templates = useCustomAndDefaultPathTemplateLookupSource(communityId);

  return (
    <Form.AsyncSelect
      key={communityId}
      name="pathTemplateId"
      dataSource={templates}
      label={t('projects.details.selectPathTemplate')}
    />
  );
}

export function ProjectDetailsForm(props: ProjectDetailsFormProps) {
  const { value, onSubmit, noCommunity, disabledPathTemplate } = props;
  const isBFP = useUserContextSelector((x) => x.isBFP);
  const clientDataSource = useClientLookupSource();

  return (
    <Form.Formik<ProjectDetailsFormValue>
      uid="project-details"
      i18n="projects.details"
      initialValues={value}
      validationSchema={schema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values }) => (
        <>
          {isBFP && !noCommunity && (
            <FieldRow>
              <Col span={24}>
                <Form.AsyncSelect
                  name="clientId"
                  dataSource={clientDataSource}
                  required
                  preloadInitial={value.clientId}
                />
              </Col>
            </FieldRow>
          )}

          {noCommunity !== true && (
            <CommunitySelect
              initialValue={value.communityId}
              initialClientId={value.clientId}
            />
          )}

          <FieldRow>
            <Col span={12}>
              <Form.Input name="name" required />
            </Col>
            <Col span={12}>
              <Form.EnumSelect name="type" type={ProjectType} required />
            </Col>
          </FieldRow>

          <Form.TextArea name="description" />
          <Form.Checkbox name="pmLiteEnabled" />

          {values.communityId && (
            <FormProjectHomes
              name="homeIds"
              communityId={values.communityId}
              projectId={value.id}
            />
          )}

          {!disabledPathTemplate && (
            <FieldRow className="mt-4">
              <Col span={12}>
                <PathTemplateSelect communityId={values.communityId} />
              </Col>
            </FieldRow>
          )}
        </>
      )}
    </Form.Formik>
  );
}
