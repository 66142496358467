import { PricingInput, PricingInputProps } from '../Pricing.Common';

export interface PricingSettingsPercentageInputProps
  extends Pick<
    PricingInputProps,
    | 'disabled'
    | 'initialValue'
    | 'resetOnInitialValueChange'
    | 'onSubmit'
    | 'placeholder'
  > {}

export function PricingSettingsPercentageInput(
  props: PricingSettingsPercentageInputProps,
) {
  return (
    <PricingInput prefix="%" min={0} max={10000} precision={6} {...props} />
  );
}
