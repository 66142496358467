import { useTranslation } from 'react-i18next';
import { AddButton, notify } from '@/components';
import { useMemo, useState } from 'react';
import { AssignmentDetailsModalValue, AssignmentDetailsModal } from '@/views';
import {
  useAssignToCommunityMutation,
  useGetCommunityUsersQuery,
} from '@/redux';
import { DEFAULT_PAGING } from '@/core';

interface Props {
  communityId: string;
  clientId: string;
}

export const INITIAL_COMMUNITY_USER: AssignmentDetailsModalValue = {
  userId: null!,
  roles: [],
};

const useSubmit = (setOpenModal: (value: boolean) => any, props: Props) => {
  const { communityId } = props;
  const [assign] = useAssignToCommunityMutation();

  return (args: AssignmentDetailsModalValue) =>
    assign({ ...args, communityId })
      .unwrap()
      .then(() => notify.success.t('communityUsers.added'))
      .then(() => setOpenModal(false));
};

export function AddAssignmentButton(props: Props) {
  const { clientId, communityId } = props;
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();

  const submit = useSubmit(setOpenModal, props);
  const { data } = useGetCommunityUsersQuery({
    communityId,
    paging: DEFAULT_PAGING,
  });

  const communityUsers = useMemo(() => {
    return data?.items;
  }, [data?.items]);

  const assignedUserIds = useMemo(
    () => communityUsers?.map((u) => u.id),
    [communityUsers],
  );

  return (
    <>
      <AddButton onClick={() => setOpenModal(true)}>
        {t('communityUsers.addButton')}
      </AddButton>
      {openModal && (
        <AssignmentDetailsModal
          clientId={clientId}
          buttonSaveName={t('communityUsers.details.addButton')}
          onClose={() => setOpenModal(false)}
          onSubmit={submit}
          title={t('communityUsers.details.addTitle')}
          value={INITIAL_COMMUNITY_USER}
          assignedUserIds={assignedUserIds}
        />
      )}
    </>
  );
}
