import { TaskState, useGetTasksListQuery } from '@/redux';
import { useEditTaskAction, useProjectContext, useTasksColumns } from '@/views';
import { useDuplicateTaskAction } from '../Tasks.Details/useDuplicateTaskAction';
import {
  DefaultNumberSorting,
  Table,
  useDefaultTablePaging,
  useFilters,
  useSorting,
} from '@/components';
import { useMemo, useState } from 'react';
import { useBulkUpdateTasksActions } from '../Tasks.Details/useBulkUpdateTasksAction';
import { TasksListActions } from './TasksListActions';
import { TaskFilterParams } from '@/core';
import { useBulkDeleteTasksAction } from '../Tasks.Details/ImportTasks/useBulkDeleteTasksAction';

interface TasksManagementListPanelProps {
  projectId: string;
  communityId: string;
}

export function TasksListPanel(props: TasksManagementListPanelProps) {
  const { projectId, communityId } = props;
  const { setTotal, paging, params } = useDefaultTablePaging();
  const {
    filters,
    onFiltersChange,
    resetFilters,
    stringFilterOptions,
    setStringFilterOptions,
  } = useFilters<TaskFilterParams>(
    ['number', 'name', 'home', 'responsiblePerson'],
    undefined,
    ['startDate', 'endDate', 'costEstimated'],
  );
  const { sorting, onSortingChange } =
    useSorting<TaskState>(DefaultNumberSorting);
  const { data, isFetching } = useGetTasksListQuery({
    projectId,
    paging: params,
    filters,
    sorting,
  });

  const tasks = useMemo(() => {
    if (data) {
      setTotal(data.total);
      setStringFilterOptions(data.stringFilterOptions);
    }
    return data?.items;
  }, [data, setStringFilterOptions, setTotal]);

  const { isActive } = useProjectContext();
  const { openEdit, editModal } = useEditTaskAction();
  const { openDuplicate, duplicateModal } = useDuplicateTaskAction();
  const { openBulkUpdate, bulkUpdateModal } = useBulkUpdateTasksActions();
  const columns = useTasksColumns(
    openEdit,
    openDuplicate,
    tasks,
    stringFilterOptions,
  );
  const [selected, setSelected] = useState<TaskState[]>([]);
  const bulkDelete = useBulkDeleteTasksAction();

  const handleBulkUpdate = () =>
    openBulkUpdate(
      selected.map((x) => x.id),
      projectId,
      communityId,
    );

  const handleDuplicate = () =>
    openDuplicate(
      selected.map((x) => x.id),
      projectId,
    );

  const handleBulkDelete = () =>
    bulkDelete({ taskIds: selected.map((x) => x.id), projectId });

  return (
    <div>
      <Table<TaskState>
        name="Tasks"
        scroll={{ x: 1200 }}
        actions={
          <TasksListActions
            onBulkUpdate={handleBulkUpdate}
            onDuplicate={handleDuplicate}
            onBulkDelete={handleBulkDelete}
            disabled={selected.length === 0 || !isActive}
          />
        }
        rowSelection={{
          type: 'checkbox',
          onChange: (_, rows) => setSelected(rows),
        }}
        loading={isFetching}
        columns={columns}
        dataSource={tasks}
        rowKey={(row) => row.id}
        pagination={paging}
        onChange={(_, filters, sorting) => {
          onFiltersChange(filters);
          onSortingChange(sorting);
        }}
        onFiltersReset={resetFilters}
      />
      {editModal}
      {duplicateModal}
      {bulkUpdateModal}
    </div>
  );
}
