import { Modal, Button } from 'antd';
import { Form } from '@/components';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useBookletLinkLookupSource } from './useBookletLinkLookupSource';
import { AddBookletLinkDto, AttachmentDto } from '@/core';
import styles from './BookletLink.module.scss';

const schema = Yup.object().shape({
  attachmentId: Yup.string().field().nullable().required(),
  page: Yup.number().min(0).nullable().required(),
});

interface Props {
  onClose: () => any;
  onSubmit: (props: AddBookletLinkDto) => any;
  value: AddBookletLinkDto;
  attachments: AttachmentDto[];
  title: string;
}

export function ItemBookletLinkModal(props: Props) {
  const { onClose: onCancel, value, onSubmit, title, attachments } = props;
  const { t } = useTranslation();
  const bookletLinkDataSource = useBookletLinkLookupSource(attachments);

  return (
    <Modal
      title={title}
      className={styles.bookletLinkModal}
      visible
      onCancel={onCancel}
      footer={[
        <Button key="back" type="default" onClick={onCancel}>
          {t('bookletLinks.cancelButton')}
        </Button>,
        <Form.Submit uid="bookletLinks" key="submit" type="primary">
          {t('bookletLinks.saveButton')}
        </Form.Submit>,
      ]}
    >
      <Form.Formik<AddBookletLinkDto>
        uid="bookletLinks"
        i18n="bookletLinks"
        initialValues={value}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        <Form.AsyncSelect
          name="attachmentId"
          dataSource={bookletLinkDataSource}
          className="w-100"
          required
        />
        <Form.Input type={'number'} name="page" required />
      </Form.Formik>
    </Modal>
  );
}
