import { ProjectStatus } from '@/core';
import { useSelector } from 'react-redux';
import { ProjectContext, projectsApi } from '.';

export function projectHasStatus(
  context: ProjectContext,
  statuses: ProjectStatus[],
) {
  return statuses.includes(context?.status);
}

function createProjectContextSelectors(projectId: string, state: any) {
  const context = projectsApi.endpoints.getProjectContext.select({
    id: projectId,
  })(state).data!;

  const selectors = Object.assign({}, context, {
    hasStatus: (statuses: ProjectStatus[]) => {
      return projectHasStatus(context, statuses);
    },
    isActive: projectHasStatus(context, ['InProgress', 'NotStarted']),
  });

  return selectors;
}

export function useProjectContextSelector<TResult>(
  projectId: string,
  select: (
    selectors: ReturnType<typeof createProjectContextSelectors>,
  ) => TResult,
) {
  return useSelector((state) => {
    return select(createProjectContextSelectors(projectId, state));
  });
}
