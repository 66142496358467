import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as Yup from 'yup';
import * as locale from 'yup/lib/locale';

import common from '@/assets/en.yaml';
import activity from '@/assets/activity.en.yaml';
import errors from '@/assets/errors.en.yaml';
import nav from '@/assets/nav.en.yaml';
import users from '@/assets/users.en.yaml';
import enums from '@/assets/enums.en.yaml';
import communities from '@/assets/communities.en.yaml';
import homes from '@/assets/homes.en.yaml';
import clients from '@/assets/clients.en.yaml';
import locations from '@/assets/locations.en.yaml';
import floorplans from '@/assets/floorplans.en.yaml';
import UsaStates from '@/assets/UsaStates.en.yaml';
import projects from '@/assets/projects.en.yaml';
import categories from '@/assets/categories.en.yaml';
import tasks from '@/assets/tasks.en.yaml';
import subcontractors from '@/assets/subcontractors.en.yaml';
import roomTypes from '@/assets/roomTypes.en.yaml';
import pathTemplates from '@/assets/pathTemplates.en.yaml';
import taskTemplates from '@/assets/taskTemplates.en.yaml';
import template from '@/assets/template.en.yaml';
import rooms from '@/assets/rooms.en.yaml';
import items from '@/assets/items.en.yaml';
import subOptions from '@/assets/subOptions.en.yaml';
import developScope from '@/assets/developScope.en.yaml';
import residents from '@/assets/residents.en.yaml';
import communityUsers from '@/assets/communityUsers.en.yaml';
import bookletLinks from '@/assets/bookletLinks.en.yaml';
import personalization from '@/assets/personalization.en.yaml';
import itemMenus from '@/assets/itemMenus.en.yaml';
import pricing from '@/assets/pricing.en.yaml';
import specialRequests from '@/assets/specialRequests.en.yaml';
import reports from '@/assets/reports.en.yaml';
import subscriptions from '@/assets/subscriptions.en.yaml';
import payments from '@/assets/payments.en.yaml';
import searchByNumber from '@/assets/searchByNumber.en.yaml';
import notificationSettings from '@/assets/notificationSettings.en.yaml';
import rolesPermissions from '@/assets/rolesPermissions.en.yaml';
import workspace from '@/assets/workspace.en.yaml';
import aboutUsPage from '@/assets/aboutUsPage.en.yaml';
import customLegalLanguages from '@/assets/customLegalLanguage.en.yaml';
import index from '@/assets/index.en.yaml';
import externalLinks from '@/assets/externalLinks.en.yaml';
import summarySettings from '@/assets/summarySettings.en.yaml';
import { formatters } from './core';

function initializeI18n() {
  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: {
          ...common,
          ...activity,
          ...errors,
          ...nav,
          ...users,
          ...notificationSettings,
          enums: {
            ...enums,
            ...UsaStates,
          },
          ...communities,
          ...clients,
          ...homes,
          ...locations,
          ...floorplans,
          ...projects,
          ...categories,
          ...tasks,
          ...subcontractors,
          ...roomTypes,
          ...pathTemplates,
          ...taskTemplates,
          ...template,
          ...rooms,
          ...items,
          ...subOptions,
          ...developScope,
          ...residents,
          ...communityUsers,
          ...bookletLinks,
          ...personalization,
          ...itemMenus,
          ...pricing,
          ...specialRequests,
          ...reports,
          ...subscriptions,
          ...payments,
          ...searchByNumber,
          ...rolesPermissions,
          ...workspace,
          ...aboutUsPage,
          ...customLegalLanguages,
          ...index,
          ...externalLinks,
          ...summarySettings,
        },
      },
    },

    lng: 'en',

    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  });

  i18n.services.formatter!.add(
    'date-time',
    (value: string | Date | null | undefined, _, options: any) => {
      if (value == null) {
        return '';
      }

      const showTimeZone = options.showTimeZone === true;
      const dateTime = formatters.dateTime(value);
      return showTimeZone ? `${dateTime} (CST Time zone)` : dateTime;
    },
  );
}
function initializeYupLocalization() {
  const keysLocale = convertToKeys(locale, []);

  function convertToKeys(obj: any, path: string[]) {
    if (typeof obj === 'string' || typeof obj === 'function') {
      return (args: any) => ({ key: path.join('.'), args });
    }

    const result: Record<string, any> = {};
    Object.keys(obj)
      .filter((x) => x !== 'default')
      .forEach(
        (key) => (result[key] = convertToKeys(obj[key], [...path, key])),
      );
    return result;
  }

  Yup.setLocale(keysLocale);
}

initializeI18n();
initializeYupLocalization();
