import { useTranslation } from 'react-i18next';
import { FieldRow, Form } from '@/components';
import * as Yup from 'yup';
import { Col } from 'antd';
import { useItemMenuLookupSource } from '@/views';

interface Props {
  onSubmit: (props: any) => any;
  communityId: string;
  currentMenuId: string;
}

export interface CopyPricingFormValue {
  menuId: string;
}

const initialValue = {
  menuId: null!
}

const schema = Yup.object().shape({
  menuId: Yup.string().field().nullable().required(),
});

export function CopyPricingForm(props: Props) {
  const { onSubmit, communityId, currentMenuId } = props;
  const { t } = useTranslation();
  const menusDataSource = useItemMenuLookupSource(communityId, true, currentMenuId);

  return (
    <Form.Formik<CopyPricingFormValue>
      uid="copy-pricing"
      initialValues={initialValue}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      <FieldRow>
        <Col span={12}>
          <Form.AsyncSelect
            name="menuId"
            dataSource={menusDataSource}
            label={t('pricing.copyPricing.menu')}
            required
          />
        </Col>
      </FieldRow>
    </Form.Formik>
  )
}