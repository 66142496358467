import { Contact } from '@/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactDetailsModal } from './ContactDetailsModal';

export interface UseEditContactActionArgs {
  onSubmit: (newValue: Contact, prevValue: Contact) => Promise<any>;
}

export function useEditContactAction(args: UseEditContactActionArgs) {
  const [contact, setContact] = useState<Contact>();
  const { onSubmit } = args;
  const { t } = useTranslation();

  const submit = (value: Contact) => {
    onSubmit(value, contact!).then(() => setContact(undefined));
  };

  return {
    openEditContact: (contact: Contact) => setContact(contact),
    editContactModal: (
      <>
        {contact && (
          <ContactDetailsModal
            title={t('clients.details.editContactTitle')}
            buttonSaveName={t('clients.details.editContactButton')}
            onSubmit={submit}
            value={contact}
            onClose={() => setContact(undefined)}
          />
        )}
      </>
    ),
  };
}
