import {
  UpdatePathTemplateArgs,
  useGetPathTemplateQuery,
  useUpdatePathTemplateMutation,
} from '@/redux';
import { useNavigate } from 'react-router-dom';
import { notify } from '@/components';
import { FormPathTemplate } from './Form';

interface Props {
  id: string;
  communityId?: string;
  onSaved?: () => any;
}

const useSubmit = (props: Props) => {
  const [update] = useUpdatePathTemplateMutation();

  return async (values: UpdatePathTemplateArgs) => {
    await update(values)
      .unwrap()
      .then(props.onSaved)
      .then(() => notify.success.t('pathTemplates.details.saved'));
  };
};

export function EditPathTemplatePanel(props: Props) {
  const submit = useSubmit(props);
  const { id, communityId } = props;
  const { currentData } = useGetPathTemplateQuery({ id });
  const push = useNavigate();

  return (
    <>
      {currentData && (
        <FormPathTemplate<UpdatePathTemplateArgs>
          onSubmit={submit}
          value={currentData}
          noTemplate
          onCancel={() => push(-1)}
          communityId={communityId}
        />
      )}
    </>
  );
}
