import { dataNodeUtils } from '@/components';
import { formatters, RoomCategory } from '@/core';
import { Col, Row } from 'antd';
import { DataNode } from 'antd/lib/tree';
import { isEmpty } from 'lodash';

const LEFT_MARGIN = 20;

interface Props {
  id: string;
  treeData: DataNode[];
  categories: RoomCategory[];
  marginLeft?: number;
}

export function useRoomCategoryFootageItemData(
  id: string,
  treeData: DataNode[],
  categories: RoomCategory[],
) {
  const path = dataNodeUtils.findPathInAny(treeData, id)!;
  const node = path.at(-1);
  const title =
    typeof node?.title === 'function' ? node.title(node) : node?.title;
  const isVisible = categories.some((c) => path.some((p) => p.key === c.id));

  return { node, title, isVisible };
}

export function RoomCategoryFootageItem(props: Props) {
  const { id, treeData, categories, marginLeft = 0 } = props;
  const { node, title, isVisible } = useRoomCategoryFootageItemData(
    id,
    treeData,
    categories,
  );

  return (
    <div>
      <Row className="mb-2">
        <Col span={12}>
          <div style={{ marginLeft }}>{title}</div>
        </Col>
        <Col span={12}>
          {isVisible &&
            formatters.footage(
              categories.find((x) => x.id === id)?.squareFootage,
            )}
        </Col>
      </Row>
      {!isEmpty(node?.children) && (
        <div>
          {node!.children!.map((n) => (
            <RoomCategoryFootageItem
              key={n.key}
              id={n.key as string}
              treeData={treeData}
              categories={categories}
              marginLeft={marginLeft + LEFT_MARGIN}
            />
          ))}
        </div>
      )}
    </div>
  );
}
