import { Form, notify } from '@/components';
import {
  PersonalizationItemState,
  useSavePersonalizationItemManualResidentPriceMutation,
} from '@/redux';
import { Button, Modal } from 'antd';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface Props {
  item: PersonalizationItemState;
  onClose: () => any;
}

const SCHEMA = Yup.object().shape({
  amount: Yup.number().min(0).nullable().required(),
});

type FormValue = OmitIndex<Yup.InferType<typeof SCHEMA>>;

function useSubmit(props: Props) {
  const { item, onClose } = props;
  const { id } = item;
  const [save] = useSavePersonalizationItemManualResidentPriceMutation();
  return useCallback(
    ({ amount }: FormValue) =>
      save({ id, amount })
        .unwrap()
        .then(() => {
          notify.success.t('personalization.summary.manualResidentPrice.saved');
          onClose();
        }),
    [id, save, onClose],
  );
}

function useDelete(props: Props) {
  const { item, onClose } = props;
  const { id } = item;
  const [save] = useSavePersonalizationItemManualResidentPriceMutation();
  return useCallback(
    () =>
      save({ id, amount: undefined })
        .unwrap()
        .then(() => {
          notify.success.t(
            'personalization.summary.manualResidentPrice.deleted',
          );
          onClose();
        }),
    [id, save, onClose],
  );
}

function useInitialValue(props: Props): FormValue {
  const { residentPriceOverridenAt, totalResidentPrice } = props.item.pricing;

  return useMemo(
    () => ({
      amount: !!residentPriceOverridenAt ? totalResidentPrice! : null!,
    }),
    [residentPriceOverridenAt, totalResidentPrice],
  );
}

export function ManualResidentPriceModal(props: Props) {
  const { onClose } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'personalization.summary.manualResidentPrice',
  });

  const handleSubmit = useSubmit(props);
  const handleDelete = useDelete(props);
  const initialValue = useInitialValue(props);

  return (
    <Modal
      visible
      onCancel={onClose}
      title={t('title')}
      footer={[
        <Button key="back" type="default" onClick={onClose}>
          {t('cancel')}
        </Button>,
        <Button key="delete" type="default" onClick={handleDelete}>
          {t('delete')}
        </Button>,
        <Form.Submit uid="manual-resident-price" key="submit" type="primary">
          {t('save')}
        </Form.Submit>,
      ]}
    >
      <Form.Formik<FormValue>
        uid="manual-resident-price"
        i18n="personalization.summary.manualResidentPrice"
        validationSchema={SCHEMA}
        onSubmit={handleSubmit}
        initialValues={initialValue}
      >
        <Form.Number name="amount" required />
      </Form.Formik>
    </Modal>
  );
}
