import { getEnumValue, TableColumnType } from '@/components';
import { PricingSettingsMatrixRowDto, SaleTaxApplyTo } from '@/core';
import { useFiltersFactory } from '@/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PricingSettingsApplyTo } from './PricingSettingsApplyTo';
import { PricingSettingsPercentageInput } from './PricingSettingsPercentageInput';
import { usePricingSettingsMatrixState } from './usePricingSettingsMatrixState';

export function usePricingSettingsColumns(
  matrixState: ReturnType<typeof usePricingSettingsMatrixState>,
) {
  const { t } = useTranslation();
  const { updateValue, defaults, value } = matrixState;

  const filters = useFiltersFactory(value);

  return useMemo<TableColumnType<PricingSettingsMatrixRowDto>[]>(() => {
    const columns: Array<TableColumnType<PricingSettingsMatrixRowDto> | false> =
      [
        {
          title: t('pricing.settings.category'),
          key: 'category',
          ...filters.plainText((x) => x.categories[0].name),
          exportValue: (record) => record.categories[0].name,
          render: (_, record) => {
            return record.categories[0].name;
          },
        },
        {
          title: t('pricing.settings.subcategory1'),
          key: 'subcategory1',
          ...filters.plainText((x) => x.categories[1]?.name),
          exportValue: (record) => record.categories[1]?.name,
          render: (_, record) => {
            return record.categories[1]?.name || '-';
          },
        },
        {
          title: t('pricing.settings.subcategory2'),
          key: 'subcategory2',
          ...filters.plainText((x) => x.categories[2]?.name),
          exportValue: (record) => record.categories[2]?.name,
          render: (_, record) => {
            return record.categories[2]?.name || '-';
          },
        },
        {
          title: t('pricing.settings.constructionMarkup'),
          key: 'constructionMarkup',
          exportValue: (record) => record.value?.construction!,
          width: 150,
          render: (_, item) => (
            <PricingSettingsPercentageInput
              onSubmit={(value) => updateValue(item, 'construction', value)}
              initialValue={item.value?.construction}
              resetOnInitialValueChange
              placeholder={defaults.construction?.toFixed(6)}
            />
          ),
        },
        {
          title: t('pricing.settings.residentMarkup'),
          key: 'residentMarkup',
          exportValue: (record) => record.value?.resident,
          width: 150,
          render: (_, item) => (
            <PricingSettingsPercentageInput
              onSubmit={(value) => updateValue(item, 'resident', value)}
              initialValue={item.value?.resident}
              resetOnInitialValueChange
              placeholder={defaults.resident?.toFixed(6)}
            />
          ),
        },
        {
          title: t('pricing.settings.saleTax.value'),
          key: 'salesTaxValue',
          exportValue: (record) => record.value?.saleTax?.value,
          width: 150,
          render: (_, item) => (
            <PricingSettingsPercentageInput
              onSubmit={(value) => updateValue(item, 'saleTax.value', value)}
              initialValue={item.value?.saleTax?.value}
              resetOnInitialValueChange
              placeholder={defaults.salesTax?.toFixed(6)}
            />
          ),
        },
        {
          title: t('pricing.settings.saleTax.applyTo'),
          key: 'salesTaxApplyTo',
          exportValue: (record) =>
            record.value?.saleTax?.applyTo
              ?.map((value) => getEnumValue(SaleTaxApplyTo, value, t))
              .join(' | '),
          width: 150,
          render: (_, item) => (
            <PricingSettingsApplyTo
              initialValue={item.value?.saleTax?.applyTo}
              onSubmit={(value) => updateValue(item, 'saleTax.applyTo', value)}
              resetOnInitialValueChange
            />
          ),
        },
      ];
    return columns.filter(
      (x) => !!x,
    ) as TableColumnType<PricingSettingsMatrixRowDto>[];
  }, [t, updateValue, defaults, filters]);
}
