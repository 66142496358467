import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkOutlined } from '@ant-design/icons';
import { AddExternalLinkDto } from '@/core';
import { Button } from 'antd';
import { ItemExternalLinkModal } from './ExternalLinkModal';

interface Props {
  className?: string;
  onCreate: (args: AddExternalLinkDto) => any;
}

const useValue = (): AddExternalLinkDto => {
  return {
    source: null!,
  };
};

const useSubmit = (props: Props, setOpenModal: (value: boolean) => void) => {
  const { onCreate } = props;

  return (args: AddExternalLinkDto) =>
    onCreate(args).then(() => {
      setOpenModal(false);
    });
};

export function AddExternalLinkButton(props: Props) {
  const [openModal, setOpenModal] = useState(false);
  const onSubmit = useSubmit(props, setOpenModal);
  const { t } = useTranslation();
  const { className } = props;

  const initialValue = useValue();

  return (
    <div className={className}>
      <Button
        type="link"
        icon={<LinkOutlined />}
        onClick={() => setOpenModal(true)}
      >
        {t('externalLinks.addTitle')}
      </Button>
      {openModal && (
        <ItemExternalLinkModal
          onSubmit={onSubmit}
          value={initialValue}
          onClose={() => setOpenModal(false)}
        />
      )}
    </div>
  );
}
