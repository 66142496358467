import { Tabs } from '@/components';
import { useTranslation } from 'react-i18next';
import { PathTemplatesListTabPanel } from '@/views';
import { CommunityDto } from '@/core';
import { EnumObject, enumObject } from '@/utils';
import { Collapse } from 'antd';
import { BFP_ORGANIZATION_ID } from '@/platform';
import { TaskTemplatesListTabPanel } from '@/views';

const TAB_KEYS = ['path-templates', 'task-templates'];

export const TemplateTabContentKeys = enumObject(
  'TemplateTabContentKeys',
  TAB_KEYS,
);

const { TabPane } = Tabs;

const { Panel } = Collapse;

interface Props {
  className?: string;
  communityId: string;
  data: CommunityDto;
  active: boolean;
  tabKeys: EnumObject<any>;
}

export const TemplateTabContent = (props: Props) => {
  const { className, communityId, data, active, tabKeys } = props;
  const { t } = useTranslation();

  return (
    <Tabs
      className={className}
      tabKeys={tabKeys}
      destroyInactiveTabPane
      defaultActiveKey={tabKeys['path-templates']}
    >
      <TabPane
        tab={t('pathTemplates.tabTitle')}
        key={tabKeys['path-templates']}
      >
        <>
          <Collapse ghost defaultActiveKey={data.clientId}>
            {active && (
              <Panel
                key={BFP_ORGANIZATION_ID}
                header={t('pathTemplates.defaultTitle')}
              >
                <PathTemplatesListTabPanel
                  detailsRoute={(id) => `/admin/path-templates/${id}`}
                  editRoute={(id) => `/admin/path-templates/${id}/edit`}
                  organizationId={BFP_ORGANIZATION_ID}
                  readonly
                  title={t('pathTemplates.defaultTitle')}
                />
              </Panel>
            )}
            <Panel key={data.clientId} header={t('pathTemplates.customTitle')}>
              <PathTemplatesListTabPanel
                detailsRoute={(id) =>
                  `/communities/${communityId}/custom-path-templates/${id}`
                }
                editRoute={(id) =>
                  `/communities/${communityId}/custom-path-templates/${id}/edit`
                }
                readonly={!active}
                organizationId={data.clientId}
                communityId={communityId}
                title={t('pathTemplates.customTitle')}
              />
            </Panel>
          </Collapse>
        </>
      </TabPane>

      <TabPane
        tab={t('taskTemplates.tabTitle')}
        key={tabKeys['task-templates']}
      >
        <TaskTemplatesListTabPanel
          detailsRoute={(id) =>
            `/communities/${communityId}/task-templates/${id}`
          }
          editRoute={(id) =>
            `/communities/${communityId}/task-templates/${id}/edit`
          }
          readonly={!active}
          organizationId={data.clientId}
          communityId={communityId}
        />
      </TabPane>
    </Tabs>
  );
};
