import {
  combineReducers,
  configureStore as reduxConfigureStore,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { api } from './api';

const reducers = {
  [api.reducerPath]: api.reducer,
};

export const configureStore = () => {
  const reducer = combineReducers({
    ...reducers,
  });

  return reduxConfigureStore({
    reducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware),
  });
};

export const useAppDispatch = () =>
  useDispatch<ReturnType<typeof configureStore>['dispatch']>();

export const store = configureStore();
