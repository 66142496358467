import { Modal, Button } from 'antd';
import { Form } from '@/components';
import { useTranslation } from 'react-i18next';
import {
  AddFloorplanArgs,
  AssignFloorplanToHomeArgs,
  FloorplanDetailsState,
  UpdateFloorplanArgs,
} from '@/redux';
import { FloorplanDetailsFormikForm } from './FloorplanDetailsFormikForm';
import {
  FloorplanCopyMenuForm,
  useFloorplanCopyMenu,
} from './FloorplanCopyMenuForm';
import { useMemo } from 'react';

interface Props {
  onClose: () => any;
  onSubmit: (props?: any) => any;
  value: AddFloorplanArgs | AssignFloorplanToHomeArgs | UpdateFloorplanArgs;
  title: string;
  buttonSaveName: string;
}

export function FloorplanDetailsModal(props: Props) {
  const { onClose: onCancel, value, onSubmit, title, buttonSaveName } = props;
  const { t } = useTranslation();
  const floorplanCopyMenuProps = useFloorplanCopyMenu('floorplan-details');
  const { floorplan } = floorplanCopyMenuProps;
  const isEditing = !!(value as any).id;
  const initialValue = useInitialValue(isEditing, value, floorplan);
  return (
    <Modal
      title={title}
      visible
      onCancel={onCancel}
      footer={[
        <Button key="back" type="default" onClick={onCancel}>
          {t('floorplans.details.cancel')}
        </Button>,
        <Form.Submit uid="floorplan-details" key="submit" type="primary">
          {buttonSaveName}
        </Form.Submit>,
      ]}
    >
      {!isEditing && (
        <FloorplanCopyMenuForm
          communityId={value.communityId}
          {...floorplanCopyMenuProps}
        />
      )}
      <FloorplanDetailsFormikForm
        initialValues={initialValue}
        onSubmit={onSubmit}
      />
    </Modal>
  );
}

function useInitialValue(
  isEditing: boolean,
  value: AddFloorplanArgs | AssignFloorplanToHomeArgs | UpdateFloorplanArgs,
  floorplan?: FloorplanDetailsState,
) {
  return useMemo(() => {
    if (isEditing) {
      return value;
    } else {
      return {
        ...value,
        constructionName: floorplan?.constructionName,
        type: floorplan?.type,
        squareFootage: floorplan?.squareFootage,
        description: floorplan?.description,
        exampleFloorplanId: floorplan?.id,
      } as any;
    }
  }, [
    floorplan?.constructionName,
    floorplan?.description,
    floorplan?.id,
    floorplan?.squareFootage,
    floorplan?.type,
    isEditing,
    value,
  ]);
}
