import { useField } from 'formik';
import { FormsGroup } from './FormsGroup';
import { useFormLabel } from './FormsI18nContext';
import React from 'react';
import { AutoComplete } from 'antd';

export interface FormsAutoCompleteOption {
  value: string;
}

export type FormsAutoCompleteProps = {
  name: string;
  label?: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  options?: FormsAutoCompleteOption[];
  filterOption?: boolean;
};

export const FormsAutoComplete = ({
  name,
  label: labelOverride,
  required,
  disabled,
  className,
  placeholder,
  options,
}: FormsAutoCompleteProps) => {
  const uniqueOptions = Array.from(
    new Set(options?.map((option) => option.value)),
  ).map((value) => ({ value }));

  const [field, , helpers] = useField(name);
  const label = useFormLabel(name, labelOverride);

  function handleChange(value: string) {
    helpers.setValue(value || null);
  }

  return (
    <FormsGroup label={label} name={name} required={required}>
      <AutoComplete
        value={field.value}
        onChange={handleChange}
        options={uniqueOptions}
        className={className}
        disabled={disabled}
        placeholder={placeholder}
        optionFilterProp={'value'}
        filterOption={(inputValue, option) =>
          option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        virtual
      />
    </FormsGroup>
  );
};
