import { useTranslation } from 'react-i18next';
import { Icon, Page } from '@/components';
import { AddProjectButton, ProjectsListPanel } from '@/views/Projects.List';
import {
  CommunityState,
  useGetAvailableCommunitiesForProjectsListQuery,
  useUserContextSelector,
} from '@/redux';
import { useClientSelectPanel } from '@/views';
import { array } from '@/utils';

export function ProjectListPage() {
  const { t } = useTranslation();
  const hasRole = useUserContextSelector((x) => x.hasRole);
  const title = t('projects.title');

  const currentUserClientId = useUserContextSelector((x) => x.clientId);

  const listPanelState = useClientSelectPanel();
  const { selected: selectedClientId } = listPanelState;

  const clientId = selectedClientId || currentUserClientId;

  const { data: communities = array.empty<CommunityState>() } =
    useGetAvailableCommunitiesForProjectsListQuery({
      clientId,
    });

  return (
    <Page htmlTitle={title}>
      <Page.Paths>
        <Page.Path>{title}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card
          title={title}
          icon={<Icon type="inbox" />}
          color="#1FB197"
          actions={
            (hasRole('CLIENT_ADMIN') ||
              hasRole('BFP_ADMIN') ||
              communities.length > 0) && (
              <AddProjectButton clientId={clientId} />
            )
          }
        >
          <ProjectsListPanel clientId={clientId} />
        </Page.Card>
      </Page.Body>
    </Page>
  );
}
