export const generateSafePassword = () => {
  const minLength = 8;
  const maxLength = 30;
  const length =
    Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
  const charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let password = '';
  let hasUpper = false;
  let hasLower = false;
  let hasDigit = false;

  while (!(hasUpper && hasLower && hasDigit)) {
    password = '';
    hasUpper = false;
    hasLower = false;
    hasDigit = false;
    for (let i = 0, n = charset.length; i < length; ++i) {
      const char = charset.charAt(Math.floor(Math.random() * n));
      if (/[A-Z]/.test(char)) hasUpper = true;
      if (/[a-z]/.test(char)) hasLower = true;
      if (/[0-9]/.test(char)) hasDigit = true;
      password += char;
    }
  }
  return password;
};
