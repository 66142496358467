import { Notification } from '@webinex/wispo';
import { ComponentType, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const DATA_SEPARATOR = '::';

export type NotificationSettings<T> =
  | {
      Subject: ComponentType<{ payload: T }>;
      Body?: ComponentType<{ payload: T; read: boolean; onRead: () => void }>;
    }
  | ((payload: T) => UseNotificationResult);

export const notificationSettings: Record<
  string,
  NotificationSettings<any>
> = {};

export function parseNotificationData(data: string): {
  type: string;
  payload: any;
} {
  const separatorIndex = data.indexOf(DATA_SEPARATOR);
  const type = data.substring(0, separatorIndex);
  const payloadJson = data.substring(separatorIndex + DATA_SEPARATOR.length);
  const payload = JSON.parse(payloadJson);
  return { type, payload };
}

function UnknownSubject() {
  const { t } = useTranslation();
  return <div>{t('notifications.unknown')}</div>;
}

export interface UseNotificationResult {
  subject: ReactNode;
  body?: ReactNode;
}

export function useNotification(
  notification: Notification,
  onRead: () => void,
): UseNotificationResult | null {
  const { subject: data } = notification;

  return useMemo(() => {
    if (!data) {
      return null;
    }

    const { type, payload } = parseNotificationData(data);
    const settings = notificationSettings[type];

    if (!settings) {
      return { subject: <UnknownSubject /> };
    }

    if (typeof settings === 'function') {
      return settings(payload);
    }

    const { Subject, Body } = settings;

    return {
      subject: <Subject payload={payload} />,
      body: Body && (
        <Body payload={payload} onRead={onRead} read={notification.read} />
      ),
    };
  }, [data, notification.read, onRead]);
}
