import { DateValue } from '@/components';
import { Card } from 'antd';
import Title from 'antd/lib/typography/Title';
import { NavLink } from 'react-router-dom';
import { ActivityKind } from '../Activity/ActivityKind';
import { useActivityOperationState } from './ActivityOperationStateContext';

export function ActivityOperationItem({ id }: { id: string }) {
  const state = useActivityOperationState();
  const value = state.itemById[id];
  const child = Object.values(state.itemById).filter((x) => x.parentId === id);

  return (
    <>
      <Card className="mb-2">
        <Title level={5} className="mb-0">
          <NavLink to={`/activity/${value.id}`}>
            <ActivityKind>{value.kind}</ActivityKind>
          </NavLink>
        </Title>
        <DateValue value={value.performedAt} className="text-small" />
      </Card>
      {child.length > 0 && (
        <div className="ms-5">
          {child.map((item) => (
            <ActivityOperationItem key={item.id} id={item.id} />
          ))}
        </div>
      )}
    </>
  );
}
