import { TextBox, EditIconButton, DeleteIconButton, Title } from '@/components';
import {
  useGetPathTemplateDetailsQuery,
  useUserContextSelector,
} from '@/redux';
import { useTranslation } from 'react-i18next';
import { PathTemplateStepList } from '@/views';
import { useNavigate } from 'react-router-dom';
import { useBoundUnlistPathTemplate } from './useUnlistPathTemplate';

interface Props {
  id: string;
  readonly?: boolean;
  editRoute: (id: string) => string;
  includeCommunityLevel: boolean;
}

export function PathTemplateDetailsPanel(props: Props) {
  const { id, readonly, editRoute, includeCommunityLevel } = props;
  const { data } = useGetPathTemplateDetailsQuery({
    id,
    includeCommunityLevel,
  });
  const [isBFP, organizationId, hasRole] = useUserContextSelector((x) => [
    x.isBFP,
    x.organizationId,
    x.hasRole,
  ]);
  const { t } = useTranslation();
  const push = useNavigate();
  const handleUnlist = useBoundUnlistPathTemplate(data?.id);

  if (!data) {
    return null;
  }

  const { name } = data;
  const canModify =
    (isBFP ||
      (data.organizationId === organizationId && hasRole('CLIENT_ADMIN'))) &&
    !readonly;

  return (
    <div>
      <Title className="mt-0">
        {t('pathTemplates.details.general')}

        <Title.Actions inline space>
          {canModify && (
            <EditIconButton type="link" onClick={() => push(editRoute(id))} />
          )}
          {canModify && <DeleteIconButton onClick={handleUnlist} />}
        </Title.Actions>
      </Title>
      <TextBox label={t('pathTemplates.details.name')}>{name}</TextBox>
      <TextBox label={t('pathTemplates.details.stepsDetails')}>
        <PathTemplateStepList value={data} />
      </TextBox>
    </div>
  );
}
