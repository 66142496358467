import { NavLink } from '@/components';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './HomeProjectLink.module.scss';
import { useBool } from '@/utils';
import { AddFloorplanModal } from './AddFloorplanModal';

interface Props {
  homeId: string;
  communityId?: string;
  floorplanId?: string;
  floorplanName?: string;
}

export const HomeFloorplanLink = (props: Props) => {
  const { homeId, communityId, floorplanId, floorplanName } = props;
  const [modalOpen, , toggleModalOpen] = useBool(false);
  const { t } = useTranslation();

  return (
    <div>
      <span className={styles.label}>{t('homes.details.floorplan')}</span>
      {floorplanId ? (
        <NavLink to={`/communities/${communityId}/floorplans/${floorplanId}`}>
          {floorplanName}
        </NavLink>
      ) : (
        <Button
          type="link"
          className={styles.addToProject}
          onClick={toggleModalOpen}
        >
          {t('homes.details.add')}
        </Button>
      )}
      {modalOpen && (
        <AddFloorplanModal
          homeId={homeId}
          communityId={communityId!}
          onClose={toggleModalOpen}
        />
      )}
    </div>
  );
};
