import { DateTimeValue, DeleteIconButton, EditIconButton } from '@/components';
import {
  TaskCommentState,
  UpdateTaskCommentArgs,
  useUserContextSelector,
} from '@/redux';
import { Comment } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormTaskComment, UpdateTaskCommentFormValue } from './FormTaskComment';
import styles from './TaskCommentsPanel.module.scss';

interface Props {
  value: TaskCommentState;
  onDelete: (value: TaskCommentState) => any;
  onSubmit: (value: UpdateTaskCommentFormValue) => any;
  canAdd?: boolean;
}

export function TaskCommentItem(props: Props) {
  const { value, onDelete, onSubmit, canAdd } = props;

  const { t } = useTranslation();

  const [isEdit, setEdit] = useState(false);

  const userId = useUserContextSelector((x) => x.id);

  const handleSubmit = (value: UpdateTaskCommentFormValue) => {
    onSubmit(value);
    setEdit(false);
  };

  return (
    <div className="mb-1">
      {isEdit ? (
        <FormTaskComment<UpdateTaskCommentArgs>
          value={value}
          onSubmit={handleSubmit}
          onCancel={() => setEdit(false)}
        />
      ) : (
        <Comment
          content={<div className="text-multiline">{value.content}</div>}
          author={value.createdByName}
          datetime={
            <>
              <DateTimeValue value={value.createdAt} />
              {userId === value.createdById && canAdd && (
                <span className={styles.actionIcons}>
                  <EditIconButton type="link" onClick={() => setEdit(true)} />
                  <DeleteIconButton
                    confirm
                    entityName={t('tasks.comments.entityName')}
                    type="link"
                    onClick={() => onDelete(value)}
                  />
                </span>
              )}
            </>
          }
        />
      )}
    </div>
  );
}
