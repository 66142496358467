import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddFloorplanArgs, useAddFloorplanMutation } from '@/redux';
import { useNavigate } from 'react-router-dom';
import { FloorplanDetailsModal } from '@/views';
import { AddButton, notify } from '@/components';
import { FloorplanDto } from '@/core';

interface Props {
  className?: string;
  communityId: string;
}

const useSubmit = (props: Props) => {
  const { communityId } = props;
  const [add] = useAddFloorplanMutation();
  const push = useNavigate();

  return (args: FloorplanDto) =>
    add(args)
      .unwrap()
      .then((id) => {
        notify.success.t('floorplans.added');
        push(`/communities/${communityId}/floorplans/${id}`);
      });
};

export function AddFloorplanButton(props: Props) {
  const submit = useSubmit(props);
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const { className, communityId } = props;

  const initialValue: AddFloorplanArgs = {
    communityId: communityId,
    marketingName: null!,
    constructionName: null!,
    type: null!,
    squareFootage: null!,
    description: null!,
  };

  return (
    <div className={className}>
      <AddButton type="primary" onClick={() => setOpenModal(true)}>
        {t('floorplans.details.addButton')}
      </AddButton>
      {openModal && (
        <FloorplanDetailsModal
          title={t('floorplans.details.addTitle')}
          buttonSaveName={t('floorplans.details.saveButton')}
          onSubmit={submit}
          value={initialValue}
          onClose={() => setOpenModal(false)}
        />
      )}
    </div>
  );
}
