import { DeleteIconButton, StyledRow } from '@/components';
import { Button, Typography } from 'antd';
import { AttachmentDto, BookletLinkDto } from '@/core';
import { t } from 'i18next';
import styles from './BookletLink.module.scss';

const { Text } = Typography;

interface Props {
  readonly?: boolean;
  attachment: AttachmentDto;
  bookletLink: BookletLinkDto;
  onDelete?: (attachmentId: string) => any;
  onOpen: (bookletLink: BookletLinkDto) => Promise<void>;
}

export function BookletLinkItem(props: Props) {
  const { attachment, bookletLink, onDelete, onOpen, readonly } = props;

  if (!attachment) {
    return null;
  }

  return (
    <StyledRow className={styles.bookletLinkRow}>
      <Button type="link" onClick={() => onOpen(bookletLink)}>
        <Text underline className={styles.fileName}>
          <span>{attachment.fileName}</span>
        </Text>
        <Text className={styles.page} type="secondary">
          <span>(page {bookletLink.page})</span>
        </Text>
      </Button>
      {!readonly && (
        <DeleteIconButton
          confirm
          className={styles.delete}
          entityName={t('bookletLinks.entityName')}
          type="link"
          onClick={() => onDelete!(bookletLink.id)}
        />
      )}
    </StyledRow>
  );
}
