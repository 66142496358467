import { StringFilterOptions, formatters } from '@/core';
import { TaskFinancialInfoDto } from '@/core/http.tasks';
import { TaskFinancialInfoState } from '@/redux';
import { useFiltersFactory } from '@/utils';
import { TableColumnType } from '@/components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { TaskActualCostEditableValue } from './TaskActualCostEditableValue';

function calcBalance(row: TaskFinancialInfoState) {
  return row.estimatedCost - (row.actualCost ?? 0);
}

export function useTaskFinancialInfoColumns(
  tasks?: TaskFinancialInfoDto[],
  stringFilterOptions?: StringFilterOptions,
) {
  const { t } = useTranslation(undefined, { keyPrefix: 'tasks.financialInfo' });
  const filters = useFiltersFactory(tasks, stringFilterOptions);

  return useMemo<TableColumnType<TaskFinancialInfoState>[]>(() => {
    const columns: Array<TableColumnType<TaskFinancialInfoState> | false> = [
      {
        title: t('number'),
        key: 'number',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.number.localeCompare(b.number),
        render: (_, item) => item.number,
        width: '10%',
        ...filters.select((x) => x.number, 'number'),
      },
      {
        title: t('name'),
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        ...filters.select((x) => x.name, 'name'),
        render: (_, { id, name }) => (
          <NavLink to={`/tasks/${id}`}>{name}</NavLink>
        ),
        width: '15%',
      },
      {
        title: t('home'),
        key: 'home',
        sorter: (a, b) => a.homeName.localeCompare(b.homeName),
        ...filters.select((x) => x.homeName, 'home'),
        render: (_, item) => item.homeName,
        width: '15%',
      },
      {
        title: t('responsiblePerson'),
        key: 'responsiblePerson',
        sorter: (a, b) =>
          a.responsiblePerson.label!.localeCompare(b.responsiblePerson.label!),
        ...filters.select(
          (x) => x.responsiblePerson.label,
          'responsiblePerson',
        ),
        render: (_, item) => item.responsiblePerson.label,
        width: '15%',
      },
      {
        title: t('estimatedCost'),
        key: 'estimatedCost',
        align: 'right',
        sorter: (a, b) => a.estimatedCost - b.estimatedCost,
        ...filters.numberRange((x) => x.estimatedCost),
        render: (_, item) => formatters.price(item.estimatedCost),
        width: '15%',
      },
      {
        title: t('actualCost'),
        key: 'actualCost',
        align: 'right',
        sorter: (a, b) => (a?.actualCost ?? 0) - (b?.actualCost ?? 0),
        ...filters.numberRange((x) => x.actualCost),
        render: (_, item) => <TaskActualCostEditableValue value={item} />,
        exportValue: (item) =>
          item.actualCost ? formatters.money(item.actualCost)! : '',
        width: '15%',
      },
      {
        title: t('budgetBalance'),
        key: 'budgetBalance',
        align: 'right',
        sorter: (a, b) => calcBalance(a) - calcBalance(b),
        ...filters.numberRange((x) => calcBalance(x)),
        render: (_, item) => formatters.price(calcBalance(item)),
        width: '15%',
      },
    ];
    return columns.filter(
      (x) => !!x,
    ) as TableColumnType<TaskFinancialInfoState>[];
  }, [filters, t]);
}
