import { notify } from '@/components';
import { AddBookletLinkDto, BookletLinkDto, useAppClippo } from '@/core';
import {
  useAddItemBookletLinkMutation,
  useDeleteItemBookletLinkMutation,
  useGetItemDetailsQuery,
} from '@/redux';
import { openPdfWithPageInNewWindow } from '@/utils';
import { ClipContentDto } from '@webinex/clippo';
import { useCallback, useEffect } from 'react';
import { BookletLinks } from '../Items.BookletLink/BookletLinks';

const useBookletLinkOpen = (
  content: (id: string) => Promise<ClipContentDto>,
) => {
  return useCallback(
    async (bookletLink: BookletLinkDto) => {
      const clip = await content(bookletLink.attachmentId);
      openPdfWithPageInNewWindow(
        {
          fileName: clip.fileName,
          blob: clip.blob,
        },
        bookletLink.page,
      );
    },
    [content],
  );
};

const useBookletLinkSubmit = (itemId: string) => {
  const [add] = useAddItemBookletLinkMutation();

  return (args: AddBookletLinkDto) =>
    add({ ...args, itemId })
      .unwrap()
      .then(() => {
        notify.success.t('bookletLinks.added');
      });
};

const useBookletLinkDelete = (itemId: string) => {
  const [remove] = useDeleteItemBookletLinkMutation();

  return (id: string) =>
    remove({ itemId, id })
      .unwrap()
      .then(() => {
        notify.success.t('bookletLinks.deleted');
      });
};

interface Props {
  itemId: string;
  readonly?: boolean;
}

export function ItemDetailsBookletLinks(props: Props) {
  const { itemId, readonly } = props;
  const bookletLinkSubmit = useBookletLinkSubmit(itemId);
  const onBookletLinkDelete = useBookletLinkDelete(itemId);
  const { currentData: data } = useGetItemDetailsQuery({
    id: itemId,
  });

  const { value, content, fetch } = useAppClippo({
    defer: false,
    ownerId: data?.communityId,
    ownerType: 'CommunityAmenities',
    noPreload: true,
  });

  const handleOpen = useBookletLinkOpen(content);
  const items = value.items;
  const bookletLinks = data?.bookletLinks;

  useEffect(() => {
    if (data?.communityId) {
      fetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.communityId]);

  if (!bookletLinks) {
    return null;
  }

  return (
    <BookletLinks
      readonly={readonly}
      onOpen={handleOpen}
      bookletLinks={bookletLinks}
      attachments={items}
      onCreate={bookletLinkSubmit}
      onDelete={onBookletLinkDelete}
    />
  );
}
