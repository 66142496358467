import { isArray } from 'lodash';
import qs from 'qs';
import { Http, asFileResponse } from '../http';
import {
  GenerateCustomReportDto,
  GeneratePersonalizationSummaryReportDto,
  GenerateTrendReportDto,
  ReportingCommunityDto,
  ReportingHomeDto,
  ReportingLocationDto,
  ReportingProjectDto,
} from './reportsTypes';

class ReportsHttp extends Http {
  public generateCustom = async (
    request: GenerateCustomReportDto,
  ): Promise<string> => {
    const response = await this._axios.post('/api/report/custom', request);
    return response.data;
  };

  public generateHomeStatus = async ({
    projectId,
    locationId,
    homeId,
  }: {
    projectId: string | string[];
    locationId?: string | string[];
    homeId?: string | string[];
  }): Promise<string> => {
    const query = qs.stringify({
      projectIds: isArray(projectId) ? projectId : [projectId],
      locationIds: isArray(locationId) ? locationId : [locationId],
      homeIds: isArray(homeId) ? homeId : [homeId],
    });
    const response = await this._axios.post(`/api/report/home-status?${query}`);
    return response.data;
  };

  public generateTrend = async (
    request: GenerateTrendReportDto,
  ): Promise<string> => {
    const response = await this._axios.post(`/api/report/trend`, request);
    return response.data;
  };

  public async getCommunitites(clientId?: string[] | string) {
    const response = await this._axios.get<ReportingCommunityDto[]>(
      `/api/report/communities?${qs.stringify({
        clientIds: isArray(clientId) ? clientId : [clientId],
      })}`,
    );
    return response.data;
  }

  public async getProjects(
    clientId?: string | string[],
    communityId?: string | string[],
  ) {
    const query = qs.stringify({
      clientIds: isArray(clientId) ? clientId : [clientId],
      communityIds: isArray(communityId) ? communityId : [communityId],
    });
    const url = `/api/report/projects?${query}`;
    const response = await this._axios.get<ReportingProjectDto[]>(url);
    return response.data;
  }

  public async getHomes(
    communityId?: string | string[],
    projectId?: string | string[],
    withMenu?: boolean,
  ) {
    const query = qs.stringify({
      communityIds: isArray(communityId) ? communityId : [communityId],
      projectIds: isArray(projectId) ? projectId : [projectId],
      withMenu,
    });
    const url = `/api/report/homes?${query}`;
    const response = await this._axios.get<ReportingHomeDto[]>(url);
    return response.data;
  }

  public async getLocations(
    communityId?: string | string[],
    projectId?: string | string[],
  ) {
    const query = qs.stringify({
      communityIds: isArray(communityId) ? communityId : [communityId],
      projectIds: isArray(projectId) ? projectId : [projectId],
    });
    const url = `/api/report/locations?${query}`;
    const response = await this._axios.get<ReportingLocationDto[]>(url);
    return response.data;
  }

  public async generatePersonalizationSummary(
    args: GeneratePersonalizationSummaryReportDto,
  ) {
    const query = qs.stringify(args);
    const response = await this._axios.get(
      `/api/report/personalization-summary?${query}`,
      { responseType: 'blob' },
    );
    return asFileResponse(response);
  }
}

export const reportsHttp = new ReportsHttp();
