import { DateValue, EnumValue } from '@/components';
import { Select, Space } from 'antd';
import { PersonalizationHistoryState } from '@/redux';
import { ProjectStatus, ProjectType } from '@/core';
import classNames from 'classnames';
import styles from './FloorplanPersonalization.module.scss';
import { useTranslation } from 'react-i18next';

export interface PersonalizationHistorySelectProps {
  className?: string;
  selected: string;
  onSelect: (projectId: string) => any;
  value: PersonalizationHistoryState[];
}

export function PersonalizationHistorySelect(
  props: PersonalizationHistorySelectProps,
) {
  const { selected, onSelect, value, className } = props;
  const { t } = useTranslation();

  if (value.length <= 1 && value.at(0)?.status === 'InProgress') return null;

  return (
    <div className={classNames(className, styles.personalizationHistory)}>
      <div>{t('personalization.history')}</div>
      <Select<string>
        value={selected}
        labelInValue={false}
        onSelect={onSelect}
        showSearch={false}
      >
        {value.map((value) => (
          <Select.Option key={value.projectId}>
            <Space direction="horizontal">
              {value.projectName}
              {' - '}
              <EnumValue type={ProjectType} value={value.projectType} />
              {' - '}
              {value.finalizedAt && <DateValue value={value.finalizedAt} />}
              {value.finalizedAt && ' - '}
              <EnumValue type={ProjectStatus} value={value.status} />
            </Space>
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}
