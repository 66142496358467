import { useState } from 'react';
import { ClientDetailsModal } from '@/views';
import {
  useGetClientDetailsQuery,
  useUpdateClientMutation,
  ClientState,
} from '@/redux';
import { useTranslation } from 'react-i18next';
import { notify } from '@/components';

const useSubmit = (closeModal: () => any) => {
  const [updateClient] = useUpdateClientMutation();

  return async (values: ClientState) => {
    await updateClient(values)
      .unwrap()
      .then(() => {
        notify.success.t('clients.details.saved');
        closeModal();
      });
  };
};

export function useEditClientAction() {
  const [id, setId] = useState<string>();
  const { currentData } = useGetClientDetailsQuery({ id: id! }, { skip: !id });
  const { t } = useTranslation();
  const submit = useSubmit(() => setId(undefined));

  return {
    openEdit: (id: string) => setId(id),
    editModal: (
      <>
        {id && currentData && (
          <ClientDetailsModal
            title={t('clients.details.editTitle')}
            buttonSaveName={t('clients.details.editButton')}
            onSubmit={submit}
            value={currentData}
            onClose={() => setId(undefined)}
          />
        )}
      </>
    ),
  };
}
