import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { CopyPricingModal } from '@/views';
import { useCopyPricingMutation } from '@/redux';
import { notify } from '@/components';

interface Props {
  currentMenuId: string;
  communityId: string;
}

const useSubmitCopyPrising = (setOpenModal: (value: boolean) => any) => {
  const [copy] = useCopyPricingMutation();
  return(args: {fromMenuId: string, toMenuId: string}) => {
    copy(args)
      .unwrap()
      .then(() => {
        notify.success.t('pricing.copyPricing.saved');
        setOpenModal(false);
      });
  }
}

export function useCopyPricing(props: Props) {
  const { currentMenuId, communityId } = props;
  const { t } = useTranslation();
  const [ openModal, setOpenModal ] = useState(false);
  const submit = useSubmitCopyPrising(setOpenModal);

  return (
    <>
      <Button className="mb-3" onClick={() => setOpenModal(true)}>
        {t('pricing.actions.copyPricing')}
      </Button>
      {openModal && (
        <CopyPricingModal
          currentMenuId={currentMenuId}
          communityId={communityId}
          onSubmit={submit}
          onClose={() => setOpenModal(false)}
        />
      )}
    </>
  )
}