import { useCallback, useMemo, useState } from 'react';
import { UserDetailsModal, UserDetailsModalProps } from '@/views';
import { useTranslation } from 'react-i18next';
import { useInviteUserMutation, UserInviteArgs } from '@/redux';
import { AddButton, notify } from '@/components';
import { BFP_ROLES, CLIENT_ROLES, Role } from '@/core';
import { BFP_ORGANIZATION_ID } from '@/platform';

interface Props {
  className?: string;
  organizationId?: string;
  initialRole?: Role;
  roles: Role[];
  disableClientSelect?: boolean;
}

function useInitialValue(props: Props) {
  const { organizationId, initialRole } = props;

  return useMemo<UserInviteArgs>(
    () => ({
      firstName: null!,
      lastName: null!,
      email: null!,
      phone: null!,
      role: initialRole ?? null!,
      organizationId: organizationId ?? null!,
    }),
    [organizationId, initialRole],
  );
}

const useSubmit = (setOpenModal: (value: boolean) => any) => {
  const [invite] = useInviteUserMutation();

  return useCallback(
    async (args: UserInviteArgs) => {
      await invite(args)
        .unwrap()
        .then(() => setOpenModal(false))
        .then(() => notify.success.t('users.added'));
    },
    [invite, setOpenModal],
  );
};

function AddUserButtonBase(
  props: Props &
    Pick<
      UserDetailsModalProps<UserInviteArgs>,
      'noRoleSelect' | 'noClientSelect'
    >,
) {
  const { className, roles, organizationId, initialRole, ...other } = props;
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const submit = useSubmit(setOpenModal);
  const initialValue = useInitialValue(props);

  return (
    <div className={className}>
      <AddButton type="primary" onClick={() => setOpenModal(true)}>
        {t('users.details.addTitle')}
      </AddButton>
      {openModal && (
        <UserDetailsModal
          title={t('users.details.addTitle')}
          saveBtnText={t('users.details.addBtnText')}
          onSubmit={submit}
          value={initialValue}
          roles={roles}
          onClose={() => setOpenModal(false)}
          {...other}
        />
      )}
    </div>
  );
}

type AddBFPUserButtonProps = Omit<
  Props,
  'roles' | 'initialRole' | 'organizationId'
>;
export function AddBFPUserButton(props: AddBFPUserButtonProps) {
  return (
    <AddUserButtonBase
      {...props}
      organizationId={BFP_ORGANIZATION_ID}
      initialRole="BFP_ADMIN"
      roles={BFP_ROLES}
      noRoleSelect
      noClientSelect
    />
  );
}

type AddClientUserButtonProps = Omit<Props, 'roles' | 'initialRole'>;
export function AddClientUserButton(props: AddClientUserButtonProps) {
  return (
    <AddUserButtonBase {...props} roles={CLIENT_ROLES} noRoleSelect={false} />
  );
}
