import { Form, StyledRow } from '@/components';
import { Button, Col } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import {
  BulkUpdateTaskField,
  BulkUpdateTaskFieldType,
  BULK_UPDATE_TASK_VALUES,
  TaskStatus,
} from '@/core';
import { MAX_VALUE } from './TaskDetailsModal';
import { useEffect, useMemo } from 'react';
import { useField, useFormikContext } from 'formik';
import { useResponsiblePersonsLookupSource } from '../Tasks.Common';
import { nameOf } from '@/utils';

export interface FormBulkUpdatePropertyProps {
  name: string;
  communityId: string;
  required: boolean;
  deletable: boolean;
  onDelete: () => any;
}

export interface BulkUpdateTaskFormField {
  type: BulkUpdateTaskFieldType;
  startDate?: string;
  endDate?: string;
  personResponsible?: string;
  costEstimate?: number;
  status?: TaskStatus;
}

export const INITIAL_FIELD: BulkUpdateTaskFormField = {
  type: null!,
};

export const fieldsPerType = {
  [BulkUpdateTaskFieldType.PersonResponsible]: (
    field: BulkUpdateTaskFormField,
  ) => field.personResponsible!,
  [BulkUpdateTaskFieldType.CostEstimate]: (field: BulkUpdateTaskFormField) =>
    JSON.stringify(field.costEstimate!),
  [BulkUpdateTaskFieldType.StartDate]: (field: BulkUpdateTaskFormField) =>
    field.startDate!,
  [BulkUpdateTaskFieldType.EndDate]: (field: BulkUpdateTaskFormField) =>
    field.endDate!,
  [BulkUpdateTaskFieldType.Status]: (field: BulkUpdateTaskFormField) =>
    field.status!,
};

export const FormBulkUpdateProperty = (props: FormBulkUpdatePropertyProps) => {
  const { name, communityId, deletable, onDelete } = props;

  const { setFieldValue } = useFormikContext();
  const [{ value }] = useField<BulkUpdateTaskField>(name);
  const [{ value: fields }] = useField<BulkUpdateTaskField[]>('fields');

  const namesPerType = useMemo(
    () => ({
      [BulkUpdateTaskFieldType.PersonResponsible]: `${name}.${nameOf<BulkUpdateTaskFormField>(
        'personResponsible',
      )}`,
      [BulkUpdateTaskFieldType.CostEstimate]: `${name}.${nameOf<BulkUpdateTaskFormField>(
        'costEstimate',
      )}`,
      [BulkUpdateTaskFieldType.StartDate]: `${name}.${nameOf<BulkUpdateTaskFormField>(
        'startDate',
      )}`,
      [BulkUpdateTaskFieldType.EndDate]: `${name}.${nameOf<BulkUpdateTaskFormField>(
        'endDate',
      )}`,
      [BulkUpdateTaskFieldType.Status]: `${name}.${nameOf<BulkUpdateTaskFormField>(
        'status',
      )}`,
    }),
    [name],
  );

  const responsiblePersonDataSource =
    useResponsiblePersonsLookupSource(communityId);

  const inputsPerType = useMemo(
    () => ({
      [BulkUpdateTaskFieldType.CostEstimate]: (
        <Form.Number
          required
          name={namesPerType[BulkUpdateTaskFieldType.CostEstimate]}
          prefix="$"
          min={0}
          max={MAX_VALUE}
        />
      ),
      [BulkUpdateTaskFieldType.PersonResponsible]: (
        <Form.CreatableAsyncSelect
          required
          name={namesPerType[BulkUpdateTaskFieldType.PersonResponsible]}
          dataSource={responsiblePersonDataSource}
          className="w-100"
        />
      ),
      [BulkUpdateTaskFieldType.StartDate]: (
        <Form.Date
          required
          name={namesPerType[BulkUpdateTaskFieldType.StartDate]}
          allowClear={false}
        />
      ),
      [BulkUpdateTaskFieldType.EndDate]: (
        <Form.Date
          required
          name={namesPerType[BulkUpdateTaskFieldType.EndDate]}
          allowClear={false}
        />
      ),
      [BulkUpdateTaskFieldType.Status]: (
        <Form.EnumSelect
          type={TaskStatus}
          required
          name={namesPerType[BulkUpdateTaskFieldType.Status]}
          allowClear={false}
        />
      ),
    }),
    [namesPerType, responsiblePersonDataSource],
  );

  useEffect(() => {
    setFieldValue(namesPerType[value.type], undefined);
  }, [namesPerType, setFieldValue, value.type]);

  const availableTypes = useMemo(() => {
    const usedTypes = fields
      .filter((field) => field !== value)
      .map((field) => field.type);
    return BULK_UPDATE_TASK_VALUES.filter(
      (value) => !usedTypes.includes(value),
    );
  }, [value, fields]);

  return (
    <StyledRow>
      <Col span={11}>
        <Form.EnumSelect
          values={availableTypes}
          required
          name={`${name}.type`}
          type={BulkUpdateTaskFieldType}
        />
      </Col>
      <Col span={11}>{inputsPerType[value.type]}</Col>
      <Col span={2}>
        {deletable && (
          <Button type="link" icon={<CloseOutlined />} onClick={onDelete} />
        )}
      </Col>
    </StyledRow>
  );
};
