import { DownloadedFile } from '@/core';
import { browserDownload, openInNewWindow } from './browserUtils';

export const browserFileDownload = (file: DownloadedFile) => {
  browserDownload(file.fileName, file.blob);
};

export const openFileInNewWindow = (file: DownloadedFile) => {
  const url = window.URL.createObjectURL(file.blob);
  openInNewWindow(url);
};

export const openPdfWithPageInNewWindow = (
  file: DownloadedFile,
  page: number,
) => {
  const url = window.URL.createObjectURL(file.blob);
  openInNewWindow(url + `#page=${page}`);
};
