import { AttachmentDto, BookletLinkDto } from '@/core';
import { List } from 'antd';
import { isEmpty } from 'lodash';
import { BookletLinkItem } from './BookletLinkItem';

interface Props {
  readonly?: boolean;
  bookletLinks: BookletLinkDto[];
  attachments: AttachmentDto[];
  onDelete?: (attachmentId: string) => any;
  onOpen: (booklet: BookletLinkDto) => Promise<void>;
}

export function ItemBookletLinkList(props: Props) {
  const { bookletLinks, attachments, onDelete, onOpen, readonly } = props;

  if (isEmpty(bookletLinks)) {
    return null;
  }

  return (
    <List
      itemLayout="horizontal"
      dataSource={bookletLinks}
      rowKey={(row) => row.id}
      renderItem={(booklet) => {
        const attachment = attachments.find(
          (att) => att.id === booklet.attachmentId,
        );
        return (
          <BookletLinkItem
            key={booklet.id}
            onOpen={onOpen}
            onDelete={onDelete}
            attachment={attachment!}
            bookletLink={booklet}
            readonly={readonly}
          />
        );
      }}
    />
  );
}
