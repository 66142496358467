import { EnumValue, TableColumnType } from '@/components';
import { RoomTypeDto, RoomTypeStatus } from '@/core/http.roomTypes';
import { useFiltersFactory } from '@/utils';
import { Tag } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RoomTypeActions } from './RoomTypeActions';
import styles from './RoomType.module.scss';

export function useRoomTypesColumns(
  roomTypes: RoomTypeDto[] | undefined,
  setEditRoomType: (value: RoomTypeDto) => any,
  onChangeStatus: (id: string, status: RoomTypeStatus) => void,
) {
  const { t } = useTranslation();

  const filters = useFiltersFactory(roomTypes);

  const statusColor = (status: RoomTypeStatus) =>
    status === RoomTypeStatus.Active ? 'cyan' : 'blue';

  return useMemo<TableColumnType<RoomTypeDto>[]>(() => {
    const columns: Array<TableColumnType<RoomTypeDto> | false> = [
      {
        title: t('roomTypes.name'),
        key: 'name',
        ...filters.plainText((x) => x.name),
        render: (_, item) => item.name,
      },
      {
        title: t('roomTypes.status'),
        key: 'status',
        ...filters.enumSelect((x) => x.status, RoomTypeStatus),
        render: (_, { status }) => (
          <Tag color={statusColor(status)} className={styles.statusText}>
            <EnumValue type={RoomTypeStatus} value={status} />
          </Tag>
        ),
      },
      {
        title: t('roomTypes.actions.title'),
        key: 'actions',
        align: 'right',
        render: (_, item) => (
          <RoomTypeActions
            onChangeStatus={onChangeStatus}
            item={item}
            setEditRoomType={setEditRoomType}
          />
        ),
      },
    ];

    return columns.filter((x) => !!x) as TableColumnType<RoomTypeDto>[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, filters]);
}
