import { Attach } from '@/components';
import { CommunityPermissions, useAppClippo } from '@/core';
import { useCommunityContext } from '@/views';

interface Props {
  id: string;
}

export const HomeAttachments = (props: Props) => {
  const { id } = props;
  const clippoProps = useAppClippo({
    defer: false,
    ownerId: id,
    ownerType: 'Home',
  });

  const { active, hasPermission } = useCommunityContext();
  const readonly =
    !active || !hasPermission(CommunityPermissions.HomeDocuments.Manage);

  return (
    <Attach className="mt-3" {...clippoProps} readonly={readonly} multiple />
  );
};
