import { AttachmentDto } from '@/core';
import { array } from '@/utils';
import { useState, useCallback } from 'react';

export interface ImagesPreviewProps {
  items: AttachmentDto[];
  index?: number;
  onClose: () => any;
}

export type UseImagesPreviewResult = ImagesPreviewProps & {
  open: (item: AttachmentDto) => any;
  close: () => any;
};

export function useImagesPreview(
  items: AttachmentDto[] | undefined,
): UseImagesPreviewResult {
  const [index, setIndex] = useState<number>();
  items = items ?? array.empty<AttachmentDto>();

  const handleOpen = useCallback(
    (item: AttachmentDto) => setIndex(items!.indexOf(item)),
    [items, setIndex],
  );

  const handleClose = useCallback(() => setIndex(undefined), [setIndex]);

  return {
    items,
    index,
    open: handleOpen,
    close: handleClose,
    onClose: handleClose,
  };
}
