import { Http } from '../http/http';
import { guard } from '@/utils';
import { EditRoomArgs, RoomState } from './roomTypes';
import qs from 'qs';

class RoomsHttp extends Http {
  public async getAll(args: {
    floorplanId?: string;
    homeId?: string;
    communityId?: string;
  }) {
    const query = qs.stringify({ ...args });
    const url = `/api/room?${query}`;
    const response = await this._axios.get<RoomState[]>(url);
    return response.data;
  }

  public async create(request: EditRoomArgs) {
    guard.notNull(request, 'request');
    const response = await this._axios.post<string>(`/api/room`, request);
    return response.data;
  }

  public async update(request: EditRoomArgs) {
    guard.notNull(request, 'request');
    await this._axios.put(`/api/room`, request);
  }

  public async unlist(id: string) {
    guard.notNull(id, 'id');
    await this._axios.put(`/api/room/${id}/unlist`);
  }

  public getDetails = async (id: string) => {
    guard.notNull(id, 'id');
    const response = await this._axios.get<RoomState>(
      `/api/room/${id}/details`,
    );
    return response.data;
  };
}

export const roomsHttp = new RoomsHttp();
