import { AddButton, TabPanel } from '@/components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RoomTypesListPanel } from './RoomTypesListPanel';

export function RoomTypesListTabPanel() {
  const { t } = useTranslation();
  const [add, setAdd] = useState(false);

  return (
    <TabPanel min="auto">
      <TabPanel.Title>{t('roomTypes.title')}</TabPanel.Title>
      <TabPanel.Actions>
        <AddButton onClick={() => setAdd(true)}>{t('roomTypes.add')}</AddButton>
      </TabPanel.Actions>
      <TabPanel.Body>
        <RoomTypesListPanel isAdd={add} onCancel={() => setAdd(false)} />
      </TabPanel.Body>
    </TabPanel>
  );
}
