import { EnumValue, StyledRow, TextBox, Tree, YesNo } from '@/components';
import {
  ItemMenuItemDetailsDto,
  ItemMenuItemType,
  ItemMenuSuboptionType,
} from '@/core';
import { useGetItemMenuDetailsQuery } from '@/redux';
import { array } from '@/utils';
import { Col, Row } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ItemDetailsPanel } from '../Items.Details';
import { useItemNavSelectedState } from '../Items.List';
import { convertItemMenuItemsToTreeData } from './convertItemMenuItemsToTreeData';

interface Props {
  id: string;
}

function useTreeData(props: Props) {
  const { id } = props;
  const { data } = useGetItemMenuDetailsQuery({ id, listed: null });
  const { items = array.empty<ItemMenuItemDetailsDto>() } = data ?? {};

  return useMemo(() => convertItemMenuItemsToTreeData(items), [items]);
}

export function ItemMenuTreeDetails(props: Props) {
  const { id } = props;
  const { t } = useTranslation();
  const { data } = useGetItemMenuDetailsQuery({ id, listed: null });
  const { onSelected, selectedId } = useItemNavSelectedState();
  const treeData = useTreeData(props);

  const { items = array.empty<ItemMenuItemDetailsDto>() } = data ?? {};

  const onSelect = (selectedKeysValue: React.Key[]) => {
    const key = selectedKeysValue[0].toString();
    const selectedItem = items.find((x) => x.id === key);
    onSelected(selectedItem?.id);
  };

  const item = items.find((x) => x.id === selectedId);
  const selectedIds = item?.suboptions.map((x) => x.id) ?? [];
  const suboptions = item?.suboptions;

  return (
    <StyledRow>
      <Col span={10}>
        <Tree treeData={treeData} showIcon onSelect={onSelect} />
      </Col>
      <Col span={14}>
        {selectedId && (
          <ItemDetailsPanel
            id={selectedId}
            readonly
            includeUnlistedSuboptions={selectedIds}
            selectableSuboption={{
              type: 'check',
              disabled: true,
              selected: selectedIds,
              onSelect: null!,
            }}
            detailsFooter={
              <div>
                <TextBox
                  className="mb-2"
                  label={t('itemMenus.details.standardCredit')}
                >
                  <YesNo>{item?.standardCredit}</YesNo>
                </TextBox>
                <EnumValue type={ItemMenuItemType} value={item?.type} />
              </div>
            }
            renderSuboptionItem={({ item: { id }, content }) => (
              <Row wrap={false} align="middle">
                <Col flex="auto">{content}</Col>
                <Col flex="none">
                  <EnumValue
                    type={ItemMenuSuboptionType}
                    value={suboptions?.find((x) => x.id === id)?.type}
                  />
                </Col>
              </Row>
            )}
          />
        )}
      </Col>
    </StyledRow>
  );
}
