import { FormikContextType, useField, useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';

export interface FormsFieldChangeProps<TFieldValue, TFormValue = any> {
  name: string;
  onChange: (
    prevValue: TFieldValue,
    newValue: TFieldValue,
    formik: FormikContextType<TFormValue>,
  ) => any;
}

export function FormsFieldChange<TFieldValue, TFormValue = any>(
  props: FormsFieldChangeProps<TFieldValue, TFormValue>,
) {
  const { name, onChange } = props;
  const formikContext = useFormikContext<TFormValue>();
  const [{ value }] = useField<TFieldValue>(name);
  const prevValueRef = useRef(value);

  useEffect(() => {
    const prevValue = prevValueRef.current;
    prevValueRef.current = value;

    if (prevValue !== value) {
      onChange(prevValue, value, formikContext);
    }

    // eslint-disable-next-line
  }, [value]);

  return <></>;
}
