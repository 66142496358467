import { Button } from 'antd';
import { useCallback } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Form, useAppFindFormik } from '@/components';
import { DataNode } from 'antd/lib/tree';
import { dataNodeUtils } from '../Forms';
import { FormsTreeTexts } from '../Forms/FormsEditTree';

export interface TreeViewEditFormTexts extends FormsTreeTexts {}

const SCHEMA: Yup.AnySchema = Yup.object().shape({
  title: Yup.string().field().nullable().required(),
  children: Yup.lazy(() => Yup.array().of(SCHEMA)),
});

interface Props {
  uid: string;
  value: DataNode;
  onCancel: () => any;
  onSubmit: (value: DataNode) => any;
  texts: TreeViewEditFormTexts;
}

function useHandleDelete(props: Props) {
  const { uid } = props;
  const formikRef = useAppFindFormik(uid);

  return useCallback(
    (item: DataNode) =>
      formikRef.current!.setValues(
        dataNodeUtils.removeChild(formikRef.current!.values, item.key),
      ),
    [formikRef],
  );
}

function useHandleAdd(props: Props) {
  const { uid } = props;
  const formikRef = useAppFindFormik(uid);

  return useCallback(
    (parent: DataNode) =>
      formikRef.current!.setValues(
        dataNodeUtils.addChild(formikRef.current!.values, parent.key),
      ),
    [formikRef],
  );
}

export function TreeViewEditForm(props: Props) {
  const { value, uid, texts, onSubmit, onCancel } = props;
  const { t } = useTranslation();
  const handleDelete = useHandleDelete(props);
  const handleAdd = useHandleAdd(props);
  const isNew = dataNodeUtils.isNew(value);
  const submitBtnText = isNew ? t('add') : t('save');

  return (
    <Form.Formik
      uid={uid}
      initialValues={value}
      onSubmit={onSubmit}
      validationSchema={SCHEMA}
    >
      <Form.Tree
        maxDepth={2}
        texts={texts}
        onDelete={handleDelete}
        onAdd={handleAdd}
      />
      <Button onClick={onCancel}>{t('cancel')}</Button>
      <Form.Submit className="ms-2 mt-5" type="primary">
        {submitBtnText}
      </Form.Submit>
    </Form.Formik>
  );
}
