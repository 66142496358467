export interface Paging {
  skip?: number;
  take?: number;
  includeTotal?: boolean;
}

export const pagingFrom = (
  pointer: { current: number; pageSize: number },
  includeTotal: boolean,
): Paging => {
  return {
    skip: pointer.pageSize * (pointer.current - 1),
    take: pointer.pageSize,
    includeTotal,
  };
};

export const DEFAULT_PAGING: Paging = {
  includeTotal: false,
};
