import { rtkq, RtkqRequest } from '../rtkq';
import {
  AddResidentDto,
  ResidentDto,
  residentsHttp,
  UpdateResidentDto,
} from '@/core';
import { api } from '../api';

export type ResidentState = ResidentDto;
export type AddResidentArgs = AddResidentDto;
export type UpdateResidentArgs = UpdateResidentDto;

const residentsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getResidentsList: build.query<
      ResidentState[],
      RtkqRequest<{ homeId: string }>
    >({
      queryFn: async (args) => {
        return rtkq(args).exec(() => residentsHttp.getAll(args.homeId));
      },
      providesTags: (result = []) => [
        { type: 'resident', id: 'list' },
        ...result.map(({ id }) => ({ type: 'resident' as const, id })),
      ],
    }),

    getResidentDetails: build.query<ResidentState, RtkqRequest<{ id: string }>>(
      {
        queryFn: async (args) => {
          const { id } = args;
          return rtkq(args).exec(() => residentsHttp.get(id));
        },
        providesTags: (resident) => [
          { type: 'resident', id: resident?.id ?? 'none' },
        ],
      },
    ),

    addResident: build.mutation<void, RtkqRequest<AddResidentArgs>>({
      queryFn: (args) => {
        return rtkq(args).exec({
          query: () => residentsHttp.create(args),
        });
      },
      invalidatesTags: (_, __, args) => [
        { type: 'resident', id: 'list' },
        { type: 'home', id: args.homeId },
      ],
    }),

    reassignResident: build.mutation<
      void,
      RtkqRequest<{ id: string; homeId: string }>
    >({
      queryFn: (args) => {
        const { id, homeId } = args;
        return rtkq(args).exec({
          query: () => residentsHttp.reassign({ id, homeId }),
        });
      },
      invalidatesTags: (_, __, args) => [
        { type: 'resident', id: 'list' },
        { type: 'home', id: args.homeId },
      ],
    }),

    updateResident: build.mutation<void, RtkqRequest<UpdateResidentArgs>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => residentsHttp.update(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'resident', id: 'list' },
        { type: 'resident', id: args.id },
      ],
    }),

    deleteResident: build.mutation<void, RtkqRequest<{ id: string }>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => residentsHttp.delete(args.id));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'resident', id: 'list' },
        { type: 'resident', id: args.id },
      ],
    }),
  }),
});

export const {
  useReassignResidentMutation,
  useGetResidentsListQuery,
  useAddResidentMutation,
  useDeleteResidentMutation,
  useGetResidentDetailsQuery,
  useUpdateResidentMutation,
} = residentsApi;
