import { useTranslation } from 'react-i18next';
import {
  CommunityDetailsTabKeys, TaskTemplateDetailsPanel,
  useCommunityContext,
} from '@/views';
import { Icon, Page } from '@/components';
import { useParams } from 'react-router-dom';
import { useGetTaskTemplateDetailsQuery } from '@/redux';

const useId = () => {
  const { id } = useParams<'id'>();
  return id!;
};

export function TaskTemplateDetailsPage() {
  const { t } = useTranslation();
  const id = useId();
  const { active } = useCommunityContext();
  const { data } = useGetTaskTemplateDetailsQuery({ id });
  const title = data?.name ?? t('fetchFallbackTitle');
  const backRoute = `/communities/${data?.communityId}#${CommunityDetailsTabKeys['path-templates']}`;

  return (
    <Page htmlTitle={title}>
      <Page.Paths>
        <Page.Path href="/communities">{t('communities.title')}</Page.Path>
        <Page.Path
          href={backRoute}
        >
          {data?.communityName ?? t('fetchFallbackTitle')}
        </Page.Path>
        <Page.Path
          href={backRoute}
        >
          {t('taskTemplates.title')}
        </Page.Path>
        <Page.Path>{title}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card
          title={title}
          icon={<Icon type="template" />}
          color="#EFAC4E"
        >
          <TaskTemplateDetailsPanel
            id={id}
            readonly={!active}
            editRoute={(id) =>
              `/communities/${
                data!.communityId
              }/task-templates/${id}/edit`
            }
          />
        </Page.Card>
      </Page.Body>
    </Page>
  );
}
