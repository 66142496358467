import { Form, Title } from '@/components';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Space, Typography } from 'antd';
import {
  mapRoomTypeLookup,
  useRoomTypeLookupSource,
} from '../RoomTypes.Common/useRoomTypeLookupSource';
import { useMemo, useState } from 'react';
import { FormRoomCategories } from './FormRoomCategories';
import { FormRoomCategoryFootageList } from './FormRoomCategoryFootageList';
import { EditRoomArgs, RoomCategory } from '@/core';
import { get } from 'lodash';
import { useGetRoomDetailsQuery } from '@/redux';
import { FormRoomSource } from './FormRoomSource';

interface Props<T extends EditRoomArgs> {
  className?: string;
  value: T;
  roomTypeName: string;
  onCancel: () => any;
  onSubmit: (value: any) => any;
  communityId: string;
  isWholeHome: boolean;
  noTemplate?: boolean;
}

const SCHEMA = Yup.object().shape({
  name: Yup.string().field().nullable().required(),
  roomTypeId: Yup.string().field().nullable().required(),
  squareFootage: Yup.number().min(0).nullable().optional(),
  categories: Yup.array()
    .of(
      Yup.object()
        .shape({
          id: Yup.string().field().nullable().required(),
          squareFoots: Yup.number().min(0).optional(),
        })
        .required(),
    )
    .required(),
  useTemplate: Yup.boolean(),
  sourceTemplateId: Yup.string().when('useTemplate', {
    is: true,
    then: Yup.string().field().nullable().required(),
    otherwise: Yup.string().field().nullable().notRequired(),
  }),
});

const SCHEMA_WHOLE_HOME = Yup.object().shape({
  squareFootage: Yup.number().min(0).nullable().required(),
  categories: Yup.array()
    .of(
      Yup.object()
        .shape({
          id: Yup.string().field().nullable().required(),
          squareFoots: Yup.number().min(0).optional(),
        })
        .required(),
    )
    .required(),
});

export const INITIAL_VALUES: EditRoomArgs = {
  name: undefined!,
  squareFootage: undefined!,
  roomTypeId: undefined!,
  id: undefined!,
  categories: [],
};

export type FormRoomTemplateValue<T extends Omit<EditRoomArgs, 'categories'>> =
  T & {
    sourceTemplateId: string | null;
    useTemplate: boolean;
    categories: RoomCategory[];
    roomTypeId: string;
    roomTypeName: string;
  };

function useValue<TValue extends EditRoomArgs>(
  props: Props<TValue>,
  isWholeHome: boolean,
): FormRoomTemplateValue<TValue> | null {
  const id: string | undefined = isWholeHome
    ? undefined
    : get(props.value, 'id');
  const { data } = useGetRoomDetailsQuery({ id: id! }, { skip: !id });

  return useMemo<FormRoomTemplateValue<TValue> | null>(() => {
    if (!id) {
      return {
        ...props.value,
        useTemplate: false,
        sourceTemplateId: null,
        categories: props.value.categories,
        roomTypeId: props.value.roomTypeId,
        roomTypeName: props.roomTypeName,
      };
    }

    if (!data) {
      return null;
    }

    return {
      ...props.value,
      useTemplate: false,
      sourceTemplateId: null,
      name: props.value.name,
      categories: data.categories.map((category) => ({
        id: category.id,
        implicit: category.implicit,
        squareFootage: category.squareFootage,
      })),
      roomTypeId: props.value.roomTypeId,
      roomTypeName: data.roomTypeName,
    };
  }, [data, id, props.roomTypeName, props.value]);
}

export function FormRoom<TValue extends EditRoomArgs>(props: Props<TValue>) {
  const {
    className,
    value,
    onSubmit,
    onCancel,
    roomTypeName,
    communityId,
    isWholeHome,
    noTemplate,
  } = props;
  const { t } = useTranslation();
  const { roomTypeId, categories } = value;

  const title = isWholeHome
    ? t('rooms.editWholeHome')
    : !value.id
    ? t('rooms.add')
    : t('rooms.edit');

  const roomTypesDataSource = useRoomTypeLookupSource();

  const formValue = useValue(props, isWholeHome);
  const [typeId, setTypeId] = useState(roomTypeId);
  const [typeName, setTypeName] = useState(roomTypeName);

  const roomTypePreload = useMemo(
    () =>
      typeId ? mapRoomTypeLookup({ id: typeId, name: typeName }) : undefined,
    [typeId, typeName],
  );

  if (formValue == null) return null;

  return (
    <div className={className}>
      <Typography.Title className="mb-3" level={5}>
        {title}
      </Typography.Title>
      <Form.Formik<FormRoomTemplateValue<TValue>>
        uid={value.id}
        initialValues={formValue || value || INITIAL_VALUES}
        onSubmit={({ useTemplate, sourceTemplateId, ...value }) =>
          onSubmit(value as unknown as TValue)
        }
        validationSchema={isWholeHome ? SCHEMA_WHOLE_HOME : SCHEMA}
        i18n="rooms"
        enableReinitialize
      >
        {!noTemplate && !isWholeHome && (
          <FormRoomSource
            i18n="rooms.add"
            categoriesFieldName="categories"
            roomTypeFieldName="roomTypeId"
            communityId={communityId}
            setRoomTypeId={setTypeId}
            setRoomTypeName={setTypeName}
          />
        )}

        <Space direction="vertical" className="w-100">
          {!isWholeHome && (
            <>
              <Form.Input name="name" required />
              <Form.AsyncSelect
                key={value.roomTypeId}
                name="roomTypeId"
                dataSource={roomTypesDataSource}
                required
                preloadInitial={roomTypePreload}
              />
            </>
          )}

          <Form.Number
            key={value.roomTypeId}
            name="squareFootage"
            className="w-100"
            precision={2}
            max={100000}
            min={0}
          />

          <Title>{t('rooms.selectCategories')}</Title>
          <Row>
            <Col span={12}>
              <FormRoomCategories
                name="categories"
                communityId={communityId}
                initialValue={categories}
              />
            </Col>
            <Col span={12}>
              <FormRoomCategoryFootageList
                name="categories"
                communityId={communityId}
                initialCategories={categories}
              />
            </Col>
          </Row>
          <Space direction="horizontal">
            <Button onClick={onCancel}>{t('cancel')}</Button>
            <Form.Submit type="primary">{t('save')}</Form.Submit>
          </Space>
        </Space>
      </Form.Formik>
    </div>
  );
}
