import { Col } from 'antd';
import { FieldRow, Form, LookupOption } from '@/components';
import { useTranslation } from 'react-i18next';
import { useClientLookupSource, useCommunityLookupSource } from '@/views';
import { useEffect } from 'react';
import { useField, useFormikContext } from 'formik';

interface Props {
  isEdit: boolean;
  initialClient?: LookupOption;
  initialCommunity?: LookupOption;
}

export function CustomLegalLanguageFilterPanel({
  isEdit,
  initialClient,
  initialCommunity,
}: Props) {
  const clientDataSource = useClientLookupSource();
  const [{ value: clientId }] = useField('clientId');
  const communityDataSorce = useCommunityLookupSource(
    initialClient?.value || clientId || [],
  );
  const { t } = useTranslation(undefined, {
    keyPrefix: 'customLegalLanguage.details',
  });
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!isEdit) setFieldValue('communityId', undefined);
  }, [clientId, isEdit, setFieldValue]);
  return (
    <>
      <FieldRow>
        <Col span={12}>
          <Form.AsyncSelect
            dataSource={clientDataSource}
            name="clientId"
            disabled={isEdit}
            preloadInitial={initialClient}
            placeholder={t('placeholder.client')}
            required
          />
        </Col>
      </FieldRow>
      <FieldRow>
        <Col span={12}>
          <Form.AsyncSelect
            key={clientId}
            dataSource={communityDataSorce}
            name="communityId"
            disabled={isEdit}
            preloadInitial={initialCommunity}
            placeholder={t('placeholder.community')}
            required
          />
        </Col>
      </FieldRow>
    </>
  );
}
