import { Form } from '@/components';
import { useProjectLookupSource } from '@/views';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
}

export function ActivityProjectSelect(props: Props) {
  const { name } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'activity.table.filter.placeholders',
  });
  const projectDataSource = useProjectLookupSource();

  return (
    <Form.AsyncSelect
      label={false}
      dataSource={projectDataSource}
      name={name}
      placeholder={t('project')}
      mode="multiple"
    />
  );
}
