import { Modal, Button } from 'antd';
import { Form } from '@/components';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  GenerateTrendReportArgs,
  useGenerateTrendReportMutation,
  useUserContextSelector,
} from '@/redux';
import { FormGenerateTrendReport } from './FormGenerateTrendReport';
import { FileExcelOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';

interface FormValue extends GenerateTrendReportArgs {
  isBFPAdmin: boolean;
}

const schema = Yup.object().shape({
  isBFPAdmin: Yup.boolean(),
  startDate: Yup.date().nullable(),
  endDate: Yup.date()
    .when('startDate', (startDate, yup) => {
      if (startDate)
        return yup.min(startDate, {
          key: 'reports_end_date_must_be_after_start_date',
        });
    })
    .nullable(),
  clientId: Yup.string().when('isBFPAdmin', {
    is: true,
    then: Yup.string().nullable().required(),
    otherwise: Yup.string().nullable().notRequired(),
  }),
  communityId: Yup.string().nullable().required(),
  projectIds: Yup.array().of(Yup.string().nullable()),
  homeIds: Yup.array().of(Yup.string().nullable()),
});

const INITIAL_VALUE: GenerateTrendReportArgs = {
  startDate: null!,
  endDate: null!,
  clientId: null!,
  communityId: null!,
  projectIds: [],
  homeIds: [],
};

interface Props {
  onClose: () => any;
}

const useSubmit = (props: Props) => {
  const { onClose } = props;
  const [generate] = useGenerateTrendReportMutation();

  return (args: GenerateTrendReportArgs) => {
    generate({
      ...args,
      projectIds: isEmpty(args.projectIds) ? undefined : args.projectIds,
      homeIds: isEmpty(args.homeIds) ? undefined : args.homeIds,
    })
      .unwrap()
      .then((uri) => {
        window.open(
          '/api/report/inline?src=' + encodeURIComponent(uri),
          '_blank',
        );

        onClose();
      });
  };
};

export function GenerateTrendReportModal(props: Props) {
  const { onClose: onCancel } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'reports.trendReport',
  });
  const hasRole = useUserContextSelector((x) => x.hasRole);

  const handleSubmit = useSubmit(props);

  return (
    <Modal
      title={t('title')}
      visible
      onCancel={onCancel}
      footer={[
        <Button key="back" type="default" onClick={onCancel}>
          {t('cancel')}
        </Button>,
        <Form.Submit
          uid="reports-trendReport"
          key="submit"
          type="primary"
          icon={<FileExcelOutlined />}
        >
          {t('runReport')}
        </Form.Submit>,
      ]}
    >
      <Form.Formik<FormValue>
        uid="reports-trendReport"
        i18n="reports.trendReport"
        initialValues={{ ...INITIAL_VALUE, isBFPAdmin: hasRole('BFP_ADMIN') }}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <FormGenerateTrendReport />
      </Form.Formik>
    </Modal>
  );
}
