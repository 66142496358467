import { Http, Paged, Paging } from '@/core';
import { guard } from '@/utils';
import qs from 'qs';
import { SortResult } from '@/components/Collections.Paging/useSorting';
import {
  SignatureDto,
  SignatureFilterParams,
  UpdateSignatureDto,
} from './signaturesTypes';

class SignaturesHttp extends Http {
  public async getAll(
    paging: Paging,
    filters?: SignatureFilterParams,
    sorting?: SortResult,
  ) {
    const response = await this._axios.get<Paged<SignatureDto>>(
      `/api/signature?${qs.stringify({
        ...paging,
        ...filters,
        ...sorting,
      })}`,
    );
    return response.data;
  }

  public async update(args: UpdateSignatureDto) {
    guard.notNull(args, 'args');
    await this._axios.put(`/api/signature`, args);
  }
}

export const signaturesHttp = new SignaturesHttp();
