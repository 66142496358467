import { Col } from 'antd';
import { FieldRow, Form } from '@/components';
import { useTranslation } from 'react-i18next';
import { FloorplanType } from '@/core';
import { AddFloorplanArgs } from '@/redux';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  type: Yup.string().field().nullable().required(),
  marketingName: Yup.string().field().nullable().required(),
  constructionName: Yup.string().field().nullable(),
  description: Yup.string().field('lg').nullable(),
  squareFootage: Yup.number().min(0).nullable(),
});

export const FloorplanDetailsFormikForm = ({
  initialValues,
  onSubmit,
}: {
  initialValues: AddFloorplanArgs;
  onSubmit: (props?: any) => void;
}) => {
  const { t } = useTranslation();
  return (
    <Form.Formik
      uid="floorplan-details"
      i18n="floorplans.details"
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <FieldRow>
        <Col span={12}>
          <Form.Input
            name="marketingName"
            placeholder={t('floorplans.details.placeholder.marketingName')}
            required
          />
        </Col>
        <Col span={12}>
          <Form.Input
            name="constructionName"
            placeholder={t('floorplans.details.placeholder.constructionName')}
          />
        </Col>
      </FieldRow>
      <FieldRow>
        <Col span={12}>
          <Form.EnumSelect type={FloorplanType} name="type" required />
        </Col>
        <Col span={12}>
          <Form.Number
            name="squareFootage"
            prefix=""
            min={0}
            placeholder={t('floorplans.details.placeholder.squareFootage')}
          />
        </Col>
      </FieldRow>
      <FieldRow>
        <Col span={24}>
          <Form.TextArea name="description" />
        </Col>
      </FieldRow>
    </Form.Formik>
  );
};
