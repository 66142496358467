import {
  DeleteIconButton,
  EditIconButton,
  ImageBox,
  notify,
  StyledRow,
  useImageBox,
} from '@/components';
import { CommunityPermissions } from '@/core';
import {
  SubOptionState,
  UpdateSubOptionArgs,
  useDeleteSubOptionMutation,
  useUpdateSubOptionMutation,
} from '@/redux';
import { Space, List, Col, Row } from 'antd';
import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Assert } from '../Communities.Common';
import { SubOptionBookletLinks } from './SubOptionBookletLinks';
import { SubOptionEditPanel } from './SubOptionEditPanel';
import { SubOptionExternalLinks } from './SubOptionExternalLinks';

interface Props {
  readonly?: boolean;
  subOption: SubOptionState;
}

const useUpdate = (setEditSubOption: (value: boolean) => any) => {
  const [update] = useUpdateSubOptionMutation();

  return useCallback(
    async (values: UpdateSubOptionArgs) =>
      await update(values)
        .unwrap()
        .then(() => notify.success.t('subOptions.details.saved'))
        .then(() => setEditSubOption(false)),
    [setEditSubOption, update],
  );
};

const useDelete = (props: Props) => {
  const { subOption } = props;
  const [remove] = useDeleteSubOptionMutation();

  return useCallback(
    () =>
      remove({ id: subOption.id })
        .unwrap()
        .then(() => notify.success.t('subOptions.details.deleted')),
    [remove, subOption.id],
  );
};

function SubOptionImages(props: { subOptionId: string }) {
  const imageBox = useImageBox({
    defer: false,
    ownerId: props.subOptionId,
    ownerType: 'SubOptionImage',
  });

  if (isEmpty(imageBox.items)) {
    return null;
  }

  return (
    <StyledRow>
      <Col>
        <ImageBox {...imageBox} readonly />
      </Col>
    </StyledRow>
  );
}

export function SubOptionItem(props: Props) {
  const { subOption, readonly } = props;
  const [editSubOption, setEditSubOption] = useState(false);
  const update = useUpdate(setEditSubOption);
  const handleDelete = useDelete(props);
  const { t } = useTranslation();

  return (
    <>
      <StyledRow>
        <Col span={10}>
          <List.Item>
            <List.Item.Meta
              title={
                <Space>
                  {subOption.name}
                  {!readonly && (
                    <Assert
                      permission={CommunityPermissions.ItemsCategories.Manage}
                    >
                      <>
                        <EditIconButton
                          onClick={() => setEditSubOption(true)}
                        />
                        <DeleteIconButton
                          onClick={handleDelete}
                          confirm
                          entityName={t('subOptions.details.entityName')}
                        />
                      </>
                    </Assert>
                  )}
                </Space>
              }
            />
          </List.Item>
        </Col>
        {!editSubOption && (
          <Col span={14} className="mb-4">
            <Row>
              <SubOptionBookletLinks
                suboption={subOption}
                readonly={readonly}
              />
            </Row>
            <Row className="mt-2">
              <SubOptionExternalLinks
                suboption={subOption}
                readonly={readonly}
              />
            </Row>
          </Col>
        )}
      </StyledRow>
      {editSubOption && (
        <div>
          <SubOptionEditPanel
            onCancel={() => setEditSubOption(false)}
            onSubmit={update}
            value={subOption}
            className="mt-3"
          />
        </div>
      )}
      {!editSubOption && <SubOptionImages subOptionId={subOption.id} />}
    </>
  );
}
