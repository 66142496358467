import { enumObject, EnumValue } from '@/utils';
import { TreeNodeDto } from '../http.communities';

const TYPE_VALUES = ['Default', 'Client'];
export type PathTemplateType = EnumValue<typeof TYPE_VALUES>;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PathTemplateType = enumObject('PathTemplateType', TYPE_VALUES);

export interface PathTemplateDto {
  id: string;
  name: string;
  type: PathTemplateType;
  organizationId: string;
  steps: PathTemplateStep[];
}

export interface UpdatePathTemplateDto {
  id: string;
  name: string;
  steps: PathTemplateStep[];
}

export type CreatePathTemplateDto = Omit<UpdatePathTemplateDto, 'id'> & {
  organizationId?: string;
  communityId?: string;
};

export interface PathTemplateStep {
  id: string;
  name: string;
  categories: string[];
}

export type PathTemplateDetailsDto = Omit<PathTemplateDto, 'steps'> & {
  steps: PathTemplateStepDetailsDto[];
  communityId?: string;
  communityName?: string;
};

export interface PathTemplateStepDetailsDto {
  index?: number;
  name: string;
  selectedCategories: TreeNodeDto[];
}
