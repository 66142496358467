import { useField } from 'formik';
import { Checkbox } from 'antd';
import { FormsGroup } from './FormsGroup';
import { useFormLabel } from './FormsI18nContext';

export type FormsCheckboxProps = {
  name: string;
  label?: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
};

export const FormsCheckbox = ({
  name,
  label: labelOverride,
  required,
  className,
  disabled,
}: FormsCheckboxProps) => {
  const [field] = useField({
    name,
    type: 'checkbox',
  });

  const label = useFormLabel(name, labelOverride);

  return (
    <FormsGroup name={name} required={required} inline>
      <Checkbox {...field} className={className} disabled={disabled}>
        {label}
      </Checkbox>
    </FormsGroup>
  );
};
