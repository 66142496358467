import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useBool } from '@/utils';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import styles from './PricingDetails.module.scss';

export function useFullScreenAction() {
  const { t } = useTranslation(undefined, { keyPrefix: 'pricing' });
  const [fullScreen, , toggleFullScreen] = useBool(false);

  return {
    fullScreen: fullScreen,
    fullScreenAction: (
      <div className={styles.fullScreenBtn}>
        <Tooltip
          placement="left"
          title={t(`fullScreen.${fullScreen ? 'exit' : 'enter'}`)}
          mouseEnterDelay={0.2}
        >
          <Button
            type="link"
            icon={
              fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />
            }
            onClick={() => toggleFullScreen()}
          />
        </Tooltip>
      </div>
    ),
  };
}
