import { useState } from 'react';
import { Button } from 'antd';
import { ClientDetailsModal } from '@/views';
import { useTranslation } from 'react-i18next';
import { AddClientArgs, useAddClientMutation } from '@/redux';
import { useNavigate } from 'react-router-dom';
import { notify } from '@/components';

interface Props {
  className?: string;
}

const INITIAL_VALUE: AddClientArgs = {
  name: null!,
  type: null!,
  contacts: [],
  address: {
    street: null!,
    city: null!,
    state: null!,
    zipCode: null!,
  },
};

const useSubmit = () => {
  const [add] = useAddClientMutation();
  const push = useNavigate();

  return (args: AddClientArgs) =>
    add(args)
      .unwrap()
      .then((id: string) => {
        notify.success.t('clients.added');
        push(`/clients/${id}`);
      });
};

export function AddClientButton(props: Props) {
  const submit = useSubmit();
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const { className } = props;

  return (
    <div className={className}>
      <Button type="primary" onClick={() => setOpenModal(true)}>
        {t('clients.details.addTitle')}
      </Button>
      {openModal && (
        <ClientDetailsModal
          title={t('clients.details.addTitle')}
          buttonSaveName={t('clients.details.addButton')}
          onSubmit={submit}
          value={INITIAL_VALUE}
          onClose={() => setOpenModal(false)}
        />
      )}
    </div>
  );
}
