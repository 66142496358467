import { AddBookletLinkDto, AttachmentDto, BookletLinkDto } from '@/core';
import { ItemBookletLinkList } from './BookletLinkList';
import { AddBookletLinkButton } from './AddBookletLinkButton';
import { Space } from 'antd';
import styles from './BookletLink.module.scss';

interface Props {
  readonly?: boolean;
  bookletLinks: BookletLinkDto[];
  attachments: AttachmentDto[];
  onCreate?: (args: AddBookletLinkDto) => Promise<void>;
  onDelete?: (attachmentId: string) => any;
  onOpen: (bookletLink: BookletLinkDto) => Promise<void>;
}

export function BookletLinks(props: Props) {
  const { attachments, onCreate, onDelete, onOpen, readonly } = props;

  return (
    <Space
      direction="vertical"
      size="small"
      className={styles.bookletLinkPanel}
    >
      <ItemBookletLinkList
        {...props}
        onOpen={onOpen}
        onDelete={onDelete}
        attachments={attachments}
        readonly={readonly}
      />

      {!readonly && (
        <AddBookletLinkButton onCreate={onCreate!} attachments={attachments} />
      )}
    </Space>
  );
}
