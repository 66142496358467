import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContextMenu,
  DateValue,
  EnumValue,
  TableColumnType,
} from '@/components';
import { NavLink } from 'react-router-dom';
import {
  formatters,
  TaskStatus,
  CommunityPermissions,
  StringFilterOptions,
} from '@/core';
import { TaskState } from '@/redux';
import { useFiltersFactory } from '@/utils';
import { Assert } from '../Communities.Common';
import { useProjectContext } from '..';
import { useDeleteTask } from '../Tasks.Details/useDeleteTask';

export function useTasksColumns(
  openEdit: (id: string) => any,
  openDuplicate: (taskIds: string[], projectId: string) => any,
  tasks?: TaskState[],
  tasksStringFilterOptions?: StringFilterOptions,
) {
  const { t } = useTranslation();
  const { isActive: isActiveProject } = useProjectContext();
  const filters = useFiltersFactory(tasks, tasksStringFilterOptions);

  const handleDelete = useDeleteTask(false);

  return useMemo<TableColumnType<TaskState>[]>(() => {
    const columns: Array<TableColumnType<TaskState> | false> = [
      {
        title: t('tasks.number'),
        key: 'number',
        width: '7%',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.number.localeCompare(b.number),
        render: (_, item) => item.number,
        ...filters.select((x) => x.number, 'number'),
      },
      {
        title: t('tasks.name'),
        key: 'name',
        width: '20%',
        sorter: (a, b) => a.name.localeCompare(b.name),
        ...filters.select((x) => x.name, 'name'),
        render: (_, { id, name, projectId }) => (
          <NavLink to={`/projects/${projectId}/task-management/${id}`}>
            {name}
          </NavLink>
        ),
      },
      {
        title: t('tasks.home'),
        key: 'home',
        width: '10%',
        sorter: (a, b) => a.homeName.localeCompare(b.homeName),
        ...filters.select((x) => x.homeName, 'home'),
        render: (_, item) => item.homeName,
      },
      {
        title: t('tasks.startDate'),
        key: 'startDate',
        width: '7%',
        sorter: (a, b) => getTime(a.startDate) - getTime(b.startDate),
        ...filters.dateTimeRange((x) => x.startDate),
        render: (_, item) => <DateValue value={item.startDate} />,
      },
      {
        title: t('tasks.endDate'),
        key: 'endDate',
        width: '7%',
        sorter: (a, b) => getTime(a.endDate) - getTime(b.endDate),
        ...filters.dateTimeRange((x) => x.endDate),
        render: (_, item) => <DateValue value={item.endDate} />,
      },
      {
        title: t('tasks.responsiblePerson'),
        key: 'responsiblePerson',
        width: '15%',
        sorter: (a, b) =>
          a.responsiblePerson.label!.localeCompare(b.responsiblePerson.label!),
        ...filters.select(
          (x) => x.responsiblePerson.label,
          'responsiblePerson',
        ),
        render: (_, item) => item.responsiblePerson?.label,
      },
      {
        title: t('tasks.costEstimated'),
        key: 'costEstimated',
        width: '15%',
        sorter: (a, b) => a.estimatedCost - b.estimatedCost,
        ...filters.numberRange((x) => x.estimatedCost),
        render: (_, item) => formatters.price(item.estimatedCost),
        className: 'text-end',
      },
      {
        title: t('tasks.status'),
        key: 'status',
        width: '10%',
        sorter: (a, b) => a.status.localeCompare(b.status),
        ...filters.enumSelect((x) => x.status, TaskStatus),
        render: (_, item) => (
          <EnumValue type={TaskStatus} value={item.status} />
        ),
      },
      {
        title: t('tasks.actions'),
        key: 'actions',
        width: '7%',
        align: 'center',
        render: (_, { id, projectId }) =>
          isActiveProject && (
            <Assert
              permission={CommunityPermissions.DevelopScope.Manage}
              active
            >
              <ContextMenu
                items={[
                  {
                    label: t('tasks.contextMenu.editButton'),
                    onClick: () => openEdit(id),
                  },
                  {
                    label: t('tasks.contextMenu.deleteButton'),
                    onClick: () => handleDelete(id, undefined),
                  },
                  {
                    label: t('tasks.contextMenu.duplicateButton'),
                    onClick: () => openDuplicate([id], projectId),
                  },
                ]}
              />
            </Assert>
          ),
      },
    ];
    return columns.filter((x) => !!x) as TableColumnType<TaskState>[];
  }, [filters, handleDelete, openEdit, openDuplicate, t, isActiveProject]);
}

export function getTime(text?: string) {
  return text ? new Date(text).getTime() : 0;
}
