import { Button, Dropdown, Menu, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useUserContextSelector } from '@/redux';
import { Icon } from '@/components';
import styles from './ItemListPanel.module.scss';
import { useCommunityContext } from '../Communities.Common';
import { CommunityPermissions } from '@/core';

interface Props {
  onItemCreate: () => any;
  onSubcategoryCreate: () => any;
}

export function ItemListActions(props: Props) {
  const { onItemCreate, onSubcategoryCreate } = props;
  const hasRole = useUserContextSelector((x) => x.hasRole);
  const { hasPermission } = useCommunityContext();
  const { t } = useTranslation();

  const menuItems: ItemType[] = [
    {
      key: 'addItem',
      onClick: onItemCreate,
      label: t(t('items.addItem')),
    },
  ];
  if (
    hasRole('CLIENT_ADMIN') ||
    hasRole('BFP_ADMIN') ||
    hasPermission(CommunityPermissions.ItemsCategories.Manage)
  ) {
    menuItems.push({
      key: 'addSubcategory',
      onClick: onSubcategoryCreate,
      label: t(t('items.addSubcategory')),
    });
  }

  return (
    <Dropdown overlay={<Menu items={menuItems} />} placement="bottomRight">
      <Button className={styles.addBtn} type="primary">
        <Space>
          {t('items.add')}
          <Icon type="arrow-down" />
        </Space>
      </Button>
    </Dropdown>
  );
}
