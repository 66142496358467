import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CommunityDto,
  CommunityStatus,
  StringFilterOptions,
  UsaStates,
} from '@/core';
import { ContextMenu, EnumValue, TableColumnType } from '@/components';
import { NavLink } from 'react-router-dom';
import { CommunityState, useUserContextSelector } from '@/redux';
import { useFiltersFactory } from '@/utils';

export function useCommunitiesColumns(
  openEdit: (id: string) => any,
  changeStatus: (id: string, status: CommunityStatus) => any,
  communities: CommunityDto[] | undefined,
  showClientName?: boolean,
  communityFilterOptions?: StringFilterOptions,
) {
  const hasRole = useUserContextSelector((x) => x.hasRole);
  const { t } = useTranslation();

  const filters = useFiltersFactory(communities, communityFilterOptions);

  return useMemo<TableColumnType<CommunityState>[]>(() => {
    const columns: Array<TableColumnType<CommunityState> | false> = [
      {
        title: t('communities.number'),
        key: 'number',
        sorter: (a, b) => a.number.localeCompare(b.number),
        render: (_, item) => item.number,
        defaultSortOrder: 'descend',
        ...filters.select((x) => x.number, 'number'),
      },
      {
        title: t('communities.name'),
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        ...filters.select((x) => x.name, 'name'),
        render: (_, { id, name }) => (
          <NavLink to={`/communities/${id}`}>{name}</NavLink>
        ),
      },
      hasRole('BFP_ADMIN') &&
        !!showClientName && {
          title: t('communities.clientName'),
          key: 'clientName',
          sorter: (a, b) => a.clientName.localeCompare(b.clientName),
          ...filters.select((x) => x.clientName, 'clientName'),
          render: (_, { clientId, clientName }) => (
            <NavLink to={`/clients/${clientId}`}>{clientName}</NavLink>
          ),
        },
      {
        title: t('communities.address.city'),
        key: 'city',
        sorter: (a, b) => a.address.city.localeCompare(b.address.city),
        ...filters.plainText((x) => x.address.city),
        render: (_, item) => item.address.city,
      },
      {
        title: t('communities.address.state'),
        dataIndex: 'state',
        key: 'state',
        sorter: (a, b) => a.address.state.localeCompare(b.address.state),
        ...filters.enumSelect((x) => x.address.state, UsaStates),
        render: (_, item) => (
          <EnumValue type={UsaStates} value={item.address.state} />
        ),
      },
      {
        title: t('communities.status'),
        key: 'status',
        sorter: (a, b) => a.status.localeCompare(b.status),
        ...filters.enumSelect((x) => x.status, CommunityStatus),
        render: (_, item) => (
          <EnumValue type={CommunityStatus} value={item.status} />
        ),
        width: '10%',
      },
      {
        title: t('communities.projects'),
        key: 'projectCount',
        sorter: (a, b) => a.projectCount - b.projectCount,
        ...filters.numberRange((x) => x.projectCount),
        render: (_, item) => item.projectCount,
        width: '10%',
      },
      {
        title: t('communities.homes'),
        key: 'homeCount',
        sorter: (a, b) => a.homeCount - b.homeCount,
        ...filters.numberRange((x) => x.homeCount),
        render: (_, item) => item.homeCount,
        width: '10%',
      },
    ];

    if (hasRole('BFP_ADMIN')) {
      columns.push({
        title: t('clients.actions'),
        key: 'actions',
        width: '8%',
        render: (_, { id, status }) => (
          <ContextMenu
            items={[
              {
                if: status === CommunityStatus.Active,
                onClick: () => openEdit(id),
                label: t('communities.action.edit'),
              },
              {
                if: status === CommunityStatus.Active,
                onClick: () => changeStatus(id, CommunityStatus.Deactivated),
                label: t('communities.action.deactivate'),
              },
              {
                if: status === CommunityStatus.Deactivated,
                onClick: () => changeStatus(id, CommunityStatus.Active),
                label: t('communities.action.activate'),
              },
            ]}
          />
        ),
      });
    }

    return columns.filter((x) => !!x) as TableColumnType<CommunityState>[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, communities, communityFilterOptions]);
}
