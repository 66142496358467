import { FieldRow, Form } from '@/components';
import { Col, Space } from 'antd';
import { useField, useFormikContext } from 'formik';
import { useEffect } from 'react';
import {
  getCustomReportAvailableColumns,
  getCustomReportType,
} from './customReportColumnsConfigurations';
import { ManageReportExcelTemplatesButton } from './ManageReportExcelTemplatesButton';
import { useReportExcelTemplateLookupSource } from './useReportExcelTemplateLookupSource';

interface Props {
  name: string;
  className?: string;
}

export function FormCustomReportTemplateOptions(props: Props) {
  const { name, className } = props;
  const { source: reportTemplateSource, sourceId: reportTemplateSourceId } =
    useReportExcelTemplateLookupSource();
  const { setFieldValue } = useFormikContext();
  const [{ value }] = useField(name);

  useEffect(() => {
    const type = getCustomReportType(value);
    const columns = getCustomReportAvailableColumns(value);

    setFieldValue('options.type', type);
    setFieldValue('columns', columns);
  }, [setFieldValue, value]);

  return (
    <FieldRow className={className}>
      <Col span={18}>
        <Space size="large">
          <Form.AsyncSelect
            key={reportTemplateSourceId}
            style={{ width: 400 }}
            dataSource={reportTemplateSource}
            name={name}
            preload
          />
          <ManageReportExcelTemplatesButton className="pt-3" />
        </Space>
      </Col>
    </FieldRow>
  );
}
