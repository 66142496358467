import {
  AddTaskCommentDto,
  AddTaskDto,
  BulkDeleteTaskDto,
  BulkUpdateTaskDto,
  DuplicateTaskDto,
  Paged,
  Paging,
  TaskCommentDto,
  TaskDetailsDto,
  TaskDto,
  TaskFilterParams,
  TaskFinancialInfoDto,
  TaskResponsiblePersonValue,
  tasksHttp,
  TaskStatus,
  UpdateTaskCommentDto,
  UpdateTaskDto,
} from '@/core';
import { rtkq, RtkqRequest } from '../rtkq';
import { api } from '../api';
import { SortResult } from '@/components/Collections.Paging/useSorting';

export type TaskState = TaskDto;
export type TaskDetailsState = TaskDetailsDto;
export type AddTaskArgs = AddTaskDto;
export type UpdateTaskArgs = UpdateTaskDto;
export type DuplicateTaskArgs = DuplicateTaskDto;
export type TaskFinancialInfoState = TaskFinancialInfoDto;

export type TaskCommentState = TaskCommentDto;
export type AddTaskCommentArgs = AddTaskCommentDto;
export type UpdateTaskCommentArgs = UpdateTaskCommentDto;

export type BulkUpdateTaskArgs = BulkUpdateTaskDto;
export type BulkDeleteTaskArgs = BulkDeleteTaskDto;

const tasksApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTasksList: build.query<
      Paged<TaskState>,
      RtkqRequest<{
        projectId?: string;
        communityId?: string;
        paging: Paging;
        filters?: TaskFilterParams;
        sorting?: SortResult;
      }>
    >({
      queryFn: async (args) => {
        return rtkq(args).exec(() => tasksHttp.getAll(args));
      },
      providesTags: (result, _, args) => [
        { type: 'task', id: 'list' },
        ...(result?.items || []).map(({ id }) => ({
          type: 'task' as const,
          id,
        })),
        { type: 'project-tasks', id: args.projectId },
      ],
    }),

    getTaskDetails: build.query<TaskDetailsState, RtkqRequest<{ id: string }>>({
      queryFn: async (args) => {
        const { id } = args;
        return rtkq(args).exec(() => tasksHttp.get(id));
      },
      providesTags: (task) => [{ type: 'task', id: task?.id ?? 'none' }],
    }),

    getResponsiblePersonsList: build.query<
      TaskResponsiblePersonValue[],
      RtkqRequest<{ communityId: string }>
    >({
      queryFn: async (args) => {
        const { communityId } = args;
        return rtkq(args).exec(() =>
          tasksHttp.getResponsiblePersonsAll(communityId),
        );
      },
      providesTags: (_) => [{ type: 'task', id: 'responsible-persons' }],
    }),

    updateTask: build.mutation<void, RtkqRequest<UpdateTaskArgs>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => tasksHttp.update(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'task', id: 'list' },
        { type: 'task', id: args.id },
      ],
    }),

    addTask: build.mutation<string, RtkqRequest<AddTaskArgs>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => tasksHttp.create(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'task', id: 'list' },
        { type: 'developScopeItem', id: args.developScopeItemId },
      ],
    }),

    deleteTask: build.mutation<void, RtkqRequest<{ id: string }>>({
      queryFn: (args) => {
        const { id } = args;
        return rtkq(args).exec(() => tasksHttp.delete(id));
      },
      invalidatesTags: (_, __, { id }) => {
        return [
          { type: 'task', id },
          { type: 'developScopeItem', id: 'list' },
        ];
      },
    }),

    bulkDeleteTasks: build.mutation<void, RtkqRequest<BulkDeleteTaskArgs>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => tasksHttp.bulkDelete(args));
      },
      invalidatesTags: () => [
        { type: 'task', id: 'list' },
        { type: 'developScopeItem', id: 'list' },
      ],
    }),

    duplicateTask: build.mutation<void, RtkqRequest<DuplicateTaskArgs>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => tasksHttp.duplicate(args));
      },
      invalidatesTags: () => [{ type: 'task', id: 'list' }],
    }),

    bulkUpdateTasks: build.mutation<void, RtkqRequest<BulkUpdateTaskArgs>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => tasksHttp.bulkUpdate(args));
      },
      invalidatesTags: () => [{ type: 'task', id: 'list' }],
    }),

    getTaskCommentList: build.query<
      TaskCommentState[],
      RtkqRequest<{ taskId: string }>
    >({
      queryFn: async (args) => {
        return await rtkq(args).exec(() =>
          tasksHttp.getAllComments(args.taskId),
        );
      },
      providesTags: (result = []) => [
        { type: 'comments', id: 'list' },
        ...result.map(({ id }) => ({
          type: 'comments' as const,
          id,
        })),
      ],
    }),

    updateTaskComment: build.mutation<void, RtkqRequest<UpdateTaskCommentArgs>>(
      {
        queryFn: async (args) => {
          return await rtkq(args).exec(() => tasksHttp.updateComment(args));
        },
        invalidatesTags: (_, __, args) => [{ type: 'comments', id: args.id }],
      },
    ),

    updateTaskStatus: build.mutation<
      void,
      RtkqRequest<{ taskId: string; status: TaskStatus }>
    >({
      queryFn: async (args) => {
        return await rtkq(args).exec(() =>
          tasksHttp.updateTaskStatus(args.taskId, args.status),
        );
      },
      invalidatesTags: (_, __, args) => {
        return [
          { type: 'task', id: 'list' },
          { type: 'task', id: args.taskId },
          { type: 'developScopeItem', id: 'list' },
        ];
      },
    }),

    addTaskComment: build.mutation<string, RtkqRequest<AddTaskCommentArgs>>({
      queryFn: async (args) => {
        return await rtkq(args).exec(() => tasksHttp.addComment(args));
      },
      invalidatesTags: () => [{ type: 'comments', id: 'list' }],
    }),

    deleteTaskComment: build.mutation<void, RtkqRequest<{ id: string }>>({
      queryFn: async (args) => {
        return await rtkq(args).exec(() => tasksHttp.deleteComment(args.id));
      },
      invalidatesTags: (_, __, args) => [{ type: 'comments', id: args.id }],
    }),

    getTaskFinancialInfoList: build.query<
      Paged<TaskFinancialInfoState>,
      RtkqRequest<{
        projectId: string;
        paging: Paging;
        filters?: TaskFilterParams;
        sorting?: SortResult;
      }>
    >({
      queryFn: async (args) => {
        const { projectId, paging, filters, sorting } = args;
        return rtkq(args).exec(() =>
          tasksHttp.getAllFinancialInfo(projectId, paging, filters, sorting),
        );
      },
      providesTags: (result) => {
        const items = result?.items || [];
        return [
          { type: 'task-financial-info', id: 'list' },
          { type: 'task', id: 'list' },
          ...items.map(({ id }) => ({
            type: 'task-financial-info' as const,
            id,
          })),
          ...items.map(({ id }) => ({
            type: 'task' as const,
            id,
          })),
        ];
      },
    }),

    updateTaskActualCost: build.mutation<
      void,
      RtkqRequest<{ id: string; value: number | null | undefined }>
    >({
      queryFn: (args) => {
        const { id, value } = args;
        return rtkq(args).exec(() => tasksHttp.updateActualCost(id, value));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'task-financial-info', id: 'list' },
        { type: 'task-financial-info', id: args.id },
      ],
    }),

    addTasks: build.mutation<void, RtkqRequest<AddTaskArgs[]>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => tasksHttp.createRange(args));
      },
      invalidatesTags: () => [{ type: 'task', id: 'list' }],
    }),
  }),
});

export const {
  useGetTasksListQuery,
  useLazyGetTasksListQuery,
  useGetTaskDetailsQuery,
  useLazyGetTaskDetailsQuery,
  useAddTaskMutation,
  useUpdateTaskMutation,
  useDeleteTaskMutation,
  useBulkDeleteTasksMutation,
  useDuplicateTaskMutation,
  useBulkUpdateTasksMutation,
  useGetTaskCommentListQuery,
  useLazyGetResponsiblePersonsListQuery,
  useUpdateTaskCommentMutation,
  useAddTaskCommentMutation,
  useDeleteTaskCommentMutation,
  useUpdateTaskActualCostMutation,
  useUpdateTaskStatusMutation,
  useGetTaskFinancialInfoListQuery,
  useAddTasksMutation,
} = tasksApi;
