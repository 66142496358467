import { useTranslation } from 'react-i18next';
import { Form, Icon, Page } from '@/components';
import { useParams, useNavigate } from 'react-router-dom';
import { EditPathTemplatePanel } from '@/views';
import { Button, Space } from 'antd';
import { AdminTabKeys } from '@/views/Admin/AdminTabs';

const useId = () => {
  const { id } = useParams<'id'>();
  return id!;
};

export function PathTemplateEditPage() {
  const { t } = useTranslation();
  const title = t('pathTemplates.details.editTitle');
  const id = useId();
  const push = useNavigate();

  return (
    <Page htmlTitle={title}>
      <Page.Paths>
        <Page.Path href={`/admin#${AdminTabKeys['path-templates']}`}>
          {t('nav.admin')}
        </Page.Path>
        <Page.Path href={`/admin#${AdminTabKeys['path-templates']}`}>
          {t('pathTemplates.title')}
        </Page.Path>
        <Page.Path>{title}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card
          actionsPosition="bottom"
          actions={
            <Space size="middle">
              <Button onClick={() => push(-1)}>{t('cancel')}</Button>
              <Form.Submit uid="pathTemplate-details" type="primary">
                {t('save')}
              </Form.Submit>
            </Space>
          }
          title={title}
          icon={<Icon type="template" />}
          color="#EFAC4E"
        >
          <EditPathTemplatePanel id={id} onSaved={() => push(-1)} />
        </Page.Card>
      </Page.Body>
    </Page>
  );
}
