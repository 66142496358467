import { Table, notify } from '@/components';
import { useProjectHomesDeadlinesColumns } from './useProjectHomesDeadlinesColumns';
import {
  ProjectHomeDeadlineInfo,
  UpdateProjectHomeStepDeadlineArgs,
  useGetProjectsHomesDeadlinesInfoQuery,
  useUpdateProjectHomePathStepDeadlineMutation,
} from '@/redux';
import { useBool } from '@/utils';
import { HomeDeadlineEditModal } from './HomeDeadlineEditModal';
import { useMemo, useState } from 'react';
import { ProjectHomeStepDeadlineLookupDto } from '@/core';
import { useDefaultTablePaging } from '@/components/Collections.Paging';
import { useSorting } from '@/components/Collections.Paging/useSorting';

interface Props {
  projectId: string;
  communityId: string;
}

const useSubmit = (closeModal: () => void) => {
  const [add] = useUpdateProjectHomePathStepDeadlineMutation();

  return (args: UpdateProjectHomeStepDeadlineArgs) =>
    add(args)
      .unwrap()
      .then(() => {
        closeModal();
        notify.success.t('projects.homeDeadlines.updated');
      });
};

export function ProjectHomesDeadlinesListPanel(props: Props) {
  const { projectId } = props;
  const { setTotal, paging, params } = useDefaultTablePaging();
  const { sorting, onSortingChange } = useSorting<ProjectHomeDeadlineInfo>({
    sortBy: 'homeNumber',
    sortOrder: 'ascend',
  });
  const { data, isFetching } = useGetProjectsHomesDeadlinesInfoQuery({
    projectId,
    paging: params,
    sorting,
  });

  const deadlines = useMemo(() => {
    if (data) setTotal(data.total);
    return data?.items;
  }, [data, setTotal]);

  const [openModal, setOpenModal] = useBool(false);
  const [projectHome, setProjectHome] = useState<ProjectHomeDeadlineInfo>();
  const [selectedDeadline, setDeadline] =
    useState<ProjectHomeStepDeadlineLookupDto>();

  const columns = useProjectHomesDeadlinesColumns({
    ...props,
    onEditButtonClick: () => setOpenModal(true),
    setProjectHome,
    setDeadline,
  });

  const submit = useSubmit(() => setOpenModal(false));

  return (
    <>
      <Table<ProjectHomeDeadlineInfo>
        name="HomesDeadlines"
        loading={isFetching}
        columns={columns}
        dataSource={deadlines}
        rowKey={(row) => row.home.id}
        pagination={paging}
        onChange={(_, __, sorting) => onSortingChange(sorting)}
      />
      {openModal && projectHome && selectedDeadline && (
        <HomeDeadlineEditModal
          value={projectHome}
          selectedStep={selectedDeadline}
          onClose={() => setOpenModal(false)}
          onSubmit={submit}
        />
      )}
    </>
  );
}
