import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { UserNotificationSettings } from '@/core/http.userNotificationSettings';
import { Form, TableColumnType } from '@/components';

export function useNotificationSettingsColumns() {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'notifications.settings',
  });

  return useMemo<TableColumnType<UserNotificationSettings>[]>(() => {
    const columns: Array<TableColumnType<UserNotificationSettings> | false
    > = [
      {
        title: t('actionColumnTitle'),
        key: 'action',
        width: '65%',
        render: (_, settings) =>
          <span>
            {t(`notificationTypes.${settings.notificationMessageType}`)}
          </span>
      },
      {
        title: t('internalColumnTitle'),
        key: 'internal',
        width: '10%',
        align: 'center',
        render: (_, settings, index) =>
          <Form.Switch
            name={`${index}.isAllow`}
            label={false}
            noValidation
          />
      },
      {
        key: 'assigned',
        width: '25%',
        align: 'center',
        render: () =>
          <span>{t('seeAssignedTask')}</span>
      },
      {
        title: t('emailColumnTitle'),
        key: 'email',
        width: '5%',
        align: 'center',
        render: (_, __, index) =>
          <Form.Switch
            name={`${index}.isEmailAllow`}
            label={false}
            noValidation
          />
      },
    ];
      return columns as TableColumnType<UserNotificationSettings>[];
  }, [t])
}