import * as Yup from 'yup';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, orderBy } from 'lodash';
import { Form, Title } from '@/components';
import { Button, Space } from 'antd';
import { ProjectStepLookupState } from '@/redux';
import { FormProjectDeadlineItem } from './FormProjectDeadlineItem';

interface Props {
  steps: ProjectStepLookupState[];
  onSubmit: (value: any) => any;
  onCancel: () => any;
}

const SCHEMA = Yup.object().shape({
  steps: Yup.array()
    .of(
      Yup.object().shape({
        initialDate: Yup.date().nullable(),
        deadlineDate: Yup.date().nullable().required(),
      }),
    )
    .required(),
});

function useInitialValue(steps: ProjectStepLookupState[]) {
  return useMemo(
    () => ({
      steps: steps.map((step) => ({
        id: step.id,
        index: step.index,
        name: step.name,
        deadlineDate: step.deadlineDate,
        initialDate: step.deadlineDate,
      })),
    }),
    [steps],
  );
}

export const FormProjectDeadlines = (props: Props) => {
  const { steps: stepsProp, onSubmit, onCancel } = props;
  const { t } = useTranslation();

  const steps = useMemo(() => orderBy(stepsProp, (x) => x.index), [stepsProp]);
  const initialValues = useInitialValue(steps);

  if (isEmpty(steps)) {
    return null;
  }

  return (
    <Form.Formik<ReturnType<typeof useInitialValue>>
      uid="project-deadlines"
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={SCHEMA}
      i18n="projects"
    >
      <Title>{t('projects.details.editDeadlinesTitle')}</Title>
      {steps.map((step) => (
        <FormProjectDeadlineItem
          key={step.index}
          name={`steps[${step.index}]`}
        />
      ))}
      <Space direction="horizontal" className="mt-3">
        <Button onClick={onCancel}>{t('cancel')}</Button>
        <Form.Submit type="primary">{t('save')}</Form.Submit>
      </Space>
    </Form.Formik>
  );
};
