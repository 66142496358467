import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useCallback, useEffect, useState } from 'react';

interface Props {
  initialFee: boolean;
  onSubmit: (value: boolean) => any;
  disabled: boolean;
}

export function PricingSpecialRequestFee(props: Props) {
  const { initialFee, onSubmit, disabled } = props;
  const [fee, setFee] = useState(initialFee);

  useEffect(() => {
    fee !== initialFee && setFee(initialFee);
  }, [fee, initialFee]);

  const handleSubmit = useCallback(
    (e: CheckboxChangeEvent) => {
      setFee(e.target.checked);
      onSubmit(e.target.checked);
    },
    [onSubmit],
  );

  return <Checkbox checked={fee} onChange={handleSubmit} disabled={disabled} />;
}
