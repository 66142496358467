import {
  getNextProjectStatuses,
  isFinalProjectStatus,
  ProjectDetailsDto,
  ProjectStatus,
} from '@/core';
import { EnumValue } from '@/components';
import { Dropdown, Menu, Space, Typography } from 'antd';
import { useMemo } from 'react';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { DownOutlined } from '@ant-design/icons';
import styles from './ProjectDetails.module.scss';

interface Props {
  project: ProjectDetailsDto;
  onChange: (value: ProjectStatus) => any;
}

function DropdownMenu({
  status,
  onSave,
}: {
  status: ProjectStatus;
  onSave: (value: ProjectStatus) => any;
}) {
  const menuItems: ItemType[] = useMemo(() => {
    const nextStatuses = getNextProjectStatuses(status);

    return nextStatuses.map((value) => ({
      key: value,
      onClick: () => onSave(value),
      label: <EnumValue type={ProjectStatus} value={value} />,
    }));
  }, [onSave, status]);

  return <Menu className={styles.menu} items={menuItems} />;
}

export function ProjectStatusControl({ project, onChange }: Props) {
  const { status } = project;

  const handleSaveClick = (value: ProjectStatus) => {
    onChange(value);
  };

  return (
    <>
      {isFinalProjectStatus(status) ? (
        <EnumValue type={ProjectStatus} value={status} />
      ) : (
        <Dropdown
          overlay={<DropdownMenu status={status} onSave={handleSaveClick} />}
          trigger={['click']}
        >
          <Typography.Link>
            <Space>
              <EnumValue type={ProjectStatus} value={status} />
              <DownOutlined />
            </Space>
          </Typography.Link>
        </Dropdown>
      )}
    </>
  );
}
