import { DateTimeValue, Table, TableColumnType, YesNo } from '@/components';
import { useAppListState } from '@/core';
import {
  NotificationListField,
  NotificationListItemState,
} from '@webinex/wispo';
import { Button, TablePaginationConfig, Tag } from 'antd';
import { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  notificationSettings,
  parseNotificationData,
} from '../Notifications/useNotification';

const INCLUDE = [NotificationListField.Items, NotificationListField.TotalMatch];

const DEFAULT_PAGING: TablePaginationConfig = {
  pageSize: 20,
  current: 1,
  pageSizeOptions: ['10', '20', '50'],
};

function useColumns(
  onRead: (id: string[]) => any,
  renderSubject: (item: NotificationListItemState) => ReactNode | undefined,
) {
  const { t } = useTranslation(undefined, { keyPrefix: 'notifications.list' });

  return useMemo<TableColumnType<NotificationListItemState>[]>(
    () => [
      {
        key: 'createdAt',
        title: t('createdAt'),
        dataIndex: 'createdAt',
        sortOrder: 'descend',
        showSorterTooltip: false,
        sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
        render: (_, { createdAt }) => <DateTimeValue value={createdAt} />,
      },
      {
        key: 'subject',
        title: t('subject'),
        render: (_, item) => (
          <div className="text-multiline">
            {renderSubject(item) ?? item.subject}
          </div>
        ),
      },
      {
        key: 'body',
        title: t('body'),
        render: (_, { body }) => <div className="text-multiline">{body}</div>,
      },
      {
        key: 'read',
        title: t('read'),
        render: (_, { read }) => (
          <Tag color={read ? 'success' : 'warning'}>
            <YesNo>{read}</YesNo>
          </Tag>
        ),
      },
      {
        key: 'readAt',
        title: t('readAt'),
        render: (_, { readAt }) => readAt && <DateTimeValue value={readAt} />,
      },
      {
        key: 'actions',
        title: t('actions'),
        render: (_, { markRead, read, id }) => {
          if (read || markRead.isPending || markRead.isReloading) {
            return null;
          }

          return (
            <Button onClick={() => onRead([id])} type="link">
              {t('markRead')}
            </Button>
          );
        },
      },
    ],
    [t, renderSubject, onRead],
  );
}

function handleSubjectRender(item: NotificationListItemState) {
  const { type, payload } = parseNotificationData(item.subject);
  const settings = notificationSettings[type];

  if (!settings || typeof settings === 'function') {
    return;
  }

  const { Subject } = settings;

  return <Subject payload={payload} />;
}

export function NotificationsListPanel() {
  const [paging, setPaging] = useState<TablePaginationConfig>(DEFAULT_PAGING);
  const skip = (paging.current! - 1) * paging.pageSize!;
  const take = paging.pageSize!;
  const { isPending, items, markRead, totalMatch } = useAppListState({
    include: INCLUDE,
    take: take,
    skip: skip,
  });

  const columns = useColumns(markRead, handleSubjectRender);

  return (
    <Table<NotificationListItemState>
      name="Notifications"
      loading={isPending}
      columns={columns}
      dataSource={items}
      onChange={setPaging}
      pagination={{ ...paging, total: totalMatch }}
      rowKey={(row) => row.id}
    />
  );
}
