import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useCallback, useEffect, useState } from 'react';

interface Props {
  resetOnInitialValueChange: true;
  disabled: boolean;
  initialValue: boolean;
  onSubmit?: (value: boolean) => any;
}

export function PricingStandardCredit(props: Props) {
  const { initialValue, onSubmit, disabled } = props;
  const [standardCredit, setStandardCredit] = useState(initialValue);

  useEffect(() => {
    setStandardCredit(initialValue);
  }, [initialValue]);

  const handleSubmit = useCallback(
    (e: CheckboxChangeEvent) => {
      setStandardCredit(e.target.checked);
      if (onSubmit) onSubmit(e.target.checked);
    },
    [onSubmit],
  );

  return (
    <Checkbox
      checked={standardCredit}
      onChange={handleSubmit}
      disabled={disabled}
    />
  );
}
