import { notify, TabPanel } from '@/components';
import {
  UpdatePricingMarkupDefaultsArgs,
  useGetPricingSettingsMatrixQuery,
  useUpdateMarkupDefaultsMutation,
} from '@/redux';
import { Col, Row } from 'antd';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PricingSettingsDefaultsForm } from './PricingSettingsDefaultsForm';
import { PricingSettingsListPanel } from './PricingSettingsListPanel';

interface Props {
  communityId: string;
}

function useUpdateDefaultMarkups() {
  const [update] = useUpdateMarkupDefaultsMutation();
  return useCallback(
    (value: UpdatePricingMarkupDefaultsArgs) =>
      update(value)
        .unwrap()
        .then(() => notify.success.t('pricing.settings.saved')),
    [update],
  );
}

export function PricingSettingsListTabPanel(props: Props) {
  const { communityId } = props;
  const { t } = useTranslation();
  const { data } = useGetPricingSettingsMatrixQuery({ communityId });
  const value: UpdatePricingMarkupDefaultsArgs = useMemo(
    () => ({
      communityId,
      constructionMarkup: data?.defaultMarkups.construction,
      residentMarkup: data?.defaultMarkups.resident,
      salesTax: data?.defaultMarkups.salesTax,
    }),
    [data, communityId],
  );
  const handleSubmit = useUpdateDefaultMarkups();

  return (
    <TabPanel>
      <TabPanel.Title>{t('pricing.settings.title')}</TabPanel.Title>
      <TabPanel.Body>
        {data && (
          <Row>
            <Col span={8}>
              <PricingSettingsDefaultsForm
                value={value}
                onSubmit={handleSubmit}
                autoSubmit
              />
            </Col>
          </Row>
        )}
        <PricingSettingsListPanel className="mt-4" communityId={communityId} />
      </TabPanel.Body>
    </TabPanel>
  );
}
