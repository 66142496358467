import { useTranslation } from 'react-i18next';

import { Auth, Page } from '@/components';
import { AdminTabs } from '@/views/Admin/AdminTabs';
import { SettingOutlined } from '@ant-design/icons';

function _AdminPage() {
  const { t } = useTranslation();
  const title = t('nav.admin');

  return (
    <Page htmlTitle={title}>
      <Page.Paths>
        <Page.Path>{title}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card icon={<SettingOutlined />} color="#512da8" title={title} />
        <Page.TabCard>
          <AdminTabs className="mt-0" />
        </Page.TabCard>
      </Page.Body>
    </Page>
  );
}

export const AdminPage = Auth.Banner('BFP_ADMIN')(_AdminPage);
