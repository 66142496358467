import { Icon, Page } from '@/components';
import { useParams } from 'react-router-dom';
import { AddTaskButton, Assert, ImportTasksButton, TasksListPanel } from '@/views';
import { useTranslation } from 'react-i18next';
import { useGetProjectQuery } from '@/redux';
import { CommunityPermissions } from '@/core';

export function ProjectTasksPage() {
  const { t } = useTranslation();
  const { id } = useParams<'id'>();
  const { data } = useGetProjectQuery({ id: id! });

  return (
    <Page.Card
      title={t('tasks.title')}
      actions={
      <>
        <Assert permission={CommunityPermissions.DevelopScope.Manage} active>
          <AddTaskButton projectId={id!} className={'me-2'}/>
        </Assert>
        <Assert permission={CommunityPermissions.DevelopScope.Manage} active>
          <ImportTasksButton projectId={id!} communityId={"test"}/>
        </Assert>
      </>
      }
      icon={<Icon type="clipboard" />}
      color="#7B54CC"
    >
      {id && data && (
        <TasksListPanel projectId={id!} communityId={data.communityId} />
      )}
    </Page.Card>
  );
}
