import { AttachmentDto } from '@/core';
import { useClickOutside } from '@/utils';
import { Col, Row } from 'antd';
import { Overlay } from '../../Overlay';
import styles from '../ImageBox.module.scss';
import { ImageUpload } from '../ImageUpload';
import { ImageBoxGalleryItem } from './ImageBoxGalleryItem';
import { ImageCropUpload } from '@/components';

export interface ImageBoxGalleryProps {
  visible: boolean;
  items: AttachmentDto[];
  readonly?: boolean;
  acceptPdf?: boolean;
  onClose: () => any;
  onOpenPreview: (item: AttachmentDto) => any;
  onDelete?: (item: AttachmentDto) => any;
  onUpload?: (files: File[]) => Promise<any>;
  crop?: boolean;
  aspect?: number;
}

export function ImageBoxGallery(props: ImageBoxGalleryProps) {
  const {
    items,
    visible,
    onOpenPreview,
    readonly,
    onDelete,
    onClose,
    onUpload,
    acceptPdf,
    crop,
    aspect
  } = props;

  const { contentRef, onContainerClick } = useClickOutside(props.onClose);

  if (!visible) {
    return null;
  }

  return (
    <Overlay visible actions={['close']} onClose={onClose}>
      <div className={styles.gallery} onClick={onContainerClick}>
        <div>
          <Row ref={contentRef}>
            {items.map((item) => (
              <Col>
                <ImageBoxGalleryItem
                  key={item.id}
                  item={item}
                  onOpenPreview={onOpenPreview}
                  onDelete={onDelete}
                  readonly={readonly}
                />
              </Col>
            ))}
            {!readonly && onUpload && (
              <Col>
                <div className={styles.galleryItem}>
                  {crop
                    ? <ImageCropUpload onUpload={onUpload} onRemove={onDelete} items={items} acceptPdf={acceptPdf} aspect={aspect}/>
                    : <ImageUpload onUpload={onUpload} acceptPdf={acceptPdf}/>}
                </div>
              </Col>
            )}
          </Row>
        </div>
      </div>
    </Overlay>
  );
}
