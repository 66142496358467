import { NavLink } from '@/components';
import { useGetHomeDetailsQuery } from '@/redux';
import { Col, Row, Space } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { SearchHomeInput } from './SearchHomeInput';

interface Props {
  id: string;
  className?: string;
}

export function HomeNavigationPanel(props: Props) {
  const { id, className } = props;
  const { t } = useTranslation();
  const { data } = useGetHomeDetailsQuery({ id });

  if (!data) {
    return null;
  }

  return (
    <Row justify="space-between" className={className} align="middle">
      <Col span={6}>
        <SearchHomeInput communityId={data.communityId} />
      </Col>
      <Col>
        <Space size="large" align="center">
          <NavLink
            to={`/communities/${data.communityId}/homes/${data.previousHomeId}`}
            disabled={!data.previousHomeId}
            disabledClassName="text-muted"
          >
            <>
              <LeftOutlined className="me-3" />
              {t('homes.details.previousHome')}
            </>
          </NavLink>
          <NavLink
            to={`/communities/${data.communityId}/homes/${data.nextHomeId}`}
            disabled={!data.nextHomeId}
            disabledClassName="text-muted"
          >
            <>
              {t('homes.details.nextHome')}
              <RightOutlined className="ms-3" />
            </>
          </NavLink>
        </Space>
      </Col>
    </Row>
  );
}
