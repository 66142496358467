import { useMemo, useState } from 'react';
import { Image as AntdImage } from 'antd';
import loading from '@/assets/images/load.gif';
import { useImageBoxImages } from '../Context';
import { UseImagesPreviewResult } from './useImagesPreview';
import { useImagePreviewLazyLoad } from './useImagePreviewLazyLoad';

function usePreviewProps(
  props: UseImagesPreviewResult,
  load: (id: string) => any,
) {
  const { close, index } = props;
  const [current, setCurrent] = useState<number>();
  useImagePreviewLazyLoad(props, load, current);

  return useMemo(
    () => ({
      visible: index !== undefined,
      onVisibleChange: close,
      current: index,
      countRender: (current: number, total: number) => {
        // s.skalaban: it's hacky, but it's only one way to get current displayed image
        current > 0 && setTimeout(() => setCurrent(current), 0);
        return `${current} / ${total}`;
      },
    }),
    [index, close, setCurrent],
  );
}

export function ImagesPreview(props: UseImagesPreviewResult) {
  const { index, items } = props;
  const { blobURL, load } = useImageBoxImages();
  const preview = usePreviewProps(props, load);

  if (index === undefined) {
    return null;
  }

  return (
    <div style={{ display: 'none' }}>
      <AntdImage.PreviewGroup
        preview={{ ...preview, rootClassName: 'image-box-preview-root' }}
      >
        {items.map(({ id }) => {
          const url = blobURL(id);
          const exists = !!url;
          return <AntdImage key={id} src={exists ? url : loading} />;
        })}
      </AntdImage.PreviewGroup>
    </div>
  );
}
