import { enumObject, EnumValue } from '@/utils';
import { CommonFilterType } from '..';

const FLOORPLAN_STATUS_VALUES = ['Active', 'Deactivated'] as const;

export type FloorplanStatus = EnumValue<typeof FLOORPLAN_STATUS_VALUES>;

// eslint-disable-next-line
export const FloorplanStatus = enumObject(
  'FloorplanStatus',
  FLOORPLAN_STATUS_VALUES,
);

export interface RoomCategory {
  id: string;
  implicit: boolean;
  squareFootage?: number;
}

const FLOORPLAN_TYPE_VALUES = ['ILU', 'CottageOrVilla', 'Condo'] as const;

export type FloorplanType = EnumValue<typeof FLOORPLAN_TYPE_VALUES>;

// eslint-disable-next-line
export const FloorplanType = enumObject('FloorplanType', FLOORPLAN_TYPE_VALUES);

const FloorplanCreationOptionValues = ['existing', 'new', 'copy'] as const;

export type FloorplanCreationType = EnumValue<
  typeof FloorplanCreationOptionValues
>;

// eslint-disable-next-line
export const FloorplanCreationType = enumObject(
  'FloorplanCreationOption',
  FloorplanCreationOptionValues,
);

export function isChooseExistingOption(type: FloorplanCreationType) {
  return type === 'existing';
}

export interface FloorplanDto {
  id: string;
  communityId: string;
  number: string;
  marketingName: string;
  constructionName?: string;
  type: FloorplanType;
  status: FloorplanStatus;
  squareFootage: number;
  wholeHomeSquareFootage: number;
  description: string;
  communityName: string;
}

export interface FloorplanDetailsDto extends FloorplanDto {
  categories: RoomCategory[];
}

export interface UpdateFloorplanDto {
  id: string;
  marketingName: string;
  constructionName?: string;
  type: FloorplanType;
  squareFootage: number;
  description: string;
  communityId: string;
}

export interface UpdateWholeHomeFloorplanDto {
  id: string;
  squareFootage: number;
  categories: RoomCategory[];
}

export type AddFloorplanDto = Omit<UpdateFloorplanDto, 'id'> & {
  communityId: string;
  homeId?: string;
  exampleFloorplanId?: string;
};

export interface AssignFloorplanToHomeDto extends AddFloorplanDto {
  creationOption: FloorplanCreationType;
  floorplanId: string;
}

export interface FloorplanLookupDto {
  id: string;
  marketingName: string;
  type: FloorplanType;
}

export interface FloorplanFilterParams extends CommonFilterType {
  number?: string[];
  marketingName?: string[];
  constructionName?: string[];
  type?: string[];
  status?: string[];
  squareFootage?: string[];
}
