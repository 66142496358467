import { notify } from '@/components';
import { useAssignMenuToHomesMutation } from '@/redux';
import { useBool } from '@/utils';
import { Alert, Button } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AssignMenuDetailsModalValue,
  AssignMenuModal,
} from './AssignMenuModal';

interface Props {
  className?: string;
  communityId: string;
  homeIds: string[];
  projectId: string;
  disabled?: boolean;
  onSaved: () => any;
}

const useSubmit = (toggleModalOpen: () => any, props: Props) => {
  const { projectId, homeIds, onSaved } = props;
  const [assign] = useAssignMenuToHomesMutation();

  return useCallback(
    async (args: AssignMenuDetailsModalValue) => {
      return assign({ ...args, homeIds, projectId })
        .unwrap()
        .then(() => notify.success.t('projects.homeList.assignMenu.assigned'))
        .then(() => onSaved(), toggleModalOpen());
    },
    [assign, projectId, homeIds, toggleModalOpen, onSaved],
  );
};

const INITIAL_VALUE = {
  menuId: null!,
};

export function AssignMenuButton(props: Props) {
  const { className, communityId, disabled } = props;
  const [modalOpen, , toggleModalOpen] = useBool(false);
  const { t } = useTranslation();
  const submit = useSubmit(toggleModalOpen, props);

  return (
    <>
      <Button
        className={className}
        onClick={toggleModalOpen}
        disabled={disabled}
      >
        {t('projects.homeList.assignMenu.buttonName')}
      </Button>
      {modalOpen && (
        <AssignMenuModal
          onCancel={toggleModalOpen}
          title={t('projects.homeList.assignMenu.name')}
          onSubmit={submit}
          value={INITIAL_VALUE}
          communityId={communityId}
          formHeader={
            <Alert
              className="mb-4"
              type="info"
              description={t('projects.homeList.assignMenu.assignTip')}
            />
          }
        />
      )}
    </>
  );
}
