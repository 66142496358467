import { Page } from '@/components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ProjectHomePersonalizationPanel,
  SearchProjectHomeInput,
  useHomeSelectedState,
} from '@/views';
import { useGetProjectQuery } from '@/redux';
import { Col, Row } from 'antd';
import styles from './ProjectHomePersonalizationPage.module.scss';
import { FormatPainterOutlined } from '@ant-design/icons';

export function ProjectHomePersonalizationPage() {
  const { t } = useTranslation();
  const { id } = useParams<'id'>();
  const selectedState = useHomeSelectedState();
  const { selectedId: selectedHomeId } = selectedState;

  const { data: project } = useGetProjectQuery({ id: id! }, { skip: !id });
  const { communityId } = project ?? {};

  return (
    <Page.Card
      title={
        <Row align="middle">
          <Col className="me-5">{t('personalization.homeTitle')}</Col>
          <Col className={styles.homeInput}>
            {communityId && (
              <SearchProjectHomeInput
                communityId={communityId}
                projectId={id}
                {...selectedState}
              />
            )}
          </Col>
        </Row>
      }
      icon={<FormatPainterOutlined style={{ fontSize: '20px' }} />}
      color="#EFAC4E"
    >
      {id && (
        <ProjectHomePersonalizationPanel
          projectId={id!}
          selectedHomeId={selectedHomeId}
        />
      )}
    </Page.Card>
  );
}
