import { Form, StyledRow } from '@/components';
import { ProjectStepLookupState } from '@/redux';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
}

export const FormProjectDeadlineItem = (props: Props) => {
  const { name } = props;
  const { t } = useTranslation();
  const [{ value }] = useField<ProjectStepLookupState>(name);

  return (
    <>
      <StyledRow>
        <Form.Date
          label={t('projects.details.deadlineName', { name: value.name })}
          name={`${name}.deadlineDate`}
          allowClear={false}
          required
        />
      </StyledRow>
    </>
  );
};
