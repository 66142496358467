import { Modal, Button, Col } from 'antd';
import { FieldRow, Form } from '@/components';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { AddCommunityArgs } from '@/redux';
import { useClientLookupSource } from '../Clients.Common';

const schema = Yup.object().shape({
  name: Yup.string().field().nullable().required(),
  clientId: Yup.string().field().nullable().required(),
  address: Yup.object().address(),
  description: Yup.string().field('lg').nullable(),
});

interface Props {
  onClose?: () => any;
  onSubmit: (props?: any) => any;
  value: AddCommunityArgs;
  title: string;
  buttonSaveName: string;
  clientSelectionDisabled?: boolean;
}

export function CommunityDetailsModal(props: Props) {
  const {
    onClose: onCancel,
    value,
    onSubmit,
    title,
    buttonSaveName,
    clientSelectionDisabled,
  } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'communities.details',
  });
  const clientDataSource = useClientLookupSource();

  return (
    <>
      <Modal
        title={title}
        visible
        onCancel={onCancel}
        footer={[
          <Button key="back" type="default" onClick={onCancel}>
            {t('cancel')}
          </Button>,
          <Form.Submit uid="community-details" key="submit" type="primary">
            {buttonSaveName}
          </Form.Submit>,
        ]}
      >
        <Form.Formik<AddCommunityArgs>
          uid="community-details"
          i18n="communities.details"
          initialValues={value}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          <FieldRow>
            <Col span={12}>
              <Form.Input
                name="name"
                required
                placeholder={t('placeholder.name')}
              />
            </Col>
            <Col span={12}>
              <Form.AsyncSelect
                dataSource={clientDataSource}
                name="clientId"
                disabled={clientSelectionDisabled}
                preloadInitial={value.clientId}
                placeholder={t('placeholder.client')}
                required
              />
            </Col>
          </FieldRow>
          <Form.Address name="address" required />
          <Col span={24}>
            <Form.TextArea
              name="description"
              placeholder={t('placeholder.description')}
            />
          </Col>
        </Form.Formik>
      </Modal>
    </>
  );
}
