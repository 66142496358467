import { Http } from '../http/http';
import { guard } from '@/utils';
import {
  CreatePathTemplateDto,
  PathTemplateDetailsDto,
  PathTemplateDto,
  UpdatePathTemplateDto,
} from './pathTemplatesTypes';
import qs from 'qs';
import { Paging } from '../http/paging';
import { Paged } from '../http/paged';

class PathTemplatesHttp extends Http {
  public async getAll(args?: {
    organizationId?: string;
    communityId?: string;
    paging: Paging;
  }) {
    const query = qs.stringify({ ...args, ...args?.paging });
    const url = `/api/path-template?${query}`;
    const response = await this._axios.get<Paged<PathTemplateDto>>(url);
    return response.data;
  }

  public get = async (id: string) => {
    guard.notNull(id, 'id');
    const response = await this._axios.get<PathTemplateDto>(
      `/api/path-template/${id}`,
    );
    return response.data;
  };

  public getDetails = async (id: string, includeCommunityLevel: boolean) => {
    guard.notNull(id, 'id');
    const query = qs.stringify({ includeCommunityLevel });
    const response = await this._axios.get<PathTemplateDetailsDto>(
      `/api/path-template/${id}/details?${query}`,
    );
    return response.data;
  };

  public async create(request: CreatePathTemplateDto) {
    guard.notNull(request, 'request');
    const response = await this._axios.post<string>(
      `/api/path-template`,
      request,
    );
    return response.data;
  }

  public async update(request: UpdatePathTemplateDto) {
    guard.notNull(request, 'request');
    const { id } = request;
    await this._axios.put(`/api/path-template/${id}`, request);
  }

  public async unlist(id: string) {
    guard.notNull(id, 'id');
    await this._axios.put(`/api/path-template/${id}/unlist`);
  }
}

export const pathTemplatesHttp = new PathTemplatesHttp();
