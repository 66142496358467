import { BFP_ROLES, CLIENT_ROLES } from '@/core';
import { isBFP } from '@/platform';
import { useMemo } from 'react';

export function useUserRoleValues(organizationId: string | undefined) {
  return useMemo(() => {
    const bfp = isBFP(organizationId);
    return bfp ? BFP_ROLES : CLIENT_ROLES;
  }, [organizationId]);
}
