import { notify, StyledRow, Table } from '@/components';
import {
  PaymentsState,
  useDeletePaymentMutation,
  useGetPersonalizationPaymentsQuery,
} from '@/redux';
import { array } from '@/utils';
import { Col, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { AddPaymentButton } from './AddPaymentButton';
import { PaymentsSubTotalInfo } from './PaymentsSubTotalInfo';
import { usePaymentsColumns } from './usePaymentsColumns';
import styles from '../PersonalizationSummary.module.scss';
import { useProjectContext } from '@/views/Projects.Common';
import { Assert } from '@/views';
import { CommunityPermissions } from '@/core';

const Title = Typography.Title;

interface Props {
  className?: string;
  homeId: string;
  projectId: string;
}

const useOnDeletePaymentHandle = (homeId: string, projectId: string) => {
  const [del] = useDeletePaymentMutation();

  return (id: string) => {
    del({ id, homeId, projectId })
      .unwrap()
      .then((id) => {
        notify.success.t('payments.deleted');
      });
  };
};

export function PaymentsPanel(props: Props) {
  const { className, homeId, projectId } = props;
  const { t } = useTranslation();

  const { isActive } = useProjectContext();

  const onDeletePaymentButton = useOnDeletePaymentHandle(homeId, projectId);
  const columns = usePaymentsColumns(isActive, onDeletePaymentButton);

  const { data = array.empty<PaymentsState>(), isFetching } =
    useGetPersonalizationPaymentsQuery(
      {
        projectId,
        homeId,
      },
      { skip: !homeId || !projectId },
    );

  return (
    <Space direction="vertical" size="large" className={className}>
      <StyledRow align="middle">
        <Col className={styles.paymentCol} span="10">
          <Title level={4} className={styles.title}>
            {t('payments.title')}
          </Title>
          {isActive && (
            <Assert
              permission={CommunityPermissions.Personalization.Manage}
              active
            >
              <AddPaymentButton
                className="ms-2"
                projectId={projectId}
                homeId={homeId}
              />
            </Assert>
          )}
        </Col>
      </StyledRow>

      <Table<PaymentsState>
        name={'payments'}
        columns={columns}
        dataSource={data}
        pagination={{ hideOnSinglePage: true, defaultPageSize: 5 }}
        loading={isFetching}
        hideDefaultActions={true}
      />
      <PaymentsSubTotalInfo data={data} />
    </Space>
  );
}
