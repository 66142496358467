import { Button, Col, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { FieldRow, Form, LookupOption } from '@/components';
import {
  useCategoryLookupSource,
  useCommunityContext,
  useProjectStepLookupSource,
} from '@/views';
import { AddSpecialRequestArgs, UpdateSpecialRequestArgs } from '@/redux';
import { SpecialRequestStatus } from '@/core';
import * as Yup from 'yup';
import { SpecialRequestCommentsPanel } from './SpecialRequestCommentsPanel';

interface Props<
  TValue extends AddSpecialRequestArgs | UpdateSpecialRequestArgs,
> {
  title: React.ReactNode;
  stepId?: string;
  projectId: string;
  homeId: string;
  onSubmit: (args: TValue) => any;
  onClose: () => any;
  value: TValue | undefined;
  isEdit?: boolean;
  isFloorplan?: boolean;
  roomId?: string;
  preloadValue?: LookupOption;
  disabledCategory?: boolean;
}

const schema = Yup.object().shape({
  name: Yup.string().field().nullable().required(),
  stepId: Yup.string().field().nullable().required(),
  categoryId: Yup.string().field().nullable().required(),
  status: Yup.string().field().nullable().required(),
  description: Yup.string().nullable(),
  fee: Yup.bool().required(),
});

export function SpecialRequestDetailsModal<
  TValue extends AddSpecialRequestArgs | UpdateSpecialRequestArgs,
>(props: Props<TValue>) {
  const {
    title,
    onSubmit,
    projectId,
    onClose,
    value,
    isEdit,
    preloadValue,
    disabledCategory,
    stepId,
  } = props;
  const { t } = useTranslation();
  const stepsDataSource = useProjectStepLookupSource([projectId]);

  const { communityId } = useCommunityContext();
  const categoriesDataSource = useCategoryLookupSource({
    communityId,
    listed: true,
  });

  const id: string | undefined = (value as UpdateSpecialRequestArgs).id;

  return (
    <Modal
      title={title}
      visible
      onCancel={onClose}
      footer={[
        <Button key="back" type="default" onClick={onClose}>
          {t('cancel')}
        </Button>,
        <Form.Submit uid="special-request" key="submit" type="primary">
          {t('save')}
        </Form.Submit>,
      ]}
    >
      {
        <Form.Formik<TValue>
          initialValues={value!}
          uid="special-request"
          onSubmit={onSubmit}
          validationSchema={schema}
          i18n="specialRequests.details"
        >
          <FieldRow>
            <Col span={12}>
              <Form.Input
                name="name"
                required
                label={t('specialRequests.details.itemName')}
              />
            </Col>
            <Col span={12}>
              <Form.AsyncSelect
                name="stepId"
                dataSource={stepsDataSource}
                preload
                required
                disabled={!!stepId}
              />
            </Col>
          </FieldRow>
          <FieldRow>
            <Col span={12}>
              <Form.AsyncSelect
                name="categoryId"
                dataSource={categoriesDataSource}
                required
                preloadInitial={preloadValue ?? value?.categoryId}
                disabled={disabledCategory ?? value?.asItem}
                allowClear={false}
              />
            </Col>
          </FieldRow>
          <Form.TextArea
            name="description"
            label={t('specialRequests.details.addDescription')}
            disabled={!stepId}
          />
          <Form.Images
            name="imageActions"
            defer
            ownerId={id}
            ownerType="SpecialRequestImage"
            noPreload={!id}
            label={t('specialRequests.details.images')}
          />
          <Form.Checkbox name="fee" />
          {isEdit && (
            <FieldRow>
              <Col span={12}>
                <Form.EnumSelect
                  name="status"
                  type={SpecialRequestStatus}
                  required
                />
              </Col>
            </FieldRow>
          )}
        </Form.Formik>
      }

      {isEdit && <SpecialRequestCommentsPanel specialRequestId={id} />}
    </Modal>
  );
}
