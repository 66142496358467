import { AsyncSelect, TabPanel } from '@/components';
import { useEffect, useState } from 'react';
import { useHomeLookupSource, useProjectLookupSource } from '@/views';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';
import { PricingSpecialRequestListPanel } from './PricingSpecialRequestListPanel';

interface Props {
  communityId: string;
}

export function PricingSpecialRequestListTabPanel(props: Props) {
  const { communityId } = props;
  const { t } = useTranslation();
  const [selectedProjectIds, setSelectedProjectIds] = useState<string[]>();
  const [selectedHomeIds, setSelectedHomeIds] = useState<string[]>();
  const projectDataSource = useProjectLookupSource(communityId, true);
  const homeDataSource = useHomeLookupSource(communityId, selectedProjectIds);

  useEffect(() => {
    setSelectedHomeIds([]);
  }, [selectedProjectIds]);

  return (
    <TabPanel>
      <TabPanel.Title>{t('pricing.specialRequest.title')}</TabPanel.Title>
      <TabPanel.Body>
        <Space direction="vertical" style={{ width: '25%' }} size="large">
          <AsyncSelect
            value={selectedProjectIds as any}
            onChange={setSelectedProjectIds as any}
            dataSource={projectDataSource}
            placeholder={t('pricing.specialRequest.project')}
            mode="multiple"
          />
          <AsyncSelect
            key={selectedProjectIds?.toString()}
            value={selectedHomeIds as any}
            onChange={setSelectedHomeIds as any}
            dataSource={homeDataSource}
            placeholder={t('pricing.specialRequest.home')}
            mode="multiple"
          />
        </Space>
        <PricingSpecialRequestListPanel
          className="mt-4"
          communityId={communityId}
          homeIds={selectedHomeIds}
          projectIds={selectedProjectIds}
        />
      </TabPanel.Body>
    </TabPanel>
  );
}
