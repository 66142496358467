import { RoomCategory } from '@/core';
import { Col, InputNumber, Row } from 'antd';
import { DataNode } from 'antd/lib/tree';
import { useField } from 'formik';
import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { useRoomCategoryFootageItemData } from './RoomCategoryFootageItem';

const LEFT_MARGIN = 20;

interface Props {
  name: string;
  id: string;
  treeData: DataNode[];
  marginLeft?: number;
}

function updateSqueryFootage(
  items: RoomCategory[],
  id: string,
  value: number | undefined,
): RoomCategory[] {
  const existing = items.find((x) => x.id === id);

  if (value == null && existing?.implicit === true) {
    return items.filter((x) => x.id !== id);
  }

  if (existing)
    return items.map((x) => (x.id !== id ? x : { ...x, squareFootage: value }));

  return [...items, { id, implicit: true, squareFootage: value }];
}

function useHandleFieldChange({ name, id }: Props) {
  const [{ value }, , { setValue }] = useField<RoomCategory[]>(name);

  return useCallback(
    (footage: number | undefined) => {
      const newValue: RoomCategory[] = updateSqueryFootage(value, id, footage);
      setValue(newValue);
    },
    [value, setValue, id],
  );
}

export function FormRoomCategoryFootageItem(props: Props) {
  const { name, id, treeData, marginLeft = 0 } = props;
  const [{ value }] = useField<RoomCategory[]>(name);
  const { node, title, isVisible } = useRoomCategoryFootageItemData(
    id,
    treeData,
    value,
  );
  const handleChange = useHandleFieldChange(props);

  return (
    <div>
      <Row className="mb-2">
        <Col span={12}>
          <div style={{ marginLeft }}>{title}</div>
        </Col>
        <Col span={12}>
          {isVisible && (
            <InputNumber
              className="w-100"
              precision={2}
              max={100000}
              min={0}
              value={value.find((x) => x.id === id)?.squareFootage}
              onChange={handleChange}
            />
          )}
        </Col>
      </Row>
      {!isEmpty(node?.children) && (
        <div>
          {node!.children!.map((n) => (
            <FormRoomCategoryFootageItem
              key={n.key}
              id={n.key as string}
              name={name}
              treeData={treeData}
              marginLeft={marginLeft + LEFT_MARGIN}
            />
          ))}
        </div>
      )}
    </div>
  );
}
