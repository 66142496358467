import { RoomCategory } from '@/core';
import { Col, Divider, Row } from 'antd';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { FormRoomCategoryFootageItem } from './FormRoomCategoryFootageItem';
import { useRoomCategoryTreeData } from './useRoomCategoryTreeData';

interface Props {
  communityId: string;
  name: string;
  initialCategories: RoomCategory[];
}

export function FormRoomCategoryFootageList(props: Props) {
  const { communityId, name, initialCategories } = props;
  const { t } = useTranslation();
  const [{ value }] = useField<RoomCategory[]>(name);
  const treeData = useRoomCategoryTreeData(
    communityId,
    value,
    true,
    initialCategories,
  );

  return (
    <div>
      <Row>
        <Col span={12}>{t('rooms.categorySelected')}</Col>
        <Col span={12}>{t('rooms.squareFootage')}</Col>
      </Row>
      <Divider className="mt-1" />
      {treeData.map((node) => (
        <FormRoomCategoryFootageItem
          name={name}
          id={node.key as string}
          treeData={treeData}
        />
      ))}
    </div>
  );
}
