import { DeleteIconButton, EditIconButton, TextBox, Title } from '@/components';
import { RoomState, CommunityPermissions } from '@/core';
import { Col, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { Assert } from '../Communities.Common';
import { RoomCategories } from './RoomCategories';
import { RoomCategoryFootageList } from './RoomCategoryFootageList';

interface Props {
  className?: string;
  value: RoomState;
  onEdit: (value: RoomState) => any;
  onDelete: (value: RoomState) => any;
  communityId: string;
  isWholeHome: boolean;
  readonly?: boolean;
}

export function RoomDetails(props: Props) {
  const {
    className,
    value,
    onDelete,
    onEdit,
    communityId,
    isWholeHome,
    readonly,
  } = props;
  const { t } = useTranslation();

  return (
    <div className={className}>
      <Title>
        <Title.Actions inline space>
          <Assert permission={CommunityPermissions.Floorplans.Manage} active>
            <Space size="small">
              {!readonly && (
                <EditIconButton type="link" onClick={() => onEdit(value)} />
              )}
              {!isWholeHome && !readonly && (
                <DeleteIconButton
                  confirm
                  entityName={t('rooms.entityName')}
                  type="link"
                  onClick={() => onDelete(value)}
                />
              )}
            </Space>
          </Assert>
        </Title.Actions>

        {t(isWholeHome ? 'rooms.wholeHome' : 'rooms.details')}
      </Title>

      <div className="mb-4">
        {!isWholeHome && (
          <>
            <TextBox label={t('rooms.name')}>{value.name}</TextBox>
            <TextBox label={t('rooms.roomTypeName')}>
              {value.roomTypeName}
            </TextBox>
          </>
        )}
        <TextBox label={t('rooms.squareFootage')}>
          {value.squareFootage}
        </TextBox>
      </div>

      <Row>
        <Col span={12}>
          <Title>{t('rooms.categorySelected')}</Title>
          <RoomCategories
            categories={value.categories}
            communityId={communityId}
          />
        </Col>
        <Col span={12}>
          <RoomCategoryFootageList
            communityId={communityId}
            categories={value.categories}
          />
        </Col>
      </Row>
    </div>
  );
}
