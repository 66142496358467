import { TreeNodeDto } from '@/core';
import { first, isEmpty, uniq } from 'lodash';

function find<T, TKey>(
  item: T,
  key: TKey,
  keyBy: (x: T) => TKey,
  childrenBy: (x: T) => T[] | undefined,
): T | undefined {
  if (keyBy(item) === key) {
    return item;
  }

  const children = childrenBy(item) ?? [];
  for (const child of children) {
    const found = find(child, key, keyBy, childrenBy);
    if (found) return found;
  }
}

function findFirst<T, TKey>(
  items: T[],
  key: TKey,
  keyBy: (x: T) => TKey,
  childrenBy: (x: T) => T[] | undefined,
): T | undefined {
  for (const item of items) {
    const found = find(item, key, keyBy, childrenBy);
    if (found) return found;
  }
}

function findParent<T, TKey>(
  item: T,
  key: TKey,
  keyBy: (x: T) => TKey,
  childrenBy: (x: T) => T[] | undefined,
): T | undefined {
  const children = childrenBy(item) ?? [];

  for (const child of children) {
    if (keyBy(child) === key) {
      return item;
    }

    const found = findParent(child, key, keyBy, childrenBy);
    if (found) return found;
  }
}

function findFirstParent<T, TKey>(
  items: T[],
  key: TKey,
  keyBy: (x: T) => TKey,
  childrenBy: (x: T) => T[] | undefined,
): T | undefined {
  for (const item of items) {
    const found = findParent(item, key, keyBy, childrenBy);
    if (found) return found;
  }
}

function merge(tree1: TreeNodeDto[], tree2: TreeNodeDto[]) {
  function findInOne(
    tree: TreeNodeDto,
    path: string[],
  ): TreeNodeDto | undefined {
    const id = first(path);
    const found = tree.children.find((x) => x.id === id);
    if (!found) return found;

    return path.length === 1 ? found : findInOne(found, path.slice(1));
  }

  function find(tree: TreeNodeDto[], path: string[]): TreeNodeDto | undefined {
    const id = first(path);
    const found = tree.find((x) => x.id === id);
    if (!found) return found;

    return path.length === 1 ? found : findInOne(found, path.slice(1));
  }

  function merge(path: string[]): TreeNodeDto {
    const tree1Node = find(tree1, path);
    const tree2Node = find(tree2, path);

    if (!tree1Node || !tree2Node) {
      return tree1Node! ?? tree2Node!;
    }

    const children1 = tree1Node?.children ?? [];
    const children2 = tree2Node?.children ?? [];
    const childrenId = uniq(children1.concat(children2).map((x) => x.id));

    return {
      ...(tree1Node ?? {}),
      ...(tree2Node ?? {}),
      children: childrenId.map((id) => merge([...path, id])),
    } as TreeNodeDto;
  }

  const rootNodeIds = uniq(tree1.concat(tree2).map((x) => x.id));
  return rootNodeIds.map((id) => merge([id]));
}

function flatten(nodes: TreeNodeDto[]): TreeNodeDto[] {
  function flatOne(node: TreeNodeDto): TreeNodeDto[] {
    if (isEmpty(node.children)) {
      return [node];
    }

    return [node, ...node.children.flatMap(flatOne)];
  }

  return nodes.flatMap(flatOne);
}

export const tree = {
  find,
  findFirst,
  findParent,
  findFirstParent,
  merge,
  flatten,
};
