import { Attach, confirmation, notify, spinner } from '@/components';
import { communitiesHttp, useAppClippo, CommunityPermissions } from '@/core';
import { itemsApi, subOptionsApi } from '@/redux';
import { RemoveArgs } from '@webinex/clippo';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useCommunityContext } from '@/views';

interface Props {
  communityId: string;
}

function useShowConfirmation() {
  const { t } = useTranslation();

  return useCallback(
    (callback: () => any) =>
      confirmation.show({
        title: t('communities.amenity.deleteTitle'),
        body: t('communities.amenity.deleteBody'),
        onConfirm: callback,
      }),
    [t],
  );
}

function useHandleRemove(fetch: () => any) {
  const showConfirmation = useShowConfirmation();
  const dispatch = useDispatch();

  return useCallback(
    ({ id }: RemoveArgs) => {
      showConfirmation(() => {
        spinner.show();
        communitiesHttp
          .removeAmenity(id)
          .then(() => fetch())
          .finally(() => {
            spinner.hide();
            dispatch(
              itemsApi.util.invalidateTags([
                { type: 'booklet-link-attachment-id', id },
              ]),
            );
            dispatch(
              subOptionsApi.util.invalidateTags([
                { type: 'booklet-link-attachment-id', id },
              ]),
            );
            notify.success.t('communities.details.amenitiesDeleted');
          });
      });

      return Promise.resolve(null!);
    },
    [fetch, showConfirmation, dispatch],
  );
}

export function CommunityAmenities(props: Props) {
  const { communityId } = props;

  const { assert } = useCommunityContext();

  const clippoProps = useAppClippo({
    defer: false,
    ownerId: communityId,
    ownerType: 'CommunityAmenities',
  });

  const { fetch } = clippoProps;
  const handleRemove = useHandleRemove(fetch);
  const readonly = !assert(CommunityPermissions.Amenities.Manage, true);

  return (
    <Attach
      {...clippoProps}
      remove={handleRemove}
      accept="application/pdf"
      readonly={readonly}
      noDeleteConfirm
    />
  );
}
