import * as yup from 'yup';
import { StringSchema } from 'yup';

function password(this: StringSchema) {
  const max = { key: 'string.max', args: { max: 250 } };
  const min = { key: 'string.min', args: { min: 8 } };
  const lowercase = { message: { key: 'string.matches.password.lowercase' } };
  const uppercase = { message: { key: 'string.matches.password.uppercase' } };
  const digits = { message: { key: 'string.matches.password.digits' } };

  return this.max(250, max)
    .min(8, min)
    .matches(/[a-z]/, lowercase)
    .matches(/[A-Z]/, uppercase)
    .matches(/[0-9]/, digits);
}

yup.addMethod(yup.string, 'password', password);
