import { useTranslation } from 'react-i18next';
import { TabPanel } from '@/components';
import { AddHomeButton, HomesListPanel, Assert } from '@/views';
import { CommunityPermissions } from '@/core';

interface Props {
  communityId: string;
}

export function HomesListTabPanel(props: Props) {
  const { communityId } = props;
  const { t } = useTranslation();

  return (
    <TabPanel>
      <TabPanel.Title>{t('homes.title')}</TabPanel.Title>
      <TabPanel.Actions>
        <Assert permission={CommunityPermissions.Homes.Manage} active>
          <AddHomeButton communityId={communityId} />
        </Assert>
      </TabPanel.Actions>
      <TabPanel.Body>
        <HomesListPanel communityId={communityId} />
      </TabPanel.Body>
    </TabPanel>
  );
}
