import classes from './NotificationsBell.module.scss';
import { LastNotificationsPanel } from '../Notifications.Last/LastNotificationsPanel';
import { useAppUnreadCount } from '@/core/wispoClient';
import { Popover } from 'antd';
import { Icon } from '@/components';
import { useBool } from '@/utils';
import { NotificationSettingsModal } from '../Notifications.Last/NotificationSettingsModal';

function OverlayFactory(props: { onClosePopover: () => any }) {
  return <LastNotificationsPanel onClosePopover={props.onClosePopover} />;
}

export function NotificationBell() {
  const unread = useAppUnreadCount();

  const [open, setOpen] = useBool(false);
  const [modalState, setModalState] = useBool(false);
  return (
    <>
      <Popover
        content={<OverlayFactory onClosePopover={() => setModalState(true)} />}
        placement="bottomRight"
        destroyTooltipOnHide
        visible={open}
        onVisibleChange={setOpen}
        zIndex={Number.MAX_SAFE_INTEGER}
      >
        <a
          href="#"
          className="ant-dropdown-link"
          onClick={(e) => e.preventDefault()}
        >
          <div className={classes.bellContainer}>
            <Icon type="bell" />
            <div className={classes.count}>
              {unread !== undefined ? unread : '?'}
            </div>
          </div>
        </a>
      </Popover>
      <NotificationSettingsModal
        visible={modalState}
        onClose={() => setModalState(false)}
      />
    </>
  );
}
