import { useAppClippo } from '@/core';
import { useField } from 'formik';
import { useEffect } from 'react';
import { Attach } from '../Attach';

export interface FormsDeferAttachProps {
  name: string;
  noPreload?: boolean;
  ownerType?: string;
  ownerId?: string;
}

export function FormsDeferAttach(props: FormsDeferAttachProps) {
  const { name, noPreload = true, ownerType, ownerId } = props;
  const [, , { setValue, setTouched }] = useField(name);

  const clippo = useAppClippo({
    defer: true,
    noPreload: noPreload,
    ownerType: ownerType,
    ownerId: ownerId,
  });

  const {
    value: { actions },
  } = clippo;

  useEffect(() => {
    setTouched(true);
    setValue(actions, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions]);

  return <Attach {...clippo} />;
}
