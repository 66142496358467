import {
  Table,
  notify,
  useDefaultTablePaging,
  useFilters,
  useSorting,
} from '@/components';
import {
  ProjectLocationDeadlineInfo,
  UpdateProjectLocationStepDeadlineArgs,
  useGetProjectLocationDeadlinesInfoQuery,
  useUpdateProjectLocationPathStepDeadlineMutation,
} from '@/redux';
import { useBool } from '@/utils';
import { useMemo, useState } from 'react';
import {
  LocationDeadlineFilterParams,
  ProjectLocationStepDeadlineLookupDto,
} from '@/core';
import { useProjectLocationDeadlinesColumns } from './useProjectLocationDeadlinesColumns';
import { LocationDeadlineEditModal } from './LocationDeadlineEditModal';
import { orderBy } from 'lodash';
import styles from './ProjectDetails.module.scss';

interface Props {
  projectId: string;
  communityId: string;
}

const useSubmit = (closeModal: () => void) => {
  const [add] = useUpdateProjectLocationPathStepDeadlineMutation();

  return (args: UpdateProjectLocationStepDeadlineArgs) =>
    add(args)
      .unwrap()
      .then(() => {
        closeModal();
        notify.success.t('projects.locationDeadlines.updated');
      });
};

export function ProjectLocationDeadlinesListPanel(props: Props) {
  const { projectId } = props;
  const { setTotal, paging, params } = useDefaultTablePaging();
  const { sorting, onSortingChange } =
    useSorting<ProjectLocationDeadlineInfo>();
  const {
    filters,
    onFiltersChange,
    stringFilterOptions,
    setStringFilterOptions,
    resetFilters,
  } = useFilters<LocationDeadlineFilterParams>([
    'location1',
    'location2',
    'location3',
  ]);
  const { data, isFetching } = useGetProjectLocationDeadlinesInfoQuery({
    projectId,
    paging: params,
    sorting,
    filters,
  });

  const deadlines = useMemo(() => {
    if (data) {
      setTotal(data.total);
      setStringFilterOptions(data.stringFilterOptions);
    }
    return data?.items;
  }, [data, setStringFilterOptions, setTotal]);

  const sortedData = useMemo(() => {
    if (!deadlines) return [];

    return orderBy(
      deadlines,
      [
        (x) => x.location.location1Name,
        (x) => x.location.location2Name,
        (x) => x.location.location3Name,
      ],
      'asc',
    );
  }, [deadlines]);

  const [openModal, setOpenModal] = useBool(false);
  const [selectedValue, setSelectedValue] =
    useState<ProjectLocationDeadlineInfo>();
  const [selectedDeadline, setDeadline] =
    useState<ProjectLocationStepDeadlineLookupDto>();

  const columns = useProjectLocationDeadlinesColumns({
    ...props,
    data: sortedData,
    onEditButtonClick: () => setOpenModal(true),
    setSelectedValue,
    setDeadline,
    stringFilterOptions,
  });

  const submit = useSubmit(() => setOpenModal(false));

  return (
    <>
      <Table<ProjectLocationDeadlineInfo>
        name="LocationsDeadlines"
        loading={isFetching}
        columns={columns}
        dataSource={sortedData}
        rowKey={(row) => row.location.locationId}
        pagination={paging}
        onChange={(_, filters, sorting) => {
          onFiltersChange(filters);
          onSortingChange(sorting);
        }}
        onFiltersReset={resetFilters}
        rowClassName={(row, index) =>
          row.location.location2Name === '' && index !== 0
            ? styles.locationRow
            : ''
        }
      />
      {openModal && selectedValue && selectedDeadline && (
        <LocationDeadlineEditModal
          value={selectedValue}
          selectedStep={selectedDeadline}
          onClose={() => setOpenModal(false)}
          onSubmit={submit}
        />
      )}
    </>
  );
}
