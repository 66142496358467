import { useCallback, useState } from 'react';
import { confirmation, DeleteIconButton, notify } from '@/components';
import { useGetProjectQuery, useUpdateProjectHomesMutation } from '@/redux';
import { HomesListPanel } from '../Homes.List';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';
import { Assert } from '../Communities.Common';
import { CommunityPermissions } from '@/core';
import { AssignMenuButton } from '../Projects.Homes.Details';
import { useProjectContext } from '@/views';

interface Props {
  projectId: string;
}

const useOnRemove = (props: Props) => {
  const { projectId } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'projects.homeList.remove',
  });

  const { data } = useGetProjectQuery({ id: projectId });
  const homeIds = data?.homeIds;
  const [update] = useUpdateProjectHomesMutation();

  return useCallback(
    (id: string) => {
      confirmation.show({
        title: t('title'),
        body: t('body'),
        onConfirm: () =>
          update({
            homeIds: homeIds!.filter((x) => x !== id),
            projectId,
          })
            .unwrap()
            .then(() => notify.success.t('projects.homeList.remove.success')),
      });
    },
    [projectId, homeIds, t, update],
  );
};

export function ProjectHomeListPanel(props: Props) {
  const { projectId } = props;
  const [selected, setSelected] = useState<string[]>([]);
  const { data } = useGetProjectQuery({ id: projectId });
  const { isActive } = useProjectContext();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'projects.homeList.remove',
  });
  const handleRemove = useOnRemove(props);

  return (
    <HomesListPanel
      noProject
      projectId={projectId}
      rowActions={
        isActive
          ? (record, defaultActions) => (
              <Space>
                {defaultActions}
                <Assert permission={CommunityPermissions.Homes.Manage} active>
                  <DeleteIconButton
                    title={t('btnTitle')}
                    className="table-action"
                    onClick={() => handleRemove(record.id)}
                  />
                </Assert>
              </Space>
            )
          : false
      }
      tablelActions={
        <Assert permission={CommunityPermissions.Homes.Manage} active>
          <AssignMenuButton
            className="mb-3"
            communityId={data!.communityId}
            homeIds={selected}
            projectId={projectId}
            disabled={selected.length === 0 || !isActive}
            onSaved={() => setSelected([])}
          />
        </Assert>
      }
      rowSelection={{
        type: 'checkbox',
        onChange: (_, rows) => setSelected(rows.map((x) => x.id)),
        selectedRowKeys: selected,
        fixed: 'left',
      }}
    />
  );
}
