import {
  UpdateTaskTemplateArgs,
  useGetTaskTemplateQuery,
  useUpdateTaskTemplateMutation,
} from '@/redux';
import { useNavigate } from 'react-router-dom';
import { notify } from '@/components';
import { FormTaskTemplate } from './Form';
import { parseResponsiblePerson } from "@/views";

interface Props {
  id: string;
  communityId?: string;
  onSaved?: () => any;
}

const useSubmit = (props: Props) => {
  const [update] = useUpdateTaskTemplateMutation();
  return async (values: UpdateTaskTemplateArgs) => {
    values.tasks.map(task => task.responsiblePerson = parseResponsiblePerson(task))
    await update(values)
      .unwrap()
      .then(props.onSaved)
      .then(() => notify.success.t('taskTemplates.details.saved'));
  };
};

export function EditTaskTemplatePanel(props: Props) {
  const submit = useSubmit(props);
  const { id, communityId } = props;
  const { currentData } = useGetTaskTemplateQuery({ id });
  const push = useNavigate();

  return (
    <>
      {currentData && (
        <FormTaskTemplate<UpdateTaskTemplateArgs>
          onSubmit={submit}
          value={currentData}
          noTemplate
          onCancel={() => push(-1)}
          communityId={communityId}
        />
      )}
    </>
  );
}