import { useTranslation } from 'react-i18next';
import { Form, Icon, Page } from '@/components';
import { useParams, useNavigate } from 'react-router-dom';
import { CommunityDetailsTabKeys, EditPathTemplatePanel } from '@/views';
import { Button, Space } from 'antd';
import { useGetPathTemplateDetailsQuery } from '@/redux';

const useIds = () => {
  const { id } = useParams<'id'>();
  const { communityId } = useParams<'communityId'>();
  return { id: id!, communityId: communityId! };
};

export function CustomPathTemplateEditPage() {
  const { id, communityId } = useIds();
  const { data } = useGetPathTemplateDetailsQuery({
    id,
    includeCommunityLevel: true,
  });
  const { t } = useTranslation();
  const title = t('pathTemplates.details.editTitle');
  const push = useNavigate();

  return (
    <Page htmlTitle={title}>
      <Page.Paths>
        <Page.Path href="/communities">{t('communities.title')}</Page.Path>
        <Page.Path
          href={`/communities/${data?.communityId}#${CommunityDetailsTabKeys['path-templates']}`}
        >
          {data?.communityName ?? t('fetchFallbackTitle')}
        </Page.Path>
        <Page.Path
          href={`/communities/${data?.communityId}#${CommunityDetailsTabKeys['path-templates']}`}
        >
          {t('pathTemplates.title')}
        </Page.Path>
        <Page.Path>{title}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card
          actionsPosition="bottom"
          actions={
            <Space size="middle">
              <Button onClick={() => push(-1)}>{t('cancel')}</Button>
              <Form.Submit uid="pathTemplate-details" type="primary">
                {t('save')}
              </Form.Submit>
            </Space>
          }
          title={title}
          icon={<Icon type="template" />}
          color="#EFAC4E"
        >
          <EditPathTemplatePanel
            id={id}
            onSaved={() => push(-1)}
            communityId={communityId}
          />
        </Page.Card>
      </Page.Body>
    </Page>
  );
}
