import { LastNotificationsPanel } from '@/views/Notifications.Last/LastNotificationsPanel';
import { NotificationSettingsModal } from '@/views/Notifications.Last/NotificationSettingsModal';
import { useBool } from '@/utils';
import styles from './IndexNotificationsPanel.module.scss'

export function IndexNotificationsPanel() {
  const [modalState, setModalState] = useBool(false);

  return (
    <div className={styles.container}>
      <LastNotificationsPanel
        adaptive
        onClosePopover={() => setModalState(true)}
      />
      <NotificationSettingsModal
        onClose={() => setModalState(false)}
        visible={modalState}
      />
  </div>
)}