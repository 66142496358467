import { useField, useFormikContext } from 'formik';
import { FileUpload, FileUploadProps } from '../FileUpload';
import { FormsGroup } from './FormsGroup';
import { useFormLabel } from './FormsI18nContext';
import { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';

export type FormsFileUploadProps = Omit<FileUploadProps, 'onChange'> & {
  name: string;
  label?: string;
  required?: boolean;
};

export const FormsFileUpload = ({
  name,
  label: labelOverride,
  required,
  accept,
}: FormsFileUploadProps) => {
  const [field] = useField(name);
  const [key, setKey] = useState(uniqueId());

  const { setFieldValue, initialValues } = useFormikContext();

  const initialValue = (initialValues as any)[name];
  useEffect(() => {
    if (field.value === initialValue) {
      setKey(uniqueId());
    }
  }, [initialValue, field.value]);

  const label = useFormLabel(name, labelOverride);

  const handleChange = (value?: string | null) => {
    setFieldValue(name, value, true);
  };

  return (
    <FormsGroup label={label} name={name} required={required}>
      <FileUpload
        key={key}
        {...field}
        onChange={handleChange}
        accept={accept}
      />
    </FormsGroup>
  );
};
