import { AddButton, notify } from '@/components';
import {
  AddSpecialRequestCommentArgs,
  useAddSpecialRequestCommentMutation,
} from '@/redux';
import { Space } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormSpecialRequestComment } from './FormSpecialRequestComment';
import { SpecialRequestCommentsList } from './SpecialRequestCommentsList';

interface Props {
  specialRequestId: string;
}

export function useSpecialReuqestCommentInitialValue({
  specialRequestId,
}: Props) {
  return useMemo<AddSpecialRequestCommentArgs>(
    () => ({
      content: null!,
      specialRequestId,
    }),
    [specialRequestId],
  );
}

export const useSpecialRequestSubmit = (onClose: () => any) => {
  const [add] = useAddSpecialRequestCommentMutation();

  return (args: AddSpecialRequestCommentArgs) =>
    add(args)
      .unwrap()
      .then(() => {
        notify.success.t('specialRequests.comments.added');
        onClose();
      });
};

export const SpecialRequestCommentsPanel = (props: Props) => {
  const { specialRequestId } = props;
  const { t } = useTranslation();
  const [addComment, setAddComment] = useState(false);
  const INITIAL_VALUES = useSpecialReuqestCommentInitialValue(props);
  const submit = useSpecialRequestSubmit(() => setAddComment(false));

  return (
    <>
      <Space className="w-100" direction="vertical">
        <AddButton type="link" onClick={() => setAddComment(true)}>
          {t('specialRequests.comments.add')}
        </AddButton>
        {addComment && (
          <FormSpecialRequestComment
            value={INITIAL_VALUES}
            onCancel={() => setAddComment(false)}
            onSubmit={submit}
          />
        )}
      </Space>
      <SpecialRequestCommentsList specialRequestId={specialRequestId} />
    </>
  );
};
