import {
  PathTemplateState,
  RtkqSpin,
  useLazyGetPathTemplatesListQuery,
  useLazyGetPathTemplateSourcesQuery,
} from '@/redux';
import {
  createEntireLoadLookupSource,
  EMPTY_LOOKUP_SOURCE,
  LookupOption,
  LookupSource,
} from '@/components';
import { useMemo } from 'react';
import { BFP_ORGANIZATION_ID } from '@/platform';
import { DEFAULT_PAGING } from '@/core';

function map(value: PathTemplateState): LookupOption {
  return {
    label: value.name,
    value: value.id,
  };
}

export function useCustomAndDefaultPathTemplateLookupSource(
  communityId?: string,
): LookupSource {
  const [fetchList] = useLazyGetPathTemplateSourcesQuery();

  return useMemo(() => {
    if (!communityId) {
      return EMPTY_LOOKUP_SOURCE;
    }

    return createEntireLoadLookupSource({
      initial: (id: string) =>
        fetchList({ communityId, [RtkqSpin]: false }, true)
          .unwrap()
          .then((values) => map(values.find((x) => x.id === id)!)),
      load: () =>
        fetchList({ communityId, [RtkqSpin]: false }, true)
          .unwrap()
          .then((values) => values.map(map)),
    });
  }, [communityId, fetchList]);
}

export function useCustomPathTemplateLookupSource(
  communityId?: string,
): LookupSource {
  const [fetchList] = useLazyGetPathTemplatesListQuery();

  return useMemo(() => {
    if (!communityId) {
      return EMPTY_LOOKUP_SOURCE;
    }

    return createEntireLoadLookupSource({
      initial: (id: string) =>
        fetchList(
          { communityId, paging: DEFAULT_PAGING, [RtkqSpin]: false },
          true,
        )
          .unwrap()
          .then((values) => map(values.items.find((x) => x.id === id)!)),
      load: () =>
        fetchList(
          { communityId, paging: DEFAULT_PAGING, [RtkqSpin]: false },
          true,
        )
          .unwrap()
          .then((values) => values.items.map(map)),
    });
  }, [communityId, fetchList]);
}

export function useDefaultPathTemplateLookupSource(): LookupSource {
  const [fetchList] = useLazyGetPathTemplatesListQuery();

  return useMemo(() => {
    return createEntireLoadLookupSource({
      initial: (id: string) =>
        fetchList(
          {
            organizationId: BFP_ORGANIZATION_ID,
            communityId: undefined,
            paging: DEFAULT_PAGING,
            [RtkqSpin]: false,
          },
          true,
        )
          .unwrap()
          .then((values) => map(values.items.find((x) => x.id === id)!)),
      load: () =>
        fetchList(
          {
            organizationId: BFP_ORGANIZATION_ID,
            communityId: undefined,
            paging: DEFAULT_PAGING,
            [RtkqSpin]: false,
          },
          true,
        )
          .unwrap()
          .then((values) => values.items.map(map)),
    });
  }, [fetchList]);
}
