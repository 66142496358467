import { AsyncSelect, LookupOption } from '@/components';
import { useSubcontractorLookupSource } from '@/views';
import { useCallback, useState } from 'react';

interface Props {
  communityId: string;
  initialSubcontractor?: LookupOption;
  onSubmit: (value: string | undefined) => any;
  disabled: boolean;
}

export function PricingSpecialRequestSubcontractor(props: Props) {
  const { communityId, initialSubcontractor, onSubmit, disabled } = props;
  const [selectedSubcontractor, setSelectedSubcontractor] = useState<
    string | undefined
  >(initialSubcontractor?.value);
  const subcontractorDataSource = useSubcontractorLookupSource(communityId);

  const handleSubmit = useCallback(
    (selectedSubcontractor: string | undefined) => {
      setSelectedSubcontractor(selectedSubcontractor);
      onSubmit(selectedSubcontractor);
    },
    [onSubmit],
  );

  return (
    <AsyncSelect
      value={selectedSubcontractor}
      preloadInitial={initialSubcontractor}
      onChange={handleSubmit}
      dataSource={subcontractorDataSource}
      disabled={disabled}
    />
  );
}
