import { AddButton, TabPanel, useTabPanelNavMenuState } from '@/components';
import { useTranslation } from 'react-i18next';
import { array } from '@/utils';
import { ItemMenuState, useGetItemMenusQuery } from '@/redux';
import { useMemo, useState } from 'react';
import { isEmpty, orderBy } from 'lodash';
import { AddItemMenu } from '../ItemMenus.Details/AddItemMenu';
import { ItemMenuDetails } from '../ItemMenus.Details';
import { Assert } from '../Communities.Common';
import { CommunityPermissions } from '@/core';

interface Props {
  communityId: string;
}

function useInitialItemMenu({ communityId }: Props) {
  return useMemo<ItemMenuState>(
    () => ({
      id: null!,
      communityId: communityId,
      name: null!,
      items: [],
    }),
    [communityId],
  );
}

export function ItemMenusList(props: Props) {
  const { communityId } = props;
  const { data = array.empty<ItemMenuState>() } = useGetItemMenusQuery({
    communityId,
  });

  const { t } = useTranslation();
  const [add, setAdd] = useState(false);
  const INITIAL_ITEMMENU = useInitialItemMenu(props);

  const orderedData = useMemo(() => orderBy(data, (x) => x.name), [data]);

  const items = useMemo(
    () => (add ? [...orderedData, INITIAL_ITEMMENU] : orderedData),
    [orderedData, add, INITIAL_ITEMMENU],
  );

  const tabNav = useTabPanelNavMenuState({
    items,
    keyBy: (x) => x.id ?? 'new',
    titleBy: (x) => x.name ?? t('itemMenus.new'),
  });

  const { select, selected } = tabNav;

  const handleAddClick = () => {
    setAdd(true);
    select('new');
  };

  const handleSelect = (id: string) => {
    setAdd(false);
    select(id);
  };

  const empty = isEmpty(items);

  return (
    <TabPanel min="auto">
      <TabPanel.Title>{t('itemMenus.title')}</TabPanel.Title>
      <TabPanel.Actions>
        <Assert permission={CommunityPermissions.Menus.Manage} active>
          <AddButton onClick={handleAddClick}>{t('itemMenus.add')}</AddButton>
        </Assert>
      </TabPanel.Actions>
      {!empty && (
        <TabPanel.Nav title={t('itemMenus.navTitle')}>
          <TabPanel.Menu<ItemMenuState> {...tabNav} onSelected={handleSelect} />
        </TabPanel.Nav>
      )}
      <TabPanel.Body>
        {add && (
          <AddItemMenu
            onCancel={() => setAdd(false)}
            communityId={communityId}
          />
        )}
        {selected && !add && <ItemMenuDetails id={selected.id} />}
      </TabPanel.Body>
    </TabPanel>
  );
}
