import { Modal, Button } from 'antd';
import { Form, notify, Table } from '@/components';
import { useTranslation } from 'react-i18next';
import { useUserContextSelector } from '@/redux';
import {
  useGetUserNotificationSettingsQuery,
  useSetUserNotificationSettingsMutation,
} from '@/redux/userNotificationSettings';
import { UserNotificationSettings } from '@/core/http.userNotificationSettings';
import { useNotificationSettingsColumns } from '@/views/Notifications.Last/useNotificationSettingsColumns';

interface Props {
  onClose: () => any;
  visible: boolean;
}

export function NotificationSettingsModal(props: Props) {
  const { onClose: onCancel, visible } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'notifications.settings',
  });
  const userId = useUserContextSelector((x) => x.id);
  const { data } = useGetUserNotificationSettingsQuery({ userId });

  const [updateSettings] = useSetUserNotificationSettingsMutation();

  const handleSubmit = (values: UserNotificationSettings[]) => {
    updateSettings({ notificationSettings: values, userId })
      .unwrap()
      .then(() => {
        onCancel();
        notify.success.t('notifications.settings.saved');
      });
  };

  const columns = useNotificationSettingsColumns();

  if (!data) return <></>;
  return (
    <Form.Formik<UserNotificationSettings[]>
      uid="notifications-settings"
      i18n="notifications.settings"
      initialValues={data}
      onSubmit={handleSubmit}
    >
      {({ submitForm }) => (
        <Modal
          title={t('title')}
          width={'80%'}
          visible={visible}
          onCancel={onCancel}
          footer={[
            <Button key="back" type="default" onClick={onCancel}>
              {t('cancel')}
            </Button>,
            <Button key="submit" type="primary" onClick={submitForm}>
              {t('save')}
            </Button>,
          ]}
        >
          <Table<UserNotificationSettings>
            name='Communities'
            dataSource={data}
            columns={columns}
            rowKey={(_, index) => `${index}`}
            pagination={false}
            hideDefaultActions
          />
        </Modal>
      )}
    </Form.Formik>
  );
}
