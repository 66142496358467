import { useTranslation } from 'react-i18next';
import { PersonalizationTableRowValues } from './PersonalizationDetails';
import { useGetPersonalizationItemsSubtotalAmount } from './PersonalizationItemList';
import { PersonalizationSummaryTotalInfo } from './PersonalizationSummaryTotalInfo';

interface Props {
  allPersonalizations: PersonalizationTableRowValues[];
  showBaseCost: boolean;
  showResidentPrice: boolean;
}

export function PersonalizationSummaryHomeTotaBalance(props: Props) {
  const { showBaseCost, showResidentPrice, allPersonalizations } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'personalization.summary',
  });

  const { amount: personalizationsResidentPriceAmount } =
    useGetPersonalizationItemsSubtotalAmount(
      allPersonalizations,
      (x) => x.totalResidentPrice,
      showResidentPrice,
    );
  const { amount: personalizationsBaseCostAmount } =
    useGetPersonalizationItemsSubtotalAmount(
      allPersonalizations,
      (x) => x.totalBaseCost,
      showBaseCost,
    );

  return (
    <PersonalizationSummaryTotalInfo
      title={t('homeTotalTitle')}
      baseCostTotal={personalizationsBaseCostAmount}
      residentPriceTotal={personalizationsResidentPriceAmount}
    />
  );
}
