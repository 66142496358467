import { useState } from 'react';
import { Button } from 'antd';
import { HomeDetailsModal } from '@/views';
import { useTranslation } from 'react-i18next';
import { AddHomeArgs, useAddHomeMutation } from '@/redux';
import { useNavigate } from 'react-router-dom';
import { notify } from '@/components';

interface Props {
  className?: string;
  communityId: string;
  addAnotherHouse?: boolean;
}

const useSubmit = (props: Props) => {
  const { communityId } = props;
  const [add] = useAddHomeMutation();
  const push = useNavigate();

  return (args: AddHomeArgs, addAnotherHouse?: boolean) => {
    add(args)
      .unwrap()
      .then((id) => {
        notify.success.t('homes.added');
        if (!addAnotherHouse) push(`/communities/${communityId}/homes/${id}`);
      });
  };
};

export function AddHomeButton(props: Props) {
  const submit = useSubmit(props);
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const { className, communityId } = props;

  const initialValue: AddHomeArgs = {
    communityId: communityId,
    homeNumber: null!,
    constructionNumber: null!,
    location: {
      level1Id: null!,
      level2Id: null!,
      level3Id: null!,
    },
  };

  return (
    <div className={className}>
      <Button type="primary" onClick={() => setOpenModal(true)}>
        {t('homes.details.addButton')}
      </Button>
      {openModal && (
        <HomeDetailsModal
          title={t('homes.details.addTitle')}
          buttonSaveAndAddName={t('homes.details.saveAndAdd')}
          buttonSaveName={t('homes.details.saveAndDone')}
          onSubmit={submit}
          value={initialValue}
          onClose={() => setOpenModal(false)}
          showSaveAndAddButton={true}
        />
      )}
    </div>
  );
}
