import { enumObject, EnumValue } from '@/utils';

const ROLE_VALUES = ['BFP_ADMIN', 'CLIENT_ADMIN', 'CLIENT_USER'] as const;

export type Role = EnumValue<typeof ROLE_VALUES>;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Role = enumObject('Role', ROLE_VALUES);

export const BFP_ROLES: Role[] = ['BFP_ADMIN'];
export const ADMIN_ROLES: Role[] = ['BFP_ADMIN', 'CLIENT_ADMIN'];
export const CLIENT_ROLES: Role[] = ['CLIENT_ADMIN', 'CLIENT_USER'];
