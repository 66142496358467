import {
  CustomReportColumn,
  CustomReportType,
  HomeSubtotalReportColumnValues,
  NoSubTotalReportColumnValues,
} from '@/core';
import { useMemo } from 'react';
import {
  HOME_SUBTOTAL_TEMPLATE,
  NO_SUBTOTAL_TEMPLATE,
} from './useReportExcelTemplateLookupSource';

export function useForceSelectedColumns(
  type: CustomReportType,
): CustomReportColumn[] {
  return useMemo(
    () =>
      type === 'HomeSubtotal'
        ? [CustomReportColumn.HomeNumber, CustomReportColumn.ProjectId]
        : [],
    [type],
  );
}

export function getCustomReportAvailableColumns(
  templateId?: string,
): CustomReportColumn[] {
  if (!templateId) return [];

  if (templateId === HOME_SUBTOTAL_TEMPLATE) {
    return [...HomeSubtotalReportColumnValues];
  }

  return [...NoSubTotalReportColumnValues];
}

export function useCustomReportAvailableColumns(templateId?: string) {
  return useMemo(
    () => getCustomReportAvailableColumns(templateId),
    [templateId],
  );
}

export function getCustomReportType(templateId: string): CustomReportType {
  if (templateId === NO_SUBTOTAL_TEMPLATE) {
    return CustomReportType.NoSubTotal;
  }

  if (templateId === HOME_SUBTOTAL_TEMPLATE) {
    return CustomReportType.HomeSubtotal;
  }

  return CustomReportType.CustomTemplate;
}
