import { CheckableTree } from '@/components';
import { RoomCategory } from '@/core';
import { useField } from 'formik';
import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { useRoomCategoryTreeData } from './useRoomCategoryTreeData';

interface Props {
  name: string;
  communityId: string;
  initialValue: RoomCategory[];
}

function useTreeData(props: Props) {
  const { communityId, initialValue } = props;
  return useRoomCategoryTreeData(communityId, initialValue, false);
}

function useHandleCheck(props: Props) {
  const { name } = props;
  const [{ value }, , { setValue }] = useField<RoomCategory[]>(name);

  return useCallback(
    (keys: string[]) => {
      const result: RoomCategory[] = keys.map((key) => {
        const current = value.find((x) => x.id === key);
        return {
          id: key,
          implicit: false,
          squareFootage: current?.squareFootage,
        };
      });

      setValue(result);
    },
    [value, setValue],
  );
}

export function FormRoomCategories(props: Props) {
  const { name } = props;
  const [{ value }] = useField<RoomCategory[]>(name);
  const treeData = useTreeData(props);
  const checkedKeys = value.map((x) => x.id);
  const handleCheck = useHandleCheck(props);

  if (isEmpty(treeData)) {
    return null;
  }

  return (
    <CheckableTree
      treeData={treeData}
      onCheck={handleCheck}
      checkedKeys={checkedKeys}
      selectable={false}
      mode="any"
      virtual={false}
    />
  );
}
