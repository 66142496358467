import { FieldRow, Form } from '@/components';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { FloorplanType, isChooseExistingOption } from '@/core';
import {
  OPTIONS,
  assignFloorplanSchema,
  useValue,
} from './AssignFloorplanToHomeInModal';
import { AssignFloorplanToHomeArgs } from '@/redux';
import { useFloorplanLookupSource } from '../Floorplans.Common';
import { FormsRadio } from '@/components/Forms/FormsRadio';
import {
  FloorplanCopyMenuForm,
  useFloorplanCopyMenu,
} from '../Floorplans.Details/FloorplanCopyMenuForm';

interface FloorplanToHomeProps {
  communityId: string;
  homeId: string;
  onSubmit: (values: AssignFloorplanToHomeArgs) => void;
}
export const AddFloorplanToHomeInForm = (props: FloorplanToHomeProps) => {
  const { t } = useTranslation();
  const { communityId, homeId, onSubmit } = props;
  const floorplansDataSource = useFloorplanLookupSource(communityId);
  const initialValues = useValue(communityId, homeId);
  const floorplanCopyProps = useFloorplanCopyMenu('home-floorplan');

  return (
    <Form.Formik<AssignFloorplanToHomeArgs>
      uid="home-floorplan"
      i18n="floorplans.details"
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={assignFloorplanSchema}
      enableReinitialize
    >
      {({ values }) => (
        <>
          <FormsRadio
            name="creationOption"
            options={OPTIONS}
            label={t('floorplans.chooseExistingOrSetManually')}
            inline={false}
          />
          {isChooseExistingOption(values.creationOption) && (
            <Form.AsyncSelect
              name="floorplanId"
              dataSource={floorplansDataSource}
              required
              preload
              label={t('floorplans.header')}
            />
          )}
          {values.creationOption === 'copy' && (
            <FloorplanCopyMenuForm
              communityId={values.communityId}
              {...floorplanCopyProps}
            />
          )}
          {!isChooseExistingOption(values.creationOption) && (
            <>
              <FieldRow>
                <Col span={12}>
                  <Form.Input
                    name="marketingName"
                    placeholder={t(
                      'floorplans.details.placeholder.marketingName',
                    )}
                    required
                  />
                </Col>
                <Col span={12}>
                  <Form.Input
                    name="constructionName"
                    placeholder={t(
                      'floorplans.details.placeholder.constructionName',
                    )}
                  />
                </Col>
              </FieldRow>
              <FieldRow>
                <Col span={12}>
                  <Form.EnumSelect type={FloorplanType} name="type" required />
                </Col>
                <Col span={12}>
                  <Form.Number
                    name="squareFootage"
                    prefix=""
                    min={0}
                    placeholder={t(
                      'floorplans.details.placeholder.squareFootage',
                    )}
                  />
                </Col>
              </FieldRow>
              <FieldRow>
                <Col span={24}>
                  <Form.TextArea name="description" />
                </Col>
              </FieldRow>
            </>
          )}
        </>
      )}
    </Form.Formik>
  );
};
