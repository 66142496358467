import { DATE_FORMAT_MOMENT } from '@/platform';
import moment from 'moment';

interface Props {
  value: Date | string | undefined;
  className?: string;
}

export const DateValue = (props: Props) => {
  const { value: valueProp, className } = props;
  const value = moment(valueProp);

  return !valueProp ? null : (
    <span className={className}>
      {value.isValid() ? value.format(DATE_FORMAT_MOMENT) : DATE_FORMAT_MOMENT}
    </span>
  );
};
