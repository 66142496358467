import { AttachmentDto } from '@/core';
import { ClipContentDto } from '@webinex/clippo';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

export interface ImageBoxCacheContextProviderProps {
  items: AttachmentDto[];
  content: (id: string) => Promise<ClipContentDto>;
  children: React.ReactNode;
}

export interface ImageBoxBlobResult {
  isLoading: boolean;
  content?: ClipContentDto;
}

export interface ImageBoxCacheContextValue {
  content: (id: string, callback: (value: ImageBoxBlobResult) => any) => any;
}

export const ImageBoxCacheContext =
  React.createContext<ImageBoxCacheContextValue>(null!);

const INITIAL_CONTENT_VALUE: ImageBoxBlobResult = { isLoading: true };

function useContent(props: ImageBoxCacheContextProviderProps) {
  const { content } = props;
  const cache = useRef<Record<string, ImageBoxBlobResult>>({});

  return useCallback(
    (id: string, callback: (value: ImageBoxBlobResult) => any) => {
      let cached = cache.current[id];
      cache.current[id] = cached ?? INITIAL_CONTENT_VALUE;

      if (!cached) {
        content(id)
          .then((x) => (cache.current[id] = { isLoading: false, content: x }))
          .then(() => callback(cache.current[id]));
      } else {
        callback(cached);
      }
    },
    [content, cache],
  );
}

export function ImageBoxContextProvider(
  props: ImageBoxCacheContextProviderProps,
) {
  const { items, children } = props;
  const content = useContent(props);
  const value = useMemo(() => ({ content, items }), [content, items]);

  return (
    <ImageBoxCacheContext.Provider value={value}>
      {children}
    </ImageBoxCacheContext.Provider>
  );
}

export function useImageBoxImages() {
  const { content } = useContext(ImageBoxCacheContext);
  const [blobURLById, setBlobURLById] = useState<Record<string, string>>({});

  const load = useCallback(
    (id: string) => {
      content(id, (value) => {
        const blob = value.content?.blob;
        if (!blob) {
          return;
        }

        setBlobURLById((prev) => ({
          ...prev,
          [id]: URL.createObjectURL(blob),
        }));
      });
    },
    [content, setBlobURLById],
  );

  const blobURL = useCallback((id: string) => blobURLById[id], [blobURLById]);

  useEffect(() => {
    return () => {
      Object.values(blobURLById).forEach((url) => URL.revokeObjectURL(url));
    };

    // eslint-disable-next-line
  }, []);

  return useMemo(
    () => ({
      load,
      blobURL,
    }),
    [load, blobURL],
  );
}

export function useImageBoxImageBlobURL(id: string) {
  const { blobURL, load } = useImageBoxImages();

  useEffect(() => {
    load(id);

    // eslint-disable-next-line
  }, [id, load]);

  return blobURL(id);
}
