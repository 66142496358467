import { Http, Paged, Paging } from '@/core';
import { guard } from '@/utils';
import {
  AddCustomLegalLanguageDto,
  CustomLegalLanguageDto,
  CustomLegalLanguageFilterParams,
  CustomLegalLanguageLookupDto,
  UpdateLegalLanguageDto,
} from './customLegalLanguageTypes';
import qs from 'qs';
import { SortResult } from '@/components/Collections.Paging/useSorting';

class CustomLegalLanguageHttp extends Http {
  public async getAll(
    paging: Paging,
    filters?: CustomLegalLanguageFilterParams,
    sorting?: SortResult,
  ) {
    const response = await this._axios.get<Paged<CustomLegalLanguageDto>>(
      `/api/custom-legal-language?${qs.stringify({
        ...paging,
        ...filters,
        ...sorting,
      })}`,
    );
    return response.data;
  }

  public async add(args: AddCustomLegalLanguageDto) {
    guard.notNull(args, 'args');
    const response = await this._axios.post(`/api/custom-legal-language`, args);
    return response.data;
  }

  public async update(args: UpdateLegalLanguageDto) {
    guard.notNull(args, 'args');
    const response = await this._axios.put<CustomLegalLanguageLookupDto>(
      `/api/custom-legal-language`,
      args,
    );
    return response.data;
  }

  public async delete(id: string) {
    guard.notNull(id, 'id');
    await this._axios.delete(`/api/custom-legal-language/${id}`);
  }
}

export const customLegalLanguageHttp = new CustomLegalLanguageHttp();
