import { useEditHomeAction } from '@/views';
import { EditIconButton } from '@/components';

interface Props {
  id: string;
}

export function EditHomeButton(props: Props) {
  const { id } = props;
  const { openEdit, editModal } = useEditHomeAction();

  return (
    <div>
      <EditIconButton onClick={() => openEdit(id)} />
      {editModal}
    </div>
  );
}
