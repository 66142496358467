import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
} from '@ant-design/icons';

export function RoomPersonalizationTabNavIcon({
  isAlert,
}: {
  isAlert: boolean;
}) {
  return isAlert ? (
    <ExclamationCircleTwoTone
      twoToneColor={['var(--addition-gold-color)', 'transparent']}
    />
  ) : (
    <CheckCircleTwoTone
      twoToneColor={['var(--addition-classic-green-color)', 'transparent']}
    />
  );
}
