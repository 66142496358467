import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
}

export function DevelopScopeHomeFinalizedAlert(props: Props) {
  const { className } = props;
  const { t } = useTranslation();

  return (
    <Alert
      message={t('developScope.homeFinalized')}
      type="success"
      className={className}
      showIcon
    />
  );
}
