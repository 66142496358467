import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddSubcontractorArgs,
  useAddSubcontractorMutation,
} from '@/redux/subcontractors';
import { SubcontractorDetailsModal } from '@/views';
import { AddButton, notify } from '@/components';

interface Props {
  className?: string;
  communityId: string;
}

const useSubmit = (setOpenModal: Dispatch<SetStateAction<boolean>>) => {
  const [add] = useAddSubcontractorMutation();

  return (args: AddSubcontractorArgs) => {
    add(args)
      .unwrap()
      .then(() => {
        notify.success.t('subcontractors.added');
        setOpenModal(false);
      });
  };
};

function useValue(props: Props) {
  const { communityId } = props;

  return useMemo(
    () => ({
      communityId: communityId,
      name: null!,
      email: null!,
      phone: null!,
      workType: null!,
    }),
    [communityId],
  );
}

export function AddSubcontractorButton(props: Props) {
  const [openModal, setOpenModal] = useState(false);
  const submit = useSubmit(setOpenModal);
  const { t } = useTranslation();
  const { className } = props;

  const initialValue: AddSubcontractorArgs = useValue(props);

  return (
    <div className={className}>
      <AddButton onClick={() => setOpenModal(true)}>
        {t('subcontractors.details.addButton')}
      </AddButton>
      {openModal && (
        <SubcontractorDetailsModal
          title={t('subcontractors.details.addTitle')}
          buttonSaveName={t('add')}
          onSubmit={submit}
          value={initialValue}
          onClose={() => setOpenModal(false)}
        />
      )}
    </div>
  );
}
