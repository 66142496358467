import { ReactNode, useCallback, useRef } from 'react';
import styles from './UserAvatarEdit.module.scss';

export interface PickerProps {
  onSelected: (file: File) => any;
  className?: string;
  children?: ReactNode | undefined;
}

function usePickerState(props: PickerProps) {
  const { onSelected } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleSelected = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.currentTarget.files![0];
      onSelected(file);
    },
    [onSelected],
  );

  const open = useCallback(() => inputRef.current!.click(), []);

  return { inputRef, open, onSelected: handleSelected };
}

export function Picker(props: PickerProps) {
  const { inputRef, open, onSelected } = usePickerState(props);

  return (
    <div className={props.className} onClick={open}>
      {props.children}
      <input
        ref={inputRef}
        className={styles.pickerInput}
        type="file"
        accept="image/*"
        onChange={onSelected}
        value={''}
      />
    </div>
  );
}
