import { RoomCategory } from '@/core';
import { isEmpty } from 'lodash';
import { useRoomCategoryTreeData } from './useRoomCategoryTreeData';
import { Tree } from '@/components';

interface Props {
  communityId: string;
  categories: RoomCategory[];
}

export function RoomCategories(props: Props) {
  const { categories, communityId } = props;
  const categoryIds = categories.map((x) => x.id);
  const treeData = useRoomCategoryTreeData(communityId, categories, true);

  if (isEmpty(treeData)) {
    return null;
  }

  return (
    <Tree
      treeData={treeData}
      checkedKeys={categoryIds}
      selectable={false}
      virtual={false}
    />
  );
}
