export type EntityNumberLetter =
  | 'P'
  | 'F'
  | 'H'
  | 'X'
  | 'C'
  | 'Y'
  | 'I'
  | 'U'
  | 'T';

export const ENTITY_NUMBER_LENGTH = 8;

export interface SearchByNumberDto {
  number: string;
  id: string;
}
