import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageCropperProps } from './ImageCropperProps';

const Component = React.lazy(() => import('./ImageCropper'));

export const LazyImageCropper = (props: ImageCropperProps) => {
  const { t } = useTranslation();

  return (
    <Suspense fallback={<div>{t('fetchFallbackTitle')}</div>}>
      <Component {...props} />
    </Suspense>
  );
};
