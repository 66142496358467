import { DeleteIconButton, StyledRow, confirmation } from '@/components';
import { Button, Typography } from 'antd';
import { ExternalLinkDto } from '@/core';
import styles from './ExternalLink.module.scss';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

interface Props {
  readonly?: boolean;
  link: ExternalLinkDto;
  onDelete?: (linkId: string) => any;
}

function useHandleClick(link: ExternalLinkDto) {
  const { t } = useTranslation();
  const handleClick = () => {
    confirmation.show({
      title: t('externalLinks.navigation.title'),
      body: t('externalLinks.navigation.body'),
      confirm: t('externalLinks.navigation.confirm'),
      onConfirm: () => {
        window.open(link.source, '_blank');
      },
    });
  };

  return { handleClick };
}

export function ExternalLinkItem(props: Props) {
  const { link, onDelete, readonly } = props;
  const { handleClick } = useHandleClick(link);
  const { t } = useTranslation();
  return (
    <StyledRow className={styles.linkRow}>
      <Button type="link" onClick={handleClick}>
        <Text underline className={styles.label}>
          <span>{t('externalLinks.source')}</span>
        </Text>
      </Button>
      {!readonly && (
        <DeleteIconButton
          confirm
          className={styles.delete}
          entityName={t('externalLinks.entityName')}
          type="link"
          onClick={() => onDelete!(link.id)}
        />
      )}
    </StyledRow>
  );
}
