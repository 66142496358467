import {
  FloorplansListPanel,
  LocationListPanel,
  AddProjectButton,
  ProjectsListPanel,
  SubcontractorsListPanel,
  HomesListTabPanel,
  ItemMenusList,
  useCommunityContext,
  PricingTabContent,
  ItemListPanel,
  TemplateTabContentKeys,
  PricingTabContentKeys,
} from '@/views';
import { Tabs, TabPanel } from '@/components';
import { useTranslation } from 'react-i18next';
import { enumObject } from '@/utils';
import { useGetCommunityDetailsQuery, useUserContextSelector } from '@/redux';
import { CommunityAmenities } from './CommunityAmenities';
import { CommunityPermissions } from '@/core';
import {
  AssignmentsTabContentKeys,
  CommunityAssignmentsTabContent,
} from '../CommunityAssignment.Common';
import { TemplateTabContent } from '@/views';

const TAB_KEYS = [
  'pricing',
  'subscriptions',
  'locations',
  'projects',
  'homes',
  'roles',
  'subcontractors',
  'items-and-categories',
  'menus',
  'floorplans',
  'amenities',
  'path-templates',
] as const;

export const CommunityDetailsTabKeys = enumObject(
  'CommunityDetailsTabKeys',
  TAB_KEYS,
);

const { TabPane } = Tabs;

interface Props {
  className?: string;
  communityId: string;
}

export const CommunityDetailsTabsPanel = (props: Props) => {
  const { className, communityId } = props;
  const { t } = useTranslation();
  const { hasPermission, active } = useCommunityContext();
  const hasRole = useUserContextSelector((x) => x.hasRole);

  const { data } = useGetCommunityDetailsQuery({ id: communityId });
  const isVisible = false;
  const hasSubscription = data?.hasSubscription;

  return (
    <Tabs
      className={className}
      tabKeys={CommunityDetailsTabKeys}
      destroyInactiveTabPane
    >
      {hasPermission(CommunityPermissions.Projects.View) && (
        <TabPane
          tab={t('communities.details.tabs.projects')}
          key={CommunityDetailsTabKeys.projects}
        >
          <TabPanel>
            <TabPanel.Title>{t('projects.title')}</TabPanel.Title>
            <TabPanel.Actions>
              {data?.clientId &&
                (hasRole('CLIENT_ADMIN') ||
                  hasRole('BFP_ADMIN') ||
                  hasPermission(CommunityPermissions.Projects.Manage)) &&
                active &&
                hasSubscription && (
                  <AddProjectButton
                    clientId={data?.clientId}
                    communityId={communityId}
                    noCommunity
                  />
                )}
            </TabPanel.Actions>
            <TabPanel.Body>
              <ProjectsListPanel
                readonly={!active}
                communityId={communityId}
                noCommunity
                noCreatedAt
              />
            </TabPanel.Body>
          </TabPanel>
        </TabPane>
      )}

      {isVisible && (
        <TabPane
          tab={t('communities.details.tabs.subscriptions')}
          key={CommunityDetailsTabKeys.subscriptions}
        >
          Stub subscriptions content
        </TabPane>
      )}

      {hasPermission(CommunityPermissions.Homes.View) && (
        <TabPane
          tab={t('communities.details.tabs.homes')}
          key={CommunityDetailsTabKeys.homes}
        >
          <HomesListTabPanel communityId={communityId} />
        </TabPane>
      )}

      {hasPermission(CommunityPermissions.Locations.View) && (
        <TabPane
          tab={t('communities.details.tabs.locations')}
          key={CommunityDetailsTabKeys.locations}
        >
          <LocationListPanel communityId={communityId} />
        </TabPane>
      )}

      {hasPermission(CommunityPermissions.Floorplans.View) && (
        <TabPane
          tab={t('communities.details.tabs.floorplans')}
          key={CommunityDetailsTabKeys.floorplans}
        >
          <FloorplansListPanel communityId={communityId} />
        </TabPane>
      )}

      {hasPermission(CommunityPermissions.ItemsCategories.View) && (
        <TabPane
          tab={t('communities.details.tabs.itemsAndCategories')}
          key={CommunityDetailsTabKeys['items-and-categories']}
        >
          <ItemListPanel communityId={communityId} className="pb-3" />
        </TabPane>
      )}

      {(hasRole('CLIENT_ADMIN') || hasRole('BFP_ADMIN')) && (
        <TabPane
          tab={t('template.tabTitle')}
          key={CommunityDetailsTabKeys['path-templates']}
        >
          {data && (
            <TemplateTabContent
              communityId={data.id}
              data={data}
              active={active}
              tabKeys={TemplateTabContentKeys}
            />
          )}
        </TabPane>
      )}

      {hasPermission(CommunityPermissions.Menus.View) && (
        <TabPane
          tab={t('communities.details.tabs.menus')}
          key={CommunityDetailsTabKeys.menus}
        >
          <ItemMenusList communityId={communityId} />
        </TabPane>
      )}

      {hasPermission(CommunityPermissions.Pricing.View) && (
        <TabPane
          tab={t('communities.details.tabs.pricing')}
          key={CommunityDetailsTabKeys.pricing}
        >
          <PricingTabContent
            communityId={communityId}
            tabKeys={PricingTabContentKeys}
          />
        </TabPane>
      )}

      {hasPermission(CommunityPermissions.Subcontractors.View) && (
        <TabPane
          tab={t('communities.details.tabs.subcontractors')}
          key={CommunityDetailsTabKeys.subcontractors}
        >
          <SubcontractorsListPanel communityId={communityId} />
        </TabPane>
      )}

      {hasPermission(CommunityPermissions.Users.View) && (
        <TabPane
          tab={t('communities.details.tabs.roles')}
          key={CommunityDetailsTabKeys.roles}
        >
          {data?.clientId && (
            <CommunityAssignmentsTabContent
              clientId={data.clientId}
              communityId={communityId}
              tabKeys={AssignmentsTabContentKeys}
            />
          )}
        </TabPane>
      )}

      {hasPermission(CommunityPermissions.Amenities.View) && (
        <TabPane
          tab={t('communities.details.tabs.amenities')}
          key={CommunityDetailsTabKeys.amenities}
        >
          <TabPanel min="auto">
            <TabPanel.Title>
              {t('communities.details.tabs.amenities')}
            </TabPanel.Title>
            <TabPanel.Body>
              <CommunityAmenities communityId={communityId} />
            </TabPanel.Body>
          </TabPanel>
        </TabPane>
      )}
    </Tabs>
  );
};
