import { Form, LookupOption } from '@/components';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useItemMenuLookupSource } from '@/views';
import React from 'react';

export interface AssignMenuDetailsModalValue {
  menuId: string;
}

const schema = Yup.object().shape({
  menuId: Yup.string().field().nullable().required(),
});

interface Props {
  onCancel: () => void;
  title: string;
  value: AssignMenuDetailsModalValue;
  onSubmit: (value: AssignMenuDetailsModalValue) => any;
  communityId: string;
  preloadInitialValue?: LookupOption;
  formHeader?: React.ReactElement;
}

export function AssignMenuModal(props: Props) {
  const {
    onCancel,
    title,
    value,
    onSubmit,
    communityId,
    preloadInitialValue,
    formHeader,
  } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'projects.homeList.assignMenu',
  });
  const itemMenuDataSource = useItemMenuLookupSource(communityId);

  return (
    <Modal
      title={title}
      visible
      onCancel={onCancel}
      footer={[
        <Button key="back" type="default" onClick={onCancel}>
          {t('cancel')}
        </Button>,
        <Form.Submit
          uid="project-homeList-assignMenu"
          key="submit"
          type="primary"
        >
          {t('buttonSaveName')}
        </Form.Submit>,
      ]}
    >
      <Form.Formik<AssignMenuDetailsModalValue>
        uid="project-homeList-assignMenu"
        i18n="projects.homeList.assignMenu"
        initialValues={value}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {formHeader}
        <Form.AsyncSelect
          dataSource={itemMenuDataSource}
          name="menuId"
          preloadInitial={preloadInitialValue}
          required
        />
      </Form.Formik>
    </Modal>
  );
}
