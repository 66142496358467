import { AddButton, notify } from '@/components';
import { AddSubOptionArgs, useAddSubOptionMutation } from '@/redux';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubOptionEditPanel } from './SubOptionEditPanel';

interface Props {
  itemId?: string;
  communityId?: string;
  className?: string;
  onSubmitHandler?: (subOptionId: string) => void;
}

const useSubmit = (
  setAddSubOption: (value: boolean) => any,
  onSubmitHandler?: (id: string) => void,
) => {
  const [add] = useAddSubOptionMutation();
  return (args: AddSubOptionArgs) =>
    add(args)
      .unwrap()
      .then((e) => {
        if (onSubmitHandler) onSubmitHandler(e);
      })
      .then(() => notify.success.t('subOptions.saved'))
      .then(() => setAddSubOption(false));
};

function useInitialValue({ itemId, communityId }: Props) {
  return useMemo<AddSubOptionArgs>(
    () => ({
      itemId: itemId,
      communityId: communityId,
      name: null!,
      images: [],
    }),
    [itemId, communityId],
  );
}

export function AddSubOptionButton(props: Props) {
  const { className, onSubmitHandler } = props;
  const [isAdd, setAdd] = useState(false);
  const submit = useSubmit(setAdd, onSubmitHandler);
  const { t } = useTranslation();
  const initialValue = useInitialValue(props);

  return (
    <>
      <AddButton
        type="default"
        onClick={() => setAdd(true)}
        className={className}
      >
        {t('subOptions.add')}
      </AddButton>
      {isAdd && (
        <div>
          <SubOptionEditPanel
            className="mb-3"
            onCancel={() => setAdd(false)}
            onSubmit={submit}
            value={initialValue}
          />
        </div>
      )}
    </>
  );
}
