import { Button, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnMenuItem, ColumnState } from './ColumnMenuItem';
import styles from './ColumnsControl.module.scss';

interface Props {
  columns: ColumnState[];
  onChange: (column: ColumnState) => any;
  onSave: (columns: ColumnState[]) => any;
}

export function ColumnsMenu(props: Props) {
  const { columns, onSave, onChange: onColumnChange, ...rest } = props;
  const { t } = useTranslation();

  const menuItems: ItemType[] = useMemo(() => {
    const columnsItems = columns.map((column) => ({
      label: <ColumnMenuItem column={column} onChange={onColumnChange} />,
      key: column.key,
    }));
    return [
      {
        type: 'group',
        key: 'columnItems',
        children: columnsItems,
      },
      { type: 'divider', key: 'divider' },
      {
        key: 'columns_actions',
        label: (
          <Button
            type="primary"
            style={{ float: 'right' }}
            size="small"
            onClick={() => onSave(columns)}
          >
            {t('ok')}
          </Button>
        ),
      },
    ];
  }, [columns, onSave, onColumnChange, t]);

  return <Menu className={styles.columnsMenu} {...rest} items={menuItems} />;
}
