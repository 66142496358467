import { Http, Paged, Paging, TaskTemplateMatrixDto } from '@/core';
import {
  CreateTaskTemplateDto,
  TaskTemplateDetailsDto,
  TaskTemplateDto,
  UpdateTaskTemplateDto,
} from '@/core';
import qs from 'qs';
import { guard } from '@/utils';

class TaskTemplatesHttp extends Http {
  public async getAll(args?: { communityId?: string; paging: Paging }) {
    const query = qs.stringify({ ...args, ...args?.paging });
    const url = `/api/task-template?${query}`;
    const response = await this._axios.get<Paged<TaskTemplateDto>>(url);
    return response.data;
  }

  public get = async (id: string) => {
    guard.notNull(id, 'id');
    const response = await this._axios.get<TaskTemplateDto>(
      `/api/task-template/${id}`,
    );
    return response.data;
  };

  public getDetails = async (id: string) => {
    guard.notNull(id, 'id');
    const response = await this._axios.get<TaskTemplateDetailsDto>(
      `/api/task-template/${id}/details`,
    );
    return response.data;
  };

  public async create(request: CreateTaskTemplateDto) {
    guard.notNull(request, 'request');
    const response = await this._axios.post<string>(
      `/api/task-template`,
      request,
    );
    return response.data;
  }

  public async update(request: UpdateTaskTemplateDto) {
    guard.notNull(request, 'request');
    const { id } = request;
    await this._axios.put(`/api/task-template/${id}`, request);
  }

  public async unlist(id: string) {
    guard.notNull(id, 'id');
    await this._axios.put(`/api/task-template/${id}/unlist`);
  }

  public async deleteTask(id: string) {
    guard.notNull(id, 'id');
    await this._axios.put(`/api/task-template/task/${id}`);
  }

  public async getTasksToImport(templateIds: string[]) {
    guard.notNull(templateIds, 'ids');
    const response = await this._axios.get<TaskTemplateMatrixDto>(
      `/api/task-template/import?${qs.stringify({ templateIds })}`,
    );
    return response.data;
  }
}

export const taskTemplatesHttp = new TaskTemplatesHttp();
