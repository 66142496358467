import {
  createEntireLoadLookupSource,
  EMPTY_LOOKUP_SOURCE,
  LookupSource,
} from '@/components';
import { RtkqSpin, useLazyGetReportingCommunitiesListQuery } from '@/redux';
import { useMemo } from 'react';
import { mapCommunityLookup } from './useCommunityLookupSource';

export function useReportingCommunitiesLookupSource(
  clientId: string[] | string | false,
): LookupSource {
  const [fetchList] = useLazyGetReportingCommunitiesListQuery();

  return useMemo(() => {
    if (!clientId && clientId !== false) {
      return EMPTY_LOOKUP_SOURCE;
    }

    const clientIdValue = typeof clientId === 'boolean' ? undefined : clientId;

    return createEntireLoadLookupSource({
      initial: (id: string) =>
        fetchList({ clientId: clientIdValue, [RtkqSpin]: false }, true)
          .unwrap()
          .then((values) =>
            mapCommunityLookup(values.find((x) => x.id === id)!),
          ),
      load: () =>
        fetchList({ clientId: clientIdValue, [RtkqSpin]: false }, true)
          .unwrap()
          .then((values) => values.map(mapCommunityLookup)),
    });
  }, [clientId, fetchList]);
}
