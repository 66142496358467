import { useCallback, useRef } from 'react';
import { useDrop } from 'react-dnd';
import { ConnectDropTarget, DropTargetMonitor } from 'react-dnd/dist/types';
import { ImageMarkerDropValue } from './ImageMarker';
import { ImageMarkerBoxProps, ImageMarkerValue } from './ImageMarkerBox';

function findRelative(container: HTMLElement, monitor: DropTargetMonitor) {
  const { x: iniX, y: iniY } = monitor.getInitialClientOffset()!;
  const { x: diX, y: diY } = monitor.getDifferenceFromInitialOffset()!;

  const {
    x: vX,
    y: vY,
    width: vW,
    height: vH,
  } = container.getBoundingClientRect();

  const iX = iniX + diX;
  const iY = iniY + diY;

  const xP = ((iX - vX) / vW) * 100;
  const yP = ((iY - vY) / vH) * 100;

  return { x: xP, y: yP };
}

export function useRelativeDropArea<T extends HTMLElement>(
  connectDrop: ConnectDropTarget,
): {
  dropRef: React.LegacyRef<T>;
  resolveXYPercents: (monitor: DropTargetMonitor) => { x: number; y: number };
} {
  const dropRef = useRef<T>();

  function setDropRef(e: T) {
    connectDrop(e);
    dropRef.current = e;
  }

  const resolveXYPercents = useCallback(
    (monitor: DropTargetMonitor) => findRelative(dropRef.current!, monitor),
    [dropRef],
  );

  return {
    dropRef: setDropRef,
    resolveXYPercents,
  };
}

export function useMarkerBoxDrop<T extends ImageMarkerValue>(
  props: ImageMarkerBoxProps<T>,
) {
  const { markers, onMarkersChange } = props;

  const handleDrop = useCallback(
    ({ index }: ImageMarkerDropValue, monitor: DropTargetMonitor) => {
      const xy = resolveXYPercents(monitor);
      const result = [...markers];
      result[index] = { ...markers[index], ...xy };
      onMarkersChange(result);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [markers, onMarkersChange],
  );

  const [, connectDrop] = useDrop<ImageMarkerDropValue>({
    accept: 'marker',
    drop: handleDrop,
  });

  const { dropRef, resolveXYPercents } =
    useRelativeDropArea<HTMLDivElement>(connectDrop);

  return { dropRef };
}
