import { useTranslation } from 'react-i18next';
import { AccessDeniedBanner, Page } from '@/components';
import { LayoutOutlined } from '@ant-design/icons';
import { ReportActions, GenerateCustomReportPanel } from '@/views';
import { useState } from 'react';
import { useUserContextSelector } from '@/redux';
import { useLocation } from 'react-router-dom';

interface LocationStateType {
  clientId?: string,
  communityId?: string,
  projectId?: string
}

export function ReportsPage() {
  const { t } = useTranslation();
  const title = t('nav.reports');
  const [isCreateCustomReport, setIsCreateCustomReport] = useState<boolean>();
  const hasReportsAccess = useUserContextSelector((x) => x.hasReportsAccess);

  const location = useLocation();
  const state = location.state as LocationStateType;

  if (!hasReportsAccess) return <AccessDeniedBanner />;

  return (
    <Page htmlTitle={title}>
      <Page.Paths>
        <Page.Path>{title}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card icon={<LayoutOutlined />} color="#259AEA" title={title}>
          <ReportActions
            onCreateCustomReport={() => setIsCreateCustomReport(true)}
          />
        </Page.Card>

        <Page.Card>
          { (state?.clientId || state?.communityId || state?.projectId || isCreateCustomReport) && (
            <GenerateCustomReportPanel
              onCancel={() => setIsCreateCustomReport(false)}
              clientId={state?.clientId}
              communityId={state?.communityId}
              projectId={state?.projectId}
            />
          )}
        </Page.Card>
      </Page.Body>
    </Page>
  );
}
