import { isEmpty } from 'lodash';
import { FC, useCallback, useState } from 'react';
import { LookupOption } from '../lookups';
import { AsyncSelectBase, AsyncSelectBaseProps } from './AsyncSelectBase';

export const SELECT_OTHER_VALUE_PREFIX = 'new://';

export type CreatableAsyncSelectProps = Omit<
  AsyncSelectBaseProps,
  'searchValue' | 'onSearchChange' | 'value' | 'onChange'
> & {
  value: string;
  onChange: (value: string | undefined) => any;
  onBlur?: () => any;
};

function useOnChange(props: CreatableAsyncSelectProps) {
  const { onChange } = props;

  return useCallback(
    (value: string | undefined, option: LookupOption) => {
      if (!value) {
        onChange(undefined);
        return;
      }
      if (value.startsWith(SELECT_OTHER_VALUE_PREFIX)) {
        option.label = value.substring(SELECT_OTHER_VALUE_PREFIX.length);
      }
      onChange(value);
    },
    [onChange],
  );
}

export const CreatableAsyncSelect: FC<CreatableAsyncSelectProps> = (props) => {
  const {
    value,
    dataSource: { onNewValue },
  } = props;
  const [searchValue, onSearchChange] = useState<string>('');
  const [oldSearchValue, onOldSearchChange] = useState<string>('');

  const optionsFn = useCallback<(options: LookupOption[]) => LookupOption[]>(
    (options) => {
      if (isEmpty(searchValue) && isEmpty(oldSearchValue)) {
        return options;
      }
      const newOptions = [...options];

      let newOption =
        onNewValue && (searchValue || oldSearchValue)
          ? onNewValue(searchValue || oldSearchValue)
          : undefined;
      if (newOption) {
        newOption.value = SELECT_OTHER_VALUE_PREFIX + newOption?.value;

        if (isEmpty(searchValue) && !isEmpty(oldSearchValue)) {
          newOption.label = oldSearchValue;
        }
        newOptions.push(newOption);
      }

      onOldSearchChange(searchValue);
      return newOptions;
    },
    [onNewValue, oldSearchValue, searchValue],
  );

  const onChange = useOnChange(props);

  return (
    <AsyncSelectBase
      {...props}
      searchValue={searchValue}
      onSearchChange={onSearchChange}
      options={optionsFn}
      value={value}
      onChange={onChange}
    />
  );
};
