import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { Col, Menu, Row, Space } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import classNames from 'classnames';
import { openid } from '@/core';
import { useUserContextSelector } from '@/redux';
import { confirmation, Icon } from '@/components';
import { NotificationBell, SearchByNumberField } from '@/views';
import { ReactComponent as Logo } from '@/assets/images/logo.svg';
import css from './Layout.module.scss';
import { useSelectedNavMenuKeys } from './useSelectedNavMenuKeys';

function useNavMenu() {
  const { t } = useTranslation();
  const hasRole = useUserContextSelector((x) => x.hasRole);
  const hasReportsAccess = useUserContextSelector((x) => x.hasReportsAccess);

  const items: ItemType[] = [
    {
      key: '/communities',
      label: <NavLink to="/communities">{t('nav.communities')}</NavLink>,
    },
    hasReportsAccess && {
      key: '/reports',
      label: <NavLink to="/reports">{t('nav.reports')}</NavLink>,
    },
    {
      key: '/activity',
      label: <NavLink to="/activity">{t('activity.title')}</NavLink>,
    },
    hasRole('BFP_ADMIN') && {
      key: '/clients',
      label: <NavLink to="/clients">{t('nav.clients')}</NavLink>,
    },
    {
      key: '/projects',
      label: <NavLink to="/projects">{t('nav.projects')}</NavLink>,
    },
    hasRole('BFP_ADMIN') && {
      key: '/admin',
      label: <NavLink to="/admin">{t('nav.admin')}</NavLink>,
    },
    {
      key: '/users',
      label: <NavLink to="/users">{t('users.title')}</NavLink>,
    },
  ].filter((x) => !!x) as ItemType[];

  const selectedKeys = useSelectedNavMenuKeys(items);

  return (
    <Menu
      className={classNames('nav', css.nav)}
      selectedKeys={selectedKeys}
      mode="horizontal"
      items={items}
    />
  );
}

function useActionButtons() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [name] = useUserContextSelector((x) => [x.name, x.avatar]);

  const showSignOutConfirmationDialog = () => {
    confirmation.show({
      title: t('logoutConfirmation.title'),
      body: t('logoutConfirmation.body'),
      onConfirm: () => openid.logout(),
    });
  };

  const items: ItemType[] = [
    {
      key: '/notifications',
      label: <NotificationBell />,
    },
    {
      key: '/user-actions',
      icon: <Icon type="account" />,
      className: 'user-actions',
      children: [
        {
          key: '/me',
          label: <NavLink to="/me">{name}</NavLink>,
        },
        {
          key: '/change-password',
          onClick: () => navigate('/change-password'),
          label: t('nav.changePassword'),
        },
        {
          key: '/logout',
          onClick: showSignOutConfirmationDialog,
          label: t('nav.logout'),
        },
      ].filter((x) => !!x) as ItemType[],
    },
  ].filter((x) => !!x) as ItemType[];

  const selectedKeys = useSelectedNavMenuKeys(items);

  return (
    <Menu
      className="nav"
      selectedKeys={selectedKeys}
      mode="horizontal"
      disabledOverflow
      items={items}
    />
  );
}

export function NavPanel() {
  const navMenu = useNavMenu();
  const actionButtons = useActionButtons();

  return (
    <Row justify="space-between" gutter={[0, 0]} wrap={false}>
      <Col xl={8} lg={10} md={16} sm={12} xs={12}>
        <Space size="large">
          <NavLink to="/" className={classNames(css.brand, 'ms-2')}>
            <Logo />
          </NavLink>
          <SearchByNumberField className={css.searchByNumber} />
        </Space>
      </Col>
      <Col xl={16} lg={14} md={8} sm={12} xs={12}>
        <Row wrap={false} justify="end" gutter={[0, 0]}>
          <Col xl={18} lg={17} md={8} sm={4} xs={4}>
            {navMenu}
          </Col>
          <Col>{actionButtons}</Col>
        </Row>
      </Col>
    </Row>
  );
}
