import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CodedException,
  CommunityPermissions,
  PricingNotCompletedPayload,
} from '@/core';
import { useCommunityContext } from '@/views';
import {
  useGetFloorplanDetailsByHomeQuery,
  useGetProjectHomeInfoQuery,
  useUpdateFinalizationStateMutation,
} from '@/redux';
import { useCallback, useMemo, useState } from 'react';
import { confirmation, notify } from '@/components';
import { RtkqNotifyError } from '@/redux/rtkq';
import { PricingNotCompletedModal } from './PricingNotCompletedModal';
import { PersonalizationNoItemsSelectedInRoomModal } from './PersonalizationNoItemsSelectedInRoomModal';
import { PersonalizationNoUnfinalizePermissionModal } from './PersonalizationNoUnfinalizePermissionModal';

interface Props {
  projectId: string;
  homeId: string;
  selectedStepId: string;
}

const useUpdateFinalizationState = (
  props: Props,
  onPricingNotCompleted: (payload: PricingNotCompletedPayload) => any,
  onPersonalizationNoSelectedItemsModal: (value: boolean) => any,
  onPersonalizationNoUnfinalizePermissionModal: (value: boolean) => any,
) => {
  const [update] = useUpdateFinalizationStateMutation();
  const { homeId, projectId, selectedStepId } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'personalization.summary',
  });

  return useCallback(
    (currentValue: boolean) => {
      const handleError = (error: any) => {
        const e = CodedException.from(error);
        if (e.code === 'INV.ROOM_PERSONALIZATION.NO_ITEMS_SELECTED') {
          onPersonalizationNoSelectedItemsModal(true);
          return;
        }

        if (e.code === 'INV.PRICING_NOT_COMPLETED') {
          onPricingNotCompleted(e.payload);
          return;
        }

        if (e.code === 'INV.ROOM_PERSONALIZATION.NO_UNFINALIZE_PERMISSION') {
          onPersonalizationNoUnfinalizePermissionModal(true);
          return;
        }

        throw error;
      };

      const perform = () =>
        update({
          homeId,
          projectId,
          value: !currentValue,
          stepId: selectedStepId,
          [RtkqNotifyError]: false,
        })
          .unwrap()
          .then(() => notify.success.t('personalization.summary.updated'))
          .catch(handleError);

      confirmation.show({
        title: currentValue ? t('unFinalize.title') : t('finalize.title'),
        body: currentValue
          ? t('unFinalize.confirmation')
          : t('finalize.confirmation'),
        onConfirm: () => perform(),
      });
    },
    [
      t,
      onPersonalizationNoSelectedItemsModal,
      onPricingNotCompleted,
      onPersonalizationNoUnfinalizePermissionModal,
      update,
      homeId,
      projectId,
      selectedStepId,
    ],
  );
};

export const FinalizeButton = (props: Props) => {
  const { t } = useTranslation();
  const { projectId, homeId, selectedStepId } = props;
  const { data: context } = useGetProjectHomeInfoQuery({
    id: projectId,
    homeId,
  });
  const { data: floorplan } = useGetFloorplanDetailsByHomeQuery({
    homeId,
  });
  const [pricingNotCompletedPayload, setPricingNotCompletedPayload] =
    useState<PricingNotCompletedPayload>();
  const [
    personalizationNoSelectedItemsModal,
    setPersonalizationNoSelectedItemsModal,
  ] = useState<boolean>();
  const [
    personalizationNoUnfinalizePermissionModal,
    setPersonalizationNoUnfinalizePermissionModal,
  ] = useState<boolean>();
  const handleFinalization = useUpdateFinalizationState(
    props,
    setPricingNotCompletedPayload,
    setPersonalizationNoSelectedItemsModal,
    setPersonalizationNoUnfinalizePermissionModal,
  );
  const { assert } = useCommunityContext();
  const step = context?.steps.find((x) => x.stepId === selectedStepId);

  const title = useMemo(
    () =>
      step?.isFinalized
        ? t('personalization.summary.unFinalize.title')
        : t('personalization.summary.finalize.title'),
    [t, step],
  );

  const enable =
    step?.isFinalized ||
    assert(CommunityPermissions.Personalization.Manage, true);

  if (!floorplan || !context || !step || !enable) return null;

  return (
    <>
      <Button
        onClick={() => handleFinalization(step!.isFinalized)}
        type={step.isFinalized ? 'primary' : undefined}
      >
        {title}
      </Button>
      {personalizationNoSelectedItemsModal && (
        <PersonalizationNoItemsSelectedInRoomModal
          onClose={() => setPersonalizationNoSelectedItemsModal(false)}
        />
      )}
      {pricingNotCompletedPayload && (
        <PricingNotCompletedModal
          errorPayload={pricingNotCompletedPayload}
          onClose={() => setPricingNotCompletedPayload(undefined)}
        />
      )}
      {personalizationNoUnfinalizePermissionModal && (
        <PersonalizationNoUnfinalizePermissionModal
          onClose={() => setPersonalizationNoUnfinalizePermissionModal(false)}
        />
      )}
    </>
  );
};
