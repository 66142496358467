import { enumObject, EnumValue } from '@/utils';
import { ClippoAction } from '@webinex/clippo';

const ITEM_PRICEPER_VALUES = ['Item', 'Foot'] as const;

export type ItemPricePer = EnumValue<typeof ITEM_PRICEPER_VALUES>;

// eslint-disable-next-line
export const ItemPricePer = enumObject('ItemPricePer', ITEM_PRICEPER_VALUES);

export interface ItemDto {
  number: string;
  id: string;
  categoryId: string;
  communityId: string;
  name: string;
  description: string;
  constructionDescription: string;
  pricePer: ItemPricePer;
  bookletLinks: ItemBookletLinkDto[];
  externalLinks: ExternalLinkDto[];
  isGeo: boolean;
  listed: boolean;
  imageActions?: ClippoAction[];
}

export type ItemDetailsDto = ItemDto;
export type UpdateItemDto = Omit<
  ItemDto,
  'number' | 'bookletLinks' | 'communityId' | 'listed' | 'externalLinks'
> & {
  subOptionsIds?: string[];
};
export type CreateItemDto = Omit<UpdateItemDto, 'id'> & {
  communityId: string;
};

export interface BookletLinkDto {
  id: string;
  attachmentId: string;
  page: number;
}

export interface ExternalLinkDto {
  id: string;
  source: string;
}

export type AddBookletLinkDto = Omit<BookletLinkDto, 'id'>;

export type AddExternalLinkDto = Omit<ExternalLinkDto, 'id'>;

export interface ItemBookletLinkDto extends BookletLinkDto {
  itemId: string;
}

export interface ItemExternalLinkDto extends ExternalLinkDto {
  itemId: string;
}

export type AddItemBookletLinkDto = Omit<ItemBookletLinkDto, 'id'>;
export type DeleteItemBookletLinkDto = Omit<
  ItemBookletLinkDto,
  'page' | 'attachmentId'
>;

export type AddItemExternalLinkDto = Omit<ItemExternalLinkDto, 'id'>;
export type DeleteItemExternalLinkDto = Omit<ItemExternalLinkDto, 'source'>;

export interface UploadItemsDto {
  excelRef: string;
  attachmentsRef: string;
  imagesRef: string;
}
