import { confirmation, notify } from '@/components';
import { useReassignResidentMutation } from '@/redux';
import { useTranslation } from 'react-i18next';

export const useShowReassignConfirmation = () => {
  const { t } = useTranslation();
  const [reassign] = useReassignResidentMutation();

  return async (id: string, homeId: string) => {
    confirmation.show({
      title: t('residents.reassign.title'),
      body: t('residents.reassign.body'),
      confirm: t('residents.reassign.confirmButton'),
      onConfirm: async () =>
        await reassign({ id, homeId })
          .unwrap()
          .then(() => notify.success.t('residents.reassigned')),
    });
  };
};
