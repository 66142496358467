import { notify } from '@/components';
import { AddResidentArgs, useAddResidentMutation } from '@/redux';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ResidentDetailsForm, useShowReassignConfirmation } from '@/views';
import { CodedException } from '@/core';
import { RtkqNotifyError } from '@/redux/rtkq';

interface Props {
  className?: string;
  communityId: string;
  homeId: string;
  onCancel: () => any;
}

function useInitialValue({ communityId, homeId }: Props) {
  return useMemo<AddResidentArgs>(
    () => ({
      communityId: communityId,
      homeId: homeId,
      name: null!,
      email: null!,
      phone: null!,
      notes: null!,
    }),
    [communityId, homeId],
  );
}

const useSubmit = (props: Props) => {
  const { onCancel } = props;
  const [add] = useAddResidentMutation();
  const showReassignConfirmation = useShowReassignConfirmation();

  return useCallback(
    (args: AddResidentArgs) => {
      const handleError = (error: any) => {
        const e = CodedException.from(error);
        if (e.code === 'INV.RESIDENT.EXISTS') {
          const { residentId } = e.payload as { residentId: string };
          showReassignConfirmation(residentId, args.homeId);
          return;
        }

        throw error;
      };

      add({ ...args, [RtkqNotifyError]: false })
        .unwrap()
        .then(() => notify.success.t('residents.saved'))
        .then(onCancel)
        .catch(handleError);
    },
    [add, showReassignConfirmation, onCancel],
  );
};

export function AddResidentPanel(props: Props) {
  const { onCancel } = props;
  const submit = useSubmit(props);
  const { t } = useTranslation();
  const initialValue = useInitialValue(props);

  return (
    <ResidentDetailsForm
      onCancel={onCancel}
      onSubmit={submit}
      saveButtonName={t('residents.details.addSaveButton')}
      value={initialValue}
    />
  );
}
