import {
  AddItemMenuDto,
  ItemMenuDetailsDto,
  ItemMenuDto,
  UpdateItemMenuDto,
  itemMenuHttp,
  ItemMenuItemDetailsDto,
  ItemMenuLookupDto,
} from '@/core';
import { rtkq, RtkqRequest } from '../rtkq';
import { api } from '../api';

export type ItemMenuState = ItemMenuDto;
export type AddItemMenuArgs = AddItemMenuDto;
export type UpdateItemMenuArgs = UpdateItemMenuDto;
export type ItemMenuDetailsState = ItemMenuDetailsDto;
export type ItemMenuItemDetailsState = ItemMenuItemDetailsDto;
export type ItemMenuLookupState = ItemMenuLookupDto;

export const itemMenusApi = api.injectEndpoints({
  endpoints: (build) => ({
    getItemMenus: build.query<
      ItemMenuState[],
      RtkqRequest<{ communityId: string; listed?: boolean | null }>
    >({
      queryFn: async (args) => {
        const { communityId, listed } = args;
        return rtkq(args).exec(() => itemMenuHttp.getAll(communityId, listed));
      },
      providesTags: (result = []) => [
        { type: 'itemMenu', id: 'list' },
        ...result.map(({ id }) => ({ type: 'itemMenu' as const, id })),
      ],
    }),

    addItemMenu: build.mutation<string, RtkqRequest<AddItemMenuArgs>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => itemMenuHttp.add(args));
      },
      invalidatesTags: () => [
        { type: 'itemMenu', id: 'list' },
        { type: 'personalizationItem-category', id: 'list' },
        { type: 'personalizationItem', id: 'list' },
      ],
    }),

    getItemMenuDetails: build.query<
      ItemMenuDetailsState,
      RtkqRequest<{ id: string; listed?: boolean | null }>
    >({
      queryFn: async (args) => {
        const { id, listed } = args;
        return rtkq(args).exec(() => itemMenuHttp.getDetails(id, listed));
      },
      providesTags: (menu) => [
        { type: 'itemMenu', id: menu?.id ?? 'never' },
        { type: 'item', id: 'list' },
      ],
    }),

    updateItemMenu: build.mutation<void, RtkqRequest<UpdateItemMenuArgs>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => itemMenuHttp.update(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'itemMenu', id: args.id },
        { type: 'personalization-item-price' },
        { type: 'personalizationItem-category', id: 'list' },
        { type: 'personalizationItem', id: 'list' },
      ],
    }),

    deleteItemMenu: build.mutation<void, RtkqRequest<{ id: string }>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => itemMenuHttp.remove(args.id));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'itemMenu', id: args.id },
        { type: 'personalization-item-price' },
        { type: 'personalizationItem-category', id: 'list' },
        { type: 'personalizationItem', id: 'list' },
      ],
    }),
  }),
});

export const {
  useGetItemMenusQuery,
  useLazyGetItemMenusQuery,
  useAddItemMenuMutation,
  useUpdateItemMenuMutation,
  useGetItemMenuDetailsQuery,
  useDeleteItemMenuMutation,
} = itemMenusApi;
