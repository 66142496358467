import { formatters } from '@/core';

interface Props {
  value: Date | string;
  format?: 'date' | 'date-time';
  className?: string;
}

export const DateTimeValue = (props: Props) => {
  const { value, className, format = 'date-time' } = props;
  const result = formatters.dateTime(value, format);
  return <span className={className}>{result}</span>;
};
