import { Form, Title } from '@/components';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Button, Space } from 'antd';
import { RoomTypeDto } from '@/core/http.roomTypes';
import styles from './RoomType.module.scss';

interface Props {
  className?: string;
  value?: RoomTypeDto;
  onCancel: () => void;
  onSubmit: (value: RoomTypeDto) => void;
}

const SCHEMA = Yup.object().shape({
  name: Yup.string().field().nullable().required(),
});

const INITIAL_VALUES: RoomTypeDto = {
  id: undefined!,
  name: undefined!,
  status: undefined!,
};

export function FormRoomType(props: Props) {
  const { className, value, onSubmit, onCancel } = props;
  const { t } = useTranslation();

  const isNew = !value || !value.id;

  return (
    <div className={className}>
      <Title>{isNew ? t('roomTypes.add') : t('roomTypes.edit')}</Title>
      <Form.Formik
        uid={value?.id || ''}
        initialValues={value || INITIAL_VALUES}
        onSubmit={onSubmit}
        validationSchema={SCHEMA}
        i18n="roomTypes"
      >
        <Space direction="vertical">
          <Form.Input name="name" required className={styles.nameInput} />
          <Space direction="horizontal">
            <Button onClick={onCancel}>{t('cancel')}</Button>
            <Form.Submit type="primary">{t('save')}</Form.Submit>
          </Space>
        </Space>
      </Form.Formik>
    </div>
  );
}
