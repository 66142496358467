import { Space } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useGetPathTemplatesListQuery,
  PathTemplateState,
  useUserContextSelector,
  useGetPathTemplateDetailsQuery,
} from '@/redux';
import { NavLink } from 'react-router-dom';
import { useFiltersFactory } from '@/utils';
import { AddPathTemplatePanel, PathTemplateStepList } from '@/views';
import { useNavigate } from 'react-router-dom';
import {
  AddButton,
  Table,
  TabPanel,
  TableColumnType,
  DeleteIconButton,
  EditIconButton,
} from '@/components';
import { useUnlistPathTemplate } from '@/views';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import styles from './PathTemplatesList.module.scss';
import { Paging } from '@/core';
import { useDefaultTablePaging } from '@/components/Collections.Paging';

interface Props {
  organizationId: string;
  communityId?: string;
  readonly?: boolean;
  title?: string;
  editRoute: (id: string) => string;
  detailsRoute: (id: string) => string;
}

function useColumns(props: Props, paging: Paging) {
  const { organizationId, communityId, readonly, editRoute, detailsRoute } =
    props;
  const { t } = useTranslation();
  const push = useNavigate();
  const unlist = useUnlistPathTemplate(false);

  const { data } = useGetPathTemplatesListQuery({
    organizationId,
    communityId,
    paging: paging,
  });

  const pathTemplates = useMemo(() => {
    return data?.items;
  }, [data?.items]);

  const filters = useFiltersFactory(pathTemplates);
  const [isBFP, hasRole, userOrgId] = useUserContextSelector((x) => [
    x.isBFP,
    x.hasRole,
    x.organizationId,
  ]);

  const canModify =
    isBFP || (organizationId === userOrgId && hasRole('CLIENT_ADMIN'));

  return useMemo<TableColumnType<PathTemplateState>[]>(() => {
    const columns: Array<TableColumnType<PathTemplateState> | false> = [
      {
        title: t('pathTemplates.name'),
        key: 'name',
        ...filters.plainText((x) => x.name),
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (_, { id, name }) => (
          <NavLink to={detailsRoute(id)}>{name}</NavLink>
        ),
      },
      {
        title: t('pathTemplates.steps'),
        key: 'steps',
        render: (_, { steps }) => (
          <Space split=" — ">
            {steps.map(({ name }, index) => (
              <span key={index}>{name}</span>
            ))}
          </Space>
        ),
      },
      canModify &&
        !readonly && {
          title: t('pathTemplates.actions'),
          key: 'actions',
          render: (_, { id }) => (
            <Space size="large">
              <EditIconButton
                type="link"
                onClick={() => push(editRoute(id))}
                className="table-action"
              />
              <DeleteIconButton
                onClick={() => unlist(id)}
                className="table-action"
              />
            </Space>
          ),
        },
    ];

    return columns.filter((x) => !!x) as TableColumnType<PathTemplateState>[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, filters, push]);
}

const DefaultExpandable = (
  includeCommunityLevel: boolean,
): ExpandableConfig<PathTemplateState> => {
  return {
    expandedRowRender: (record: PathTemplateState) => (
      <Expanded id={record.id} includeCommunityLevel={includeCommunityLevel} />
    ),
    expandIcon: ({ expanded, onExpand, record }) =>
      record.steps.length > 0 ? (
        expanded ? (
          <DownOutlined
            onClick={(e: any) => onExpand(record, e)}
            className={styles.icon}
          />
        ) : (
          <RightOutlined
            onClick={(e: any) => onExpand(record, e)}
            className={styles.icon}
          />
        )
      ) : (
        <></>
      ),
  };
};

function Expanded({
  id,
  includeCommunityLevel,
}: {
  id: string;
  includeCommunityLevel: boolean;
}) {
  const { data } = useGetPathTemplateDetailsQuery({
    id,
    includeCommunityLevel,
  });

  if (!data) {
    return null;
  }

  return <PathTemplateStepList value={data} className="ps-5" />;
}

export function PathTemplatesListTabPanel(props: Props) {
  const { organizationId, communityId, readonly, title } = props;

  const { setTotal, paging, params } = useDefaultTablePaging();

  const columns = useColumns(props, params);

  const { data, isFetching } = useGetPathTemplatesListQuery({
    organizationId,
    communityId,
    paging: params,
  });

  const pathTemplates = useMemo(() => {
    if (data) setTotal(data.total);
    return data?.items;
  }, [data, setTotal]);

  const { t } = useTranslation();
  const [add, setAdd] = useState(false);

  return (
    <TabPanel>
      <TabPanel.Title>{title ?? t('pathTemplates.title')}</TabPanel.Title>
      <TabPanel.Actions>
        {!readonly && (
          <AddButton onClick={() => setAdd(true)}>
            {t('pathTemplates.details.addDefaultButton')}
          </AddButton>
        )}
      </TabPanel.Actions>
      <TabPanel.Body compact={add}>
        {!add && (
          <Table<PathTemplateState>
            name="PathTemplates"
            expandable={DefaultExpandable(communityId != null)}
            loading={isFetching}
            columns={columns}
            dataSource={pathTemplates}
            rowKey={(row) => row.id}
            showHeader={false}
            pagination={paging}
          />
        )}
        {add && (
          <AddPathTemplatePanel
            communityId={communityId}
            organizationId={organizationId}
            onCreated={() => setAdd(false)}
            onCancel={() => setAdd(false)}
          />
        )}
      </TabPanel.Body>
    </TabPanel>
  );
}
