import { useTranslation } from 'react-i18next';
import { Form, SecondaryCancelButton } from '@/components';
import { FormsRadio } from '@/components/Forms/FormsRadio';
import {
  AssignFloorplanToHomeArgs,
  useLazyGetFloorplanDetailsQuery,
} from '@/redux';
import { useFloorplanLookupSource } from '@/views';
import { FloorplanDetailsFormikForm } from '../Floorplans.Details/FloorplanDetailsFormikForm';
import {
  useValue,
  assignFloorplanSchema,
  OPTIONS,
} from './AssignFloorplanToHomeInModal';
import { useCallback, useEffect } from 'react';
import { FloorplanDetailsDto, isChooseExistingOption } from '@/core';
import {
  FloorplanCopyMenuForm,
  useFloorplanCopyMenu,
} from '../Floorplans.Details/FloorplanCopyMenuForm';
import { useFormikContext } from 'formik';

interface Props {
  communityId: string;
  homeId?: string;
  onCancel: () => void;
  onSubmit: (values: AssignFloorplanToHomeArgs) => void;
  floorplan?: FloorplanDetailsDto;
}

const useSubmit = (props: Props) => {
  const { onSubmit } = props;
  const [getFloorplanDetails] = useLazyGetFloorplanDetailsQuery();

  const submit = useCallback(
    (values: AssignFloorplanToHomeArgs) => {
      if (values.floorplanId) {
        return getFloorplanDetails({ id: values.floorplanId }).then((x) =>
          onSubmit({ ...values, marketingName: x.data?.marketingName || '' }),
        );
      } else {
        onSubmit(values);
      }
    },
    [getFloorplanDetails, onSubmit],
  );

  return { submit };
};

export const AssignFloorplanToHomeInForm = (props: Props) => {
  const { t } = useTranslation();
  const { communityId, homeId, onCancel, onSubmit, floorplan } = props;
  const floorplansDataSource = useFloorplanLookupSource(communityId);
  const floorplanCopyProps = useFloorplanCopyMenu('floorplan-details');
  const {
    floorplan: copyFromFloorplan,
    resetCopyFields,
    setFloorplanId,
    setNeedToCopy,
  } = floorplanCopyProps;
  const initialValues = useValue(
    communityId,
    homeId!,
    floorplan,
    copyFromFloorplan,
  );

  const { submit } = useSubmit(props);

  return (
    <Form.Formik<AssignFloorplanToHomeArgs>
      uid="home-floorplan"
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={assignFloorplanSchema}
    >
      {({ values }) => (
        <>
          <CreationOptionRadio
            onChange={() => {
              setNeedToCopy(false);
              setFloorplanId(undefined);
              resetCopyFields();
            }}
          />
          {isChooseExistingOption(values.creationOption) && (
            <>
              <Form.AsyncSelect
                name="floorplanId"
                dataSource={floorplansDataSource}
                required
                preload
                label={t('floorplans.header')}
              />
              <SecondaryCancelButton onClick={onCancel} className="me-2">
                {t('floorplans.details.cancel')}
              </SecondaryCancelButton>
              <Form.Submit
                uid="home-floorplan"
                key="submit"
                type="primary"
                secondary
              >
                {t('save')}
              </Form.Submit>
            </>
          )}
          {values.creationOption === 'copy' && (
            <FloorplanCopyMenuForm
              communityId={values.communityId}
              {...floorplanCopyProps}
            />
          )}
          {!isChooseExistingOption(values.creationOption) && (
            <>
              {
                <FloorplanDetailsFormikForm
                  onSubmit={(values) =>
                    onSubmit({
                      ...values,
                      creationOption: 'new',
                      exampleFloorplanId: copyFromFloorplan?.id,
                    })
                  }
                  initialValues={{ ...initialValues }}
                />
              }
              <SecondaryCancelButton onClick={onCancel} className="me-2">
                {t('floorplans.details.cancel')}
              </SecondaryCancelButton>
              <Form.Submit
                uid="floorplan-details"
                key="submit"
                type="primary"
                secondary
              >
                {t('save')}
              </Form.Submit>
            </>
          )}
        </>
      )}
    </Form.Formik>
  );
};
function CreationOptionRadio({
  onChange,
}: {
  onChange: (option: string) => void;
}) {
  const { t } = useTranslation();
  const formik = useFormikContext<AssignFloorplanToHomeArgs>();
  useEffect(() => {
    onChange(formik.values.creationOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.creationOption]);
  return (
    <FormsRadio
      name="creationOption"
      options={OPTIONS}
      label={t('floorplans.chooseExistingOrSetManually')}
      inline={false}
    />
  );
}
