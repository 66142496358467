import { Form, LookupSource } from '@/components';
import { Typography } from 'antd';

interface AdditionalOptionDropDownBoxSectionProps {
  title?: React.ReactNode;
  className?: string;
  dataSource: LookupSource;
  fieldName: string;
  placeholder: string;
}

export function AdditionalOptionDropDownBoxSection(
  props: AdditionalOptionDropDownBoxSectionProps,
) {
  const { title, fieldName, className, placeholder, dataSource } = props;

  return (
    <div>
      <Typography.Title level={5} className={className}>
        {title}
      </Typography.Title>

      <Form.AsyncSelect
        label={false}
        dataSource={dataSource}
        name={fieldName}
        placeholder={placeholder}
        mode="multiple"
      />
    </div>
  );
}
