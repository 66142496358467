import {
  LookupSource,
  LookupOption,
  createEntireLoadLookupSource,
} from '@/components';
import { AttachmentDto } from '@/core';
import { useMemo } from 'react';

function map(value: AttachmentDto): LookupOption {
  const { id, fileName } = value;

  return {
    _search: fileName,
    value: id,
    label: fileName,
  };
}

export function useBookletLinkLookupSource(
  attachments: AttachmentDto[],
): LookupSource {
  return useMemo(() => {
    const entireSource = createEntireLoadLookupSource({
      initial: (id: string) =>
        new Promise((resolve, _) =>
          resolve(map(attachments.find((x) => x.id === id)!)),
        ),
      load: () => new Promise((resolve, _) => resolve(attachments.map(map))),
    });

    return { ...entireSource, searchField: '_search' };
  }, [attachments]);
}
