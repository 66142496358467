import {
  LookupSource,
  LookupOption,
  createEntireLoadLookupSource,
  EMPTY_LOOKUP_SOURCE,
} from '@/components';
import { DEFAULT_PAGING } from '@/core';
import { RtkqSpin, useLazyGetCommunitiesListQuery } from '@/redux';
import { useMemo } from 'react';

export function mapCommunityLookup(value: {
  name: string;
  id: string;
}): LookupOption {
  return {
    label: value.name,
    value: value.id,
  };
}

export function useCommunityLookupSource(
  clientId: string[] | string | false,
): LookupSource {
  const [fetchList] = useLazyGetCommunitiesListQuery();

  return useMemo(() => {
    if (!clientId && clientId !== false) {
      return EMPTY_LOOKUP_SOURCE;
    }

    const clientIdValue = typeof clientId === 'boolean' ? undefined : clientId;

    return createEntireLoadLookupSource({
      initial: (id: string) =>
        fetchList(
          {
            clientId: clientIdValue,
            paging: DEFAULT_PAGING,
            [RtkqSpin]: false,
          },
          true,
        )
          .unwrap()
          .then((values) =>
            mapCommunityLookup(values.items.find((x) => x.id === id)!),
          ),
      load: () =>
        fetchList(
          {
            clientId: clientIdValue,
            paging: DEFAULT_PAGING,
            [RtkqSpin]: false,
          },
          true,
        )
          .unwrap()
          .then((values) => values.items.map(mapCommunityLookup)),
    });
  }, [clientId, fetchList]);
}
