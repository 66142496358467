import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LinkOutlined } from '@ant-design/icons';
import { AddBookletLinkDto, AttachmentDto } from '@/core';
import { ItemBookletLinkModal } from './BookletLinkModal';
import { Button } from 'antd';

interface AddBookletLinkButtonProps {
  className?: string;
  attachments: AttachmentDto[];
  onCreate: (args: AddBookletLinkDto) => Promise<void>;
}

const useValue = (props: AddBookletLinkButtonProps): AddBookletLinkDto => {
  return {
    attachmentId: null!,
    page: null!,
  };
};

const useSubmit = (
  props: AddBookletLinkButtonProps,
  setOpenModal: (value: boolean) => void,
) => {
  const { onCreate } = props;

  return (args: AddBookletLinkDto) =>
    onCreate(args).then((id) => {
      setOpenModal(false);
    });
};

export function AddBookletLinkButton(props: AddBookletLinkButtonProps) {
  const [openModal, setOpenModal] = useState(false);
  const onSubmit = useSubmit(props, setOpenModal);
  const { t } = useTranslation();
  const { className, attachments } = props;

  const initialValue: AddBookletLinkDto = useValue(props);

  return (
    <div className={className}>
      <Button
        type="link"
        icon={<LinkOutlined />}
        onClick={() => setOpenModal(true)}
      >
        {t('bookletLinks.addTitle')}
      </Button>
      {openModal && (
        <ItemBookletLinkModal
          title={t('bookletLinks.addTitle')}
          onSubmit={onSubmit}
          value={initialValue}
          attachments={attachments}
          onClose={() => setOpenModal(false)}
        />
      )}
    </div>
  );
}
