import { RoomTypeDto, RoomTypeStatus } from '@/core/http.roomTypes';
import {
  useChangeStatusRoomTypeMutation,
  useCreateRoomTypeMutation,
  useGetRoomTypeListQuery,
  useUpdateRoomTypeMutation,
} from '@/redux';
import { useEffect, useState } from 'react';
import { FormRoomType } from './FormRoomType';
import { useRoomTypesColumns } from './useRoomTypesColumns';
import { Table } from 'antd';
import { notify } from '@/components';

interface Props {
  isAdd: boolean;
  onCancel: () => any;
}

const statusChangeNotificationKeys = {
  [RoomTypeStatus.Active]: 'roomTypes.activated',
  [RoomTypeStatus.Deactivated]: 'roomTypes.deactivated',
};

export function RoomTypesListPanel(props: Props) {
  const { isAdd, onCancel } = props;

  const { data: roomTypes, isFetching } = useGetRoomTypeListQuery({});

  const [create] = useCreateRoomTypeMutation();
  const [changeStatus] = useChangeStatusRoomTypeMutation();
  const [update] = useUpdateRoomTypeMutation();

  const [editRoomType, setEditRoomType] = useState<RoomTypeDto>();

  useEffect(() => {
    if (isAdd) {
      setEditRoomType(undefined);
    }
  }, [isAdd]);

  const handleAdd = (roomType: RoomTypeDto) =>
    create(roomType)
      .unwrap()
      .then(onCancel)
      .then(() => notify.success.t('roomTypes.added'));

  const handleChangeStatus = (id: string, status: RoomTypeStatus) =>
    changeStatus({ id, status })
      .unwrap()
      .then(() => notify.success.t(statusChangeNotificationKeys[status]));

  const handleUpdate = (roomType: RoomTypeDto) =>
    update(roomType)
      .unwrap()
      .then(() => notify.success.t('roomTypes.saved'))
      .then(() => setEditRoomType(undefined));

  const columns = useRoomTypesColumns(
    roomTypes,
    setEditRoomType,
    handleChangeStatus,
  );

  return (
    <>
      {!editRoomType && !isAdd && (
        <Table<RoomTypeDto>
          loading={isFetching}
          columns={columns}
          dataSource={roomTypes}
          rowKey={(row) => row.id}
          showHeader={false}
        />
      )}
      {editRoomType && (
        <FormRoomType
          onSubmit={handleUpdate}
          onCancel={() => setEditRoomType(undefined)}
          value={editRoomType}
        />
      )}
      {isAdd && <FormRoomType onCancel={onCancel} onSubmit={handleAdd} />}
    </>
  );
}
