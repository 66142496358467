import { TaskTemplateMatrixRowState } from '@/redux';
import { useTranslation } from 'react-i18next';
import { Form, TableColumnType } from '@/components';
import { useMemo } from 'react';
import { CostEstimateInput, HomeInput, NameInput } from '@/views';
import { TaskStatus } from '@/core';
import { ResponsiblePersonInput } from '@/views/Tasks.Details/ImportTasks/Fields/ResponsiblePersonInput';
import { Typography } from 'antd';

const ColumnTitle = (props: { label: string; required?: boolean }) => {
  const { label, required } = props;
  const { Text } = Typography;
  return (
    <Text>
      {label} {required && <Text type="warning">*</Text>}
    </Text>
  );
};

export function useImportTaskTemplateColumns(
  communityId: string,
  projectId: string,
) {
  const { t } = useTranslation();

  return useMemo<TableColumnType<TaskTemplateMatrixRowState>[]>(() => {
    const columns: Array<TableColumnType<TaskTemplateMatrixRowState> | false> =
      [
        {
          title: <ColumnTitle label={t('tasks.name')} required />,
          key: 'name',
          exportValue: (x) => x.name,
          render: (_, __, index) => {
            return <NameInput name={`rows.${index}`} />;
          },
        },
        {
          title: <ColumnTitle label={t('tasks.home')} required />,
          key: 'home',
          render: (_, __, index) => {
            return (
              <HomeInput
                communityId={communityId}
                projectId={projectId}
                name={`rows.${index}`}
              />
            );
          },
        },
        {
          title: t('tasks.startDate'),
          key: 'startDate',
          render: (_, __, index) => (
            <Form.Date name={`rows.${index}.startDate`} label={false} />
          ),
        },
        {
          title: t('tasks.endDate'),
          key: 'endDate',
          render: (_, __, index) => (
            <Form.Date name={`rows.${index}.endDate`} label={false} />
          ),
        },
        {
          title: <ColumnTitle label={t('tasks.responsiblePerson')} required />,
          key: 'responsiblePerson',
          render: (_, item, index) => {
            return (
              <ResponsiblePersonInput
                initialValue={item.responsiblePerson}
                communityId={communityId}
                name={`rows.${index}`}
              />
            );
          },
        },
        {
          title: t('tasks.costEstimated'),
          key: 'costEstimated',
          render: (_, __, index) => {
            return <CostEstimateInput name={`rows.${index}`} />;
          },
        },
        {
          title: <ColumnTitle label={t('tasks.status')} required />,
          className: 'required',
          key: 'status',
          render: (_, __, index) => (
            <Form.EnumSelect
              name={`rows.${index}.status`}
              type={TaskStatus}
              label={false}
            />
          ),
        },
        {
          title: 'checked',
          key: 'checked',
          render: (_, __, index) => (
            <Form.Checkbox name={`rows.${index}.checked`} />
          ),
        },
      ];
    return columns.filter(
      (x) => !!x && x.key !== 'checked',
    ) as TableColumnType<TaskTemplateMatrixRowState>[];
  }, [t, communityId, projectId]);
}
