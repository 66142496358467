import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import {
  DefaultNumberSorting,
  DeleteIconButton,
  EditIconButton,
  NavLink,
  TableColumnType,
  useDefaultTablePaging,
  useFilters,
  useSorting,
} from '@/components';
import { confirmation, notify, Table, TabPanel } from '@/components';
import {
  AddSubcontractorButton,
  Assert,
  useEditSubcontractorAction,
} from '@/views';
import { useFiltersFactory, PhoneView } from '@/utils';
import {
  SubcontractorState,
  useGetSubcontractorsListQuery,
  useUnlistSubcontractorMutation,
} from '@/redux';
import { Space } from 'antd';
import {
  CommunityPermissions,
  Paging,
  SubcontractorFilterParams,
  StringFilterOptions,
} from '@/core';

interface Props {
  communityId: string;
}

function useColumns(
  communityId: string,
  paging: Paging,
  openEdit: (id: string) => any,
  stringFilteOptions?: StringFilterOptions,
) {
  const { t } = useTranslation();

  const { data } = useGetSubcontractorsListQuery({
    communityId,
    paging: paging,
  });
  const [unlist] = useUnlistSubcontractorMutation();

  const subcontractors = useMemo(() => {
    return data?.items;
  }, [data?.items]);

  const filters = useFiltersFactory(subcontractors, stringFilteOptions);

  const handleUnlist = useCallback(
    (id: string) => {
      const perform = () =>
        unlist({ id })
          .unwrap()
          .then(() => notify.success.t('subcontractors.deleted'));
      confirmation.delete({ key: 'subcontractors.entityName' }, perform);
    },
    [unlist],
  );

  return useMemo<TableColumnType<SubcontractorState>[]>(() => {
    const columns: Array<TableColumnType<SubcontractorState> | false> = [
      {
        title: t('subcontractors.number'),
        key: 'number',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.number.localeCompare(b.number),
        render: (_, item) => item.number,
      },
      {
        title: t('subcontractors.name'),
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        ...filters.select((x) => x.name, 'name'),
        render: (_, { id, name, communityId }) => (
          <NavLink to={`/communities/${communityId}/subcontractors/${id}`}>
            {name}
          </NavLink>
        ),
      },
      {
        title: t('subcontractors.email'),
        key: 'email',
        ...filters.select((x) => x.email, 'email'),
        render: (_, item) => item.email,
      },
      {
        title: t('subcontractors.phone'),
        key: 'phone',
        ...filters.plainText((x) => x.phone),
        render: (_, { phone }) => <PhoneView value={phone} />,
      },
      {
        title: t('subcontractors.workType'),
        key: 'workType',
        sorter: (a, b) => a.workType.localeCompare(b.workType),
        ...filters.select((x) => x.workType, 'workType'),
        render: (_, item) => item.workType,
      },
      {
        title: t('subcontractors.actions'),
        key: 'actions',
        render: (_, { id }) => (
          <Assert
            permission={CommunityPermissions.Subcontractors.Manage}
            active
          >
            <Space size="large">
              <EditIconButton
                type="link"
                onClick={() => openEdit(id)}
                className="table-action"
              />
              <DeleteIconButton
                onClick={() => handleUnlist(id)}
                className="table-action"
              />
            </Space>
          </Assert>
        ),
      },
    ];
    return columns.filter((x) => !!x) as TableColumnType<SubcontractorState>[];
  }, [filters, handleUnlist, openEdit, t]);
}

export function SubcontractorsListPanel(props: Props) {
  const { communityId } = props;
  const { t } = useTranslation();

  const { setTotal, paging, params } = useDefaultTablePaging();
  const {
    filters,
    onFiltersChange,
    resetFilters,
    stringFilterOptions,
    setStringFilterOptions,
  } = useFilters<SubcontractorFilterParams>(['name', 'email', 'workType']);
  const { sorting, onSortingChange } =
    useSorting<SubcontractorState>(DefaultNumberSorting);

  const { openEdit, editModal } = useEditSubcontractorAction();

  const { data, isFetching } = useGetSubcontractorsListQuery({
    communityId,
    paging: params,
    filters,
    sorting,
  });

  const subcontractors = useMemo(() => {
    if (data) {
      setTotal(data.total);
      setStringFilterOptions(data.stringFilterOptions);
    }
    return data?.items;
  }, [data, setStringFilterOptions, setTotal]);

  const columns = useColumns(
    communityId,
    params,
    openEdit,
    stringFilterOptions,
  );

  return (
    <TabPanel>
      <TabPanel.Title>{t('subcontractors.title')}</TabPanel.Title>
      <TabPanel.Actions>
        <Assert permission={CommunityPermissions.Subcontractors.Manage} active>
          <AddSubcontractorButton communityId={communityId} />
        </Assert>
      </TabPanel.Actions>
      <TabPanel.Body>
        <Table<SubcontractorState>
          name="Subcontractors"
          loading={isFetching}
          columns={columns}
          dataSource={subcontractors}
          rowKey={(row) => row.id}
          pagination={paging}
          onChange={(_, filters, sorting) => {
            onFiltersChange(filters);
            onSortingChange(sorting);
          }}
          onFiltersReset={resetFilters}
        />
        {editModal}
      </TabPanel.Body>
    </TabPanel>
  );
}
