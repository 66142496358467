import { useTranslation } from 'react-i18next';
import { Auth, Page } from '@/components';
import { useParams } from 'react-router-dom';
import { useGetClientDetailsQuery } from '@/redux';
import { ClientDetailsPanel } from '@/views';
import { ShareAltOutlined } from '@ant-design/icons';
import { ClientDetailsTabsPanel } from './ClientDetailsTabsPanel';

const useId = () => {
  const params = useParams<'id'>();
  return params.id!;
};

function _ClientDetailsPage() {
  const { t } = useTranslation();
  const id = useId();
  const { data } = useGetClientDetailsQuery({ id });
  const title = data?.name ?? t('fetchFallbackTitle');

  return (
    <Page htmlTitle={title}>
      <Page.Paths>
        <Page.Path href="/clients">{t('clients.title')}</Page.Path>
        <Page.Path>{title}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card
          collapsible
          defaultCollapsed
          title={title}
          icon={<ShareAltOutlined />}
          color="#f57c00"
        >
          <ClientDetailsPanel id={id} />
        </Page.Card>
        <Page.TabCard>
          <ClientDetailsTabsPanel clientId={id} />
        </Page.TabCard>
      </Page.Body>
    </Page>
  );
}

export const ClientDetailsPage = Auth.Banner('BFP_ADMIN')(_ClientDetailsPage);
