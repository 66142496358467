import { notify } from '@/components';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './HomeProjectLink.module.scss';
import { ItemMenuLookupState, useAssignMenuToHomesMutation } from '@/redux';
import {
  AssignMenuDetailsModalValue,
  AssignMenuModal,
} from '../Projects.Homes.Details';
import { useBool } from '@/utils';
import { useCallback } from 'react';
import { HomeMenuDetails } from './HomeMenuDetails';
import { HomeHasNoProjectModal } from './HomeHasNoProjectModal';

interface Props {
  communityId?: string;
  menu?: ItemMenuLookupState;
  homeId: string;
  projectId?: string;
}

const useSubmit = (toggleModalOpen: () => any, props: Props) => {
  const { projectId, homeId } = props;
  const [assign] = useAssignMenuToHomesMutation();

  return useCallback(
    async (args: AssignMenuDetailsModalValue) => {
      return assign({ ...args, homeIds: [homeId], projectId: projectId! })
        .unwrap()
        .then(() => notify.success.t('projects.homeList.assignMenu.assigned'))
        .then(() => toggleModalOpen());
    },
    [assign, projectId, homeId, toggleModalOpen],
  );
};

export const HomeMenuLink = (props: Props) => {
  const { homeId, projectId, menu, communityId } = props;
  const [modalOpen, , toggleModalOpen] = useBool(false);
  const { t } = useTranslation();
  const submit = useSubmit(toggleModalOpen, props);
  const value = {
    menuId: null!,
  };

  if (menu)
    return (
      <HomeMenuDetails
        communityId={communityId!}
        menu={menu}
        homeId={homeId}
        projectId={projectId!}
      />
    );

  return (
    <div>
      <span className={styles.label}> {t('homes.details.menu')}</span>
      <Button
        type="link"
        className={styles.addToProject}
        onClick={toggleModalOpen}
      >
        {t('homes.details.add')}
      </Button>
      <>
        {modalOpen && projectId && (
          <AssignMenuModal
            onCancel={toggleModalOpen}
            title={t('projects.homeList.assignMenu.name')}
            onSubmit={submit}
            value={value}
            communityId={communityId!}
          />
        )}
        {modalOpen && !projectId && (
          <HomeHasNoProjectModal onClose={toggleModalOpen} />
        )}
      </>
    </div>
  );
};
