import { useTranslation } from 'react-i18next';
import {
  CommunityDetailsTabKeys,
  PathTemplateDetailsPanel,
  useCommunityContext,
} from '@/views';
import { Icon, Page } from '@/components';
import { useParams } from 'react-router-dom';
import { useGetPathTemplateDetailsQuery } from '@/redux';

const useId = () => {
  const { id } = useParams<'id'>();
  return id!;
};

export function CustomPathTemplateDetailsPage() {
  const { t } = useTranslation();
  const id = useId();
  const { active } = useCommunityContext();
  const { data } = useGetPathTemplateDetailsQuery({
    id,
    includeCommunityLevel: true,
  });

  const title = data?.name ?? t('fetchFallbackTitle');

  return (
    <Page htmlTitle={title}>
      <Page.Paths>
        <Page.Path href="/communities">{t('communities.title')}</Page.Path>
        <Page.Path
          href={`/communities/${data?.communityId}#${CommunityDetailsTabKeys['path-templates']}`}
        >
          {data?.communityName ?? t('fetchFallbackTitle')}
        </Page.Path>
        <Page.Path
          href={`/communities/${data?.communityId}#${CommunityDetailsTabKeys['path-templates']}`}
        >
          {t('pathTemplates.title')}
        </Page.Path>
        <Page.Path>{title}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card
          title={title}
          icon={<Icon type="template" />}
          color="#EFAC4E"
        >
          <PathTemplateDetailsPanel
            includeCommunityLevel
            id={id}
            readonly={!active}
            editRoute={(id) =>
              `/communities/${
                data!.communityId
              }/custom-path-templates/${id}/edit`
            }
          />
        </Page.Card>
      </Page.Body>
    </Page>
  );
}
