import { useUserContextSelector } from '@/redux';
import { useTranslation } from 'react-i18next';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import css from './Auth.module.scss';
import { Role } from '@/core';

export interface AuthProps {
  role: Role;
  children: (() => React.ReactElement) | React.ReactNode;
}

function _Auth({ role, children }: AuthProps): JSX.Element | null {
  const has = useUserContextSelector((x) => x.hasRole(role));
  if (!has) {
    return null;
  }

  const content = typeof children === 'function' ? children() : children;
  return <>{content}</>;
}

export function AccessDeniedBanner() {
  const { t } = useTranslation();

  return (
    <div className={classNames(css.container, 'mt-2 p-4')}>
      <EyeInvisibleOutlined style={{ fontSize: 40 }} />
      <div className="font-weight-bold">{t('auth.denied')}</div>
    </div>
  );
}

function createWrap(fallbackElement: React.ReactElement | null) {
  return (role: Role | Role[]) => {
    return <T extends React.ComponentType<any>>(Component: T): T => {
      const Wrapped: React.FC = (props: any) => {
        const hasRole = useUserContextSelector((x) => x.hasRole);

        const has = Array.isArray(role)
          ? role.some((r) => hasRole(r))
          : hasRole(role);

        return has ? <Component {...props} /> : fallbackElement;
      };
      return Wrapped as T;
    };
  };
}

export const Auth = Object.assign(_Auth, {
  Banner: createWrap(<AccessDeniedBanner />),
  when: {
    ...createWrap(null),
  },
});
