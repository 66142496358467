import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './RoomPersonalization.module.scss';
import { useMemo } from 'react';
import { RoomPersonalizationKeepExistingLabel } from '@/views/Personalization.Details/RoomPersonalization/RoomPersonalizationKeepExistingLabel';

interface Props {
  count: number;
  showNoSelectionsWarning: boolean;
  showKeepExisting?: boolean;
}

const { Text } = Typography;

function useItemsCount(props: Props) {
  const { count, showNoSelectionsWarning } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'personalization.item.details',
  });

  const text = useMemo(
    () =>
      showNoSelectionsWarning ? t('noSelections') : t('subheading', { count }),
    [showNoSelectionsWarning, t, count],
  );
  return { text };
}

export function RoomPersonalizationItemsAddedCount(props: Props) {
  const { showKeepExisting } = props;
  const { text } = useItemsCount(props);
  return showKeepExisting ? (
    <RoomPersonalizationKeepExistingLabel />
  ) : (
    <Text className={styles.label} type="secondary">
      {text}
    </Text>
  );
}
