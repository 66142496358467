import { SortResult } from '@/components/Collections.Paging/useSorting';
import {
  Http,
  SubscriptionDto,
  AddSubscriptionDto,
  UpdateSubscriptionDto,
  Paged,
  Paging,
  SubscriptionFilterParams,
} from '@/core';
import { guard } from '@/utils';
import qs from 'qs';

class SubscriptionHttp extends Http {
  public async getAll(
    paging: Paging,
    filters?: SubscriptionFilterParams,
    sotring?: SortResult,
  ) {
    const query = qs.stringify({ ...paging, ...filters, ...sotring });
    const response = await this._axios.get<Paged<SubscriptionDto>>(
      `/api/subscription?${query}`,
    );
    return response.data;
  }

  public async get(communityId: string) {
    guard.notNull(communityId, 'communityId');
    const url = `/api/subscription/${communityId}`;
    const response = await this._axios.get<SubscriptionDto>(url);
    return response.data;
  }

  public async add(request: AddSubscriptionDto) {
    guard.notNull(request, 'request');
    const response = await this._axios.post<string>(
      `/api/subscription`,
      request,
    );
    return response.data;
  }

  public async update(request: UpdateSubscriptionDto) {
    guard.notNull(request, 'request');
    await this._axios.put(`/api/subscription`, request);
  }
}

export const subscriptionHttp = new SubscriptionHttp();
