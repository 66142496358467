import { AddPaymentDto, Http, PaymentsDto } from '@/core';
import { guard } from '@/utils';
import qs from 'qs';

class PaymentsHttp extends Http {
  public async getAll(args: {
    homeId: string;
    projectId: string;
    stepId?: string;
  }) {
    const query = qs.stringify({ ...args });
    const response = await this._axios.get<PaymentsDto[]>(
      `/api/home/payment?${query}`,
    );
    return response.data;
  }

  public async add(request: AddPaymentDto) {
    guard.notNull(request, 'request');
    const response = await this._axios.post<string>(
      `/api/home/payment`,
      request,
    );
    return response.data;
  }

  public async delete(args: { id: string }) {
    guard.notNull(args, 'args');
    const { id } = args;
    const response = await this._axios.delete<string>(
      `/api/home/payment/${id}`,
    );
    return response.data;
  }
}

export const paymentsHttp = new PaymentsHttp();
