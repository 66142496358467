import { Modal, Button } from 'antd';
import { Form } from '@/components';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { AddExternalLinkDto } from '@/core';

const schema = Yup.object().shape({
  source: Yup.string().field().nullable().required(),
});

interface Props {
  onClose: () => any;
  onSubmit: (props: AddExternalLinkDto) => any;
  value: AddExternalLinkDto;
}

export function ItemExternalLinkModal(props: Props) {
  const { onClose: onCancel, value, onSubmit } = props;
  const { t } = useTranslation();

  return (
    <Modal
      title={t('externalLinks.title')}
      visible
      onCancel={onCancel}
      footer={[
        <Button key="back" type="default" onClick={onCancel}>
          {t('externalLinks.cancelButton')}
        </Button>,
        <Form.Submit uid="externalLinks" key="submit" type="primary">
          {t('externalLinks.saveButton')}
        </Form.Submit>,
      ]}
    >
      <Form.Formik<AddExternalLinkDto>
        uid="externalLinks"
        i18n="externalLinks"
        initialValues={value}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        <Form.Input name="source" className="w-100" required />
      </Form.Formik>
    </Modal>
  );
}
