import { useGetTaskDetailsQuery } from '@/redux';
import { Navigate, useParams } from 'react-router-dom';

export function TaskNavigatePage() {
  const { id } = useParams<'id'>();
  const { data } = useGetTaskDetailsQuery({ id: id! });

  if (!data) {
    return null;
  }

  return (
    <Navigate
      to={`/projects/${data!.projectId}/task-management/${data!.id}`}
      replace
    />
  );
}
