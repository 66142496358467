import { PathTemplateStep } from '@/core';
import { useGetPathTemplateDetailsQuery } from '@/redux';
import { Steps } from 'antd';
import { useField } from 'formik';
import { FormPathTemplateStep } from './FormPathTemplateStep';

const { Step } = Steps;

interface Props {
  name: string;
  communityId?: string;
  freezeExisting?: boolean;
}

export function FromPathTemplateStepList(props: Props) {
  const { name, communityId, freezeExisting } = props;
  const [{ value: templateId }] = useField<string>('sourceTemplateId');
  const [{ value: steps }] = useField<PathTemplateStep[]>(name);
  const { data } = useGetPathTemplateDetailsQuery(
    { id: templateId!, includeCommunityLevel: !!communityId },
    { skip: !templateId },
  );
  return (
    <Steps direction="vertical" size="small" current={0}>
      {steps.map((_, index) => (
        <Step
          key={index}
          status="process"
          title={
            <FormPathTemplateStep
              name={`steps[${index}]`}
              communityId={communityId}
              freezeExisting={freezeExisting}
              step={data?.steps.find((s) => s.index === index)}
            />
          }
        />
      ))}
    </Steps>
  );
}
