import { LookupSource, createEntireLoadLookupSource } from '@/components';
import { useLazyGetReportingHomesListQuery } from '@/redux';
import { RtkqSpin } from '@/redux/rtkq';
import { useMemo } from 'react';
import { mapHomeLookup } from './useHomeLookupSource';

export function useReportingHomesLookupSource(
  communityId?: string | string[],
  projectId?: string | string[],
  withMenu?: boolean,
): LookupSource {
  const [fetchList] = useLazyGetReportingHomesListQuery();

  return useMemo(() => {
    const entireSource = createEntireLoadLookupSource({
      initial: (id: string) =>
        fetchList({ communityId, projectId, withMenu, [RtkqSpin]: false }, true)
          .unwrap()
          .then((values) => mapHomeLookup(values.find((x) => x.id === id)!)),
      load: () =>
        fetchList({ communityId, projectId, withMenu, [RtkqSpin]: false }, true)
          .unwrap()
          .then((values) => values.map(mapHomeLookup)),
    });

    return { ...entireSource, searchField: '_search' };
  }, [fetchList, communityId, projectId, withMenu]);
}
