import { SpecialRequestDto, SpecialRequestStatus } from '@/core';
import { Tag } from 'antd';

const statusColorMap: Record<SpecialRequestStatus, string> = {
  Drawing: 'geekblue',
  Price: 'cyan',
  ManagementApproved: 'lime',
  Clarify: 'orange',
  ConstructionApproved: 'green',
  Denied: 'volcano',
  Pending: 'purple',
};

export function SpecialRequestStatusTag({
  item,
}: {
  item: SpecialRequestDto | undefined;
}) {
  return item ? (
    <Tag className="me-0" color={statusColorMap[item.status]}>
      {item?.status}
    </Tag>
  ) : (
    <></>
  );
}
