import { Tabs } from '@/components';
import { enumObject } from '@/utils';
import { useTranslation } from 'react-i18next';
import { ProjectState } from '@/redux';
import { ProjectPathTabPanel, useCommunityContext } from '@/views';
import { CommunityPermissions } from '@/core';
import { ProjectOverrideDeadlinesTabPanel } from './ProjectOverrideDeadlinesTabPanel';

const { TabPane } = Tabs;

const TAB_KEYS = ['path', 'deadlines'] as const;

export const ProjectDetailsTabKeys = enumObject(
  'ProjectDetailsTabKeys',
  TAB_KEYS,
);

interface Props {
  className?: string;
  data: ProjectState;
}

export const ProjectAdministrationTabsPanel = (props: Props) => {
  const { className, data } = props;
  const { t } = useTranslation();

  var { assert } = useCommunityContext();

  const showPaths = assert(CommunityPermissions.Path.View);
  const isAnyTabAvailable = showPaths;

  if (!isAnyTabAvailable) return null;

  return (
    <Tabs
      className={className}
      tabKeys={ProjectDetailsTabKeys}
      defaultActiveKey={ProjectDetailsTabKeys.path}
      activeKey={ProjectDetailsTabKeys.path}
      destroyInactiveTabPane
    >
      {showPaths && (
        <>
          <TabPane
            tab={t('projects.path.title')}
            key={ProjectDetailsTabKeys.path}
          >
            <ProjectPathTabPanel data={data} />
          </TabPane>
          <TabPane
            tab={t('projects.deadlines')}
            key={ProjectDetailsTabKeys.deadlines}
          >
            <ProjectOverrideDeadlinesTabPanel
              pathName={data.pathName}
              projectId={data.id}
              communityId={data.communityId}
            />
          </TabPane>
        </>
      )}
    </Tabs>
  );
};
