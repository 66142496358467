import { FieldRow, Form } from '@/components';
import { Button, Col, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import * as yup from 'yup';
import { ActivityKindSelect } from './ActivityKindSelect';
import { FilterRule } from '@webinex/wispo';
import { useCallback } from 'react';
import { isEmpty } from 'lodash';
import { ActivityPerformedAtFilter } from './ActivityPerformedAtFilter';
import { ActivityHomeSelect } from './ActivityHomeSelect';
import { ActivityCommunitySelect } from './ActivityCommunitySelect';
import { ActivityUserSelect } from './ActivityUserSelect';
import { ActivityProjectSelect } from './ActivityProjectSelect';
import { DEFAULT_FILTERS } from '../ActivityTablePanel';
import moment from 'moment';
import { DATE_TIME_UTC_OFFSET_HOURS } from '@/platform';

const schema = yup.object().shape({
  userIds: yup.array().of(yup.string().nullable()),
  kinds: yup.array().of(yup.string().nullable()),
  communityIds: yup.array().of(yup.string().nullable()),
  homeIds: yup.array().of(yup.string().nullable()),
  projectIds: yup.array().of(yup.string().nullable()),
  performedAt: yup
    .object()
    .shape({
      startDate: yup
        .date()
        .max(yup.ref('endDate'), {
          key: 'activities_start_date_must_be_before_end_date',
        })
        .nullable(),
      endDate: yup
        .date()
        .min(yup.ref('startDate'), {
          key: 'activities_end_date_must_be_after_start_date',
        })
        .nullable(),
    })
    .nullable(),
  filters: yup.array(
    yup
      .object()
      .shape({
        path: yup.string().nullable().required(),
        value: yup.string().nullable(),
      })
      .required(),
  ),
});

type FormType = OmitIndex<yup.InferType<typeof schema>>;

export interface ActivityTableSearchPanelProps {
  onSubmit: (request: FilterRule) => any;
  className?: string;
}

const INITIAL_VALUES: FormType = {
  userIds: [],
  kinds: [],
  communityIds: [],
  homeIds: [],
  projectIds: [],
  performedAt: null!,
  filters: [],
};

function mapValueFilterRule(value: FormType['filters']): FilterRule[] {
  return value!.map(({ path, value }) => ({
    fieldId: `$value.${path}`,
    operator: '=',
    value,
  }));
}

function mapFilterRule(value: FormType): FilterRule {
  const {
    userIds,
    kinds,
    communityIds,
    homeIds,
    projectIds,
    performedAt,
    filters: valueFilters,
  } = value;

  const filters: FilterRule[] = [...mapValueFilterRule(valueFilters)];

  filters.push(DEFAULT_FILTERS);

  !isEmpty(userIds) &&
    filters.push({ fieldId: 'userId', operator: 'in', values: userIds! });
  !isEmpty(kinds) &&
    filters.push({ fieldId: 'kind', operator: 'in', values: kinds! });
  !isEmpty(communityIds) &&
    filters.push({
      fieldId: `$value.@communityId`,
      operator: 'in',
      values: communityIds!,
    });
  !isEmpty(homeIds) &&
    filters.push({
      fieldId: `$value.@homeId`,
      operator: 'in',
      values: homeIds!,
    });
  !isEmpty(projectIds) &&
    filters.push({
      fieldId: `$value.@projectId`,
      operator: 'in',
      values: projectIds!,
    });
  !isEmpty(performedAt) &&
    filters.push(
      {
        fieldId: 'performedAt',
        operator: '>=',
        value: moment(performedAt.startDate)
          .add(-DATE_TIME_UTC_OFFSET_HOURS, 'hours')
          .toISOString(true),
      },
      {
        fieldId: 'performedAt',
        operator: '<=',
        value: moment(performedAt.endDate)
          .add(1, 'day')
          .add(-DATE_TIME_UTC_OFFSET_HOURS, 'hours')
          .toISOString(true),
      },
    );

  return filters.length === 1
    ? filters[0]
    : { operator: 'and', children: filters };
}

function useSubmit(props: ActivityTableSearchPanelProps) {
  const { onSubmit } = props;

  return useCallback(
    (value: FormType) => {
      const filterRule = mapFilterRule(value);
      onSubmit(filterRule);
    },
    [onSubmit],
  );
}

export function ActivityTableSearchPanel(props: ActivityTableSearchPanelProps) {
  const { className } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'activity.table.filter',
  });
  const handleSubmit = useSubmit(props);

  return (
    <Form.Formik<FormType>
      uid="activity-table"
      onSubmit={handleSubmit}
      initialValues={INITIAL_VALUES}
      i18n="activity.table"
      validationSchema={schema}
    >
      {({ setValues }) => (
        <>
          <FieldRow>
            <Col span={8}>
              <ActivityKindSelect name="kinds" />
            </Col>
            <Col span={16}>
              <ActivityPerformedAtFilter name="performedAt" />
            </Col>
          </FieldRow>
          <FieldRow>
            <Col span={8}>
              <ActivityUserSelect name="userIds" />
            </Col>
            <Col span={8}>
              <ActivityCommunitySelect name="communityIds" />
            </Col>
            <Col span={8}>
              <ActivityHomeSelect name="homeIds" />
            </Col>
          </FieldRow>
          <FieldRow>
            <Col span={8}>
              <ActivityProjectSelect name="projectIds" />
            </Col>
          </FieldRow>
          <FieldRow className={className}>
            <Col span={24} className="text-end">
              <Space size="large">
                <Button
                  icon={<CloseOutlined />}
                  onClick={() => setValues(INITIAL_VALUES)}
                >
                  {t('clearAll')}
                </Button>
                <Form.Submit icon={<SearchOutlined />} type="primary">
                  {t('search')}
                </Form.Submit>
              </Space>
            </Col>
          </FieldRow>
        </>
      )}
    </Form.Formik>
  );
}
