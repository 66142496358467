import { ClientType } from '@/core';
import { Modal, Button, Col } from 'antd';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { FieldRow, Form, INITIAL_CONTACT } from '@/components';
import { AddClientArgs } from '@/redux';

const schema = Yup.object().shape({
  name: Yup.string().field().nullable().required(),
  type: Yup.string().field().nullable().required(),
  address: Yup.object().address(),
  contacts: Yup.array().of(Yup.object().contact().nullable()).nullable(),
});

interface Props {
  onClose: () => any;
  onSubmit: (props?: any) => any;
  value: AddClientArgs;
  title: string;
  buttonSaveName: string;
}

export function ClientDetailsModal(props: Props) {
  const { onClose: onCancel, value, onSubmit, title, buttonSaveName } = props;
  const { t } = useTranslation();

  return (
    <>
      <Modal
        title={title}
        visible
        onCancel={onCancel}
        footer={[
          <Button key="back" type="default" onClick={onCancel}>
            {t('clients.details.cancel')}
          </Button>,
          <Form.Submit uid="client-details" key="submit" type="primary">
            {buttonSaveName}
          </Form.Submit>,
        ]}
      >
        <Form.Formik<AddClientArgs>
          uid="client-details"
          i18n="clients.details"
          initialValues={value}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({ values: { contacts }, setFieldValue }) => (
            <>
              <FieldRow>
                <Col span={12}>
                  <Form.Input
                    name="name"
                    placeholder={t('clients.details.placeholder.name')}
                    required
                  />
                </Col>
                <Col span={12}>
                  <Form.EnumSelect type={ClientType} name="type" required />
                </Col>
              </FieldRow>

              <h4>{t('clients.details.address.header')}</h4>
              <Form.Address name="address" required />

              <h4>{t('clients.details.contacts.header')}</h4>
              {contacts.map((contact, index) => (
                <Form.Contacts
                  deletable={contacts.length > 1}
                  key={index}
                  name={`contacts[${index}]`}
                  onDelete={() => {
                    const result = contacts.filter((x) => x !== contact);
                    setFieldValue('contacts', result);
                  }}
                />
              ))}
              <Button
                onClick={() =>
                  setFieldValue('contacts', [...contacts, INITIAL_CONTACT])
                }
                icon={<PlusOutlined />}
                type="link"
              >
                {t('clients.details.addContact')}
              </Button>
            </>
          )}
        </Form.Formik>
      </Modal>
    </>
  );
}
