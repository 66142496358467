import {
  confirmation,
  DeleteIconButton,
  EditIconButton,
  EnumValue,
  notify,
  TableColumnType,
} from '@/components';
import {
  CommunityUserState,
  useRemoveCommunityAssignmentMutation,
} from '@/redux';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFiltersFactory, PhoneView } from '@/utils';
import {
  CommunityRole,
  CommunityPermissions,
  StringFilterOptions,
} from '@/core';
import { Space } from 'antd';
import { Assert } from '../Communities.Common';
import { UserStatusBadge } from '../Users.List';

export const useRemoveCommunityAssignment = () => {
  const { t } = useTranslation();
  const [removeCommunityAssignment] = useRemoveCommunityAssignmentMutation();

  return async (userId: string, communityId: string) => {
    confirmation.show({
      title: t('communityUsers.removeAccessAction.title'),
      body: t('communityUsers.removeAccessAction.body'),
      confirm: t('communityUsers.removeAccessAction.confirmButton'),
      onConfirm: async () =>
        await removeCommunityAssignment({ userId, communityId })
          .unwrap()
          .then(() => notify.success.t('communityUsers.removedAccess')),
    });
  };
};

export function useCommunityAssignmentsColumns(
  communityUsers: CommunityUserState[] | undefined,
  openEdit: (item: CommunityUserState) => any,
  stringFilterOptions?: StringFilterOptions,
) {
  const { t } = useTranslation();
  const filters = useFiltersFactory(communityUsers, stringFilterOptions);

  const removeCommunityAccess = useRemoveCommunityAssignment();

  return useMemo<TableColumnType<CommunityUserState>[]>(() => {
    const columns: Array<TableColumnType<CommunityUserState> | false> = [
      {
        title: t('communityUsers.number'),
        key: 'number',
        sorter: (a, b) => a.number.localeCompare(b.number),
        defaultSortOrder: 'descend',
        ...filters.select((x) => x.number, 'number'),
        render: (_, item) => item.number,
      },
      {
        title: t('communityUsers.name'),
        sorter: (a, b) =>
          (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName),
        ...filters.select((x) => x.firstName + ' ' + x.lastName, 'name'),
        render: (_, { firstName, lastName }) => `${firstName} ${lastName}`,
        key: 'name',
      },
      {
        title: t('communityUsers.communityRole'),
        ...filters.enumSelect((x) => x.communityRoles, CommunityRole),
        sorter: (a, b) => {
          const aSorted: string | undefined = a.communityRoles
            ?.slice()
            .sort()[0];
          const bSorted: string | undefined = b.communityRoles
            ?.slice()
            .sort()[0];

          return !aSorted ? -1 : !bSorted ? 1 : aSorted.localeCompare(bSorted);
        },
        key: 'communityRole',
        dataIndex: 'communityRole',
        render: (_, item) => (
          <Space split={'|'}>
            {item.communityRoles.map((x, index) => (
              <EnumValue type={CommunityRole} value={x} key={index} />
            ))}
          </Space>
        ),
      },
      {
        title: t('communityUsers.email'),
        ...filters.select((x) => x.email, 'email'),
        sorter: (a, b) => a.email.localeCompare(b.email),
        key: 'email',
        dataIndex: 'email',
      },
      {
        title: t('communityUsers.phone'),
        ...filters.select((x) => x.phone, 'phone'),
        sorter: (a, b) => a.phone.localeCompare(b.phone),
        key: 'phone',
        dataIndex: 'phone',
        render: (_, { phone }) => <PhoneView value={phone} />,
      },
      {
        title: t('communityUsers.status'),
        key: 'isActive',
        width: 100,
        render: (_, user) => <UserStatusBadge value={user} />,
      },
      {
        title: t('communityUsers.actions'),
        key: 'actions',
        render: (_, item) => (
          <Assert permission={CommunityPermissions.Users.Manage} active>
            <Space size="large">
              <EditIconButton
                type="link"
                onClick={() => openEdit(item)}
                className="table-action"
              />
              <DeleteIconButton
                onClick={() => removeCommunityAccess(item.id, item.communityId)}
                className="table-action"
              />
            </Space>
          </Assert>
        ),
      },
    ];

    return columns.filter((x) => !!x) as TableColumnType<CommunityUserState>[];
  }, [filters, t, openEdit, removeCommunityAccess]);
}
