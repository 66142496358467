import { EditRoomArgs, RoomState } from '@/core';
import { useEffect, useState } from 'react';
import { FormRoom } from './FormRoom';
import { RoomDetails } from './RoomDetails';

interface Props {
  value: RoomState;
  onDelete: (value: RoomState) => any;
  onSubmit?: (value: any) => any;
  onCancel: () => any;
  communityId: string;
  isWholeHome: boolean;
  readonly?: boolean;
}

export function RoomItem(props: Props) {
  const {
    value,
    onDelete,
    onSubmit,
    onCancel,
    isWholeHome,
    communityId,
    readonly,
  } = props;
  const [isEdit, setEdit] = useState(!value.id);

  useEffect(() => {
    if (!value.id) {
      setEdit(true);
    }
  }, [value.id]);

  const handleSubmit = (value: EditRoomArgs) => {
    onSubmit!({ ...props.value, ...value });
    setEdit(false);
  };

  const handleCancel = () => {
    setEdit(false);
    onCancel();
  };

  return (
    <>
      {isEdit ? (
        <FormRoom<EditRoomArgs>
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          noTemplate={false}
          value={value}
          roomTypeName={value.roomTypeName}
          communityId={communityId}
          isWholeHome={isWholeHome}
        />
      ) : (
        <RoomDetails
          value={value}
          onDelete={onDelete}
          onEdit={() => setEdit(true)}
          communityId={communityId}
          isWholeHome={isWholeHome}
          readonly={readonly}
        />
      )}
    </>
  );
}
