import { AttachmentDto } from '@/core';
import { ClipContentDto } from '@webinex/clippo';
import { ImageBoxContextProvider } from './Context';
import { ImageBoxGallery } from './Gallery';
import { ImagesPreview, useImagesPreview } from './Preview';
import styles from './ImageBox.module.scss';
import classNames from 'classnames';
import { ImageBoxItem } from './ImageBoxItem';
import { ImageUpload } from './ImageUpload';
import { memo } from 'react';
import { ImageSkeleton } from './ImageSkeleton';
import { ImageCropUpload } from '@/components';

export interface ImageBoxProps {
  items: AttachmentDto[];
  readonly?: boolean;
  className?: string;
  galleryVisible: boolean;
  hideEmpty?: boolean;
  compact?: boolean;
  inCard?: boolean;
  rootRef?: (node?: Element | null) => void;
  notInitialized?: boolean;
  acceptPdf?: boolean;
  crop?: boolean;
  aspect?: number;

  fetch: () => Promise<any>;
  content: (id: string) => Promise<ClipContentDto>;
  onStore: (files: File[]) => Promise<any>;
  onGalleryVisibleChange: (visible: boolean) => any;
  toggleGallery: () => any;
  onRemove: (item: AttachmentDto) => any;
  galleryOnly?: boolean;
}

const MAX_PREVIEW = 2;

function _ImageBox(props: ImageBoxProps) {
  const {
    items,
    content,
    galleryVisible,
    toggleGallery,
    onStore,
    className,
    onRemove,
    readonly,
    hideEmpty,
    compact,
    rootRef,
    notInitialized,
    acceptPdf,
    galleryOnly,
    inCard,
    crop,
    aspect
  } = props;

  const preview = useImagesPreview(items);

  const hasMore = items.length - MAX_PREVIEW > 0;
  const more = hasMore ? items.length - MAX_PREVIEW : false;
  const showUpload = !readonly && items.length < MAX_PREVIEW;
  const visibleItems = items.slice(0, MAX_PREVIEW);
  const isLast = (index: number) => index === MAX_PREVIEW - 1;
  const show = !hideEmpty || !!notInitialized || items.length > 0;
  const showSkeleton = items.length === 0;

  return (
    <ImageBoxContextProvider items={items} content={content}>
      <span ref={rootRef} />
      {show && (
        <>
          {!galleryOnly && (
            <div
              className={classNames(
                styles.imageBox,
                className,
                compact && '--compact',
                inCard && '--in-card'
              )}
            >
              {visibleItems.map((item, index) => (
                <ImageBoxItem
                  key={item.id}
                  item={item}
                  onOpenPreview={preview.open}
                  more={isLast(index) ? more : false}
                  onMoreClick={toggleGallery}
                />
              ))}

              {showSkeleton && (
                <div className={styles.imageBoxItem}>
                  <ImageSkeleton />
                </div>
              )}

              {showUpload && (
                <div className={styles.imageBoxItem}>
                  {crop
                    ? <ImageCropUpload onUpload={onStore} onRemove={onRemove} items={items} acceptPdf={acceptPdf} aspect={aspect}/>
                    : <ImageUpload onUpload={onStore} acceptPdf={acceptPdf} />}
                </div>
              )}
            </div>
          )}
          <ImagesPreview {...preview} />
          <ImageBoxGallery
            visible={galleryVisible}
            items={items}
            onOpenPreview={preview.open}
            onClose={toggleGallery}
            onDelete={onRemove}
            onUpload={onStore}
            readonly={readonly}
            acceptPdf={acceptPdf}
            crop={crop}
            aspect={aspect}
          />
        </>
      )}
    </ImageBoxContextProvider>
  );
}

export const ImageBox = memo(_ImageBox);
