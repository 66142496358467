import { AddReportButton } from '@/components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GenerateHomeStatusReportModal } from './GenerateHomeStatusReportModal';

export function GenerateHomeStatusReportButton() {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <AddReportButton onClick={() => setOpenModal(true)}>
        {t('reports.homeReport')}
      </AddReportButton>
      {openModal && (
        <GenerateHomeStatusReportModal onClose={() => setOpenModal(false)} />
      )}
    </>
  );
}
