import {
  useGetSubcontractorDetailsQuery,
  useUpdateSubcontractorMutation,
} from '@/redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubcontractorDetailsModal } from '@/views';
import { UpdateSubcontractorDto } from '@/core';
import { notify } from '@/components';

const useSubmit = (closeModal: () => any) => {
  const [update] = useUpdateSubcontractorMutation();

  return async (values: UpdateSubcontractorDto) => {
    const result = {
      ...values,
      items: undefined,
    };
    await update(result)
      .unwrap()
      .then(() => {
        notify.success.t('subcontractors.updated');
        closeModal();
      });
  };
};

export function useEditSubcontractorAction() {
  const [id, setId] = useState<string>();
  const { currentData } = useGetSubcontractorDetailsQuery(
    { id: id! },
    { skip: !id },
  );
  const { t } = useTranslation();
  const submit = useSubmit(() => setId(undefined));

  return {
    openEdit: (id: string) => setId(id),
    editModal: (
      <>
        {currentData && (
          <SubcontractorDetailsModal
            title={t('subcontractors.details.editTitle')}
            buttonSaveName={t('save')}
            onSubmit={submit}
            value={currentData}
            onClose={() => setId(undefined)}
          />
        )}
      </>
    ),
  };
}
