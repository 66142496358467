import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Page, Title } from '@/components';
import { Activity } from '@webinex/activity';
import { ActivityOperationPanel, ActivityDetailsPanel } from '@/views';

const useId = () => {
  const { id } = useParams<'id'>();
  return id!;
};

export function ActivityDetailsPage() {
  const id = useId();
  const { t } = useTranslation();
  const [activity, setActivity] = useState<Activity>();

  return (
    <Page htmlTitle={t('activity.details.title')}>
      <Page.Paths>
        <Page.Path href="/activity">{t('activity.title')}</Page.Path>
        <Page.Path>{t('activity.details.title')}</Page.Path>
      </Page.Paths>

      <Page.Body>
        <Page.Card title={t('activity.details.title')}>
          <Title>{t('activity.details.title')}</Title>
          <ActivityDetailsPanel id={id} onLoaded={setActivity} />

          <Title className="mt-4">{t('activity.operation.title')}</Title>
          {activity && <ActivityOperationPanel id={activity.operationId} />}
        </Page.Card>
      </Page.Body>
    </Page>
  );
}
