import { enumObject, EnumValue } from '@/utils';

const ROOM_TYPE_STATUS = ['Active', 'Deactivated'] as const;
export type RoomTypeStatus = EnumValue<typeof ROOM_TYPE_STATUS>;

// eslint-disable-next-line
export const RoomTypeStatus = enumObject('RoomTypeStatus', ROOM_TYPE_STATUS);

export interface RoomTypeDto {
  id: string;
  name: string;
  status: RoomTypeStatus;
}

export interface RoomTypeLookupDto {
  id: string;
  name: string;
}

export type AddRoomTypeDto = Omit<RoomTypeDto, 'id' | 'number'>;
export type UpdateRoomTypeDto = Omit<RoomTypeDto, 'status'>;
export type ChangeStatusRoomTypeDto = Omit<RoomTypeDto, 'name'>;
