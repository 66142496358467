import { useCallback } from 'react';
import { useLocation, resolvePath, matchPath } from 'react-router-dom';

export function useRouteMatchPredicate(basePath: string) {
  const { pathname } = useLocation();

  return useCallback(
    (to: string) => {
      const resolvedTo = resolvePath(to, basePath);
      return !!matchPath({ path: resolvedTo.pathname, end: false }, pathname);
    },
    [basePath, pathname],
  );
}
