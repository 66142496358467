import { notify } from '@/components';
import { useAddItemMenuMutation } from '@/redux';
import { useTranslation } from 'react-i18next';
import {
  ItemMenuDetailsForm,
  ItemMenuDetailsFormValue,
  ItemMenuDetailsSubmitValue,
} from './ItemMenuDetailsForm';

interface Props {
  onCancel: () => any;
  communityId: string;
}

const useSubmit = (props: Props) => {
  const { communityId, onCancel } = props;
  const [add] = useAddItemMenuMutation();

  return (args: ItemMenuDetailsSubmitValue) =>
    add({ ...args, communityId })
      .unwrap()
      .then(() => notify.success.t('itemMenus.details.saved'))
      .then(onCancel);
};

const INITIAL_VALUE: ItemMenuDetailsFormValue = {
  name: null!,
  items: [],
};

export function AddItemMenu(props: Props) {
  const { onCancel, communityId } = props;
  const submit = useSubmit(props);
  const { t } = useTranslation();

  return (
    <ItemMenuDetailsForm
      texts={{
        saveBtn: t('itemMenus.details.addButton'),
        title: t('itemMenus.details.addTitle'),
      }}
      communityId={communityId}
      onSubmit={submit}
      onCancel={onCancel}
      value={INITIAL_VALUE}
    />
  );
}
