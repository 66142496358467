import { useTranslation } from 'react-i18next';
import { AddButton } from '@/components';

interface Props {
  onAdd: () => any;
}

export function AddResidentButton(props: Props) {
  const { t } = useTranslation();
  const { onAdd } = props;

  return (
    <AddButton onClick={onAdd}>{t('residents.details.addButton')}</AddButton>
  );
}
