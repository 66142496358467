import { useTranslation } from 'react-i18next';
import { TabPanel } from '@/components';
import { Button } from 'antd';
import {
  useGenerateOfferingsReportMutation,
  useGetProjectHomeInfoQuery,
} from '@/redux';

interface Props {
  projectId: string;
  homeId: string;
}

const useSubmit = (homeId: string) => {
  const [generate] = useGenerateOfferingsReportMutation();

  return () =>
    generate({ homeId })
      .unwrap()
      .then((uri) => {
        window.open(
          '/api/report/inline?src=' + encodeURIComponent(uri),
          '_blank',
        );
      });
};

export function HomesOfferingsReportPanel(props: Props) {
  const { projectId, homeId } = props;
  const { t } = useTranslation();
  const { data } = useGetProjectHomeInfoQuery({ id: projectId, homeId });
  const submit = useSubmit(homeId);

  return (
    <TabPanel>
      <TabPanel.Title>{t('reports.offeringsReport')}</TabPanel.Title>
      <TabPanel.Actions>
        {data?.isActive && (
          <Button type="primary" onClick={submit}>
            {t('reports.offeringsReportButton')}
          </Button>
        )}
      </TabPanel.Actions>
      <TabPanel.Body></TabPanel.Body>
    </TabPanel>
  );
}
