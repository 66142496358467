import { Http } from '../http/http';
import { guard } from '@/utils';
import { UserTableSettingsDto } from './clientSetingsTypes';

class ClientSettingsHttp extends Http {
  public async getUserTableSettings(userId: string, table: string) {
    const url = `/api/user-table-settings?userId=${userId}&table=${table}`;
    const response = await this._axios.get<UserTableSettingsDto>(url);
    return response.data;
  }

  public async setUserTableSettings(request: UserTableSettingsDto) {
    guard.notNull(request, 'request');
    const response = await this._axios.post(
      `/api/user-table-settings`,
      request,
    );
    return response.data;
  }
}

export const clientSettingsHttp = new ClientSettingsHttp();
