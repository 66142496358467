import { Form, LookupOption } from '@/components';
import { mapResponsiblePersonValue, useResponsiblePersonsLookupSource } from '@/views';
import { TaskResponsiblePersonValue } from '@/core';
import { useMemo } from 'react';

interface ResponsiblePersonInputProps {
  communityId: string;
  initialValue?: TaskResponsiblePersonValue,
  name: string
}

export function ResponsiblePersonInput(props: ResponsiblePersonInputProps)
{
  const { communityId, initialValue, name} = props;
  const responsiblePersonDataSource = useResponsiblePersonsLookupSource(communityId)
  const preloadInitial: LookupOption | undefined = useMemo(
    () =>
    initialValue
      ? {
        label: initialValue.label,
        value: mapResponsiblePersonValue(initialValue),
        }
        : undefined,
    [initialValue],
  );

  return (
    <Form.CreatableAsyncSelect
      dataSource={responsiblePersonDataSource}
      preloadInitial={preloadInitial}
      name={`${name}.responsiblePerson`}
      label={false}
    />
  )
}