import { ImageBox, useImageBox } from '@/components';
import styles from './IndexImage.module.scss';
import { useClippoBlobs } from '@/utils';
import { useEffect } from 'react';
import { UploadImageButton } from '@/views';
import { useTranslation } from 'react-i18next';

interface Props {
  communityId: string
  readonly?: boolean
}

export function NoImage() {
  const { t } = useTranslation();
  return <div className={styles.noImage}>{t('index.noImage')}</div>
}

export function IndexImage(props: Props){
  const { communityId, readonly } = props;

  const imageBox = useImageBox({
    defer: false,
    ownerId: communityId,
    ownerType: 'IndexPageImage',
    noPreload: true,
    reloadAfterAction: true,
  });

  const { blobs }  = useClippoBlobs(imageBox);

  useEffect(() => {
    imageBox.fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityId]);

  return (
    <>
      {!readonly && communityId && <UploadImageButton onClick={imageBox.toggleGallery} />}
      {communityId && (<ImageBox {...imageBox} galleryOnly crop aspect={16/5} />)}
      {blobs[0] && communityId
        ? (<img alt="" className={styles.bgImage} src={blobs[0]?.blobURL} /> )
        : (<NoImage />)}
    </>
  )}