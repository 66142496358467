import { AttachmentDto } from '@/core';
import { memo, useCallback } from 'react';
import styles from './ImageBox.module.scss';
import { ImageBoxImage } from './ImageBoxImage';

export interface ImageBoxItemProps {
  item: AttachmentDto;
  onOpenPreview: (item: AttachmentDto) => any;
  more?: number | false;
  onMoreClick?: () => any;
}

function _ImageBoxItem(props: ImageBoxItemProps) {
  const { item, onOpenPreview, more, onMoreClick } = props;

  const handleClick = useCallback(() => {
    onOpenPreview(item);
  }, [item, onOpenPreview]);

  return (
    <div className={styles.imageBoxItem}>
      <ImageBoxImage.Lazy onClick={handleClick} value={item} />
      {more && (
        <div className={styles.more} onClick={onMoreClick}>
          <div className={styles.moreContent}>+{more}</div>
        </div>
      )}
    </div>
  );
}

export const ImageBoxItem = memo(_ImageBoxItem);
