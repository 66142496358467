import { RtkqSpin, useLazyGetFloorplansListQuery } from '@/redux';
import {
  createEntireLoadLookupSource,
  LookupOption,
  LookupSource,
} from '@/components';
import { useMemo } from 'react';
import { DEFAULT_PAGING, FloorplanDto } from '@/core';
import { Typography } from 'antd';

const { Text } = Typography;

function map(value: FloorplanDto): LookupOption {
  const { constructionName, number, marketingName } = value;

  const searchValue = [constructionName, marketingName, number].join(' ');

  return {
    _search: searchValue,
    value: value.id,
    label: (
      <span>
        <div>{marketingName}</div>
        <div>
          <Text type="secondary">{constructionName}</Text>
        </div>
      </span>
    ),
  };
}

export function useFloorplanLookupSource(communityId: string): LookupSource {
  const [fetchList] = useLazyGetFloorplansListQuery();

  return useMemo(() => {
    const entireSource = createEntireLoadLookupSource({
      initial: (id: string) =>
        fetchList(
          {
            communityId,
            onlyActive: true,
            paging: DEFAULT_PAGING,
            [RtkqSpin]: false,
          },
          true,
        )
          .unwrap()
          .then((values) => map(values.items.find((x) => x.id === id)!)),
      load: () =>
        fetchList(
          {
            communityId,
            onlyActive: true,
            paging: DEFAULT_PAGING,
            [RtkqSpin]: false,
          },
          true,
        )
          .unwrap()
          .then((values) => values.items.map(map)),
    });

    return { ...entireSource, searchField: '_search' };
  }, [fetchList, communityId]);
}
