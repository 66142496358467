import { ActiveProjectStatuses, ProjectStatus } from '@/core';
import {
  ProjectContext as ProjectContextType,
  projectHasStatus,
} from '@/redux';
import React, { useContext, useMemo } from 'react';

export const ProjectContext = React.createContext<ProjectContextType>(null!);

export interface UseProjectContextResult extends ProjectContextType {
  hasStatus: (statuses: ProjectStatus[]) => boolean;
  isActive: boolean;
}

export function useProjectContext(): UseProjectContextResult {
  const ctx = useContext(ProjectContext);

  if (!ctx) {
    throw new Error(
      'useProjectContext could not be used outside ProjectContext',
    );
  }

  return useMemo(
    () => ({
      ...ctx,
      hasStatus: (statuses: ProjectStatus[]) => projectHasStatus(ctx, statuses),
      isActive: projectHasStatus(ctx, ActiveProjectStatuses),
    }),
    [ctx],
  );
}
